import { actionType } from "constants/Recommendations";
import { axiosProxy } from "utils/axiosProxy";
import { find, get, invert, map } from "lodash";
import { genericAttachmentUploaderWithoutSW } from "utils/genericAttachmentUploader";
import { actionType as sprayDiaryActions } from "constants/SprayDiary";
import {
  APPLICATION_TYPES,
  PRESSURE_UNITS,
  RECOMMENDATION_STATUSES,
  URGENCY_LEVELS,
} from "constants/Recommendations/types";
import {
  calculateChemicalsValues,
  convertRecommendationChemicalToSprayDiaryFormat,
} from "utils/recommendations";
import { ROWS_TO_SPRAY } from "constants/shared";
import { getDataToSend } from "../../routes/Recommendations/utils";
import uuid from "uuid/v4";
import moment from "moment";
let uniqId;

const url = "/recommendation";

export const newTaskShape = {
  assignees: [],
  rowsToSpray: invert(ROWS_TO_SPRAY)[ROWS_TO_SPRAY.ALL],
  applicationType: invert(APPLICATION_TYPES)[APPLICATION_TYPES.SPRAY],
  urgencyLevel: invert(URGENCY_LEVELS)[URGENCY_LEVELS.LOW],
  status: invert(RECOMMENDATION_STATUSES)[RECOMMENDATION_STATUSES.PENDING],
  pressureUnit: invert(PRESSURE_UNITS)[PRESSURE_UNITS.PSI],
  rowWidth: 100,
  conc: 1,
};

export const fetchRecommendations = ({
  archived = false,
  filters,
  page,
  size,
  sort,
  unpaged,
}) => async (dispatch) => {
  try {
    dispatch({ type: actionType.FETCH_RECOMMENDATIONS_START });
    const convertedFilter = Object.fromEntries(filters);
    const newFrom =
      convertedFilter.seasons && convertedFilter.seasons.slice(0, 10);
    const newTo = convertedFilter.seasons && convertedFilter.seasons.slice(-10);
    const from = convertedFilter.from && convertedFilter.from;
    const to = convertedFilter.to && convertedFilter.to;

    delete convertedFilter.seasons;
    delete convertedFilter.plannedDateFrom;
    delete convertedFilter.plannedDateTo;
    delete convertedFilter.from;
    delete convertedFilter.to;
    const newFilters =
      !newFrom || !newTo
        ? {
            ...convertedFilter,
          }
        : {
            ...convertedFilter,
            ...(newFrom && { plannedDateAfter: newFrom }),
            ...(newTo && { plannedDateBefore: newTo }),
          };
    const params = new URLSearchParams(newFilters);

    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    if (from)
      params.append("plannedDateAfter", moment(from).format("YYYY-MM-DD"));
    if (to) {
      params.append("plannedDateBefore", moment(to).format("YYYY-MM-DD"));
    }
    (sort || ["id,asc"]).forEach((field) => {
      params.append("sort", field);
    });

    params.append("archived", archived);
    uniqId = uuid();

    const response = await axiosProxy({
      method: "GET",
      url,
      uniqId,
      params,
    });

    if (uniqId === response.config.headers["X-REQUEST-ID"]) {
      dispatch({
        type: actionType.SET_RECOMMENDATIONS,
        payload: response.data,
      });
    }
  } catch (e) {
    dispatch({ type: actionType.SET_RECOMMENDATIONS_ERROR });
  }
};

export const setCurrentTask = (newTaskData) => (dispatch) => {
  // TODO: Refuse using the spray diary
  // Converting to the format needed by the Spray Diary.
  const chemicals = convertRecommendationChemicalToSprayDiaryFormat(
    newTaskData
  );

  dispatch({
    type: actionType.SET_CURRENT_TASK,
    payload: { ...newTaskData, chemicals },
  });

  dispatch({
    type: sprayDiaryActions.SET_SELECTED_CHEMICALS,
    payload: chemicals.map(({ chemical }) => chemical),
  });
};

export const setCurrentTaskById = (taskId) => async (dispatch, getState) => {
  const { recommendations, currentTask } = getState();
  const { data } = recommendations;

  if (!taskId) {
    dispatch({ type: actionType.SET_CURRENT_TASK, payload: null });
  } else if (!currentTask || currentTask.id !== taskId) {
    const dataInState = find(
      get(data, "content"),
      (item) => item.id === taskId
    );

    if (dataInState) {
      dispatch(setCurrentTask(dataInState));
    } else {
      try {
        dispatch({ type: actionType.FETCH_RECOMMENDATIONS_START });
        const response = await axiosProxy({
          method: "GET",
          url: `${url}/${taskId}`,
        });

        dispatch(setCurrentTask(response.data));
      } catch {
        dispatch({ type: actionType.SET_RECOMMENDATIONS_ERROR });
      }
    }
  }
};

export const fetchEditTask = (newTaskData) => async (dispatch, getState) => {
  dispatch({ type: actionType.SET_IS_EDITING, payload: true });
  try {
    const response = await axiosProxy({
      method: "PUT",
      data: getDataToSend(newTaskData, getState()),
      url: `${url}/${newTaskData.id}`,
    });
    const { recommendations } = getState();
    const { data } = recommendations;

    dispatch(setCurrentTask(newTaskData));
    dispatch({
      type: actionType.SET_RECOMMENDATIONS,
      payload: {
        ...data,
        content: map(data.content, (task) =>
          task.id === newTaskData.id ? response.data : task
        ),
      },
    });
    return response.data;
  } catch (e) {
    dispatch({ type: actionType.SET_RECOMMENDATIONS_ERROR, payload: e });
  } finally {
    dispatch({ type: actionType.SET_IS_EDITING, payload: false });
  }
};

export const fetchCreateTask = (newTaskData) => async (dispatch, getState) => {
  dispatch({ type: actionType.SET_IS_EDITING, payload: true });

  try {
    const response = await axiosProxy({
      method: "POST",
      data: getDataToSend(newTaskData, getState()),
      url,
    });
    dispatch(setCurrentTask(response.data));

    return response.data;
  } catch (e) {
    dispatch({ type: actionType.SET_RECOMMENDATIONS_ERROR, payload: e });
  } finally {
    dispatch({ type: actionType.SET_IS_EDITING, payload: false });
  }
};

export const fetchCreateTemplateAndTask = (newTaskData, templateName) => async (
  dispatch,
  getState
) => {
  dispatch({ type: actionType.SET_IS_EDITING, payload: true });
  const state = getState();
  const machineryList = state.sprayDiary.machineryList;
  const areasList = get(state.sprayDiary, "areasList.content", []);
  const { chemicals } = calculateChemicalsValues(
    newTaskData,
    machineryList,
    areasList
  );

  const dataToSend = {
    ...newTaskData,
    templateId: null,
    name: templateName,
    chemicals: map(chemicals, (chemical) => ({
      chemicalId: chemical.chemicalId,
      chemicalRateId: chemical.chemicalRateId,
      dilutionRate: chemical.dilutionRate,
      dilutionRateType: chemical.dilutionRateType,
      qtyPerFullVat: chemical.qtyPerFullVat,
      qtyPerPartTank: chemical.qtyPerPartTank,
      batchNumber: chemical.batchNumber,
      dateOfManufacture: chemical.dateOfManufacture,
    })),
  };

  try {
    const response = await axiosProxy({
      method: "POST",
      data: dataToSend,
      url: `${url}/template`,
    });

    return response.data;
  } catch (e) {
    dispatch({ type: actionType.SET_RECOMMENDATIONS_ERROR, payload: e });
  } finally {
    dispatch({ type: actionType.SET_IS_EDITING, payload: false });
  }
};

export const uploadAttachment = ({
  file,
  callBack,
  idReplace,
  data,
  parentId,
}) =>
  genericAttachmentUploaderWithoutSW({
    url: "/recommendation/attachments/presign_put_url",
    file,
    startAction: "",
    successAction: "",
    errorAction: "",
    callBack,
    idReplace,
    data,
    parentId,
  });

export const fetchTemplates = () => async (dispatch) => {
  dispatch({ type: actionType.FETCH_TEMPLATES_START });

  const res = await axiosProxy({
    url: `${url}/template?unpaged=true`,
  });

  dispatch({ type: actionType.SET_TEMPLATES, payload: res.data.content });
};

export const clearError = () => (dispatch) =>
  dispatch({ type: actionType.CLEAR_ERROR });

export const archiveTasks = (tasksIds) => async (dispatch) => {
  dispatch({ type: actionType.ARCHIVE_TASKS_START });

  try {
    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?ids=${tasksIds.join(",")}&archive=true`,
    });

    dispatch({ type: actionType.ARCHIVE_TASKS, payload: tasksIds });
  } catch (e) {
    dispatch({ type: actionType.ARCHIVE_TASKS_FAIL });
  }
};

export const unArchiveTasks = (tasksIds) => async (dispatch) => {
  dispatch({ type: actionType.FETCH_RECOMMENDATIONS_START });
  try {
    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?ids=${tasksIds.join(",")}&archive=false`,
    });

    // TODO: Think on the better solution
    window.location.reload();
  } catch (e) {
    dispatch({ type: actionType.FETCH_TEMPLATES_FAIL });
  }
};
