export const actionTypes = {
  CLEAR_ERROR: "HARVEST_UNITS/CLEAR_ERROR",

  DELETE_HARVEST_UNITS: "HARVEST_UNITS/DELETE_HARVEST_UNITS",
  DELETE_HARVEST_UNITS_START: "HARVEST_UNITS/DELETE_HARVEST_UNITS_START",
  DELETE_HARVEST_UNITS_ERROR: "HARVEST_UNITS/DELETE_HARVEST_UNITS_ERROR",

  FETCH_HARVEST_UNITS: "HARVEST_UNITS/FETCH_HARVEST_UNITS",
  FETCH_HARVEST_UNITS_START: "HARVEST_UNITS/FETCH_HARVEST_UNITS_START",
  FETCH_HARVEST_UNITS_ERROR: "HARVEST_UNITS/FETCH_HARVEST_UNITS_ERROR",

  FETCH_HARVEST_UNITS_OPTIONS:
    "HARVEST_UNITS_OPTIONS/FETCH_HARVEST_UNITS_OPTIONS",
  FETCH_HARVEST_UNITS_OPTIONS_START:
    "HARVEST_UNITS_OPTIONS/FETCH_HARVEST_UNITS_OPTIONS_START",
  FETCH_HARVEST_UNITS_OPTIONS_ERROR:
    "HARVEST_UNITS_OPTIONS/FETCH_HARVEST_UNITS_OPTIONS_ERROR",

  ADD_HARVEST_UNITS: "HARVEST_UNITS/ADD_HARVEST_UNITS",
  ADD_HARVEST_UNITS_START: "HARVEST_UNITS/ADD_HARVEST_UNITS_START",
  ADD_HARVEST_UNITS_ERROR: "HARVEST_UNITS/ADD_HARVEST_UNITS_ERROR",

  EXPORT_DATA_HARVEST_UNITS: "HARVEST_UNITS/EXPORT_DATA_HARVEST_UNITS",
  EXPORT_DATA_HARVEST_UNITS_START:
    "HARVEST_UNITS/EXPORT_DATA_HARVEST_UNITS_START",
  EXPORT_DATA_HARVEST_UNITS_ERROR:
    "HARVEST_UNITS/EXPORT_DATA_HARVEST_UNITS_ERROR",

  UPDATE_HARVEST_UNITS: "HARVEST_UNITS/UPDATE_HARVEST_UNITS",
  UPDATE_HARVEST_UNITS_START: "HARVEST_UNITS/UPDATE_HARVEST_UNITS_START",
  UPDATE_HARVEST_UNITS_ERROR: "HARVEST_UNITS/UPDATE_HARVEST_UNITS_ERROR",

  FETCH_HARVEST_UNITS_CONTROL:
    "HARVEST_UNITS_CONTROL/FETCH_HARVEST_UNITS_CONTROL",
  FETCH_HARVEST_UNITS_FILTERS:
    "HARVEST_UNITS_CONTROL/FETCH_HARVEST_UNITS_FILTERS",
  FETCH_HARVEST_UNITS_CONTROL_START:
    "HARVEST_UNITS_CONTROL/FETCH_HARVEST_UNITS_CONTROL_START",
  FETCH_HARVEST_UNITS_CONTROL_ERROR:
    "HARVEST_UNITS_CONTROL/FETCH_HARVEST_UNITS_CONTROL_ERROR",

  FETCH_HARVEST_UNITS_CONTROL_LIST:
    "HARVEST_UNITS_CONTROL_LIST/FETCH_HARVEST_UNITS_CONTROL_LIST",
  FETCH_HARVEST_UNITS_CONTROL_LIST_START:
    "HARVEST_UNITS_CONTROL_LIST/FETCH_HARVEST_UNITS_CONTROL_LIST_START",
  FETCH_HARVEST_UNITS_CONTROL_LIST_ERROR:
    "HARVEST_UNITS_CONTROL_LIST/FETCH_HARVEST_UNITS_CONTROL_LIST_ERROR"
};
