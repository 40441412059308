import React, { useEffect } from "react";
import { Button, Form, Input } from "semantic-ui-react";
import { Formik, Field } from "formik";
import styles from "./Parametrs.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getParametrsList, updateQC } from "actions/QualityControl";
import { parametrsFiltersOptions } from "selectors/qualityControl";

const ParameterAdd = ({ activeItem, setParameterAddCheck, activeRounds }) => {
  const dispatch = useDispatch();
  const parametrsOptions = useSelector(parametrsFiltersOptions);
  const onSubmit = data => {
    dispatch(updateQC(activeItem.id, data));
    setParameterAddCheck(false);
  };
  useEffect(() => {
    activeItem?.qcRounds.length <= 1 &&
      !parametrsOptions.options.length &&
      dispatch(getParametrsList());
  }, []);
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={{
        ...activeRounds,
        defectReports: [
          { parameter: null, minorAmount: null, majorAmount: null }
        ]
      }}
    >
      {({ values, dirty, isValid, errors, setFieldValue, setFieldError }) => {
        const dataToSend = {
          barcodeId: activeItem?.barcodeId,
          createdAt: activeItem?.createdAt,
          id: activeItem?.id,
          name: activeItem?.name,
          unitToCheckPerRound: activeItem?.unitToCheckPerRound,
          qcRounds: activeItem?.qcRounds.map(item => {
            return {
              ...item,
              defectReports: [...item.defectReports, ...values.defectReports]
            };
          }),
          rounds: activeItem?.rounds
        };

        const newOptions = parametrsOptions.options.filter(item =>
          activeRounds?.defectReports.every(i => item.key !== i.parameter)
        );
        return (
          <>
            <div className={styles.root}>
              <Form>
                <div className={styles.rowWrapper}>
                  <div className={styles.formFieldWrapper}>
                    <Form.Field required width={8} className={styles.formField}>
                      <label className={styles.label}>Parameter type</label>
                      <Form.Dropdown
                        required
                        selection
                        search
                        name="parameters"
                        options={newOptions || []}
                        onChange={(e, { value }) => {
                          setFieldValue(
                            "defectReports",
                            values.defectReports.map(i => {
                              return { ...i, parameter: value };
                            })
                          );
                        }}
                      />
                    </Form.Field>
                    <Form.Field required width={4} className={styles.formField}>
                      <label className={styles.label}>Minor</label>
                      <Field
                        as={Input}
                        fluid
                        className={styles.totalField}
                        required
                        name="minor"
                        onChange={e => {
                          const { value } = e.target;
                          setFieldValue(
                            "defectReports",
                            values.defectReports.map(i => {
                              return { ...i, minorAmount: value };
                            })
                          );
                        }}
                      />
                    </Form.Field>

                    <Form.Field required width={4} className={styles.formField}>
                      <label className={styles.label}>Major</label>
                      <Field
                        required
                        as={Input}
                        className={styles.totalField}
                        fluid
                        name="major"
                        onChange={e => {
                          const { value } = e.target;
                          setFieldValue(
                            "defectReports",
                            values.defectReports.map(i => {
                              return { ...i, majorAmount: value };
                            })
                          );
                        }}
                      />
                    </Form.Field>
                  </div>
                </div>
              </Form>
            </div>
            <div className={styles.btnWrapper}>
              <Button
                content="Cancel"
                color="green"
                basic
                onClick={() => {
                  setParameterAddCheck(false);
                }}
              />
              <Button
                content={"Save"}
                color="green"
                onClick={() => onSubmit(dataToSend)}
                disabled={
                  !values.defectReports[0].parameter ||
                  !values.defectReports[0].minorAmount ||
                  !values.defectReports[0].majorAmount
                }
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default ParameterAdd;
