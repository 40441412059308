import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Menu } from "semantic-ui-react";
import Parametrs from "../../components/Tabs/Parametrs/Parametrs";
import ParameterAdd from "./Parametrs/ParameterAdd";
import styles from "./Tabs.module.css";
import BinPicturesEdit from "./Pictures/BinPicturesEdit";
import WorkersAdd from "./Workers/WorkersAdd";
import Workers from "../../components/Tabs/Workers/Workers";

const TabsAdd = ({
  activeItem,
  activeRoundsNumber,
  setFieldValue,
  setOpenParametrAdd,
  openParametrAdd,
  setOpenWorkerAdd,
  openWorkerAdd
}) => {
  const [activeTab, setactiveTab] = useState("Parameters");
  const handleItemClick = (e, { name }) => {
    setactiveTab(name);
    setOpenParametrAdd(false);
  };
  return (
    <Grid className={styles.body}>
      <div className={styles.tabsHolder}>
        <Menu pointing secondary className={styles.tabsMenu}>
          <Menu.Item
            name="Parameters"
            active={activeTab === "Parameters"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="Bin pictures"
            active={activeTab === "Bin pictures"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="Workers"
            active={activeTab === "Workers"}
            onClick={handleItemClick}
          />
        </Menu>
        <div
          className={`${styles.tabItem} ${
            activeTab === "Parameters"
              ? styles.visibleTab
              : styles.undisplayedTab
          }`}
        >
          {openParametrAdd ||
          !activeItem.qcRounds[activeRoundsNumber]?.defectReports?.length ? (
            <ParameterAdd
              setOpenParametrAdd={setOpenParametrAdd}
              activeRounds={activeItem.qcRounds[activeRoundsNumber]}
              activeItem={activeItem}
              onChangeField={setFieldValue}
            />
          ) : (
            <Parametrs activeRounds={activeItem.qcRounds[activeRoundsNumber]} />
          )}
          {!openParametrAdd &&
            !!activeItem.qcRounds[activeRoundsNumber]?.defectReports.length && (
              <div className={styles.addBtnWrapper}>
                <Button
                  className={styles.addBtn}
                  content="+ Add a parameter"
                  color="green"
                  onClick={() => setOpenParametrAdd(true)}
                />
              </div>
            )}
        </div>
        <div
          className={`${styles.tabItem} ${
            activeTab === "Bin pictures"
              ? styles.visibleTab
              : styles.undisplayedTab
          }`}
        >
          <BinPicturesEdit
            activeItem={activeItem}
            activeRounds={activeItem.qcRounds[activeRoundsNumber]}
            header={"Add Quality Control task"}
            onChangeField={setFieldValue}
          />
        </div>
        <div
          className={`${styles.tabItem} ${
            activeTab === "Workers" ? styles.visibleTab : styles.undisplayedTab
          }`}
        >
          {openWorkerAdd ||
          !activeItem.qcRounds[activeRoundsNumber]?.employeeResults.length ? (
            <WorkersAdd
              activeRounds={activeItem.qcRounds[activeRoundsNumber]}
              activeItem={activeItem}
              setAddCheck={setOpenWorkerAdd}
              onChangeField={setFieldValue}
            />
          ) : (
            <Workers
              setAddCheck={setOpenWorkerAdd}
              activeRounds={activeItem.qcRounds[activeRoundsNumber]}
            />
          )}
        </div>
      </div>
    </Grid>
  );
};

TabsAdd.propTypes = {
  activeRounds: PropTypes.object
};

export default TabsAdd;
