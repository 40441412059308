import React, { useEffect } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import LayoutOverlay from "components/LayoutOverlay";
import Sidebar from "./components/Sidebar";
import Location from "./components/Location";
import Operations from "./components/Operations";
import { ADD_RECOMMENDATION } from "../../constants/shared/routes";
import { Formik } from "formik";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  fetchCreateTask,
  fetchEditTask,
  newTaskShape,
  setCurrentTaskById,
} from "actions/Recommendations/recommendations";
import SaveRecommendationButton from "./components/SaveRecommendationButton";
import { history } from "../../store";
import {
  infoMessageType,
  messagesIds,
  putMessage,
} from "../../actions/InfoMessages";
import {
  getRecommendationTaskReadableId,
  validateRecommendationForm,
} from "../../utils/recommendations";
import styles from "./styles.module.css";

const RecommendationForm = ({
  route,
  match,
  currentTask,
  actions,
  isEditing,
  error,
  navigate,
}) => {
  const { params, path } = match;
  const isCreatePage = path === ADD_RECOMMENDATION;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!params.id) {
      dispatch(setCurrentTaskById(null));
    } else {
      dispatch(setCurrentTaskById(params.id));
    }
  }, [params.id]);

  useEffect(() => {
    if (error) {
      dispatch(
        putMessage({
          type: infoMessageType.ERROR,
          messageId: messagesIds.GENERIC,
          extraData: {
            message: error.message,
          },
        })
      );
    }
  }, [error]);

  return (
    <Formik
      enableReinitialize
      initialValues={currentTask || newTaskShape}
      validate={validateRecommendationForm}
      onSubmit={(values) => {
        const action = isCreatePage
          ? actions.fetchCreateTask
          : actions.fetchEditTask;

        action(values).then((data) => {
          if (data) {
            dispatch(
              putMessage({
                type: infoMessageType.INFO,
                messageId: messagesIds.GENERIC,
                extraData: {
                  message: `${getRecommendationTaskReadableId(data.id)} was ${
                    isCreatePage ? "created" : "updated"
                  }`,
                },
              })
            );
            navigate("/recommendations");
          }
        });
      }}
      validateOnChange={false}
    >
      <LayoutOverlay
        sidebar={<Sidebar />}
        extra={
          <Dimmer active={false}>
            <Loader />
          </Dimmer>
        }
        closeLink={"/recommendations"}
        route={{
          ...route,
          name: `${isCreatePage ? "Edit" : "Create"} ${route.name}`,
        }}
        footer={<SaveRecommendationButton isEditing={isEditing} />}
        className={styles.root}
      >
        <Location />
        <Operations navigate={navigate} />
      </LayoutOverlay>
    </Formik>
  );
};

RecommendationForm.propTypes = {};

const mapStateToProps = (state) => {
  const { currentTask, isEditing, error } = state.recommendations;

  return {
    currentTask,
    isEditing,
    error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setCurrentTaskById,
      fetchEditTask,
      fetchCreateTask,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationForm);
