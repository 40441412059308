import React, { useEffect, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";
import { Header, Button, Container, Icon } from "semantic-ui-react";
import TasksReport from "./components/TasksReport";
import BreakdownTable from "./components/BreakdownTable";
import {
  taskTypesList,
  defaultFiltersOptions,
  sortConfig,
  printDocColumns,
} from "./constants";
import ExportToExcel from "../ExportToExcel";
import SortDropdown from "../SortDropdown";
import Filters from "../Filters";
import Loader from "../Loader";
import { SPRAY_TASKS_COST_BREAKDOWN_TITLE } from "../../constants/Farms";
import { getFormatedSprayTasksForBreakdown } from "../../utils/chemicalBreakdownUtils";
import { getFiltersFromLocations } from "../../utils/routeHelpers";
import { copyStyles } from "../../utils/styles";
import { hashToObject } from "../../utils/hashToObject";
import "./index.css";

const SprayTasksCostsBreakdown = ({
  getChemicalsBreakdown,
  sprayTasksForBreakdown,
  location,
  farm,
  area,
  isFetching,
  setSummary,
  dateFrom,
  dateTo,
  excludeFilters,
  breakdownTitle,
  navigate,
}) => {
  const isFarm = !!farm && !area;
  const isDateProvided = dateFrom || dateTo;
  const itemSize = isFarm ? farm.size : area.size;
  const { sort } = hashToObject(location.hash);
  const [sortValue, sortOrder] = sort
    ? sort.split(",")
    : ["sprayTaskId", "asc"];
  const chemicalTypeFilterOptions = [];
  const chemicalTypeFilteOptionsExpanded = [];

  if (sprayTasksForBreakdown && sprayTasksForBreakdown.length) {
    sprayTasksForBreakdown.forEach((item) => {
      item.sprayTaskChemicalCosts.forEach((chemical) => {
        if (!chemicalTypeFilterOptions.includes(chemical.chemicalType)) {
          chemicalTypeFilterOptions.push(chemical.chemicalType);
          chemicalTypeFilteOptionsExpanded.push({
            id: "chemicalType",
            value: chemical.chemicalType,
            text: chemical.chemicalType,
          });
        }
      });
    });
  }

  const filtersOptionsExpanded = [
    ...defaultFiltersOptions,
    {
      name: "Chemical Type",
      type: "MultiSelectFilter",
      id: "chemicalType",
      options: chemicalTypeFilteOptionsExpanded,
    },
  ];

  const finalFiltersOptions = filtersOptionsExpanded.filter(
    (f) => !excludeFilters.includes(f.id)
  );

  const locationData = getFiltersFromLocations(finalFiltersOptions, location);

  const dateFromL = locationData.dateFrom ? locationData.dateFrom[0] : null;
  const dateToL = locationData.dateTo ? locationData.dateTo[0] : null;

  dateFrom = isDateProvided ? dateFrom : dateFromL;
  dateTo = isDateProvided ? dateTo : dateToL;

  /* TODO taskTypes.includes(task.type) */
  // const taskTypes = locationData.taskType
  //   ? locationData.taskType
  //   : taskTypesList;

  const chemicalTypes = locationData.chemicalType
    ? locationData.chemicalType
    : chemicalTypeFilterOptions;

  const sprayTasksForBreakdownFiltered = useMemo(
    () =>
      _.orderBy(
        sprayTasksForBreakdown.filter((sprayTask) =>
          sprayTask.sprayTaskChemicalCosts.some((chemical) =>
            chemicalTypes.includes(chemical.chemicalType)
          )
        ),
        sortValue,
        sortOrder
      ),
    [sprayTasksForBreakdown, itemSize, chemicalTypeFilterOptions]
  );

  const { breakdownData, breakdownDataTotals } = useMemo(
    () =>
      getFormatedSprayTasksForBreakdown(
        sprayTasksForBreakdownFiltered,
        itemSize
      ),
    [sprayTasksForBreakdownFiltered, itemSize]
  );

  const breakdownDataForPrint = breakdownData.map((task) => ({
    ...task,
    sprayTaskId: `ST-${task.sprayTaskId}`,
  }));

  const keyToChange = isFarm ? `f_${farm.id}` : `a_${area.id}`;

  useEffect(() => {
    getChemicalsBreakdown(dateFrom, dateTo);
  }, [dateFrom, dateTo, keyToChange]);

  useEffect(() => {
    setSummary(breakdownDataTotals.cost);
  }, [breakdownDataTotals.cost]);

  const print = () => {
    const newWindow = window.open();
    newWindow.document.title = SPRAY_TASKS_COST_BREAKDOWN_TITLE;
    copyStyles(window.document, newWindow.document);

    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <TasksReport
        data={breakdownDataForPrint}
        totalBreakdown={breakdownDataTotals}
        locationName={farm ? farm.name : area.name}
        startDate={dateFrom}
        endDate={dateTo}
      />
    );
    newWindow.print();
  };

  return (
    <Container className="application">
      {breakdownTitle && <Header as="h2">{breakdownTitle}</Header>}
      <div className="filters-row">
        <Filters
          navigate={navigate}
          options={finalFiltersOptions}
          location={location}
        />
        <div className="print-wrapper">
          <div>
            <ExportToExcel
              columns={printDocColumns}
              rawData={breakdownDataForPrint}
              name={SPRAY_TASKS_COST_BREAKDOWN_TITLE}
              className={"mr-1"}
            />
            <Button onClick={print}>
              <Icon name="print" />
              <span>Print</span>
            </Button>
          </div>
          <SortDropdown
            navigate={navigate}
            {...sortConfig}
            sortOrder={sortOrder}
            sortValue={sortValue}
          />
        </div>
      </div>
      <div className="table-wrapper">
        {isFetching ? (
          <Loader />
        ) : (
          <BreakdownTable
            breakdownData={breakdownData}
            totalBreakdown={breakdownDataTotals}
          />
        )}
      </div>
    </Container>
  );
};

SprayTasksCostsBreakdown.propTypes = {
  area: PropTypes.object,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  excludeFilters: PropTypes.array,
  farm: PropTypes.object,
  getChemicalsBreakdown: PropTypes.func,
  isFetching: PropTypes.bool,
  location: PropTypes.object,
  setSummary: PropTypes.func,
  sprayTasksForBreakdown: PropTypes.any,
  breakdownTitle: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    isFetching: state.farms.sprayTasksForBreakdown.isFetching,
    sprayTasksForBreakdown: state.farms.sprayTasksForBreakdown.data.content,
  };
};

const mapDispatchToProps = () => ({ actions: {} });

SprayTasksCostsBreakdown.defaultProps = {
  excludeFilters: [],
  setSummary: () => {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SprayTasksCostsBreakdown);
