import React, { useState } from "react";
import { Icon, Checkbox } from "semantic-ui-react";
import styles from "../../ClockIn.module.css";

const Contractor = ({
  employee,
  contractors,
  values,
  setFieldValue,
  expandAll
}) => {
  const initialExpandedContractors = contractors.reduce(
    (expandedContractors, contractor) => {
      if (expandAll) {
        return {
          ...expandedContractors,
          [contractor.id]: true
        };
      } else {
        const match = values.children.filter(({ employeeId }) =>
          contractor.employeeIds.includes(employeeId)
        );
        return {
          ...expandedContractors,
          [contractor.id]: !!match.length
        };
      }
    },
    {}
  );

  const [expandedContractors, setExpandedContractors] = useState(
    initialExpandedContractors
  );

  const toggleContractorRow = id => {
    setExpandedContractors(prevExpandedContractors => ({
      ...prevExpandedContractors,
      [id]: !prevExpandedContractors[id]
    }));
  };

  return (
    <div className={styles.wrapper}>
      {contractors.map(contractor => {
        const activeEmployee = employee.filter(e =>
          contractor.employeeIds.includes(e.id)
        );

        const {
          employeesMatchContractor,
          employeesNotMatchContractor
        } = values.children.reduce(
          (data, employee) => {
            const match = contractor.employeeIds.includes(employee.employeeId);
            data[
              match ? "employeesMatchContractor" : "employeesNotMatchContractor"
            ].push(employee);
            return data;
          },
          { employeesMatchContractor: [], employeesNotMatchContractor: [] }
        );

        return (
          <>
            <div key={contractor.id} className={styles.groupRow}>
              <div className={styles.checkboxWrapper}>
                {!employeesMatchContractor.length ||
                employeesMatchContractor.length ===
                  contractor.employeeIds.length ? (
                  <Checkbox
                    className={styles.checkBox}
                    checked={
                      employeesMatchContractor.length ===
                      contractor.employeeIds.length
                    }
                    onClick={() => {
                      employeesMatchContractor.length ===
                      contractor.employeeIds.length
                        ? setFieldValue("children", employeesNotMatchContractor)
                        : setFieldValue("children", [
                            ...employeesNotMatchContractor,
                            ...contractor.employeeIds.map(id => ({
                              employeeId: id,
                              generalTaskId: values.generalTaskId,
                              areas: values.areas,
                              startAt: values.startAt
                            }))
                          ]);
                    }}
                  />
                ) : (
                  <div
                    className={styles.minusIconWrapper}
                    onClick={() => {
                      setFieldValue("children", employeesNotMatchContractor);
                    }}
                  >
                    <Icon name="minus" className={styles.minusIcon} />
                  </div>
                )}
                {`${contractor.name}`}
              </div>
              <span className={styles.iconRowField}>
                <Icon
                  className={styles.rowChevronIcon}
                  name={`chevron ${
                    expandedContractors[contractor.id] ? "up" : "down"
                  }`}
                  onClick={() => toggleContractorRow(contractor.id)}
                />
              </span>
            </div>
            {expandedContractors[contractor.id] &&
              activeEmployee.map(i => (
                <div className={styles.subRows}>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      className={styles.checkBox}
                      checked={values.children
                        .map(e => e.employeeId)
                        .includes(i.id)}
                      onClick={() => {
                        values.children.map(e => e.employeeId).includes(i.id)
                          ? setFieldValue(
                              "children",
                              values.children.filter(e => e.employeeId !== i.id)
                            )
                          : setFieldValue("children", [
                              ...values.children,
                              {
                                employeeId: i.id,
                                generalTaskId: values.generalTaskId,
                                areas: values.areas,
                                startAt: values.startAt
                              }
                            ]);
                      }}
                    />
                    {i.firstName} {i.lastName}
                  </div>
                </div>
              ))}
          </>
        );
      })}
    </div>
  );
};

export default Contractor;
