import React from "react";
import PropTypes from "prop-types";
import _, { map } from "lodash";
import { Checkbox, Dropdown, Icon, Input } from "semantic-ui-react";
import ReactDOM from "react-dom";
import { centerOfMass, centroid, polygon as polygonTurf } from "@turf/turf";

import GoogleMaps, { PopupTypes } from "../../../components/GoogleMaps";
import classNames from "classnames";
import styles from "../Dashboard.module.css";
import moment from "moment";
import tagIcon from "./assets/tag.svg";
import binIcon from "./assets/bin.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchScannedBarcodes } from "../../../actions/GeneralBarcodes/generalBarcodes";
import { fetchScannedTagBarcodes } from "../../../actions/TagBarcodes/tagBarcodes";
import {
  getLastLocation,
  collectedLastLocation,
} from "../../../actions/JhonDeere";
import MapFilter from "./components/MapFilter";
import { toEndDateString, toStartDateString } from "../../../utils/dateUtils";

class FarmsMap extends React.Component {
  constructor(props) {
    super(props);
    const filtredFarms = props.collections.filter((i) => !i.farm.archived)[0];
    this.state = {
      options: [],
      selectedItem: [0],
      polygons: [],
      markers: [],
      binMarkers: [],
      currentLatLng: [],
      tagMarkers: [],
      activeFarmIds: [],
      showMachineries: false,
      yieldActive: false,
      tagsActive: false,
      searchQuery: "",
      selectedFarms: [filtredFarms?.farm.id],
      isDropdownOpen: false,
      yieldDateRange: {
        start: toStartDateString(moment()),
        end: toEndDateString(moment()),
      },
      tagsDateRange: {
        start: toStartDateString(moment()),
        end: toEndDateString(moment()),
      },
    };

    this.collectBins = this.collectBins.bind(this);
    this.collectTags = this.collectTags.bind(this);
    this.collectAdditionalData = this.collectAdditionalData.bind(this);
    this.dropdownRef = React.createRef();
  }

  onLoadMap = (...props) => {
    this.props.onLoadMap && this.props.onLoadMap(...props);
    this.setState({
      showFarmSelector: true,
    });
  };

  static mapOptions = (collections) => {
    return (collections || []).map((farm) => {
      return {
        key: farm.farm.id,
        value: farm.farm.id,
        text: farm.farm.name,
      };
    });
  };

  componentDidMount = () => {
    this.collectData();
    this.collectAdditionalData();
    document.addEventListener("click", this.handleDocumentClick);
    this.intervalId = setInterval(() => {
      if (this.state.showMachineries && this.props.checkTokenStatus) {
        if (this.props.machines.length) {
          const promises = this.props.machines.map((item) =>
            this.props.actions.getLastLocation(item.id)
          );

          Promise.all(promises)
            .then((responses) => {
              const locationsData = responses.map((item) => item.values).flat();
              this.props.actions.collectedLastLocation(locationsData);
            })
            .catch((error) => {
              console.error("Error fetching last location:", error);
            });
        }
      }
    }, 60000);
  };

  componentDidUpdate = (prevProps) => {
    const { collections } = this.props;
    if (collections !== prevProps.collections) {
      this.collectData(undefined, this.collectAdditionalData);
    }
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
    clearInterval(this.intervalId);
  }
  handleDocumentClick = (e) => {
    const dropdownNode = ReactDOM.findDOMNode(this.dropdownRef.current);

    if (dropdownNode && !dropdownNode.contains(e.target)) {
      this.setState({ isDropdownOpen: false });
    }
  };
  collectData = (value, selectedItemIndex, callback) => {
    const {
      collections,
      currentLatLng,
      onLatLngChange,
      defaultLocation,
    } = this.props;

    if (collections && collections.length > 0) {
      const newOptions = FarmsMap.mapOptions(
        collections.filter(({ farm }) => !farm.archived)
      );
      const filtredData = collections.filter(
        (item, index) =>
          !item.farm.archived &&
          (value?.length ? value : this.state.selectedFarms).some(
            (i) => i == item.farm.id
          )
      );
      const indices = [];
      let polygonsColsection = {};
      let markersColsection = {};

      for (let i = 0; i < newOptions.length; i++) {
        if (this.state.selectedFarms.includes(newOptions[i].value)) {
          indices.push(i);
        }
      }
      filtredData.map((item, index) => {
        const {
          polygons,
          markers,
          featCollection,
        } = FarmsMap.dataToGeoEntities(item.areas);

        polygonsColsection = {
          ...polygonsColsection,
          [item.farm.id]: polygons,
        };
        markersColsection = {
          ...markersColsection,
          [item.farm.id]: markers,
        };

        const farmLatLng =
          featCollection.features && featCollection.features.length > 0
            ? centroid(featCollection).geometry.coordinates
            : item.farm.location && item.farm.location.length > 0
            ? item.farm.location
            : defaultLocation;

        return this.setState(
          {
            options: newOptions,
            selectedItem: indices,
            polygons: Object.values(polygonsColsection).flat(),
            markers: Object.values(markersColsection).flat(),
            currentLatLng: [
              ...this.state.currentLatLng,
              farmLatLng && farmLatLng[0] && farmLatLng[1]
                ? { lat: farmLatLng[0], lng: farmLatLng[1] }
                : currentLatLng,
            ],
          },
          () => {
            onLatLngChange(this.state.currentLatLng);
            if (callback) callback(this.state);
            document.activeElement.blur();
          }
        );
      });
    }
  };

  static toMarkerObject(type, id, iconUrl, lat, lng, rest) {
    return {
      id: `${type}_${id}`,
      icon: {
        url: iconUrl,
        scaledSize: {
          width: 25,
          height: 30,
        },
      },
      position: { lat, lng },
      ...rest,
    };
  }

  async collectBins(newState) {
    const { selectedItem, options, yieldActive, yieldDateRange } =
      newState || this.state;
    const {
      actions: { fetchScannedBarcodes },
    } = this.props;

    const { key: farmId } = _.get(options, selectedItem, {});

    if (!farmId || !yieldActive || !yieldDateRange.start || !yieldDateRange.end)
      return [];

    const data = await fetchScannedBarcodes({
      filters: { farmId },
      unpaged: true,
      from: yieldDateRange.start,
      to: yieldDateRange.end,
    });

    return data.content
      .filter((bin) => bin.location)
      .map((bin) => {
        const [lat, lng] = bin.location.split(",").map((x) => +x);
        return FarmsMap.toMarkerObject("bin", bin.id, binIcon, lat, lng, {
          $type: "bin",
          $bin: {
            ...bin,
            position: { lat, lng },
          },
        });
      });
  }

  async collectTags(newState) {
    const { selectedItem, options, tagsActive, tagsDateRange } =
      newState || this.state;
    const {
      actions: { fetchScannedTagBarcodes },
    } = this.props;

    const { key: farmId } = _.get(options, selectedItem, {});

    if (!farmId || !tagsActive || !tagsDateRange.start || !tagsDateRange.end)
      return [];

    const data = await fetchScannedTagBarcodes({
      unpaged: true,
      filters: { farmId },
      from: tagsDateRange.start,
      to: tagsDateRange.end,
    });

    return data.content
      .filter((tag) => tag.location)
      .map((tag) => {
        const [lat, lng] = tag.location.split(",").map((x) => +x);
        return FarmsMap.toMarkerObject("tag", tag.id, tagIcon, lat, lng, {
          $type: "tag",
          $tag: {
            ...tag,
            position: { lat, lng },
          },
        });
      });
  }

  collectAdditionalData(newState) {
    Promise.all([this.collectBins(newState), this.collectTags(newState)]).then(
      ([binMarkers, tagMarkers]) => {
        this.setState({ binMarkers, tagMarkers });
      }
    );
  }

  static coordinatesToPath = (coordinates) =>
    coordinates.map((point) => ({ lat: point[0], lng: point[1] }));

  static dataToGeoEntities = (data) => {
    const polygons = [];
    const markers = [];
    const featCollection = {
      type: "FeatureCollection",
      features: [],
    };

    data.forEach((block) => {
      if (block.geojson && block.geojson.features) {
        featCollection.features = [
          ...featCollection.features,
          ...block.geojson.features.map((feature) => ({
            geometry: {
              type: feature.geometry.type,
              coordinates: [feature.geometry.coordinates],
            },
            properties: feature.properties,
            type: feature.type,
          })),
        ];

        block.geojson.features.forEach((feature, index) => {
          const center = centerOfMass(
            polygonTurf([feature.geometry.coordinates])
          );
          markers.push({
            id: `${block.id}_${index}`,
            label: {
              text:
                block.name ||
                `${block.type === "block" ? "Block" : "Patch"} ${index + 1}`,
              color: "white",
            },
            icon: {
              path: 0,
              scale: 0,
            },
            position: {
              lat: center.geometry.coordinates[0],
              lng: center.geometry.coordinates[1],
            },
          });

          polygons.push({
            id: `${block.id}_${index}`,
            block,
            name: feature.properties.name,
            area: feature.properties.area,
            path: FarmsMap.coordinatesToPath(feature.geometry.coordinates),
            options: {
              fillOpacity: block.hasSubAreas ? 0.2 : 0.5,
            },
            position: {
              lat: center.geometry.coordinates[0],
              lng: center.geometry.coordinates[1],
            },
            zIndex: block.hasSubAreas ? 1 : 2,
          });
        });
      }
    });

    return { polygons, markers, featCollection };
  };
  toggleDropdown = () => {
    this.setState(
      (prevState) => ({
        isDropdownOpen: !prevState.isDropdownOpen,
      }),
      () => {
        if (this.dropdownRef.current) {
          this.dropdownRef.current.open = this.state.isDropdownOpen;
        }
      }
    );
  };

  onOptionClick = (e, { value }) => {
    e.stopPropagation();
    this.handleChangeCollection(e, value);
  };

  handleChangeCollection = (e, value) => {
    e.stopPropagation();
    let selectedFarms = this.state.selectedFarms.slice();

    const index = selectedFarms.indexOf(value);
    if (index !== -1) {
      selectedFarms.splice(index, 1);
      if (selectedFarms.length === 0 && this.state.options.length > 0) {
        selectedFarms.push(this.state.options[0].value);
      }
    } else {
      selectedFarms.push(value);
    }

    this.setState({ selectedFarms }, () => {
      this.collectData(selectedFarms, index, this.collectAdditionalData);
    });
  };

  static isGreaterThanYear(startDate, endDate) {
    return (
      startDate &&
      endDate &&
      moment(endDate).isAfter(moment(startDate).add(1, "year"))
    );
  }

  handleChangeDate = (rangeName) => (value) => {
    if (FarmsMap.isGreaterThanYear(value.start, value.end))
      return alert("Can't select end date greater than year after start date");

    this.setState(
      {
        [rangeName]: {
          start: toStartDateString(value.start),
          end: toEndDateString(value.end),
        },
      },
      this.collectAdditionalData
    );
  };
  handleChangeShowMachineries = (value) => {
    this.setState({
      showMachineries: value,
    });
  };

  render() {
    const { zoom, mapOptions, showMap, navigate } = this.props;
    const {
      markers,
      binMarkers,
      tagMarkers,
      polygons,
      options,
      yieldActive,
      showMachineries,
      tagsActive,
      yieldDateRange,
      tagsDateRange,
    } = this.state;

    return (
      <>
        <div className={styles.farmSelector}>
          <Dropdown
            text="Farms"
            multiple
            className={styles.filterButton}
            closeOnChange={false}
            open={this.state.isDropdownOpen}
            onClick={() =>
              this.setState((prevState) => ({
                isDropdownOpen: !prevState.isDropdownOpen,
              }))
            }
            ref={this.dropdownRef}
            value={this.state.selectedFarms}
            icon={
              <div
                className={classNames(styles.filterDropDownIcons, {
                  [styles.activeFilterDropDownIcons]: this.state.selectedFarms
                    .length,
                })}
              >
                {this.state.selectedFarms.length ? (
                  <span>{this.state.selectedFarms.length}</span>
                ) : null}
                <Icon name="caret down" />
              </div>
            }
          >
            <Dropdown.Menu className={styles.farmFilterMenu}>
              <Input
                placeholder="Search"
                icon="search"
                iconPosition="left"
                className="search"
                onClick={(e) => e.stopPropagation()}
                onChange={(_, { value }) =>
                  this.setState({
                    searchQuery: value,
                  })
                }
              />
              <Dropdown.Menu className={styles.scrolingMenu} scrolling>
                {map(
                  options.filter(
                    (item) =>
                      item &&
                      item.text &&
                      item.text
                        .toLowerCase()
                        .includes(this.state.searchQuery.toLowerCase())
                  ),
                  (option) => (
                    <Dropdown.Item
                      key={option.value}
                      value={option.value}
                      text={option.text}
                      onClick={this.onOptionClick}
                    >
                      <Checkbox
                        label={option.text}
                        checked={this.state.selectedFarms.includes(
                          option.value
                        )}
                      />
                    </Dropdown.Item>
                  )
                )}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
          <MapFilter
            className={styles.mapFilter}
            setYieldDateRange={this.handleChangeDate("yieldDateRange")}
            setTagsDateRange={this.handleChangeDate("tagsDateRange")}
            setShowMachineries={this.handleChangeShowMachineries}
            yieldActive={yieldActive}
            showMachineries={showMachineries}
            tagsActive={tagsActive}
            yieldDateRange={yieldDateRange}
            tagsDateRange={tagsDateRange}
            setYieldActive={(value) =>
              this.setState({ yieldActive: value }, this.collectAdditionalData)
            }
            setTagsActive={(value) =>
              this.setState({ tagsActive: value }, this.collectAdditionalData)
            }
          />
        </div>
        <GoogleMaps
          navigate={navigate}
          onLoadMap={this.onLoadMap}
          zoom={zoom}
          showMachineries={this.state.showMachineries}
          yieldActive={yieldActive}
          yieldDateRange={yieldDateRange}
          mapOptions={mapOptions}
          drawingDisabled={true}
          showDrawing={false}
          markers={[...markers, ...binMarkers, ...tagMarkers]}
          polygons={polygons}
          showMap={showMap}
          showInfoWindow={true}
          popUpType={
            yieldActive || tagsActive
              ? PopupTypes.MarkerDetailPopup
              : PopupTypes.FarmDetailPopup
          }
        />
      </>
    );
  }
}

FarmsMap.propTypes = {
  onLoadMap: PropTypes.func,
  onLatLngChange: PropTypes.func,
  zoom: PropTypes.number,
  mapOptions: PropTypes.any,
  collections: PropTypes.any,
  actions: PropTypes.object,
  showMap: PropTypes.bool,
  currentLatLng: PropTypes.array,
  defaultLocation: PropTypes.array,
};

function mapStateToProps(state) {
  const {
    johnDeere: { machines, checkTokenStatus },
  } = state;
  return {
    machines,
    checkTokenStatus,
  };
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchScannedBarcodes,
      fetchScannedTagBarcodes,
      getLastLocation,
      collectedLastLocation,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FarmsMap);
