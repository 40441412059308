import { actionType } from "../../constants/SprayDiary";
import { actionType as actionTypeTasks } from "../../constants/Spray";
import { actionType as actionTypeWeatherStation } from "../../constants/WeatherStation";
import ObjectId from "bson-objectid";
import { parseResponseValue } from "../../actions/SprayDiary/common";
import { ROWS_TO_SPRAY } from "../../constants/shared";

export const setFullTank = (
  hasMachinery,
  type,
  minRate,
  maxRate,
  rate,
  size,
  conc,
  literPerHectare,
  totalAreasSize
) => {
  if ((!rate && minRate !== maxRate && maxRate) || isNaN(rate || minRate)) {
    return null;
  }

  switch (type) {
    case "PER_100_LITERS": {
      if (hasMachinery) {
        return (((rate || minRate) * size) / 100) * conc;
      } else {
        return null;
      }
    }
    case "PER_HECTARE": {
      if (hasMachinery) {
        return ((rate || minRate) * size) / literPerHectare;
      } else {
        return (rate || minRate) * totalAreasSize;
      }
    }
    default: {
      return null;
    }
  }
};

export const setPartTank = (fullTanks, totalTanksValue) => {
  if (!fullTanks) {
    return null;
  }

  const tankFraction = totalTanksValue - Math.trunc(totalTanksValue);
  return tankFraction * fullTanks;
};

export const calcBlockPlannedLitersValue = (
  size,
  literPerHectare,
  rowsToSpray,
  widthPerRow
) => {
  return (
    size *
    literPerHectare *
    (rowsToSpray === "ALL" ? 1 : 0.5) *
    (widthPerRow / 100)
  );
};

export const calcBlockTanksValue = (
  size,
  literPerHectare,
  machinerySize,
  rowsToSpray,
  widthPerRow
) => {
  return machinerySize && machinerySize > 0
    ? calcBlockPlannedLitersValue(
        size,
        literPerHectare,
        rowsToSpray,
        widthPerRow
      ) / machinerySize
    : null;
};

const updateOriginalTasks = ({ fetchedTasks, payload, forceUpdate }) => {
  return !payload.id || (fetchedTasks[payload.id] && !forceUpdate)
    ? fetchedTasks
    : { ...fetchedTasks, [payload.id]: payload };
};

const computeDependingValues = (oldState, newState) => {
  const hasMachinery = newState.selectedMachinery.id;
  const machinerySize = newState.selectedMachinery.size;

  const areasSizes = [...newState.selectedAreas]
    .map(a => a.size)
    .filter(a => !!parseFloat(a));

  const totalAreasSize = areasSizes.length
    ? areasSizes.reduce((curr, next) => curr + next)
    : 0;

  const {
    literPerHectare,
    chemicalToAdd,
    conc,
    widthPerRow,
    rowsToSpray
  } = newState;
  let fullTanksForChemicalToAdd = chemicalToAdd.rate.rateType
    ? setFullTank(
        hasMachinery,
        chemicalToAdd.rate.rateType,
        chemicalToAdd.rate.minRate,
        chemicalToAdd.rate.maxRate,
        chemicalToAdd.rate.rate,
        machinerySize,
        conc,
        literPerHectare,
        totalAreasSize
      )
    : null;
  let totalTanksValue = calcBlockTanksValue(
    totalAreasSize,
    literPerHectare,
    machinerySize,
    rowsToSpray,
    widthPerRow
  );
  let totalPlannedLiters = calcBlockPlannedLitersValue(
    totalAreasSize,
    literPerHectare,
    rowsToSpray,
    widthPerRow
  );
  const computedSate = {
    ...newState,
    selectedAreas: newState.selectedAreas.map(area => ({
      ...area,
      plannedLiters: calcBlockPlannedLitersValue(
        area.size,
        newState.literPerHectare,
        rowsToSpray,
        widthPerRow
      ),
      tanks: calcBlockTanksValue(
        area.size,
        newState.literPerHectare,
        machinerySize,
        rowsToSpray,
        widthPerRow
      )
    })),
    totalPlannedLiters,
    totalTanks: totalTanksValue,
    chemicalToAdd: {
      ...chemicalToAdd,
      fullTanks: fullTanksForChemicalToAdd,
      partTanks: hasMachinery
        ? setPartTank(fullTanksForChemicalToAdd, totalTanksValue)
        : null
    },
    selectedChemicals: newState.selectedChemicals.map(chemical => {
      let fullTanks = chemical.rate.rateType
        ? setFullTank(
            hasMachinery,
            chemical.rate.rateType,
            chemical.rate.minRate,
            chemical.rate.maxRate,
            chemical.rate.rate,
            machinerySize,
            conc,
            literPerHectare,
            totalAreasSize
          )
        : null;
      return {
        ...chemical,
        fullTanks: fullTanks,
        partTanks: hasMachinery ? setPartTank(fullTanks, totalTanksValue) : null
      };
    }),
    conc:
      hasMachinery && !oldState.selectedMachinery.id
        ? newState.conc || initialState.conc
        : newState.conc
  };
  if (newState.id) {
    delete computedSate._id;
  }
  return computedSate;
};

const initialChemicalToAdd = {
  toAdd: true,
  rate: {},
  rates: [],
  new: true,
  dateOfManufacture: null,
  fullTanks: null,
  partTanks: null
};

const duplicateOptions = [
  {
    key: "Personal",
    text: "Personal",
    value: "Personal"
  },
  {
    key: "Locations",
    text: "Locations",
    value: "Locations"
  },
  {
    key: "Machinery",
    text: "Machinery",
    value: "Machinery"
  },
  {
    key: "Chemicals",
    text: "Chemicals",
    value: "Chemicals"
  }
];

const initialState = {
  activeFilter: {},
  id: null,
  _id: null,
  originalTask: {},
  fetchedTasks: {},
  saving: false,
  isFetching: false,
  plannedDate: null,
  expirationDate: null,
  workingTimes: [],
  rowsToSpray: "ALL",
  actualRowsToSpray: "",
  windStrength: null,
  windDirection: null,
  actualRowsToSprayChecked: true,
  status: "OPEN",
  isUploadingAttachment: false,
  attachments: [],
  error: null,
  temperature: "",
  deltaT: null,
  growthStage: "",
  employee: {
    content: [],
    error: null,
    isFetching: false
  },
  areasList: {
    content: [],
    blocks: [],
    patches: [],
    isFetching: false,
    error: null
  },
  selectedAreas: [],
  selectedBlocks: [],
  widthPerRow: 100,
  literPerHectare: "",
  machineryList: {
    isFetching: false,
    error: null,
    content: []
  },
  selectedMachinery: {},
  conc: 1,
  machinerySpeed: "",
  nozzle: "",
  nozzlesPerSide: "",
  gear: "",
  rpm: "",
  pressure: "",
  pto: "",
  chemicalsTypes: {
    isFetching: false,
    data: [],
    error: null
  },
  dilutionRateTypes: {
    PER_100_LITERS: "100L",
    PER_HECTARE: "Ha"
  },
  periodUnits: {
    DAYS: "Days",
    WEEKS: "Weeks",
    MONTHS: "Months"
  },
  chemicalsList: { isFetching: false, content: [], error: null },
  selectedChemicals: [],
  chemicalToAdd: { ...initialChemicalToAdd },
  supervisors: [],
  assignees: [],
  interventionType: null,
  shedId: null,
  applicationType: "SPRAY",
  creator: {},
  comment: "",
  taskCreatedAt: null,
  taskNames: {
    isFetching: false,
    error: null,
    content: []
  },
  taskName: null,
  seen: null,
  seenAt: null,
  completedAt: null,
  inProgressAt: null,
  totalTanks: null,
  actualTotalTanks: null,
  ppeWorn: null,
  validations: {
    creator: true,
    plannedDate: true,
    supervisors: true,
    selectedMachinery: true,
    selectedChemicals: true,
    selectedAreas: true,
    widthPerRow: true,
    literPerHectare: true,
    machinerySpeed: true,
    nozzle: true,
    nozzlesPerSide: true,
    gear: true,
    rpm: true,
    pressure: true,
    pto: true,
    conc: true,
    windDirection: true,
    windStrength: true,
    temperature: true,
    workingTimes: true,
    addChemicalSelection: true,
    ppeWorn: true
  },
  areasToAdd: {
    block: null,
    patch: null
  },
  strenghts: [
    {
      value: "WS_LESS_THEN_2",
      text: "<2"
    },
    {
      value: "WS_2_TO_5",
      text: "2-5"
    },
    {
      value: "WS_5_TO_12",
      text: "5-12"
    },
    {
      value: "WS_12_TO_20",
      text: "12-20"
    },
    {
      value: "WS_20_TO_30",
      text: "20-30"
    },
    {
      value: "WS_39_TO_49",
      text: "30-49"
    }
  ],
  rowsToSprayOptions: Object.entries(ROWS_TO_SPRAY).map(([key, value]) => ({
    key,
    text: value,
    value: key
  })),
  duplicateOptions: duplicateOptions,
  duplicateSelectedItems: [...duplicateOptions],
  directions: ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
  addChemicalSelection: false,
  filters: {
    farms: [],
    varieties: [],
    parentBlock: [],
    ageFrom: "",
    ageTo: "",
    showArchived: false
  },
  addButtonAttentionAnimationActivation: false,
  activeDimmer: false,
  isFetchingWeatherCondition: false,
  weatherDataDateTime: null
};

const employee = (state, action) => {
  switch (action.type) {
    case actionType.GET_EMPLOYEE_LIST_START: {
      return {
        ...state,
        employee: { ...state.employee, error: null, isFetching: true }
      };
    }
    case actionType.GET_EMPLOYEE_LIST_SUCCESS: {
      return {
        ...state,
        employee: { ...action.payload, isFetching: false }
      };
    }
    case actionType.GET_EMPLOYEE_LIST_ERROR: {
      return {
        ...state,
        employee: {
          ...state.employee,
          error: action.payload,
          isFetching: false
        }
      };
    }

    case actionType.SELECT_EMPLOYEES: {
      const field = action.payload.fieldName;
      const gotEmployees = state[field];
      const newEmployees = [
        ...action.payload.employees.map(item => {
          const gotEmployee = gotEmployees.find(
            employee => employee.employee.id === item
          );
          return {
            ...(gotEmployee || {}),
            employee: {
              ...state.employee.content.find(employee => employee.id === item)
            }
          };
        })
      ];

      return {
        ...state,
        [field]: newEmployees,
        validations: { ...state.validations, [field]: true }
      };
    }
    case actionType.SELECT_SINGLE_EMPLOYEE: {
      const field = action.payload.fieldName;
      return {
        ...state,
        [field]: {
          ...state.employee.content.find(
            employee => employee.id === action.payload.employee
          )
        },
        validations: { ...state.validations, [field]: true }
      };
    }
    default: {
      return state;
    }
  }
};

const areas = (state, action) => {
  switch (action.type) {
    case actionType.GET_AREAS_LIST: {
      const { block: blockToAdd, patch: patchToAdd } = state.areasToAdd;
      const { areas } = action.payload;
      const selectedAreas = [];
      const patches = areas.filter(b => b.type === "PATCH");
      const formattedBlocks = areas
        .filter(b => !b.hasSubAreas && b.type === "BLOCK")
        .map(b => ({
          ...b,
          key: b.id,
          type: "block"
        }));
      const formattedPatches = patches.map(patch => ({
        ...patch,
        key: `patch${patch.id}`,
        name: `${patch.parent ? `${patch.parent.name} -` : ""}${patch.name}`,
        type: "patch"
      }));

      if (blockToAdd) {
        selectedAreas.push(formattedBlocks.find(b => b.id === blockToAdd));
      }
      if (patchToAdd) {
        selectedAreas.push(formattedPatches.find(b => b.id === patchToAdd));
      }

      return {
        ...state,
        areasList: {
          content: [...formattedBlocks, ...formattedPatches],
          blocks: areas.filter(b => b.type === "BLOCK"),
          patches,
          loaded: true
        },
        selectedAreas: [...state.selectedAreas, ...selectedAreas.filter(a => a)]
      };
    }
    case actionType.CHANGE_AREAS: {
      const newAreas = [
        ...action.payload.map(item => {
          const itemInSelectedAreas = state.selectedAreas.find(
            area => area.id === item
          );
          return {
            ...(itemInSelectedAreas
              ? itemInSelectedAreas
              : state.areasList.content.find(area => area.id === item))
          };
        })
      ];
      return computeDependingValues(state, {
        ...state,
        selectedAreas: newAreas,
        validations: {
          ...state.validations,
          selectedAreas: !!newAreas.length
        }
      });
    }

    case actionType.SET_WIDTH_PER_ROW: {
      let widthPerRow = 0;

      if (action.payload === "") {
        widthPerRow = "";
      } else if (+action.payload > 0) {
        if (+action.payload > 100) {
          widthPerRow = 100;
        } else {
          widthPerRow = action.payload;
        }
      }

      return computeDependingValues(state, {
        ...state,
        widthPerRow: widthPerRow,
        validations: {
          ...state.validations,
          widthPerRow: true
        }
      });
    }
    case actionType.SET_L_PER_HA: {
      return computeDependingValues(state, {
        ...state,
        literPerHectare: action.payload,
        validations: { ...state.validations, literPerHectare: true }
      });
    }

    case actionType.SET_AREA_DONE_STATE: {
      const selectedAreas = [
        ...state.selectedAreas.map(area =>
          area.id == action.payload
            ? {
                ...area,
                done: !area.done,
                actualTanks: area.done ? area.tanks : area.actualTanks
              }
            : { ...area }
        )
      ];
      const isAllDone = selectedAreas.every(a => a.done);

      return {
        ...state,
        selectedAreas,
        status: isAllDone ? state.status : "IN_PROGRESS",
        actualRowsToSpray: isAllDone
          ? state.rowsToSpray
          : state.actualRowsToSpray
      };
    }
    case actionType.SET_AREAS_DONE_STATE: {
      const { selectedAreas } = state;

      const selectedAreasNew = selectedAreas.map(area => {
        if (!action.payload.areas.includes(area.id)) return area;
        return {
          ...area,
          done: !action.payload.isDone,
          actualTanks: area.done ? area.tanks : area.actualTanks
        };
      });
      const isAllDone = selectedAreasNew.every(a => a.done);

      return {
        ...state,
        selectedAreas: selectedAreasNew,
        status: isAllDone ? state.status : "IN_PROGRESS",
        actualRowsToSpray: isAllDone
          ? state.rowsToSpray
          : state.actualRowsToSpray
      };
    }
    case actionType.SET_ALL_AREAS_DONE_STATE: {
      return {
        ...state,
        selectedAreas: state.selectedAreas.map(area => {
          return {
            ...area,
            done: action.isAllDone,
            actualTanks: area.done ? area.tanks : area.actualTanks
          };
        }),
        status: action.isAllDone ? state.status : "IN_PROGRESS",
        actualRowsToSpray: action.isAllDone
          ? state.rowsToSpray
          : state.actualRowsToSpray
      };
    }
    case actionType.SET_AREA_VALUE: {
      return {
        ...state,
        selectedAreas: [
          ...state.selectedAreas.map(area =>
            area.id === action.payload.id
              ? { ...area, ...action.payload.value }
              : { ...area }
          )
        ]
      };
    }
    case actionType.UPDATE_AREAS_LIST: {
      return {
        ...state,
        selectedAreas: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

const machinery = (state, action) => {
  switch (action.type) {
    case actionType.GET_MACHINERY_LIST_START: {
      return {
        ...state,
        machineryList: {
          ...state.machineryList,
          error: null,
          isFetching: true
        }
      };
    }
    case actionType.GET_MACHINERY_LIST: {
      return {
        ...state,
        machineryList: {
          ...state.machineryList,
          ...action.payload,
          isFetching: false
        }
      };
    }
    case actionType.GET_MACHINERY_LIST_ERROR: {
      return {
        ...state,
        machineryList: {
          ...state.machineryList,
          error: action.payload,
          isFetching: false
        }
      };
    }
    case actionType.CLEAR_MACHINERY: {
      return computeDependingValues(state, {
        ...state,
        selectedMachinery: {},
        validations: { ...state.validations, selectedMachinery: false }
      });
    }
    case actionType.SELECT_MACHINERY: {
      return computeDependingValues(state, {
        ...state,
        selectedMachinery: {
          ...state.selectedMachinery,
          ...state.machineryList.content.find(m => m.id === action.payload)
        },
        validations: { ...state.validations, selectedMachinery: true }
      });
    }

    case actionType.ADD_AND_SELECT_MACHINERY: {
      return computeDependingValues(state, {
        ...state,
        machineryList: {
          ...state.machineryList,
          content: [...state.machineryList.content, action.payload]
        },
        selectedMachinery: {
          ...state.selectedMachinery,
          ...action.payload
        },
        validations: { ...state.validations, selectedMachinery: true }
      });
    }
    case actionType.SET_MACHINERY_CONC: {
      return computeDependingValues(state, {
        ...state,
        conc: action.payload,
        validations: { ...state.validations, conc: true }
      });
    }
    case actionType.SET_MACHINERY_SPEED: {
      return {
        ...state,
        machinerySpeed: action.payload,
        validations: { ...state.validations, machinerySpeed: true }
      };
    }
    case actionType.SET_NOZZLE: {
      return {
        ...state,
        nozzle: action.payload,
        validations: { ...state.validations, nozzle: true }
      };
    }
    case actionType.SET_NOZZLES_PER_SIDE: {
      return {
        ...state,
        nozzlesPerSide: action.payload,
        validations: { ...state.validations, nozzlesPerSide: true }
      };
    }
    case actionType.SET_GEAR: {
      return {
        ...state,
        gear: action.payload,
        validations: { ...state.validations, gear: true }
      };
    }
    case actionType.SET_RPM: {
      return {
        ...state,
        rpm: action.payload,
        validations: { ...state.validations, rpm: true }
      };
    }
    case actionType.SET_PRESSURE: {
      return {
        ...state,
        pressure: action.payload,
        validations: { ...state.validations, pressure: true }
      };
    }
    case actionType.SET_PTO: {
      return {
        ...state,
        pto: action.payload,
        validations: { ...state.validations, pto: true }
      };
    }
    default: {
      return state;
    }
  }
};

const chemicals = (state, action) => {
  switch (action.type) {
    case actionType.ADD_NEW_CHEMICAL_START:
      return {
        ...state,
        chemicalsList: {
          ...state.chemicalsList,
          isFetching: true,
          error: null
        }
      };
    case actionType.ADD_NEW_CHEMICAL:
      return {
        ...state,
        chemicalsList: {
          ...state.chemicalsList,
          content: [...state.chemicalsList.content, action.payload],
          isFetching: false
        }
      };

    case actionType.ADD_NEW_CHEMICAL_FAIL:
      return {
        ...state,
        chemicalsList: {
          ...state.chemicalsList,
          error: action.payload,
          isFetching: false
        }
      };
    case actionType.GET_CHEMICALS_LIST_START: {
      return {
        ...state,
        chemicalsList: {
          ...state.chemicalsList,
          isFetching: true
        }
      };
    }
    case actionType.GET_CHEMICALS_LIST_SUCCESS: {
      return {
        ...state,
        chemicalsList: { ...action.payload, isFetching: false }
      };
    }

    case actionType.GET_CHEMICALS_LIST_ERROR: {
      return {
        ...state,
        chemicalsList: {
          ...state.chemicalsList,
          isFetching: false,
          error: action.payload
        }
      };
    }
    case actionType.ADD_CHEMICAL_ITEM: {
      const selectedChemicals =
        action.payload.id === "new" && !action.payload.targetChemicalId
          ? [
              ...state.selectedChemicals,
              { ...state.chemicalToAdd, toAdd: false, new: false }
            ]
          : action.payload.targetChemicalId
          ? [
              ...state.selectedChemicals.map(chemical =>
                chemical.id === action.payload.targetChemicalId
                  ? {
                      ...chemical,
                      ...state.chemicalToAdd,
                      toAdd: false,
                      new: false
                    }
                  : chemical
              )
            ]
          : [
              ...state.selectedChemicals.map(chemical =>
                chemical.id === action.payload.id
                  ? {
                      ...chemical,
                      editState: null,
                      toAdd: false,
                      new: false
                    }
                  : chemical
              )
            ];
      let chemicalPosition = 0;
      return {
        ...state,
        selectedChemicals: selectedChemicals.map(chemical => ({
          ...chemical,
          position: chemicalPosition++
        })),

        chemicalToAdd:
          action.payload.id === "new"
            ? initialChemicalToAdd
            : state.chemicalToAdd
      };
    }
    case actionType.ON_EDIT_CHEMICAL_ITEM: {
      return {
        ...state,
        selectedChemicals: [
          ...state.selectedChemicals.map(chemical =>
            chemical.id === action.payload
              ? { ...chemical, toAdd: true, editState: chemical }
              : { ...chemical }
          )
        ]
      };
    }
    case actionType.DELETE_CHEMICAL_ITEM: {
      return {
        ...state,
        selectedChemicals: [
          ...state.selectedChemicals.filter(
            chemical => chemical.id !== action.payload
          )
        ]
      };
    }
    case actionType.SET_CHEMICAL_FROM_LIST: {
      const { id, chemicalId, dataFrom } = action.payload;
      const selectedChemical = state.chemicalsList.content.find(
        ch => ch.id === chemicalId
      );

      const fromChemical = dataFrom
        ? state.selectedChemicals.find(ch => ch.id === dataFrom)
        : null;
      return {
        ...state,
        chemicalToAdd:
          id === "new"
            ? {
                ...initialChemicalToAdd,
                ...(fromChemical
                  ? {
                      ...fromChemical
                    }
                  : {}),
                ...selectedChemical
              }
            : { ...state.chemicalToAdd },
        selectedChemicals:
          id === "new"
            ? [...state.selectedChemicals]
            : [
                ...state.selectedChemicals.map(ch =>
                  ch.id === id
                    ? {
                        ...initialChemicalToAdd,
                        ...ch,
                        ...selectedChemical,
                        new: false,
                        rate: {},
                        fullTanks: null,
                        partTanks: null
                      }
                    : { ...ch }
                )
              ],
        validations: { ...state.validations, selectedChemicals: true }
      };
    }

    case actionType.SET_CHEMICAL_ITEM_TO_ADD: {
      const { id, item } = action.payload;
      return computeDependingValues(state, {
        ...state,
        chemicalToAdd: !item
          ? { ...initialChemicalToAdd }
          : item.new
          ? { ...state.chemicalToAdd, ...item }
          : state.chemicalToAdd,
        selectedChemicals:
          item && !item.new
            ? [...state.selectedChemicals].map(ch => {
                return ch.id === id ? { ...ch, ...item } : { ...ch };
              })
            : state.selectedChemicals
      });
    }

    case actionType.DECLINE_EDITING_CHEMICAL_ITEM: {
      if (action.payload === "new")
        return {
          ...state,
          chemicalToAdd: initialChemicalToAdd
        };

      return {
        ...state,
        selectedChemicals: [
          ...state.selectedChemicals.map(chemical =>
            chemical.id === action.payload
              ? {
                  ...chemical.editState,
                  editState: null
                }
              : { ...chemical }
          )
        ]
      };
    }
    case actionType.ADD_CHEMICAL_SELECTION: {
      return {
        ...state,
        addChemicalSelection: action.payload,
        validations: {
          ...state.validations,
          addChemicalSelection: true
        }
      };
    }

    case actionType.SET_SELECTED_CHEMICALS: {
      return {
        ...state,
        selectedChemicals: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
        selectedAreas:
          action.payload === "COMPLETED"
            ? [...state.selectedAreas.map(area => ({ ...area, done: true }))]
            : action.payload === "OPEN"
            ? [...state.selectedAreas.map(area => ({ ...area, done: false }))]
            : state.selectedAreas,
        donePercent: 100,
        workingFrom:
          !state.startWorkingTime && action.payload === "COMPLETED"
            ? "00:00:00"
            : state.workingFrom,
        workingTo:
          !state.workingTo && action.payload === "COMPLETED"
            ? "00:00:00"
            : state.workingTo,
        actualRowsToSpray: !state.actualRowsToSpray
          ? state.rowsToSpray
          : state.actualRowsToSpray
      };
    }
    case actionType.SET_WIND_DIRECTION: {
      return {
        ...state,
        windDirection: action.payload,
        validations: { ...state.validations, windDirection: true }
      };
    }
    case actionType.SET_ROWS_TO_SPRAY: {
      return computeDependingValues(state, {
        ...state,
        rowsToSpray: action.payload
      });
    }

    case actionType.SET_ACTUALL_ROWS_TO_SPRAY: {
      return {
        ...state,
        actualRowsToSpray: action.payload
      };
    }
    case actionType.SET_ACTUALL_ROWS_TO_SPRAY_CHECK_STATE: {
      const actualRowsToSprayChecked =
        action.payload || !state.actualRowsToSprayChecked;
      return {
        ...state,
        actualRowsToSprayChecked,
        actualRowsToSpray: state.rowsToSpray,
        validations: {
          ...state.validations
        }
      };
    }
    case actionType.SET_SPRAY_DATE: {
      return {
        ...state,
        plannedDate: action.payload.date,
        validations: {
          ...state.validations,
          plannedDate: true
        }
      };
    }
    case actionType.SET_SPRAY_EXPIRY_DATE: {
      return {
        ...state,
        expirationDate: action.payload.expirationDate
      };
    }
    case actionType.SET_WORKING_TIMES: {
      return {
        ...state,
        workingTimes: action.payload
      };
    }

    case actionType.SET_WIND_SPEED: {
      return {
        ...state,
        windStrength: action.payload,
        validations: { ...state.validations, windStrength: true }
      };
    }
    case actionType.SET_TEMPERATURE: {
      return {
        ...state,
        temperature: action.payload,
        validations: { ...state.validations, temperature: true }
      };
    }
    case actionType.SET_DELTA_T: {
      return {
        ...state,
        deltaT: action.payload
      };
    }
    case actionType.SET_GROWTH_STAGE: {
      return {
        ...state,
        growthStage: action.payload
      };
    }
    case actionTypeWeatherStation.WEATHER_STATION_GET_TASK_WEATHER_CONDITION_START: {
      return {
        ...state,
        isFetchingWeatherCondition: true
      };
    }
    case actionTypeWeatherStation.WEATHER_STATION_GET_TASK_WEATHER_CONDITION: {
      return {
        ...state,
        isFetchingWeatherCondition: false,
        weatherDataDateTime: action.payload.dataDateTime,
        temperature: action.payload.temperature || initialState.temperature,
        deltaT: action.payload.deltaT || initialState.deltaT,
        windDirection: action.payload.windDirection,
        windStrength: action.payload.windStrength,
        validations: {
          ...state.validations,
          temperature: true,
          windDirection: true,
          windStrength: true
        }
      };
    }
    case actionTypeWeatherStation.WEATHER_STATION_GET_TASK_WEATHER_CONDITION_FAIL: {
      return {
        ...state,
        isFetchingWeatherCondition: false,
        error: action.payload
      };
    }
    case actionType.SET_INTERVENTION_TYPE: {
      return {
        ...state,
        interventionType: action.payload
      };
    }
    case actionType.SET_SHED: {
      return {
        ...state,
        shedId: action.payload
      };
    }
    case actionType.SET_APPLICATION_TYPE: {
      return {
        ...state,
        applicationType: action.payload
      };
    }
    case actionType.SET_TASK_NAME: {
      return {
        ...state,
        taskName: action.payload
      };
    }
    case actionType.GET_TASK_NAME_LIST_START: {
      return {
        ...state,
        taskNames: { ...state.taskNames, isFetching: true, error: null }
      };
    }
    case actionType.GET_TASK_NAME_LIST_SUCCESS: {
      return {
        ...state,
        taskNames: {
          ...state.taskNames,
          isFetching: false,
          content: action.payload.content
        }
      };
    }
    case actionType.GET_TASK_NAME_LIST_FAIL: {
      return {
        ...state,
        error: action.payload,
        taskNames: { ...state.taskNames, isFetching: false }
      };
    }
    case actionType.SET_COMMENTS: {
      return {
        ...state,
        comment: action.payload
      };
    }
    case actionType.SET_PPE_WORN: {
      return {
        ...state,
        ppeWorn: action.payload
      };
    }
    case actionType.GET_TASK_BY_ID: {
      return {
        ...state,
        isFetching: true
      };
    }
    case actionType.GET_TASK_BY_ID_SUCCESS: {
      return computeDependingValues(state, {
        ...state,
        ...action.payload,
        originalTask: { ...action.payload },
        fetchedTasks: updateOriginalTasks({
          fetchedTasks: state.fetchedTasks,
          payload: action.payload,
          forceUpdate: action.meta && action.meta.fetched
        }),
        isFetching: false
      });
    }
    case actionType.GET_TASK_BY_ID_ERROR: {
      return {
        ...state,
        isFetching: false
      };
    }
    case actionType.CHANGE_DUPLICATE_SELECTION: {
      return {
        ...state,
        duplicateSelectedItems: action.payload
      };
    }
    case actionType.CLEAR_AND_DUPLICATE: {
      return computeDependingValues(initialState, {
        ...initialState,
        ...action.payload,
        employee: state.employee,
        areasList: state.areasList,
        machineryList: state.machineryList,
        chemicalsList: state.chemicalsList,
        taskName: state.taskName,
        _id: ObjectId().toHexString()
      });
    }
    case actionType.CLEAR_CREATOR: {
      return {
        ...initialState,
        employee: state.employee,
        taskNames: state.taskNames,
        areasList: state.areasList,
        machineryList: state.machineryList,
        chemicalsList: state.chemicalsList,
        fetchedTasks: state.fetchedTasks,
        _id: ObjectId().toHexString(),
        filters: state.filters
      };
    }
    case actionType.CHANGE_CHEMICAL_BATCHNUMBERS_LIST: {
      return {
        ...state,
        selectedChemicals: state.selectedChemicals.map(chemical => {
          if (chemical.id === action.payload.id) {
            return {
              ...chemical,
              batchNumbers: action.payload.batchNumbers
            };
          }
          return chemical;
        })
      };
    }
    case actionType.PREFILL_CREATOR: {
      const { machinery: selectedMachinery, areas, chemicals } = action.payload;

      const selectedAreas = areas.map(area => ({
        ...area.area,
        ...area
      }));

      const selectedChemicals = chemicals.map(chemical => ({
        ...chemical,
        ...chemical.chemical,
        rate: chemical.chemicalRate
      }));

      return {
        ...state,
        selectedAreas: selectedAreas || [],
        selectedMachinery: selectedMachinery || {},
        selectedChemicals: selectedChemicals || [],
        _id: ObjectId().toHexString()
      };
    }
    case actionType.SET_VALIDATIONS: {
      return {
        ...state,
        validations: action.payload,
        addButtonAttentionAnimationActivation:
          !action.payload.selectedChemicals ||
          !action.payload.addChemicalSelection
      };
    }
    case actionType.SET_AREAS_TO_ADD: {
      return {
        ...state,
        areasToAdd: action.payload
      };
    }
    case actionType.UPLOAD_TASK_ATTACHMENT_START: {
      const { idReplace, name, data } = action.payload;
      return {
        ...state,
        attachments: [...state.attachments, { idReplace, name, data }],
        isUploadingAttachment: true,
        error: null
      };
    }

    case actionType.UPLOAD_TASK_ATTACHMENT: {
      const { id, subPath, name, idReplace, data } = action.payload;

      const indexToReplace = state.attachments.findIndex(
        item => item.idReplace === idReplace
      );
      if (indexToReplace >= 0) {
        state.attachments.splice(indexToReplace, 1, {
          id,
          subPath,
          name,
          data
        });
      }
      return {
        ...state,
        isUploadingAttachment: false,
        attachments: [...state.attachments]
      };
    }

    case actionType.UPLOAD_TASK_ATTACHMENT_ERROR: {
      //eslint-disable-next-line
      return {
        ...state,
        attachments: state.attachments.filter(
          file => file.idReplace !== action.meta.idReplace
        ),
        isUploadingAttachment: false,
        error: {
          ...action.payload,
          message: `Error uploading file: ${action.meta.name}`
        }
      };
    }
    case actionType.SET_ATTACHMENTS: {
      return {
        ...state,
        attachments: action.payload.attachments
      };
    }
    case actionType.SET_FIELD: {
      return {
        ...state,
        [action.payload.fieldName]: action.payload.fieldValue
      };
    }
    case actionType.SET_NESTED_FIELD: {
      return action.payload.parent
        ? {
            ...state,
            [action.payload.parent]: {
              ...state[action.payload.parent],
              [action.payload.fieldName]: action.payload.fieldValue
            }
          }
        : state;
    }
    case actionType.CREATE_TASK_START: {
      return {
        ...state,
        saving: true,
        error: null
      };
    }
    case actionType.CREATE_TASK_SUCCESS: {
      return {
        ...state,
        ...(action.payload || {}),
        originalTask: {
          ...(action.payload || {})
        },
        fetchedTasks: updateOriginalTasks({
          fetchedTasks: state.fetchedTasks,
          payload: action.payload,
          forceUpdate: true
        }),
        saving: false
      };
    }
    case actionType.CREATE_TASK_ERROR: {
      return {
        ...state,
        saving: false,
        error: action.payload
      };
    }
    case actionType.RESTORE_BACKUP: {
      return {
        ...state,
        ...state.originalTask
      };
    }
    case actionTypeTasks.SET_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilter: action.payload
      };

    case actionTypeTasks.GET_SPRAY_TASKS:
    case actionType.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    case actionType.SET_AREAS_LIST_LOADING: {
      return {
        ...state,
        areasList: {
          ...state.areasList,
          isFetching: action.payload
        }
      };
    }
    case actionType.GET_EMPLOYEE_LIST_START:
    case actionType.GET_EMPLOYEE_LIST_SUCCESS:
    case actionType.GET_EMPLOYEE_LIST_ERROR:
    case actionType.SELECT_EMPLOYEES:
    case actionType.SELECT_SINGLE_EMPLOYEE:
      return employee(state, action);

    case actionType.GET_MACHINERY_LIST_START:
    case actionType.GET_MACHINERY_LIST:
    case actionType.SELECT_MACHINERY:
    case actionType.CLEAR_MACHINERY:
    case actionType.ADD_AND_SELECT_MACHINERY:
    case actionType.SET_MACHINERY_CONC:
    case actionType.GET_MACHINERY_LIST_ERROR:
    case actionType.SET_MACHINERY_SPEED:
    case actionType.SET_NOZZLE:
    case actionType.SET_NOZZLES_PER_SIDE:
    case actionType.SET_GEAR:
    case actionType.SET_RPM:
    case actionType.SET_PRESSURE:
    case actionType.SET_PTO:
      return machinery(state, action);

    case actionType.GET_AREAS_LIST:
    case actionType.SET_WIDTH_PER_ROW:
    case actionType.CHANGE_AREAS:
    case actionType.SET_VIEW_MODE:
    case actionType.SET_L_PER_HA:
    case actionType.SET_AREA_DONE_STATE:
    case actionType.SET_AREAS_DONE_STATE:
    case actionType.SET_ALL_AREAS_DONE_STATE:
    case actionType.SET_AREA_VALUE:
    case actionType.UPDATE_AREAS_LIST:
      return areas(state, action);
    case actionType.GET_CHEMICALS_LIST_START:
    case actionType.GET_CHEMICALS_LIST_SUCCESS:
    case actionType.GET_CHEMICALS_LIST_ERROR:
    case actionType.SET_CHEMICAL_FROM_LIST:
    case actionType.SET_CHEMICAL_ITEM_TO_ADD:
    case actionType.ADD_CHEMICAL_ITEM:
    case actionType.ON_EDIT_CHEMICAL_ITEM:
    case actionType.DELETE_CHEMICAL_ITEM:
    case actionType.DECLINE_EDITING_CHEMICAL_ITEM:
    case actionType.ADD_NEW_CHEMICAL_START:
    case actionType.ADD_NEW_CHEMICAL:
    case actionType.ADD_NEW_CHEMICAL_FAIL:
    case actionType.ADD_CHEMICAL_SELECTION:
    case actionType.SET_SELECTED_CHEMICALS:
      return chemicals(state, action);
    default: {
      return state;
    }
  }
};
