import moment from "moment";
import React from "react";
import { Label } from "semantic-ui-react";

export const StatusChip = ({ approved }) => {
  const localTime = moment().format("YYYY-MM-DDTHH:mm:ss");
  return (
    <Label
      as="a"
      style={{
        width: "110px",
        height: "16px",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: approved ? "#9DACBA" : "#F0AC47",
        color: "#FFFFFF",
        borderRadius: 20,
        padding: "4px 8px",
        paddingLeft: "0px",
        fontSize: "10px"
      }}
    >
      {approved ? "Approved" : "For approve"}
    </Label>
  );
};
