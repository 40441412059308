import React from "react";
import { useSelector } from "react-redux";
import { Icon, Form, Dropdown } from "semantic-ui-react";

import { assigneeFilterOptions } from "selectors/harvestUnits";
import { machineryFilterOptions } from "selectors/generalTasks";

import styles from "./CompletionModal.module.css";

const ComboForm = ({
  assigneeIndex,
  assignee,
  assigneeRows,
  handleFieldChange,
  error
}) => {
  const assigneeOptions = useSelector(assigneeFilterOptions).options.map(
    item => ({
      key: item.key,
      text: item.label,
      value: item.value
    })
  );

  const machineryOptions = useSelector(machineryFilterOptions).options.map(
    item => ({
      key: item.key,
      text: item.label,
      value: item.value
    })
  );

  return (
    <>
      <div className={styles.assigneeWrapper}>
        <div className={styles.assigneeHeader}>
          Assignee {assigneeIndex + 1}
          <Icon
            name="trash alternate"
            className={styles.deleteBtn}
            disabled={assigneeRows.length < 2}
            onClick={() => {
              handleFieldChange(
                assigneeRows.filter((_, index) => assigneeIndex !== index)
              );
            }}
          />
        </div>
        <div className={styles.comboContainer}>
          <Form.Group style={{ gap: "40px" }}>
            <Form.Field width={8}>
              <Dropdown
                placeholder="Select Assignee"
                search
                selection
                value={assignee.assignee}
                options={assigneeOptions}
                onChange={async (_, { value }) =>
                  handleFieldChange(value, "assignee")
                }
              />
            </Form.Field>
            <Form.Field width={8}>
              <Dropdown
                placeholder="Select Machinery"
                search
                selection
                clearable
                value={assignee.machinery}
                options={machineryOptions}
                onChange={(_, { value }) => {
                  handleFieldChange(value, "machinery");
                }}
              />
            </Form.Field>
          </Form.Group>
          {error && (
            <div
              style={{
                width: "100%",
                marginTop: "16px",
                marginBottom: "26px",
                color: "#8C0D24"
              }}
            >
              {error}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ComboForm;
