import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import LayoutOverlay from "components/LayoutOverlay";
import Loader from "components/Loader";
import { fetchScoutingByID, updateScouting } from "actions/Scouting/scouting";
import { history } from "store";
import EditScoutingForm from "./EditScoutingForm";
import { actionType } from "../../../constants/Scouting";
import { infoMessageType, messagesIds, putMessage } from "actions/InfoMessages";
import { getParentPageHref } from "utils/routeHelpers";
import styles from "./styles.module.css";

const UpdateScouting = ({ route, match, navigate }) => {
  const {
    params: { id },
  } = match;
  const { currentScouting, isFetching, isUpdating } = useSelector(
    (state) => state.scouting
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchScoutingByID(id));
  }, [id]);
  const { setOn, doneOn, name, comment, areas } = currentScouting || {};

  const doUpdateScouting = async (values) => {
    const dataToSend = {
      ...currentScouting,
      ...values,
    };

    await dispatch(updateScouting(currentScouting.id, dataToSend));
  };

  const formik = useFormik({
    initialValues: {
      comment,
      setOn,
      doneOn,
      name,
      areas,
    },
    enableReinitialize: true,
    onSubmit: doUpdateScouting,
  });

  useEffect(() => {
    if (isUpdating) {
      return () => {
        dispatch(
          putMessage({
            type: infoMessageType.INFO,
            messageId: messagesIds.GENERIC,
            extraData: {
              message: `SC-${currentScouting.id} was updated!`,
            },
          })
        );
        navigate("/scouting");
      };
    }
  }, [isUpdating]);

  return (
    <LayoutOverlay
      closeLink={"/scouting"}
      route={route}
      footer={
        <div className={styles.buttonsFooter}>
          <Button
            disabled={isUpdating || !formik.dirty || !formik.isValid}
            primary
            size="large"
            onClick={formik.submitForm}
          >
            Save
          </Button>
        </div>
      }
    >
      {isFetching && <Loader />}
      {!isFetching && currentScouting && <EditScoutingForm formik={formik} />}
    </LayoutOverlay>
  );
};

UpdateScouting.propTypes = {
  match: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
};

export default UpdateScouting;
