import { actionType } from "constants/GeneralTasks/costEntering";
import { axiosProxy } from "utils/axiosProxy";
import { omitBy, isNil } from "lodash";
import uuid from "uuid/v4";
let uniqId;
let altUniqId;

const url = "/operating_costs";

export const getCostEntering = (
  { sort, page, search, size, to, from, unpaged = false, filters = [] },
  shedIds
) => async dispatch => {
  await dispatch({
    type: actionType.COST_ENTERING_START,
    payload: shedIds
  });
  try {
    const paramsObject = omitBy(
      {
        sort,
        page,
        search,
        size,
        unpaged,
        to,
        from
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (!Object.fromEntries(filters).costType) {
      filters.push(["costTypes", "OVERHEADS,OTHER_EXPENSES"]);
    }
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? filter !== "from" && filter !== "to"
            ? params.append(filter, value)
            : (filter === "from" && params.append("startAtAfter", value),
              filter === "to" && params.append("startAtBefore", value))
          : (params.append("startAtAfter", `${from}T00:00:00`),
            params.append("startAtBefore", `${to}T23:59:59`));
      });
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.COST_ENTERING,
        payload: res.data
      });
    }
    return res.data;
  } catch (error) {}
};

export const getAltCostEntering = (
  { sort, page, search, size, to, from, unpaged = false, filters = [] },
  shedIds
) => async dispatch => {
  await dispatch({
    type: actionType.COST_ENTERING_START,
    payload: shedIds
  });
  try {
    const paramsObject = omitBy(
      {
        sort,
        page,
        search,
        size,
        unpaged,
        to,
        from
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (!Object.fromEntries(filters).costType) {
      filters.push(["costTypes", "CONTRACTOR,OTHER_WAGES"]);
    }
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? filter !== "from" && filter !== "to"
            ? params.append(filter, value)
            : (filter === "from" && params.append("startAtAfter", value),
              filter === "to" && params.append("startAtBefore", value))
          : (params.append("startAtAfter", `${from}T00:00:00`),
            params.append("startAtBefore", `${to}T23:59:59`));
      });
    }
    altUniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId: altUniqId,
      url
    });
    if (altUniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.COST_ENTERING,
        payload: res.data
      });
    }
    return res.data;
  } catch (error) {}
};

export const costEnteringType = value => async dispatch => {
  dispatch({ type: actionType.COST_ENTERING_TYPE_START });
  try {
    const res = await axiosProxy({
      method: "GET",
      url: `${url}/types`,
      params: { costType: value }
    });

    await dispatch({
      type: actionType.COST_ENTERING_TYPE,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.COST_ENTERING_TYPE_FAIL,
      payload: error
    });
  }
};
export const createCostEntering = values => async dispatch => {
  dispatch({ type: actionType.ADD_COST_ENTERING_START });
  try {
    const res = await axiosProxy({
      method: "POST",
      url,
      data: values
    });

    await dispatch({
      type: actionType.ADD_COST_ENTERING,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.ADD_COST_ENTERING_FAIL,
      payload: error
    });
  }
};
export const createCostEnteringBatch = values => async dispatch => {
  dispatch({ type: actionType.ADD_COST_ENTERING_BATCH_START });
  try {
    const res = await axiosProxy({
      method: "POST",
      url: `${url}/batch`,
      data: values
    });

    await dispatch({
      type: actionType.ADD_COST_ENTERING_BATCH,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.ADD_COST_ENTERING_BATCH_FAIL,
      payload: error
    });
  }
};

export const updateCostEntering = (id, data) => async dispatch => {
  dispatch({
    type: actionType.UPDATE_COST_ENTERING_START
  });
  try {
    const res = await axiosProxy({
      method: "PUT",
      url,
      data
    });

    await dispatch({
      type: actionType.UPDATE_COST_ENTERING,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.UPDATE_COST_ENTERING_FAIL,
      payload: error
    });
  }
};
export const deleteCostEntering = id => async dispatch => {
  try {
    dispatch({
      type: actionType.COST_ENTERING_DELETE_START
    });
    const res = await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`
    });

    dispatch({
      type: actionType.COST_ENTERING_DELETE,
      payload: { id, res }
    });
    return res;
  } catch (error) {
    dispatch({
      type: actionType.COST_ENTERING_DELETE_FAIL,
      payload: error
    });
  }
};

export const getAltCostEnteringList = ({
  unpaged = true,
  filters
}) => async dispatch => {
  await dispatch({
    type: actionType.COST_ENTERING_LIST_START
    // payload: shedIds
  });
  try {
    const paramsObject = omitBy(
      {
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? filter !== "from" && filter !== "to"
            ? params.append(filter, value)
            : (filter === "from" && params.append("startAtAfter", value),
              filter === "to" && params.append("startAtBefore", value))
          : (params.append("startAtAfter", `${from}T00:00:00`),
            params.append("startAtBefore", `${to}T23:59:59`));
      });
    }
    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });
    await dispatch({
      type: actionType.COST_ENTERING_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {}
};
export const getCostEnteringList = ({
  unpaged = true,
  filters
}) => async dispatch => {
  await dispatch({
    type: actionType.COST_ENTERING_LIST_START
    // payload: shedIds
  });
  try {
    const paramsObject = omitBy(
      {
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? filter !== "from" && filter !== "to"
            ? params.append(filter, value)
            : (filter === "from" && params.append("startAtAfter", value),
              filter === "to" && params.append("startAtBefore", value))
          : (params.append("startAtAfter", `${from}T00:00:00`),
            params.append("startAtBefore", `${to}T23:59:59`));
      });
    }
    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });
    await dispatch({
      type: actionType.COST_ENTERING_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {}
};
export const getAllOperatingCost = () => async dispatch => {
  await dispatch({
    type: actionType.COST_ENTERING_LIST_START
  });
  try {
    const paramsObject = omitBy(
      {
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });
    await dispatch({
      type: actionType.COST_ENTERING_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {}
};
