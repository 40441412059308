import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Segment, Grid } from "semantic-ui-react";
import HeaderOverlay from "../HeaderOverlay";
import styles from "./LayoutOverlay.module.css";
import { useNavigate } from "react-router-dom";

function LayoutOverlay({
  closeLink,
  withGoBack,
  route,
  sidebar,
  children,
  extra,
  footer,
  noOverflow,
  childrenOnly,
  gridOptions,
  columnRight,
  className
}) {
  const navigate = useNavigate();
  const columnLeft = {
    mobile: 16,
    tablet: 16,
    computer: 5,
    largeScreen: 4,
    widescreen: 3
  };
  const columnRightSizes = columnRight
    ? columnRight
    : sidebar
    ? { mobile: 16, tablet: 16, computer: 11, largeScreen: 12, widescreen: 13 }
    : { width: 16 };

  return (
    <div
      className={`${styles.overlayHolder} ${
        noOverflow ? styles.noOverflow : ""
      }`}
    >
      {route && (closeLink || withGoBack) && (
        <HeaderOverlay
          navigate={navigate}
          closeLink={closeLink}
          withGoBack={withGoBack}
          route={route}
          footer={footer}
        />
      )}
      <div className={styles.overlayMain}>
        {childrenOnly ? (
          children
        ) : (
          <>
            <Grid className={styles.sprayGrid} {...gridOptions} id="sprayGrid">
              <Grid.Row>
                {sidebar && (
                  <Grid.Column {...columnLeft}>
                    <Segment className={styles.sprayTasksSidebarIn}>
                      {sidebar}
                    </Segment>
                  </Grid.Column>
                )}

                <Grid.Column {...columnRightSizes}>
                  <div
                    className={classNames(styles.sprayFormContainer, className)}
                  >
                    {children}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {footer && (
              <div className={`${styles.sprayFormFooter} show-sm`}>
                {footer}
              </div>
            )}
          </>
        )}
      </div>
      {extra}
    </div>
  );
}

LayoutOverlay.propTypes = {
  route: PropTypes.object,
  closeLink: PropTypes.string,
  withGoBack: PropTypes.bool,
  sidebar: PropTypes.any,
  children: PropTypes.any,
  extra: PropTypes.any,
  footer: PropTypes.any,
  childrenOnly: PropTypes.bool,
  noOverflow: PropTypes.bool,
  gridOptions: PropTypes.object,
  columnRight: PropTypes.object,
  className: PropTypes.string
};

LayoutOverlay.defaultProps = {
  childrenOnly: false,
  gridOptions: {}
};

export default LayoutOverlay;
