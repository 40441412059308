import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { Button, Message } from "semantic-ui-react";
import { Container, Grid, Popup, Segment, Icon } from "semantic-ui-react";
import { partialUpdate } from "actions/Sheds";
import ThresholdForm from "./ThresholdForm";
import { chemicalsOptionsSelector } from "selectors/chemicals";
import { useDispatch, useSelector } from "react-redux";
import ThresholdRow from "./ThresholdRow";
import styles from "./Threshold.module.css";

const Threshold = ({ shed, setNegativeThresholds }) => {
  const dispatch = useDispatch();
  const { values, setFieldValue, validateForm } = useFormikContext();
  const thresholdsList = useSelector(chemicalsOptionsSelector);
  const thresholdsListCheck = shed.thresholds?.length;
  const [addButtonCheck, setAddButtonCheck] = useState(true);
  const [checkNegativeThreshold, setCheckNegativeThreshold] = useState(true);
  const [deleteButtonCheck, setDeleteButtonCheck] = useState(true);

  const handleThresholdAdd = thresholdValues => {
    const path = "thresholds";
    const thresholds = [...values.thresholds, thresholdValues];
    setFieldValue("thresholds", thresholds);
    dispatch(partialUpdate(shed.id, thresholds, path));
    validateForm();
    setAddButtonCheck(true);
  };

  const addProduct = () => {
    setAddButtonCheck(false);
    setDeleteButtonCheck(true);
  };

  const deleteAddingForm = () => {
    setAddButtonCheck(true);
  };
  const negativeThresholdsList = shed.thresholds.filter(item => {
    const product = shed.buckets.find(ch => ch.chemicalId === item.chemicalId);
    return item.threshold > (product?.quantity || 0);
  });
  useEffect(() => {
    return setNegativeThresholds(negativeThresholdsList.length);
  }, [shed.thresholds]);
  return (
    <Segment className={styles.sideBarThreshold}>
      <Grid className={styles.root} columns="equal" as={Container}>
        <Grid.Row className={styles.labelContainer}>
          {/* !!shed.thresholds.length && checkNegativeThreshold && */ shed
            .thresholds.length == 0 && (
            <Message className={styles.warrningMessages} color="yellow">
              <Grid columns="equal" as={Container}>
                <Grid.Row className={styles.iconTextContainer}>
                  <Grid.Column className={styles.iconWrapper} width={1}>
                    <Icon
                      className={styles.warrningMessagesIcon}
                      name="info circle"
                    />
                  </Grid.Column>
                  <Grid.Column
                    className={styles.warrningMessagesContent}
                    width={15}
                  >
                    By adding a minimum stock threshold, you will receive a
                    notification when your stock levels of products fall below
                    this threshold in each shed.
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Message>
          )}
          <Grid.Column className={styles.thresholdProductHeader}>
            Product
          </Grid.Column>
          <Grid.Column className={styles.thresholdHeader}>
            Threshold
            <Popup
              position="right center"
              className={styles.popup}
              trigger={
                <Icon className={styles.infoButton} name="info circle" />
              }
              content={
                <div className={styles.messageContainer}>
                  By adding a minimum stock threshold, you will receive a
                  notification when your stock levels of products fall below
                  this threshold in each shed.
                </div>
              }
              size="small"
              inverted
            />
          </Grid.Column>
          <Grid.Column />
        </Grid.Row>
        {shed.thresholds.map(threshold => (
          <ThresholdRow
            setNegativeThresholds={setNegativeThresholds}
            key={threshold.id}
            setCheckNegativeThreshold={setCheckNegativeThreshold}
            threshold={threshold}
            thresholdsList={thresholdsList}
            buckets={shed.buckets}
            shed={shed}
            shedId={shed.id}
            shedName={shed.name}
          />
        ))}
      </Grid>
      {addButtonCheck && thresholdsListCheck ? (
        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            className="button-text color-green"
            onClick={addProduct}
          >
            + Add threshold
          </Button>
        </div>
      ) : null}

      {(!addButtonCheck || !thresholdsListCheck) && (
        <Segment className={styles.sideBarThresholdForm}>
          <ThresholdForm
            thresholdsListCheck={thresholdsListCheck}
            thresholdsList={thresholdsList}
            deleteAddingForm={deleteAddingForm}
            deleteButtonCheck={deleteButtonCheck}
            onSubmit={handleThresholdAdd}
          />
        </Segment>
      )}
    </Segment>
  );
};

export default Threshold;
