import React, { useState } from "react";
import { Icon, Checkbox } from "semantic-ui-react";
import styles from "../../ClockIn.module.css";

const EmployeeGroup = ({
  employeeGroups,
  values,
  setFieldValue,
  expandAll
}) => {
  const initialExpandedEmployeeGroups = employeeGroups.reduce(
    (expandedEmployeeGroups, group) => {
      if (expandAll) {
        return {
          ...expandedEmployeeGroups,
          [group.id]: true
        };
      } else {
        const match = values.children.filter(({ employeeId }) =>
          group.employeeIds.includes(employeeId)
        );
        return {
          ...expandedEmployeeGroups,
          [group.id]: !!match.length
        };
      }
    },
    {}
  );

  const [expandedEmployeeGroups, setExpandedemployeeGroups] = useState(
    initialExpandedEmployeeGroups
  );

  const toggleEmployeeGroupRow = id => {
    setExpandedemployeeGroups(prevExpandedEmployeeGroups => ({
      ...prevExpandedEmployeeGroups,
      [id]: !prevExpandedEmployeeGroups[id]
    }));
  };

  return (
    <div className={styles.wrapper}>
      {employeeGroups.map(group => {
        const {
          employeesMatchEmployeeGroup,
          employeesNotMatchEmployeeGroup
        } = values.children.reduce(
          (data, employee) => {
            const match = group.employeeIds.includes(employee.employeeId);
            data[
              match
                ? "employeesMatchEmployeeGroup"
                : "employeesNotMatchEmployeeGroup"
            ].push(employee);
            return data;
          },
          {
            employeesMatchEmployeeGroup: [],
            employeesNotMatchEmployeeGroup: []
          }
        );

        return (
          <>
            <div key={group.id} className={styles.groupRow}>
              <div className={styles.checkboxWrapper}>
                {!employeesMatchEmployeeGroup.length ||
                employeesMatchEmployeeGroup.length ===
                  group.employeeIds.length ? (
                  <Checkbox
                    className={styles.checkBox}
                    checked={
                      employeesMatchEmployeeGroup.length ===
                      group.employeeIds.length
                    }
                    onClick={() => {
                      employeesMatchEmployeeGroup.length ===
                      group.employeeIds.length
                        ? setFieldValue(
                            "children",
                            employeesNotMatchEmployeeGroup
                          )
                        : setFieldValue("children", [
                            ...employeesNotMatchEmployeeGroup,
                            ...group.employeeIds.map(id => ({
                              employeeId: id,
                              generalTaskId: values.generalTaskId,
                              areas: values.areas,
                              startAt: values.startAt
                            }))
                          ]);
                    }}
                  />
                ) : (
                  <div
                    className={styles.minusIconWrapper}
                    onClick={() => {
                      setFieldValue("children", employeesNotMatchEmployeeGroup);
                    }}
                  >
                    <Icon name="minus" className={styles.minusIcon} />
                  </div>
                )}

                {`${group.name}`}
              </div>
              <span className={styles.iconRowField}>
                <Icon
                  className={styles.rowChevronIcon}
                  onClick={() => toggleEmployeeGroupRow(group.id)}
                  name={`chevron ${
                    expandedEmployeeGroups[group.id] ? "up" : "down"
                  }`}
                />
              </span>
            </div>
            {expandedEmployeeGroups[group.id] &&
              group.employees.map(employee => (
                <div className={styles.subRows}>
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      className={styles.checkBox}
                      checked={values.children
                        .map(e => e.employeeId)
                        .includes(employee.id)}
                      onClick={() => {
                        values.children
                          .map(e => e.employeeId)
                          .includes(employee.id)
                          ? setFieldValue(
                              "children",
                              values.children.filter(
                                e => e.employeeId !== employee.id
                              )
                            )
                          : setFieldValue("children", [
                              ...values.children,
                              {
                                employeeId: employee.id,
                                generalTaskId: values.generalTaskId,
                                areas: values.areas,
                                startAt: values.startAt
                              }
                            ]);
                      }}
                    />
                    {employee.firstName} {employee.lastName}
                  </div>
                </div>
              ))}
          </>
        );
      })}
    </div>
  );
};

export default EmployeeGroup;
