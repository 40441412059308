import React from "react";
import { Label } from "semantic-ui-react";

export const StatusChip = ({ label, fullWidth = false }) => {
  return (
    <Label
      as="a"
      style={{
        cursor: "default",
        width: fullWidth ? "100%" : "70px",
        height: fullWidth ? "24px" : "16px",
        fontSize: fullWidth ? "14px" : "10px",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: label === "In progress" ? "#F0AC47" : "#59B655",
        color: "#FFFFFF",
        borderRadius: 20,
        padding: "2px 8px"
      }}
    >
      <span>{label}</span>
    </Label>
  );
};
