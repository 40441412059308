import { createSelector } from "reselect";
import moment from "moment";
import { orderBy } from "lodash";
const farmsSummary = state => state.farms.summary;
export const farmsWithAreas = state =>
  state.farms.summary?.filter(
    ({ areas }) => areas.length && areas.some(({ archived }) => !archived)
  );
const showArchived = state => state.farms.showArchived;
const filterList = state => state.sprayDiary.filters;
const search = state => state.farms.search;
const generalTaskInstructionsData = state =>
  state.taskCreator.taskStages.instructions;
const prepareAreasInformation = farm => {
  const now = moment().startOf("day");
  farm.areas.forEach(area => {
    area.reentryDays = area.reentryDate
      ? moment(`${area.reentryDate}Z`)
          .startOf("day")
          .diff(now, "days")
      : null;
    area.withholdingDays = area.withholdingPeriodDate
      ? moment(`${area.withholdingPeriodDate}Z`)
          .startOf("day")
          .diff(now, "days")
      : null;

    area.tasksCount = {
      OPEN: 0,
      IN_PROGRESS: 0,
      COMPLETED: 0
    };
    (area.taskStatistics || []).forEach(statusRow => {
      area.tasksCount[statusRow.status] = statusRow.count;
    });
  });
};
const summaryWithActivateAreasOnlySelector = createSelector(
  [farmsSummary],
  selectFarmsSummary => {
    return selectFarmsSummary
      ? selectFarmsSummary
          .map(farm => {
            prepareAreasInformation(farm);
            return {
              ...farm,
              areas: farm.areas.filter(area => !area.archived)
            };
          })
          .sort((farm1, farm2) =>
            farm1.farm?.name.localeCompare(farm2.farm?.name)
          )
      : [];
  }
);
const farmsSelector = createSelector(
  [farmsSummary, showArchived, search],
  (selectFarmsSummary, showArchived, search = "") => {
    const searchFilter = farm =>
      farm?.farm?.name?.toUpperCase().indexOf(search.toUpperCase()) !== -1 ||
      farm?.areas?.filter(
        area => area?.name?.toUpperCase().indexOf(search.toUpperCase()) !== -1
      ).length > 0;
    return selectFarmsSummary
      ? selectFarmsSummary
          .filter(farm => showArchived || !farm.farm.archived)
          .filter(searchFilter)
          .map(farm => {
            prepareAreasInformation(farm);
            const parentAreasInSearch =
              !!farm.areas.length &&
              farm.areas
                .filter(area => {
                  return (
                    area.name?.toUpperCase().indexOf(search.toUpperCase()) !==
                      -1 ||
                    (area.parent &&
                      area.parent.name
                        ?.toUpperCase()
                        .indexOf(search.toUpperCase()) !== -1)
                  );
                })
                .map(area => area.parent)
                .filter(a => a);
            return {
              ...farm.farm,
              areas: farm.areas.filter(
                area =>
                  farm.farm.name.toUpperCase().indexOf(search.toUpperCase()) !==
                    -1 ||
                  area.name.toUpperCase().indexOf(search.toUpperCase()) !==
                    -1 ||
                  (area.parent &&
                    area.parent.name
                      .toUpperCase()
                      .indexOf(search.toUpperCase()) !== -1) ||
                  parentAreasInSearch.filter(parent => parent.id === area.id)
                    .length > 0
              ),
              show: farm.show === undefined ? true : farm.show,
              open: farm.open === undefined ? search !== "" : farm.open
            };
          })
          .sort((farm1, farm2) => farm1.name.localeCompare(farm2.name))
      : null;
  }
);
const farmsOptionsSelector = createSelector(
  [farmsSummary, filterList],
  (selectFarmsSummary, filters) => {
    return selectFarmsSummary
      ? orderBy(
          selectFarmsSummary
            .filter(item => filters.showArchived || !item.farm.archived)
            .map(item => ({
              key: item.farm.id,
              text: item.farm.archived
                ? `${item.farm.name} (archived)`
                : item.farm.name,
              value: item.farm.id
            })),
          [farm => farm.text.toLowerCase()]
        )
      : [];
  }
);
export const farmsOptionsFilterSelector = createSelector(
  [farmsOptionsSelector],
  farmsOptions => ({
    options: farmsOptions.map(({ text, ...restProperties }) => ({
      ...restProperties,
      label: text
    }))
  })
);

export const farmsBlockSelector = createSelector(
  [farmsWithAreas, generalTaskInstructionsData],
  (
    farms,
    {
      treeSelectSearchFilter,
      expandedFarmsIds,
      expandedAreasIds,
      areas: selectedAreas
    }
  ) =>
    farms
      .map(({ farm, areas }) => {
        const filteredAreas = areas.filter(a => !a.archived);
        const groupedAreas = filteredAreas.reduce((prev, current) => {
          if (current.parent) {
            const prevParentIndex = prev.findIndex(
              block => block.id === current.parent.id
            );
            if (prevParentIndex > -1) {
              const prevNew = [...prev];
              prevNew[prevParentIndex] = {
                ...prevNew[prevParentIndex],
                label: current.parent.name,
                value: [...prevNew[prevParentIndex].value, current],
                type: "block",
                children: [
                  ...prevNew[prevParentIndex].children,
                  {
                    id: current.id,
                    farmId: farm.id,
                    blockId: current.parent.id,
                    label: current.name,
                    value: [current],
                    checked: !!selectedAreas.find(
                      area => area.id === current.id
                    ),
                    className: treeSelectSearchFilter
                      ? !current.name.includes(treeSelectSearchFilter)
                        ? "notFitSearch"
                        : "fitSearch"
                      : "",
                    type: "patch"
                  }
                ]
              };
              return prevNew;
            }
            return [
              ...prev,
              {
                id: current.parent.id,
                farmId: farm.id,
                label: current.parent.name,
                value: [current],
                expanded:
                  expandedAreasIds.includes(current.parent.id) ||
                  treeSelectSearchFilter,
                className: treeSelectSearchFilter ? "notFitSearch" : "",
                type: "block",
                children: [
                  {
                    id: current.id,
                    farmId: farm.id,
                    blockId: current.parent.id,
                    label: current.name,
                    value: [current],
                    checked: !!selectedAreas.find(
                      area => area.id === current.id
                    ),
                    className: treeSelectSearchFilter
                      ? !current.name.includes(treeSelectSearchFilter)
                        ? "notFitSearch"
                        : "fitSearch"
                      : "",
                    type: "patch"
                  }
                ]
              }
            ];
          }
          if (prev.findIndex(block => block.id === current.id) === -1) {
            return [
              ...prev,
              {
                id: current.id,
                farmId: farm.id,
                label: current.name,
                value: current.hasSubAreas ? [] : [current],
                expanded:
                  expandedAreasIds.includes(current.id) ||
                  treeSelectSearchFilter,
                className: treeSelectSearchFilter
                  ? current.hasSubAreas ||
                    !current.name.includes(treeSelectSearchFilter)
                    ? "notFitSearch"
                    : "fitSearch"
                  : "",
                type: "block",
                children: []
              }
            ];
          }
          return prev;
        }, []);
        const checkedAreas = groupedAreas
          .map(area => ({
            ...area,
            children: area.children.sort((p1, p2) =>
              p1.label.localeCompare(p2.label)
            ),
            checked:
              area.children && area.children.length
                ? area.children.every(patch => patch.checked)
                : selectedAreas.find(a => a.id === area.id)
          }))
          .sort((b1, b2) => b1.label.localeCompare(b2.label));
        return {
          farmId: farm.id,
          label: farm.name,
          value: checkedAreas.map(area => area.value).flat(),
          checked:
            checkedAreas.length && checkedAreas.every(block => block.checked),
          expanded:
            expandedFarmsIds.includes(farm.id) || treeSelectSearchFilter,
          ...(treeSelectSearchFilter && { className: "notFitSearch" }),
          type: "farm",
          children: checkedAreas
        };
      })
      .sort((farm1, farm2) => farm1.label.localeCompare(farm2.label))
);

export {
  farmsSelector,
  summaryWithActivateAreasOnlySelector,
  farmsOptionsSelector
};
