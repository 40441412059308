export function hashToObject(hash) {
  if (!hash || typeof hash !== "string") {
    return {};
  }
  const hashArray = hash.slice(1).split("&");
  const hashObject = {};
  hashArray.forEach((item) => {
    const values = item.split("=");
    hashObject[values[0]] = values[1];
  });
  return hashObject;
}

export function hashToParamIndex(hash) {
  if (!hash || typeof hash !== "string") {
    return {};
  }
  const hashArray = hash.slice(1).split("&");
  const hashObject = {};
  hashArray.forEach((item, index) => {
    const values = item.split("=");
    hashObject[values[0]] = index;
  });
  return hashObject;
}

export const getHashParameterIndex = (location, name, tag = null) => {
  const tagPrefix = tag ? `${tag}_` : "";
  const hashObject = hashToParamIndex(location?.hash);
  return hashObject[tagPrefix + name];
};

export const getHashParameter = (location, name, tag = null) => {
  const tagPrefix = tag ? `${tag}_` : "";
  const hashObject = hashToObject(location.hash);
  return hashObject[tagPrefix + name];
};

export const getHashParameters = (location) => hashToObject(location.hash);

export const setHashParameter = (
  location,
  name,
  value,
  tag = null,
  navigate = () => {}
) => {
  const tagPrefix = tag ? `${tag}_` : "";

  const hashObject = hashToObject(location?.hash);

  if (value) {
    hashObject[tagPrefix + name] = value;
  } else {
    delete hashObject[tagPrefix + name];
  }

  const hash = objectToHash(hashObject);
  navigate(
    {
      ...location,
      hash: `${hash ? `#${hash}` : ""}`,
    },
    { replace: true }
  );
};

export const removeHashParameter = (location, name, tag = null, navigate) => {
  const tagPrefix = tag ? `${tag}_` : "";
  setHashParameter(location, tagPrefix + name, null, null, navigate);
};

export const removeHashParameters = (location, names, tag = null, navigate) => {
  const tagPrefix = tag ? `${tag}_` : "";
  const hashObject = hashToObject(location.hash);
  names.forEach((name) => delete hashObject[tagPrefix + name]);
  const hash = objectToHash(hashObject);

  navigate(
    {
      ...location,
      hash: `${hash ? `#${hash}` : ""}`,
    },
    { replace: true }
  );
};

export function objectToHash(hashObject) {
  if (!hashObject || typeof hashObject !== "object") {
    return "";
  }
  return Object.keys(hashObject).reduce((hash, item) => {
    return `${hash}${hash ? "&" : ""}${item}=${hashObject[item]}`;
  }, "");
}
