import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dropdown, Form, Grid, Input, TextArea } from "semantic-ui-react";
import SegmentClosableControlled from "../../../../../components/SegmentClosableControlled";
import SelectEmployee from "../../../../../components/SelectEmployee";
import {
  deleteAttachment,
  fetchTaskPriorities,
  fetchTaskStatuses,
  setField,
  uploadAttachment
} from "../../../../../actions/GeneralTaskCreator/taskCreator";
import styles from "../styles.module.css";
import DateRangePicker from "../../../../../components/DateRangePicker";
import TaskStepFooter from "../TaskStepFooter";
import Review from "./Review";
import Attachments from "../../../../../components/Attachments";
import { generalStageValidation } from "../../../../../reducers/GeneralTaskCreator";

const GeneralStep = ({
  isOpen,
  allowedFields,
  mandatoryFields,
  onArrowClick,
  subTasksList
}) => {
  const data = useSelector(
    state => state.taskCreator.taskStages.general,
    shallowEqual
  );
  const {
    content: taskPriorities,
    isFetching: isTaskPrioritiesFetching
  } = useSelector(state => state.taskCreator.taskPriorities);
  const {
    content: taskStatuses,
    isFetching: isTaskStatusesFetching
  } = useSelector(state => state.taskCreator.taskStatuses);
  const isUploadingAttachment = useSelector(
    state => state.taskCreator.isUploadingAttachment,
    shallowEqual
  );
  const dispatch = useDispatch();

  const generalStageIsValid = generalStageValidation(data);

  useEffect(() => {
    dispatch(fetchTaskPriorities());
    dispatch(fetchTaskStatuses());
  }, []);

  const uploadAttachmentAction = file => dispatch(uploadAttachment(file));
  const onDeleteAttachmentAction = id => dispatch(deleteAttachment(id));
  const setFieldValue = (fieldName, fieldValue) =>
    dispatch(setField("general", fieldName, fieldValue, mandatoryFields));

  const {
    taskName,
    description,
    dateRange,
    subTaskName,
    priority,
    assignTo,
    status,
    attachments
  } = data;

  const subTasksOptions = subTasksList.map((item, index) => ({
    key: item,
    text: item,
    value: item
  }));
  return (
    <SegmentClosableControlled
      title={"General"}
      open={isOpen}
      onArrowClick={onArrowClick}
    >
      {isOpen && (
        <>
          <Grid className={styles.formPadding}>
            <Grid.Row>
              <Grid.Column
                widescreen={8}
                largeScreen={8}
                computer={8}
                mobile={16}
              >
                <Form.Field
                  required={mandatoryFields.includes("NAME")}
                  disabled={!allowedFields.includes("NAME")}
                >
                  <label>Task Name:</label>
                  <Input
                    disabled={!allowedFields.includes("NAME")}
                    value={taskName}
                    maxLength={100}
                    onChange={(_, data) =>
                      setFieldValue("taskName", data.value)
                    }
                    fluid
                    type={"text"}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column
                widescreen={4}
                largeScreen={4}
                computer={4}
                mobile={16}
              >
                <Form.Field
                  required={mandatoryFields.includes("END_AT")}
                  disabled={!allowedFields.includes("END_AT")}
                >
                  <label>Date Range:</label>
                  <DateRangePicker
                    disabled={!allowedFields.includes("START_AT")}
                    name={"dateRange"}
                    value={dateRange}
                    onChange={value => setFieldValue("dateRange", value)}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column
                widescreen={4}
                largeScreen={4}
                computer={4}
                mobile={16}
              >
                <Form.Field
                  required={mandatoryFields.includes("ASSIGNED_TO")}
                  disabled={!allowedFields.includes("ASSIGNED_TO")}
                >
                  <label>Assign to:</label>
                  <SelectEmployee
                    native
                    disabled={!allowedFields.includes("ASSIGNED_TO")}
                    placeholder="All employees"
                    multiple
                    value={assignTo}
                    clearable
                    onChange={(_, data) =>
                      setFieldValue("assignTo", data.value)
                    }
                    fluid
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                widescreen={8}
                largeScreen={8}
                computer={8}
                mobile={16}
              >
                <Form.Field
                  required={mandatoryFields.includes("SUBTASK_NAME")}
                  disabled={!allowedFields.includes("SUBTASK_NAME")}
                >
                  <label>Sub task:</label>
                  <Dropdown
                    className={styles.subTaskDropdown}
                    disabled={!allowedFields.includes("SUBTASK_NAME")}
                    placeholder="Sub task:"
                    search
                    value={subTaskName}
                    selection
                    options={subTasksOptions}
                    onChange={(_, data) =>
                      setFieldValue("subTaskName", data.value)
                    }
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column
                widescreen={4}
                largeScreen={4}
                computer={4}
                mobile={16}
              >
                <Form.Field
                  required={mandatoryFields.includes("PRIORITY")}
                  disabled={!allowedFields.includes("PRIORITY")}
                >
                  <label>Priority:</label>
                  <Dropdown
                    disabled={!allowedFields.includes("PRIORITY")}
                    name="priority"
                    closeOnChange
                    loading={isTaskPrioritiesFetching}
                    fluid
                    selection
                    value={priority}
                    options={taskPriorities.map(priority => ({
                      key: priority,
                      text: priority,
                      value: priority
                    }))}
                    onChange={(_, data) =>
                      setFieldValue("priority", data.value)
                    }
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column
                widescreen={4}
                largeScreen={4}
                computer={4}
                mobile={16}
              >
                <Form.Field
                  required={mandatoryFields.includes("STATUS")}
                  disabled={!allowedFields.includes("STATUS")}
                >
                  <label>Status:</label>
                  <Dropdown
                    disabled={!allowedFields.includes("STATUS")}
                    name="status"
                    loading={isTaskStatusesFetching}
                    value={status}
                    options={taskStatuses.map(status => ({
                      key: status,
                      text: status.replace(/_/gi, " "),
                      value: status
                    }))}
                    onChange={(_, data) => setFieldValue("status", data.value)}
                    closeOnChange
                    fluid
                    selection
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                widescreen={8}
                largeScreen={8}
                computer={8}
                mobile={16}
              >
                <Form.Field
                  required={mandatoryFields.includes("DESCRIPTION")}
                  disabled={!allowedFields.includes("DESCRIPTION")}
                >
                  <label>Description:</label>
                  <TextArea
                    disabled={!allowedFields.includes("DESCRIPTION")}
                    value={description}
                    name={"description"}
                    maxLength={500}
                    style={{ width: "100%" }}
                    rows={5}
                    onChange={(_, data) =>
                      setFieldValue("description", data.value)
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column
                widescreen={8}
                largeScreen={8}
                computer={8}
                mobile={16}
              >
                <Form.Field
                  required={mandatoryFields.includes("ATTACHMENTS")}
                  disabled={!allowedFields.includes("ATTACHMENTS")}
                >
                  <Attachments
                    onFileSelected={uploadAttachmentAction}
                    isUploading={isUploadingAttachment}
                    attachments={attachments}
                    onDelete={onDeleteAttachmentAction}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column
                widescreen={4}
                largeScreen={4}
                computer={4}
                mobile={16}
              />
              <Grid.Column
                widescreen={4}
                largeScreen={4}
                computer={4}
                mobile={16}
              />
            </Grid.Row>
          </Grid>
          <TaskStepFooter isValid={generalStageIsValid} />
        </>
      )}
      {isOpen || <Review generalStep={data} />}
    </SegmentClosableControlled>
  );
};

GeneralStep.propTypes = {
  allowedFields: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  mandatoryFields: PropTypes.array.isRequired,
  onArrowClick: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return {
    subTasksList: state.generalTasks.subTasks
  };
};

export default connect(mapStateToProps)(GeneralStep);
