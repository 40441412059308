import React from "react";
import localStorage from "localStorage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import config from "../config";
import { setVersionChecked } from "../actions/Common";
import { purgeAllData } from "../actions/Common";
import { fetchAreasList } from "actions/Areas/areas";
import { fetchEmployeesList } from "actions/Employee/employees";
import { onlineSelector } from "../selectors/common";

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      firstPage: props.pathname
    };
  }

  async componentDidMount() {
    this.fetchMeta();
    this.fetchUrl();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { forceUpdate, online, actions, pathname } = this.props;
    if (forceUpdate && forceUpdate !== prevProps.forceUpdate) {
      this.refreshCacheAndReload();
    }

    if (!online && prevProps.online) {
      actions.setVersionChecked(false);
    }
    if (online && prevProps.online === false) {
      this.fetchMeta();
    }

    if (pathname !== prevProps.pathname) {
      this.fetchUrl();
    }
  }

  refreshCacheAndReload = async () => {
    const { actions } = this.props;
    //eslint-disable-next-line
    console.log("Clearing cache and hard reloading...");
    this.deleteCache();
    // delete browser cache and hard reload
    localStorage.setItem("purgeData", "true");
    localStorage.setItem("forceUpdate", "true");
    window.localforage.clear();
    await actions.purgeAllData();
    // if (JSON.parse(localStorage.getItem("userCheck"))) {
    //   await actions.fetchAreasList(true);
    //   await actions.fetchEmployeesList(true);
    // }
    window.location.reload(true);
  };

  deleteCache = async () => {
    if (window.Cache) {
      // Service worker cache should be cleared with caches.delete()
      const names = await caches.keys();
      //eslint-disable-next-line
      for (let name of names) caches.delete(name);
    }
  };

  fetchUrl = () => {
    const { pathname, onlineReal } = this.props;
    const { firstPage } = this.state;

    if (!window.Cache) {
      return;
    }
    caches.open(config.notCached.cacheName).then(cache => {
      return cache.match(onlineReal ? pathname : firstPage).then(response => {
        if (!onlineReal && response) {
          cache.put(pathname, response.clone());
        }

        return onlineReal
          ? response ||
              fetch(pathname).then(networkResponse => {
                cache.put(pathname, networkResponse.clone());
                return networkResponse;
              })
          : response;
      });
    });
  };

  fetchMeta = () => {
    const { actions } = this.props;
    fetch("/meta.json", { cache: "no-cache" })
      .then(response => response.json())
      .then(meta => {
        const currentVersion = localStorage.getItem("appVersion");
        const latestVersion = meta.version;
        localStorage.setItem("appVersion", latestVersion);
        localStorage.setItem("currentVersion", currentVersion);
        const shouldForceRefresh = latestVersion !== currentVersion;

        if (shouldForceRefresh) {
          //eslint-disable-next-line
          console.log(
            `We have a new version: ${latestVersion} (current version: ${currentVersion}). Should force refresh`
          );
          this.refreshCacheAndReload();
        } else {
          //eslint-disable-next-line
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          this.setState({ loading: false });
          actions.setVersionChecked(true);
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        actions.setVersionChecked(false);
      });
  };

  render() {
    const { loading } = this.state;
    const { rehydrate } = this.props;
    return this.props.children({
      loading: loading || !rehydrate
    });
  }
}

CacheBuster.propTypes = {
  pathname: PropTypes.string,
  actions: PropTypes.object,
  forceUpdate: PropTypes.bool,
  online: PropTypes.bool,
  onlineReal: PropTypes.bool,
  rehydrate: PropTypes.bool,
  children: PropTypes.func
};

const mapStateToProps = state => {
  const {
    router: {
      location: { pathname }
    },
    offline: { online },
    common: { forceUpdate, rehydrate }
  } = state;

  return {
    forceUpdate,
    rehydrate,
    online,
    pathname,
    onlineReal: onlineSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { setVersionChecked, purgeAllData, fetchAreasList, fetchEmployeesList },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CacheBuster);
