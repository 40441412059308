import { createSelector } from "reselect";
import { startCase, camelCase } from "lodash";
export const employeesList = (state) => state.employee.list;
export const contractorsList = (state) => state.contractors.list.content;

export const contractorsListSelector = createSelector(
  [contractorsList],
  (contractor) => ({
    options: contractor.map(({ id, name }) => ({
      key: id,
      label: name,
      value: id,
    })),
  })
);

const getEmployeesTypes = (state) => {
  const {
    employee: { employeesTypes },
  } = state;

  return employeesTypes;
};

const getContractors = (state) => {
  const {
    contractors: { list },
  } = state;

  return list;
};

const getEmployeesList = (state) => {
  const {
    employee: { list },
  } = state;

  return list;
};

const getGroups = (state) => {
  const {
    laborGroups: { list },
  } = state;

  return list;
};

export const employeeList = createSelector([employeesList], ({ content }) =>
  content
    ? content
        .filter((item) => item.archived === false)
        .map(({ id, firstName, lastName }) => ({
          key: id,
          text: `${firstName} ${lastName}`,
          value: id,
        }))
        .sort((b1, b2) => b1.text.localeCompare(b2.text))
    : []
);

export const employeesTypesSelector = createSelector(
  [getEmployeesTypes],
  (employeesTypes) => {
    return {
      isFetching: employeesTypes.isFetching,
      options: employeesTypes.items.map((type, index) => ({
        key: type,
        label: startCase(camelCase(type)),
        value: type,
        id: index + 1,
      })),
    };
  }
);
export const employeesOptionsSelector = createSelector(
  [employeesList],
  ({ content }) =>
    content
      ? content.map(({ id, picture, lastName, firstName, type }) => ({
          key: id,
          value: id,
          picture,
          lastName,
          firstName,
          type: type,
        }))
      : []
);

export const contractorNamesSelector = createSelector(
  [getContractors],
  (list) => {
    return {
      isFetching: false,
      options: list.content.map((contractor) => ({
        key: contractor.id,
        label: startCase(camelCase(contractor.name)),
        value: contractor.id,
      })),
    };
  }
);

export const groupNamesSelector = createSelector([getGroups], (list) => {
  return {
    isFetching: list.isFetching,
    options: list.content.map((group) => ({
      key: group.id,
      sortName: group.name,
      label: startCase(camelCase(group.name)),
      value: group.id,
    })),
  };
});

export const employeeOptionsSelector = createSelector(
  [getEmployeesList],
  (list) => {
    return {
      isFetching: list.isFetching,
      options: list.content.map((employee) => ({
        key: employee.id,
        sortName: employee.firstName,
        label: `${startCase(camelCase(employee.firstName))} ${startCase(
          camelCase(employee.lastName)
        )}`,
        value: employee.id,
        group:
          employee.employeeGroup && employee.employeeGroup.name
            ? employee.employeeGroup.name
            : null,
      })),
    };
  }
);
