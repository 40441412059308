import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Modal, Popup, Icon, Segment } from "semantic-ui-react";
import Numeric from "components/Numeric/Numeric";
import styles from "./PurchaseOrderModal.module.css";
import { setActiveItemId } from "actions/PurchaseHistory/index";
import { removeHashParameters } from "utils/hashToObject";
import TableContainer from "components/Table";

const PurchaseOrderModal = ({
  open,
  setCreateOrderModalShown,
  purchaseOrder,
  defaultSort,
  setOpen,
  location,
  setFilters,
  rowId,
  selectedItems,
  tableColumns,
  tableData,
  navigate,
}) => {
  const dispatch = useDispatch();

  const newData = purchaseOrder?.products
    .map((item) => {
      const test = item.quantities.map((q) => {
        return {
          chemicalId: item.chemicalId,
          manufacturer: item.manufacturer,
          totalCost: item.totalCost,
          quantities: [q],
          products: purchaseOrder.products,
          quantitiesList: item.quantities,
        };
      });
      return [...test];
    })
    ?.flat();
  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      onClick={(e) => e.stopPropagation()}
      className={styles.root}
    >
      {purchaseOrder && (
        <Modal.Content scrolling className={styles.modalContentContainer}>
          <div className={styles.modalHeaderWrapper}>
            <div className={styles.modalHeader}>
              {`Purchase receipt - ${purchaseOrder.orderNumber}`}
            </div>
            <div className={styles.closeButtonContainer}>
              <Popup
                inverted
                position="bottom center"
                content={"Close"}
                size="tiny"
                trigger={
                  <Button
                    size="small"
                    floated="right"
                    className={styles.closeButton}
                    icon={<Icon className="tuf-times" />}
                    onClick={() => {
                      return (
                        removeHashParameters(
                          location,
                          ["purchaseOrderId"],
                          null,
                          navigate
                        ),
                        dispatch(setActiveItemId(null)),
                        setCreateOrderModalShown(false)
                      );
                    }}
                  />
                }
              />
            </div>
          </div>
          <div className={styles.generalInformationLabe}>
            <div className={styles.orderDateLabe}>Order date</div>
            <div className={styles.deliveryDateLabe}>Delivery date</div>
            <div className={styles.supplierLabe}>Suppliers</div>
            <div className={styles.totalPriceLabe}>Total price ($)</div>
          </div>
          <div className={styles.generalInformation}>
            <div className={styles.orderDate}>
              {moment(purchaseOrder.orderDate).format("DD/MM/YYYY")}
            </div>
            <div className={styles.deliveryDate}>
              {moment(purchaseOrder.deliveryDate).format("DD/MM/YYYY")}
            </div>
            <div className={styles.supplier}>{purchaseOrder.supplier}</div>
            <div className={styles.totalPrice}>
              <Numeric
                fractionDigits={
                  purchaseOrder.products.reduce(
                    (prev, curr) => prev + +curr.totalCost,
                    0
                  ) > 1000
                    ? 0
                    : 2
                }
                value={purchaseOrder.products.reduce(
                  (prev, curr) => prev + +curr.totalCost,
                  0
                )}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
                defaultValue="-"
              />
            </div>
          </div>
          <div className={styles.tableWrapper}>
            <TableContainer
              data={newData}
              defaultSort={defaultSort}
              columns={tableColumns}
              selectedItems={selectedItems}
              open={open}
              setOpen={setOpen}
              setFilters={setFilters}
              rowId={rowId}
              onRowClick={() => {}}
              inTable={true}
              withoutSort={true}
              singleLine
              fixed={true}
              withSelection={false}
            />
          </div>
        </Modal.Content>
      )}
    </Modal>
  );
};

export default PurchaseOrderModal;
