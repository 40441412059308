import React from "react";
import { createSelector } from "reselect";
import { get } from "lodash";
import { matterToUnits } from "utils/constToUnits";
import Numeric from "components/Numeric/Numeric";
const tableDataSelector = state => state.reports.shedReportProductUsage.data;
const tableListDataSelector = state =>
  state.reports.shedReportProductUsage.list;
const areasListSelector = state => state.areas.list.content;
const chemicalListSelector = state => state.chemical.list;
const farmListSelector = state => state.farms.summary;
const ids = state => state.spray.ids;
export const formaterData = (data, chemicalList) => {
  const { content, page, ...rest } = data;
  const contentUpdated = content?.length
    ? content
        ?.sort(function(a, b) {
          var nameA = a.typeName?.toLowerCase(),
            nameB = b.typeName?.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
        .map(block => {
          return {
            ...block?.items
              ?.sort(function(a, b) {
                var nameA = chemicalList.content
                    .find(({ id }) => id == a.chemicalId)
                    ?.name?.toLowerCase(),
                  nameB = chemicalList.content
                    .find(({ id }) => id == b.chemicalId)
                    ?.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .reduce(
                (prev, curr, _, items) => {
                  const trackInventoryCheck = chemicalList.content.find(
                    i => i.id === curr.chemicalId
                  ).trackInventory;
                  return {
                    name: get(block, "chemicalName", "-"),
                    matter: get(block, "matter", "-"),
                    plannedQuantity: (
                      <Numeric
                        fractionDigits={
                          items.reduce(
                            (prev, curr) => prev + curr.plannedQty,
                            0
                          ) > 1000
                            ? 0
                            : 2
                        }
                        value={items.reduce(
                          (prev, curr) => prev + curr.plannedQty,
                          0
                        )}
                        commaSeparatorOnThousands
                        units={` ${matterToUnits(
                          get(
                            chemicalList.content?.find(
                              ({ id }) => id == curr.chemicalId
                            ),
                            "matter"
                          )
                        )}`}
                        unitsPosition="right"
                      />
                    ),
                    actualQuantity: "",
                    totalCost: (
                      <Numeric
                        fractionDigits={
                          items.reduce((prev, curr) => prev + curr.cost, 0) >
                          1000
                            ? 0
                            : 2
                        }
                        value={items.reduce(
                          (prev, curr) => prev + curr.cost,
                          0
                        )}
                        commaSeparatorOnThousands
                        units="$ "
                        unitsPosition="left"
                      />
                    ),
                    empty: "",
                    subRows: [
                      ...prev.subRows,
                      {
                        name: (
                          <div style={{ paddingLeft: "10px" }}>
                            ST-{curr.sprayTaskId}
                          </div>
                        ),
                        matter: curr.sprayTaskName || "-",
                        plannedQuantity: (
                          <Numeric
                            fractionDigits={curr.plannedQty > 1000 ? 0 : 2}
                            value={curr.plannedQty}
                            commaSeparatorOnThousands
                          />
                        ),
                        actualQuantity:
                          curr.sprayTaskStatus !== "OPEN" ? (
                            <Numeric
                              fractionDigits={curr.actualQty > 1000 ? 0 : 2}
                              value={curr.actualQty}
                              commaSeparatorOnThousands
                            />
                          ) : (
                            "-"
                          ),
                        totalCost:
                          curr.sprayTaskStatus !== "OPEN" ? (
                            <Numeric
                              fractionDigits={curr.diff > 1000 ? 0 : 2}
                              value={curr.diff}
                              commaSeparatorOnThousands
                            />
                          ) : (
                            "-"
                          ),
                        empty: ""
                      }
                    ]
                  };
                },
                {
                  name: "",
                  matter: "",
                  plannedQuantity: null,
                  actualQuantity: null,
                  difference: null,
                  totalCost: null,
                  buttonContainer: null,
                  printButtonContainer: null,
                  subRows: [
                    {
                      name: (
                        <b
                          style={{
                            paddingLeft: "10px",
                            height: "48px",
                            borderTop: "1px solid #DEDEDF",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F6F7F8"
                          }}
                        >
                          {" "}
                          Task ID
                        </b>
                      ),
                      matter: (
                        <b
                          style={{
                            height: "48px",
                            borderTop: "1px solid #DEDEDF",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F6F7F8"
                          }}
                        >
                          Task name
                        </b>
                      ),
                      plannedQuantity: (
                        <b
                          style={{
                            height: "48px",
                            borderTop: "1px solid #DEDEDF",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F6F7F8"
                          }}
                        >
                          Planned Qty (L/Kg)
                        </b>
                      ),
                      actualQuantity: (
                        <b
                          style={{
                            height: "48px",
                            borderTop: "1px solid #DEDEDF",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F6F7F8"
                          }}
                        >
                          Actual Qty (L/Kg)
                        </b>
                      ),
                      difference: (
                        <b
                          style={{
                            height: "48px",
                            borderTop: "1px solid #DEDEDF",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F6F7F8"
                          }}
                        >
                          Difference (L/Kg)
                        </b>
                      ),
                      totalCost: (
                        <b
                          style={{
                            height: "48px",
                            borderTop: "1px solid #DEDEDF",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F6F7F8"
                          }}
                        >
                          Difference (L/Kg)
                        </b>
                      ),
                      empty: (
                        <b
                          style={{
                            height: "48px",
                            borderTop: "1px solid #DEDEDF",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#F6F7F8"
                          }}
                        ></b>
                      )
                    }
                  ]
                }
              )
          };
        }, {})
    : [];

  const contentUpdatedNew = contentUpdated
    ? contentUpdated.map(item => {
        return {
          ...item,
          subRows: item.subRows.map((row, index) => {
            if (index === item.subRows.length - 1) {
              row.isSubRowsLastItem = true;
            }
            return row;
          })
        };
      })
    : [];

  return {
    ...rest,
    page,
    content: contentUpdatedNew
  };
};
export const selectTableStructuredData = createSelector(
  [tableDataSelector, chemicalListSelector],
  (tableData, chemicalList) => {
    return formaterData(tableData, chemicalList);
  }
);

export const selectExpotStructuredData = createSelector(
  [
    tableListDataSelector,
    areasListSelector,
    farmListSelector,
    chemicalListSelector
  ],
  (listData, chemicalList) => {
    return formaterData(listData, chemicalList);
  }
);
export const statusOptions = createSelector([], () => {
  return {
    options: [
      { key: "open", label: "Open", value: "OPEN" },
      { key: "inProgress", label: "In Progress", value: "IN_PROGRESS" },
      { key: "completed", label: "Completed", value: "COMPLETED" }
    ]
  };
});

export const idsOptionsSelector = createSelector([ids], selectIds => {
  return selectIds
    ? {
        isFetching: selectIds.isFetching,
        options: [
          ...selectIds.data.content.map(task => ({
            key: task.id,
            label: `ST-${task.id}`,
            value: task.id
          }))
        ]
      }
    : null;
});
