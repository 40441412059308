import React from "react";
import PropTypes from "prop-types";
import { InfoWindowF } from "@react-google-maps/api";
import { Button, Header } from "semantic-ui-react";
import moment from "moment";

import styles from "./GoogleMaps.module.css";
import { Area } from "../../models/block.model";

const BlockWithSubAreaDetailPopup = ({ block, location }) => {
  return (
    <InfoWindowF position={(block.path && block.path[0]) || block.position}>
      <div className={styles.infoHolder}>
        <Header as="h3" className={styles.blockName}>
          {block.name}
        </Header>
      </div>
    </InfoWindowF>
  );
};

BlockWithSubAreaDetailPopup.propTypes = {
  block: PropTypes.object,
};

const BlockDetailPopup = ({ block, navigate }) => {
  const blockModel = new Area(block.block);
  const areaVariety = blockModel.areaVarietyByDate(moment());
  return (
    <InfoWindowF position={block.position}>
      <div className={styles.infoHolder}>
        <Header as="h3" className={styles.blockName}>
          {block.name}
          {areaVariety && areaVariety.name ? (
            ` — ${areaVariety.name}`
          ) : (
            <span className={styles.vacant}>Vacant</span>
          )}
          {areaVariety && areaVariety.crop ? (
            <div className={styles.cropName}>{areaVariety.crop.name}</div>
          ) : (
            ""
          )}
        </Header>
        <div className={block.block.reentryDays ? styles.active : ""}>
          Re-entry:{" "}
          {block.block.reentryDays
            ? `${block.block.reentryDays} Days (by ${moment(
                block.block.reentryDate
              ).format("DD/MM/YYYY")})`
            : "No re-Entry Period"}
        </div>
        <div className={block.block.withholdingDays ? styles.active : ""}>
          Withholding:{" "}
          {block.block.withholdingDays
            ? `${block.block.withholdingDays} Days (by ${moment(
                block.block.withholdingPeriodDate
              ).format("DD/MM/YYYY")})`
            : "No Withholding Period"}
        </div>
        {!!block.block.size && <div>Size: {block.block.size} Ha</div>}
        {areaVariety.numberOfPlants && (
          <div>No. Of Plants: {areaVariety.numberOfPlants}</div>
        )}
        <Button
          className={styles.buttonCreate}
          onClick={() =>
            navigate(
              `/spray_diary/create_spray_task/?${
                block.block.block ? "patch" : "block"
              }=${block.block.id}`
            )
          }
        >
          Create Task
        </Button>
      </div>
    </InfoWindowF>
  );
};

BlockDetailPopup.propTypes = {
  block: PropTypes.object,
};

const FarmDetailPopup = ({ block, navigate }) => {
  return block.block.hasSubAreas ? (
    <BlockWithSubAreaDetailPopup block={block} />
  ) : (
    <BlockDetailPopup navigate={navigate} block={block} />
  );
};

FarmDetailPopup.propTypes = {
  block: PropTypes.object,
};

export default FarmDetailPopup;
