import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import "react-table-6/react-table.css";
import { abilitiesSelector } from "../../selectors/user";
import { fetchPackedBoxesCountPerEmployee } from "../../actions/PackedBoxes/packedBoxes";
import { Button, Grid, Header, Segment, Sidebar } from "semantic-ui-react";
import LocalDataTable from "../../components/LocalDataTable";
import Layout from "../../components/Layout";
import styles from "../../components/ListHolder/ListHolder.module.css";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import Blank from "../Blank";

const Harvest = ({
  route,
  location,
  Can,
  data,
  isFetching,
  fetchAction,
  navigate
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const columns = useMemo(() => {
    let cols = [];

    if (!isMobile) {
      cols = [
        {
          id: "date",
          Header: "Date",
          width: 8,
          columns: [
            {
              id: "date",
              Header: d => "Total",
              accessor: d => d.date && moment(d.date).format("DD/MM/YYYY")
            }
          ]
        }
      ];
    }

    return [
      ...cols,
      {
        id: "employee",
        Header: "Employee",
        width: 8,
        columns: [
          {
            id: "employee",
            Header: isMobile
              ? "Total"
              : d => <div>{d.data.map(d => d.employee).length} employees</div>,
            accessor: d => `${d.employee.firstName} ${d.employee.lastName}`
          }
        ]
      },
      {
        id: "count",
        Header: "Packed boxes",
        width: 8,
        columns: [
          {
            Header: d => (
              <div className={isMobile ? "textRight" : ""}>
                {d.data.map(d => d.count).reduce((a, b) => a + b, 0)} boxes
              </div>
            ),
            accessor: "count",
            className: isMobile && "textRight"
          }
        ]
      }
    ];
  }, [isMobile]);

  return (
    <>
      <Can not I={"access_module"} a={"harvest"}>
        <Blank route={route} location={location} />
      </Can>
      <Can I={"access_module"} a={"harvest"}>
        <Can I={"view"} a={"packed_boxes"}>
          <Layout
            route={route}
            location={location}
            classForMain={styles.mainHolder}
          >
            <Sidebar.Pushable className={`${styles.farmsHolder}`}>
              <Sidebar.Pusher>
                <Segment basic className={styles.headerTasks}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Header as="h2" className={styles.taskName}>
                          {route.name}
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Can I={"view"} a={"/harvest/packed_boxes/scan"}>
                          <Button
                            content={"Scan"}
                            floated="right"
                            size="large"
                            primary
                            onClick={() =>
                              navigate("/harvest/packed_boxes/scan")
                            }
                          />
                        </Can>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                <Segment>
                  <LocalDataTable
                    navigate={navigate}
                    name={"harvest summary"}
                    Can={Can}
                    route={route}
                    location={location}
                    data={data}
                    columns={columns}
                    getData={fetchAction}
                    isFetching={isFetching}
                    defaultSort="employee,asc"
                    searchColumn={"employee"}
                    withDateRange={!isMobile}
                    exportableToExcel={true}
                  />
                </Segment>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </Layout>
        </Can>
      </Can>
    </>
  );
};

Harvest.propTypes = {
  fetchAction: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func
};

const mapStateToProps = (state) => {
  const {
    packedBoxes: {
      isFetching,
      countPerEmployee: { content }
    }
  } = state;
  return {
    data: content,
    isFetching,
    Can: abilitiesSelector(state)
  };
};

const mapDispatchToProps = {
  fetchAction: fetchPackedBoxesCountPerEmployee
};

export default connect(mapStateToProps, mapDispatchToProps)(Harvest);
