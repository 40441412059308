import { createSelector } from "reselect";

export const allocatedSelector = createSelector([], () => ({
  options: [
    {
      key: true,
      label: "Yes",
      value: true,
    },
    {
      key: false,
      label: "No",
      value: false,
    },
  ],
}));
