import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import styles from "./TrackerViewer.module.css";
import { RouteViewer } from "../../../../components/RouteViewer";
import TrackerViewerControlBar from "./TrackerViewerControlBar";

const TrackerViewer = ({ machineryId, route, isFetching, navigate }) => {
  return (
    <div className={styles.holder}>
      <TrackerViewerControlBar machineryId={machineryId} />
      <RouteViewer
        navigate={navigate}
        isFetching={isFetching}
        route={route && route.length > 0 ? [route] : null}
      />
    </div>
  );
};

TrackerViewer.propTypes = {
  machineryId: PropTypes.number,
  route: PropTypes.array,
  isFetching: PropTypes.bool,
};

TrackerViewer.defaultProps = {};

const mapStateToProps = ({
  machinery: {
    route: { isFetching, route },
  },
}) => {
  return { isFetching, route };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackerViewer);
