import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "formsy-semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { fetchMachineryAction } from "../../actions/Machinery/machineries";

const SelectMachinery = ({
  actions: { fetchMachineryAction },
  items,
  isFetching,
  required,
  error,
  minItemForSearch,
  onChange,
  native,
  showArchived,
  ...extraProps
}) => {
  useEffect(() => {
    fetchMachineryAction({ unpaged: true });
  }, []);

  const filterArchived = (data) =>
    showArchived || !data.hasOwnProperty("archived")
      ? true
      : data.archived === false;

  const options = useMemo(
    () =>
      items
        .filter(filterArchived)
        .sort((c1, c2) => c1.name.localeCompare(c2.name))
        .map((content) => ({
          key: content.id,
          text: content.name,
          value: content.id,
        })),
    [items]
  );

  useEffect(() => {
    if (required && options && options.length === 1) {
      onChange(null, { options, value: options[0].value });
    }
  }, [options]); // eslint-disable-line

  const props = {
    placeholder: "Select machinery",
    fluid: true,
    ...extraProps,
    error,
    closeOnBlur: true,
    onChange,
    loading: isFetching,
    selection: true,
    required,
    search: options.length >= minItemForSearch,
    noResultsMessage: "Nothing was found",
    options,
  };
  return native ? <Dropdown {...props} /> : <Form.Dropdown {...props} />;
};

SelectMachinery.propTypes = {
  actions: PropTypes.object.isRequired,
  contractorsList: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  items: PropTypes.object,
  minItemForSearch: PropTypes.bool,
  native: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  showArchived: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.number.isRequired,
  ]),
};

SelectMachinery.defaultProps = {
  disabled: false,
  error: false,
  isFetching: false,
  minItemForSearch: 10,
  native: false,
  onChange: () => {},
  required: false,
  showArchived: false,
};

const mapStateToProps = ({
  machinery: {
    data: { content, isFetching },
  },
}) => {
  return {
    items: content,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ fetchMachineryAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectMachinery);
