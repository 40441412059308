import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getAltCostEntering,
  deleteCostEntering,
  getAltCostEnteringList
} from "actions/GeneralTasks/costEntering";
import { abilitiesSelector } from "selectors/user";
import { activeItemSelector, areasOptionsSelector } from "selectors/chemicals";
import { getCostEnteringTableColumns } from "utils/altCostEntering";
import { Button, Icon } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import styles from "./CostEntering.module.css";
import { excelExport } from "utils/excelExport";
import { ALT_LABOUR_CONTRACTOR_TYPE } from "constants/GeneralTasks/costEntering";
import { get, round } from "lodash";
import moment from "moment";
import { fetchGeneralTaskList } from "actions/GeneralTasks/generalTasks";
const headerColumn = [
  "Area",
  "Block",
  "Patch",
  "Cost type",
  "General task id",
  "Type",
  "Notes",
  "Date/Period",
  "Date created",
  "Units",
  "Rate",
  "Total"
];
const CostEntering = ({
  areas,
  isArchivedView,
  Can,
  isFetching,
  location,
  route,
  tableData,
  activeItem,
  generalTaskList,
  navigate
}) => {
  const [filters, setFilters] = useState({
    generalTaskId: [],
    costType: [],
    seasons: [],
    from: "",
    to: ""
  });
  const areasList = useSelector(areasOptionsSelector);
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const [filtersList, setFiltersList] = useState([]);
  const [editSectionIndex, setEditSectionIndex] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGeneralTaskList());
  }, []);
  const deleteCost = async id => {
    await dispatch(deleteCostEntering(id));
    dispatch(
      getAltCostEntering({
        page: 0,
        search: "",
        size: 10,
        unpaged: false
      })
    );
  };
  const getData = useCallback(
    params => {
      setFiltersList(params);
      dispatch(
        getAltCostEntering({
          ...params,
          sort: !!params.sort.length ? params.sort : ["createdAt,desc"]
        })
      );
    },
    [getAltCostEntering]
  );
  useEffect(() => {
    activeItem === null && setEditSectionIndex(null);
  }, [activeItem]);

  const breadcrumbSections = [
    { content: "Chemical and fertiliser", active: true },
    { content: route.name, active: false }
  ];
  const tableColumns = getCostEnteringTableColumns(
    areasList,
    deleteCost,
    generalTaskList,
    isFetching
  );

  const handleExcelExport = useCallback(
    async generalTaskList => {
      const data = await dispatch(getAltCostEnteringList(filtersList));
      const newData = data.content
        .map(item => {
          const allAreas = areas
            .filter(i => item.areaIds.some(id => id === i.id))
            .map(a => {
              return a?.parent ? `${a?.parent?.name}/${a?.name}` : a?.name;
            });
          const filtredAreas = areas.filter(i =>
            item.areaIds.some(id => id === i.id)
          );
          const area = areas.find(i => i.id === item.areaIds[0]);
          return item.areaIds.length > 1
            ? [
                {
                  ...item,
                  areas: allAreas,
                  block: undefined,
                  pacth: undefined
                },
                ...item.areaIds.map(area => {
                  const areaSizeSum = areas
                    .filter(area => item.areaIds.some(a => a === area.id))
                    .reduce((prev, curr) => prev + curr.size, 0);
                  const filtredArea = filtredAreas.find(
                    item => item.id === area
                  );
                  return {
                    costType: item.costType,
                    areas: undefined,
                    block: filtredArea?.parent
                      ? filtredArea?.parent?.name
                      : filtredArea?.name,
                    patch: filtredArea?.parent ? filtredArea?.name : "-",
                    generalTaskId: item.generalTaskId,
                    costSubType: item.costSubType,
                    units:
                      (item.units *
                        areas.find(item => item.id === area)?.size) /
                      areaSizeSum,
                    rate: item.rate,
                    total:
                      (item.total *
                        areas.find(item => item.id === area)?.size) /
                      areaSizeSum,
                    notes: item.notes,
                    startAt: item.startAt,
                    endAt: item.endAt,
                    createdAt: item.createdAt
                  };
                })
              ]
            : {
                ...item,
                areas: [
                  area?.parent
                    ? `${area?.parent?.name}/${area?.name}`
                    : area?.name
                ],
                block: area?.parent ? area?.parent?.name : area?.name,
                patch: area?.parent ? area?.name : "-"
              };
        })
        .flat();
      if (data.content.length) {
        const excelData =
          newData &&
          newData.map(
            ({
              areas,
              block,
              patch,
              costType,
              areaId,
              areaIds,
              generalTaskId,
              costSubType,
              units,
              rate,
              total,
              notes,
              startAt,
              endAt,
              createdAt
            }) => {
              return {
                ...{
                  area: areas?.join(", ") || "-"
                },
                ...{
                  block: block || "-"
                },
                ...{
                  patch: patch || "-"
                },
                ...(costType && {
                  costType: get(
                    ALT_LABOUR_CONTRACTOR_TYPE.find(
                      ({ value }) => value === costType
                    ),
                    "text"
                  )
                }),
                ...{
                  generalTaskId: generalTaskId
                    ? `GT-${generalTaskId} ${generalTaskList.find(
                        item => item.id === generalTaskId
                      )?.name || ""}`
                    : generalTaskId === undefined
                    ? ""
                    : "-"
                },
                ...{
                  costSubType:
                    costSubType || costSubType === undefined ? costSubType : "-"
                },
                ...{ notes: notes || notes === undefined ? notes : "-" },
                ...{
                  datePeriod:
                    (endAt || endAt !== undefined) &&
                    (startAt || startAt !== undefined)
                      ? endAt
                        ? `${moment(startAt).format("DD/MM/YYYY")}-${moment(
                            endAt
                          ).format("DD/MM/YYYY")}`
                        : `${moment(startAt).format("DD/MM/YYYY")}` || "-"
                      : " "
                },
                ...{
                  dateCreated:
                    (createdAt &&
                      moment
                        .utc(createdAt)
                        .local()
                        .format("DD/MM/YYYY")) ||
                    "-"
                },

                ...{
                  units: units ? units.toFixed(3) : "-"
                },
                ...{
                  rate: rate ? rate.toFixed(2) : "-"
                },
                ...{ total: total ? total.toFixed(2) : "-" }
              };
            }
          );
        const dataeFromFilter = filters.from
          ? filters.from &&
            moment
              .utc(filters.from)
              .local()
              .format("DD/MM/YYYY")
          : "";
        const dataeTOFilter = `${
          filters.to
            ? filters.to &&
              moment
                .utc(filters.to)
                .local()
                .format("DD/MM/YYYY")
            : ""
        } `;
        const dateCheker = dataeFromFilter && dataeTOFilter ? "-" : "";
        const costType = filters.costType
          ? `Cost type-${filters.costType
              .toLowerCase()
              .charAt(0)
              .toUpperCase() + filters.costType.toLowerCase().slice(1)} `
          : "";
        const generalTaskId = filters.generalTaskId?.length
          ? `Activity-${filters.generalTaskId.join(", ")} `
          : "";
        const fileName = `alt_labor_cost${`${dataeFromFilter}${dateCheker}`}${dataeTOFilter}${costType}${generalTaskId}`;
        excelExport(excelData, headerColumn, fileName);
      }
    },
    [filtersList, generalTaskList]
  );
  return (
    <>
      <TablePageHolder
        navigate={navigate}
        actionsButtons={
          <>
            <Button
              size="small"
              floated="right"
              className={styles.printButtonForPage}
              onClick={() => {
                return handleExcelExport(generalTaskList);
              }}
            >
              <div className={styles.iconWrapperForPage}>
                <Icon name="download" />
                Export
              </div>
            </Button>
          </>
        }
        breadcrumbSections={breadcrumbSections}
        getData={getData}
        isFetching={isFetching}
        location={location}
        mainButton={
          !isArchivedView && (
            <Can I="add" a="chemicals">
              <Button
                primary
                className={styles.addButton}
                onClick={() =>
                  navigate("/alt_labour_cost_entering/add_new_alt_cost")
                }
              >
                Add new costs
              </Button>
            </Can>
          )
        }
        pageTitle={route.name}
        withoutSearch={true}
        setFilters={setFilters}
        route={route}
        selectedItems={selectedItemsIds}
        setSelectedItems={setSelectedItems}
        withSelection={false}
        withoutSort={false}
        fixed={false}
        singleLine
        wideTableClass={styles.scrollableTableWidth}
        onRowClick={() => {}}
        tableColumns={tableColumns}
        tableData={tableData}
      />
    </>
  );
};

CostEntering.propTypes = {
  Can: PropTypes.func,
  isArchivedView: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};

export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  return {
    isArchivedView,
    tableData: state.costEntering.data,
    filterValues: state.costEntering.filters,
    areas: state.areas.list.content,
    generalTaskList: state.generalTasks.list.content,
    isFetching: state.costEntering.isFetching,
    Can: abilitiesSelector(state),
    error: state.costEntering.error,
    activeItem: activeItemSelector(state)
  };
})(CostEntering);
