import React from "react";
import BasicCell from "components/Table/components/BasicCell";
import { Button, Icon, Popup } from "semantic-ui-react";
import style from "../routes/Harvest/PackedBoxes/PackedBoxes.module.css";
import { Link } from "react-router-dom";

export const getMachineryTableColumns = (
  isFetching,
  navigate,
  setOpenModal,
  setActiveId
) => {
  const columnsConfig = {
    name: {
      withSort: true,
      type: "string",
      id: "name",
      title: "Name",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ name }) => name || "-",
      withSort: true,
      cellWidth: 2,
    },
    make: {
      withSort: true,
      type: "string",
      id: "make",
      title: "Make",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ make }) => make || "-",
      withSort: true,
      cellWidth: 2,
    },
    model: {
      withSort: true,
      type: "string",
      id: "model",
      title: "Model",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ model }) => model || "-",
      withSort: true,
      cellWidth: 2,
    },

    size: {
      withSort: true,
      type: "string",
      id: "size",
      title: "Size",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ size }) => size || "-",
      withSort: true,
      cellWidth: 2,
    },
    ratePerHour: {
      withSort: true,
      type: "string",
      id: "ratePerHour",
      title: "Rate per hr",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ ratePerHour }) => ratePerHour || "-",
      withSort: true,
      cellWidth: 2,
    },
    notes: {
      withSort: true,
      type: "string",
      id: "notes",
      title: "Notes",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ notes }) => notes || "-",
      withSort: true,
      cellWidth: 2,
    },
    btnWrapper: {
      withSort: false,
      type: "string",
      id: "btnWrapper",
      title: "",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ id }) => {
        return (
          <div className={style.wrapper}>
            <div className={style.iconWrapper}>
              <Popup
                inverted
                position="bottom center"
                content={"Edit"}
                size="tiny"
                trigger={
                  <div className={style.editButtonWrapper}>
                    <Button
                      as={Link}
                      to={`/machinery/add_new_machinery/${id}`}
                      onClick={(event) => {
                        return (
                          // navigate(``),
                          event.stopPropagation()
                        );
                      }}
                      className={style.editButton}
                      floated="right"
                      icon={<Icon name="edit" />}
                      size="small"
                    />
                  </div>
                }
              />
              <Popup
                inverted
                position="bottom center"
                content={"Delete"}
                size="tiny"
                trigger={
                  <Button
                    onClick={(event) => {
                      return (
                        setOpenModal(true),
                        setActiveId(id),
                        event.stopPropagation()
                      );
                    }}
                    className={style.deleteButton}
                    floated="right"
                    icon={<Icon name="trash alternate" />}
                    size="small"
                  />
                }
              />
            </div>
          </div>
        );
      },
      withSort: true,
      cellWidth: 2,
    },
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {}),
  }));
};
