import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

import {
  getErrorFields,
  validationErrors,
} from "../../../../utils/validationRules";
import ErrorLabel from "../../../../components/ErrorLabel";
import { abilitiesSelector } from "../../../../selectors/user";
import SegmentClosable from "../../../../components/SegmentClosable";
import { scrollIntoError } from "../../../../utils/scrollIntoError";
import styles from "./BoxUnitsForm.module.css";
import {
  addBoxUnit,
  updateBoxUnit,
} from "../../../../actions/BoxUnits/boxUnits";

const initialValues = {
  displayName: "",
  netWeight: "",
  boxType: "",
  packType: "",
  pickingPrice: "",
  paddingPrice: "",
  cartingPrice: "",
  unitsPerPallet: "",
  liner: "",
};

class BoxUnitsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boxUnit: props.initialValues ? props.initialValues : initialValues,
      errorsInSegments: {},
    };
    this.formRef = React.createRef();
    this.isSubmitted = false;
  }

  changeValue = (fieldName, value) => {
    const { boxUnit } = this.state;
    this.setState({
      boxUnit: {
        ...boxUnit,
        [fieldName]: value,
      },
    });
  };

  changeValueAndUpdateDisplayName = (fieldName, value) => {
    const { boxUnit } = this.state;
    this.setState(
      {
        boxUnit: {
          ...boxUnit,
          [fieldName]: value,
        },
      },
      () =>
        this.setState({
          boxUnit: {
            ...this.state.boxUnit,
            displayName: `${
              this.state.boxUnit.netWeight
                ? `${this.state.boxUnit.netWeight} KG `
                : ""
            }${
              this.state.boxUnit.packType
                ? `${this.state.boxUnit.packType} `
                : ""
            }${this.state.boxUnit.boxType || ""}`,
          },
        })
    );
  };

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name,
        },
      },
    });
    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = async () => {
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;
    const {
      actions: { addBoxUnit, updateBoxUnit },
      onSubmitForm,
      navigate,
    } = this.props;
    const { boxUnit } = this.state;

    const boxUnitData = _.omit(boxUnit, ["id", "isFetching"]);

    this.setState({
      errorsInSegments: {},
    });

    let res;

    if (boxUnit.id) {
      res = await updateBoxUnit(boxUnit.id, boxUnitData, true);
    } else {
      res = await addBoxUnit(boxUnitData, true);
    }
    this.isSubmitted = false;

    if (!res.error) {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        navigate("/harvest/box_units");
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  render() {
    const { modal, Can, showMobileHeader, formRef } = this.props;
    const { boxUnit, errorsInSegments } = this.state;

    const columnMiddle = modal
      ? {
          width: 16,
        }
      : {
          mobile: 16,
          tablet: 16,
          computer: 10,
          largeScreen: 10,
          widescreen: 10,
        };
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid} ${modal ? styles.forModal : ""}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <SegmentClosable
                title="Box unit information"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field>
                        <label htmlFor="netWeight">Net weight</label>
                        <Input
                          {...(!boxUnit || !boxUnit.id
                            ? { autoFocus: true }
                            : {})}
                          fluid
                          name="netWeight"
                          size="large"
                          type="number"
                          validations="isNotNegativeFloat"
                          validationErrors={validationErrors()}
                          errorLabel={ErrorLabel}
                          value={boxUnit.netWeight}
                          onChange={(event) =>
                            this.changeValueAndUpdateDisplayName(
                              "netWeight",
                              event.target.value
                            )
                          }
                          label="Kg"
                          labelPosition="right"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Pack type"
                        placeholder="Pack type"
                        name="packType"
                        size="large"
                        value={boxUnit.packType}
                        onChange={(event) =>
                          this.changeValueAndUpdateDisplayName(
                            "packType",
                            event.target.value
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Box type"
                        placeholder="Box type"
                        name="boxType"
                        size="large"
                        value={boxUnit.boxType}
                        onChange={(event) =>
                          this.changeValueAndUpdateDisplayName(
                            "boxType",
                            event.target.value
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Display name"
                        placeholder="Display name"
                        name="displayName"
                        size="large"
                        required
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Display name",
                        })}
                        errorLabel={ErrorLabel}
                        value={boxUnit.displayName}
                        onChange={(event) =>
                          this.changeValue("displayName", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
              <SegmentClosable
                title="Pricing"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required>
                        <label htmlFor="pickingPrice">Picking price</label>
                        <Input
                          fluid
                          name="pickingPrice"
                          size="large"
                          type="number"
                          validations="isNotNegativeFloat"
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Picking price",
                          })}
                          errorLabel={ErrorLabel}
                          value={boxUnit.pickingPrice}
                          required
                          onChange={(event) =>
                            this.changeValue("pickingPrice", event.target.value)
                          }
                          label="$"
                          labelPosition="left"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required>
                        <label htmlFor="paddingPrice">Padding price</label>
                        <Input
                          fluid
                          name="paddingPrice"
                          size="large"
                          type="number"
                          validations="isNotNegativeFloat"
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Padding price",
                          })}
                          errorLabel={ErrorLabel}
                          value={boxUnit.paddingPrice}
                          required
                          onChange={(event) =>
                            this.changeValue("paddingPrice", event.target.value)
                          }
                          label="$"
                          labelPosition="left"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required>
                        <label htmlFor="cartingPrice">Crating price</label>
                        <Input
                          fluid
                          name="cartingPrice"
                          size="large"
                          type="number"
                          validations="isNotNegativeFloat"
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Crating price",
                          })}
                          errorLabel={ErrorLabel}
                          value={boxUnit.cartingPrice}
                          required
                          onChange={(event) =>
                            this.changeValue("cartingPrice", event.target.value)
                          }
                          label="$"
                          labelPosition="left"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
              <SegmentClosable
                title="Packing"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required>
                        <label htmlFor="unitsPerPallet">Units per pallet</label>
                        <Input
                          fluid
                          name="unitsPerPallet"
                          size="large"
                          type="number"
                          validations="isNotNegativeInteger"
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Units per pallet",
                          })}
                          errorLabel={ErrorLabel}
                          value={boxUnit.unitsPerPallet}
                          required
                          onChange={(event) =>
                            this.changeValue(
                              "unitsPerPallet",
                              event.target.value
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field>
                        <label htmlFor="liner">Liner</label>
                        <Input
                          fluid
                          name="liner"
                          size="large"
                          value={boxUnit.liner}
                          onChange={(event) =>
                            this.changeValue("liner", event.target.value)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                {boxUnit.id ? (
                  <Can I="update" a="box_units">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                ) : (
                  <Can I="add" a="box_units">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                )}
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

BoxUnitsForm.propTypes = {
  initialValues: PropTypes.object,
  actions: PropTypes.object,
  formRef: PropTypes.func,
  onSubmitForm: PropTypes.func,
  Can: PropTypes.func,
  modal: PropTypes.bool,
  showMobileHeader: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    Can: abilitiesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addBoxUnit,
      updateBoxUnit,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BoxUnitsForm);
