import { actionType } from "../../constants/Employees";
import { keyBy } from "lodash";

const initialState = {
  data: {
    content: [],
    page: { number: 0, totalPages: 0, totalElements: 0, size: 10 },
    isFetching: false
  },
  employeesTypes: {
    items: [],
    isFetching: false
  },
  list: {
    content: [],
    isFetching: false
  },
  archivedList: {
    content: [],
    page: { number: 0, totalPages: 0, totalElements: 0, size: 10 },
    isFetching: false
  },
  isUploadingAttachment: false,
  lastUpdateDate: null,
  uploadProgressPercentage: 0,
  uploadAttachmentCancelToken: null,
  isUploadingPicture: false,
  isFetching: false,
  saving: false,
  error: null
};

//TODO: fix it so it will update the state
export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.UPDATE_EMPLOYEE_START:
    case actionType.ADD_EMPLOYEE_START:
      return { ...state, saving: true };
    case actionType.ADD_EMPLOYEE:
      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          content: [...state.data.content, action.payload],
          totalElements: state.data.totalElements + 1,
          totalPages:
            state.data.totalElements + 1 >
            state.data.size * state.data.totalPages
              ? state.data.totalPages + 1
              : state.data.totalPages
        }
      };
    case actionType.ADD_EMPLOYEE_FAIL:
    case actionType.UPDATE_EMPLOYEE_FAIL:
      return {
        ...state,
        saving: false,
        error: action.payload
      };
    case actionType.DELETE_EMPLOYEE_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case actionType.FETCH_EMPLOYEES_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true
        }
      };

    case actionType.FETCH_EMPLOYEES_START:
    case actionType.DELETE_EMPLOYEES_START:
    case actionType.ARCHIVE_EMPLOYEES_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };

    case actionType.DELETE_EMPLOYEES:
    case actionType.ARCHIVE_EMPLOYEES: {
      const deletedElementsIdsMap = keyBy(action.payload);

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => !deletedElementsIdsMap[e.id]),
          isFetching: false
        },
        error: null
      };
    }
    case actionType.FETCH_EMPLOYEES_LIST:
      return {
        ...state,
        list: {
          ...action.payload.data,
          isFetching: false
        },
        lastUpdateDate: action.payload.lastUpdateDate
      };
    case actionType.FETCH_ARCHIVED_EMPLOYEES_LIST:
      return {
        ...state,
        archivedList: {
          ...action.payload.data,
          isFetching: false
        },
        lastUpdateDate: action.payload.lastUpdateDate
      };
    case actionType.FETCH_EMPLOYEES_LIST_FAIL:
      return {
        ...state,
        list: { ...state.list, isFetching: false },
        error: action.payload
      };
    case actionType.CHANGE_LAST_UPDATE_DATE:
      return {
        ...state,
        lastUpdateDate: action.payload
      };

    case actionType.FETCH_EMPLOYEES: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false
        }
      };
    }
    case actionType.FETCH_EMPLOYEES_ERROR:
    case actionType.DELETE_EMPLOYEES_FAIL:
    case actionType.ARCHIVE_EMPLOYEES_FAIL:
      return {
        ...state,
        error: action.payload,
        data: {
          ...state.data,
          isFetching: false
        }
      };
    case actionType.FETCH_EMPLOYEES_LIST_FAIL:
      return {
        ...state,
        list: { ...state.list, isFetching: false },
        error: action.payload
      };
    case actionType.CHANGE_EMPLOYEES_PAGE:
      return {
        ...state,
        data: {
          ...state.data,
          number: action.payload
        }
      };

    case actionType.CHANGE_PAGE_SIZE:
      return {
        ...state,
        data: {
          ...state.data,
          size: action.payload,
          totalPages: Math.ceil(state.data.totalElements / action.payload),
          number: 0
        }
      };

    case actionType.DELETE_EMPLOYEE:
      const newTotalElements = state.data.totalElements - 1;
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => e.id !== action.payload),
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size)
        },
        error: null
      };

    case actionType.FETCH_EMPLOYEE_START:
      return {
        ...state,
        isFetching: true
      };

    case actionType.FETCH_EMPLOYEE:
      return {
        ...state,
        isFetching: false
      };

    case actionType.UPDATE_EMPLOYEE:
      const updatedEmployee = action.payload;

      const updatedEmployees = state.data.content.map(e => {
        // eslint-disable-next-line
        if (e.id == updatedEmployee.id) {
          return updatedEmployee;
        }

        return e;
      });

      return {
        ...state,
        saving: false,
        data: {
          ...state.data,
          content: updatedEmployees
        }
      };

    case actionType.UPLOAD_EMPLOYEE_ATTACHMENT_START:
      return {
        ...state,
        uploadProgressPercentage: 0,
        isUploadingAttachment: true
      };

    case actionType.UPLOAD_EMPLOYEE_ATTACHMENT:
    case actionType.UPLOAD_EMPLOYEE_ATTACHMENT_ERROR:
      return {
        ...state,
        isUploadingAttachment: false
      };

    case actionType.UPLOAD_EMPLOYEE_ATTACHMENT_PROGRESS:
      return {
        ...state,
        uploadProgressPercentage: action.payload
      };

    case actionType.CREATE_EMPLOYEE_ATTACHMENT_CANCEL_TOKEN:
      return {
        ...state,
        uploadAttachmentCancelToken: action.payload
      };

    case actionType.UPLOAD_EMPLOYEE_PICTURE_START:
      return {
        ...state,
        isUploadingPicture: true
      };

    case actionType.UPLOAD_EMPLOYEE_PICTURE:
    case actionType.UPLOAD_EMPLOYEE_PICTURE_ERROR:
      return {
        ...state,
        isUploadingPicture: false
      };

    case actionType.CLEAR_EMPLOYEE_ERROR:
      return {
        ...state,
        error: null
      };
    case actionType.FETCH_EMPLOYEES_TYPES_START:
      return {
        ...state,
        employeesTypes: {
          ...state.employeesTypes,
          isFetching: true
        }
      };
    case actionType.FETCH_EMPLOYEES_TYPES: {
      return {
        ...state,
        employeesTypes: {
          items: action.payload,
          isFetching: false
        }
      };
    }
    case actionType.FETCH_EMPLOYEES_TYPES_FAIL:
      return {
        ...state,
        data: {
          ...state.data
        },
        filters: {
          ...state.filters,
          isFetching: false
        },
        error: action.payload
      };
    default:
      return state;
  }
};
