import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Grid, Icon, Header } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import styles from "./TaskHeader.module.css";
import { history } from "../../../../store";
import { abilitiesSelector } from "selectors/user";
import { getBatchNumbersLastYear } from "actions/Spray/tasks";

function TaskHeader({
  fieldToEdit,
  sprayTaskId,
  onSave,
  onCancel,
  onBack,
  onPrint,
  disabledSave,
  disabledBack,
  onCloseSidebar,
  selectedChemicals,
  taskId,
  showWizard,
  showWizardCancel,
  navigate
}) {
  const Can = useSelector(abilitiesSelector);
  const dispatch = useDispatch();
  const routeToTaskUpdate = useCallback(() => {
    dispatch(getBatchNumbersLastYear(selectedChemicals));
    onBack(null);
    navigate(`/spray_diary/create_spray_task/${taskId}`);
  }, [taskId, onBack]);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const propsForSave = isMobile
    ? {
        className: `button-text ${styles.buttonSave}`
      }
    : {
        primary: true
      };
  return (
    <div className={styles.taskHeader}>
      <Grid>
        <Grid.Row>
          <Grid.Column only="mobile" mobile={4}>
            {fieldToEdit || (showWizard && showWizardCancel) ? (
              <Button className="button-text" onClick={onCancel}>
                Cancel
              </Button>
            ) : onBack ? (
              <Button
                className="button-text"
                onClick={onBack}
                disabled={disabledBack}
              >
                <Icon name="arrow left" />
              </Button>
            ) : null}
          </Grid.Column>
          <Grid.Column className={styles.taskName} width={8}>
            <Header>
              {showWizard ? "Ticket Completion" : `ST-${sprayTaskId}`}
            </Header>
          </Grid.Column>
          <Grid.Column
            className={styles.taskActions}
            textAlign="right"
            mobile={4}
            tablet={8}
          >
            {fieldToEdit && !showWizard ? (
              <Button
                key="save"
                {...propsForSave}
                size="large"
                onClick={onSave}
                disabled={disabledSave}
              >
                Save
              </Button>
            ) : onPrint && !showWizard ? (
              <Button
                key="print"
                className="button-text color-green"
                size="large"
                onClick={onPrint}
              >
                <Icon name="print" />
                <span className="show-sm-inline">Print</span>
              </Button>
            ) : null}

            {fieldToEdit || (showWizard && showWizardCancel) ? (
              <Button
                key="cancel"
                className="show-sm-inline-block color-green"
                size="large"
                onClick={onCancel}
              >
                Cancel
              </Button>
            ) : !showWizard ? (
              <>
                <Can I="add" a="spray_tasks">
                  <Button
                    key="edit"
                    className={
                      isMobile
                        ? "button-text color-green"
                        : "show-inline-block color-green"
                    }
                    size="large"
                    onClick={routeToTaskUpdate}
                  >
                    {isMobile && (
                      <Icon name="edit" className="show-xs-inline" />
                    )}
                    <span className="show-sm-inline">Edit</span>
                  </Button>
                </Can>
                <Button
                  key="close"
                  className="button-text show-sm-inline-block"
                  onClick={onCloseSidebar}
                >
                  <Icon className="tuf-times" size="large" />
                </Button>
              </>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

TaskHeader.propTypes = {
  fieldToEdit: PropTypes.object,
  sprayTaskId: PropTypes.number,
  taskId: PropTypes.number,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  onPrint: PropTypes.func,
  disabledSave: PropTypes.bool,
  disabledBack: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  showWizard: PropTypes.bool,
  showWizardCancel: PropTypes.bool
};

TaskHeader.defaultProps = {};

export default TaskHeader;
