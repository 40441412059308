import React, { useState } from "react";
import PropTypes from "prop-types";
import { PolylineF } from "@react-google-maps/api";

import GoogleMaps from "../GoogleMaps";
import DetailPopup from "./DetailPopup";

const mapOptions = {
  streetViewControl: false,
  mapTypeControlOptions: {
    style: 1,
    position: 11,
  },
  mapTypeId: "satellite",
};

function getPathCoordinates(path) {
  return path.map((item) => ({
    lat: item.position.coordinates[1],
    lng: item.position.coordinates[0],
  }));
}

function getPathCoordinates1(path) {
  const coordinates = {};
  path.forEach(
    (item) =>
      (coordinates[
        `${item.position.coordinates[1].toFixed(
          3
        )},${item.position.coordinates[0].toFixed(3)}`
      ] = item)
  );
  return coordinates;
}

const getMapBounds = (map, paths) => {
  const bounds = new window.google.maps.LatLngBounds();
  paths.forEach((path) =>
    path.forEach((item) => {
      bounds.extend(
        new window.google.maps.LatLng(
          item.position.coordinates[1],
          item.position.coordinates[0]
        )
      );
    })
  );
  return bounds;
};

const fitBounds = (map, paths) => {
  if (map) {
    const bounds = getMapBounds(map, paths);
    map.fitBounds(bounds);
  }
};

/**
 * @return {null}
 */
function RouteGoogleMaps({ paths, navigate }) {
  const [activeCoordinate, setActiveCoordinate] = useState(null);
  return paths ? (
    <GoogleMaps
      navigate={navigate}
      showMap={true}
      mapOptions={mapOptions}
      onLoadMap={(map) => map && fitBounds(map, paths)}
    >
      {paths.map((path) => {
        const pathCoordinates = getPathCoordinates(path);
        const coordinates1 = getPathCoordinates1(path);
        return path.length === 0 ? null : (
          <PolylineF
            key={`path_${path[0].date}`}
            path={pathCoordinates}
            options={{
              strokeColor: "#00ffff",
              strokeOpacity: 1,
              strokeWeight: 4,
            }}
            onMouseOver={(e) => {
              const cord =
                coordinates1[
                  `${e.latLng.lat().toFixed(3)},${e.latLng.lng().toFixed(3)}`
                ];
              setActiveCoordinate(cord);
            }}
            onMouseMove={(e) => {
              const cord =
                coordinates1[
                  `${e.latLng.lat().toFixed(3)},${e.latLng.lng().toFixed(3)}`
                ];
              setActiveCoordinate(cord);
            }}
            onMouseOut={() => {
              setActiveCoordinate(null);
            }}
          />
        );
      })}
      {activeCoordinate && <DetailPopup coordinate={activeCoordinate} />}
    </GoogleMaps>
  ) : null;
}

RouteGoogleMaps.propTypes = {
  route: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  currentLatLng: PropTypes.object,
  mapOptions: PropTypes.object,
  paths: PropTypes.object,
  showDrawing: PropTypes.bool,
  drawingDisabled: PropTypes.bool,
  drawingOptions: PropTypes.object.isRequired,
  markers: PropTypes.array,
  setPolygonReady: PropTypes.func,
  onLoadMap: PropTypes.func,
  showMap: PropTypes.bool,
  step: PropTypes.number,
  polygons: PropTypes.array,
  showInfoWindow: PropTypes.bool,
};

RouteGoogleMaps.defaultProps = {
  route: { name: "{%name%}" },
  zoom: 18,
  drawingOptions: {},
};

export default RouteGoogleMaps;
