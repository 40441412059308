import { actionTypes } from "constants/Reports/sheds";

const initialState = {
  data: {
    content: [],
    page: {},
    isFetching: false
  },
  list: {
    content: [],
    isFetching: false
  },
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_USAGE_TABLE_DATA_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };
    case actionTypes.PRODUCT_USAGE_TABLE_DATA:
      return {
        ...state,
        data: {
          ...action.payload,
          isFetching: false
        }
      };
    case actionTypes.PRODUCT_USAGE_TABLE_DATA_FAILED:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false
        },
        error: action.error
      };
    case actionTypes.PRODUCT_USAGE_TABLE_DATA_LIST_START:
      return {
        ...state,
        list: {
          content: action.payload,
          isFetching: true
        }
      };
    case actionTypes.PRODUCT_USAGE_TABLE_DATA_LIST:
      return {
        ...state,
        list: {
          content: action.payload,
          isFetching: false
        }
      };
    case actionTypes.PRODUCT_USAGE_TABLE_DATA_LIST_FAILED:
      return {
        ...state,
        list: {
          content: action.payload,
          isFetching: false
        },
        error: action.error
      };
    default:
      return state;
  }
};
