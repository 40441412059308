import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, Menu, Segment } from "semantic-ui-react";
import { history } from "../../../store";
import { setSelectedTab } from "actions/Tasks/index";
import styles from "./index.module.css";

const Tasks = ({ selectedGeneralTaskType, navigate }) => {
  const [activeTab, setActiveTab] = useState(selectedGeneralTaskType[0]);
  const scrollContainer = useRef(null);
  const dispatch = useDispatch();
  const handleItemClick = (item) => {
    setActiveTab(item);
  };
  useEffect(() => {
    dispatch(setSelectedTab(activeTab));
  }, [activeTab]);
  const handleMouseWheelScroll = (e) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += e.deltaY;
    }
  };
  return (
    <>
      <Segment className={styles.section}>
        <div className={styles.tabSection}>
          <Menu pointing secondary className={styles.tabsMenu}>
            <span
              style={{ overflowX: "scroll" }}
              ref={scrollContainer}
              onWheel={handleMouseWheelScroll}
              className={`${styles.typeWrapper} ${styles.horizontalScroll}`}
            >
              {selectedGeneralTaskType.map((item) => (
                <Menu.Item
                  key={item.key}
                  name={item?.text}
                  active={activeTab.text === item?.text}
                  onClick={() => handleItemClick(item)}
                />
              ))}
            </span>
          </Menu>
          <div>
            <Button
              className={styles.customizeTabsBtn}
              onClick={() => navigate("/tasks_customization")}
            >
              Customize Tabs
            </Button>
          </div>
        </div>
      </Segment>
    </>
  );
};
Tasks.propTypes = {};
export default connect((state, props) => {
  return {
    selectedGeneralTaskType: state.newTasks.selectedGeneralTaskType,
    tableData: state.generalTasks.data.content,
    isFetching: state.generalTasks.isFetching,
  };
})(Tasks);
