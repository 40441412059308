import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/PackedBoxes";
import uuid from "uuid/v4";
import moment from "moment";

const url = "/packed_boxes";
let uniqId;

export const fetchPackedBoxes = ({
  page,
  size,
  search,
  sort,
  filters,

  unpaged = false,
}) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.FETCH_PACKED_BOXES_START,
    });

    const convertedFilter = Object.fromEntries(filters);
    const newFrom =
      convertedFilter.seasons && convertedFilter.seasons.slice(0, 10);
    const newTo = convertedFilter.seasons && convertedFilter.seasons.slice(-10);
    const from = convertedFilter.from && convertedFilter.from;
    const to = convertedFilter.to && convertedFilter.to;

    delete convertedFilter.seasons;
    delete convertedFilter.from;
    delete convertedFilter.to;
    const newFilters =
      !newFrom || !newTo
        ? {
            ...convertedFilter,
          }
        : {
            ...convertedFilter,
            ...(newFrom && { scannedAtFrom: newFrom }),
            ...(newTo && { scannedAtTo: newTo }),
          };
    const params = new URLSearchParams(newFilters);
    if (unpaged) {
      params.append("unpaged", true);
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    from && params.append("scannedAtFrom", moment(from).format("YYYY-MM-DD"));
    to && params.append("scannedAtTo", moment(to).format("YYYY-MM-DD"));
    search && params.append("search", search);

    (sort || ["id,asc"]).forEach((field) => {
      params.append("sort", field);
    });
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url,
    });

    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.FETCH_PACKED_BOXES,
        payload: res.data,
      });
    }
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_PACKED_BOXES_FAIL,
      payload: error,
    });
  }
};

export const fetchPackedBoxesCountPerEmployee = ({
  page,
  size,
  from,
  to,
  unpaged,
}) => async (dispatch) => {
  try {
    dispatch({ type: actionType.FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE_START });

    const params = new URLSearchParams();
    params.append("from", from);
    params.append("to", to);

    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `${url}/count_per_employee`,
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE,
        payload: res.data,
      });
    }
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE_FAIL,
      payload: error,
    });
  }
};

export const getPackedBoxById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.GET_PACKED_BOXES_BY_ID_START,
    });
    const res = await axiosProxy({
      method: "GET",
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.GET_PACKED_BOXES_BY_ID,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_PACKED_BOXES_BY_ID_FAIL,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const addPackedBoxes = (data) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.ADD_PACKED_BOXES_START,
    });
    const res = await axiosProxy({
      method: "POST",
      data,
      url: `${url}/batch`,
    });

    await dispatch({
      type: actionType.ADD_PACKED_BOXES,
      payload: res.data,
    });

    return res;
  } catch (error) {
    dispatch({
      type: actionType.ADD_PACKED_BOXES_FAIL,
      payload: error,
    });
    return {
      error,
    };
  }
};

export const updatePackedBoxes = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.UPDATE_PACKED_BOXES_START,
    });
    const res = await axiosProxy({
      method: "PUT",
      data,
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.UPDATE_PACKED_BOXES,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_PACKED_BOXES_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const batchUpdatePackedBoxes = (data, packedBoxes) => async (
  dispatch
) => {
  const newData = {
    ...data,
    packedBoxes: packedBoxes
      .filter((item) => data.packedBoxes.some((i) => i == item.id))
      .map((item) => item.barcodeId),
  };
  try {
    dispatch({
      type: actionType.BATCH_UPDATE_PACKED_BOXES_START,
    });
    const res = await axiosProxy({
      method: "PUT",
      data: newData,
      url: `${url}/batch`,
    });

    await dispatch({
      type: actionType.BATCH_UPDATE_PACKED_BOXES,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.BATCH_UPDATE_PACKED_BOXES_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const deletePackedBoxes = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.DELETE_PACKED_BOXES_START,
    });
    await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.DELETE_PACKED_BOXES,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_PACKED_BOXES_FAIL,
      payload: error,
    });
  }
};

export const clearError = () => (dispatch) => {
  dispatch({
    type: actionType.CLEAR_PACKED_BOXES_ERROR,
  });
};
