import { actionTypes } from "../../constants/Reports";

const initialState = {
  data: [],
  barcodes: {},
  loadingBarcodesEmployeeIds: [],
  employeePickedCrops: {
    content: [],
    page: {}
  },
  employeePickedCropsFilters: {},
  barcodesByEmployeeMap: {},
  reportPerAreaCosts: {
    content: [],
    page: {}
  },
  reportPerAreaCostsList: [],
  error: null,
  isFetching: false,
  isBarcodesReportLoading: false,
  isSubmitting: false
};

const toggleLoadingBarcodesByEmployee = (state, employeeId) => {
  const { loadingBarcodesEmployeeIds } = state;
  const newList = loadingBarcodesEmployeeIds.includes(employeeId)
    ? loadingBarcodesEmployeeIds.filter(id => id !== employeeId)
    : [...loadingBarcodesEmployeeIds, employeeId];

  return {
    ...state,
    loadingBarcodesEmployeeIds: newList
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REPORT_TYPES_START:
      return {
        ...state,
        isFetching: true
      };

    case actionTypes.FETCH_REPORT_TYPES:
      return {
        ...state,
        data: action.payload,
        isFetching: false
      };

    case actionTypes.FETCH_REPORT_TYPES_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case actionTypes.FETCH_REPORT_PER_AREA_START:
      return {
        ...state,
        isFetching: true
      };
    case actionTypes.FETCH_REPORT_PER_AREA_LIST_START:
      return {
        ...state,
        isFetching: true
      };

    case actionTypes.FETCH_REPORT_PER_AREA:
      return {
        ...state,
        reportPerAreaCosts: action.payload,
        isFetching: false
      };

    case actionTypes.FETCH_REPORT_PER_AREA_LIST:
      return {
        ...state,
        reportPerAreaCostsList: action.payload,
        isFetching: false
      };

    case actionTypes.FETCH_REPORT_PER_AREA_LIST_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case actionTypes.FETCH_REPORT_PER_AREA_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case actionTypes.SUBMIT_REPORT_START:
      return {
        ...state,
        isSubmitting: true
      };

    case actionTypes.SUBMIT_REPORT:
      return {
        ...state,
        isSubmitting: false
      };

    case actionTypes.SUBMIT_REPORT_FAIL:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload
      };

    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    //Barcodes
    case actionTypes.FETCH_SCANNED_BARCODES_REPORT_START: {
      return {
        ...state,
        isBarcodesReportLoading: true
      };
    }
    case actionTypes.FETCH_SCANNED_BARCODES_REPORT: {
      return {
        ...state,
        barcodes: {
          ...action.payload
        },
        isBarcodesReportLoading: false
      };
    }
    case actionTypes.FETCH_SCANNED_BARCODES_REPORT_FAIL:
      return {
        ...initialState,
        error: action.payload,
        isBarcodesReportLoading: false
      };

    case actionTypes.CLEAR_SCANNED_BARCODES_REPORT_ERROR:
      return {
        ...state,
        error: null
      };

    //Picked Crops per Employee
    case actionTypes.FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case actionTypes.FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT: {
      return {
        ...state,
        employeePickedCrops: {
          ...action.payload
        },
        isFetching: false
      };
    }
    case actionTypes.FETCH_PICKED_CROP_PER_EMPLOYEE_REPORT_FAIL:
      return {
        ...initialState,
        error: action.payload,
        isFetching: false
      };

    case actionTypes.CLEAR_PICKED_CROP_PER_EMPLOYEE_REPORT_ERROR:
      return {
        ...state,
        error: null
      };

    case actionTypes.SET_EMPLOYEE_PICKED_CROPS_FILTERS:
      return {
        ...state,
        employeePickedCropsFilters: action.payload
      };

    case actionTypes.TOGGLE_LOADING_BARCODES_BY_EMPLOYEE:
      return toggleLoadingBarcodesByEmployee(state, action.payload);

    case actionTypes.SET_EMPLOYEE_BARCODES_LIST:
      return {
        ...state,
        barcodesByEmployeeMap: action.payload
      };
    case actionTypes.FETCH_PACKED_BOXES:
      return {
        ...state,
        harvestByArea: action.payload
      };
    case actionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload
      };

    default:
      return state;
  }
};
