import React from "react";
import { createRoot } from "react-dom/client";
import { UserManager, WebStorageStateStore } from "oidc-client";
import configureStore, { createHistory } from "./store";
import "./index.css";
import App from "./App";

import appConfig from "./config";
import * as serviceWorker from "./serviceWorker";
import { getParsableItemsFromStorage } from "./utils/localStorage";

const createUserManager = config => {
  const userStore = new WebStorageStateStore({ store: window.localStorage });
  const userManagerSettings = Object.assign({ userStore }, config);
  const userManager = new UserManager(userManagerSettings);

  userManager.events.addAccessTokenExpired(() => {
    userManager.signinSilent();
  });

  userManager.events.addUserSignedOut(() => {
    userManager.removeUser();
  });

  return userManager;
};

const renderApp = (store, history) => {
  createRoot(document.getElementById("root")).render(
    <React.StrictMode>
      <App store={store} history={history} />
    </React.StrictMode>
  );

  serviceWorker.register();

  // Fix for scroll inputs into view on Android devices
  if (/Android/.test(navigator.appVersion) && window) {
    window.addEventListener("resize", () => {
      if (document.activeElement.tagName === "INPUT") {
        window.setTimeout(() => {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 0);
      }
    });
  }
};

export let userManager = {};
export let userManagerOffline = {};
export let store;
export const requestsCancels = {};

const waitForRegister = async () => {
  while (!window.hasOwnProperty("myServiceWorkerReg")) {
    await new Promise(resolve => setTimeout(resolve, 50));
  }
};

const run = async () => {
  serviceWorker.register(); // Register service worker initially
  if ("serviceWorker" in navigator) {
    await waitForRegister(); // Wait for service worker registration
  }

  userManager = createUserManager(appConfig.oauth2);
  userManagerOffline = new UserManager(appConfig.oauth2Offline);
  store = configureStore({
    initialState: {
      offline: { outbox: [], lastTransaction: 0 },
      appConfig,
      ...(await getParsableItemsFromStorage(["areas", "chemical", "employee"]))
    }
  });

  const history = createHistory(store);

  renderApp(store, history);
};

run().catch(e => console.error(e));
