import React, { useEffect, useState } from "react";
import {
  Icon,
  Form,
  Input,
  Label,
  Segment,
  Menu,
  Button,
} from "semantic-ui-react";
import { Field, Formik, useFormikContext } from "formik";
import { patchListOption, blockListSelectorOption } from "selectors/areas";
import { harvestUnitSelector } from "selectors/harvestUnits";
import styles from "./HarvestUnitsControlModal.module.css";
import DatePicker from "components/DatePicker/DatePicker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { employeeList, employeeOptionsSelector } from "selectors/employee";
import { get } from "lodash";
import MultiSelect from "components/MultiSelect/MultiSelect";
import { getEmployeeList } from "actions/SprayDiary/employee";
import {
  workersFilterOptions,
  supervisorsFilterOptions,
} from "selectors/harvestUnits";
import { area } from "@turf/turf";
import FileChooser from "components/Attachments/FileChooser";
import ObjectId from "bson-objectid";
import { uploadAttachment } from "actions/Chemicals";
import { generalTasksOptionsSelector } from "selectors/generalTasks";

const HarvestUnitsForm = ({ activeItem, areas, setChangeValue, employees }) => {
  const [scanDate, setScanDate] = useState();
  const [data, setData] = useState();
  // const employees = useSelector(employeeList);
  const patchList = useSelector(patchListOption);
  const blockList = useSelector(blockListSelectorOption);
  const harvestUnitsList = useSelector(harvestUnitSelector);
  const generalTaskIDSelector = useSelector(generalTasksOptionsSelector);
  const dispatch = useDispatch();

  const imageIconContainer = {
    padding: "0px",
  };
  const iconContainer = {
    backgroundColor: "black",
    padding: " 0px",
    width: "25px",
    height: "25px",
    paddingLeft: " 5px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "0",
  };

  const closeIcon = {
    color: "white",
    marginRight: "11px",
    cursor: "ponter",
  };

  const dotsContainerStyles = {
    display: "flex",
    justifyContent: "center",
  };

  const dotStyle = {
    margin: "0 12px",
    fontSize: "20px",
    marginTop: "15px",
  };

  const image = {
    width: "136px",
    height: "136px",
    borderRadius: "8px",
    cursor: "default",
  };
  const reader = new FileReader();

  const onDeleteUrl = (changeValue, url, index) => {
    const filtredUrl = url.filter((item, urlIndex) => index !== urlIndex);
    return (
      changeValue("attachments", filtredUrl),
      setChangeValue("attachments", filtredUrl)
    );
  };
  const onDeleteAll = (changeValue) => {
    return changeValue("attachments", []), setChangeValue("attachments", []);
  };
  const onAttachmentSelected = (
    fieldName,
    multi = true,
    setFieldValue,
    values
  ) => (files) => {
    files.forEach((file) => {
      const dataToSend = {
        file,
        idReplace: ObjectId().toHexString(),
        callBack: (loadedFile) => {
          return (
            setFieldValue(
              fieldName,
              !multi
                ? loadedFile
                : values[fieldName]
                ? [
                    ...values[fieldName],
                    {
                      id: loadedFile.id,
                      name: loadedFile.name,
                      presignedUrl: loadedFile.data,
                      subPath: loadedFile.subPath,
                    },
                  ]
                : [loadedFile]
            ),
            setChangeValue(fieldName, [
              ...values[fieldName],
              {
                id: loadedFile.id,
                name: loadedFile.name,
                presignedUrl: loadedFile.data,
                subPath: loadedFile.subPath,
              },
            ])
          );
        },
      };
      reader.addEventListener("error", () => {
        console.error(`Error occurred reading file`);
      });
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(uploadAttachment(dataToSend)).catch(() => {});
      };
    });
    setChangeValue("attachments", values.attachments);
  };
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={
        activeItem || {
          scannedAt: "",
          attachments: [],
          supervisors: [],
          employeeBinParts: [],
          blockIds: "",
          patchIds: "",
          row: "",
          price: "",
          harvestUnitIds: "",
        }
      }
    >
      {({ values, dirty, isValid, setValues, setFieldValue, handleSubmit }) => {
        values = {
          ...values,
          area: areas.content.find((item) => item.id === values.areaId),
        };
        return (
          <Form>
            <Segment className={styles.editModalSegment}>
              <Form.Group>
                <Form.Field className={styles.formField} required>
                  <label>Scan date</label>
                  <DatePicker
                    name="scannedAt"
                    trigger={false}
                    focused={false}
                    value={values.scannedAt}
                    onChange={(scannedAt) => {
                      return (
                        setScanDate(scannedAt),
                        setValues({
                          ...values,
                          scannedAt: moment(scannedAt)
                            .add(1, "days")
                            .utc()
                            .format("YYYY-MM-DDTHH:mm:ss"),
                        }),
                        setChangeValue(
                          "scannedAt",
                          moment(scannedAt)
                            .add(1, "days")
                            .utc()
                            .format("YYYY-MM-DDTHH:mm:ss")
                        )
                      );
                    }}
                    disableClear
                  />
                </Form.Field>
                <Form.Field className={styles.formField}>
                  <label>Supervisor</label>
                  <Field
                    fluid
                    className={styles.selectWorkersField}
                    required
                    as={MultiSelect}
                    search={true}
                    text={
                      values.supervisors?.length
                        ? `${values.supervisors?.length} workers`
                        : ""
                    }
                    selector={supervisorsFilterOptions}
                    value={values.supervisors.map(
                      (item) => item.employeeId || item
                    )}
                    name="supervisors"
                    loaderAction={getEmployeeList}
                    onOptionChange={(name, value) => {
                      return (
                        setFieldValue(`supervisors`, value),
                        setChangeValue(`supervisors`, value)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field className={styles.formField}>
                  <label>Worker</label>
                  <Field
                    fluid
                    className={styles.selectWorkersField}
                    required
                    as={MultiSelect}
                    search={true}
                    text={
                      values.employeeBinParts?.length
                        ? `${values.employeeBinParts?.length} workers`
                        : ""
                    }
                    selector={workersFilterOptions}
                    value={values.employeeBinParts?.map(
                      (item) => item?.employeeId || item
                    )}
                    name="employeeBinParts"
                    onOptionChange={(name, value) => {
                      return (
                        setFieldValue("employeeBinParts", value),
                        setChangeValue(`employeeBinParts`, value)
                      );
                    }}
                    loaderAction={getEmployeeList}
                  />
                </Form.Field>
              </Form.Group>
              {!!values.employeeBinParts?.length && (
                <div className={styles.workersRow}>
                  <span className={styles.workersRowHead}>Workers:</span>{" "}
                  {values.employeeBinParts.map((item) => {
                    return employees.map(({ id, lastName, firstName }) => {
                      return (
                        id === (item?.employeeId || item) && (
                          <Label className={styles.addedItem}>
                            {`${firstName} ${lastName}`}
                            <Icon
                              className={styles.closeIcon}
                              name="close"
                              onClick={() => {
                                const filteredValues = values.employeeBinParts.filter(
                                  (id) => item !== id
                                );
                                setValues({
                                  ...values,
                                  employeeBinParts: filteredValues,
                                });
                              }}
                            />
                          </Label>
                        )
                      );
                    });
                  })}
                </div>
              )}
              <Form.Group>
                <Form.Field className={styles.formField}>
                  <label>Block</label>
                  <Form.Dropdown
                    selection
                    search
                    name="block"
                    options={blockList.options}
                    value={
                      values.area?.type === "PATCH"
                        ? !values.blockIds
                          ? areas.content.find(
                              (item) => item.id === values.area?.id
                            )?.parent?.id
                          : values.blockIds
                        : values.blockIds || values.areaId
                    }
                    onChange={(e, { value }) => {
                      return (
                        setValues({
                          ...values,
                          blockIds: value,
                        }),
                        setChangeValue("blockIds", value)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field className={styles.formField}>
                  <label>Patch</label>
                  <Form.Dropdown
                    selection
                    search
                    name="patch"
                    options={
                      values.blockIds && values.area?.type === "PATCH"
                        ? areas.content
                            .filter(
                              (item) =>
                                item.parent &&
                                item.parent.id === values.blockIds
                            )
                            .map(({ id, name }) => ({
                              key: id,
                              text: name,
                              value: id,
                            }))
                        : !values.blockIds && values.area?.type === "PATCH"
                        ? areas.content
                            .filter((item) => {
                              return (
                                item.parent &&
                                item.parent.id === values.area?.parent.id
                              );
                            })
                            .map(({ id, name }) => ({
                              key: id,
                              text: name,
                              value: id,
                            }))
                        : areas.content
                            .filter((item) => {
                              return (
                                item.parent &&
                                item.parent.id ===
                                  (values.blockIds || values.area?.id)
                              );
                            })
                            .map(({ id, name }) => ({
                              key: id,
                              text: name,
                              value: id,
                            }))
                    }
                    value={
                      !values.patchIds
                        ? values.area?.type === "PATCH" && values.areaId
                        : values.patchIds
                    }
                    onChange={(e, { value }) => {
                      return (
                        setValues({
                          ...values,
                          patchIds: value,
                        }),
                        setChangeValue("patchIds", value)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field className={styles.formField}>
                  <label>Row</label>
                  <Field
                    as={Input}
                    name="row"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={values.row}
                    onChange={(e, { value }) => {
                      return (
                        setFieldValue("row", value),
                        setChangeValue("row", value)
                      );
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field className={styles.formField} required>
                  <label>Harvest unit</label>
                  <Form.Dropdown
                    selection
                    search
                    name="harvestUnitIds"
                    options={harvestUnitsList.options}
                    value={
                      values.harvestUnitIds || get(values.harvestUnit, "id")
                    }
                    onChange={(e, { value }) => {
                      return (
                        setValues({
                          ...values,
                          harvestUnitIds: value,
                        }),
                        setChangeValue("harvestUnitIds", value)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field className={styles.formField}>
                  <label>Price</label>
                  <Field
                    as={Input}
                    name="price"
                    onKeyPress={(event) => {
                      if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={values.price}
                    onChange={(e, { value }) => {
                      return (
                        setFieldValue("price", value),
                        setChangeValue("price", value)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field className={styles.formSmallField}>
                  <label>Task ID</label>
                  <Form.Dropdown
                    className={styles.formSmallDropDown}
                    selection
                    search
                    name="taskId"
                    options={generalTaskIDSelector}
                    value={get(values, "generalTaskId")}
                    onChange={(e, { value }) => {
                      return (
                        setFieldValue("generalTaskId", value),
                        setChangeValue("generalTaskId", value)
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field className={styles.formSmallField}>
                  <label>Internal bin ID</label>
                  <Field
                    as={Input}
                    name="internalBinId"
                    value={values.internalBinId}
                    onChange={(e, { value }) => {
                      return (
                        setFieldValue("internalBinId", value),
                        setChangeValue("internalBinId", value)
                      );
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </Segment>
            <Segment className={styles.editModalSegment}>
              <Form.Group className={styles.headerContainer}>
                <Form.Field className={styles.imageSegmentHeader}>
                  Image
                </Form.Field>
                <Form.Field className={styles.deleteButtonContainer}>
                  {!!values.attachments.length && (
                    <Button
                      className={styles.deleteButton}
                      onClick={() => onDeleteAll(setFieldValue)}
                    >
                      Delete All
                    </Button>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group className={styles.imageSegment}>
                {values.attachments.map((slide, slideIndex) => (
                  <div style={dotsContainerStyles}>
                    <div style={dotStyle} key={slideIndex} onClick={() => {}}>
                      <Menu compact>
                        <Menu.Item style={imageIconContainer} as="a">
                          <img style={image} src={slide.presignedUrl} />
                          <Label
                            onClick={() => {
                              return onDeleteUrl(
                                setFieldValue,
                                values.attachments,
                                slideIndex
                              );
                            }}
                            style={iconContainer}
                            floating
                          >
                            <Icon
                              size="small"
                              color="white"
                              name="close"
                              style={closeIcon}
                            />
                          </Label>
                        </Menu.Item>
                      </Menu>
                    </div>
                  </div>
                ))}
                <FileChooser
                  forImage={true}
                  className={styles.generalAttachmentsButton}
                  onChange={onAttachmentSelected(
                    "attachments",
                    true,
                    setFieldValue,
                    values
                  )}
                  label={
                    <div className={styles.addImageContainer}>
                      <Icon color="#0E9354" size="small" name="plus" />
                    </div>
                  }
                  // disabled={isUploadingAttachment}
                />
              </Form.Group>
            </Segment>
          </Form>
        );
      }}
    </Formik>
  );
};

export default HarvestUnitsForm;
