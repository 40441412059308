import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Dropdown } from "semantic-ui-react";
import { onlineSelector } from "../../../../../selectors/common";
import styles from "./SelectChemicals.module.css";
import { getBatchNumbersLastYear } from "actions/Spray/tasks";

const SelectChemicalDropdown = ({
  chemicalsList,
  value,
  shedId,
  applicationType,
  list,
  selectedChemicals,
  validation,
  onChange,
  id,
  online
}) => {
  const dispatch = useDispatch();
  const selectedShedChemiicalId = list.content
    .find(({ id }) => id === shedId)
    ?.buckets.map(item => item.chemicalId);

  const selectedShedProduct = chemicalsList.content?.filter(product => {
    return selectedShedChemiicalId?.some(
      chemicalId => product.id === chemicalId
    );
  });

  const selectedShedProductsId = (applicationType === "SPRAY"
    ? selectedShedProduct
    : selectedShedProduct
        ?.filter(ch => ch.rates?.some(rate => rate.rateType === "PER_HECTARE"))
        .map(i => {
          return {
            ...i,
            rates: i.rates.filter(r => r.rateType === "PER_HECTARE")
          };
        })
  )
    ?.map(item => ({
      key: `-${item.id}`,
      text: item.name,
      value: item.id
    }))
    .sort(function(a, b) {
      var nameA = a.text?.toLowerCase(),
        nameB = b.text?.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

  const test = (applicationType === "SPRAY"
    ? chemicalsList.content
    : chemicalsList.content.filter(ch =>
        ch.rates.some(rate => rate.rateType === "PER_HECTARE")
      )
  )
    .map(i => {
      return {
        ...i,
        rates: i.rates.filter(r => r.rateType === "PER_HECTARE")
      };
    })
    .filter(product => {
      return selectedShedChemiicalId?.every(
        chemicalId => product.id != chemicalId
      );
    });

  const restProducts = [
    {
      key: -5,
      text: <b>Other products</b>,
      value: -5,
      className: "restProductsHeaders"
    },
    ...test
      ?.map(item => ({
        key: item.id,
        text: item.name,
        value: item.id,
        color: "#fffaf2",
        className: "restProducts"
      }))
      .sort(function(a, b) {
        var nameA = a.text?.toLowerCase(),
          nameB = b.text?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      })
  ];

  const selectedIds = selectedChemicals.filter(c => !c.toAdd).map(c => c.id);

  const sortedProductList = [
    /*  {
      key: -1,
      text: "+ Add new product",
      value: -1
    }, */
    {
      key: -3,
      text: (
        <b>{`${
          list.content.find(item => item.id === shedId)?.name
        } inventory`}</b>
      ),
      value: -3,
      className: "selectedShedProductsHeaders"
    },
    ...selectedShedProductsId
  ];

  const optionsWithSelectedShed = useMemo(
    () =>
      sortedProductList
        ? sortedProductList
            .filter(chemical =>
              chemical.archived
                ? id === "new"
                  ? false
                  : chemical.id === value
                : true
            )
            .filter(listItem => !selectedIds.includes(listItem.id))
        : [],
    [chemicalsList, selectedIds, value, id]
  );

  const filtredOptions = useMemo(
    () =>
      chemicalsList && chemicalsList.content
        ? chemicalsList.content
            .filter(chemical =>
              chemical.archived
                ? id === "new"
                  ? false
                  : chemical.id === value
                : true
            )
            .filter(listItem => !selectedIds.includes(listItem.id))
        : [],
    [chemicalsList, selectedIds, value, id]
  );

  const optionsList = (applicationType === "SPRAY"
    ? filtredOptions
    : filtredOptions
        .filter(ch => ch.rates.some(rate => rate.rateType === "PER_HECTARE"))
        .map(i => {
          return {
            ...i,
            rates: i.rates.filter(r => r.rateType === "PER_HECTARE")
          };
        })
  )
    .map(item => {
      return {
        key: item.id,
        text: item.name,
        value: item.id
      };
    })
    .sort(function(a, b) {
      var nameA = a.text?.toLowerCase(),
        nameB = b.text?.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

  const [searchQuery, setSearchQuery] = useState("");
  return (
    <Dropdown
      error={!validation && !value}
      search
      fluid
      searchQuery={searchQuery}
      onClose={() => setSearchQuery("")}
      onSearchChange={(_, { searchQuery }) => setSearchQuery(searchQuery)}
      noResultsMessage="Nothing was found"
      selectOnBlur={false}
      selectOnNavigation={false}
      closeOnChange={(_, data) => !!data.value}
      selection
      placeholder="Select chemical"
      onChange={(_, data) => {
        return (
          dispatch(
            getBatchNumbersLastYear(
              chemicalsList.content.filter(item => item.id == data.value)
            )
          ),
          onChange(id, data.value)
        );
      }}
      value={value || null}
      loading={chemicalsList.isFetching && online}
      options={
        shedId && !!list.content.length ? optionsWithSelectedShed : optionsList
      }
    />
  );
};

const mapStateToProps = state => {
  const {
    sprayDiary: {
      chemicalsList,
      applicationType,
      shedId,
      selectedChemicals,
      validations: {
        selectedChemicals: selectedChemicalsValidation,
        addChemicalSelection: addChemicalSelectionValidation
      }
    },
    shed: { list }
  } = state;

  return {
    chemicalsList,
    shedId,
    applicationType,
    list,
    selectedChemicals,
    validation: selectedChemicalsValidation && addChemicalSelectionValidation,
    online: onlineSelector(state)
  };
};

SelectChemicalDropdown.propTypes = {
  validation: PropTypes.bool,
  online: PropTypes.bool,
  selectedChemicals: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.number,
  value: PropTypes.any,
  chemicalsList: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectChemicalDropdown);
