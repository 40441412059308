import React, { useEffect, useState } from "react";
import { Segment, Table, Icon, Button } from "semantic-ui-react";
import styles from "../Sidebar.module.css";
import { StatusChip } from "routes/WorkingTime/components/StatusChip";
import { connect } from "react-redux";
import moment from "moment";

const ClockDetails = ({
  setForWtAdd,
  activeItem = {},
  areas,
  index,
  employees,
  subRowsIndex,
  setClockOutOpen,
  setSubRowsIndex
}) => {
  const [open, setOpen] = useState(false);
  const filtredArea = areas.filter(item =>
    activeItem?.areas?.some(i => i.areaId === item.id)
  );
  const allFarm = filtredArea.map(item => item.farm.name);
  const activeEmployee = employees.find(
    item => item.id === activeItem.employeeId
  );
  const start = new Date(activeItem.startAt);
  const end = new Date(activeItem.endAt);
  const diffInMs = end - start;
  const diffInHours = diffInMs / (1000 * 60 * 60);
  useEffect(() => {
    (index === subRowsIndex || subRowsIndex == "totalRow") && setOpen(true);
  });
  return (
    <Segment className={styles.segment}>
      <div className={styles.rowFieldWrapper}>
        <span className={styles.nameRowField}>
          {" "}
          {`${activeEmployee?.firstName} ${activeEmployee?.lastName}`}
        </span>
        <span className={styles.btnContainer}>
          <Button
            onClick={() => {
              return (
                setClockOutOpen(true), setSubRowsIndex(index), setForWtAdd(true)
              );
            }}
            className={styles.clockOutBtn}
          >
            Clock Out
          </Button>
        </span>
        <span className={styles.iconRowField}>
          <Icon
            className={styles.rowChevronIcon}
            onClick={() => (setSubRowsIndex(false), setOpen(!open))}
            name={!!open ? `chevron up` : `chevron down`}
          />
        </span>
      </div>
      {(open || index === subRowsIndex || subRowsIndex == "totalRow") && (
        <Table className={styles.taskDetailsTable} definition fixed>
          <Table.Body>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>Farm</Table.Cell>
              <Table.Cell> {allFarm.join(", ")}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>Areas</Table.Cell>
              <Table.Cell>
                {filtredArea.map(item => item?.name).join(", ")}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>Clock in</Table.Cell>
              <Table.Cell>
                {moment(activeItem.startAt).format("DD/MM/YYYY hh:mm A")}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Clock out
              </Table.Cell>
              <Table.Cell>-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Lunch interval (min)
              </Table.Cell>
              <Table.Cell>-</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Total working time
              </Table.Cell>
              <Table.Cell> -</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                No. of pieces
              </Table.Cell>
              <Table.Cell> -</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Piece rate ($)
              </Table.Cell>
              <Table.Cell> -</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>
                Approved by
              </Table.Cell>
              <Table.Cell> -</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className={styles.headerSection}>Comments</Table.Cell>
              <Table.Cell> -</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </Segment>
  );
};

export default connect((state, props) => {
  return {
    employees: state.employee.list.content,
    areas: state.areas.list.content,
    farms: state.farms.summary
  };
})(ClockDetails);
