import React from "react";
import styles from "../ClockOut.module.css";
import { isNaN } from "lodash";

const ClockoutForm = ({ activeItem, values }) => {
  const wtData = values?.children.map(i => ({
    startAt: i.startAt,
    endAt: i.endAt,
    lunchInterval: i.lunchInterval
  }));

  const result = wtData
    ?.filter(wt => wt.startAt && wt.endAt)
    .map(item => {
      const start = new Date(item.startAt);
      const end = new Date(item.endAt);

      let diffInMs = end - start;
      if (diffInMs < 0) {
        diffInMs += 24 * 60 * 60 * 1000;
      }
      const diffInHours = diffInMs / (1000 * 60 * 60);
      return {
        diffInHours: (diffInHours - item.lunchInterval / 60).toFixed(2)
      };
    });
  function convertToHoursMinutes() {
    const total = result?.reduce((prev, curr) => +prev + +curr.diffInHours, 0);

    const hours = Math.floor(total);
    const minutes = Math.round((total - hours) * 60);
    return !isNaN(hours) && !isNaN(minutes) ? `${hours}h ${minutes}m` : "-";
  }

  return (
    <div className={styles.content}>
      <div className={styles.fixedContent}>
        <div className={styles.fixedContentRow}>
          <div>
            <span className={styles.fixedContentRowId}>
              {`GT-${activeItem?.id}`}
              {`${activeItem?.taskName}`}
            </span>
            <span className={styles.fixedContentRowType}>
              Type:
              <span style={{ color: "#212121", paddingLeft: "8px" }}>
                {activeItem?.type}
              </span>
            </span>
          </div>
          <div>
            <span className={styles.fixedContentRowTotalWt}>
              Total time:
              <span style={{ color: "#212121", paddingLeft: "8px" }}>
                {convertToHoursMinutes()}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.scrollableContent}></div>
    </div>
  );
};

export default ClockoutForm;
