import React, { useCallback, useEffect, useState } from "react";
import { Input, Button, Icon, Segment, Form, Label } from "semantic-ui-react";
import { connect, useDispatch, useSelector } from "react-redux";
import styles from "../ClockIn.module.css";
import { fetchEmployeeGroupsList } from "actions/EmployeeGroups/employeeGroups";
import { fetchContractorsList } from "actions/Contractors/contractors";
import AllEmployees from "./Sections/AllEmployees";
import EmployeeGroup from "./Sections/EmployeeGroup";
import Contractor from "./Sections/Contractor";

import moment from "moment";
import Datetime from "react-datetime";
import { employeeViewer } from "routes/Tasks/components/helper";
import AreasList from "routes/WorkingTime/components/AreasList";

const tabTypes = {
  ALL: "ALL",
  GROUP: "GROUP",
  CONTRACTOR: "CONTRACTOR"
};

const employeeTabs = [
  {
    key: "all",
    type: tabTypes.ALL,
    text: "All employees"
  },
  {
    key: "group",
    type: tabTypes.GROUP,
    text: "Group"
  },
  {
    key: "contractor",
    type: tabTypes.CONTRACTOR,
    text: "Contractor"
  }
];

const EmployeeSection = ({
  areas,
  values,
  setFieldValue,
  areasOptions,
  employeeGroupsWithEmployees,
  employeesList,
  contractorsWithEployees
}) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [openRows, setOpenRows] = useState({});
  const [activeTabType, setActiveTabType] = useState(tabTypes.ALL);

  useEffect(() => {
    !employeeGroupsWithEmployees.length && dispatch(fetchEmployeeGroupsList());
    !contractorsWithEployees.length && dispatch(fetchContractorsList());
  }, [employeeGroupsWithEmployees, employeesList, contractorsWithEployees]);

  const onSearchChange = useCallback((_, { value: search }) => {
    setSearch(search);
  }, []);

  const filterBasedOnSearch = arr => {
    return arr.filter(({ firstName, lastName }) =>
      `${firstName} ${lastName}`
        .toUpperCase()
        .replace(/\s/g, "")
        .includes(search.toUpperCase().replace(/\s/g, ""))
    );
  };

  const toggleRow = id => {
    setOpenRows(prevOpenRows => ({
      ...prevOpenRows,
      [id]: !prevOpenRows[id]
    }));
  };

  return (
    <div className={styles.btnContentWrapper}>
      <div className={styles.serchRow}>
        <Input
          value={search}
          className={styles.searchInput}
          placeholder="Search"
          onChange={onSearchChange}
        />
        <Button.Group basic className={styles.buttonsGroup} fluid>
          {employeeTabs.map(({ key, type, text }) => (
            <Button
              key={key}
              type="button"
              onClick={() => setActiveTabType(type)}
              className={activeTabType === type ? styles.buttonActive : null}
              basic
            >
              {text}
            </Button>
          ))}
        </Button.Group>
      </div>
      <div className={styles.contentSection}>
        {activeTabType === tabTypes.ALL ? (
          <AllEmployees
            employee={filterBasedOnSearch(employeesList)}
            setFieldValue={setFieldValue}
            values={values}
          />
        ) : activeTabType === tabTypes.GROUP ? (
          <EmployeeGroup
            employeeGroups={employeeGroupsWithEmployees
              .map(({ employees, ...rest }) => ({
                ...rest,
                employees: filterBasedOnSearch(employees)
              }))
              .filter(({ employees }) => !!employees.length)}
            setFieldValue={setFieldValue}
            values={values}
            expandAll={!!search}
          />
        ) : (
          <Contractor
            employee={employeesList}
            contractors={contractorsWithEployees
              .map(({ employeeIds, ...rest }) => {
                const employeesFiltered = employeesList.filter(e =>
                  employeeIds.includes(e.id)
                );

                return {
                  ...rest,
                  employeeIds: filterBasedOnSearch(employeesFiltered).map(
                    ({ id }) => id
                  )
                };
              })
              .filter(({ employeeIds }) => !!employeeIds.length)}
            setFieldValue={setFieldValue}
            values={values}
            expandAll={!!search}
          />
        )}
        {values.children.map((item, index) => {
          const activeEmployee = employeesList.find(
            e => e.id === item.employeeId
          );

          return (
            <Segment key={item.employeeId} className={styles.segment}>
              <div className={styles.rowFieldWrapper}>
                <div className={styles.employeeIconWrapper}>
                  <span className={styles.iconRowField}>
                    <Icon
                      className={styles.rowChevronIcon}
                      onClick={() => toggleRow(item.employeeId)}
                      name={`chevron ${
                        openRows[item.employeeId] ? "up" : "down"
                      }`}
                    />
                  </span>
                  <span className={styles.nameRowField}>
                    {employeeViewer(
                      [{ employeeId: item.employeeId }],
                      employeesList
                    )}
                    {`${activeEmployee.firstName} ${activeEmployee.lastName}`}
                  </span>
                </div>
                <span className={styles.iconRowField}>
                  <Icon
                    onClick={() =>
                      setFieldValue(
                        "children",
                        values.children.filter(
                          i => i.employeeId !== item.employeeId
                        )
                      )
                    }
                    className={styles.deleteIcon}
                    name="trash alternate"
                  />
                </span>
              </div>
              {openRows[item.employeeId] && (
                <div className={styles.openRows}>
                  <Form.Group widths={16}>
                    <Form.Field required width={8}>
                      <label className={styles.formLabel}>Date and time</label>
                      <div className={styles.calendarWrapper}>
                        <Datetime
                          value={
                            values.children[index].startAt
                              ? moment(values.children[index].startAt)
                              : null
                          }
                          dateFormat="DD/MM/YYYY"
                          timeFormat="HH:mm"
                          inputProps={{
                            placeholder: "dd/mm/yyyy hh:mm",
                            readOnly: false
                          }}
                          onChange={selectedDate => {
                            const formattedDate = selectedDate
                              ? moment(selectedDate).format(
                                  "YYYY-MM-DDTHH:mm:ss"
                                )
                              : null;
                            setFieldValue(
                              `children[${index}].startAt`,
                              formattedDate
                            );
                          }}
                          className={styles.expirCalendar}
                        />
                        <div className={styles.calendarIcon}>
                          <Icon name="calendar outline" />
                        </div>
                      </div>
                    </Form.Field>
                    <Form.Field required width={8}>
                      <label className={styles.formLabel}>Areas </label>
                      <AreasList
                        text={
                          !!values.children[index]?.areas?.length &&
                          `${values.children[index]?.areas?.length} areas`
                        }
                        onChange={(_, data) => {
                          return setFieldValue(`children[${index}].areas`, [
                            ...values.children[index]?.areas,
                            data
                          ]);
                        }}
                        options={areasOptions.filter(
                          i => !values.children[index]?.areas.includes(i.value)
                        )}
                        disabled={!values.generalTaskId}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field width={8} />
                    <Form.Field width={8}>
                      <Form.Group>
                        {!!values.children[index]?.areas?.length && (
                          <Form.Field>
                            <div className={styles.areasRow}>
                              <span className={styles.areasRowHead}>
                                Areas:
                              </span>
                              {values.children[index]?.areas?.map(item => {
                                return areas.map(({ id, name, parent }) => {
                                  return (
                                    id === (item?.areaId || item) && (
                                      <Label className={styles.addedItem}>
                                        <span className={styles.addedItemValue}>
                                          {" "}
                                          {parent
                                            ? `${parent.name}/ ${name}`
                                            : name}
                                        </span>
                                        <Icon
                                          className={styles.closeIcon}
                                          name="close"
                                          onClick={() => {
                                            const filteredValues = values.children[
                                              index
                                            ]?.areas?.filter(i => item !== i);
                                            setFieldValue(
                                              `children[${index}].areas`,
                                              filteredValues
                                            );
                                          }}
                                        />
                                      </Label>
                                    )
                                  );
                                });
                              })}
                            </div>
                          </Form.Field>
                        )}
                      </Form.Group>
                    </Form.Field>
                  </Form.Group>
                </div>
              )}
            </Segment>
          );
        })}
      </div>
    </div>
  );
};

export default connect(state => {
  return {
    employeeGroupsWithEmployees: state.employeeGroups?.list.content.filter(
      c => c.employeeIds.length
    ),
    employeesList: state.employee.list.content,
    contractorsWithEployees: state.contractors.list.content.filter(
      c => c.employeeIds.length
    ),
    areas: state.areas.list.content
  };
})(EmployeeSection);
