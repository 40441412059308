import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Confirm, Grid, Icon, Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import { onlineSelector } from "../../selectors/common";
import { setNewFarm } from "../../actions/Farms/farms";
import { initialState } from "../../reducers/Farms";
import { bindActionCreators } from "redux";

// eslint-disable-next-line no-unused-vars
import styles from "./HeaderOverlay.css";

class HeaderOverlay extends Component {
  state = { confirmOpen: false, somethingChanged: false };

  onChangeEvent = () => {
    const { somethingChanged } = this.state;
    if (!somethingChanged) {
      this.setState({ somethingChanged: true });
    }
  };

  componentDidMount() {
    //TODO: find a better wat to do it, it can happen that the user click but didn't changed anything
    document.addEventListener("click", this.onChangeEvent);
    document.addEventListener("change", this.onChangeEvent);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onChangeEvent);
    document.removeEventListener("change", this.onChangeEvent);
  }

  openConfirm = (event) => {
    event.preventDefault();
    const { somethingChanged } = this.state;
    this.props.actions.setNewFarm({ newFarm: initialState.newFarm });
    if (somethingChanged) {
      this.setState({ confirmOpen: true });
    } else {
      this.onConfirm();
    }
  };

  closeConfirm = () => this.setState({ confirmOpen: false });

  onConfirm = () => {
    const { withGoBack, closeLink, navigate } = this.props;
    withGoBack ? navigate(-1) : navigate(closeLink);
  };

  render() {
    const { route, closeLink, online, footer } = this.props;
    const { confirmOpen } = this.state;
    const logoColumn = footer
      ? {
          width: 8,
        }
      : {
          width: 12,
        };
    //const isMobile = useMediaQuery({ maxWidth: 767 })
    return (
      <Segment basic className="header-main">
        <MediaQuery minWidth={768}>
          <Grid>
            <Grid.Row>
              <Grid.Column
                mobile={12}
                tablet={7}
                computer={7}
                className="logo-column"
              >
                <div className="vertical-middle">
                  <div>
                    <Link to={closeLink}>
                      <div className="symbol-tuf" />
                    </Link>
                  </div>
                  <div className="title">{route.name}</div>
                  {!online && <div className="status-offline">Offline</div>}
                </div>
              </Grid.Column>

              {closeLink && (
                <Grid.Column className="column-close" floated="right">
                  <div className="vertical-middle center">
                    <Button
                      className={"transparent"}
                      icon={<Icon className="tuf-times" size="large" />}
                      onClick={this.openConfirm}
                    />
                    <Confirm
                      open={confirmOpen}
                      onCancel={this.closeConfirm}
                      onConfirm={this.onConfirm}
                      content="Any changes you have made will be lost"
                    />
                  </div>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <Grid className={`header-grid ${footer ? "with-footer" : ""}`}>
            <Grid.Row>
              <Grid.Column {...logoColumn} className="logo-column">
                <div className={`vertical-middle ${footer ? "center" : ""}`}>
                  <div className="title">{route.name}</div>
                  {!online && <div className="status-offline">Offline</div>}
                </div>
              </Grid.Column>
              {closeLink && (
                <Grid.Column width={2} className="column-close" floated="right">
                  <div className={`vertical-middle ${footer ? "" : "center"}`}>
                    <Button
                      icon={<Icon className="tuf-times" size="large" />}
                      type="button"
                      onClick={this.openConfirm}
                    />

                    <Confirm
                      open={confirmOpen}
                      onCancel={this.closeConfirm}
                      onConfirm={this.onConfirm}
                      content="Any changes you have made will be lost"
                    />
                  </div>
                </Grid.Column>
              )}
              {footer && (
                <Grid.Column className="column-buttons" width={4}>
                  <div className="vertical-middle">{footer}</div>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </MediaQuery>
      </Segment>
    );
  }
}

HeaderOverlay.propTypes = {
  route: PropTypes.object.isRequired,
  closeLink: PropTypes.string.isRequired,
  withGoBack: PropTypes.bool,
  online: PropTypes.bool,
  footer: PropTypes.object,
};

HeaderOverlay.defaultProps = {
  route: { name: "{%name%}" },
  closeLink: "/",
};

const mapStateToProps = (state) => ({ online: onlineSelector(state) });
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setNewFarm,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderOverlay);
