import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import Loader from "../../../components/Loader";
import { abilitiesSelector } from "../../../selectors/user";
import { getParentPageHref } from "../../../utils/routeHelpers";
import { history } from "../../../store";
import LayoutOverlay from "../../../components/LayoutOverlay";
// eslint-disable-next-line
import styles from "./AddPackingSheds.module.css";
import { getPackingShedById } from "../../../actions/PackingSheds/packingSheds";
import PackingShedsForm from "./PackingShedsForm/PackingShedsForm";

class AddPackingSheds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      packingShed: null,
    };
    this.packingShedForm = React.createRef();
  }
  async componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getPackingShed(id);
    } else {
      this.setState({
        isFetching: false,
      });
    }
  }

  getPackingShed = (id) => {
    const {
      actions: { getPackingShedById },
    } = this.props;
    this.setState({
      isFetching: true,
    });

    getPackingShedById(id)
      .then((packingShed) => {
        this.setState({
          isFetching: false,
          packingShed,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.props.navigate("/404");
        }
        this.setState({
          isFetching: false,
        });
      });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;
    const { id } = match.params;

    if (id && id !== prevProps.match.params.id) {
      this.getPackingShed(id);
    }
  }

  doSubmitForm = () => {
    if (this.packingShedForm.current) {
      this.packingShedForm.current.submit();
    }
  };

  render() {
    const { packingShed, isFetching } = this.state;
    const { route, match, Can, navigate } = this.props;
    const { id } = match.params;
    return (
      <LayoutOverlay
        closeLink={getParentPageHref(route)}
        route={{
          ...route,
          name: id ? route.name.replace("Add", "Edit") : route.name,
        }}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            {packingShed && packingShed.id ? (
              <Can I="update" a="packing_sheds">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            ) : (
              <Can I="add" a="packing_sheds">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            )}
          </div>
        }
      >
        {isFetching ? (
          <Loader />
        ) : (
          <PackingShedsForm
            navigate={navigate}
            initialValues={packingShed}
            formRef={this.packingShedForm}
          />
        )}
      </LayoutOverlay>
    );
  }
}

AddPackingSheds.propTypes = {
  route: PropTypes.object.isRequired,
  packingShed: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  Can: PropTypes.func,
};

AddPackingSheds.defaultProps = {
  route: { name: "{%name%}" },
};

const mapStateToProps = (state) => {
  return {
    Can: abilitiesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ getPackingShedById }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPackingSheds);
