import moment from "moment";
import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/Employees";
import genericAttachmentUploader from "../../utils/genericAttachmentUploader";
import { isNil, omitBy } from "lodash";
import { getEmployeeList as setSprayDiaryEmployees } from "../SprayDiary/employee";
import uuid from "uuid/v4";

const url = "/employees";
const changeLogUrl = "/changeLog";
let uniqId;

export const addEmployee = (data, returnError) => async dispatch => {
  try {
    const newData =
      data.attachments &&
      data.attachments.map(item => ({
        name: item.name,
        subPath: item.subPath,
        id: item.id
      }));
    dispatch({
      type: actionType.ADD_EMPLOYEE_START
    });
    const res = await axiosProxy({
      method: "POST",
      data: {
        ...data,
        attachments: newData
      },
      url
    });

    await dispatch({
      type: actionType.ADD_EMPLOYEE,
      payload: { ...res.data }
    });
    dispatch(fetchEmployeesLog());
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_EMPLOYEE_FAIL,
      payload: error
    });
    if (returnError) {
      return { error };
    }
  }
};

export const updateEmployee = (employee, returnError) => async dispatch => {
  try {
    const newData =
      employee.attachments &&
      employee.attachments.map(item => ({
        name: item.name,
        subPath: item.subPath,
        id: item.id
      }));
    dispatch({
      type: actionType.UPDATE_EMPLOYEE_START
    });
    const res = await axiosProxy({
      method: "PUT",
      data: {
        ...employee,
        attachments: newData
      },
      url: `${url}/${employee.id}`
    });

    const result = { ...res.data };

    await dispatch({
      type: actionType.UPDATE_EMPLOYEE,
      payload: result
    });
    dispatch(fetchEmployeesLog());
    return res;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_EMPLOYEE_FAIL,
      payload: error
    });
    if (returnError) {
      return { error };
    }
  }
};
let db;

let openRequest = indexedDB.open("changelog_db", 1);

openRequest.onupgradeneeded = function(e) {
  db = e.target.result;
  if (!db.objectStoreNames.contains("store")) {
    const storeOS = db.createObjectStore("store", { keyPath: "name" });
  }
};

openRequest.onsuccess = function(e) {
  db = e.target.result;
  addData();
};

const addData = item => {
  return new Promise(resolve => {
    let openRequest = indexedDB.open("changelog_db", 1);

    openRequest.onsuccess = () => {
      db = openRequest.result;
      if (db.objectStoreNames.contains("store")) {
        const tx = db.transaction(["store"], "readwrite");
        const store = tx.objectStore("store");
        item && store.put(item);
        resolve(item);
      }
    };

    openRequest.onerror = () => {
      const error = openRequest.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve("Unknown error");
      }
    };
  });
};

export const fetchEmployeesList = (forceFetch = false) => async (
  dispatch,
  getState
) => {
  const {
    user: { user },
    employee
  } = getState();
  const clientId = localStorage.getItem("employeeClientId");
  if (
    !employee.list.content.length ||
    !clientId ||
    clientId !== user.profile.client_id ||
    forceFetch
  ) {
    try {
      dispatch({
        type: actionType.FETCH_EMPLOYEES_LIST_START
      });

      const lastUpdateDate = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

      const res = await axiosProxy({
        method: "GET",
        url,
        params: {
          unpaged: true
        }
      });
      await dispatch({
        type: actionType.FETCH_EMPLOYEES_LIST,
        payload: {
          data: res.data,
          lastUpdateDate
        }
      });

      dispatch(setSprayDiaryEmployees());
      const item = {
        name: "employee",
        ...employee,
        list: {
          ...res.data,
          isFetching: false
        },
        lastUpdateDate
      };
      addData(item);

      localStorage.setItem("employeeClientId", user.profile.client_id);
      return res;
    } catch (error) {
      dispatch({
        type: actionType.FETCH_EMPLOYEES_LIST_FAIL,
        payload: error
      });
    }
  }
};

export const fetchArchivedEmployeesList = (forceFetch = false) => async (
  dispatch,
  getState
) => {
  const {
    user: { user },
    employee
  } = getState();
  const clientId = localStorage.getItem("employeeClientId");
  try {
    dispatch({
      type: actionType.FETCH_ARCHIVED_EMPLOYEES_LIST_START
    });

    const lastUpdateDate = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

    const res = await axiosProxy({
      method: "GET",
      url,
      params: {
        unpaged: true,
        archived: true
      }
    });
    await dispatch({
      type: actionType.FETCH_ARCHIVED_EMPLOYEES_LIST,
      payload: {
        data: res.data,
        lastUpdateDate
      }
    });

    const item = {
      name: "employee",
      ...employee,
      archivedList: {
        ...res.data,
        isFetching: false
      },
      lastUpdateDate
    };
    addData(item);

    localStorage.setItem("employeeClientId", user.profile.client_id);
    return res;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_ARCHIVED_EMPLOYEES_LIST_FAIL,
      payload: error
    });
  }
};

export const fetchEmployeesLog = () => async (dispatch, getState) => {
  const {
    employee,
    employee: { list, lastUpdateDate }
  } = getState();

  try {
    const startSyncDate = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

    const params = new URLSearchParams({
      unpaged: true,
      entityType: "EMPLOYEE",
      ...(lastUpdateDate && { updatedAfter: lastUpdateDate })
    });

    const {
      data: { content }
    } = await axiosProxy({
      method: "GET",
      url: changeLogUrl,
      params
    });

    if (content.length > 0) {
      const { updatableEmployeesIds, deletableEmployeesIds } = content.reduce(
        (prev, item) => ({
          updatableEmployeesIds:
            item.updateType !== "DELETED"
              ? [...prev.updatableEmployeesIds, item.entityId]
              : prev.updatableEmployeesIds,
          deletableEmployeesIds:
            item.updateType === "DELETED"
              ? [...prev.deletableEmployeesIds, item.entityId]
              : prev.deletableEmployeesIds
        }),
        {
          updatableEmployeesIds: [],
          deletableEmployeesIds: []
        }
      );
      const updatedEmployees = list.content.filter(
        employee => !deletableEmployeesIds.includes(employee.id)
      );

      if (updatableEmployeesIds.length) {
        dispatch({
          type: actionType.FETCH_EMPLOYEES_LIST_START
        });
        if (updatableEmployeesIds.length) {
          const params = new URLSearchParams({ unpaged: true });
          params.append("ids", updatableEmployeesIds);
          const {
            data: { content: fetchedEmployees }
          } = await axiosProxy({
            method: "GET",
            url,
            params
          });
          fetchedEmployees.forEach(employee => {
            const itemIndex = updatedEmployees.findIndex(
              item => item.id === employee.id
            );

            if (itemIndex > -1) {
              updatedEmployees[itemIndex] = employee;
            } else {
              updatedEmployees.push(employee);
            }
          });
        }
      }

      await dispatch({
        type: actionType.FETCH_EMPLOYEES_LIST,
        payload: {
          data: {
            ...list,
            content: updatedEmployees,
            numberOfElements: updatedEmployees.length,
            totalElements: updatedEmployees.length
          }
        }
      });

      dispatch(setSprayDiaryEmployees());
      dispatch(setSprayDiaryEmployees());

      const item = {
        name: "employee",
        ...employee,
        list: {
          ...list,
          content: updatedEmployees,
          numberOfElements: updatedEmployees.length,
          totalElements: updatedEmployees.length,
          isFetching: false
        },
        lastUpdateDate: startSyncDate
      };
      addData(item);
    } else {
      const i = {
        name: "employee",
        ...employee,
        list: { ...list },
        lastUpdateDate: startSyncDate
      };
      addData(i);
    }

    dispatch({
      type: actionType.CHANGE_LAST_UPDATE_DATE,
      payload: startSyncDate
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_EMPLOYEES_LIST_FAIL,
      payload: error
    });
  }
};

export const fetchEmployee = id => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_EMPLOYEE_START
    });
    const res = await axiosProxy({
      method: "GET",
      url: `${url}/${id}`
    });

    await dispatch({
      type: actionType.FETCH_EMPLOYEE,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_EMPLOYEE_FAIL,
      payload: error
    });
    return { error };
  }
};

export const fetchEmployees = ({
  archived,
  page,
  size,
  search,
  sort = ["id,asc"],
  unpaged = false,
  filters
}) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_EMPLOYEES_START
    });

    const paramsObject = omitBy(
      {
        archived,
        page,
        size,
        search,
        sort,
        unpaged
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => params.append(filter, value));
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      uniqId,
      params,
      url
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.FETCH_EMPLOYEES,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.FETCH_EMPLOYEES_ERROR,
      payload: error
    });
  }
};

export const employeesStatusChange = (
  archive,
  employeesIds
) => async dispatch => {
  try {
    dispatch({
      type: actionType.ARCHIVE_EMPLOYEES_START
    });

    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?archive=${archive}&ids=${employeesIds.join(
        "&ids="
      )}`
    });

    dispatch({
      type: actionType.ARCHIVE_EMPLOYEES,
      payload: employeesIds
    });
    dispatch(fetchEmployeesLog());
  } catch (error) {
    dispatch({
      type: actionType.ARCHIVE_EMPLOYEES_FAIL,
      payload: error
    });
  }
};

export const deleteEmployees = employeesIds => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_EMPLOYEES_START
    });

    await axiosProxy({
      method: "DELETE",
      url: `${url}/batch?ids=${employeesIds.join("&ids=")}`
    });

    dispatch({
      type: actionType.DELETE_EMPLOYEES,
      payload: employeesIds
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_EMPLOYEES_FAIL,
      payload: error
    });
  }
};

export const changeEmployeesPage = page => dispatch => {
  dispatch({
    type: actionType.CHANGE_EMPLOYEES_PAGE,
    payload: page
  });
};

export const changePageSize = pageSize => dispatch => {
  dispatch({
    type: actionType.CHANGE_PAGE_SIZE,
    payload: pageSize
  });
};

export const deleteEmployee = id => async dispatch => {
  try {
    dispatch({
      type: actionType.DELETE_EMPLOYEE_START
    });
    await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`
    });

    dispatch({
      type: actionType.DELETE_EMPLOYEE,
      payload: id
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_EMPLOYEE_FAIL,
      payload: error
    });
  }
};

export const uploadAttachment = ({
  file,
  idReplace,
  callBack,
  data,
  parentId
}) => {
  return genericAttachmentUploader({
    url: `${url}/attachments/presign_put_url`,
    file,
    startAction: actionType.UPLOAD_EMPLOYEE_ATTACHMENT_START,
    successAction: actionType.UPLOAD_EMPLOYEE_ATTACHMENT,
    errorAction: actionType.UPLOAD_EMPLOYEE_ATTACHMENT_ERROR,
    progressAction: actionType.UPLOAD_EMPLOYEE_ATTACHMENT_PROGRESS,
    createUploadRequestCancelTokenAction:
      actionType.CREATE_EMPLOYEE_ATTACHMENT_CANCEL_TOKEN,
    callBack,
    idReplace,
    data,
    parentId
  });
};

export const uploadProfilePicture = (file, data, parentId) =>
  genericAttachmentUploader({
    url: `${url}/pictures/presign_put_url`,
    file,
    startAction: actionType.UPLOAD_EMPLOYEE_PICTURE_START,
    successAction: actionType.UPLOAD_EMPLOYEE_PICTURE,
    errorAction: actionType.UPLOAD_EMPLOYEE_PICTURE_ERROR,
    data,
    parentId
  });

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_EMPLOYEE_ERROR
  });
};

export const fetchEmployeesTypes = () => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_EMPLOYEES_TYPES_START
    });

    const res = await axiosProxy({
      method: "GET",
      url: `${url}/types`
    });

    await dispatch({
      type: actionType.FETCH_EMPLOYEES_TYPES,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_EMPLOYEES_TYPES_FAIL,
      payload: error
    });
  }
};
