import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { getParentPageHref } from "../../../utils/routeHelpers";
import Loader from "../../../components/Loader";
import LayoutOverlay from "../../../components/LayoutOverlay";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import QCForm from "./AddEditQCForm";
import styles from "./AddEditQCForm.module.css";
import { Button } from "semantic-ui-react";
import { history } from "../../../store";
import { bindActionCreators } from "redux";
import {
  addNewQCForm,
  clearFormData,
  setFormData,
  updateQCForm,
} from "../../../actions/QualityControl/forms";

const prepareFormFields = (qcForm) =>
  Object.values(qcForm.fields).reduce((prev, curr) => {
    prev[curr.label] = curr;
    return prev;
  }, {});

const AddEditQCForm = (props) => {
  const {
    route,
    isFetching,
    match,
    actions: { addNewQCForm, updateQCForm, setFormData, clearFormData },
    qualityControlForms,
    qcForm,
    navigate,
  } = props;

  const { id } = match.params;
  const action = id ? "UPDATE" : "CREATE";

  const formRef = useRef({});

  useEffect(() => {
    if (id) {
      const form = qualityControlForms.find((l) => +l.id === +id);
      if (!form) navigate("/harvest/qc");
      setFormData({
        name: form.name,
        employeeIds: form.permissions.map((e) => e.employee.id),
        fields: Object.values(form.formTemplate),
      });
    } else {
      clearFormData();
    }
  }, []);

  const onValidSubmit = async () => {
    const form = {
      ...qcForm,
      fields: prepareFormFields(qcForm),
    };

    if (action === "CREATE") {
      await addNewQCForm(form);
      navigate(getParentPageHref(route));
    } else {
      await updateQCForm(id, form);
      window.location.href = "/harvest/qc/";
    }
  };

  const onCancel = () => navigate("/harvest/qc/");

  return (
    <LayoutOverlay
      closeLink={"/harvest/qc/"}
      route={{
        ...route,
        name: id ? route.name.replace("Add new", "Edit") : route.name,
      }}
      footer={
        <div className={styles.buttonsFooter}>
          <Button light size="large" onClick={onCancel}>
            Cancel
          </Button>

          <Button primary size="large" onClick={formRef.current.submit}>
            Save
          </Button>
        </div>
      }
    >
      {isFetching ? (
        <Loader />
      ) : (
        <Form onValidSubmit={onValidSubmit} loading={false} ref={formRef}>
          <QCForm groupID={id} />
        </Form>
      )}
    </LayoutOverlay>
  );
};

AddEditQCForm.propTypes = {
  isFetching: PropTypes.bool,
  match: PropTypes.object,
  route: PropTypes.object,
  qcForm: PropTypes.object,
  qualityControlForms: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isFetching: state.qualityControl.isFetching,
  qualityControlForms: state.qualityControl.data.content,
  qcForm: state.qualityControl.qcForm,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setFormData,
      clearFormData,
      addNewQCForm,
      updateQCForm,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditQCForm);
