import React from "react";
import { Checkbox } from "semantic-ui-react";
import styles from "../../ClockIn.module.css";

const AllEmployees = ({ employee, values, setFieldValue }) => {
  return (
    <div className={styles.wrapper}>
      {employee.map(item => (
        <div key={item.id} className={styles.employeeRow}>
          <Checkbox
            onClick={() => {
              return values.children.map(e => e.employeeId).includes(item.id)
                ? setFieldValue(
                    "children",
                    values.children.filter(i => i.employeeId !== item.id)
                  )
                : setFieldValue("children", [
                    ...values.children,
                    {
                      employeeId: item.id,
                      generalTaskId: values.generalTaskId,
                      areas: values.areas,
                      startAt: values.startAt
                    }
                  ]);
            }}
            className={styles.checkBox}
            checked={values.children.map(i => i.employeeId).includes(item.id)}
          />
          {`${item.firstName} ${item.lastName}`}
        </div>
      ))}
    </div>
  );
};

export default AllEmployees;
