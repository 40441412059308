import { createSelector } from "reselect";
import { map } from "lodash";
import { options } from "joi-browser";
const scouting = (state) => state.scouting;
const scoutingData = (state) => state.scouting.filterData;

export const activeItemSelector = createSelector([scouting], (scoutingData) => {
  const { currentScouting, data } = scoutingData;
  if (currentScouting?.id) {
    return data.content.find(({ id }) => currentScouting.id === id);
  }
  return null;
});
export const creatorSelector = createSelector([scouting], (scoutingData) => {
  if (scoutingData.filterData) {
    const { filterData, isFetching } = scoutingData;
    return {
      options: filterData.creatorIds.map((item) => ({
        ...item,
        label: item.text,
      })),
      isFetching,
    };
  }
  return null;
});
export const taskIdSelector = createSelector([scouting], (scoutingData) => {
  if (scoutingData.filterData) {
    const { filterData, isFetching } = scoutingData;
    return {
      options: filterData.id.map((item) => ({ ...item, label: item.text })),
      isFetching,
    };
  }
  return null;
});
