import React from "react";
import { Modal, Button } from "semantic-ui-react";
import styles from "./ConfirmationModal.module.css";

const ConfirmationModal = ({ open, setOpen, workingTimes, onConfirm }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal size="tiny" open={open} onClose={handleClose}>
      <Modal.Content className={styles.confirmationModalContentHeader}>
        {`Delete working time${workingTimes.length === 1 ? "" : "s"}`}?
      </Modal.Content>
      <Modal.Actions>
        <Button color="green" content="Cancel" basic onClick={handleClose} />
        <Button content="Delete" onClick={onConfirm} positive />
      </Modal.Actions>
    </Modal>
  );
};

export default ConfirmationModal;
