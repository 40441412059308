import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import createRootReducer from "../reducers";
import { axiosProxy } from "../utils/axiosProxy";
import { diff } from "deep-object-diff";
import ObjectId from "bson-objectid";
import { offline, createOffline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import defaultQueue from "@redux-offline/redux-offline/lib/defaults/queue";
import persist from "@redux-offline/redux-offline/lib/defaults/persist";
import { createReduxHistoryContext } from "redux-first-history";
import config from "../config";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({
  history: createBrowserHistory()
});

const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;

window.localforage.setDriver(window.localforage.INDEXEDDB);
window.localforage.setItem(`externalUrls`, config.notCached);

const getTagData = tag => {
  return new Promise((resolve, reject) => {
    window.localforage
      .getItem(`req_${tag}`)
      .then(request => {
        resolve(request || { error: { message: "Record not Found" } });
      })
      .catch(error => {
        reject(error);
      });
  });
};

const waitForTagData = async tag => {
  let result = await getTagData(tag);
  let tryCount = 0;
  while (!(result && (result.data || result.error)) && tryCount < 10) {
    result = await getTagData(tag);
    tryCount++;
    await new Promise(resolve => setTimeout(resolve, 1000));
  }
  if (!(result && (result.data || result.error))) {
    if (!result) {
      result = {};
    }
    result.error = { message: "Timeout error" };
  }

  return result;
};

const effect = async (effect, _action) => {
  if (
    _action.meta.callFrom &&
    _action.meta.callFrom !== history.location.pathname &&
    effect.method &&
    effect.method.toUpperCase() === "GET"
  ) {
    return Promise.reject();
  } else {
    let res = null;
    if (_action.meta.sync) {
      res = await waitForTagData(_action.meta.reqId);
      window.localforage.removeItem(`req_${_action.meta.reqId}`);
    } else {
      res = await axiosProxy({ ...effect });
    }
    if (res && res.error) {
      return Promise.reject(res.error);
    } else if (res) {
      if (effect.nextStep && !_action.meta.sync) {
        const nextData = {
          ...effect.nextStep,
          url: res.data[effect.nextStep.url]
        };

        const resNext = await axiosProxy(nextData);

        const payload = { ...res.data, ...resNext.data, ...nextData.dataToAdd };
        if (typeof nextData.callBack === "function") {
          nextData.callBack(payload);
        }
        return payload;
      } else {
        let data = effect.dataToAdd
          ? { ...res.data, ...effect.dataToAdd }
          : res.data;
        if (typeof effect.callBack === "function") {
          await effect.callBack(data);
        }
        if (typeof effect.payloadConstructor === "function") {
          data = effect.payloadConstructor(data);
        }
        return data;
      }
    }
  }
};
const discard = (error, _action, _retries) => {
  const { request, response } = error || {};
  if (!request) throw error; // There was an error creating the request
  if (!response) return true; // There was no response

  return response.status >= 400 || _retries > 2;
};

const getMethod = action =>
  action.meta.offline.effect.method
    ? action.meta.offline.effect.method.toUpperCase()
    : "GET";
const getUrl = action => action.meta.offline.effect.url;
const isNotDiff = (item, action) => {
  const diffReq = {
    ...diff(item.payload, action.payload)
  };
  return Object.keys(diffReq).length === 0;
};

const queue = {
  ...defaultQueue,
  enqueue(array, action) {
    const newArray = array.filter(item => {
      return (
        getMethod(item) === getMethod(action) &&
        getUrl(item) === getUrl(action) &&
        isNotDiff(item, action)
      );
    });
    const offlineFlow =
      action.meta &&
      action.meta.sync === undefined &&
      !action.meta.online &&
      action.meta.offline.effect.method &&
      (action.meta.offline.effect.method.toUpperCase() !== "GET" ||
        action.meta.forceSW) &&
      navigator.serviceWorker &&
      window.myServiceWorkerReg.sync;

    action.meta.sync = offlineFlow;
    if (!action.meta.reqId && offlineFlow) {
      action.meta.reqId = ObjectId().toHexString();
    }
    if ((newArray.length === 0 || array.length === 0) && offlineFlow) {
      const request = {
        url: `${config.oauth2.authority}/protocol/openid-connect/token`,
        effect: action.meta.offline.effect,
        refresh_token: action.meta.refresh_token,
        scope: config.oauth2.scope
      };

      new Promise(async resolve => {
        request.effect.headers = {
          ...(request.effect.headers || {}),
          authorization: `Bearer ${action.meta.user &&
            action.meta.user.access_token}`
        };
        request.refresh_token =
          action.meta.user && action.meta.user.refresh_token;

        if (request.effect.nextStep && request.effect.nextStep.callBack) {
          delete request.effect.nextStep.callBack;
        }

        const requestToSend = {
          ...request,
          effect: {
            ...request.effect,
            url:
              request.effect.url.indexOf("http") === 0
                ? request.effect.url
                : `${API_URL}${request.effect.url}`
          }
        };

        if (requestToSend.effect.payloadConstructor) {
          delete requestToSend.effect.payloadConstructor;
        }

        window.localforage
          .setItem(`req_${action.meta.reqId}`, requestToSend)
          .then(() => {
            window.myServiceWorkerReg.sync.register(action.meta.reqId);
            resolve();
          });
      });
    }

    const targetActionIndex = offlineFlow
      ? array.findIndex(
          item =>
            item.meta.reqId === action.meta.reqId && isNotDiff(item, action)
        )
      : array.findIndex(item => {
          return (
            getMethod(item) === getMethod(action) &&
            getUrl(item) === getUrl(action) &&
            isNotDiff(item, action)
          );
        });
    if (targetActionIndex >= 0 && getMethod(action) === "DELETE") {
      array.splice(targetActionIndex, 1);
    } else if (targetActionIndex >= 0) {
      array.splice(targetActionIndex, 1, action);
      if (offlineFlow) {
        window.localforage.getItem(`req_${action.meta.reqId}`).then(request => {
          if (request) {
            const effectToSet = JSON.parse(
              JSON.stringify(action.meta.offline.effect)
            );
            if (effectToSet.payloadConstructor) {
              delete effectToSet.payloadConstructor;
            }
            window.localforage.setItem(`req_${action.meta.reqId}`, {
              ...request,
              effect: {
                ...effectToSet,
                url: request.effect.url,
                headers: request.effect.headers
              }
            });
          }
        });
      }
    } else {
      array.push(action);
    }
    return array;
  }
};

const offlineConfigOwn = {
  ...offlineConfig,
  returnPromises: true,
  persistOptions: {
    blacklist: [
      "router",
      "oidc",
      "common",
      "generalTasks",
      "reports",
      "generalBarcodes",
      "applicationsRecordByBlock",
      "scouting",
      "sprayDiary",
      "pendingMovements",
      "areas",
      "farms",
      "recommendations",
      "chemical",
      "shed",
      "employee",
      "dashboard"
    ]
  },
  effect,
  discard,
  queue,
  persist
};

const enhancers = [offline(offlineConfigOwn)];
const middlewares = [
  thunk,
  routerMiddleware, // Use routerMiddleware from redux-first-history
  createOffline(offlineConfigOwn).middleware
];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

export default function configureStore({ initialState }) {
  // const appReducer = createRootReducer(history);
  // const rootReducer = (state, action) => {
  //   // Clear all data in redux store to initial.
  //   const { user, router, oidc, common } = state;

  //   if (action.type === actionType.PURGE_ALL_DATA)
  //     state = { user, router, oidc, common };

  //   return appReducer(state, action);
  // };

  // return createStore(
  //   rootReducer, // root reducer with router state
  //   initialState,
  //   compose(applyMiddleware(...middlewares), ...enhancers)
  // );

  const store = createStore(
    createRootReducer({
      router: routerReducer // Add router reducer to root reducer
    }),
    initialState,
    compose(applyMiddleware(...middlewares), ...enhancers)
  );

  return store;
}

export const createHistory = store => createReduxHistory(store);

// export { history };
