import React, { useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { Formik, Field } from "formik";
import styles from "./Workers.module.css";
import { useDispatch, useSelector } from "react-redux";
import { updateQC } from "actions/QualityControl";
import { employeeList } from "selectors/employee";
import { getEmployeeList } from "actions/SprayDiary/employee";
import { workersFilterOptions } from "selectors/harvestUnits";
import CustomSlider from "./CustoSlider/CustomSLider";
import SingleSelect from "components/Table/components/Filters/SingleSelect";

const WorkersEdit = ({ activeItem, setCheckEdit, activeRounds }) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [newActiveItem, setNewActiveItem] = useState([]);
  const [activeEmployeeID, setActiveEmployeeID] = useState([]);
  const [checkerDelete, setCheckerDelete] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const employees = useSelector(employeeList);

  const onSubmit = data => {
    dispatch(updateQC(activeItem.id, data)).then(() => setCheckEdit(false));
  };
  const onDeleteWorker = (changeValue, workers) => {
    const filtredUrl = workers.filter(
      item => item.employeeId !== activeEmployeeID.employeeId
    );
    return changeValue("employeeResults", filtredUrl), setOpenModal(false);
  };
  function removeEmployeeById(array) {
    return array.map(item => ({
      ...item,
      employeeResults: item.employeeResults.filter(
        result => result.employeeId !== activeEmployeeID.employeeId
      )
    }));
  }

  const onDeleteAllWorker = (setFieldValue, values, id) => {
    const newArray = removeEmployeeById(activeItem.qcRounds, id);
    const newEmployeeResult = removeEmployeeById(activeItem.qcRounds, id).find(
      i => i.roundNumber === values.roundNumber
    );
    return (
      setFieldValue("employeeResults", newEmployeeResult.employeeResults),
      setNewActiveItem({
        qcRounds: newArray
      })
    );
  };
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={activeRounds}
    >
      {({ values, dirty, isValid, setFieldValue, setFieldError, errors }) => {
        const dataToSend = checkerDelete
          ? {
              barcodeId: activeItem?.barcodeId,
              createdAt: activeItem?.createdAt,
              id: activeItem?.id,
              name: activeItem?.name,
              unitToCheckPerRound: activeItem?.unitToCheckPerRound,
              qcRounds: newActiveItem.qcRounds,
              rounds: activeItem?.rounds
            }
          : {
              barcodeId: activeItem?.barcodeId,
              createdAt: activeItem?.createdAt,
              id: activeItem?.id,
              name: activeItem?.name,
              unitToCheckPerRound: activeItem?.unitToCheckPerRound,
              qcRounds: activeItem?.qcRounds.map(item =>
                item.roundNumber === activeRounds?.roundNumber ? values : item
              ),
              rounds: activeItem?.rounds
            };
        return (
          <>
            <div className={styles.root}>
              <Form>
                {values.employeeResults.map((item, index) => {
                  return (
                    <div className={styles.rowWrapper}>
                      <div className={styles.container}>
                        <div className={styles.deleteBtnWrapper}>
                          <div className={styles.textWrapper}>
                            Workers: {index + 1}
                          </div>
                          <div className={styles.btnContainer}>
                            <Button
                              className={styles.deleteBtn}
                              content={<Icon name="trash alternate" />}
                              onClick={() => {
                                return (
                                  setOpenModal(true), setActiveEmployeeID(item)
                                );
                              }}
                            />
                          </div>
                        </div>
                        <Form.Field className={styles.formField}>
                          <label className={styles.label}>Worker name</label>
                          <Field
                            fluid
                            className={styles.selectWorkersField}
                            required
                            as={SingleSelect}
                            search={true}
                            text={
                              values.employeeResults?.length
                                ? employees.find(i => {
                                    return i.key === item.employeeId;
                                  })?.text
                                : ""
                            }
                            selector={workersFilterOptions}
                            value={values.employeeBinParts?.map(
                              item => item?.employeeId || item
                            )}
                            name="employeeResults"
                            onChange={(_, value) => {
                              if (
                                activeRounds?.employeeResults.some(
                                  i => i.employeeId === value
                                )
                              ) {
                                setFieldError(
                                  `${item.id}`,
                                  `Тhis user has already been added`
                                );
                              } else {
                                setFieldError(`${item.id}`, undefined);
                              }
                              setFieldValue(
                                "employeeResults",
                                values.employeeResults.map(i =>
                                  i.id === item.id
                                    ? { ...i, employeeId: value }
                                    : i
                                )
                              );
                            }}
                            loaderAction={getEmployeeList}
                          />
                        </Form.Field>
                        <CustomSlider
                          setSliderValue={setSliderValue}
                          setFieldValue={setFieldValue}
                          values={values}
                          item={item}
                          sliderValue={sliderValue}
                        />
                        {errors[`${item.id}`] && (
                          <div
                            styles={styles.errorContainer}
                            style={{ color: "#b00020", marginTop: "10px" }}
                          >
                            {errors[`${item.id}`]}
                          </div>
                        )}
                      </div>
                      <Modal
                        className={styles.messageModal}
                        onClose={() => setOpenModal(false)}
                        open={openModal}
                        size="tiny"
                      >
                        <Modal.Content>
                          {` Delete worker ${
                            employees.find(i => {
                              return i.key === activeEmployeeID.employeeId;
                            })?.text
                          } from all rounds?`}
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            content="From current"
                            className={styles.deleteCurrlButton}
                            onClick={async () => {
                              return (
                                onDeleteWorker(
                                  setFieldValue,
                                  values.employeeResults
                                ),
                                await setOpenModal(false)
                              );
                            }}
                          />
                          <Button
                            content="From all"
                            className={styles.deleteAllButton}
                            onClick={async () => {
                              return (
                                setCheckerDelete(true),
                                await onDeleteAllWorker(setFieldValue, values),
                                setOpenModal(false)
                              );
                            }}
                            positive
                          />
                        </Modal.Actions>
                      </Modal>
                    </div>
                  );
                })}
              </Form>
            </div>
            <div className={styles.btnWrapper}>
              <Button
                content="Cancel"
                color="green"
                basic
                onClick={() => {
                  setCheckEdit(false);
                }}
              />
              <Button
                content={"Save"}
                color="green"
                onClick={() => {
                  return onSubmit(dataToSend), setCheckEdit(false);
                }}
                disabled={!isValid || !dirty}
              />
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default WorkersEdit;
