import React, { useRef } from "react";
import { useFormikContext, Field } from "formik";
import { Form, Segment, Button, Icon } from "semantic-ui-react";
import styles from "../Sidebar.module.css";
import { useSelector } from "react-redux";
import MultiSelect from "../../../../../components/MultiSelect/MultiSelect";
import { employeeOptionsSelector } from "../../../../../selectors/employee";
import { getEmployeeList } from "../../../../../actions/Contractors/contractors";

const AssignNewWorkersForm = (props) => {
  const employeeOptions = useSelector(employeeOptionsSelector);

  const {
    values,
    setFieldValue,
    submitForm,
    dirty,
    isValid,
  } = useFormikContext();

  if (!props.shown) {
    return null;
  }
  return (
    <Segment className={styles.formContainerSegment}>
      <Form>
        <Form.Group>
          <Form.Field required width={16}>
            <label>Select workers</label>
            <Field
              required
              search={true}
              as={MultiSelect}
              width={16}
              text={
                values.employees.length
                  ? `${values.employees.length} workers`
                  : ""
              }
              selector={employeeOptionsSelector}
              value={values.employees}
              name="employees"
              loaderAction={getEmployeeList}
              onOptionChange={setFieldValue}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <div className={styles.workerElementsContainer}>
            {values.employees.map((employee) => (
              <div key={employee} className={styles.workerElement}>
                <span>
                  {
                    employeeOptions.options.find(
                      (option) => option.value === employee
                    ).label
                  }
                </span>
                <Icon
                  name="close"
                  onClick={() => {
                    setFieldValue(
                      "employees",
                      values.employees.filter((id) => id !== employee)
                    );
                  }}
                />
              </div>
            ))}
          </div>
        </Form.Group>
        <div className={styles.buttonContainer}>
          {props.onCancel && (
            <Button
              className={`${styles.cancelButton} button-text`}
              onClick={props.onCancel}
            >
              Cancel
            </Button>
          )}
          <Button
            type="submit"
            className={`${styles.submitButton} button-text`}
            disabled={!dirty || !isValid}
            onClick={submitForm}
          >
            Save
          </Button>
        </div>
      </Form>
    </Segment>
  );
};

export default AssignNewWorkersForm;
