import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import { areaFilterOptions } from "selectors/areas";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import moment from "moment";
import { employeeOptionsSelector } from "selectors/employee";
import { contractorFilterOptions } from "selectors/generalTasks";
import { varietiesOptionSelector } from "selectors/areas";
import { boxUnitOptionsSelector } from "selectors/boxUnits";
import { Button, Icon, Popup } from "semantic-ui-react";
import style from "../routes/Harvest/PackedBoxes/PackedBoxes.module.css";
import { calcNotAllocatedPallets } from "routes/Harvest/Stock/Stock";
import { Link } from "react-router-dom";

export const getDispatchesTableColumns = (isFetching, navigate, onDelete) => {
  const columnsConfig = {
    id: {
      withSort: true,
      type: "string",
      id: "id",
      title: "Id",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ id }) => id || "-",
      withSort: true,
    },
    createDate: {
      withSort: true,
      type: "string",
      id: "createDate",
      title: "Date",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ createDate }) =>
        moment(createDate).format("DD/MM/YYYY") || "-",
      withSort: true,
    },
    vendor: {
      withSort: true,
      type: "string",
      id: "vendor",
      title: "Client/Buyer",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: (d) => (d.vendor && d.vendor.name) || "-",
      withSort: true,
    },

    orderNumber: {
      withSort: true,
      type: "string",
      id: "orderNumber",
      title: "Order number",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: (d) => d.orderNumber || "-",
      withSort: true,
      cellWidth: 2,
    },
    numberOfPallets: {
      withSort: true,
      type: "string",
      id: "numberOfPallets",
      title: "Number of pallets",
      accessor: (d) => (d.pallets && d.pallets.length) || "-",
      withSort: true,
      cellWidth: 2,
    },
    notes: {
      withSort: true,
      type: "string",
      id: "notes",
      title: "Notes",
      accessor: (d) => d.notes || "-",
      withSort: true,
      cellWidth: 2,
    },
    btnWrapper: {
      withSort: false,
      type: "string",
      id: "btnWrapper",
      title: "",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ id }) => {
        return (
          <div className={style.wrapper}>
            <div className={style.iconWrapper}>
              <Popup
                inverted
                position="bottom center"
                content={"Edit"}
                size="tiny"
                trigger={
                  <div className={style.editButtonWrapper}>
                    <Button
                      as={Link}
                      to={`/harvest/dispatches/dispatch${id}`}
                      onClick={(event) => {
                        return (
                          // navigate(``),
                          event.stopPropagation()
                        );
                      }}
                      className={style.editButton}
                      floated="right"
                      icon={<Icon name="edit" />}
                      size="small"
                    />
                  </div>
                }
              />
              <Popup
                inverted
                position="bottom center"
                content={"Delete"}
                size="tiny"
                trigger={
                  <Button
                    onClick={(event) => {
                      return onDelete(id), event.stopPropagation();
                    }}
                    className={style.deleteButton}
                    floated="right"
                    icon={<Icon name="trash alternate" />}
                    size="small"
                  />
                }
              />
            </div>
          </div>
        );
      },
      withSort: true,
      cellWidth: 2,
    },
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {}),
  }));
};
