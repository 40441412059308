export const actionType = {
  TASKS_TYPE_LIST: "TASKS/TASKS_TYPE_LIST",
  SET_SEEN_STATUS: "TASKS/SET_SEEN_STATUS",
  SET_ACTIVE_ITEM_ID: "TASKS/SET_ACTIVE_ITEM_ID",
  SET_ACTIVE_TAB: "TASKS/SET_ACTIVE_TAB",
  SET_ADDED_TYPE: "TASKS/SET_ADDED_TYPE",
  SET_TABLE_TYPE: "TASKS/SET_TABLE_TYPE",
  CHANGE_ACTIVE_TAB: "TASKS/CHANGE_ACTIVE_TAB",
  CLEAR_ERROR: "TASKS/CLEAR_ERROR",

  GENERAL_TASK_WORKING_TIME_DELETE_START:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_DELETE_START",
  GENERAL_TASK_WORKING_TIME_DELETE:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_DELETE",
  GENERAL_TASK_WORKING_TIME_DELETE_FAIL:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_DELETE_FAIL",

  FETCH_GENERAL_TASK_LIST_START: "GENERAL_TASKS/FETCH_GENERAL_TASK_LIST_START",
  FETCH_GENERAL_TASK_LIST: "GENERAL_TASKS/FETCH_GENERAL_TASK_LIST",
  FETCH_GENERAL_TASK_LIST_FAIL: "GENERAL_TASKS/FETCH_GENERAL_TASK_LIST_FAIL",

  FETCH_GENERAL_TASK_TABLE_LIST_START:
    "GENERAL_TASKS/FETCH_GENERAL_TASK_TABLE_LIST_START",
  FETCH_GENERAL_TASK_TABLE_LIST: "GENERAL_TASKS/FETCH_GENERAL_TASK_TABLE_LIST",
  FETCH_GENERAL_TASK_TABLE_LIST_FAIL:
    "GENERAL_TASKS/FETCH_GENERAL_TASK_TABLE_LIST_FAIL",

  FETCH_TASKS_START: "TASKS/FETCH_TASKS_START",
  FETCH_TASKS: "TASKS/FETCH_TASKS",
  FETCH_TASKS_FAIL: "TASKS/FETCH_TASKS_FAIL",

  FETCH_TASKS_ARCHIVE_START: "TASKS/FETCH_TASKS_ARCHIVE_START",
  FETCH_TASKS_ARCHIVE: "TASKS/FETCH_TASKS_ARCHIVE",
  FETCH_TASKS_ARCHIVE_FAIL: "TASKS/FETCH_TASKS_ARCHIVE_FAIL",

  DELETE_GENERAL_TASK_START: "TASKS/DELETE_GENERAL_TASK_START",
  DELETE_GENERAL_TASK: "TASKS/DELETE_GENERAL_TASK",
  DELETE_GENERAL_TASK_FAIL: "TASKS/DELETE_GENERAL_TASK_FAIL",

  FETCH_CARDS_TASKS_START: "TASKS/FETCH_CARDS_TASKS_START",
  FETCH_CARDS_TASKS: "TASKS/FETCH_CARDS_TASKS",
  FETCH_CARDS_TASKS_FAIL: "TASKS/FETCH_CARDS_TASKS_FAIL",

  FETCH_GENERAL_TASK_IDS_START: "GENERAL_TASKS/FETCH_GENERAL_TASK_IDS_START",
  FETCH_GENERAL_TASK_IDS: "GENERAL_TASKS/FETCH_GENERAL_TASK_IDS",
  FETCH_GENERAL_TASK_IDS_FAIL: "GENERAL_TASKS/FETCH_GENERAL_TASK_IDS_FAIL",

  CREATE_GENERAL_TASK: "GENERAL_TASKS/CREATE_GENERAL_TASK",
  CREATE_GENERAL_TASK_START: "GENERAL_TASKS/CREATE_GENERAL_TASK_START",
  CREATE_GENERAL_TASK_FAIL: "GENERAL_TASKS/CREATE_GENERAL_TASK_FAIL",

  FETCH_GENERAL_TASK_NAMES_START:
    "GENERAL_TASKS/FETCH_GENERAL_TASK_NAMES_START",
  FETCH_GENERAL_TASK_NAMES: "GENERAL_TASKS/FETCH_GENERAL_TASK_NAMES",
  FETCH_GENERAL_TASK_NAMES_FAIL: "GENERAL_TASKS/FETCH_GENERAL_TASK_NAMES_FAIL",

  FETCH_TASK_PAYMENT_METHODS_START:
    "CREATE_NEW_TASK/FETCH_TASK_PAYMENT_METHODS_START",
  FETCH_TASK_PAYMENT_METHODS: "CREATE_NEW_TASK/FETCH_TASK_PAYMENT_METHODS",
  FETCH_TASK_PAYMENT_METHODS_ERROR:
    "CREATE_NEW_TASK/FETCH_TASK_PAYMENT_METHODS_ERROR",

  FETCH_GENERAL_TASK_START: "GENERAL_TASK/FETCH_GENERAL_TASK_START",
  FETCH_GENERAL_TASK: "GENERAL_TASK/FETCH_GENERAL_TASK",
  FETCH_GENERAL_TASK_FAIL: "GENERAL_TASK/FETCH_GENERAL_TASK_FAIL",
  GET_GENERAL_TASK_WORKING_TIME_START:
    "GENERAL_TASKS/GET_GENERAL_TASK_WORKING_TIME_START",
  GET_GENERAL_TASK_WORKING_TIME: "GENERAL_TASKS/GET_GENERAL_TASK_WORKING_TIME",
  GET_GENERAL_TASK_WORKING_TIME_FAIL:
    "GENERAL_TASKS/GET_GENERAL_TASK_WORKING_TIME_FAIL",
  UPLOAD_GENERAL_TASKS_ATTACHMENTS_START:
    "CREATE_NEW_TASK/UPLOAD_GENERAL_TASKS_ATTACHMENTS_START",
  UPLOAD_GENERAL_TASKS_ATTACHMENTS:
    "CREATE_NEW_TASK/UPLOAD_GENERAL_TASKS_ATTACHMENTS",
  UPLOAD_GENERAL_TASKS_ATTACHMENTS_ERROR:
    "CREATE_NEW_TASK/UPLOAD_GENERAL_TASKS_ATTACHMENTS_ERROR",
  DELETE_ATTACHMENT: "CREATE_NEW_TASK/DELETE_ATTACHMENT",

  GENERAL_TASK_WORKING_TIME_CREATE_START:
    "GENERAL_TASKS_WORKING_TIMES/CREATE_START",
  GENERAL_TASK_WORKING_TIME_CREATE: "GENERAL_TASKS_WORKING_TIMES/CREATE",
  GENERAL_TASK_WORKING_TIME_CREATE_FAIL:
    "GENERAL_TASKS_WORKING_TIMES/CREATE_FAIL",

  GENERAL_TASK_WORKING_TIME_UPDATE_START:
    "GENERAL_TASKS_WORKING_TIMES/UPDATE_START",
  GENERAL_TASK_WORKING_TIME_UPDATE: "GENERAL_TASKS_WORKING_TIMES/UPDATE",
  GENERAL_TASK_WORKING_TIME_UPDATE_FAIL:
    "GENERAL_TASKS_WORKING_TIMES/UPDATE_FAIL"
};

export const ROLE_COLORS = {
  OWNER: "#DED500",
  EXTERNAL_AGRONOMIST: "#37B963",
  OPERATOR: "#E87432",
  INTERNAL_AGRONOMIST: "#B4CA2D",
  WORKER: "#B25E21",
  SUPERVISOR: "#1DC5CF",
  FARM_MANAGER: "#3A67BF",
  ADMIN: "#AD3ABF",
  HEAD_OFFICE: "#DE4267"
};

export const SLASHING_OPTIONS = [
  {
    key: 1,
    label: "All",
    value: true
  },
  {
    key: 2,
    label: "Parent block",
    value: false
  }
];
