import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import Loader from "../../../components/Loader";
import { abilitiesSelector } from "../../../selectors/user";
import MachineryForm from "./MachineryForm/MachineryForm";
import {
  clearCreator,
  getMachineryById,
} from "../../../actions/Machinery/machineries";
import { getParentPageHref } from "../../../utils/routeHelpers";
import LayoutOverlay from "../../../components/LayoutOverlay";
// eslint-disable-next-line
import styles from "./AddNewMachinery.module.css";

class AddNewMachinery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      machinery: null,
    };
    this.machineryForm = React.createRef();
  }
  async componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getMachinery(id);
    } else {
      this.setState({
        isFetching: false,
      });
    }
  }

  getMachinery = (machineryId) => {
    const { getMachineryById } = this.props;
    this.setState({
      isFetching: true,
    });

    getMachineryById(machineryId)
      .then((machinery) => {
        this.setState({
          isFetching: false,
          machinery,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.props.navigate("/404");
        }
        this.setState({
          isFetching: false,
        });
      });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;
    const { id } = match.params;

    if (id && id !== prevProps.match.params.id) {
      this.getMachinery(id);
    }
  }

  componentWillUnmount() {
    const { clearCreator } = this.props;

    clearCreator();
  }

  doSubmitForm = () => {
    if (this.machineryForm.current) {
      this.machineryForm.current.submit();
    }
  };

  render() {
    const { machinery, isFetching } = this.state;
    const { route, match, Can, navigate } = this.props;
    const { id } = match.params;

    return (
      <LayoutOverlay
        closeLink={getParentPageHref(route)}
        route={{
          ...route,
          name: id ? route.name.replace("Add New", "Edit") : route.name,
        }}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            {machinery && machinery.id ? (
              <Can I="update" a="machineries">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            ) : (
              <Can I="add" a="machineries">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            )}
          </div>
        }
      >
        {isFetching ? (
          <Loader />
        ) : (
          <MachineryForm
            navigate={navigate}
            initialValues={machinery}
            formRef={this.machineryForm}
          />
        )}
      </LayoutOverlay>
    );
  }
}

AddNewMachinery.propTypes = {
  route: PropTypes.object.isRequired,
  match: PropTypes.object,
  machinery: PropTypes.object,
  Can: PropTypes.func,
  getMachineryById: PropTypes.func,
  clearCreator: PropTypes.func,
};

AddNewMachinery.defaultProps = {
  route: { name: "{%name%}" },
};

const mapStateToProps = (state) => {
  return {
    Can: abilitiesSelector(state),
  };
};

const mapDispatchToProps = {
  getMachineryById,
  clearCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewMachinery);
