import { createSelector } from "reselect";
import { get } from "lodash";
import { Item } from "semantic-ui-react";

const tableDataSelector = state => state.reports.shedReportNutrient.data;
const tableListDataSelector = state => state.reports.shedReportNutrient.list;
const areasListSelector = state => state.areas.list.content;
const farmListSelector = state => state.farms.summary;

export const formaterData = (data, areasList, farmsList) => {
  if (areasList.length && data.content && farmsList) {
    const { content, page, ...rest } = data;

    const distructData = content?.length
      ? content.map(item => {
          if (item.children && item.items) {
            return {
              farmId: item.farmId,
              children: [...item.children, ...item.items]
            };
          }

          if (item.children && !item.items) {
            return item;
          }

          if (!item.children && item.items) {
            return {
              farmId: item.farmId,
              children: [...item.items]
            };
          }
        })
      : [];
    const contentUpdated = distructData
      .map((block, item) => {
        const farmData = farmsList.find(
          ({ farm }) => farm.id === get(block, "farmId", "")
        );
        return {
          withPatches: !!block.children,
          ...(block.children &&
            block.children.reduce(
              (prevPatch, current, index, childrens) => {
                const areaData = areasList.find(
                  ({ id }) => id === current.areaId
                );
                return {
                  ...prevPatch,
                  name: get(farmData.farm, "name", "-"),
                  nitrogen: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.n, 0),
                  potassium: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.k, 0),
                  calcium: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.ca, 0),
                  copper: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.cu, 0),
                  sulphur: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.s, 0),
                  iron: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.fe, 0),
                  boron: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.b, 0),
                  molybdenum: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.mo, 0),
                  chlorine: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.cl, 0),
                  phosphorus: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.p, 0),
                  sodium: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.na, 0),
                  zinc: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.zn, 0),
                  manganese: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.mn, 0),
                  magnesium: childrens
                    .map(item => {
                      return item.items ? item.items : item;
                    })
                    .flat()
                    .reduce((prev, curr) => prev + curr.mg, 0),
                  subRows: [
                    ...(prevPatch && prevPatch.subRows),
                    (current.items ? current.items : childrens).reduce(
                      (prev, curr, _, items) => {
                        const areaData = areasList.find(
                          ({ id }) => id === current.areaId
                        );

                        return {
                          ...prev,
                          name: get(areaData, "name", "-"),
                          nitrogen: current.items
                            ? items.reduce((prev, curr) => prev + curr.n, 0)
                            : current.n,
                          potassium: current.items
                            ? items.reduce((prev, curr) => prev + curr.k, 0)
                            : current.k,
                          calcium: current.items
                            ? items.reduce((prev, curr) => prev + curr.ca, 0)
                            : current.ca,
                          copper: current.items
                            ? items.reduce((prev, curr) => prev + curr.cu, 0)
                            : current.cu,
                          sulphur: current.items
                            ? items.reduce((prev, curr) => prev + curr.s, 0)
                            : current.s,
                          iron: current.items
                            ? items.reduce((prev, curr) => prev + curr.fe, 0)
                            : current.fe,
                          boron: current.items
                            ? items.reduce((prev, curr) => prev + curr.b, 0)
                            : current.b,
                          molybdenum: current.items
                            ? items.reduce((prev, curr) => prev + curr.mo, 0)
                            : current.mo,
                          chlorine: current.items
                            ? items.reduce((prev, curr) => prev + curr.cl, 0)
                            : current.cl,
                          phosphorus: current.items
                            ? items.reduce((prev, curr) => prev + curr.p, 0)
                            : current.p,
                          sodium: current.items
                            ? items.reduce((prev, curr) => prev + curr.na, 0)
                            : current.na,
                          zinc: current.items
                            ? items.reduce((prev, curr) => prev + curr.zn, 0)
                            : current.zn,
                          manganese: current.items
                            ? items.reduce((prev, curr) => prev + curr.mn, 0)
                            : current.mn,
                          magnesium: current.items
                            ? items.reduce((prev, curr) => prev + curr.mg, 0)
                            : current.mg,
                          isSubRows: true,
                          ...(current.items && {
                            subRows: current.items.map(
                              ({
                                areaId,
                                b,
                                ca,
                                cu,
                                s,
                                n,
                                fe,
                                k,
                                mo,
                                na,
                                p,
                                cl,
                                mg,
                                mn,
                                zn
                              }) => {
                                const areaData = areasList.find(
                                  item => item.id === areaId
                                );
                                return {
                                  name: get(areaData, "name", "-"),
                                  nitrogen: n,
                                  potassium: k,
                                  calcium: ca,
                                  copper: cu,
                                  sulphur: s,
                                  iron: fe,
                                  boron: b,
                                  molybdenum: mo,
                                  chlorine: cl,
                                  phosphorus: p,
                                  sodium: na,
                                  zinc: zn,
                                  manganese: mn,
                                  magnesium: mg,
                                  subRows: []
                                };
                              }
                            )
                          })
                        };
                      },
                      {
                        name: "",
                        boron: null,
                        calcium: null,
                        copper: null,
                        sulphur: null,
                        nitrogen: null,
                        iron: null,
                        potassium: null,
                        molybdenum: null,
                        manganese: null,
                        manganese: null,
                        zinc: null,
                        chlorine: null,
                        phosphorus: null,
                        sodium: null,
                        subRows: []
                      }
                    )
                  ]
                };
              },
              {
                name: "",
                boron: null,
                calcium: null,
                copper: null,
                sulphur: null,
                nitrogen: null,
                iron: null,
                potassium: null,
                molybdenum: null,
                manganese: null,
                manganese: null,
                zinc: null,
                chlorine: null,
                phosphorus: null,
                sodium: null,
                subRows: []
              }
            ))
        };
      }, {})
      .map(({ subRows, withPatches, ...rest }) => ({
        ...rest,
        ...(withPatches
          ? {
              subRows:
                subRows &&
                subRows?.map(({ subRows, ...rest }) =>
                  subRows?.length >= 1 ? { ...rest, subRows } : rest
                )
            }
          : subRows?.length >= 1 && { subRows })
      }));

    return {
      ...rest,
      page,
      content: contentUpdated
    };
  }
  return {
    content: [],
    page: {}
  };
};
export const selectTableStructuredData = createSelector(
  [tableDataSelector, areasListSelector, farmListSelector],
  (tableData, areasList, farmsList) => {
    return formaterData(tableData, areasList, farmsList);
  }
);

export const selectExpotStructuredData = createSelector(
  [tableListDataSelector, areasListSelector, farmListSelector],
  (listData, areasList, farmsList) => {
    return formaterData(listData, areasList, farmsList);
  }
);
