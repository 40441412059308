import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Icon, Button } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import {
  selectTableStructuredData,
  selectInProgressTableStructuredData,
  activeItemSelector
} from "selectors/workingTime";
import {
  fetchGeneralTask,
  setActiveItemId,
  setActiveTab,
  updateWorkingTimeStatus,
  deleteWorkingTime,
  deleteWorkingTimes
} from "actions/WorkingTime";
import { fetchGeneralTasksIDs } from "actions/Tasks";
import styles from "./WorkingTime.module.css";
import { Chip } from "routes/Reports/Harvest/components/Chip";
import { StatusChip } from "./components/StatusChip";
import { uniq } from "lodash";
import doubleCheck from "assets/img/doubleCheck.svg";
import Sidebar from "./SideBar/completed/Sidebar";
import InprogressSidebar from "./SideBar/inprogress/Sidebar";
import ClockOut from "./modal/ClockOut/ClockOut";
import ClockIn from "./modal/ClockIn/ClockIn";
import AddInterval from "./modal/AddInterval/AddInterval";
import { employeeViewer } from "routes/Tasks/components/helper";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { farmsOptionsFilterSelector } from "selectors/farms";
import { areaFilterOptions } from "selectors/areas";
import filterTypes from "components/Table/filterTypes";
import { generalTasksFilterOptions } from "selectors/workingTime";
import { getFarmsList } from "actions/Farms/farms";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
import ConfirmationModal from "./components/ConfirmationModal";

const WorkingTime = ({
  navigate,
  location,
  route,
  employees,
  activeItem,
  areas,
  farms,
  taskList,
  pageActiveTab,
  isFetching,
  isFetchingClockIn
}) => {
  const dispatch = useDispatch();
  const [clockOutOpen, setClockOutOpen] = useState(false);
  const [forWtAdd, setForWtAdd] = useState(false);
  const [clockIn, setClockIn] = useState(false);
  const [clockOutValue, setClockOutValue] = useState(false);
  const [addInterval, setAddInterval] = useState(false);
  const [activeTab, setactiveTab] = useState(pageActiveTab);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [subRowsIndex, setSubRowsIndex] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [workingTimesToDelete, setWorkingTimesToDelete] = useState([]);
  const [params, setParams] = useState([]);
  const [filters, setFilters] = useState({
    cropIds: [],
    varietyIds: [],
    blockIds: [],
    patchIds: [],
    harvestUnitIds: [],
    seasons: [],
    from: "",
    to: ""
  });
  const [open, setOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState(false);
  const farmsList = useSelector(farmsOptionsFilterSelector);
  const handleItemClick = async (e, { name }) => {
    name !== activeTab && setactiveTab(name);
    await dispatch(setActiveTab(name));
  };
  const getData = useCallback(
    params => {
      setParams(params);
      const workingTimeCompleted = pageActiveTab === "COMPLETED" ? true : false;
      dispatch(fetchGeneralTask(params, pageActiveTab, workingTimeCompleted));

      dispatch(setActiveItemId(null));
    },
    [dispatch, activeTab]
  );

  const onRowClick = useCallback(
    (event, row) => {
      return (
        row.original.rowInfo !== "totalRow"
          ? setSubRowsIndex(row.index)
          : setSubRowsIndex("totalRow"),
        !activeItem &&
          row &&
          !event.target.closest(".gtId-cell") &&
          event.target.id !== "btn" &&
          (dispatch(setActiveItemId(row.original.id)), setSideBarOpen(true))
      );
    },
    [activeItem, dispatch]
  );

  const approvedWorkingTime = wtId => {
    dispatch(updateWorkingTimeStatus(wtId));
  };
  const tableData = useSelector(selectTableStructuredData);
  const inProgressTableDataSelector = useSelector(
    selectInProgressTableStructuredData
  );

  const handleOnDeleteWorkingTime = async () => {
    if (workingTimesToDelete.length === 1) {
      await dispatch(deleteWorkingTime(workingTimesToDelete[0]));
    } else {
      await dispatch(deleteWorkingTimes(workingTimesToDelete));
    }

    setOpenConfirmationModal(false);

    dispatch(fetchGeneralTask(params, pageActiveTab, true));
  };

  useEffect(() => {
    return setExpandedRow(false);
  }, [tableData.number]);

  useEffect(() => {
    return setExpandedRow(false);
  }, [inProgressTableDataSelector.number]);
  useEffect(() => {
    !farms && !farms?.length && dispatch(getFarmsList());
  }, []);
  useEffect(() => {
    dispatch(fetchGeneralTasksIDs());
  }, []);
  const completedTableData = [
    {
      accessor: "gtId",
      id: "expander",
      Header: () => (
        <div
          style={{
            height: "64px",
            paddingLeft: `${1.2}rem`,
            paddingTop: "21px"
          }}
        >
          ID
        </div>
      ),
      withSort: false,
      Cell: ({ value, row }) => {
        return row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${0.1}rem`
              }
            })}
            className="gtId-cell"
          >
            <Icon
              onClick={e => {
                setExpandedRow(row.id);
              }}
              name={`angle ${row.isExpanded ? "up" : "down"}`}
            />
            {value}
          </span>
        ) : row.depth === 1 ? (
          <span
            style={{ paddingLeft: `${1.2}rem` }}
            className="gtId-cell"
          ></span>
        ) : (
          <span style={{ paddingLeft: `${1.2}rem` }} className="gtId-cell">
            {value}
          </span>
        );
      },
      cellWidth: 2,
      minWidth: 280,
      filterId: "seasons",
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector
      }
    },
    {
      Header: "Task name",
      id: "taskName",
      withSort: false,
      accessor: "taskName",
      cellWidth: 2,
      Cell: ({ value }) => value,
      filterId: "date",
      filter: {
        title: "Date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching} />
      }
    },
    {
      Header: "Type",
      id: "type",
      withSort: false,
      accessor: "type",
      cellWidth: 2,
      Cell: ({ value }) => value,
      filterId: "farmId",
      filter: {
        title: "Farm",
        type: filterTypes.MultiSelect,
        selector: farmsOptionsFilterSelector
      }
    },
    {
      Header: "Employees",
      id: "employees",
      withSort: false,
      accessor: "employees",
      cellWidth: 2,
      Cell: ({ value }) => {
        const formattedEmployee =
          value.length > 1
            ? employees
                .filter(item => value.includes(item.id))
                .map(item => `${item.firstName} ${item.lastName}`)
            : employees.find(item => item.id === value[0]);
        return value.length > 1 ? (
          <Chip label="Employees" items={uniq(formattedEmployee)} />
        ) : (
          `${formattedEmployee?.firstName ||
            "-"} ${formattedEmployee?.lastName || ""}`
        );
      },
      filterId: "areaId",
      filter: {
        title: "Area",
        type: filterTypes.MultiSelect,
        selector: areaFilterOptions
      }
    },
    {
      Header: "Added by",
      id: "createdBy",
      withSort: false,
      accessor: "createdBy",
      cellWidth: 2,
      Cell: ({ value }) => {
        return employeeViewer(value, employees);
      },
      filterId: "ids",
      filter: {
        title: "Task Id",
        type: filterTypes.MultiSelect,
        selector: generalTasksFilterOptions
      }
    },
    {
      Header: "Total working time",
      id: "totalWorkingTime",
      withSort: false,
      accessor: "totalWorkingTime",
      cellWidth: 2,
      Cell: ({ value }) => {
        if (value) {
          const hours = Math.floor(value);
          const minutes = Math.round((value - hours) * 60);
          return `${hours}h ${minutes}m`;
        }

        return "-";
      }
    },
    {
      Header: "Status",
      id: "approved",
      withSort: false,
      accessor: "approved",
      cellWidth: 2,
      Cell: ({ value }) => <StatusChip approved={value} />
    },
    {
      Header: "",
      id: "buttonWrapper",
      withSort: false,
      accessor: "buttonWrapper",
      cellWidth: 2,
      Cell: ({ row, value }) => {
        return (
          <div className={styles.btnWrapper} id="btn">
            <Button
              id="btn"
              onClick={event => {
                return (
                  setClockOutOpen(true),
                  dispatch(setActiveItemId(row?.original?.id))
                );
              }}
              className={styles.editBtn}
              icon={<Icon id="btn" name="edit" />}
            />
            {!value ? (
              <Button
                onClick={() => approvedWorkingTime(row.original.wtId)}
                id="btn"
                className={styles.editBtn}
                icon={<Icon id="btn" name="check" />}
              />
            ) : (
              <img
                id="btn"
                className={styles.doubleCheck}
                src={doubleCheck}
                alt="Drag icon"
              />
            )}
            <Button
              onClick={e => {
                e.stopPropagation();
                setWorkingTimesToDelete(row.original.wtId);
                setOpenConfirmationModal(true);
              }}
              className={styles.deleteButton}
              floated="right"
              icon={<Icon name="trash alternate" />}
            />
          </div>
        );
      }
    }
  ];

  const inProgressTableData = [
    {
      accessor: "gtId",
      id: "expander",
      Header: () => (
        <div
          style={{
            height: "64px",
            paddingLeft: `${1.2}rem`,
            paddingTop: "21px"
          }}
        >
          ID
        </div>
      ),
      withSort: false,
      Cell: ({ value, row }) => {
        return row.canExpand ? (
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${0.1}rem`
              }
            })}
            className="gtId-cell"
          >
            <Icon
              onClick={e => {
                setExpandedRow(row.id);
              }}
              name={`angle ${row.isExpanded ? "up" : "down"}`}
            />
            {value}
          </span>
        ) : row.depth === 1 ? (
          <span
            style={{ paddingLeft: `${1.2}rem` }}
            className="gtId-cell"
          ></span>
        ) : (
          <span style={{ paddingLeft: `${1.2}rem` }} className="gtId-cell">
            {value}
          </span>
        );
      },
      cellWidth: 2,
      minWidth: 280,
      filterId: "seasons",
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector
      }
    },
    {
      Header: "Task name",
      id: "taskName",
      withSort: false,
      accessor: "taskName",
      cellWidth: 3,
      Cell: ({ value }) => value,
      filterId: "date",
      filter: {
        title: "Date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching || isFetchingClockIn} />
      }
    },
    {
      Header: "Type",
      id: "type",
      withSort: false,
      accessor: "type",
      cellWidth: 2,
      Cell: ({ value }) => value,
      filterId: "farmId",
      filter: {
        title: "Farm",
        type: filterTypes.MultiSelect,
        selector: farmsOptionsFilterSelector
      }
    },
    {
      Header: "Areas",
      id: "areas",
      withSort: false,
      accessor: "areas",
      cellWidth: 2,
      Cell: ({ value }) => {
        return Array.isArray(value)
          ? areas
              .filter(item => value?.map(i => i.areaId)?.includes(item.id))
              .map(a => a.name)
              ?.join(", ")
          : value;
      },
      filterId: "areaId",
      filter: {
        title: "Area",
        type: filterTypes.MultiSelect,
        selector: areaFilterOptions
      }
    },
    {
      Header: "Employees",
      id: "employees",
      withSort: false,
      accessor: "employees",
      cellWidth: 3,
      Cell: ({ value }) => {
        const formattedEmployee =
          value.length > 1
            ? employees
                .filter(item => value.includes(item.id))
                .map(item => `${item.firstName} ${item.lastName}`)
            : employees.find(item => item.id === value[0]);
        return value.length > 1 ? (
          <Chip label="Employees" items={uniq(formattedEmployee)} />
        ) : (
          `${formattedEmployee?.firstName ||
            "-"} ${formattedEmployee?.lastName || ""}`
        );
      },
      filterId: "ids",
      filter: {
        title: "Task Id",
        type: filterTypes.MultiSelect,
        selector: generalTasksFilterOptions
      }
    },
    {
      Header: "Added by",
      id: "createdBy",
      withSort: false,
      accessor: "createdBy",
      cellWidth: 2,
      Cell: ({ value }) => {
        return employeeViewer(value, employees);
      }
    },
    {
      Header: "",
      id: "buttonWrapper",
      withSort: false,
      accessor: "buttonWrapper",
      cellWidth: 2,
      Cell: ({ row, value }) => {
        return (
          <Button
            id="btn"
            className={
              value === "totalRow"
                ? styles.totalClockOutBtn
                : styles.clockOutBtn
            }
            onClick={() => {
              return (
                setForWtAdd(true),
                value === "subRows" && setClockOutValue(row.id),
                dispatch(setActiveItemId(row?.original?.id)),
                setClockOutOpen(true)
              );
            }}
          >
            Clock Out
          </Button>
        );
      }
    }
  ];

  const columnsConfig = useMemo(
    () =>
      activeTab === "IN_PROGRESS" ? inProgressTableData : completedTableData,
    [filters, employees, activeTab]
  );
  return (
    <>
      <ClockOut
        workingTimeCompleted={pageActiveTab === "COMPLETED" ? true : false}
        params={params}
        pageActiveTab={pageActiveTab}
        forWtAdd={forWtAdd}
        setForWtAdd={setForWtAdd}
        open={clockOutOpen}
        subRowsIndex={subRowsIndex}
        setSubRowsIndex={setSubRowsIndex}
        setSideBarOpen={setSideBarOpen}
        onClose={() => {
          return setClockOutOpen(false), dispatch(setActiveItemId(null));
        }}
        activeItem={activeItem}
      />
      <ClockIn
        pageActiveTab={pageActiveTab}
        open={clockIn}
        onClose={() => {
          return setClockIn(false), dispatch(setActiveItemId(null));
        }}
        activeItem={activeItem}
      />
      <AddInterval
        params={params}
        workingTimeCompleted={pageActiveTab === "COMPLETED" ? true : false}
        pageActiveTab={pageActiveTab}
        open={addInterval}
        onClose={() => {
          return setAddInterval(false), dispatch(setActiveItemId(null));
        }}
        activeItem={activeItem}
      />
      <TablePageHolder
        mainButton={
          <div>
            {/* <Can I="add" a="sheds"> */}
            <Button
              primary
              className={styles.clockInButton}
              onClick={() => setClockIn(true)}
            >
              Clock In
            </Button>
            {/* </Can> */}
            <Button
              primary
              className={styles.addButton}
              onClick={() => setAddInterval(true)}
            >
              Add Interval
            </Button>
          </div>
        }
        sidebarShown={sideBarOpen}
        rowId={expandedRow}
        getData={getData}
        isFetching={
          activeTab === "IN_PROGRESS"
            ? inProgressTableDataSelector.isFetching
            : tableData.isFetching
        }
        location={location}
        onRowClick={onRowClick}
        onSidebarHidden={() => {
          return dispatch(setActiveItemId(null));
        }}
        navigate={navigate}
        open={open}
        setSideBarOpen={setSideBarOpen}
        handleItemClick={handleItemClick}
        activeTab={activeTab}
        timeTracking={true}
        setFilters={setFilters}
        setOpen={setOpen}
        pageTitle={route.name}
        route={route}
        tableColumns={columnsConfig}
        withSelection={false}
        withBorder={false}
        tableData={
          activeTab === "IN_PROGRESS" ? inProgressTableDataSelector : tableData
        }
        withoutSort={false}
        firstColumnFixed={false}
        singleLine
        fixed={true}
        sidebarComponent={
          activeTab === "IN_PROGRESS" ? (
            <InprogressSidebar
              setForWtAdd={setForWtAdd}
              employees={employees}
              taskList={taskList}
              subRowsIndex={subRowsIndex}
              setSubRowsIndex={setSubRowsIndex}
              setClockOutOpen={setClockOutOpen}
              setSideBarOpen={setSideBarOpen}
              clockOutValue={clockOutValue}
              activeItem={activeItem}
              setActiveItemId={setActiveItemId}
            />
          ) : (
            <Sidebar
              employees={employees}
              taskList={taskList}
              subRowsIndex={subRowsIndex}
              setSubRowsIndex={setSubRowsIndex}
              setClockOutOpen={setClockOutOpen}
              setSideBarOpen={setSideBarOpen}
              activeItem={activeItem}
              setActiveItemId={setActiveItemId}
            />
          )
        }
      />
      <ConfirmationModal
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        workingTimes={workingTimesToDelete}
        onConfirm={handleOnDeleteWorkingTime}
      />
    </>
  );
};

export default connect((state, props) => {
  return {
    pageActiveTab: state.workingTimes.activeTab.tab,
    areas: state.areas.list.content,
    farms: state.farms.summary,
    employees: [
      ...state.employee.list.content,
      ...state.employee.archivedList.content
    ],
    filterValues: state.packedBoxes.filters,
    isFetching: state.workingTimes.data.isFetching,
    isFetchingClockIn: state.workingTimes.clockIn.isFetching,
    activeItem: activeItemSelector(state),
    error: state.packedBoxes.error
  };
})(WorkingTime);
