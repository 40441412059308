import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { HistoryRouter } from "redux-first-history/rr6";
import { Route, Routes } from "react-router-dom";
import { OidcProvider } from "redux-oidc";
import CacheBuster from "./utils/CacheBuster";
import { createReduxHistory } from "redux-first-history";
import "semantic-ui-css/semantic.css";
import AuthCallBack from "./routes/AuthCallBack";
import RoutesComponent from "./routes/Routes/Routes";
import { userManager } from "./index";
import "./App.css";

function App({ store, history }) {
  return (
    <Provider store={store}>
      <CacheBuster>
        {({ loading }) => {
          if (loading) return null;
          return (
            <OidcProvider store={store} userManager={userManager}>
              <HistoryRouter history={history}>
                <Routes>
                  <Route
                    path="/auth-callback"
                    element={<AuthCallBack userManager={userManager} />}
                  />
                  <Route path="/*" element={<RoutesComponent />} />
                </Routes>
              </HistoryRouter>
            </OidcProvider>
          );
        }}
      </CacheBuster>
    </Provider>
  );
}

App.propTypes = {
  store: PropTypes.object.isRequired
};

export default App;
