import React, { useState } from "react";
import { Popup, Table } from "semantic-ui-react";
import AttachmentLabel from "components/Attachments/AttachmentLabel";
import styles from "./Table.module.css";
import moment from "moment";

const TaskInformation = ({
  task,
  taskWorkingTimes,
  employeeList,
  machineryList
}) => {
  const [activeMenuItem, setActiveMenuItem] = useState("Timing");
  const handleMenuItemClick = menuItem => {
    setActiveMenuItem(menuItem);
    const sectionId = `section-${menuItem}`;
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const filtredSupervisor = employeeList.filter(item =>
    task.supervisors.map(i => i.employeeId).includes(item.id)
  );
  const filtredAssignees = employeeList.filter(item =>
    task.assignedTo.map(i => i.employeeId).includes(item.id)
  );
  const filtredMachinery = machineryList.filter(item =>
    task.equipments.map(i => i.machineryId).includes(item.id)
  );

  const filtredContractor = taskWorkingTimes
    .filter(wt => wt.contractor)
    .map(i => i.contractor);

  return (
    <div className={styles.sectionTableWrapper}>
      <div className={styles.navigateMenu}>
        <div className={styles.generalInfoSection}>
          <div
            className={
              activeMenuItem === "GeneralInfo" ||
              activeMenuItem === "Timing" ||
              activeMenuItem === "Assign to" ||
              activeMenuItem === "Additional information"
                ? styles.activeMenuHeader
                : styles.menuHeader
            }
            onClick={() => handleMenuItemClick("Timing")}
          >
            General information
          </div>
          <div
            className={`${styles.menuSubHeader} ${
              activeMenuItem === "Timing" ? styles.activeMenuItem : ""
            }`}
            onClick={() => handleMenuItemClick("Timing")}
          >
            Timing
          </div>
          <div
            className={`${styles.menuSubHeader} ${
              activeMenuItem === "Assign to" ? styles.activeMenuItem : ""
            }`}
            onClick={() => handleMenuItemClick("Assign to")}
          >
            Assign to
          </div>
          <div
            className={`${styles.menuSubHeader} ${
              activeMenuItem === "Additional information"
                ? styles.activeMenuItem
                : ""
            }`}
            onClick={() => handleMenuItemClick("Additional information")}
          >
            Additional information
          </div>
        </div>
        <div className={styles.detailsSection}>
          <div
            className={
              activeMenuItem === "Machinery"
                ? styles.activeMenuHeader
                : styles.menuHeader
            }
            onClick={() => handleMenuItemClick("Machinery")}
          >
            Details
          </div>
          <div
            className={`${styles.menuSubHeader} ${
              activeMenuItem === "Machinery" ? styles.activeMenuItem : ""
            }`}
            onClick={() => handleMenuItemClick("Machinery")}
          >
            Machinery
          </div>
        </div>
      </div>
      <div className={`${styles.navigateTableContainer} scrollable-container`}>
        <div className={styles.tableHeader}>General information</div>
        <div className={styles.tableSegment} id="section-Timing">
          <div className={styles.tableSubHeader}>Timing</div>
          <Table className={styles.sectionTable} definition fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell className={styles.headerSection}>
                  Planned date rage
                </Table.Cell>
                <Table.Cell className={styles.valueSection}>
                  {`${moment(task?.startAt).format("DD/MM/YY")}-
                    ${moment(task?.endAt).format("DD/MM/YY")}`}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className={styles.headerSection}>
                  Suggested expiry date and time
                </Table.Cell>
                <Table.Cell className={styles.valueSection}>
                  {`${moment(task?.expireAt).format("DD/MM/YYYY hh:mm")}`}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <div className={styles.tableSegment} id="section-Assign to">
          <div className={styles.tableSubHeader}>Assign to</div>
          <Table className={styles.sectionTable} definition fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell className={styles.headerSection}>
                  Supervisor
                </Table.Cell>
                <Table.Cell className={styles.valueSection}>
                  {filtredSupervisor.length
                    ? filtredSupervisor.map(item => (
                        <p>{`${item.firstName}${item.lastName}`}</p>
                      ))
                    : "-"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className={styles.headerSection}>
                  Contractor
                </Table.Cell>
                <Table.Cell className={styles.valueSection}>
                  {filtredContractor.length
                    ? filtredContractor.map(item => <p>{`${item.name}`}</p>)
                    : "-"}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className={styles.headerSection}>
                  Assignees
                </Table.Cell>
                <Table.Cell className={styles.valueSection}>
                  {filtredAssignees.length
                    ? filtredAssignees.map(item => (
                        <p>{`${item.firstName}${item.lastName}`}</p>
                      ))
                    : "-"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <div
          className={styles.tableSegment}
          id="section-Additional information"
        >
          <div className={styles.tableSubHeader}>Additional information</div>
          <Table className={styles.sectionTable} definition fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell className={styles.headerSection}>Notes</Table.Cell>
                <Table.Cell className={styles.valueSection}>
                  {task.description}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell className={styles.headerSection}>
                  Attachments
                </Table.Cell>
                <Table.Cell className={styles.valueSection}>
                  {task.attachments
                    ? task.attachments.map(item => (
                        <Popup
                          inverted
                          position="left center"
                          content={item.name}
                          size="mini"
                          trigger={
                            <div className={styles.attachmentContainer}>
                              {task && (
                                <AttachmentLabel
                                  attachment={item}
                                  withDownloadIcon={true}
                                />
                              )}
                            </div>
                          }
                        />
                      ))
                    : null}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <div className={styles.tableHeader}>Details</div>
        <div className={styles.tableSegment} id="section-Machinery">
          <div className={styles.tableSubHeader}>Machinery</div>
          <Table className={styles.sectionTable} definition fixed>
            <Table.Body>
              <Table.Row>
                <Table.Cell className={styles.headerSection}>
                  Required equipment
                </Table.Cell>
                <Table.Cell className={styles.valueSection}>
                  {filtredMachinery.length
                    ? filtredMachinery.map(item => <p>{item.name}</p>)
                    : "-"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TaskInformation;
