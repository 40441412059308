import React from "react";
import PropTypes from "prop-types";
import { Grid, Header, Table } from "semantic-ui-react";
import moment from "moment";
import classNames from "classnames";
import styles from "./PrintReport.module.css";
import { isEmptyValue } from "../../../../../utils/tasksUtils";
import Logo from "../../../../../components/Logo";
import { round } from "lodash";

class PrintReport extends React.Component {
  render() {
    const {
      data,
      startDate,
      endDate,
      showExpandedData,
      drawMultipleItems,
      shedsList
    } = this.props;
    const drawAdditionalInfoItem = (text, value, skipForEmpty) => (
      <div>
        <span>{text} </span>
        {skipForEmpty ? value : isEmptyValue(value)}
      </div>
    );

    const dataKeys = Object.keys(data);
    return (
      <div className={styles.reportWrapper}>
        <style>{`@page { size: landscape !important;} html,body {margin: 0px;}`}</style>
        <div className={styles.reportHeaderRow}>
          <div>
            <div className={styles.reportHeader}>Application Record</div>
            {startDate && endDate && (
              <div className={styles.reportSubHeader}>
                {`For application tasks applied between ${moment(
                  startDate
                ).format("DD/MM/YY")}
                    to ${moment(endDate).format("DD/MM/YY")}`}
              </div>
            )}
            <div className={styles.reportSubHeader}>
              {`I/We declare that this diary is a true and accurate record of chemicals applied.       Signed.......................`}
            </div>
          </div>
          <Logo width={140} height={60} />
        </div>
        {dataKeys.length > 0
          ? dataKeys.map((key, farmIndex) => {
              return (
                <Grid.Row key={`farm_${key}`}>
                  {data[key].tasks.length > 0 &&
                    data[key].tasks.map(
                      (
                        {
                          date,
                          expirationDate,
                          taskId,
                          type,
                          farmSize,
                          farms,
                          plannedDate,
                          areas,
                          crops,
                          varieties,
                          chemicalsFertilisers,
                          batchNumbers,
                          activeIngredients,
                          purposes,
                          withholdingPeriods,
                          productTypeList,
                          deltaT,
                          safeHarvestDate,
                          concentrationFactor,
                          actualRateApplied,
                          literPerHectare,
                          shedId,
                          taskAdditionalInfo: {
                            workingTimes,
                            windSpeed,
                            windDirection,
                            temperature,
                            supervisors,
                            assignees,
                            machinery,
                            comment
                          }
                        },
                        index
                      ) => {
                        return (
                          <>
                            <Table
                              basic="very"
                              singleLine
                              className={styles.farmTable}
                            >
                              <Table.Header fullWidth>
                                <Table.Row className={styles.Header}>
                                  <Table.Cell className={styles.headerCell}>
                                    Planned date
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {moment(plannedDate).format("DD/MM/YYYY") ||
                                      "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.midleHeaderCell}
                                  >
                                    Supervisors
                                  </Table.Cell>
                                  <Table.Cell className={styles.midleValueCell}>
                                    {supervisors || "-"}
                                  </Table.Cell>
                                  <Table.Cell className={styles.headerCell}>
                                    Working Times
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {workingTimes || "-"}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell className={styles.headerCell}>
                                    Completed date
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {date ? date : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.midleHeaderCell}
                                  >
                                    Operators
                                  </Table.Cell>
                                  <Table.Cell className={styles.midleValueCell}>
                                    {assignees.length ? assignees : "-"}
                                  </Table.Cell>
                                  <Table.Cell className={styles.headerCell}>
                                    Wind Speed
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {windSpeed || "-"}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell className={styles.headerCell}>
                                    Expiry date{" "}
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {expirationDate
                                      ? moment(expirationDate).format(
                                          "DD/MM/YYYY"
                                        )
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.midleHeaderCell}
                                  >
                                    Machinery
                                  </Table.Cell>
                                  <Table.Cell className={styles.midleValueCell}>
                                    {machinery || "-"}
                                  </Table.Cell>
                                  <Table.Cell className={styles.headerCell}>
                                    Wind Direction
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {windDirection || "-"}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell className={styles.headerCell}>
                                    Task ID{" "}
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {taskId || "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.midleHeaderCell}
                                  >
                                    Comments
                                  </Table.Cell>
                                  <Table.Cell className={styles.midleValueCell}>
                                    {comment || "-"}
                                  </Table.Cell>
                                  <Table.Cell className={styles.headerCell}>
                                    Temperature
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {temperature ? `${temperature} °C` : "—"}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell className={styles.headerCell}>
                                    Application type
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {(
                                      type
                                        .toLowerCase()
                                        .charAt(0)
                                        .toUpperCase() +
                                      type.toLowerCase().slice(1)
                                    ).replace(/\s/g, "_") || "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.midleHeaderCell}
                                  ></Table.Cell>
                                  <Table.Cell
                                    className={styles.midleValueCell}
                                  ></Table.Cell>
                                  <Table.Cell className={styles.headerCell}>
                                    Delta T
                                  </Table.Cell>
                                  <Table.Cell className={styles.valueCell}>
                                    {deltaT ? deltaT : "—"}
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Header>
                            </Table>
                            <Table
                              basic="very"
                              celled
                              className={styles.secondFarmTable}
                            >
                              <Table.Header
                                className={styles.secondFarmTableHeader}
                              >
                                <Table.Row>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderSmallCell
                                    }
                                  >
                                    Farm
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableHeaderAreaCell}
                                  >
                                    Area
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderSmallCell
                                    }
                                  >
                                    Crop
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderVarietyCell
                                    }
                                  >
                                    Variety
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderSmallCell
                                    }
                                  >
                                    Shed
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderSmallCell
                                    }
                                  >
                                    Product type
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderLargeCell
                                    }
                                  >
                                    Chemical/Fertiliser
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderLargeCell
                                    }
                                  >
                                    Batch Number
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderActiveIngredientLargeCell
                                    }
                                  >
                                    Active ingredient
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderPurposeCell
                                    }
                                  >
                                    Purpose
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableHeaderWHPCell}
                                  >
                                    Withholding Period
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderSafeHarvestCell
                                    }
                                  >
                                    Safe Harvest Date
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderMiddleCell
                                    }
                                  >
                                    Concentration Factor
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderMiddleCell
                                    }
                                  >
                                    Actual Rate Applied
                                  </Table.Cell>
                                  <Table.Cell
                                    className={
                                      styles.secondTableHeaderMiddleCell
                                    }
                                  >
                                    Spray Vol/Ha
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {drawMultipleItems(farms, "farm")}{" "}
                                    {round(
                                      farmSize.reduce((a, b) => {
                                        return a && b ? a + b : 0;
                                      }),
                                      2
                                    )}{" "}
                                    Ha
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {!!areas.length
                                      ? areas.map(i => {
                                          return (
                                            <p
                                              className={styles.areasContainer}
                                            >
                                              &bull;{i}
                                            </p>
                                          );
                                        })
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {!!crops.length
                                      ? crops.map(i => {
                                          return (
                                            <p
                                              className={styles.cropsContainer}
                                            >
                                              &bull;{i}
                                            </p>
                                          );
                                        })
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {!!varieties.length
                                      ? varieties.map(i => {
                                          return (
                                            <p
                                              className={
                                                styles.varietiesContainer
                                              }
                                            >
                                              &bull;{i}
                                            </p>
                                          );
                                        })
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {shedsList.find(item => item.key === shedId)
                                      ?.text || "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {drawMultipleItems(
                                      productTypeList,
                                      "productTypeList"
                                    )}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {" "}
                                    {drawMultipleItems(
                                      chemicalsFertilisers,
                                      "chemical"
                                    )}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {drawMultipleItems(batchNumbers, "batch")}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {!!activeIngredients.length
                                      ? activeIngredients.map(i => {
                                          return (
                                            <p
                                              className={
                                                styles.activeIngredientsContainer
                                              }
                                            >
                                              &bull;{i}
                                            </p>
                                          );
                                        })
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {!!purposes.length
                                      ? purposes.map(i => {
                                          return (
                                            <p
                                              className={
                                                styles.purposesContainer
                                              }
                                            >
                                              &bull;{i}
                                            </p>
                                          );
                                        })
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {!!withholdingPeriods.length
                                      ? withholdingPeriods.map(i => {
                                          return (
                                            <p
                                              className={
                                                styles.withholdingPeriodsContainer
                                              }
                                            >
                                              &bull;{i}
                                            </p>
                                          );
                                        })
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {safeHarvestDate || "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {concentrationFactor || "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {!!actualRateApplied.length
                                      ? actualRateApplied.map(i => {
                                          return (
                                            <p
                                              className={
                                                styles.actualRateAppliedContainer
                                              }
                                            >
                                              &bull;{i}
                                            </p>
                                          );
                                        })
                                      : "-"}
                                  </Table.Cell>
                                  <Table.Cell
                                    className={styles.secondTableValue}
                                  >
                                    {literPerHectare.toFixed(2) || "-"} L/Ha
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </>
                        );
                      }
                    )}
                </Grid.Row>
              );
            })
          : "No Data"}
        <div className={styles.reportCreatedRow}>
          Report created {moment().format("DD/MM/YYYY")} using Tie Up Farming
        </div>
      </div>
    );
  }
}
PrintReport.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  showExpandedData: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default PrintReport;
