import React, { useState, useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import ReactTable from "react-table-6";
// import "react-table-6/react-table.css";
// import withFixedColumns from "react-table-hoc-fixed-columns";
// import "react-table-hoc-fixed-columns/lib/styles.css";
import styles from "./StationDataTable.module.css";
import Pagination from "../../../../components/ListTable/Pagination";
// const ReactTableFixedColumns = withFixedColumns(ReactTable);

const getColumnWidth = (data, accessor, headerText) => {
  const maxWidth = 400;
  const magicSpacing = 8.8;
  const cellLength = Math.max(
    ...data.map(row => (`${row[accessor]}` || "").length),
    headerText.length
  );
  return Math.min(maxWidth, cellLength * magicSpacing);
};

const toColumn = (data, header) => ({
  Header: header.headerName,
  accessor: header.field,
  sortable: !header.suppressSorting,
  width: header.width
    ? header.width
    : getColumnWidth(data, header.field, header.headerName)
});

const StationDataTable = ({ grid }) => {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pages: 1,
    currentPage: 0,
    itemsCount: {
      itemFrom: 0,
      itemTo: 0
    },
    totalElements: 0
  });

  const columns = useMemo(
    () =>
      grid.headers &&
      grid.headers.map((header) => {
        return !header.children
          ? {
              fixed: header.pinned && "left",
              columns: [toColumn(grid.data, header)]
            }
          : {
              ...toColumn(grid.data, header),
              columns: header.children.map(child => toColumn(grid.data, child))
            };
      }),
    [grid]
  );

  useEffect(() => {
    grid.data &&
      setPagination({
        ...pagination,
        pages: Math.ceil(grid.data.length / pagination.pageSize),
        currentPage: 0,
        itemsCount: {
          itemFrom: 0,
          itemTo: pagination.pageSize - 1
        },
        totalElements: grid.data.length
      });
  }, [grid.data]); // eslint-disable-line

  const onPageChange = useCallback(
    (currentPage, loadMore) => {
      const itemFrom = loadMore ? 0 : currentPage * pagination.pageSize + 1;
      const itemTo = Math.min(
        itemFrom + pagination.pageSize - 1,
        pagination.totalElements
      );
      const pageSize = loadMore
        ? currentPage * pagination.pageSize + pagination.pageSize
        : pagination.pageSize;
      const pages = loadMore
        ? Math.ceil(pagination.totalElements / pageSize)
        : pagination.pages;
      setPagination({
        ...pagination,
        pageSize,
        pages,
        currentPage: loadMore ? 0 : currentPage,
        itemsCount: {
          itemFrom,
          itemTo
        }
      });
    },
    [pagination]
  );

  const updatePageSize = useCallback(
    (_, { value: pageSize }) => {
      setPagination({
        ...pagination,
        pageSize,
        pages: Math.ceil(pagination.totalElements / pageSize),
        currentPage: 0,
        itemsCount: {
          itemFrom: 0,
          itemTo: pageSize - 1
        }
      });
    },
    [pagination]
  );

  return (
    <div className={styles.tableHolder}>
      {!grid.data ? (
        <span>No data available for this period</span>
      ) : (
        <>
          {/*  <ReactTableFixedColumns
            data={grid.data}
            columns={columns}
            className={`-striped -highlight ${styles.dataTable}`}
            showPagination={false}
            pageSize={pagination.pageSize}
            page={pagination.currentPage}
            minRows={0}
          /> */}
          <Pagination
            pageSizeOptions={[10, 20, 50, 100]}
            {...pagination}
            updatePageSize={updatePageSize}
            onPageChangeOwn={onPageChange}
          /> 
        </>
      )}
    </div>
  );
};

StationDataTable.propTypes = {
  grid: PropTypes.object.isRequired
};

StationDataTable.defaultProps = {};

const mapStateToProps = ({
  weatherStation: {
    stationData: { grid }
  }
}) => {
  return { grid };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StationDataTable);
