import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Segment,
  Input,
  Icon,
  Header,
  Popup
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { Field, Formik, FieldArray, useFormikContext } from "formik";
import { get, map, values } from "lodash";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { shedsListSelector } from "selectors/sheds";
import { productsOptionsSelector } from "selectors/chemicals";
import { manufacturerSelector } from "selectors/purchaseHistory";
import styles from "./Product.module.css";
import { manufacturerOptionsSelector } from "selectors/purchaseHistory";
import NewProduct from "../QuantitiesPurchased";
import { PURCHASE_PRODUCT_INITIAL_VALUES } from "constants/PurchaseHistory";

const ProductForm = ({
  productData,
  productsListCheck,
  productIndex,
  deleteButtonCheck,
  deleteAddingForm,
  setProductId,
  onSubmit,
  setValidation,
  checkForm,
  setCheckForm,
  validation,
  setDirty,
  dirtyRow
}) => {
  const { values, setFieldValue, setValues, validateForm } = useFormikContext();

  const [resetRequired, setResetRequired] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const { options: manufacturesOptions, isFetching } = useSelector(
    manufacturerSelector
  );
  const shownOptions = map(manufacturesOptions, ({ label, ...rest }) => ({
    ...rest,
    text: label
  }));

  const shedsList = useSelector(shedsListSelector);
  const manufacturer = useSelector(manufacturerOptionsSelector);
  const productList = useSelector(productsOptionsSelector);
  const validationSchema = () => {
    return Yup.object().shape({
      totalCost: Yup.number().required("totalCost is required"),
      chemicalId: Yup.number().required("productName is required")
    });
  };
  const handleProductDelete = (productId, productIndex) => {
    if (productId) {
      setFieldValue(
        "products",
        values.products.filter(p => p.id !== productId)
      );
    } else {
      setFieldValue(
        "products",
        values.products.filter((p, i) => i !== productIndex)
      );
    }
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={values => {
        onSubmit(values);
        setResetRequired(true);
      }}
      initialValues={productData || PURCHASE_PRODUCT_INITIAL_VALUES}
    >
      {({ values, dirty, isValid, setValues, setFieldValue, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <>
              <div className={styles.productInformationHeader}>
                Product Information
              </div>
              <Form.Group className={styles.firstRow}>
                <Form.Field required className={styles.productName} width={10}>
                  <label>Product name</label>
                  <Form.Dropdown
                    selection
                    search
                    name="chemicalId"
                    options={productList}
                    value={get(values, "chemicalId")}
                    onChange={(e, { value }) => {
                      return setValues({
                        ...values,
                        chemicalId: value
                      });
                    }}
                  />
                </Form.Field>
                <Form.Field width={10}>
                  <label>Manufacturer</label>
                  <FieldArray
                    name="manufacturer"
                    render={() => (
                      <div className={styles.manufacturerWrapper}>
                        {values.manufacturer && (
                          <Segment className={styles.manufacturersSection}>
                            <Header
                              floated="left"
                              className={styles.manufacturerHeader}
                              size="small"
                            >
                              {values.manufacturer}
                            </Header>
                            <Popup
                              content="Delete manufacturer"
                              position="bottom center"
                              inverted
                              trigger={
                                <Icon
                                  name="trash alternate"
                                  className={styles.removeManufacturer}
                                  onClick={() => {
                                    return setValues({
                                      ...values,
                                      manufacturer: ""
                                    });
                                  }}
                                />
                              }
                            />
                          </Segment>
                        )}
                        {!values.manufacturer && (
                          <Field
                            className={styles.manufacturerField}
                            fluid
                            as={Form.Select}
                            allowAdditions
                            search
                            searchQuery={searchQuery}
                            onSearchChange={(_, { searchQuery }) => {
                              setSearchQuery(searchQuery);
                            }}
                            // loading={isFetching}
                            name="manufacturer"
                            onChange={(e, { value }) => {
                              return setValues({
                                ...values,
                                manufacturer: value
                              });
                            }}
                            options={shownOptions}
                          />
                        )}
                      </div>
                    )}
                  />
                </Form.Field>
                <Form.Field required width={10}>
                  <label>Total cost ($)</label>
                  <Field
                    className={styles.totalCostField}
                    as={Input}
                    name="totalCost"
                    onKeyPress={event => {
                      if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={values.totalCost}
                  />
                </Form.Field>
              </Form.Group>
              <div className={styles.newProductWrapper}>
                <NewProduct
                  setValidation={setValidation}
                  setCheckForm={setCheckForm}
                  checkForm={checkForm}
                  productIndex={productIndex}
                  setDirty={setDirty}
                  setProductId={setProductId}
                  quantities={values.quantities}
                  chemicalId={values.chemicalId}
                  handleProductDelete={handleProductDelete}
                />
              </div>
              <div className={styles.buttonContainer}>
                <>
                  {dirty || !productsListCheck || deleteButtonCheck ? (
                    <>
                      {!!productsListCheck && (
                        <Button
                          className={styles.cancelBtn}
                          onClick={deleteAddingForm}
                        >
                          Cancel
                        </Button>
                      )}
                      <Button
                        type="submit"
                        className={
                          productData
                            ? !isValid || !dirty || checkForm
                              ? styles.disabledBtn
                              : styles.saveButton
                            : !isValid || !validation || !dirty || checkForm
                            ? styles.disabledBtn
                            : styles.saveButton
                        }
                        disabled={
                          (productData
                            ? !isValid || !dirty || checkForm
                            : !isValid || !validation || !dirty) || checkForm
                        }
                      >
                        Save
                      </Button>
                    </>
                  ) : null}
                </>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

ProductForm.propTypes = {
  productData: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default ProductForm;
