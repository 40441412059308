import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import ReactDOMServer from "react-dom/server";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchScannedTagBarcodes } from "../../actions/TagBarcodes/tagBarcodes";
import { Button, Container, Grid, Header, Icon } from "semantic-ui-react";
import { onlineSelector } from "../../selectors/common";
import Filters from "../Filters";
import { getFiltersFromLocations } from "../../utils/routeHelpers";
import moment from "moment";
import BreakdownTable from "./components/BreakdownTable";
import { copyStyles } from "../../utils/styles";
import PrintReport from "../PrintReport";
import styles from "../GeneralTasksCostsBreakdown/index.module.css";
import ExportToExcel from "../ExportToExcel";

const defaultFiltersOptions = [
  {
    name: "Date",
    type: "DateRangeFilter",
    id: "tag_date",
  },
];

const getRowName = (tag) =>
  tag.rowsPrefix || Number.isInteger(tag.row)
    ? `${tag.rowsPrefix ? tag.rowsPrefix + " " : ""}${tag.row}`
    : null;

const formatDate = (date) => (date ? moment(date).format("DD/MM/YYYY") : "-");

const columnsConfig = [
  {
    id: "farmName",
    accessor: "farmName",
    Header: "Farm",
  },
  {
    id: "areaName",
    accessor: "areaName",
    Header: "Area",
  },
  {
    id: "createdAt",
    accessor: "createdAt",
    Header: "Date",
  },
  {
    id: "rowNumber",
    accessor: "rowNumber",
    Header: "Row Number",
  },
  {
    id: "id",
    accessor: "id",
    Header: "Tag Barcode ID",
  },
];

const ScannedTagBarcodes = ({
  location,
  actions: { fetchScannedTagBarcodes },
  content,
  farm,
  area,
  dateFrom,
  dateTo,
  excludeFilters,
  navigate,
}) => {
  const isFarmReport = !!farm;
  const isDateProvided = dateFrom || dateTo;

  const finalFiltersOptions = useMemo(() => {
    const temp = defaultFiltersOptions
      .filter((f) => (isFarmReport ? true : f.id !== "area"))
      .filter((f) => !excludeFilters.includes(f.id));
    if (isFarmReport)
      temp.push({
        name: "Area",
        type: "AreaMultiSelectFilter",
        id: "tagArea",
        farmId: farm.id,
      });

    return temp;
  }, [isFarmReport, isDateProvided, farm]);

  const rawFilters = getFiltersFromLocations(finalFiltersOptions, location);
  let { tagDateFrom, tagDateTo } = rawFilters;
  dateFrom = isDateProvided ? dateFrom : tagDateFrom;
  dateTo = isDateProvided ? dateTo : tagDateTo;

  const data = useMemo(() => {
    const uniqueDates = new Set(
      content.map((tag) => tag.createdAt.substr(0, 10))
    );
    const dates = {};
    uniqueDates.forEach((date) => {
      dates[formatDate(date)] = content.filter(
        (tag) => tag.createdAt.substr(0, 10) === date
      );
    });
    return dates;
  }, [content]);

  const dataForExport = content.map((tag) => ({
    farmName: tag.area.farm.name,
    areaName: tag.area.name,
    createdAt: tag.createdAt.replace(/T/gi, " ").substr(0, 16),
    rowNumber: getRowName(tag),
    id: tag.rfidTagId,
  }));

  const print = () => {
    const newWindow = window.open();
    newWindow.document.title = `Tag Scanning Report`;
    copyStyles(window.document, newWindow.document);

    newWindow.document.body.innerHTML = ReactDOMServer.renderToString(
      <PrintReport
        title={"Tag Scanning Report"}
        columnsConfig={columnsConfig}
        data={dataForExport}
        areaName={isFarmReport ? farm.name : area.name}
        date={dateFrom}
        endDate={dateTo}
        showTimePeriod={false}
      />
    );
    newWindow.print();
  };

  useEffect(() => {
    const filters = {};
    if (isFarmReport && rawFilters.tagArea) filters.area = rawFilters.tagArea;
    if (isFarmReport) filters.farmId = farm.id;

    dateFrom = dateFrom ? `${dateFrom}T00:00:00` : null;
    dateTo = dateTo ? `${dateTo}T23:59:59` : null;

    fetchScannedTagBarcodes({
      unpaged: true,
      filters: {
        ...filters,
        ...(isFarmReport ? {} : { area: area.id }),
      },
      to: dateTo,
      from: dateFrom,
    });
  }, [location, fetchScannedTagBarcodes, farm]);

  return (
    <Container className={styles.wrapper}>
      <div style={{ height: 10 }} />
      <Grid.Row className={classNames(styles.justifyContentBetween)}>
        <Header as="h2" className={styles.noMargin}>
          Tag Scanning Report
        </Header>
        <div>
          <ExportToExcel
            rawData={dataForExport}
            columns={columnsConfig}
            name={"Tag Scanning Report"}
            className={styles.mr10}
          />
          <Button style={{ marginLeft: 20 }} onClick={print}>
            <Icon name="print" />
            <span>Print</span>
          </Button>
        </div>
      </Grid.Row>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} computer={10} largeScreen={10}>
            <Filters
              navigate={navigate}
              options={finalFiltersOptions}
              location={location}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <BreakdownTable data={data} hasAreaColumn={isFarmReport} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

ScannedTagBarcodes.propTypes = {
  actions: PropTypes.object,
  area: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    farm: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  }),
  content: PropTypes.any,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  excludeFilters: PropTypes.array,
  farm: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  isFetching: PropTypes.bool,
  location: PropTypes.object,
  online: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchScannedTagBarcodes,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => {
  const {
    router: { location },
    tagBarcodes: {
      isFetching,
      data: { content },
    },
  } = state;

  return {
    location,
    isFetching,
    content,
    online: onlineSelector(state),
  };
};

ScannedTagBarcodes.defaultProps = {
  excludeFilters: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(ScannedTagBarcodes);
