import PropTypes from "prop-types";
import React, { useState } from "react";
import { Field, Formik, useFormikContext } from "formik";
import styles from "../NewCost.module.css";
import { Radio } from "semantic-ui-react";
import DateRange from "./DateRange";
import DatePicker from "components/DatePicker/DatePicker";
import moment from "moment";

const DateSelector = ({
  setDateDropdownChecker,
  onChangeDate,
  onRadioButtonChange,
  index,
  costData,
  checker,
  setChecker
}) => {
  const newValue = {
    start:
      costData[index].startAt && checker != "applied"
        ? new Date(moment(costData[index].startAt).format("MM/DD/YYYY"))
        : "",
    end:
      costData[index].endAt && checker != "applied"
        ? new Date(
            moment(costData[index].endAt)
              .add(-1, "day")
              .format("MM/DD/YYYY")
          )
        : ""
  };
  const { values, setValues } = useFormikContext();

  return (
    <div className={styles.selectorBody}>
      <div className={styles.selectorDatePickerContainer}>
        <div className={styles.radioButtonContainer}>
          <Radio
            label="Applied date"
            name="radioGroup"
            value="applied"
            checked={checker === "applied"}
            onChange={() => {
              return (
                onRadioButtonChange(),
                setValues({
                  ...values,
                  endAt: ""
                }),
                setChecker("applied")
              );
            }}
          />
        </div>
        <label
          className={
            checker === "applied"
              ? styles.dateRangeLabel
              : styles.dateRangeLabelDisabled
          }
        >
          From
        </label>
        <DatePicker
          setDateDropdownChecker={setDateDropdownChecker}
          placeholder="dd/mm/yyyy"
          value={checker == "applied" && costData[index].startAt}
          disabled={checker !== "applied"}
          onChange={value => {
            return value && onChangeDate(value, false);
          }}
        />
      </div>
      <div className={styles.selectorDateRangeContainer}>
        <div className={styles.radioButtonContainer}>
          <Radio
            label="Date range"
            name="radioGroup"
            value="range"
            checked={checker === "range"}
            onChange={() => {
              return (
                onRadioButtonChange(),
                setValues({
                  ...values,
                  date: ""
                }),
                setChecker("range")
              );
            }}
          />
        </div>

        <DateRange
          name="dateRange"
          value={newValue}
          checker={checker}
          onChange={value => {
            return onChangeDate(value, true);
          }}
        />
      </div>
    </div>
  );
};

DateSelector.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
};

DateSelector.defaultProps = {
  disabled: false
};

export default DateSelector;
