import React, { useEffect } from "react";
import { Dropdown, Header, Icon } from "semantic-ui-react";
import Radio from "../Radio";
import { connect, useDispatch, useSelector } from "react-redux";
import { map } from "lodash";
import styles from "../Filters.module.css";

const SingleSelect = ({
  filterChange = () => {},
  data,
  id,
  filterId,
  text,
  selector,
  loaderAction,
  className,
  value,
  onChange,
  addedFilter,
  setSelectedFilter,
  withAsyncSearch,
  filter,
  selectedFilter,
  showOptions,
  loading,
  disableChecker
}) => {
  const { isFetching, options } = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isFetching === false &&
      !options &&
      typeof loaderAction === "function"
    ) {
      dispatch(loaderAction());
    }
  }, [loaderAction]);

  const defaultSeason = data?.reduce(function(prev, current) {
    return prev.startAt > current.startAt ? prev : current;
  }, {});
  const defaultDate = [defaultSeason?.startAt, defaultSeason?.endAt];
  useEffect(() => {
    text === "Season" &&
      defaultSeason?.startAt &&
      defaultSeason?.endAt &&
      onChange("seasons", defaultDate);
  }, []);

  return (
    <Dropdown
      placeholder=""
      disabled={/* loading || */ disableChecker === "date" && text === "Season"}
      text={text}
      icon={
        addedFilter ? (
          <div className={styles.filterDropDownIcons}>
            <Icon
              size="small"
              name="close"
              onClick={() => {
                return (
                  onChange(filterId, null),
                  setSelectedFilter(
                    selectedFilter.filter(
                      item =>
                        showOptions.some(options => item != options.filterId) &&
                        item != filter.filterId
                    )
                  )
                );
              }}
            />
          </div>
        ) : (
          <div className={styles.filterDropDownIcons}>
            <Icon name={"angle down"} />
          </div>
        )
      }
      loading={isFetching}
      className={className}
      selectOnBlur={false}
      onChange={(_, { value }) => onChange(filterId, value)}
    >
      <Dropdown.Menu className={styles.filterMenu}>
        <Dropdown.Menu scrolling>
          {options.length ? (
            map(options, option => (
              <Dropdown.Item
                key={option.value}
                value={option?.value || defaultDate}
                text={option.label}
                content={
                  <Radio
                    label={option.label}
                    checked={
                      JSON.stringify(value) === JSON.stringify(option.value)
                    }
                  />
                }
                onClick={(_, { value }) => (
                  onChange(filterId, value), filterChange(filterId, value)
                )}
              />
            ))
          ) : (
            <Dropdown.Item text={"Nothing was found"} />
          )}
        </Dropdown.Menu>
        {value != null && value != undefined && (
          <>
            <Dropdown.Divider />
            <Header
              className={styles.clearButton}
              onClick={e => {
                e.stopPropagation();
                filterChange(filterId, null);
                onChange(filterId, null);
              }}
            >
              Clear selection
            </Header>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default connect((state, props) => {
  return {
    data: state.settings.data.seasons
  };
})(SingleSelect);
