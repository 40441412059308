import React from "react";
import { InfoWindowF } from "@react-google-maps/api";
import PropTypes from "prop-types";
import styles from "./RouteGoogleMaps.module.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formatInUTC } from "../../utils/timezoneUtils";

const DetailPopup = ({ coordinate }) => {
  return (
    <InfoWindowF
      position={{
        lat: coordinate.position.coordinates[1],
        lng: coordinate.position.coordinates[0]
      }}
      options={{ disableAutoPan: true }}
    >
      <div className={styles.infoHolder}>
        <div>{formatInUTC(coordinate.date)}</div>
        <div>Alt: {coordinate.position.alt}</div>
        <div>Angle: {coordinate.position.angle}</div>
        <div>Speed: {coordinate.position.speed}</div>
        <div>Satellites: {coordinate.position.satellites}</div>
      </div>
    </InfoWindowF>
  );
};

DetailPopup.propTypes = {
  coordinate: PropTypes.object
};

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({}, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailPopup);
