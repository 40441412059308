import moment from "moment";
import { axiosProxy } from "utils/axiosProxy";
import { actionTypes } from "constants/Reports/sheds";
import { omitBy, isNil } from "lodash";

import uuid from "uuid/v4";

let uniqId;
const url = "/reporting_module/inventory";

export const fetchNutrientBreakdownTableData = ({
  page,
  size,
  search,
  unpaged = false,
  filters
}) => async dispatch => {
  try {
    dispatch({ type: actionTypes.NUTRIENT_BREAKDOWN_TABLE_DATA_START });
    const paramsObject = omitBy(
      {
        search,
        page,
        unpaged,
        size
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `${url}/nutrientBreakdown`
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      dispatch({
        type: actionTypes.NUTRIENT_BREAKDOWN_TABLE_DATA,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: actionTypes.NUTRIENT_BREAKDOWN_TABLE_DATA_FAILED,
      error
    });
  }
};

export const fetchNutrientBreakdownTableDataList = ({
  unpaged = true,
  filters
}) => async dispatch => {
  try {
    dispatch({
      type: actionTypes.NUTRIENT_BREAKDOWN_TABLE_DATA_LIST_START
    });
    const paramsObject = omitBy(
      {
        unpaged: true
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    const res = await axiosProxy({
      url: `${url}/nutrientBreakdown`,
      params,
      method: "GET"
    });

    dispatch({
      type: actionTypes.NUTRIENT_BREAKDOWN_TABLE_DATA_LIST,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionTypes.NUTRIENT_BREAKDOWN_TABLE_DATA_LIST_FAILED,
      payload: error
    });
  }
};
