import React from "react";
import { Table } from "semantic-ui-react";
import { useTable } from "react-table";
import "./TableComponent.css";
import moment from "moment";

const TableComponent = ({
  data,
  tableColumns,
  sortValue = "employee",
  sortOrder = "asc",
  searchQuery = "",
}) => {
  const columns = React.useMemo(() => tableColumns, []);
  const dynamicSort = (field, order) => {
    return (a, b) => {
      if (field === "date") {
        const dateA = moment(a[field], "DD/MM/YYYY");
        const dateB = moment(b[field], "DD/MM/YYYY");
        return order === "asc" ? dateA.diff(dateB) : dateB.diff(dateA);
      } else {
        if (a[field] < b[field]) return order === "asc" ? -1 : 1;
        if (a[field] > b[field]) return order === "asc" ? 1 : -1;
        return 0;
      }
    };
  };

  const filteredData = data
    .map(({ date, count, employee }) => ({
      date: moment(date).format("DD/MM/YYYY"),
      boxes: count,
      employee: `${employee.firstName} ${employee.lastName}`,
    }))
    .filter(({ employee }) =>
      employee.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort(dynamicSort(sortValue, sortOrder));

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: filteredData });

  return (
    <div className="table-container">
      <Table celled {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
          <Table.Row className="total-row">
            <Table.HeaderCell>Total</Table.HeaderCell>
            <Table.HeaderCell>{filteredData.length} emploees</Table.HeaderCell>
            <Table.HeaderCell>
              {filteredData.reduce((prev, curr) => prev + curr.boxes, 0)} boxes
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {!!rows.length &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Table.Cell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </Table.Cell>
                  ))}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default TableComponent;
