import React, { useEffect, useState } from "react";
import { Modal, Button, Confirm, Icon, Form } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import styles from "./ClockOut.module.css";
import ClockoutForm from "./component/Form";
import EmployeeCard from "./component/EmployeeCard";
import {
  fetchGeneralTask,
  updateClockOut,
  updateWorkingTime
} from "actions/WorkingTime";

const ClockOut = ({
  params,
  workingTimeCompleted,
  open,
  onClose,
  setSideBarOpen,
  activeItem,
  subRowsIndex,
  setSubRowsIndex,
  forWtAdd,
  setForWtAdd,
  pageActiveTab
}) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [diffHours, setDiffHours] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeItem) {
      setInitialValues({
        children: activeItem.items.map(item => ({
          areas: item.areas,
          generalTaskId: item.generalTaskId,
          id: item.id,
          startAt: item.startAt,
          endAt: item.endAt,
          lunchInterval: item.lunchInterval || 0,
          pieceRate: item.pieceRate,
          piecesPerEmployee: item.piecesPerEmployee,
          comment: item.comment,
          employeeId: item.employeeId
        }))
      });
    }
  }, [activeItem]);

  const handleSave = data => {
    const changedItems = data.filter((item, index) => {
      const initialItem = initialValues.children[index];
      return JSON.stringify(item) !== JSON.stringify(initialItem);
    });
    const newData = changedItems?.map(i => ({
      areas: i.areas,
      comment: i.comment,
      endAt: i.endAt,
      id: i.id,
      lunchInterval: i.lunchInterval,
      pieceRate: i.pieceRate,
      piecesPerEmployee: i.piecesPerEmployee,
      startAt: i.startAt
    }));

    return (
      !forWtAdd
        ? Promise.all(
            changedItems.map(item => dispatch(updateWorkingTime(item)))
          ).then(() => onClose())
        : dispatch(updateClockOut(newData))
            .then(() => {
              dispatch(
                fetchGeneralTask(params, pageActiveTab, workingTimeCompleted)
              );
            })
            .catch(error => {}),
      onClose()
    );
  };
  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
    >
      <Modal.Header className={styles.headerWrapper}>
        <div className={styles.header}>Clock Out</div>
        <div className={styles.icon}>
          <Icon name="close" onClick={() => setSecondOpen(true)} />
        </div>
      </Modal.Header>
      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={
          activeItem
            ? {
                children: activeItem.items.map(item => ({
                  areas: item.areas,
                  generalTaskId: item.generalTaskId,
                  id: item.id,
                  startAt: item.startAt,
                  endAt: item.endAt,
                  lunchInterval: item.lunchInterval || 0,
                  pieceRate: item.pieceRate,
                  piecesPerEmployee: item.piecesPerEmployee,
                  comment: item.comment,
                  employeeId: item.employeeId
                }))
              }
            : {
                children: [
                  {
                    areas: [],
                    startAt: "",
                    endAt: "",
                    lunchInterval: 0,
                    pieceRate: "",
                    piecesPerEmployee: "",
                    comment: ""
                  }
                ],
                notes: ""
              }
        }
      >
        {({ values, setFieldValue, setValues, dirty, submitForm, isValid }) => {
          const validation = values.children.every(
            item =>
              item.areas.length &&
              item.startAt &&
              item.endAt &&
              item.lunchInterval !== ""
          );
          const validationForAdd = values.children.some(
            item =>
              item.areas.length &&
              item.startAt &&
              item.endAt &&
              item.lunchInterval !== ""
          );

          return (
            <Form>
              <Modal.Content scrolling className={styles.modalContentContainer}>
                <ClockoutForm
                  activeItem={activeItem}
                  values={values}
                  setFieldValue={setFieldValue}
                />
                {values?.children.map((item, index) => {
                  return (
                    <EmployeeCard
                      setDiffHours={setDiffHours}
                      subRowsIndex={subRowsIndex}
                      setSubRowsIndex={setSubRowsIndex}
                      activeItem={item}
                      index={index}
                      values={values}
                      setFieldValue={setFieldValue}
                      setValues={setValues}
                    />
                  );
                })}
              </Modal.Content>
              <Modal.Actions className={styles.actionButtons}>
                <Button
                  content="Cancel"
                  color="green"
                  basic
                  onClick={() => {
                    return (
                      dirty ? setSecondOpen(true) : onClose(),
                      !dirty && (setSideBarOpen(false), setForWtAdd(false))
                    );
                  }}
                />
                <Button
                  content="Save"
                  color="green"
                  onClick={() => handleSave(values.children)}
                  disabled={
                    (forWtAdd ? !validationForAdd : !validation) ||
                    !dirty ||
                    diffHours > 12 ||
                    diffHours == 0
                  }
                />
              </Modal.Actions>
            </Form>
          );
        }}
      </Formik>
      <Confirm
        className={styles.confirmModal}
        content="Are you sure you want to close modal window"
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={() => {
          setForWtAdd(false);
          onClose();
          setSideBarOpen(false);
          setSecondOpen(false);
        }}
      />
    </Modal>
  );
};

export default ClockOut;
