import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchEmployees } from "../../actions/Employee/employees";
import SelectWithSelectAll from "../SelectWithSelectAll";

const SelectEmployee = props => {
  const {
    actions: { fetchEmployees },
    employeesList,
    archivedList,
    isFetching,
    validation,
    showLaborGroup,
    compareLaborGroupID,
    showArchived,
    value,
    ...extraProps
  } = props;
  const filterArchived = data =>
    showArchived || !data.hasOwnProperty("archived")
      ? true
      : data.archived === false;
  const archivedEmployee = archivedList.find(item => item.id === value);

  const options = useMemo(
    () =>
      (archivedEmployee
        ? [archivedEmployee, ...employeesList.filter(filterArchived)]
        : employeesList.filter(filterArchived)
      ).map(content => ({
        key: content.id,
        content: `${content.firstName} ${content.lastName} ${
          showLaborGroup &&
          content.groupId &&
          content.groupId !== compareLaborGroupID
            ? "- In another group"
            : ""
        }`,
        text: `${content.firstName} ${content.lastName}`,
        disabled: showLaborGroup
          ? content.groupId && content.groupId !== compareLaborGroupID
          : false,
        value: content.id
      })),
    [compareLaborGroupID, employeesList, showLaborGroup]
  );
  return (
    <SelectWithSelectAll
      placeholder="Select employee"
      noResultsMessage="Nothing was found"
      fluid
      {...extraProps}
      value={value}
      error={!validation}
      closeOnBlur
      search
      loading={isFetching}
      selection
      options={options}
    />
  );
};

SelectEmployee.propTypes = {
  actions: PropTypes.object.isRequired,
  compareLaborGroupID: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  employeesList: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  showArchived: PropTypes.bool,
  showLaborGroup: PropTypes.bool,
  validation: PropTypes.bool.isRequired,
  value: PropTypes.array.isRequired
};

SelectEmployee.defaultProps = {
  disabled: false,
  employeesList: [],
  isFetching: false,
  onChange: () => {},
  showArchived: false,
  validation: true,
  value: []
};

const mapStateToProps = ({
  employee: {
    list: { content },
    archivedList,
    isFetching
  }
}) => {
  return {
    employeesList: content,
    archivedList: archivedList.content,
    isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchEmployees
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectEmployee);
