import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import styles from "./SingleSelectFilter.module.css";
import filterStyles from "../../Filters.module.css";

import {
  getHashParameter,
  setHashParameter,
} from "../../../../utils/hashToObject";
import PropTypes from "prop-types";
import Loader from "../../../Loader";
import { useNavigate } from "react-router-dom";

const SingleSelectFilter = ({
  id,
  name,
  disabled,
  numbers,
  options,
  loading,
  location,
  disableChecker,
  list,
  onFilterClick = () => {},
}) => {
  const navigate = useNavigate();
  const ref = useRef();
  const [listNode, setListNode] = useState(null);

  const value = useMemo(() => {
    const param = getHashParameter(location, id);

    if (!param || param === "*") {
      return [];
    }

    return param.split(",").map((param) => (numbers ? parseInt(param) : param));
  }, [id, location, numbers]);

  const text = useMemo(
    () =>
      `${name}: 
       ${
         value.length === 0
           ? "All"
           : Array.isArray(value)
           ? options
               ?.filter(
                 (item) => JSON.stringify(value) === JSON.stringify(item.value)
               )
               .map((item) => item.text)
               .join(",  ")
           : options
               ?.filter((item) => value.includes(item.value))
               .map((item) => item.text)
               .join(",  ")
       }`,
    [name, options, value]
  );

  const defaultSeason = list.seasons?.reduce(function(prev, current) {
    return prev.startAt > current.startAt ? prev : current;
  }, {});
  const defaultDate = [defaultSeason?.startAt, defaultSeason?.endAt];

  const onOpen = useCallback(() => {
    setTimeout(() => {
      // eslint-disable-next-line
      const listNode = ref.current && ReactDOM.findDOMNode(ref.current);
      setListNode(listNode);
      if (!listNode) {
        return;
      }

      if (listNode.querySelector("input")) {
        listNode.querySelector("input").focus();
      }
    });
  }, [ref, setListNode]);

  const handleBlur = useCallback(
    (event) => {
      // eslint-disable-next-line
      if (listNode && !ReactDOM.findDOMNode(listNode).contains(event.target)) {
        if (listNode && listNode.classList.contains("visible")) {
          listNode.click();
        }
      }
    },
    [listNode]
  );

  useEffect(() => {
    document.body.addEventListener("mousedown", handleBlur);
    return () => document.body.removeEventListener("mousedown", handleBlur);
  }, [handleBlur]);

  const dropdownValue = value
    ? Array.isArray(value)
      ? value[1]
      : value
    : null;

  return (
    <Dropdown
      filter={id}
      name={name}
      labeled
      button
      floating
      text={text}
      closeOnChange={false}
      closeOnBlur={false}
      icon={null}
      disabled={
        /*  disabled || */ disableChecker === "date" && text.includes("Season")
      }
      onOpen={onOpen}
      ref={ref}
    >
      <Dropdown.Menu
        className={`dropdownMenu ${filterStyles.dropdownMenu} ${filterStyles.selectItemsHolder}`}
      >
        <Dropdown
          fluid
          search
          options={options}
          className={filterStyles.selectItems}
          onChange={(_, data) => {
            return (
              onFilterClick(true),
              setHashParameter(location, id, data.value, null, navigate)
            );
          }}
          defaultOpen
          value={dropdownValue || defaultDate}
          noResultsMessage={
            loading && options.length === 0 ? (
              <div className={styles.loadingPlaceHolder}>
                <Loader className={styles.loader} />
                Loading...
              </div>
            ) : (
              "Nothing was found"
            )
          }
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

SingleSelectFilter.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  numbers: PropTypes.any,
  options: PropTypes.array.isRequired,
  location: PropTypes.object,
};

SingleSelectFilter.defaultProps = {
  disabled: false,
  loading: false,
};

function mapStateToProps(state) {
  const {
    settings: { data },
    router: { location },
  } = state;
  return {
    list: data,
    location,
  };
}

export default connect(mapStateToProps)(SingleSelectFilter);
