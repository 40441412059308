import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../components/Layout";
import { Container, Header, Icon, Segment } from "semantic-ui-react";
import styles from "./Reports.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportsTypes } from "../../actions/Reports/reports";
import { makeTree } from "../../utils/trees";
import Loader from "../../components/Loader";
import ReportForm from "./ReportForm";
import { Link } from "react-router-dom";
import { userFERolesSelector } from "selectors/user";

const links = {
  2: "/reports/farm_assets/planting_report",
  5: "/reports/applications_record_by_block",
  25: "/reports/nutrient_breakdown",
  26: "/reports/product_usage",
  10: "/reports/harvest/pick_to_pack/picker_report",
  11: "/reports/harvest/pick_to_pack/harvest_by_area",
  12: "/reports/harvest/pick_to_pack/harvest_by_variety",
  13: "/harvest/packed_boxes/reports/custom",
  14: "/reports/harvest/field_scanning/harvest_by_area",
  15: "/reports/harvest/field_scanning/harvest_by_variety",
  16: "/harvest/packed_boxes/reports/by_general_barcodes",
  17: "/harvest/scanned_general_barcodes",
  20: "/reports/total_cost_per_farm",
  21: "/reports/total_cost_per_area",
  23: "/reports/hourly_payroll",
  24: "/reports/piece_rate_payroll",
  27: "/reports/harvest/field_scanning/picker_report",
  28: "/reports/harvest/pick_to_pack/contractor_report",
  29: "/reports/harvest/field_scanning/contractor_report",
  30: "/reports/product_type_usage"
};

const Reports = ({ route, location }) => {
  const dispatch = useDispatch();
  const { data: content, isFetching } = useSelector(
    state => state.reports.reportsReducerNeedToRefactor
  );
  const userRoles = useSelector(userFERolesSelector);
  const tree = useMemo(() => makeTree(content), [content]);
  const [currentNode, setNode] = useState(
    (location.state && location.state.activeNode) || { children: [] }
  );

  useEffect(() => {
    dispatch(fetchReportsTypes());
  }, []);

  useEffect(() => {
    if (tree.children.length)
      setNode(currentNode.children.length > 0 ? currentNode : tree);
  }, [tree]);

  const isRootNode = currentNode?.parent === currentNode;

  return (
    <Layout route={route} location={location} childrenOnly>
      <Container className={styles.container}>
        <Header className={styles.header} as={"h2"}>
          {!isRootNode && (
            <span
              className={styles.backIconWrapper}
              onClick={() => setNode(currentNode?.parent)}
            >
              <Icon name="angle left" className={styles.backIcon} />
            </span>
          )}
          {currentNode.name ? currentNode.name : "Choose report type"}
        </Header>
        {isFetching && <Loader />}
        {!isFetching && !currentNode.isReport && (
          <Segment className={styles.reportTypesHolder}>
            {currentNode.children.map(node => {
              if (
                node.requiredPermissions.every(rp => userRoles.includes(rp))
              ) {
                if (links.hasOwnProperty(node.id))
                  return (
                    <Link
                      to={{
                        pathname: links[node.id]
                      }}
                      state={{ reportNode: 1 }}
                      key={`spray_task_type_${node.id}`}
                      className={styles.card}
                    >
                      <img src={node.logo.presignedUrl} alt="" />
                      <span className={styles.caption}>{node.name}</span>
                    </Link>
                  );

                return (
                  <div
                    onClick={() => setNode(node)}
                    key={`spray_task_type_${node.id}`}
                    className={styles.card}
                  >
                    <img src={node.logo.presignedUrl} alt="" />
                    <span className={styles.caption}>{node.name}</span>
                  </div>
                );
              }
            })}
            {currentNode.children.length === 0 && (
              <span>We will add the reports under this section soon...</span>
            )}
          </Segment>
        )}
        {!isFetching && currentNode.isReport && (
          <Segment>
            <ReportForm typeFullName={currentNode.name} />
          </Segment>
        )}
      </Container>
    </Layout>
  );
};

Reports.propTypes = {
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
};

export default Reports;
