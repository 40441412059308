import React from "react";
import { CallbackComponent } from "redux-oidc";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

function AuthCallBack({ userManager }) {
  const navigate = useNavigate();

  const checkBackUrl = () => {
    const backUrl = localStorage.getItem("backUrl");
    localStorage.removeItem("backUrl");
    navigate(backUrl || "/", { replace: true });
  };

  const onError = () => {
    navigate("/", { replace: true });
  };

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={checkBackUrl}
      errorCallback={onError}
    >
      <div />
    </CallbackComponent>
  );
}

AuthCallBack.propTypes = {
  userManager: PropTypes.any
};

AuthCallBack.defaultProps = {
  route: { name: "{%name%}" }
};

export default AuthCallBack;
