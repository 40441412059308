import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import Loader from "../../../../components/Loader";
import { abilitiesSelector } from "../../../../selectors/user";
import { getParentPageHref } from "../../../../utils/routeHelpers";
import LayoutOverlay from "../../../../components/LayoutOverlay";
// eslint-disable-next-line
import styles from "./AddVendor.module.css";
import { getVendorById } from "../../../../actions/Vendors/vendors";
import VendorsForm from "./VendorForm/VendorForm";

class AddVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      Vendor: null,
    };
    this.VendorForm = React.createRef();
  }
  async componentWillMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.getVendor(id);
    } else {
      this.setState({
        isFetching: false,
      });
    }
  }

  getVendor = (id) => {
    const {
      actions: { getVendorById },
    } = this.props;
    this.setState({
      isFetching: true,
    });

    getVendorById(id)
      .then((Vendor) => {
        this.setState({
          isFetching: false,
          Vendor,
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.props.navigate("/404");
        }
        this.setState({
          isFetching: false,
        });
      });
  };

  async componentDidUpdate(prevProps, prevState) {
    const { match } = this.props;
    const { id } = match.params;

    if (id && id !== prevProps.match.params.id) {
      this.getVendor(id);
    }
  }

  doSubmitForm = () => {
    if (this.VendorForm.current) {
      this.VendorForm.current.submit();
    }
  };

  render() {
    const { Vendor, isFetching } = this.state;
    const { route, match, Can, navigate } = this.props;
    const { id } = match.params;
    return (
      <LayoutOverlay
        closeLink={getParentPageHref(route)}
        route={{
          ...route,
          name: id ? route.name.replace("Add", "Edit") : route.name,
        }}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            {Vendor && Vendor.id ? (
              <Can I="update" a="vendors">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            ) : (
              <Can I="add" a="vendors">
                <Button primary size="large" onClick={this.doSubmitForm}>
                  Save
                </Button>
              </Can>
            )}
          </div>
        }
      >
        {isFetching ? (
          <Loader />
        ) : (
          <VendorsForm
            navigate={navigate}
            route={route}
            initialValues={Vendor}
            formRef={this.VendorForm}
          />
        )}
      </LayoutOverlay>
    );
  }
}

AddVendor.propTypes = {
  route: PropTypes.object.isRequired,
  Vendor: PropTypes.object,
  match: PropTypes.object,
  actions: PropTypes.object,
  Can: PropTypes.func,
};

AddVendor.defaultProps = {
  route: { name: "{%name%}" },
};

const mapStateToProps = (state) => {
  return {
    Can: abilitiesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ getVendorById }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
