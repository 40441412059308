import React from "react";
import PropTypes from "prop-types";
import { Table, Button } from "semantic-ui-react";
import { useSelector, connect } from "react-redux";
import { employeeList } from "selectors/employee";
import styles from "./Workers.module.css";
import Loader from "components/Loader";

const Workers = ({
  activeRounds,
  setAddCheck,
  isFetching,
  isFetchingBarcode
}) => {
  const employees = useSelector(employeeList);
  return isFetching || isFetchingBarcode ? (
    <Loader />
  ) : (
    <>
      {!!activeRounds?.employeeResults?.length ? (
        <Table className={styles.workersTable} definition fixed>
          <Table.Body>
            {activeRounds?.employeeResults?.map(item => {
              return (
                <>
                  <Table.Row>
                    <Table.Cell>
                      {employees.find(i => i.key === item.employeeId)?.text}
                    </Table.Cell>
                    <Table.Cell>{item.rate}/5</Table.Cell>
                  </Table.Row>
                </>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <div className={styles.nothingResultMessage}>Nothing to show</div>
      )}
      <div className={styles.addBtnWrapper}>
        <Button
          className={styles.addBtn}
          content="+ Add a worker"
          color="green"
          onClick={() => {
            setAddCheck(true);
          }}
        />
      </div>
    </>
  );
};

Workers.propTypes = {
  activeRounds: PropTypes.object
};

export default connect((state, props) => {
  return {
    isFetching: state.qualityControl.data.isFetching,
    isFetchingBarcode: state.generalBarcodes.isFetching
  };
})(Workers);
