import React from "react";
import BasicCell from "components/Table/components/BasicCell";
import { Button, Icon, Popup } from "semantic-ui-react";
import style from "../routes/Harvest/PackedBoxes/PackedBoxes.module.css";
import { Link } from "react-router-dom";
const renderBatteryCell = (props) => {
  const {
    value,
    column: { Header },
  } = props;
  const percentage = (value - 6000) / 600;
  let icon = "battery full";

  if (percentage < 0.1) {
    icon = "battery empty red";
  } else if (percentage < 0.3) {
    icon = "battery quarter yellow";
  } else if (percentage < 0.6) {
    icon = "battery half";
  } else if (percentage < 0.85) {
    icon = "battery three quarters";
  }

  return (
    <div>
      <div className="cellTitle hide-md">{Header}</div>
      <div className="cellValue">
        <Icon className={icon} /> {value}
      </div>
    </div>
  );
};
export const getWeatherStationTableColumns = (
  isFetching,
  navigate,
  setOpenModal,
  setActiveId
) => {
  const columnsConfig = {
    stationId: {
      withSort: true,
      type: "string",
      id: "stationId",
      title: "Station Id",
      accessor: ({ stationId }) => stationId || "-",
      withSort: true,
      cellWidth: 4,
    },
    displayName: {
      withSort: true,
      type: "string",
      id: "displayName",
      title: "Name",
      accessor: ({ displayName }) => displayName || "-",
      withSort: true,
      cellWidth: 4,
    },
    battery: {
      withSort: true,
      type: "string",
      id: "battery",
      title: "Battery",
      accessor: (d) =>
        d.extraData && d.extraData.metadata && d.extraData.metadata.lastBattery,
      Cell: (d) => renderBatteryCell(d),
      withSort: false,
      cellWidth: 5,
    },
    btnWrapper: {
      withSort: false,
      type: "string",
      id: "btnWrapper",
      title: "",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ id }) => {
        return (
          <div className={style.wrapper}>
            <div className={style.iconWrapper}>
              <Popup
                inverted
                position="bottom center"
                content={"Edit"}
                size="tiny"
                trigger={
                  <div className={style.editButtonWrapper}>
                    <Button
                      as={Link}
                      to={`/weather_stations/add_new_weather_station/${id}`}
                      onClick={(event) => {
                        return (
                          // navigate(``),
                          event.stopPropagation()
                        );
                      }}
                      className={style.editButton}
                      floated="right"
                      icon={<Icon name="edit" />}
                      size="small"
                    />
                  </div>
                }
              />
              <Popup
                inverted
                position="bottom center"
                content={"Delete"}
                size="tiny"
                trigger={
                  <Button
                    onClick={(event) => {
                      return (
                        setOpenModal(true),
                        setActiveId(id),
                        event.stopPropagation()
                      );
                    }}
                    className={style.deleteButton}
                    floated="right"
                    icon={<Icon name="trash alternate" />}
                    size="small"
                  />
                }
              />
            </div>
          </div>
        );
      },
      withSort: true,
    },
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {}),
  }));
};
