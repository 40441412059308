import React, { useState } from "react";
import { Segment, Grid, Icon, Radio } from "semantic-ui-react";

import { employeeViewer } from "routes/Tasks/components/helper";
import SecondStepForm from "./StepTwoForm";

import styles from "./CompletionModal.module.css";

const DetailsSection = ({
  stepData,
  isDetailedCompletion,
  filtredArea,
  employeeList,
  machineryList,
  errors,
  handleFieldChange
}) => {
  const [expandedRows, setExpandedRows] = useState({});

  const generalCommonErrors = errors?.general?.common;

  return (
    <div className={styles.stepTwoContainer}>
      <SecondStepForm
        wtData={stepData.general}
        disabled={isDetailedCompletion}
        errors={generalCommonErrors && Object.values(generalCommonErrors)}
        handleOnChange={(key, value) => {
          handleFieldChange(`step2.general.${key}`, value);
        }}
      />
      <div className={styles.detailsBar}>
        <div className={styles.progressBarHeader}>
          Specify the time for each area and / or employee
        </div>
        <Radio
          className={styles.checkBox}
          toggle
          defaultChecked={isDetailedCompletion}
          onChange={async () => {
            // set general data to each WT of area rows
            const areaRowsUpdated = stepData.areaRows.map(areaRow => ({
              ...areaRow,
              assigneeRows: areaRow.assigneeRows.map(assigneeRow => ({
                ...assigneeRow,
                workingTimes: [stepData.general]
              }))
            }));

            await handleFieldChange("step2.areaRows", areaRowsUpdated);
            handleFieldChange(
              "common.isDetailedCompletion",
              !isDetailedCompletion
            );
          }}
        />
      </div>
      {stepData.areaRows.map((areaRow, areaRowIndex, areaRowsArray) => (
        <div>
          <div className={styles.areaWrapper}>
            {filtredArea[areaRowIndex]?.name}
          </div>
          {areaRow.assigneeRows.map(
            (assigneeRow, assigneeRowIndex, assigneeRowsArray) => {
              const employee = employeeList.find(
                item => item.id === assigneeRow.assignee
              );
              const machinery = machineryList.find(
                item => item.id === assigneeRow.machinery
              );

              const isRowExpanded =
                expandedRows[`${areaRowIndex}-${assigneeRowIndex}`];

              return (
                <Segment className={styles.wrapper}>
                  <Grid.Row className={styles.wrapperRow}>
                    <div className={styles.iconContainer}>
                      <Grid.Column className={styles.chevronContainer}>
                        <div
                          disabled={!isDetailedCompletion}
                          onClick={() => {
                            if (isDetailedCompletion) {
                              const rowAccesser = `${areaRowIndex}-${assigneeRowIndex}`;

                              setExpandedRows(prevExpandedRows => ({
                                ...prevExpandedRows,
                                [rowAccesser]: !prevExpandedRows[rowAccesser]
                              }));
                            }
                          }}
                          className={`${styles.toggleIcon} ${
                            isRowExpanded ? styles.openTable : ""
                          }`}
                        >
                          <Icon
                            disabled={!isDetailedCompletion}
                            className={
                              isRowExpanded
                                ? "tuf-chevron-down"
                                : "tuf-chevron-right"
                            }
                          />
                        </div>
                      </Grid.Column>
                    </div>
                    <div className={styles.columnContainer}>
                      <Grid.Column
                        style={{ opacity: !isDetailedCompletion ? 0.45 : 1 }}
                        className={styles.nameColumn}
                      >
                        <label className={styles.productHeader}>
                          {employeeViewer(
                            [{ employeeId: assigneeRow.assignee }],
                            employeeList
                          )}
                          {`${employee?.firstName} ${employee?.lastName}`}
                        </label>
                      </Grid.Column>
                      <Grid.Column className={styles.nameColumn}>
                        <label className={styles.productHeader}>
                          {machinery?.name || "-"}
                        </label>
                      </Grid.Column>
                      <Grid.Column
                        style={{ opacity: !isDetailedCompletion ? 0.45 : 1 }}
                        className={styles.iconColumn}
                      >
                        <Icon
                          name="trash alternate"
                          className={styles.deleteBtn}
                          disabled={assigneeRowsArray.length < 2}
                          onClick={() => {
                            handleFieldChange(
                              `step2.areaRows`,
                              areaRowsArray.map(areaRow => ({
                                assigneeRows: areaRow.assigneeRows.filter(
                                  (_, _assigneeRowIndex) =>
                                    assigneeRowIndex !== _assigneeRowIndex
                                )
                              }))
                            );
                          }}
                        />
                      </Grid.Column>
                    </div>
                  </Grid.Row>
                  {isRowExpanded &&
                    assigneeRow.workingTimes.map((wt, wtIndex) => {
                      const errorsObj =
                        errors?.areaRows?.[areaRowIndex]?.assigneeRows?.[
                          assigneeRowIndex
                        ]?.workingTimes?.[wtIndex]?.common;
                      const commonErrors =
                        errorsObj && Object.values(errorsObj);

                      return (
                        <Grid.Row style={{ opacity: 1 }}>
                          <SecondStepForm
                            wtData={wt}
                            disabled={!isDetailedCompletion}
                            errors={commonErrors}
                            handleOnChange={(key, value) => {
                              handleFieldChange(
                                `step2.areaRows.${areaRowIndex}.assigneeRows.${assigneeRowIndex}.workingTimes.${wtIndex}.${key}`,
                                value
                              );
                            }}
                          />
                        </Grid.Row>
                      );
                    })}
                  {isRowExpanded && (
                    <div className={styles.wtAddBtnWrapper}>
                      <div
                        className={styles.wtAddBtn}
                        onClick={() => {
                          return handleFieldChange(
                            `step2.areaRows.${areaRowIndex}.assigneeRows.${assigneeRowIndex}.workingTimes`,
                            [
                              ...assigneeRow.workingTimes,
                              {
                                dateStart: null,
                                dateEnd: null,
                                timeStart: null,
                                timeEnd: null,
                                lunchInterval: null,
                                pieceRate: null,
                                piecesCount: null
                              }
                            ]
                          );
                        }}
                      >
                        + Add working time
                      </div>
                    </div>
                  )}
                </Segment>
              );
            }
          )}
        </div>
      ))}
    </div>
  );
};

export default DetailsSection;
