import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import { areaFilterOptions } from "selectors/areas";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import moment from "moment";
import { employeeOptionsSelector } from "selectors/employee";
import { contractorFilterOptions } from "selectors/generalTasks";
import { varietiesOptionSelector } from "selectors/areas";
import { boxUnitOptionsSelector } from "selectors/boxUnits";
import { Button, Icon, Popup } from "semantic-ui-react";
import style from "../routes/Harvest/PackedBoxes/PackedBoxes.module.css";
import { calcNotAllocatedPallets } from "routes/Harvest/Stock/Stock";
import { Link } from "react-router-dom";

export const getBoxUnitsTableColumns = (isFetching, navigate, onDelete) => {
  const columnsConfig = {
    displayName: {
      withSort: true,
      type: "string",
      id: "displayName",
      title: "Variety",
      cellWidth: 3, // of 16, see Semantic Table
      accessor: ({ displayName }) => displayName || "-",
      withSort: true,
    },
    pickingPrice: {
      withSort: true,
      type: "string",
      id: "pickingPrice",
      title: "Box suits",
      cellWidth: 3, // of 16, see Semantic Table
      accessor: ({ pickingPrice }) => pickingPrice || "-",
      withSort: true,
    },
    paddingPrice: {
      withSort: true,
      type: "string",
      id: "paddingPrice",
      title: "Units on pallet",
      cellWidth: 3, // of 16, see Semantic Table
      accessor: ({ paddingPrice }) => paddingPrice || "-",
      withSort: true,
    },

    cartingPrice: {
      withSort: true,
      type: "string",
      id: "cartingPrice",
      title: "Not allocated pallets",
      cellWidth: 3, // of 16, see Semantic Table
      accessor: ({ cartingPrice }) => cartingPrice || "-",
      withSort: true,
    },
    unitsPerPallet: {
      withSort: true,
      type: "string",
      id: "unitsPerPallet",
      title: "Units in Stock",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ unitsPerPallet }) => unitsPerPallet || "-",
      withSort: true,
    },
    btnWrapper: {
      withSort: false,
      type: "string",
      id: "btnWrapper",
      title: "",
      cellWidth: 1, // of 16, see Semantic Table
      accessor: ({ id }) => {
        return (
          <div className={style.wrapper}>
            <div className={style.iconWrapper}>
              <Popup
                inverted
                position="bottom center"
                content={"Edit"}
                size="tiny"
                trigger={
                  <div className={style.editButtonWrapper}>
                    <Button
                      as={Link}
                      to={`/harvest/box_units/add_box_units/${id}`}
                      onClick={(event) => {
                        return (
                          // navigate(``),
                          event.stopPropagation()
                        );
                      }}
                      className={style.editButton}
                      floated="right"
                      icon={<Icon name="edit" />}
                      size="small"
                    />
                  </div>
                }
              />
              <Popup
                inverted
                position="bottom center"
                content={"Delete"}
                size="tiny"
                trigger={
                  <Button
                    onClick={(event) => {
                      return onDelete(id), event.stopPropagation();
                    }}
                    className={style.deleteButton}
                    floated="right"
                    icon={<Icon name="trash alternate" />}
                    size="small"
                  />
                }
              />
            </div>
          </div>
        );
      },
      withSort: true,
    },
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {}),
  }));
};
