import { uniqBy } from "lodash";
import { createSelector } from "reselect";

const tableDataSelector = state => state.workingTimes.data;
const inProgressTableDataSelector = state => state.workingTimes.clockIn;
export const taskList = state => state.newTasks.taskIDs;
const workingTime = state => state.workingTimes;

export const formaterData = data => {
  const { content, ...rest } = data;

  const calculateWorkingTime = wt => {
    const start = new Date(wt.startAt);
    const end = new Date(wt.endAt);
    const diffInMs = end - start;
    const lunchIntervalInMs = (wt.lunchInterval || 0) * 60 * 1000;
    const diffInHours = (diffInMs - lunchIntervalInMs) / (1000 * 60 * 60);

    return diffInHours;
  };

  const contentUpdated = !!content.length
    ? content
        .map(wt => {
          const totalWorkingTime = wt.items.reduce((prev, item) => {
            const diffInHours = calculateWorkingTime(item);

            return prev + diffInHours;
          }, 0);

          return {
            id: wt.id,
            gtId: `GT-${wt.id}`,
            ...wt.items.reduce(
              (prev, curr, index) => {
                const diffInHours = calculateWorkingTime(curr);

                return {
                  ...prev,
                  taskName: wt.taskName,
                  type: wt.type,
                  approvedBy: "Approved by",
                  employees: wt.items.map(item => item.employeeId),
                  createdBy: uniqBy(
                    wt.items?.map(item => ({
                      employeeId: item.createdBy
                    })),
                    "employeeId"
                  ),
                  approved: wt.items.some(i => !i.approved) ? false : true,
                  totalWorkingTime,
                  buttonWrapper: wt.items.some(i => !i.approved) ? false : true,
                  wtId: wt.items.map(i => i.id),
                  rowInfo: "totalRow",
                  subRows: [
                    ...prev.subRows,
                    {
                      id: wt.id,
                      gtId: "",
                      taskName: "",
                      type: "",
                      createdBy: [{ employeeId: curr.createdBy }],
                      approved: curr.approved,
                      approvedBy: "Approved by",
                      employees: [curr.employeeId],
                      totalWorkingTime: diffInHours,
                      buttonWrapper: curr.approved,
                      wtId: [curr.id],
                      rowInfo: index
                    }
                  ]
                };
              },
              {
                binUnitsNumber: 0,
                weight: 0,
                tonnePerHa: 0,
                numberOfPlants: 0,
                cost: 0,
                costPerTonne: 0,
                costPerBin: 0,
                binUnits: [],
                avgKgPerTree: 0,
                varieties: [],
                crops: [],
                subRows: []
              }
            )
          };
        }, {})
        .map(({ subRows, withPatches, ...rest }) => ({
          ...rest,
          ...(withPatches
            ? {
                subRows: subRows.map(({ subRows, ...rest }) =>
                  subRows.length > 1 ? { ...rest, subRows } : rest
                )
              }
            : subRows.length > 1 && { subRows })
        }))
    : [];

  return {
    ...rest,
    content: contentUpdated
  };
};
export const formaterClockInData = data => {
  const { content, ...rest } = data;

  const contentUpdated = !!content.length
    ? content
        .map(wt => ({
          id: wt.id,
          gtId: `GT-${wt.id}`,
          ...wt.items?.reduce(
            (prev, curr, _, items) => {
              const start = new Date(curr.startAt);
              const end = new Date(curr.endAt);
              const diffInMs = end - start;
              const diffInHours = diffInMs / (1000 * 60 * 60);
              return {
                ...prev,
                taskName: wt?.taskName || "-",
                type: wt?.type || "-",
                areas:
                  wt.items?.map(item => item.areas).flat().length > 1
                    ? wt.items?.map(item => item.areas).flat().length
                    : wt.items?.map(item => item.areas).flat(),
                employees: wt.items?.map(item => item.employeeId),
                createdBy: uniqBy(
                  wt.items?.map(item => ({
                    employeeId: item.createdBy
                  })),
                  "employeeId"
                ),
                addedBy: "Added by",
                buttonWrapper: "totalRow",
                rowInfo: "totalRow",
                subRows: [
                  ...prev.subRows,
                  {
                    id: wt.id,
                    gtId: "",
                    taskName: "",
                    type: "",
                    areas: curr.areas,
                    employees: [curr.employeeId],
                    createdBy: [{ employeeId: curr.createdBy }],
                    addedBy: "Added by",
                    buttonWrapper: "subRows",
                    rowInfo: "subRows"
                  }
                ]
              };
            },
            {
              binUnitsNumber: 0,
              weight: 0,
              tonnePerHa: 0,
              numberOfPlants: 0,
              cost: 0,
              costPerTonne: 0,
              costPerBin: 0,
              binUnits: [],
              avgKgPerTree: 0,
              varieties: [],
              crops: [],
              subRows: []
            }
          )
        }))
        .map(({ subRows, withPatches, ...rest }) => ({
          ...rest,
          ...(withPatches
            ? {
                subRows: subRows.map(({ subRows, ...rest }) =>
                  subRows.length > 1 ? { ...rest, subRows } : rest
                )
              }
            : subRows?.length > 1 && { subRows })
        }))
    : [];

  return {
    ...rest,
    content: contentUpdated
  };
};

export const selectTableStructuredData = createSelector(
  [tableDataSelector],
  tableData => {
    return formaterData(tableData);
  }
);
export const selectInProgressTableStructuredData = createSelector(
  [inProgressTableDataSelector],
  tableData => {
    return formaterClockInData(tableData);
  }
);
export const generalTasksFilterOptions = createSelector([taskList], types => {
  return {
    options: types?.content?.length
      ? types.content.map(item => ({
          key: item,
          label: `GT-${item}`,
          value: item
        }))
      : []
  };
});
export const activeItemSelector = createSelector(
  [workingTime],
  workingTimeData => {
    const { activeItem, data, clockIn, activeTab } = workingTimeData;
    if (activeItem.id) {
      return activeTab.tab === "IN_PROGRESS"
        ? clockIn.content.find(({ id }) => activeItem.id === id)
        : data.content.find(({ id }) => activeItem.id === id);
    }
    return null;
  }
);
