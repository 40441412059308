import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import CollapsibleBlock from "components/CollapsibleBlock";
import styles from "../SideBar.module.css";

const HarvestUnitInfo = ({ activeItem }) => {
  const { barcodeId, harvestUnit, variety, employeeBinParts } =
    activeItem || {};
  return (
    <CollapsibleBlock
      className={styles.generalInfoTitle}
      title="Harvest unit info"
    >
      <Table className={styles.qcTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.addressRow}>Bin ID</Table.Cell>
            <Table.Cell className={styles.addressValueRow}>
              {barcodeId ? barcodeId : "-"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.comentsRow}>Harvest unit</Table.Cell>
            <Table.Cell className={styles.comentsValueRow}>
              {harvestUnit
                ? `${harvestUnit.name} ${harvestUnit.netWeight} Kg`
                : "-"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.comentsRow}>Crop</Table.Cell>
            <Table.Cell className={styles.comentsValueRow}>
              {variety ? variety.crop.name : "-"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.comentsRow}>Variety</Table.Cell>
            <Table.Cell className={styles.comentsValueRow}>
              {variety ? variety.name : "-"}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.comentsRow}>Pickers</Table.Cell>
            <Table.Cell className={styles.comentsValueRow}>
              <div>
                {!!employeeBinParts?.length
                  ? employeeBinParts.map(item => {
                      return (
                        <p>
                          {`${item.employee?.firstName} ${item.employee?.lastName}`}
                        </p>
                      );
                    })
                  : "-"}
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

HarvestUnitInfo.propTypes = {
  activeItem: PropTypes.object
};

export default HarvestUnitInfo;
