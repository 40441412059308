import React from "react";
import { Grid, Header, Table } from "semantic-ui-react";
import { map } from "lodash";
import {
  getChemicalsInformationData,
  getMachineryInformationData,
} from "../../utils";
import classNames from "classnames";
import styles from "./RecommendationsPrintout.module.css";

const OperationsSection = React.forwardRef(({ taskData }, ref) => {
  const machineryData = getMachineryInformationData(taskData);
  const chemicalsInformationData = getChemicalsInformationData(taskData);
  const headingRow = map(chemicalsInformationData[0], ({ title }) => title);

  return (
    <Grid.Row columns={1} ref={ref}>
      <Grid.Column>
        <Header
          className={classNames("bold", styles.operationsHeader)}
          size="medium"
        >
          Operations
        </Header>
      </Grid.Column>
      <Grid.Column>
        <Header className="bold" size="small">
          Machinery
        </Header>
      </Grid.Column>
      <Grid.Column className={styles.printedTable}>
        <Table basic="very" striped>
          <Table.Header>
            <Table.Row>
              {machineryData.map(({ title }) => (
                <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              {machineryData.map(({ value, title }) => (
                <Table.Cell key={title}>{value}</Table.Cell>
              ))}
            </Table.Row>
          </Table.Body>
        </Table>
      </Grid.Column>
      <Grid.Column>
        <Header className="bold" size="small">
          Chemicals
        </Header>
      </Grid.Column>
      <Grid.Column className={styles.printedTable}>
        <Table basic="very" striped>
          <Table.Header>
            <Table.Row>
              {headingRow.map((title) => (
                <Table.HeaderCell key={title}>{title}</Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {chemicalsInformationData.map((item, index) => (
              <Table.Row key={index}>
                {item.map(({ title, value, accessor }) => (
                  <Table.Cell key={title}>
                    {accessor ? accessor(taskData) : value}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid.Row>
  );
});

export default OperationsSection;
