import React from "react";
import { Table } from "semantic-ui-react";
import CollapsibleBlock from "components/CollapsibleBlock";
import styles from "../Sidebar.module.css";
import { StatusChip } from "routes/WorkingTime/components/StatusChip";

const TaskDetails = ({ activeItem = {}, employees, taskList }) => {
  const activeEmployee = employees
    .filter(item => activeItem?.items?.some(e => e.createdBy === item.id))
    .map(i => `${i.firstName} ${i.lastName}`);

  const activeTask = taskList?.find(item => item.id === activeItem?.id);
  const completedArea = activeTask?.areas.filter(i => i.completed);

  return (
    <CollapsibleBlock title="Task details" className={styles.blockContainer}>
      <Table className={styles.taskDetailsTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>ID</Table.Cell>
            <Table.Cell> {`GT-${activeItem?.id}`}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>Task name</Table.Cell>
            <Table.Cell>{activeItem?.taskName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>Type</Table.Cell>
            <Table.Cell>{activeItem?.type}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>
              Areas(completed)
            </Table.Cell>
            <Table.Cell>{`${completedArea?.length}/${activeTask?.areas?.length}`}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>Added by</Table.Cell>
            <Table.Cell>{activeEmployee.join(", ")}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell className={styles.headerSection}>
              Total working time
            </Table.Cell>
            <Table.Cell>-</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>Comments</Table.Cell>
            <Table.Cell>{activeTask?.comment || "-"}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

export default TaskDetails;
