import React from "react";
import PropTypes from "prop-types";
import { Grid, Header, Button, Icon } from "semantic-ui-react";
import { isEmptyValue } from "../../../utils/tasksUtils";
import TaskRow from "../../SprayDiary/TaskDetailsMobile/TaskRow";
import styles from "./Sidebar.module.css";
import Numeric from "../../../components/Numeric";
import { Link } from "react-router-dom";

const Sidebar = ({ boxUnit, id, setActiveItem }) => {
  return boxUnit ? (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column className={styles.wrapper} width={16}>
            <Header as="h1" className={styles.name}>
              {boxUnit.displayName}
            </Header>
            <div className={styles.btnWrapper}>
              <Button
                as={Link}
                color="green"
                to={`/harvest/box_units/add_box_units/${id}`}
              >
                Edit
              </Button>
              <Icon
                className={styles.closeIcon}
                name="close"
                onClick={() => setActiveItem(null)}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header>Information</Header>
      <Grid>
        <TaskRow
          label="Net weight"
          name="netWeight"
          value={
            <span>
              <Numeric value={boxUnit.netWeight} units={"Kg"} />
            </span>
          }
        />
        <TaskRow
          label="Box type"
          name="boxType"
          value={<span>{isEmptyValue(boxUnit.boxType)}</span>}
        />
        <TaskRow
          label="Pack type"
          name="packType"
          value={<span>{isEmptyValue(boxUnit.packType)}</span>}
        />
      </Grid>
      <Header>Pricing</Header>
      <Grid>
        <TaskRow
          label="Picking price"
          name="pickingPrice"
          value={
            <span>
              <Numeric
                value={boxUnit.pickingPrice}
                units={"$"}
                unitsPosition={"left"}
              />
            </span>
          }
        />
        <TaskRow
          label="Padding price"
          name="paddingPrice"
          value={
            <span>
              <Numeric
                value={boxUnit.paddingPrice}
                units={"$"}
                unitsPosition={"left"}
              />
            </span>
          }
        />
        <TaskRow
          label="Carting price"
          name="cartingPrice"
          value={
            <span>
              <Numeric
                value={boxUnit.cartingPrice}
                units={"$"}
                unitsPosition={"left"}
              />
            </span>
          }
        />
      </Grid>
      <Header>Picking</Header>
      <Grid>
        <TaskRow
          label="Units per pallet"
          name="unitsPerPallet"
          value={
            <span>
              <Numeric value={boxUnit.unitsPerPallet} fractionDigits={0} />
            </span>
          }
        />
        <TaskRow
          label="Liner"
          name="liner"
          value={<span>{isEmptyValue(boxUnit.liner)}</span>}
        />
      </Grid>
    </>
  ) : (
    <div>Nothing to show</div>
  );
};

Sidebar.propTypes = {
  boxUnit: PropTypes.object,
};
Sidebar.defaultProps = {
  boxUnit: {},
};

export default Sidebar;
