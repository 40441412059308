import React from "react";
import Checkbox from "../components/Checkbox";
import { getHashParameters } from "utils/hashToObject";
import priorityMedium from "assets/img/priorityMedium.svg";
import low_priority from "assets/img/low_priority.svg";
import hige_priority from "assets/img/hige_priority.svg";
import filterTypes from "../filterTypes";
import styles from "../components/Table.module.css";
import { Menu, Icon, Dropdown, Popup } from "semantic-ui-react";
import moment from "moment";

export const tableTypeIcon = (
  activeTab,
  taskTableType,
  setTaskTableType,
  selectedItemsIdsChange,
  setSelectMode
) => {
  return (
    <div className={styles.iconContainer}>
      <span
        className={
          taskTableType === "list" ? styles.activeIcon : styles.iconWrapper
        }
      >
        <Icon
          onClick={() => {
            return (
              setTaskTableType("list"),
              selectedItemsIdsChange([]),
              setSelectMode(false)
            );
          }}
          className={styles.listIcon}
          name="list"
        />
      </span>
      <span
        className={
          taskTableType === "card" ? styles.activeIcon : styles.iconWrapper
        }
      >
        <Icon
          onClick={() => {
            return (
              setTaskTableType("card"),
              selectedItemsIdsChange([]),
              setSelectMode(false)
            );
          }}
          className={styles.cardIcon}
          name="th"
        />
      </span>
      <span
        className={
          taskTableType === "timeLine" ? styles.activeIcon : styles.iconWrapper
        }
      >
        <Icon
          onClick={() => {
            return (
              setTaskTableType("timeLine"),
              selectedItemsIdsChange([]),
              setSelectMode(false)
            );
          }}
          className={styles.listIcon}
          name="bars"
        />
      </span>
    </div>
  );
};
export const addTabsSection = (
  list,
  data,
  selectedItemsIds,
  activeTab,
  handleItemClick,
  selectMode,
  setSelectMode,
  isArchivedView,
  onArchiveClick,
  onDeleteClick
) => {
  const localTime = moment().format("YYYY-MM-DDTHH:mm:ss");
  const filtredData = status => {
    return list.filter(
      i => i.status === status && i.archived === isArchivedView
    );
  };
  const expiredData = status =>
    list.filter(item => {
      return (
        item.status === status &&
        item.archived === isArchivedView &&
        item.expireAt < localTime
      );
    });
  const dropdown = () => (
    <Dropdown
      className={styles.selectionDropdown}
      icon="ellipsis horizontal"
      iconposition="right"
      floating
    >
      <Dropdown.Menu direction="left">
        <Dropdown.Item
          className={styles.dropdownItem}
          onClick={() => {
            return setSelectMode(!selectMode);
          }}
        >
          {selectMode ? "Cancel selection" : "Select"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
  const archiveIcon = () =>
    !isArchivedView ? (
      <Icon
        onClick={() => onArchiveClick(null)}
        disabled={!selectedItemsIds.length}
        className={styles.archiveIcon}
        name="archive"
      />
    ) : (
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Icon
          onClick={() => onArchiveClick(null)}
          disabled={!selectedItemsIds.length}
          className={styles.archiveIcon}
          name="archive"
        />
        <Icon
          onClick={() => onDeleteClick(null)}
          disabled={!selectedItemsIds.length}
          className={styles.taskDeleteIcon}
          name="trash alternate"
        />
      </div>
    );
  return (
    <Menu pointing secondary className={styles.tabsMenu}>
      <Menu.Item
        name={"OPEN"}
        active={activeTab === "OPEN"}
        onClick={handleItemClick}
      >
        <span>
          {`To Do (${filtredData("OPEN").length})`}
          {!!expiredData("OPEN").length && (
            <Popup
              position="right center"
              className={styles.expiredPopup}
              trigger={
                <Icon className={styles.infoButton} name="info circle" />
              }
              content={`There are ${
                expiredData("OPEN").length
              } expired tasks. Please, check the details`}
              size="small"
              inverted
            />
          )}
        </span>
        <div className={styles.tasksIconWrapper}>
          {selectMode && activeTab === "OPEN" && archiveIcon()}
          {dropdown()}
        </div>
      </Menu.Item>
      <Menu.Item
        name={"IN_PROGRESS"}
        active={activeTab === "IN_PROGRESS"}
        onClick={handleItemClick}
      >
        <span>
          {`In Progress (${filtredData("IN_PROGRESS").length})`}
          {!!expiredData("IN_PROGRESS").length && (
            <Popup
              position="right center"
              className={styles.expiredPopup}
              trigger={
                <Icon className={styles.infoButton} name="info circle" />
              }
              content={`There are ${
                expiredData("IN_PROGRESS").length
              } expired tasks. Please, check the details`}
              size="small"
              inverted
            />
          )}
        </span>
        <div className={styles.tasksIconWrapper}>
          {selectMode && activeTab === "IN_PROGRESS" && archiveIcon()}
          {dropdown()}
        </div>
      </Menu.Item>
      <Menu.Item
        name={"COMPLETED"}
        active={activeTab === "COMPLETED"}
        onClick={handleItemClick}
      >
        {`Completed(${filtredData("COMPLETED").length})`}
        <div className={styles.tasksIconWrapper}>
          {selectMode && activeTab === "COMPLETED" && archiveIcon()}
          {dropdown()}
        </div>
      </Menu.Item>
    </Menu>
  );
};
export const addSimpleTabsSection = (activeTab, handleItemClick) => {
  return (
    <Menu pointing secondary className={styles.tabsMenu}>
      <Menu.Item
        name={"IN_PROGRESS"}
        active={activeTab === "IN_PROGRESS"}
        onClick={handleItemClick}
      >
        In Progress
      </Menu.Item>
      <Menu.Item
        name={"COMPLETED"}
        active={activeTab === "COMPLETED"}
        onClick={handleItemClick}
      >
        Completed
      </Menu.Item>
    </Menu>
  );
};
export const priorityIcon = priority => {
  return priority === "HIGH" ? (
    <div className={styles.priorityIconWrapper}>
      {/*  <Icon name="chevron up" className={styles.highPriorityIcon} /> */}
      <img
        src={hige_priority}
        alt="priority medium"
        className={styles.priorityMedium}
      />
    </div>
  ) : priority === "MEDIUM" ? (
    <div className={styles.priorityIconWrapper}>
      <img
        src={priorityMedium}
        alt="priority medium"
        className={styles.priorityMedium}
      />
    </div>
  ) : priority === "LOW" ? (
    <div className={styles.priorityIconWrapper}>
      <img
        src={low_priority}
        alt="priority medium"
        className={styles.priorityMedium}
      />
      {/* <Icon name="chevron down" className={styles.lowPriorityIcon} /> */}
    </div>
  ) : (
    <div className={styles.withoutPriorityWrapper}>
      <div className={styles.withoutPriority}>-</div>
    </div>
  );
};
export const addSelectColumn = hooks =>
  hooks.visibleColumns.push(columns => [
    {
      id: "selection",
      Header: ({
        getToggleAllRowsSelectedProps,
        toggleAllRowsSelected,
        rows
      }) => (
        <div className={styles.checkboxContainer}>
          <Checkbox
            className={styles.tableCheckbox}
            {...getToggleAllRowsSelectedProps()}
            onChange={() => {
              const isAnySelected = rows.some(r => r.isSelected);
              toggleAllRowsSelected(!isAnySelected);
            }}
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className={styles.checkboxContainer}>
          <Checkbox
            className={styles.tableCheckbox}
            {...row.getToggleRowSelectedProps()}
          />
        </div>
      ),
      disableSortBy: true,
      className: styles.checkboxCell,
      headerClassName: styles.selectHeader,
      cellWidth: "auto"
    },
    ...columns
  ]);

export const getTableInitialState = ({
  pageIndex,
  pageSize,
  defaultSort,
  selectedItems
}) => {
  const initialStateObject = {
    pageIndex,
    pageSize
  };

  if (defaultSort) {
    const [id, order] = defaultSort.split(",");
    const desc = order === "desc";

    initialStateObject.sortBy = [{ id, desc }];
  }

  if (selectedItems) {
    initialStateObject.selectedRowIds = selectedItems.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }

  return initialStateObject;
};

export const changeHandler = ({
  search,
  size,
  filters,
  sortBy,
  page,
  getData,
  getListData
}) => {
  if (getData) {
    const sort = sortBy.map(({ id, desc }) =>
      [id, desc ? "asc" : "desc"].join(",")
    );

    getData({
      page,
      size,
      search,
      filters,
      sort
    });
  }

  if (getListData) {
    getListData({ search, filters });
  }
};

export const parseFilters = (options = [], location) => {
  const params = getHashParameters(location);

  return options
    .filter(option => params[option.filterId])
    .reduce((acc, filter) => {
      if (filter.type === filterTypes.MultiSelect) {
        acc[filter.filterId] = params[filter.filterId].split(",");
      } else {
        acc[filter.filterId] = params[filter.filterId];
      }

      return acc;
    }, {});
};
