import React from "react";
import PropTypes from "prop-types";
import CollapsibleBlock from "components/CollapsibleBlock";
import { Button, Grid, Icon, Popup, Segment } from "semantic-ui-react";
import styles from "../SideBar.module.css";
import { ROLE_COLORS } from "constants/InventoryMovements";
import { getNameInitials } from "routes/Labour/utils";

const RoundsInfo = ({
  setOpenAddSideBar = () => {},
  roundAddInitialValue,
  setActiveRounds = () => {},
  setActiveRoundsNumber = () => {},
  activeItem,
  employees,
  setOpenSecondSideBar,
  setUrlList,
  setblockName,
  setimageModalOpen,
  header
}) => {
  const { qcRounds } = activeItem || {};
  return (
    <CollapsibleBlock className={styles.generalInfoTitle} title="Rounds info">
      {(qcRounds?.length
        ? !!roundAddInitialValue
          ? [...qcRounds, roundAddInitialValue]
          : qcRounds
        : []
      ).map((item, index) => {
        const numberOfEmployees = item?.employeeResults?.length;
        const employeeIds = employees
          ?.filter(i => item?.employeeResults.some(e => e.employeeId === i.id))
          .map(i => i.id);
        const morePopupContent = members => {
          return (
            <div className={styles.morePopupContent}>
              {members.length &&
                members &&
                members.slice(3, members.length).map(emp => {
                  const employee =
                    (employees &&
                      emp &&
                      employees.find(employeeObj => employeeObj.id === emp)) ||
                    null;
                  const employeeImg =
                    employee && employee.picture
                      ? employee.picture.presignedUrl
                      : null;
                  return (
                    employee &&
                    !employee.archived && (
                      <div key={employee.id} className={styles.popupItem}>
                        <div
                          className={styles.avatarHolder}
                          styles={
                            employeeImg
                              ? {
                                  backgroundImage:
                                    employeeImg && `url(${employeeImg})`
                                }
                              : {
                                  background: ROLE_COLORS[employee.type]
                                    ? ROLE_COLORS[employee.type]
                                    : "gray"
                                }
                          }
                        >
                          {employee.firstName}
                          {employee.lastName}
                        </div>
                      </div>
                    )
                  );
                })}
            </div>
          );
        };
        const imagesUrl =
          item?.pictures &&
          item?.pictures.length &&
          item.pictures.map(item => ({
            url: item.presignedUrl
          }));
        return (
          <Grid as={Segment}>
            <Grid.Row className={styles.rowContainer}>
              <Grid.Column width={4} className={styles.columnWrapper}>
                <Grid.Column className={styles.valueRow}>
                  {`Round ${index + 1}`}
                </Grid.Column>
                <Grid.Column className={styles.subRow}>
                  Checked units: {activeItem?.unitToCheckPerRound || 0}
                </Grid.Column>
              </Grid.Column>
              <Grid.Column width={3} className={styles.columnWrapper}>
                <Grid.Column className={styles.valueRow}>
                  {item.defectReports.length}
                </Grid.Column>
                <Grid.Column className={styles.subRow}>Parameters</Grid.Column>
              </Grid.Column>
              <Grid.Column width={4} className={styles.columnWrapper}>
                {numberOfEmployees ? (
                  <ul className={styles.avatarsContainer}>
                    {employeeIds.slice(0, 3).map((employeeId, index) => {
                      const employee =
                        (employees &&
                          employees.find(
                            employeeObj => employeeObj.id === employeeId
                          )) ||
                        null;
                      const employeeImg =
                        employee && employee.picture
                          ? employee.picture.presignedUrl
                          : null;

                      return employeeImg ? (
                        <Popup
                          position="bottom center"
                          inverted
                          // key={employee.id||index}
                          trigger={
                            <li
                              // key={employee.id||index}
                              className={styles.avatarHolder}
                              style={{
                                backgroundImage: `url(${employeeImg})`
                              }}
                            />
                          }
                          content={`${employee.firstName} ${employee.lastName}`}
                        />
                      ) : (
                        <Popup
                          inverted
                          position="bottom center"
                          trigger={
                            <li
                              className={styles.avatarHolder}
                              style={{
                                background: ROLE_COLORS[
                                  employee && employee.type
                                ]
                                  ? ROLE_COLORS[employee && employee.type]
                                  : "gray"
                              }}
                            >
                              {getNameInitials(employee && employee)}
                            </li>
                          }
                          content={`${employee &&
                            employee.firstName} ${employee &&
                            employee.lastName}`}
                        />
                      );
                    })}
                    {numberOfEmployees > 3 && (
                      <Popup
                        inverted
                        position="right center"
                        popperModifiers={{
                          preventOverflow: {
                            boundariesElement: "offsetParent"
                          }
                        }}
                        content={morePopupContent(employeeIds)}
                        trigger={<span>+{numberOfEmployees - 3}</span>}
                      />
                    )}
                  </ul>
                ) : (
                  "—"
                )}
              </Grid.Column>
              <Grid.Column width={4} className={styles.columnWrapper}>
                {!!imagesUrl && imagesUrl.length ? (
                  <div className={styles.imageContainer}>
                    {imagesUrl.slice(0, 3).map(item => {
                      return (
                        <Button
                          className={styles.imageButton}
                          onClick={event => {
                            return (
                              setUrlList(imagesUrl),
                              setblockName(header),
                              setimageModalOpen(true)
                            );
                          }}
                        >
                          <img className={styles.image} src={item.url} />
                        </Button>
                      );
                    })}
                    {imagesUrl.length > 3 && `+ ${imagesUrl.length - 3}`}
                  </div>
                ) : (
                  "—"
                )}
              </Grid.Column>
              <Grid.Column
                width={1}
                textAlign="right"
                className={styles.columnWrapper}
              >
                <Popup
                  inverted
                  position="bottom center"
                  content={`Round ${index + 1}`}
                  size="tiny"
                  trigger={
                    <Button
                      onClick={() => {
                        return (
                          !item.defectReports.length && setOpenAddSideBar(true),
                          setActiveRoundsNumber(index),
                          setActiveRounds(item),
                          setOpenSecondSideBar(true)
                        );
                      }}
                      className={styles.nextStepBtn}
                      floated="right"
                      icon={<Icon name="angle right" />}
                      size="small"
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      })}
    </CollapsibleBlock>
  );
};

RoundsInfo.propTypes = {
  activeItem: PropTypes.object
};

export default RoundsInfo;
