import React from "react";
import PropTypes from "prop-types";
import { Icon, Label, Table } from "semantic-ui-react";
import styles from "./TableFarm.module.css";
import AreaRow from "./AreaRow";

const AreaWithSubAreas = ({
  farm,
  area,
  subAreas,
  setActivePatch,
  handleArchiveArea,
  handleEdit,
  countOpenTasks,
  archivedFarm
}) => {
  const openTasks = countOpenTasks(subAreas);
  return (
    <>
      <Table.Row
        onClick={event => {
          event.stopPropagation();
          setActivePatch(area, farm);
        }}
        key={`area_${area.id}`}
        className={`${styles.rowBlock} ${styles.rowBlockWithSubArea} ${
          area.archived && !archivedFarm ? styles.archivedFarm : ""
        }`}
      >
        <Table.Cell />
        <Table.Cell colSpan={6} className={styles.areaCell}>
          <div className={styles.blockName}>{area.name}</div>
        </Table.Cell>

        <Table.Cell textAlign="center">
          <Icon className={styles.tasksListIcon} />
          <Label circular color={openTasks && openTasks > 0 ? "olive" : "grey"}>
            {openTasks ? openTasks : 0}
          </Label>
        </Table.Cell>
        <Table.Cell />
        <Table.Cell textAlign="center">
          {!area.archived ? (
            <Icon
              className={`tuf-pen ${styles.tasksEditIcon}`}
              onClick={event => handleEdit(event, farm.id, area)}
            />
          ) : (
            <Icon className="blank" />
          )}
          <Icon
            className={area.archived ? "tuf-unarchive" : "tuf-archive"}
            onClick={() => handleArchiveArea(area, !area.archived)}
          />
        </Table.Cell>
      </Table.Row>
      {subAreas.map(area => {
        return (
          <AreaRow
            key={`area_${area.id}`}
            farm={farm}
            area={area}
            setActivePatch={setActivePatch}
            handleArchiveArea={handleArchiveArea}
            handleEdit={handleEdit}
            blockRow={false}
          />
        );
      })}
    </>
  );
};

AreaWithSubAreas.propTypes = {
  archivedFarm: PropTypes.bool,
  area: PropTypes.object.isRequired,
  className: PropTypes.string,
  countOpenTasks: PropTypes.func.isRequired,
  farm: PropTypes.object.isRequired,
  handleArchiveArea: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  setActivePatch: PropTypes.func,
  handleFarmClick: PropTypes.func.isRequired,
  subAreas: PropTypes.array.isRequired
};

export default AreaWithSubAreas;
