import { actionType } from "../../constants/EmployeeGroups";

const initialState = {
  data: {
    content: [],
    number: 0,
    totalPages: 0,
    totalElements: 0,
    size: 10,
    isFetching: false
  },
  list: {
    content: [],
    isFetching: false
  },
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.FETCH_EMPLOYEE_GROUPS_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };

    case actionType.FETCH_EMPLOYEE_GROUPS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false
        }
      };
    }
    case actionType.FETCH_EMPLOYEE_GROUPS_ERROR:
      return {
        ...state,
        error: action.payload,
        data: {
          ...state.data,
          isFetching: false
        }
      };
    case actionType.FETCH_EMPLOYEE_GROUPS_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true
        }
      };

    case actionType.FETCH_EMPLOYEE_GROUPS_LIST: {
      return {
        ...state,
        list: {
          ...action.payload,
          isFetching: false
        }
      };
    }
    case actionType.FETCH_EMPLOYEE_GROUPS_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        list: {
          ...state.list,
          isFetching: false
        }
      };

    case actionType.CLEAR_EMPLOYEE_GROUPS_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
