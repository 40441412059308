import { actionType } from "../../constants/WorkingTime";
import { keyBy } from "lodash";

const initialState = {
  data: {
    content: [],
    isFetching: false
  },
  clockIn: {
    content: [],
    isFetching: false
  },
  activeItem: {
    id: null,
    isEditing: false
  },
  activeTab: {
    tab: "IN_PROGRESS",
    isEditing: false
  },
  error: null
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.FETCH_TASKS_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        },
        clockIn: {
          ...state.clockIn,
          isFetching: true
        }
      };
    case actionType.FETCH_TASKS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          content: !!action.payload.content.lenth ? state.data.content : [],
          isFetching: false
        },
        clockIn: {
          ...state.clockIn,
          ...action.payload,
          content: !!action.payload.content.lenth ? state.clockIn.content : [],
          isFetching: false
        }
      };
    case actionType.FETCH_TASKS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case actionType.GET_WORKING_TIME_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };

    case actionType.GET_WORKING_TIME:
      return {
        ...state,
        data: {
          ...state.data,
          content: action.payload,
          isFetching: false
        }
      };
    case actionType.GET_WORKING_TIME_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case actionType.GET_CLOCK_IN_START:
      return {
        ...state,
        clockIn: {
          ...state.clockIn,
          isFetching: true
        }
      };

    case actionType.GET_CLOCK_IN:
      return {
        ...state,
        clockIn: {
          ...state.clockIn,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.GET_CLOCK_IN_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case actionType.ADD_CLOCK_IN_START:
      return {
        ...state,
        clockIn: {
          ...state.clockIn,
          isFetching: true
        }
      };
    case actionType.ADD_CLOCK_IN:
      return {
        ...state,
        clockIn: {
          content: [...state.clockIn.content, ...action.payload],
          isFetching: false
        }
      };

    case actionType.ADD_CLOCK_IN_FAIL:
      return {
        ...state,
        clockIn: {
          ...state.clockIn,
          isFetching: false
        },
        error: action.payload
      };
    case actionType.UPDATE_WORKING_TIME_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };
    case actionType.UPDATE_WORKING_TIME:
      return {
        ...state,
        data: {
          content: state.data.content.map(item =>
            item.id === action.payload.generalTaskId
              ? {
                  ...item,
                  items: item.items.map(e => {
                    return e.id === action.payload.id ? action.payload : e;
                  })
                }
              : item
          ),
          isFetching: false
        }
      };

    case actionType.UPDATE_WORKING_TIME_FAIL:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false
        },
        error: action.payload
      };
    case actionType.ADD_CLOCK_OUT_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };

    case actionType.ADD_CLOCK_OUT_FAIL:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false
        },
        error: action.payload
      };
    case actionType.ADD_WORKING_TIME_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };

    case actionType.ADD_WORKING_TIME_FAIL:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false
        },
        error: action.payload
      };
    case actionType.UPDATE_WORKING_TIME_STATUS_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };
    case actionType.UPDATE_WORKING_TIME_STATUS:
      return {
        ...state,
        data: {
          content: state.data.content.map(item =>
            item.id === action.payload[0].generalTaskId
              ? {
                  ...item,
                  items: item.items.map(e => {
                    return action.payload.some(i => i.id === e.id)
                      ? {
                          ...e,
                          approved: true,
                          approvedBy: action.payload[0].approvedBy
                        }
                      : e;
                  })
                }
              : item
          ),
          isFetching: false
        }
      };

    case actionType.UPDATE_WORKING_TIME_STATUS_FAIL:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: false
        },
        error: action.payload
      };
    case actionType.SET_ACTIVE_ITEM_ID:
      return {
        ...state,
        activeItem: {
          id: action.payload,
          error: null
        }
      };
    case actionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: {
          tab: action.payload,
          error: null
        }
      };
    case actionType.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
