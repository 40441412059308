import React, { useEffect, useState } from "react";
import moment from "moment";
import * as Yup from "yup";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Icon,
  Input,
  Popup,
  Header,
  Modal
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik, FieldArray, useFormikContext } from "formik";
import styles from "./NewCost.module.css";
import PropTypes from "prop-types";
import {
  ALT_LABOUR_CONTRACTOR_TYPE,
  ALT_COST_ENTERING_INITIAL_VALUES
} from "constants/GeneralTasks/costEntering";
import DateSelector from "../components/DateSelecor/DateSelector";
import { generalTasksOptions, subTypeSelector } from "selectors/generalTasks";
import { costEnteringType } from "actions/GeneralTasks/costEntering";
import DropdownTree from "./DropdownTree/AreaDropDown";
import { fetchGeneralTaskList } from "actions/GeneralTasks/generalTasks";
const NewCostForm = ({
  errors,
  costData,
  costsListCheck,
  selectedItem,
  deleteModalOpen,
  checkHandler,
  index
}) => {
  useEffect(() => {
    dispatch(fetchGeneralTaskList());
  }, []);

  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();
  const generalTaskIdList = useSelector(generalTasksOptions) || [];
  const costSubType = useSelector(subTypeSelector);
  const [dateDropdownChecker, setDateDropdownChecker] = useState(false);
  const [showChecker, setShowChecker] = useState(false);
  const cost = values.costs.find((_, costIndex) => costIndex === index);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [checker, setChecker] = useState(
    costData.endAt !== "" ? "range" : "applied"
  );

  const validationSchema = () => {
    return Yup.object().shape({
      costType: Yup.string().required("costType is required"),
      costSubType: Yup.string().required("type is required"),
      startAt: Yup.string().required("date is required"),
      generalTaskId: Yup.string().required("general task id is required")
    });
  };

  const onDeleteClick = index => {
    const newCost = values.costs?.filter((_, i) => i !== index);

    setFieldValue("costs", newCost);
    setShowDeleteModal(false);
  };
  return (
    <Formik
      enableReinitialize
      validateOnBlur
      validationSchema={validationSchema}
      initialValues={ALT_COST_ENTERING_INITIAL_VALUES || costData}
    >
      {props => (
        <Form>
          <Modal
            className={styles.deleteModal}
            size="mini"
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <Modal.Content>
              <p>
                Delete{" "}
                {costData.costType
                  ? (
                      costData.costType
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() +
                      costData.costType.toLowerCase().slice(1)
                    ).replace(/\s/g, "_")
                  : ""}{" "}
                ?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                className={styles.cancelDltBtn}
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button
                className={styles.deleteBtn}
                positive
                onClick={() => onDeleteClick(index)}
              >
                Delete
              </Button>
            </Modal.Actions>
          </Modal>
          <Form.Group
            className={
              !!costData.costType ? styles.rowWrapper : styles.formWrapper
            }
          >
            <Form.Field className={styles.costtypeLabel} width={2}>
              <div className={styles.checkboxWrapper}>
                <Checkbox
                  checked={selectedItem?.includes(index)}
                  onChange={() => checkHandler(index)}
                />
              </div>
              <Dropdown
                placeholder="Select"
                name="costType"
                scrolling
                onFocus={() => setDateDropdownChecker(false)}
                openOnFocus
                options={ALT_LABOUR_CONTRACTOR_TYPE}
                value={costData.costType}
                isInvalid={!!errors.costType}
                onChange={(e, { value }) => {
                  dispatch(costEnteringType(value));
                  setFieldValue(`costs[${index}].costType`, value);
                  setFieldValue(`costs[${index}].costSubType`, "");
                  setFieldValue(`costs[${index}].generalTaskId`, "");
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <div className={styles.dropdownTreeWrapper}>
                <div className={styles.dropdownTreeContainer}>
                  <DropdownTree
                    data={costData.areaData}
                    showChecker={showChecker}
                    costIndex={index}
                    handleAreaSelectTreeChange={setFieldValue}
                  />
                </div>
              </div>
            </Form.Field>
            <Form.Field width={2}>
              <Dropdown
                placeholder="Select"
                name="generalTaskId"
                scrolling
                className={styles.gtId}
                clearable
                openOnFocus
                search
                onFocus={() => setDateDropdownChecker(false)}
                options={generalTaskIdList.options}
                value={costData.generalTaskId}
                onChange={(e, { value }) => {
                  setFieldValue(`costs[${index}].generalTaskId`, value);
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <FieldArray
                name="costSubType"
                render={() => (
                  <div className={styles.costEnteringTypeWrapper}>
                    {costData.costSubType && (
                      <div className={styles.costEnteringTypesSection}>
                        <Header
                          floated="left"
                          className={styles.costEnteringTypeHeader}
                          size="small"
                        >
                          {costData.costSubType}
                        </Header>
                        <Popup
                          content="Delete type"
                          position="bottom center"
                          inverted
                          trigger={
                            <Icon
                              name="trash alternate"
                              className={styles.removeCostEnteringType}
                              onClick={() => {
                                return setFieldValue(
                                  `costs[${index}].costSubType`,
                                  ""
                                );
                              }}
                            />
                          }
                        />
                      </div>
                    )}
                    {!costData.costSubType && (
                      <Field
                        placeholder="Select"
                        className={styles.costEnteringTypeField}
                        fluid
                        disabled={costData.costType == ""}
                        as={Form.Select}
                        allowAdditions
                        search
                        searchQuery={searchQuery}
                        onSearchChange={(_, { searchQuery }) => {
                          setSearchQuery(searchQuery);
                        }}
                        name="costSubType"
                        onChange={(e, { value }) => {
                          setFieldValue(`costs[${index}].costSubType`, value);
                        }}
                        options={costSubType || []}
                      />
                    )}
                  </div>
                )}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Field
                placeholder={0}
                as={Input}
                onFocus={() => setDateDropdownChecker(false)}
                name="units"
                value={costData.units}
                onKeyPress={event => {
                  if (!/^[0-9.]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e, { value }) => {
                  setFieldValue(`costs[${index}].units`, value);
                  setFieldValue(
                    `costs[${index}].rate`,
                    costData.total && value ? costData.total / value : 0
                  );
                  setFieldValue(
                    `costs[${index}].total`,
                    costData.rate && value ? costData.rate * value : 0
                  );
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Field
                placeholder={0}
                as={Input}
                disabled={!costData.units}
                className={styles.disabledField}
                value={costData.rate}
                name="rate"
                onFocus={() => setDateDropdownChecker(false)}
                onKeyPress={event => {
                  if (!/^[0-9.]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e, { value }) => {
                  setFieldValue(`costs[${index}].rate`, value);
                  setFieldValue(
                    `costs[${index}].total`,
                    costData.units && value ? costData.rate * value : 0
                  );
                }}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Field
                placeholder={0}
                as={Input}
                value={
                  values.costs[index].rate && values.costs[index].units
                    ? values.costs[index].rate * values.costs[index].units
                    : values.costs[index].total
                    ? values.costs[index].total
                    : ""
                }
                name="total"
                onFocus={() => setDateDropdownChecker(false)}
                onKeyPress={event => {
                  if (!/^[0-9.]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e, { value }) => {
                  setFieldValue(`costs[${index}].total`, value);
                  if (costData.units) {
                    setFieldValue(
                      `costs[${index}].rate`,
                      value / costData.units
                    );
                  }
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <Field
                value={costData.notes}
                placeholder={"Type the notes..."}
                as={Input}
                onFocus={() => setDateDropdownChecker(false)}
                name="notes"
                onChange={(e, { value }) => {
                  setFieldValue(`costs[${index}].notes`, value);
                }}
              />
            </Form.Field>
            <Form.Field width={2}>
              <div className={styles.dateField}>
                <div
                  onClick={() => setDateDropdownChecker(!dateDropdownChecker)}
                  className={styles.dateFieldWrapper}
                >
                  <div className={styles.dateValues}>
                    {values.costs.find((_, costIndex) => costIndex === index)
                      .endAt ? (
                      `${moment(
                        values.costs.find((_, costIndex) => costIndex === index)
                          .startAt
                      ).format("DD/MM/YYYY")} - 
                        ${moment(
                          values.costs.find(
                            (_, costIndex) => costIndex === index
                          ).endAt
                        )
                          .add(-1, "day")
                          .format("DD/MM/YYYY")}`
                    ) : values.costs.find((_, costIndex) => costIndex === index)
                        .startAt ? (
                      `${moment(
                        values.costs.find((_, costIndex) => costIndex === index)
                          .startAt
                      ).format("DD/MM/YYYY")}`
                    ) : (
                      <span className={styles.datePlaceholder}>Select</span>
                    )}
                  </div>
                  <Icon className={styles.dateSelectIcon} name="caret down" />
                </div>
                {dateDropdownChecker && (
                  <div
                    tabIndex="0"
                    id="myDropdown"
                    className={styles.dateSelectWrapper}
                  >
                    <DateSelector
                      index={index}
                      checker={checker}
                      setChecker={setChecker}
                      costData={values.costs}
                      onChangeDate={(value, checker, setDate) => {
                        return values.costs.map((item, i) => {
                          i == index &&
                            setFieldValue(
                              "costs",
                              checker
                                ? values.costs.map((cost, costIndex) =>
                                    costIndex === i
                                      ? {
                                          ...item,
                                          startAt: `${moment(
                                            value.start
                                          ).format("YYYY-MM-DD")}T00:00:00`,
                                          endAt: `${moment(value.end)
                                            .add(1, "day")
                                            .format(
                                              "YYYY-MM-DD"
                                            )}T00:00:00` /* new Date(
                                        moment(value.end)
                                          .add(1, "day")
                                          .format("MM/DD/YYYY")
                                      ).toISOString() */
                                        }
                                      : { ...cost }
                                  )
                                : values.costs.map((cost, costIndex) =>
                                    costIndex === i
                                      ? {
                                          ...item,
                                          startAt: `${moment(value).format(
                                            "YYYY-MM-DD"
                                          )}T00:00:00`,
                                          endAt: ""
                                        }
                                      : { ...cost }
                                  )
                            );
                        });
                        // setDateDropdownChecker(false)
                      }}
                      onRadioButtonChange={(value, checker, setDate) => {
                        return values.costs.map((item, i) => {
                          i == index &&
                            setFieldValue(
                              "costs",
                              checker
                                ? values.costs.map((cost, costIndex) =>
                                    costIndex === i
                                      ? {
                                          ...item,
                                          startAt: "",
                                          endAt: ""
                                        }
                                      : { ...cost }
                                  )
                                : values.costs.map((cost, costIndex) =>
                                    costIndex === i
                                      ? {
                                          ...item,
                                          startAt: "",
                                          endAt: ""
                                        }
                                      : { ...cost }
                                  )
                            );
                        });
                        // setDateDropdownChecker(false)
                      }}
                      setDateDropdownChecker={setDateDropdownChecker}
                    />
                  </div>
                )}
              </div>
            </Form.Field>
            <Form.Field className={styles.buttonContainer} width={1}>
              <Popup
                inverted
                content={"Delete"}
                size="tiny"
                position="center bottom"
                trigger={
                  <span className={styles.deleteIconWrapper}>
                    <Button
                      type="reset"
                      disabled={costsListCheck < 2}
                      onClick={() => setShowDeleteModal(true)}
                      className={styles.deleteButton}
                      icon={
                        <Icon
                          className={styles.deleteIcon}
                          name="trash alternate"
                        />
                      }
                    />
                  </span>
                }
              />
              <Popup
                inverted
                content={"Copy"}
                size="tiny"
                position="center bottom"
                trigger={
                  <span className={styles.copyIconWrapper}>
                    <Button
                      type="reset"
                      onClick={() =>
                        setFieldValue("costs", [
                          ...values.costs,
                          values.costs[index]
                        ])
                      }
                      className={styles.copyButton}
                      icon={<Icon className={styles.copyIcon} name="copy" />}
                    />
                  </span>
                }
              />
            </Form.Field>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};
NewCostForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
export default NewCostForm;
