import moment from "moment";
import { actionType } from "../../constants/HourlyPayroll";
import { axiosProxy } from "../../utils/axiosProxy";
import {
  enumerateDaysBetweenDates,
  convertDatesToCertainRange
} from "utils/dateUtils";
import uuid from "uuid/v4";

let uniqId;
const url = "/reporting_module";

export const fetchHourlyPayrollsByEmployeeId = ({
  employeeId,
  from,
  to
}) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_HOURLY_PAYROLLS_EXPANDED_DATA_START,
      payload: employeeId
    });

    const params = new URLSearchParams({
      unpaged: true,
      sort: "date,desc",
      employeeIds: [employeeId]
    });

    if (from) params.append("from", from);
    if (to)
      params.append(
        "to",
        moment(to)
          .add(1, "day")
          .format("YYYY-MM-DD")
      );

    const {
      data: { content, page }
    } = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/payrollHourly`
    });

    await dispatch({
      type: actionType.FETCH_HOURLY_PAYROLLS_EXPANDED_DATA,
      payload: {
        employeeId,
        data: content,
        page
      }
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_HOURLY_PAYROLLS_EXPANDED_DATA_FAIL,
      payload: error
    });
  }
};

export const fetchHourlyPayrollsAllInformation = (
  dateAfter,
  dateBefore,
  filters = {}
) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_START
    });

    const params = new URLSearchParams({ unpaged: true });

    Object.entries(filters).forEach(([name, value]) => {
      const from = value[0].slice(0, 10);
      const to = value[0].slice(-10);
      return name !== "seasons"
        ? Array.isArray(value)
          ? value.forEach(val => params.append(name, val))
          : params.append(name, value)
        : (params.append("from", from), params.append("to", to));
    });

    if (dateAfter) params.append("from", dateAfter);
    if (dateBefore)
      params.append(
        "to",
        moment(dateBefore)
          .add(1, "day")
          .format("YYYY-MM-DD")
      );

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/payrollHourly`
    });

    const formatedData = res.data.content.reduce(
      (
        prev,
        {
          employeeId,
          employeeName,
          contractor,
          workingSeconds,
          currentRate,
          rate,
          ...restPayroll
        }
      ) => {
        if (prev[employeeId]) {
          prev[employeeId] = {
            ...prev[employeeId],
            workingSeconds: prev[employeeId].workingSeconds + workingSeconds,
            totalPay:
              prev[employeeId].totalPay + (workingSeconds / 3600) * rate,
            expandedData: [
              ...prev[employeeId].expandedData,
              { ...restPayroll, workingSeconds, rate }
            ]
          };

          return prev;
        }

        return {
          ...prev,
          [employeeId]: {
            employeeName,
            contractor,
            workingSeconds,
            currentRate,
            workingSeconds,
            totalPay: (workingSeconds / 3600) * rate,
            expandedData: [{ ...restPayroll, workingSeconds, rate }]
          }
        };
      },
      {}
    );

    await dispatch({
      type: actionType.SET_HOURLY_PAYROLLS_ALL_INFORMATION,
      payload: Object.values(formatedData)
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_FAIL,
      payload: error
    });
  }
};

export const fetchHourlyPayrollsSummary = (
  page,
  size,
  filters = {},
  sort,
  dateAfter,
  dateBefore
) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_HOURLY_PAYROLLS_SUMMARY_START });
    dispatch({ type: actionType.FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_START });

    const params = new URLSearchParams({
      page,
      size
    });

    Object.entries(filters).forEach(([name, value]) => {
      const from = value[0].slice(0, 10);
      const to = value[0].slice(-10);
      return name !== "seasons"
        ? Array.isArray(value)
          ? value.forEach(val => params.append(name, val))
          : params.append(name, value)
        : (params.append("from", from), params.append("to", to));
    });

    if (dateAfter) params.append("from", dateAfter);
    if (dateBefore)
      params.append(
        "to",
        moment(dateBefore)
          .add(1, "day")
          .format("YYYY-MM-DD")
      );

    sort.forEach(field => params.append("sort", field));
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `${url}/payrollHourlyTotal`
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.SET_HOURLY_PAYROLLS_SUMMARY,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.FETCH_HOURLY_PAYROLLS_SUMMARY_FAIL,
      payload: error
    });
    dispatch({ type: actionType.FETCH_HOURLY_PAYROLLS_ALL_INFORMATION_FAIL });
  }
};

export const fetchHourlyPayrollsExcelData = (
  dateAfter,
  dateBefore,
  filters
) => async () => {
  const { dateFrom, dateTo } = convertDatesToCertainRange(
    dateAfter,
    moment(dateBefore)
      .add(1, "day")
      .format("YYYY-MM-DD"),
    2,
    "months"
  );
  const params = new URLSearchParams({ unpaged: true });

  Object.entries(filters).forEach(([name, value]) => {
    const from = value[0].slice(0, 10);
    const to = value[0].slice(-10);
    return name !== "seasons"
      ? Array.isArray(value)
        ? value.forEach(val => params.append(name, val))
        : params.append(name, value)
      : (params.append("from", from), params.append("to", to));
  });

  dateAfter && params.append("from", dateFrom);
  dateBefore && params.append("to", dateTo);

  const res = await axiosProxy({
    method: "GET",
    params,
    url: `${url}/payrollHourly`
  });

  const dateRange = enumerateDaysBetweenDates(dateFrom, dateTo);
  const formatedData = res.data.content.reduce(
    (
      prev,
      { employeeId, employeeName, contractor, workingSeconds, rate, date }
    ) => {
      const prevEmployee = prev.values[employeeId];
      const payrollDate = moment(date).format("ddd DD/MM/YYYY");
      const workingHours = workingSeconds / 3600;
      const workedTimeByDate = {};
      const workedTimeColumns = [];

      if (prevEmployee && prevEmployee[payrollDate]) {
        prevEmployee[payrollDate] =
          prevEmployee[payrollDate] === 0
            ? workingHours
            : prevEmployee[payrollDate] + workingHours;
      } else {
        dateRange.forEach(item => {
          const dateFormatedColumnName = moment(item).format("ddd DD/MM/YYYY");
          workedTimeColumns.push(dateFormatedColumnName);
          workedTimeByDate[dateFormatedColumnName] =
            payrollDate === dateFormatedColumnName ? workingHours : 0;
        });
      }

      if (prevEmployee) {
        const { contractor, employeeName, ...dateColumnsValues } = prevEmployee;
        const dateColumnsValuesNew = { ...dateColumnsValues };
        for (let dateKey in workedTimeByDate) {
          dateColumnsValuesNew[dateKey] += workedTimeByDate[dateKey];
        }
        return {
          ...prev,
          values: {
            ...prev.values,
            [employeeId]: {
              ...dateColumnsValuesNew,
              contractor,
              employeeName,
              totalWorkingTime: prevEmployee.totalWorkingTime + workingHours,
              rates: prevEmployee.rates.includes(rate)
                ? prevEmployee.rates
                : [...prevEmployee.rates, rate],
              totalPay: prevEmployee.totalPay + workingHours * rate
            }
          },
          totalPaySum: prev.totalPaySum + workingHours * rate
        };
      }

      return {
        ...prev,
        values: {
          ...prev.values,
          [employeeId]: {
            employeeName,
            contractor,
            ...workedTimeByDate,
            totalWorkingTime: workingHours,
            rates: [rate],
            totalPay: workingHours * rate
          }
        },
        columns: [
          "Employee Name",
          "Contractor",
          ...workedTimeColumns,
          "Total Working Time",
          "Rates ($/H)",
          "Total Pay"
        ],
        totalPaySum: prev.totalPaySum + workingHours * rate
      };
    },
    { values: {}, columns: [], totalPaySum: 0 }
  );
  return {
    ...formatedData,
    values: [
      ...Object.values(formatedData.values).map(
        ({
          employeeName,
          contractor,
          totalWorkingTime,
          rates,
          totalPay,
          ...rest
        }) => {
          return {
            employeeName,
            contractor,
            ...Object.keys(rest).reduce(
              (prev, key) => ({
                ...prev,
                [key]:
                  rest[key] > 0 ? parseFloat(rest[key]).toFixed(2) : rest[key]
              }),
              rest
            ),
            totalWorkingTime: parseFloat(totalWorkingTime).toFixed(2),
            rates: rates.join("|"),
            totalPay: `$ ${totalPay.toFixed(2)}`
          };
        }
      ),
      {
        totalPay: `$ ${formatedData.totalPaySum.toFixed(2)}`
      }
    ],
    fileName: `${dateAfter ? dateFrom.replaceAll("-", ".") : "any time"}-${
      dateBefore ? dateTo.replaceAll("-", ".") : "any time"
    }`
  };
};

export const pageChecker = check => dispatch => {
  dispatch({ type: actionType.CHECK_REPORT_PAGE, payload: check });
};
