import React, { useState, useEffect } from "react";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import { Icon, Input } from "semantic-ui-react";
import "./DateRangePicker.css";

const DateRangePickerComponent = ({ setFieldValue, dateStart, dateEnd }) => {
  const [dates, setDates] = useState([]);
  useEffect(() => {
    if (dateStart && dateEnd) {
      const changeDate = [
        new Date(moment(dateStart).format("YYYY/MM/DD")),
        new Date(moment(dateEnd).format("YYYY/MM/DD"))
      ];
      setDates(changeDate);
    }
  }, []);
  const handleDatesChange = (a, b) => {
    const start = b.validatedValue[0];
    const end = b.validatedValue[1];
    return (
      setFieldValue(
        "start",
        `${moment(start, "DD/MM/YYYY").format("YYYY-MM-DD")}T00:00:00`
      ),
      setFieldValue(
        "end",
        `${moment(end, "DD/MM/YYYY").format("YYYY-MM-DD")}T23:59:59`
      )
    );
  };
  return (
    <DatePicker
      className="range-calendar"
      value={dates}
      onChange={(a, b) => handleDatesChange(a, b)}
      range
      format={"DD/MM/YYYY"}
      calendarPosition="bottom-center"
      numberOfMonths={2}
      render={
        <Input
          placeholder={"dd/mm/yyyy-dd/mm/yyyy"}
          icon={<Icon name="calendar outline" />}
          iconPosition="right"
        />
      }
    />
  );
};

export default DateRangePickerComponent;
