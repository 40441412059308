import React, { useState, useRef, useEffect } from "react";
import styles from "./TasksCustomization.module.css";
import customizationIcon from "assets/img/customizationIcon.svg";
import { Icon, Container, Button } from "semantic-ui-react";
import TypeDropdown from "./Dropdown";
import HeaderMain from "components/HeaderMain/HeaderMain";
import { connect, useDispatch } from "react-redux";
import { fetchTaskTypeList } from "actions/GeneralTasks/generalTasks";
import { addSelectedTaskType } from "actions/Tasks";

const TaskCustomization = ({
  route,
  tasksType,
  allowedGeneralTaskTypeIds,
  selectedGeneralTaskType,
  navigate
}) => {
  const [selectedItems, setSelectedItems] = useState(
    selectedGeneralTaskType.length
      ? selectedGeneralTaskType
      : [
          {
            key: 0,
            text: "All",
            value: 0
          }
        ]
  );
  const scrollContainer = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    !tasksType.length && dispatch(fetchTaskTypeList());
  }, []);
  const options = [
    {
      key: 0,
      text: "All",
      value: 0
    },
    ...tasksType
      .filter(type => allowedGeneralTaskTypeIds.includes(type.id))
      .map(item => ({
        key: item.id,
        text: item.name,
        value: item.id
      }))
  ];

  const handleMouseWheelScroll = e => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += e.deltaY;
    }
  };

  const handleItemDelete = item => {
    setSelectedItems(selectedItems.filter(i => i.value !== item.value));
  };

  const handleDropdownChange = item => {
    selectedItems.some(t => t.value === item.value)
      ? setSelectedItems(selectedItems.filter(i => i.value !== item.value))
      : setSelectedItems([...selectedItems, item]);
  };

  const handleClearSelection = () => {
    setSelectedItems([]);
  };

  const handleDragStart = (e, item) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(item)); // Convert item to string
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const draggedItem = JSON.parse(e.dataTransfer.getData("text/plain")); // Parse back to object
    const newItems = [...selectedItems];
    const draggedValue = draggedItem.value; // Get the value of the dragged item
    const oldIndex = newItems.findIndex(item => item.value === draggedValue); // Find index using value
    if (oldIndex > -1) {
      newItems.splice(oldIndex, 1);
    }
    newItems.splice(index, 0, draggedItem);
    setSelectedItems(newItems);
  };

  return (
    <Container id="el" fluid className={styles.root}>
      <HeaderMain route={route} menuOpen={false} online={true} />
      <div className={styles.contentWrapper}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>Task Customization</div>
          <Button
            className={styles.doneBtn}
            content="Done"
            onClick={() => {
              dispatch(addSelectedTaskType(selectedItems));
              navigate("/tasks");
            }}
          />
        </div>
        <div className={styles.customizationContainer}>
          <div className={styles.infoMessage}>
            <img
              className={styles.dragIcon}
              src={customizationIcon}
              alt="Drag icon"
            />{" "}
            Drag to organise your task preferences
          </div>
          <div id="typeContainer" className={styles.typeContainer}>
            <span
              style={{ overflowX: "scroll" }}
              ref={scrollContainer}
              onWheel={handleMouseWheelScroll}
              className={`${styles.typeWrapper} ${styles.horizontalScroll}`}
            >
              {selectedItems.map((item, index) => (
                <div
                  key={item.value}
                  draggable
                  onDragStart={e => handleDragStart(e, item)}
                  onDragOver={handleDragOver}
                  onDrop={e => handleDrop(e, index)}
                  className={`${styles.type} ${styles.item}`}
                >
                  {item?.text}
                  <Icon
                    onClick={() => handleItemDelete(item)}
                    className={styles.closeIcon}
                    name="close"
                  />
                </div>
              ))}
            </span>
            <div>
              <TypeDropdown
                handleClearSelection={handleClearSelection}
                selectedItems={selectedItems}
                options={options}
                onChange={handleDropdownChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default connect((state, props) => {
  return {
    tasksType: state.generalTasks.tasksType,
    allowedGeneralTaskTypeIds: state.settings.data.allowedGeneralTaskTypeIds,
    selectedGeneralTaskType: state.newTasks.selectedGeneralTaskType
  };
})(TaskCustomization);
