import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styles from "./FarmsRecords.module.css";
import { Dropdown, Grid, Header, Segment, Sidebar } from "semantic-ui-react";
import Layout from "../../../components/Layout";
import FlattenRecordsView from "../../../components/FlattenRecordsView";
import { fetchAreas } from "../../../actions/Areas/areas";
import { getParentPageHref } from "../../../utils/routeHelpers";

const coercion = [
  {
    test: (key, value) => key === "parent",
    transform: (value) => value && value.id,
  },
];

const filters = [
  {
    test: function(key, value) {
      return key === "geojson";
    },
  },
];
const options = { filters: filters, coercion: coercion };

const FarmsRecords = ({
  route,
  location,
  content,
  isFetching,
  actions,
  navigate,
}) => {
  const handelRawDataViewClick = useCallback(() => {
    navigate(getParentPageHref(route));
  }, [route]);
  return (
    <Layout route={route} location={location} classForMain={styles.mainHolder}>
      <Sidebar.Pushable className={styles.farmsHolder}>
        <Sidebar.Pusher>
          <Segment basic className={styles.headerTasks}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header as="h2" className={styles.header}>
                    Areas records
                    <Dropdown
                      text={" "}
                      floating
                      className={styles.dropdownHeaderMenu}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handelRawDataViewClick}>
                          List view
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment>
            <FlattenRecordsView
              navigate={navigate}
              name={route.name}
              data={content}
              getData={actions.fetchAreas}
              options={options}
              isFetching={isFetching}
            />
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Layout>
  );
};

FarmsRecords.propTypes = {
  content: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  route: PropTypes.object,
  location: PropTypes.object,
  actions: PropTypes.object,
};

FarmsRecords.defaultProps = {};

function mapStateToProps({
  areas: {
    data: { content, isFetching },
  },
}) {
  return { content, isFetching };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchAreas }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmsRecords);
