import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Header, Button, Icon } from "semantic-ui-react";
import {
  closeMessage,
  sendNotification,
} from "../../../actions/Notifications/notifications";
import { isEmptyValue } from "../../../utils/tasksUtils";
import TaskRow from "../../SprayDiary/TaskDetailsMobile/TaskRow";
import styles from "./Sidebar.module.css";
import { Link } from "react-router-dom";
const Sidebar = ({ packingShed, id, setActiveItem }) => {
  return packingShed ? (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column className={styles.wrapper} width={16}>
            <Header as="h1" className={styles.name}>
              {packingShed.name}
            </Header>

            <div className={styles.btnWrapper}>
              <Button
                as={Link}
                color="green"
                to={`/harvest/packing_sheds/add_packing_sheds/${id}`}
              >
                Edit
              </Button>
              <Icon
                className={styles.closeIcon}
                name="close"
                onClick={() => setActiveItem(null)}
              />
            </div>
          </Grid.Column>
          <div>{packingShed.registrationNumber}</div>
        </Grid.Row>
      </Grid>
      <Header>Address</Header>
      <Grid>
        <TaskRow
          label="Country"
          name="country"
          value={<span>{isEmptyValue(packingShed.country)}</span>}
        />
        <TaskRow
          label="State"
          name="state"
          value={<span>{isEmptyValue(packingShed.state)}</span>}
        />
        <TaskRow
          label="City"
          name="city"
          value={<span>{isEmptyValue(packingShed.city)}</span>}
        />
        <TaskRow
          label="Street"
          name="street"
          value={<span>{isEmptyValue(packingShed.street)}</span>}
        />
        <TaskRow
          label="House Number"
          name="houseNumber"
          value={<span>{isEmptyValue(packingShed.houseNumber)}</span>}
        />
        <TaskRow
          label="Zip"
          name="zip"
          value={<span>{isEmptyValue(packingShed.zip)}</span>}
        />
      </Grid>
    </>
  ) : (
    <div>Nothing to show</div>
  );
};

Sidebar.propTypes = {
  packingShed: PropTypes.object,
};
Sidebar.defaultProps = {
  packingShed: {},
};

const mapStateToProps = (state) => {
  const {
    notifications: { isSending, success, error, showMessage },
  } = state;
  return { notifications: { isSending, success, error, showMessage } };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        sendNotification,
        closeMessage,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
