import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { deleteBoxUnit, fetchBoxUnits } from "actions/BoxUnits/boxUnits";
import { abilitiesSelector } from "selectors/user";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import { chemicalShape } from "constants/Chemicals/types";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import { getBoxUnitsTableColumns } from "utils/boxUnitsNew";
import { Button } from "semantic-ui-react";
import FlattenRecordsView from "components/FlattenRecordsView/FlattenRecordsView";

const BoxUnits = ({
  Can,
  location,
  route,
  isFetching,
  tableData,
  filterData,
  navigate
}) => {
  const [flatten, setFlatte] = useState(false);
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  const dispatch = useDispatch();
  const getData = useCallback(
    params => {
      setSelectedItems([]);
      dispatch(fetchBoxUnits({ ...params }));
    },
    [fetchBoxUnits, filterData]
  );
  const onRowClick = useCallback(
    (event, row) => {
      if (!activeItem && row) {
        setActiveItem(row.original);
      }
    },
    [activeItem]
  );
  const onDelete = id => {
    dispatch(deleteBoxUnit(id));
  };
  const tableColumns = getBoxUnitsTableColumns(isFetching, navigate, onDelete);

  return (
    <>
      {
        <TablePageHolder
          mainButton={
            <Button
              as={Link}
              color="green"
              to={"/harvest/box_units/add_box_units"}
            >
              Add
            </Button>
          }
          getData={getData}
          navigate={navigate}
          isFetching={isFetching}
          location={location}
          withoutSearch={false}
          onRowClick={onRowClick}
          onSidebarHidden={() => setActiveItem(null)}
          sidebarShown={!!activeItem}
          pageTitle={route.name}
          route={route}
          withSelection={false}
          selectedItems={selectedItemsIds}
          setSelectedItems={setSelectedItems}
          flatten={flatten}
          flattenComponent={
            <FlattenRecordsView
              name="Stock"
              data={tableData.content}
              options={{}}
              getData={getData}
              isFetching={isFetching}
            />
          }
          setFlatte={setFlatte}
          withFlatten={true}
          sidebarComponent={
            <Sidebar
              id={activeItem && activeItem.id}
              boxUnit={activeItem}
              setActiveItem={setActiveItem}
            />
          }
          tableColumns={tableColumns}
          tableData={tableData || []}
        />
      }
    </>
  );
};

BoxUnits.propTypes = {
  Can: PropTypes.func,
  isArchivedView: PropTypes.bool,
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(chemicalShape)
  }).isRequired,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  totalElements: PropTypes.number,
  fetchChemicals: PropTypes.func,
  isFetching: PropTypes.bool,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};

export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  return {
    isArchivedView,
    Can: abilitiesSelector(state),
    tableData: state.boxUnits.data,
    isFetching: state.boxUnits.isFetching
  };
})(BoxUnits);
