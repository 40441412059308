import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/Pallets";
import { actionType as stockActionType } from "../../constants/Stock";
import { genericErrors } from "../InfoMessages";
import uuid from "uuid/v4";

const url = "/pallets";
let uniqId;
export const fetchPalletsList = () => async (dispatch) => {
  try {
    dispatch({
      type: actionType.FETCH_PALLETS_LIST_START,
    });

    const res = await axiosProxy({
      method: "GET",
      url: `${url}`,
      params: {
        unpaged: true,
      },
    });

    dispatch({
      type: actionType.FETCH_PALLETS_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_PALLETS_LIST_FAIL,
      payload: error,
    });
  }
};

export const fetchPallets = ({
  page,
  size,
  search,
  sort,
  filters = [],
  unpaged = false,
}) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.FETCH_PALLETS_START,
    });

    const params = new URLSearchParams(filters);

    if (unpaged) {
      params.append("unpaged", true);
    } else {
      params.append("page", page);
      params.append("size", size);
    }
    search && params.append("search", search);

    (sort || ["id,asc"]).forEach((field) => {
      params.append("sort", field);
    });
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url,
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.FETCH_PALLETS,
        payload: res.data,
      });
    }
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_PALLETS_FAIL,
      payload: error,
    });
  }
};

export const fetchPalletsNotDispatched = (params) =>
  fetchPallets({ ...params, filters: [["isAllocatedToDispatch", "false"]] });

export const getPalletById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.GET_PALLETS_BY_ID_START,
    });
    const res = await axiosProxy({
      method: "GET",
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.GET_PALLETS_BY_ID,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GET_PALLETS_BY_ID_FAIL,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const addPallet = (data) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.ADD_PALLETS_START,
    });
    const res = await axiosProxy({
      method: "POST",
      url,
      data,
    });

    await dispatch({
      type: actionType.ADD_PALLETS,
      payload: res.data,
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_PALLETS_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const addBatchPallet = (data) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.ADD_BATCH_PALLETS_START,
    });
    const res = await axiosProxy({
      method: "POST",
      url: `${url}/batch`,
      data,
    });

    await dispatch({
      type: actionType.ADD_BATCH_PALLETS,
      payload: res.data.pallets,
    });

    if (res.data.stockItem) {
      await dispatch({
        type: stockActionType.UPDATE_STOCK,
        payload: res.data.stockItem,
      });
    }

    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.ADD_BATCH_PALLETS_FAIL,
      payload: error,
    });

    if (
      error.response &&
      error.response.data &&
      error.response.data.code === genericErrors.NO_UNITS_IN_STOCK &&
      error.response.data.body
    ) {
      await dispatch({
        type: stockActionType.UPDATE_STOCK,
        payload: error.response.data.body,
      });
    }

    return { error };
  }
};

export const updatePallet = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.UPDATE_PALLETS_START,
    });
    const res = await axiosProxy({
      method: "PUT",
      data,
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.UPDATE_PALLETS,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_PALLETS_FAIL,
      payload: error,
    });
    return { error };
  }
};

export const deletePallet = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionType.DELETE_PALLETS_START,
    });
    await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`,
    });

    await dispatch({
      type: actionType.DELETE_PALLETS,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: actionType.DELETE_PALLETS_FAIL,
      payload: error,
    });
  }
};

export const setPrintLayout = (printLayout) => (dispatch) => {
  dispatch({
    type: actionType.PALLETS_SET_PRINT_LAYOUT,
    payload: printLayout,
  });
};
export const clearError = () => (dispatch) => {
  dispatch({
    type: actionType.CLEAR_PALLETS_ERROR,
  });
};
