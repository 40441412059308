import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import "react-table-6/react-table.css";
import ListHolder from "../../components/ListHolder";
import { getClientsList } from "../../actions/Clients";
import { abilitiesSelector } from "../../selectors/user";

const PAGE_SIZE = 10;

class Clients extends Component {
  state = {
    tableData: [],
    tableColumns: [],
    sideBarShown: false,
  };

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;
    if (prevProps.data !== data) this.prepareData();
  }

  prepareData = () => {
    const { data } = this.props;
    const columnsNames = {
      id: {
        title: "ID",
        width: 16,
      },
    };
    const tableColumns = [];
    const tableData = [];

    Object.keys(columnsNames).forEach((column) => {
      const targetColumn = columnsNames[column];
      const columnToAdd = {
        id: column,
        accessor: targetColumn.accessor || column,
        Header: targetColumn.title,
        Cell: this.renderCell,
        className: `cell_${targetColumn.width} ${targetColumn.className || ""}`,
        ...(targetColumn.sortFields
          ? { sortFields: targetColumn.sortFields }
          : {}),
      };

      tableColumns.push(columnToAdd);
    });

    if (data && data.content) {
      data.content.forEach((row) => {
        const rowData = {
          rights: {
            update: "update",
            delete: "delete",
          },
        };
        Object.keys(row).forEach((key) => {
          switch (key) {
            case "ratePerHour":
              rowData[key] = row[key] >= 0 ? `$${row[key].toFixed(2)}` : "";
              break;
            case "size":
              rowData[key] =
                row[key] >= 0
                  ? `${row[key]} ${row.sizeUnit === "LITER" ? "L" : "Kg"}`
                  : "";
              break;
            default:
              rowData[key] = row[key] || "";
              break;
          }
        });
        tableData.push(rowData);
      });
    }

    this.setState({
      tableData,
      tableColumns,
    });
  };

  renderCell = (props) => {
    const {
      value,
      column: { Header },
    } = props;

    return (
      <div>
        <div className="cellTitle hide-md">{Header}</div>
        <div className="cellValue">{value}</div>
      </div>
    );
  };

  render() {
    const {
      route,
      location,
      machinery,
      currentPage,
      totalPages,
      isFetching,
      Can,
      actions,
      size,
      totalElements,
      error,
      online,
      navigate
    } = this.props;

    const { tableData, tableColumns } = this.state;
    return (
      <ListHolder
        navigate={navigate}
        content={machinery}
        accessName="clients"
        Can={Can}
        addNewPath={"/clients/add_new_client"}
        route={route}
        location={location}
        currentPage={currentPage}
        size={size || PAGE_SIZE}
        totalPages={totalPages}
        data={tableData}
        columns={tableColumns}
        getData={actions.getClientsList}
        isFetching={isFetching && !totalPages}
        totalElements={totalElements}
        withDateRange={false}
        error={error}
        online={online}
        defaultSort="id,asc"
      />
    );
  }
}

Clients.propTypes = {
  machinery: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastLoaded: PropTypes.bool,
  data: PropTypes.object,
  actions: PropTypes.object,
  error: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  data: state.clients.data,
  isFetching: state.clients.data.isFetching,
  currentPage: state.clients.data.number,
  totalPages: state.clients.data.totalPages,
  totalElements: state.clients.data.totalElements,
  size: state.clients.data.size,
  lastLoaded: state.clients.data.last,
  Can: abilitiesSelector(state),
  error: state.clients.error,
  online: state.offline.online,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getClientsList,
      },
      dispatch
    ),
  };
};

const connectedClients = connect(mapStateToProps, mapDispatchToProps)(Clients);

export default connectedClients;
