import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Grid, Icon, Input } from "semantic-ui-react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./SelectPalletsTable.module.css";
import DatePicker from "../../../../../../components/DatePicker";
import SortDropdown from "../../../../../../components/SortDropdown";
import ExpandedTable from "../../../../../../components/ExpandedTable";
import Pagination from "../../../../../../components/ListTable/Pagination";

export const renderCell = (props) => {
  const {
    value,
    column: { Header },
  } = props;

  return (
    <div>
      <div className="cellTitle hide-md">{Header}</div>
      <div className="cellValue">{value}</div>
    </div>
  );
};

renderCell.propTypes = {
  value: PropTypes.any,
  column: PropTypes.object,
};

export const renderCellMobile = (props) => {
  const { value } = props;

  return (
    <div>
      <div className="cellValue">{value}</div>
    </div>
  );
};

renderCellMobile.propTypes = {
  value: PropTypes.any,
};

const generatePagination = (pagination, setPageSize, gotoPage) => (
  <Pagination
    {...pagination}
    pageSizeOptions={[10, 20, 50, 100]}
    updatePageSize={(_, { value: pageSize }) => setPageSize(pageSize)}
    onPageChangeOwn={(currentPage, loadMore) => {
      loadMore
        ? setPageSize(currentPage * pagination.pageSize + pagination.pageSize)
        : gotoPage(currentPage);
    }}
  />
);

const SelectPalletsTable = ({
  columns,
  data,
  getData,
  loading,
  searchColumn,
  showFilters,
  defaultSort,
  defaultGroupBy,
  dynamicGrouping,
  withDateRange,
  withRowSelect,
  onSelectPallets,
  navigate,
}) => {
  const [filters, setFilters] = useState({});
  const [selectedPallets, setSelectedPallets] = useState([]);
  const [sortBy, setSortBy] = useState(
    (defaultSort && {
      id: defaultSort.split(",")[0],
      desc: defaultSort.split(",")[1] === "desc",
    }) ||
      null
  );

  const [dateFrom, setDateFrom] = useState(
    moment(moment.now())
      .subtract(1, "days")
      .format("YYYY-MM-DD")
  );
  const [dateTo, setDateTo] = useState(
    moment(moment.now()).format("YYYY-MM-DD")
  );

  const refresh = useCallback(
    () => getData({ from: dateFrom, to: dateTo, unpaged: true }),
    [getData, dateFrom, dateTo]
  );

  useEffect(() => {
    refresh();
    //eslint-disable-next-line
  }, []);

  const tableColumns = useMemo(
    () =>
      columns.map((column) => {
        return {
          accessor: column.id,
          Cell: renderCell,
          ...column,
          className: `cell_${column.width} ${column.className || ""}`,
        };
      }),
    [columns]
  );

  const sortOptions = useMemo(() => {
    return tableColumns
      .filter(
        (column) =>
          column.accessor !== "actions" && column.Header && !column.disableSort
      )
      .map((column) => ({
        key: column.id,
        text: `Sort by ${column.Header}`,
        value: column.id,
        content: column.Header,
        sortfields: column.sortFields || [column.id],
      }));
  }, [tableColumns]);

  const onSortChange = useCallback(({ sortValue, sortOrder, sort }) => {
    setSortBy(
      sort.length > 0 ? { id: sortValue, desc: sortOrder === "desc" } : null
    );
  }, []);

  const sortByArray = useMemo(() => sortBy && [sortBy], [sortBy]);

  const setFilter = useCallback(
    (name, value) => setFilters({ ...filters, [name]: value }),
    [filters, setFilters]
  );

  const filtersArray = useMemo(
    () => Object.keys(filters).map((key) => ({ id: key, value: filters[key] })),
    [filters]
  );

  const onSelectedRowsChanged = useCallback((selectedRows) => {
    setSelectedPallets(selectedRows);
  }, []);

  return (
    <Grid verticalAlign="middle">
      {withDateRange && (
        <Grid.Row verticalAlign="bottom">
          <Grid.Column mobile={16} tablet={10} computer={4}>
            <DatePicker
              value={dateFrom}
              onChange={(date) => setDateFrom(date)}
              labelText={"From"}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={10} computer={4}>
            <DatePicker
              value={dateTo}
              onChange={(date) => setDateTo(date)}
              labelText={"To"}
            />
          </Grid.Column>
          <Grid.Column mobile={8} tablet={10} computer={3}>
            <Button onClick={refresh} size={"large"} type="button" fluid>
              <Icon name="repeat" />
              <span>Refresh</span>
            </Button>
          </Grid.Column>
          <Grid.Column mobile={8} tablet={10} computer={5}>
            <Button
              type={"button"}
              disabled={selectedPallets.length === 0}
              size={"large"}
              fluid
              primary
              onClick={() => onSelectPallets(selectedPallets)}
            >
              Select pallets ({selectedPallets.length})
            </Button>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div className={styles.filtersHolder}>
            <Input
              icon="search"
              placeholder="Search"
              onChange={(e) => setFilter(searchColumn, e.target.value)}
              className={styles.filterItem}
              size={"large"}
            />
            {columns
              .filter((column) => column.ExternalFilter)
              .map((column) => (
                <column.ExternalFilter
                  key={`filter_${column.id}`}
                  value={filters[column.id]}
                  className={styles.filterItem}
                  setFilter={(value) => setFilter(column.id, value)}
                  fulid={false}
                />
              ))}
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          verticalAlign={"bottom"}
          mobile={16}
          tablet={16}
          computer={16}
          textAlign="right"
          className={styles.sortColumn}
        >
          {sortOptions && sortOptions.length > 0 && (
            <SortDropdown
              navigate={navigate}
              sortOptions={sortOptions}
              onChange={onSortChange}
              sortValue={sortBy ? sortBy.id : "none"}
              sortOrder={sortBy && sortBy.desc ? "desc" : "asc"}
              defaultSort={defaultSort}
            />
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={16}
          largeScreen={16}
          widescreen={16}
        >
          <ExpandedTable
            data={data}
            columns={tableColumns}
            className={`-striped ${styles.listTable}`}
            pagination={generatePagination}
            showFilters={showFilters}
            dynamicGrouping={dynamicGrouping}
            loading={loading}
            sortBy={sortByArray}
            groupBy={defaultGroupBy}
            filters={filtersArray}
            withRowSelect={withRowSelect}
            defaultExpandedLevel={defaultGroupBy.length - 1}
            noDataText="No data available"
            onSelectedRowsChanged={onSelectedRowsChanged}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SelectPalletsTable.propTypes = {
  columns: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showFilters: PropTypes.bool,
  defaultGroupBy: PropTypes.any,
  loading: PropTypes.bool,
  searchColumn: PropTypes.string,
  onSelectPallets: PropTypes.func,
  defaultSort: PropTypes.string.isRequired,
  withDateRange: PropTypes.bool,
  withRowSelect: PropTypes.bool,
  exportableToExcel: PropTypes.bool,
  dynamicGrouping: PropTypes.bool,
};

SelectPalletsTable.defaultProps = {
  withDateRange: true,
  exportableToExcel: false,
  isFetching: false,
  withRowSelect: false,
  getData: () => {},
  defaultSort: "",
  showFilters: false,
  dynamicGrouping: false,
};

export default SelectPalletsTable;
