import React, { useState } from "react";
import { Icon, Segment, Checkbox, Form, Label, Input } from "semantic-ui-react";
import { Field } from "formik";
import moment from "moment";
import Datetime from "react-datetime";
import styles from "../../AddInterval.module.css";
import { useSelector } from "react-redux";
import {
  calculateTimeDifference,
  employeeViewer
} from "routes/Tasks/components/helper";
import AreasList from "routes/WorkingTime/components/AreasList";
import { generalTasksAreas } from "selectors/generalTasks";

const LabourGroup = ({
  employee,
  laborGroups,
  values,
  setFieldValue,
  setValues,
  areas
}) => {
  const [openRows, setOpenRows] = useState({});
  const [opengroup, setOpenGroup] = useState({});
  const [checkedItems, setCheckedItems] = useState({});
  const handleCheckClick = employeeId => {
    setCheckedItems(prevState => ({
      ...prevState,
      [employeeId]: !prevState[employeeId]
    }));
  };
  const tasksAreas = useSelector(generalTasksAreas);
  const areasOptions = values.generalTaskId
    ? tasksAreas[values.generalTaskId]
    : [];
  const toggleRow = id => {
    setOpenRows(prevOpenRows => ({
      ...prevOpenRows,
      [id]: !prevOpenRows[id]
    }));
  };
  const toggleGroupRow = id => {
    setOpenGroup(prevOpenRows => ({
      ...prevOpenRows,
      [id]: !prevOpenRows[id]
    }));
  };
  return (
    <>
      <div className={styles.wrapper}>
        {laborGroups
          .filter(i => i.employeeIds.length)
          .map(item => {
            const activeEmployee = employee.filter(e =>
              item.employeeIds.includes(e.id)
            );

            return (
              <div>
                <div key={item.id} className={styles.groupRow}>
                  <div className={styles.checkboxWrapper}>
                    {values.children.filter(i => i.employeesGroupId === item.id)
                      ?.length === item.employeeIds.length ||
                    !values.children.filter(i => i.employeesGroupId === item.id)
                      ?.length ? (
                      <Checkbox
                        onClick={() => {
                          values.children.filter(
                            i => i.employeesGroupId === item.id
                          )?.length === item.employeeIds.length
                            ? setFieldValue(
                                "children",
                                values.children.filter(i => {
                                  return !item.employeeIds.includes(
                                    i.employeeId
                                  );
                                })
                              )
                            : setFieldValue("children", [
                                ...values.children,
                                ...item.employeeIds.map(i => ({
                                  employeeId: i,
                                  employeesGroupId: item.id,
                                  generalTaskId: values.generalTaskId,
                                  areas: values.areas,
                                  lunchInterval: values.lunchInterval,
                                  startAt: values.startAt,
                                  endAt: values.endAt
                                }))
                              ]);
                        }}
                        className={styles.checkBox}
                        checked={
                          item.employeeIds.length &&
                          item.employeeIds.every(i =>
                            values.children.map(e => e.employeeId).includes(i)
                          )
                        }
                      />
                    ) : (
                      <div
                        onClick={() => {
                          setFieldValue(
                            "children",
                            values.children.filter(i => {
                              return !item.employeeIds.includes(i.employeeId);
                            })
                          );
                        }}
                        className={styles.minusIconWrapper}
                      >
                        <Icon name="minus" className={styles.minusIcon} />
                      </div>
                    )}

                    {`${item.name}`}
                  </div>
                  <span className={styles.iconRowField}>
                    <Icon
                      className={styles.rowChevronIcon}
                      onClick={() => toggleGroupRow(item.id)}
                      name={opengroup[item.id] ? "chevron up" : "chevron down"}
                    />
                  </span>
                </div>
                {opengroup[item.id] &&
                  activeEmployee.map(i => {
                    return (
                      <div className={styles.subRows}>
                        <div className={styles.checkboxWrapper}>
                          <Checkbox
                            onClick={() => {
                              values.children
                                .map(e => e.employeeId)
                                .includes(i.id)
                                ? setFieldValue(
                                    "children",
                                    values.children.filter(
                                      e => e.employeeId !== i.id
                                    )
                                  )
                                : setFieldValue("children", [
                                    ...values.children,
                                    {
                                      employeeId: i.id,
                                      employeesGroupId: i.employeeGroup.id,
                                      generalTaskId: values.generalTaskId,
                                      areas: values.areas,
                                      lunchInterval: values.lunchInterval,
                                      startAt: values.startAt,
                                      endAt: values.endAt
                                    }
                                  ]);
                            }}
                            className={styles.checkBox}
                            checked={values.children
                              .map(e => e.employeeId)
                              .includes(i.id)}
                          />
                          {i.firstName} {i.lastName}
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
      {values.children.map((item, index) => {
        const activeEmployee = employee.find(e => e.id === item.employeeId);
        const isChecked = checkedItems[item.employeeId] || false;
        const detailsHoursDifference =
          item?.startAt &&
          item?.endAt &&
          calculateTimeDifference(
            moment(item?.startAt).format("HH:mm"),
            moment(item?.endAt).format("HH:mm"),
            item?.lunchInterval
          );
        const start = moment(item?.startAt);
        let end = moment(item?.endAt);

        if (end.isBefore(start)) {
          end.add(1, "day");
        }

        const diffHours = end.diff(start, "hours", true);

        return (
          <Segment key={item.employeeId} className={styles.segment}>
            <div className={styles.rowFieldWrapper}>
              <div className={styles.employeeIconWrapper}>
                <span className={styles.iconRowField}>
                  {!isChecked ? (
                    <div className={styles.notSelectedIconWrapper}>
                      <span
                        onClick={() => {
                          return (
                            handleCheckClick(item.employeeId),
                            setFieldValue(`children[${index}]checked`, true)
                          );
                        }}
                        className={styles.notSelectedIcon}
                      />
                    </div>
                  ) : (
                    <div className={styles.selectedIconWrapper}>
                      <Icon
                        onClick={() => {
                          return (
                            setFieldValue(`children[${index}]checked`, false),
                            handleCheckClick(item.employeeId)
                          );
                        }}
                        name="check"
                        className={styles.selectedIcon}
                      />
                    </div>
                  )}
                </span>
                <span className={styles.nameRowField}>
                  {employeeViewer([{ employeeId: item.employeeId }], employee)}
                  {`${activeEmployee.firstName} ${activeEmployee.lastName}`}
                </span>
              </div>
              <span className={styles.iconRowField}>
                <Icon
                  onClick={() =>
                    setFieldValue(
                      "children",
                      values.children.filter(
                        i => i.employeeId !== item.employeeId
                      )
                    )
                  }
                  className={styles.deleteIcon}
                  name="trash alternate"
                />
              </span>
            </div>
            <div className={styles.openRows}>
              <Form.Group widths={16}>
                <Form.Field required width={16}>
                  <label className={styles.formLabel}>Areas </label>
                  <AreasList
                    text={
                      !!values.children[index]?.areas?.length &&
                      `${values.children[index]?.areas?.length} areas`
                    }
                    onChange={(_, data) => {
                      return setFieldValue(`children[${index}].areas`, [
                        ...values.children[index]?.areas,
                        data
                      ]);
                    }}
                    options={areasOptions.filter(
                      i => !values.children[index]?.areas.includes(i.value)
                    )}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={16}>
                  <Form.Group>
                    {!!values.children[index]?.areas?.length && (
                      <Form.Field>
                        <div className={styles.areasRow}>
                          <span className={styles.areasRowHead}>Areas:</span>
                          {values.children[index]?.areas?.map(item => {
                            return areas.map(({ id, name, parent }) => {
                              return (
                                id === (item?.areaId || item) && (
                                  <Label className={styles.addedItem}>
                                    <span className={styles.addedItemValue}>
                                      {" "}
                                      {parent
                                        ? `${parent.name}/ ${name}`
                                        : name}
                                    </span>
                                    <Icon
                                      className={styles.closeIcon}
                                      name="close"
                                      onClick={() => {
                                        const filteredValues = values.children[
                                          index
                                        ]?.areas?.filter(i => item !== i);
                                        setFieldValue(
                                          `children[${index}].areas`,
                                          filteredValues
                                        );
                                      }}
                                    />
                                  </Label>
                                )
                              );
                            });
                          })}
                        </div>
                      </Form.Field>
                    )}
                  </Form.Group>
                </Form.Field>
              </Form.Group>
              <Form.Group widths={16}>
                <Form.Field required width={8}>
                  <label className={styles.formLabel}>Clock in</label>
                  <div className={styles.calendarWrapper}>
                    <Datetime
                      value={
                        values.children[index].startAt
                          ? moment(values.children[index].startAt)
                          : null
                      }
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      inputProps={{
                        placeholder: "dd/mm/yyyy hh:mm",
                        readOnly: false
                      }}
                      onChange={selectedDate => {
                        const formattedDate = selectedDate
                          ? moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss")
                          : null;
                        setFieldValue(
                          `children[${index}].startAt`,
                          formattedDate
                        );
                      }}
                      className={styles.expirCalendar}
                    />
                    <div className={styles.calendarIcon}>
                      <Icon name="calendar outline" />
                    </div>
                  </div>
                </Form.Field>
                <Form.Field width={8} className={styles.formField}>
                  <label>Working time</label>
                  <Field
                    as={Input}
                    disabled={true}
                    icon={<Icon name="clock outline" />}
                    className={styles.disabledField}
                    name="row"
                    value={
                      detailsHoursDifference
                        ? `${detailsHoursDifference?.hoursDifference}h ${detailsHoursDifference?.minutesDifference}mm`
                        : "0h 00mm"
                    }
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={16}>
                <Form.Field required width={8}>
                  <label className={styles.formLabel}>Clock out</label>
                  <div className={styles.calendarWrapper}>
                    <Datetime
                      value={
                        values.children[index].endAt
                          ? moment(values.children[index].endAt)
                          : null
                      }
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm"
                      inputProps={{
                        placeholder: "dd/mm/yyyy hh:mm",
                        readOnly: false
                      }}
                      onChange={selectedDate => {
                        const formattedDate = selectedDate
                          ? moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss")
                          : null;
                        setFieldValue(
                          `children[${index}].endAt`,
                          formattedDate
                        );
                      }}
                      className={styles.expirCalendar}
                    />
                    <div className={styles.calendarIcon}>
                      <Icon name="calendar outline" />
                    </div>
                  </div>
                </Form.Field>
                <Form.Field width={8} className={styles.formField}>
                  <label>Piece rate</label>
                  <Field
                    as={Input}
                    name="pieceRate"
                    value={item.pieceRate}
                    onChange={(_, { value }) =>
                      setFieldValue(`children[${index}]pieceRate`, value)
                    }
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={16}>
                <Form.Field required width={8}>
                  <label className={styles.formLabel}>Lunch interval</label>
                  <Field
                    as={Input}
                    icon={<Icon name="clock outline" />}
                    className={styles.disabledField}
                    name="lunchInterval"
                    value={values.lunchInterval}
                    onChange={(_, { value }) => {
                      setFieldValue(`children[${index}].lunchInterval`, value);
                    }}
                  />
                </Form.Field>
                <Form.Field
                  width={4}
                  type="number"
                  className={styles.formField}
                >
                  <label>No. of pieces</label>
                  <Field
                    as={Input}
                    name="piecesPerEmployee"
                    value={item.piecesPerEmployee}
                    onChange={(_, { value }) =>
                      setFieldValue(
                        `children[${index}]piecesPerEmployee`,
                        value
                      )
                    }
                  />
                </Form.Field>
                <Form.Field width={4} className={styles.formField}>
                  <label>Total ($)</label>
                  <Field
                    as={Input}
                    disabled={true}
                    className={styles.disabledField}
                    name="number"
                    value={
                      item?.pieceRate && item?.piecesPerEmployee
                        ? !isNaN(+item?.pieceRate * +item?.piecesPerEmployee)
                          ? +item?.pieceRate * +item?.piecesPerEmployee
                          : "0.00"
                        : "0.00"
                    }
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={16}>
                <Field
                  value={values.children[index].comment}
                  placeholder=""
                  as={Form.TextArea}
                  width={16}
                  name="notes"
                  label="Comments"
                  onChange={(_, { value }) =>
                    setFieldValue(`children[${index}]comment`, value)
                  }
                />
              </Form.Group>
              {diffHours > 12 && (
                <Form.Group>
                  <div className={styles.errorMessage}>
                    Clock out time cannot be more than 12 hours after Clock in
                    time.
                  </div>
                </Form.Group>
              )}
              {diffHours <= 0 && (
                <Form.Group>
                  <div className={styles.errorMessage}>
                    Clock out cannot be less Clock in.
                  </div>
                </Form.Group>
              )}
            </div>
          </Segment>
        );
      })}
    </>
  );
};

export default LabourGroup;
