import moment from "moment";
import { object, string, number, array, boolean, ValidationError } from "yup";

export const initialValues = {
  step1: {
    assigneeRows: [
      {
        assignee: "",
        machinery: ""
      }
    ],
    description: "",
    attachments: []
  },
  step2: {
    general: {
      dateStart: null,
      dateEnd: null,
      timeStart: null,
      timeEnd: null,
      lunchInterval: null,
      pieceRate: null,
      piecesCount: null
    },
    areaRows: []
  },
  common: { isDetailedCompletion: false }
};

// TODO: optimize code and remove duplicates
export const getValidationSchema = existingWorkingTimes =>
  object({
    step1: object({
      assigneeRows: array()
        .of(
          object({
            assignee: string().required("Assignee is required"),
            machinery: string()
          })
        )
        .test("unique", "Assignee must be unique", function(items) {
          const assignees = {};
          const errors = [];

          items.forEach(item => {
            assignees[item.assignee] = (assignees[item.assignee] || 0) + 1;
          });

          for (const assignee in assignees) {
            if (assignees[assignee] > 1) {
              items.forEach((item, index) => {
                if (item.assignee === assignee) {
                  errors.push(
                    this.createError({
                      path: `${this.path}.${index}.assignee`,
                      message: "This assignee has already been added"
                    })
                  );
                }
              });
            }
          }

          if (errors.length) {
            throw new ValidationError(errors);
          }

          return true;
        }),
      description: string()
    }),
    step2: object({
      general: object().when("$common.isDetailedCompletion", {
        is: false,
        then: schema =>
          schema
            .shape({
              dateStart: string().required("Start date is required"),
              dateEnd: string().required("End date is required"),
              timeStart: string().required("Start time is required"),
              timeEnd: string().required("End time is required"),
              lunchInterval: string().required("Lunch interval is required"),
              pieceRate: number().nullable(),
              piecesCount: number().nullable()
            })
            .test(
              "is-within-12-hours",
              "Should be earlier than 12 hours after Clocked In time",
              function(wt) {
                const errors = [];

                const dateTime1 = moment(
                  `${wt.dateStart} ${wt.timeStart}`,
                  "DD/MM/YYYY HH:mm"
                );
                const dateTime2 = moment(
                  `${wt.dateEnd} ${wt.timeEnd}`,
                  "DD/MM/YYYY HH:mm"
                );
                const diffInHours = dateTime2.diff(dateTime1, "hours");

                if (!!diffInHours && diffInHours >= 12) {
                  errors.push(
                    this.createError({
                      path: `${this.path}.common.hoursDiff`,
                      message:
                        "Clocked Out time should be earlier than 12 hours after Clocked In time"
                    })
                  );
                }

                if (
                  existingWorkingTimes.length &&
                  this.options.context.step1.assigneeRows.length
                ) {
                  const assigneeIds = this.options.context.step1.assigneeRows.map(
                    ({ assignee }) => assignee
                  );
                  const employeeWts = existingWorkingTimes.filter(wt =>
                    assigneeIds.includes(wt.employeeId)
                  );

                  if (employeeWts.length) {
                    const isOverlapping = employeeWts.some(employeeWt => {
                      const start1 = moment(
                        moment(employeeWt.startAt).format("DD/MM/YYYY HH:mm"),
                        "DD/MM/YYYY HH:mm"
                      );

                      const end1 = moment(
                        moment(employeeWt.endAt).format("DD/MM/YYYY HH:mm"),
                        "DD/MM/YYYY HH:mm"
                      );

                      const start2 = moment(
                        `${wt.dateStart} ${wt.timeStart}`,
                        "DD/MM/YYYY HH:mm"
                      );
                      const end2 = moment(
                        `${wt.dateEnd} ${wt.timeEnd}`,
                        "DD/MM/YYYY HH:mm"
                      );

                      return start1.isBefore(end2) && start2.isBefore(end1);
                    });

                    if (isOverlapping) {
                      errors.push(
                        this.createError({
                          path: `${this.path}.common.overlap`,
                          message:
                            "Selected time overlaps another working period for this user"
                        })
                      );
                    }
                  }
                }

                if (errors.length) {
                  throw new ValidationError(errors);
                }

                return true;
              }
            ),
        otherwise: schema =>
          schema.shape({
            dateStart: string().nullable(),
            dateEnd: string().nullable(),
            timeStart: string().nullable(),
            timeEnd: string().nullable(),
            lunchInterval: string().nullable(),
            pieceRate: number().nullable(),
            piecesCount: number().nullable()
          })
      }),
      areaRows: array().when("$common.isDetailedCompletion", {
        is: true,
        then: schema =>
          schema
            .of(
              object().shape({
                assigneeRows: array().of(
                  object().shape({
                    assignee: string(),
                    machinery: string(),
                    workingTimes: array().of(
                      object().shape({
                        dateStart: string().required(
                          "Working start date is required"
                        ),
                        dateEnd: string().required(
                          "Working end date is required"
                        ),
                        timeStart: string().required(
                          "Working start time is required"
                        ),
                        timeEnd: string().required(
                          "Working end time is required"
                        ),
                        lunchInterval: string().required(
                          "Lunch interval is required"
                        ),
                        pieceRate: number().nullable(),
                        piecesCount: number().nullable()
                      })
                    )
                  })
                )
              })
            )
            .test("no-overlap", "DateTimes cannot overlap", function(areaRows) {
              const errors = [];
              const assigneeMap = {};

              areaRows.forEach((areaRow, areaIndex) => {
                areaRow.assigneeRows.forEach(
                  ({ assignee, workingTimes }, assigneeRowIndex) => {
                    if (!assigneeMap[assignee]) {
                      assigneeMap[assignee] = [];
                    }

                    workingTimes.forEach((workingTime, workingTimeIndex) => {
                      const start = moment(
                        `${workingTime.dateStart} ${workingTime.timeStart}`,
                        "DD/MM/YYYY HH:mm"
                      );
                      const end = moment(
                        `${workingTime.dateEnd} ${workingTime.timeEnd}`,
                        "DD/MM/YYYY HH:mm"
                      );
                      const diffInHours = end.diff(start, "hours");

                      if (!!diffInHours && diffInHours >= 12) {
                        errors.push(
                          this.createError({
                            path: `${this.path}.${areaIndex}.assigneeRows.${assigneeRowIndex}.workingTimes.${workingTimeIndex}.common.hoursDiff`,
                            message:
                              "Should be earlier than 12 hours after Clocked In time"
                          })
                        );
                      }

                      assigneeMap[assignee].push({
                        workingTime,
                        areaIndex,
                        assigneeRowIndex,
                        workingTimeIndex
                      });
                    });
                  }
                );
              });
              Object.entries(assigneeMap).forEach(
                ([assignee, workingTimesArray]) => {
                  for (let i = 0; i < workingTimesArray.length; i++) {
                    const {
                      workingTime: time1,
                      areaIndex: areaIndex1,
                      assigneeRowIndex,
                      workingTimeIndex: workingTimeIndex1
                    } = workingTimesArray[i];

                    const start1 = moment(
                      `${time1.dateStart} ${time1.timeStart}`,
                      "DD/MM/YYYY HH:mm"
                    );
                    const end1 = moment(
                      `${time1.dateEnd} ${time1.timeEnd}`,
                      "DD/MM/YYYY HH:mm"
                    );

                    for (let j = 0; j < workingTimesArray.length; j++) {
                      if (i !== j) {
                        const {
                          workingTime: time2,
                          areaIndex: areaIndex2,
                          assigneeRowIndex: assigneeRowIndex2,
                          workingTimeIndex: workingTimeIndex2
                        } = workingTimesArray[j];
                        const start2 = moment(
                          `${time2.dateStart} ${time2.timeStart}`,
                          "DD/MM/YYYY HH:mm"
                        );
                        const end2 = moment(
                          `${time2.dateEnd} ${time2.timeEnd}`,
                          "DD/MM/YYYY HH:mm"
                        );

                        // TODO: refactor
                        let check = false;

                        if (existingWorkingTimes.length) {
                          const employeeWts = existingWorkingTimes.filter(
                            wt => wt.employeeId == assignee
                          );
                          if (employeeWts.length) {
                            check = employeeWts.some(wt => {
                              const start1OfExisting = moment(
                                moment(wt.startAt).format("DD/MM/YYYY HH:mm"),
                                "DD/MM/YYYY HH:mm"
                              );

                              const end1OfExisting = moment(
                                moment(wt.endAt).format("DD/MM/YYYY HH:mm"),
                                "DD/MM/YYYY HH:mm"
                              );

                              return (
                                start1OfExisting.isBefore(end2) &&
                                start2.isBefore(end1OfExisting)
                              );
                            });
                          }
                        }

                        if (
                          (start1.isBefore(end2) && start2.isBefore(end1)) ||
                          check
                        ) {
                          errors.push(
                            this.createError({
                              path: `${this.path}.${
                                check ? areaIndex2 : areaIndex1
                              }.assigneeRows.${
                                check ? assigneeRowIndex2 : assigneeRowIndex
                              }.workingTimes.${
                                check ? workingTimeIndex2 : workingTimeIndex1
                              }.common.overlap`,
                              message:
                                "Selected time overlaps another working period for this user"
                            })
                          );
                        }
                      }
                    }
                  }
                }
              );

              if (errors.length) {
                throw new ValidationError(errors);
              }

              return true;
            }),
        otherwise: schema =>
          schema.of(
            object().shape({
              assigneeRows: array().of(
                object().shape({
                  assignee: string(),
                  machinery: string(),
                  workingTimes: array().of(
                    object().shape({
                      dateStart: string().nullable(),
                      dateEnd: string().nullable(),
                      timeStart: string().nullable(),
                      timeEnd: string().nullable(),
                      lunchInterval: string().nullable(),
                      pieceRate: number().nullable(),
                      piecesCount: number().nullable()
                    })
                  )
                })
              )
            })
          )
      })
    }),
    common: object({
      isDetailedCompletion: boolean()
    })
  });
