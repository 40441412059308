import React from "react";
import Calendar from "./components/FullCalendar";
import styles from "./TimeLine.module.css";
import { connect } from "react-redux";

const TimeLine = ({
  data,
  areasList,
  employeeList,
  onRowClick,
  handleTaskIdClick
}) => (
  <div className={styles.calendarWrapper}>
    <Calendar
      handleTaskIdClick={handleTaskIdClick}
      onRowClick={onRowClick}
      data={data}
      areasList={areasList}
      employeeList={employeeList}
    />
  </div>
);

export default connect(state => ({
  employeeList: state.employee.list.content,
  areasList: state.areas.list.content,
  data: state.newTasks.data,
  list: state.newTasks.list.content,
  isFetching: state.newTasks.isFetching
}))(TimeLine);
