import React from "react";
import PropTypes from "prop-types";
import { Button, Menu, Sidebar } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { menuTypesSelector } from "../../selectors/user";
import styles from "./MenuLeft.module.css";
import MenuSection from "./MenuSection";

const MenuLeft = ({
  animation,
  direction,
  className,
  setMenuOpen,
  location,
}) => {
  const pathname = location.pathname;
  const menuCollections = useSelector(menuTypesSelector);
  const headerMenuChecker =
    pathname === "/account/settings" ||
    pathname === "/taskCustomization_settings";
  return (
    <Sidebar
      as={Menu}
      animation={animation}
      direction={direction}
      vertical
      className={className}
    >
      <Button
        icon="close"
        className={`${styles.buttonClose} hide-md`}
        onClick={() => setMenuOpen(false)}
      />
      <div className={styles.menuHolder}>
        {menuCollections.mainMenu.length > 0 && !headerMenuChecker && (
          <div className={styles.menuGroup}>
            <div className={`${styles.groupTitle} hide-md`}>Menu</div>
            <MenuSection
              main={true}
              section={menuCollections.mainMenu}
              pathname={pathname}
            />
          </div>
        )}
        {menuCollections.headerMenu.length > 0 && headerMenuChecker && (
          <div className={styles.menuGroup}>
            <MenuSection
              main={true}
              headerMenuChecker={headerMenuChecker}
              section={menuCollections.headerMenu}
              pathname={pathname}
            />
          </div>
        )}
        {menuCollections.accountMenu.length > 0 ? (
          <div className={`${styles.menuGroup} ${styles.growAuto} hide-sm`}>
            <div
              className={`${styles.groupTitle} ${
                menuCollections.accountMenu.filter(
                  (menu) => menu.className === "hide-md"
                ).length === menuCollections.accountMenu.length
                  ? "hide-md"
                  : ""
              }`}
            >
              Account
            </div>
            <MenuSection
              section={menuCollections.accountMenu}
              pathname={pathname}
            />
          </div>
        ) : (
          <div className={`${styles.menuGroup} ${styles.growAuto}`} />
        )}

        <div className={`${styles.menuGroup} ${styles.support}`}>
          <div className={styles.groupTitle}>Support</div>
          <MenuSection
            section={menuCollections.supportMenu}
            pathname={pathname}
          />
        </div>
      </div>
    </Sidebar>
  );
};

MenuLeft.propTypes = {
  animation: PropTypes.string,
  direction: PropTypes.string,
  className: PropTypes.string.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

MenuLeft.defaultProps = {
  className: "",
  location: {},
  setMenuOpen: () => {},
};

export default MenuLeft;
