import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Menu } from "semantic-ui-react";
import BinPicture from "../Tabs/Pictures/BinPictures";
import Parametrs from "../Tabs/Parametrs/Parametrs";
import Workers from "../Tabs/Workers/Workers";
import styles from "./Tabs.module.css";
import EditParameter from "./Parametrs/EditParametrs";
import WorkersEdit from "./Workers/WorkersEdit";
import WorkersAdd from "./Workers/WorkersAdd";
import BinPicturesEdit from "./Pictures/BinPicturesEdit";
import ParameterAdd from "./Parametrs/ParameterAdd";

const Tabs = ({
  activeItem,
  header,
  activeRounds,
  checkEdit,
  addCheck,
  parameterAddCheck,
  setParameterAddCheck,
  setAddCheck,
  setCheckEdit
}) => {
  const [activeTab, setactiveTab] = useState("Parameters");
  const handleItemClick = (e, { name }) => {
    setactiveTab(name);
    setCheckEdit(false);
    setParameterAddCheck(false);
    setAddCheck(false);
  };
  return (
    <Grid className={styles.body}>
      <div className={styles.tabsHolder}>
        <Menu pointing secondary className={styles.tabsMenu}>
          <Menu.Item
            name="Parameters"
            active={activeTab === "Parameters"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="Bin pictures"
            active={activeTab === "Bin pictures"}
            onClick={handleItemClick}
          />
          <Menu.Item
            name="Workers"
            active={activeTab === "Workers"}
            onClick={handleItemClick}
          />
        </Menu>
        <div
          className={`${styles.tabItem} ${
            activeTab === "Parameters"
              ? styles.visibleTab
              : styles.undisplayedTab
          }`}
        >
          {checkEdit ? (
            <EditParameter
              activeItem={activeItem}
              setCheckEdit={setCheckEdit}
              activeRounds={activeRounds}
            />
          ) : parameterAddCheck ? (
            <ParameterAdd
              activeRounds={activeRounds}
              activeItem={activeItem}
              setParameterAddCheck={setParameterAddCheck}
            />
          ) : (
            <Parametrs
              activeItem={activeItem}
              activeRounds={activeRounds}
              setParameterAddCheck={setParameterAddCheck}
            />
          )}
        </div>
        <div
          className={`${styles.tabItem} ${
            activeTab === "Bin pictures"
              ? styles.visibleTab
              : styles.undisplayedTab
          }`}
        >
          {checkEdit ? (
            <BinPicturesEdit
              activeItem={activeItem}
              activeRounds={activeRounds}
              header={header}
              setCheckEdit={setCheckEdit}
            />
          ) : (
            <BinPicture activeRounds={activeRounds} header={header} />
          )}
        </div>
        <div
          className={`${styles.tabItem} ${
            activeTab === "Workers" ? styles.visibleTab : styles.undisplayedTab
          }`}
        >
          {checkEdit ? (
            <WorkersEdit
              activeItem={activeItem}
              activeRounds={activeRounds}
              setCheckEdit={setCheckEdit}
            />
          ) : addCheck ? (
            <WorkersAdd
              activeRounds={activeRounds}
              activeItem={activeItem}
              setAddCheck={setAddCheck}
            />
          ) : (
            <Workers setAddCheck={setAddCheck} activeRounds={activeRounds} />
          )}
        </div>
      </div>
    </Grid>
  );
};

Tabs.propTypes = {
  activeRounds: PropTypes.object
};

export default Tabs;
