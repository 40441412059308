export const actionType = {
  GET_EMPLOYEE_LIST_REQUEST: "SPRAYDIARY/GET_EMPLOYEE_LIST_REQUEST",
  GET_EMPLOYEE_LIST_START: "SPRAYDIARY/GET_EMPLOYEE_LIST_START",
  GET_EMPLOYEE_LIST_SUCCESS: "SPRAYDIARY/GET_EMPLOYEE_LIST_SUCCESS",
  GET_EMPLOYEE_LIST_ERROR: "SPRAYDIARY/GET_EMPLOYEE_LIST_ERROR",
  SELECT_EMPLOYEES: "SPRAYDIARY/SELECT_EMPLOYEES",
  SELECT_SINGLE_EMPLOYEE: "SPRAYDIARY/SELECT_SINGLE_EMPLOYEE",
  GET_MACHINERY_LIST_START: "SPRAYDIARY/GET_MACHINERY_LIST_START",
  GET_MACHINERY_LIST: "SPRAYDIARY/GET_MACHINERY_LIST",
  GET_MACHINERY_LIST_ERROR: "SPRAYDIARY/GET_MACHINERY_LIST_ERROR",

  CLEAR_ROUTE: "SPRAYDIARY/CLEAR_ROUTE",
  GET_ROUTE_START: "SPRAYDIARY/GET_ROUTE_START",
  GET_ROUTE: "SPRAYDIARY/GET_ROUTE",
  GET_ROUTE_ERROR: "SPRAYDIARY/GET_ROUTE_ERROR",

  SELECT_MACHINERY: "SPRAYDIARY/SELECT_MACHINERY",
  CLEAR_MACHINERY: "SPRAYDIARY/CLEAR_MACHINERY",
  ADD_AND_SELECT_MACHINERY: "SPRAYDIARY/ADD_AND_SELECT_MACHINERY",
  SET_MACHINERY_CONC: "SPRAYDIARY/SET_MACHINERY_CONC",
  GET_AREAS_LIST: "SPRAYDIARY/GET_AREAS_LIST",
  CHANGE_AREAS: "SPRAYDIARY/CHANGE_AREAS",
  SET_AREA_VALUE: "SPRAYDIARY/SET_AREA_VALUE",
  UPDATE_AREAS_LIST: "SPRAYDIARY/UPDATE_AREAS_LIST",
  SET_WIDTH_PER_ROW: "SPRAYDIARY/SET+WIDTH_PER_ROW",
  SET_L_PER_HA: "SPRAYDIARY/SET_L_PER_HA",

  GET_CHEMICALS_LIST_START: "SPRAYDIARY/GET_CHEMICALS_LIST_START",
  GET_CHEMICALS_LIST_SUCCESS: "SPRAYDIARY/GET_CHEMICALS_LIST_SUCCESS",
  GET_CHEMICALS_LIST_ERROR: "SPRAYDIARY/GET_CHEMICALS_LIST_ERROR",
  SET_CHEMICAL_ITEM_TO_ADD: "SPRAYDIARY/SET_CHEMICAL_ITEM_TO_ADD",
  ON_EDIT_CHEMICAL_ITEM: "SPRAYDIARY/ON_EDIT_CHEMICAL_ITEM",
  DELETE_CHEMICAL_ITEM: "SPRAYDIARY/DELETE_CHEMICAL_ITEM",
  ADD_CHEMICAL_ITEM: "SPRAYDIARY/ADD_CHEMICAL_ITEM",
  SET_STATUS: "SPRAYDIARY/SET_STATUS",
  SET_ROWS_TO_SPRAY: "SPRAYDIARY/SET_ROWS_TO_SPRAY",
  SET_ACTUALL_ROWS_TO_SPRAY_CHECK_STATE:
    "SET_ACTUALL_ROWS_TO_SPRAY_CHECK_STATE",
  SET_ACTUALL_ROWS_TO_SPRAY: "SPRAYDIARY/SET_ACTUALL_ROWS_TO_SPRAY",
  SET_SPRAY_DATE: "SPRAYDIARY/SET_SPRAY_DATE",
  SET_SPRAY_EXPIRY_DATE: "SPRAYDIARY/SET_SPRAY_EXPIRY_DATE",
  SET_AREA_DONE_STATE: "SPRAYDIARY/SET_AREA_DONE_STATE",
  SET_AREAS_DONE_STATE: "SPRAYDIARY/SET_AREAS_DONE_STATE",
  SET_ALL_AREAS_DONE_STATE: "SPRAYDIARY/SET_ALL_AREAS_DONE_STATE",
  SET_CHEMICAL_FROM_LIST: "SPRAYDIARY/SET_CHEMICAL_FROM_LIST",
  SET_WORKING_TIMES: "SPRAYDIARY/SET_WORKING_TIMES",
  SET_WIND_SPEED: "SPRAYDIARY/SET_WIND_SPEED",
  SET_TEMPERATURE: "SPRAYDIARY/SET_TEMPERATURE",
  SET_DELTA_T: "SPRAYDIARY/SET_DELTA_T",
  SET_GROWTH_STAGE: "SPRAYDIARY/SET_GROWTH_STAGE",
  SET_WIND_DIRECTION: "SPRAYDIARY/SET_WIND_DIRECTION",
  SET_NOZZLE: "SPRAYDIARY/SET_NOZZLE",
  SET_NOZZLES_PER_SIDE: "SPRAYDIARY/SET_NOZZLES_PER_SIDE",
  SET_GEAR: "SPRAYDIARY/SET_GEAR",
  SET_RPM: "SPRAYDIARY/SET_RPM",
  SET_PRESSURE: "SPRAYDIARY/SET_PRESSURE",
  SET_PTO: "SPRAYDIARY/SET_PTO",
  SET_MACHINERY_SPEED: "SPRAYDIARY/SET_MACHINERY_SPEED",
  CREATE_TASK_START: "SPRAYDIARY/CREATE_TASK_START",
  CREATE_TASK_SUCCESS: "SPRAYDIARY/CREATE_TASK_SUCCESS",
  CREATE_TASK_OFFLINE: "SPRAYDIARY/CREATE_TASK_OFFLINE",
  CREATE_TASK_ERROR: "SPRAYDIARY/CREATE_TASK_ERROR",
  SET_INTERVENTION_TYPE: "SPRAYDIARY/SET_INTERVENTION_TYPE",
  SET_SHED: "SPRAYDIARY/SET_SHED",
  SET_APPLICATION_TYPE: "SPRAYDIARY/SET_APPLICATION_TYPE",
  DECLINE_EDITING_CHEMICAL_ITEM: "SPRAYDIARY/DECLINE_EDITING_CHEMICAL_ITEM",
  SET_COMMENTS: "SPRAYDIARY/SET_COMMENTS",
  SET_PPE_WORN: "SPRAYDIARY/SET_PPE_WORN",
  GET_TASK_BY_ID: "SPRAYDIARY/GET_TASK_BY_ID",
  GET_TASK_BY_ID_SUCCESS: "SPRAYDIARY/GET_TASK_BY_ID_SUCCESS",
  GET_TASK_BY_ID_ERROR: "SPRAYDIARY/GET_TASK_BY_ID_ERROR",
  CHANGE_DUPLICATE_SELECTION: "SPRAYDIARY/CHANGE_DUPLICATE_SELECTION",
  CLEAR_AND_DUPLICATE: "SPRAYDIARY/CLEAR_AND_DUPLICATE",
  CLEAR_CREATOR: "SPRAYDIARY/CLEAR_CREATOR",
  PREFILL_CREATOR: "SPRAYDIARY/PREFILL_CREATOR",
  SET_VALIDATIONS: "SPRAYDIARY/SET_VALIDATIONS",
  SET_AREAS_TO_ADD: "SPRAYDIARY/SET_AREAS_TO_ADD",
  CHANGE_CHEMICAL_BATCHNUMBERS_LIST:
    "SPRAYDIARY/CHANGE_CHEMICAL_BATCHNUMBERS_LIST",

  ADD_NEW_CHEMICAL_START: "SPRAYDIARY/ADD_NEW_CHEMICAL_START",
  ADD_NEW_CHEMICAL: "SPRAYDIARY/ADD_NEW_CHEMICAL",
  ADD_NEW_CHEMICAL_FAIL: "SPRAYDIARY/ADD_NEW_CHEMICAL_FAIL",
  GET_SPRAY_TASKS_ERROR: "SPRAYDIARY/GET_SPRAY_TASKS_ERROR",
  UPLOAD_TASK_ATTACHMENT_START: "SPRAYDIARY/UPLOAD_TASK_ATTACHMENT_START",
  UPLOAD_TASK_ATTACHMENT: "SPRAYDIARY/UPLOAD_TASK_ATTACHMENT",
  UPLOAD_TASK_ATTACHMENT_ERROR: "SPRAYDIARY/UPLOAD_TASK_ATTACHMENT_ERROR",
  SET_ATTACHMENTS: "SPRAYDIARY/SET_ATTACHMENTS",
  ADD_CHEMICAL_SELECTION: "SPRAYDIARY/ADD_CHEMICAL_SELECTION",
  SET_FIELD: "SPRAYDIARY/SET_FIELD",
  RESTORE_BACKUP: "SPRAYDIARY/RESTORE_BACKUP",
  SET_NESTED_FIELD: "SPRAYDIARY/SET_NESTED_FIELD",

  CLEAR_SELECTED_CHEMICALS: "SPRAYDIARY/CLEAR_SELECTED_CHEMICALS",
  SET_SELECTED_CHEMICALS: "SPRAYDIARY/SET_SELECTED_CHEMICALS",

  SET_TASK_NAME: "SPRAYDIARY/SET_TASK_NAME",
  GET_TASK_NAME_LIST_START: "SPRAYDIARY/GET_TASK_NAME_LIST_START",
  GET_TASK_NAME_LIST_SUCCESS: "SPRAYDIARY/GET_TASK_NAME_LIST_SUCCESS",
  GET_TASK_NAME_LIST_FAIL: "SPRAYDIARY/GET_TASK_NAME_LIST_FAIL",

  CLEAR_ERROR: "SPRAYDIARY/CLEAR_ERROR",

  SET_AREAS_LIST_LOADING: "SPRAYDIARY/SET_AREAS_LIST_LOADING"
};

export const TASK_NAME_MAX_LENGTH = 20;
