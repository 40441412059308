import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Confirm, Grid, Label } from "semantic-ui-react";
import {
  Checkbox,
  Form,
  Input,
  Select,
  TextArea
} from "formsy-semantic-ui-react";
import ObjectId from "bson-objectid";
import { getCropsList } from "../../../../actions/Farms/crops";
import { addNewChemical } from "../../../../actions/SprayDiary/chemicals";
import { uploadAttachment } from "../../../../actions/Chemicals";
import { cropsSelector, GENERIC_VALUE } from "../../../../selectors/crops";
import {
  chemicalsTypesSelector,
  dilutionRateTypesSelector,
  periodUnitsSelector
} from "../../../../selectors/chemicals";
import assignValue from "../../../../utils/assignValue";
import SwitchButton from "../../../../components/SwitchButton";
import {
  getErrorFields,
  validationErrors
} from "../../../../utils/validationRules";
import { valueToString } from "../../../../utils/tasksUtils";
import ChemicalProperties from "../ChemicalProperties";
import CropTable from "../CropTable";
import Attachments from "../../../../components/Attachments";
import SegmentClosable from "../../../../components/SegmentClosable";
import {
  MATTER_TYPE_LIQUID,
  MATTER_TYPE_SOLID,
  PER_100_LITERS,
  PER_HECTARE
} from "../../../../constants/Farms";
import { scrollIntoError } from "../../../../utils/scrollIntoError";
import styles from "../AddNewChemical.module.css";

const ErrorLabel = <Label color="red" pointing />;

const DaysLabel = () => <Label className={styles.daysLabel}>Days</Label>;
const rateTypeItems = [
  { label: "Per 100L", value: PER_100_LITERS },
  { label: "Per Ha", value: PER_HECTARE }
];

const matterItems = [
  { label: "Liquid", value: MATTER_TYPE_LIQUID },
  { label: "Solid", value: MATTER_TYPE_SOLID }
];

const properties = () => ({
  poisonSchedule: "",
  activeIngredient: "",
  resistanceGroup1: "",
  resistanceGroup2: "",
  elements: {}
});

const mapChemicalProperties = props => {
  if (_.isEmpty(props)) {
    return properties();
  }

  return props;
};

const mapChemicalToState = chemical => {
  chemical = chemical || {};

  const type = chemical.type || {};
  return {
    id: chemical.id,
    name: chemical.name || "",
    matter: chemical.matter || MATTER_TYPE_SOLID,
    batchRequired: chemical.batchRequired || false,
    attachments: chemical.attachments || [],
    notes: chemical.notes || "",
    price: valueToString(chemical.price),
    reEntry: valueToString(chemical.reentry),
    sds: chemical.sds || "",
    label: chemical.label || "",
    withholding: _.get(chemical, "rates.0.withholdingPeriod") || "",
    rateType: _.get(chemical, "rates.0.rateType") || PER_100_LITERS,
    crops: (chemical.rates || []).map(rate => {
      return {
        _id: rate.id,
        generatedId: false,
        id: {
          key: _.get(rate, "crop.id") || GENERIC_VALUE,
          id: _.get(rate, "crop.id") || GENERIC_VALUE,
          text: _.get(rate, "crop.name") || "Generic"
        },
        purpose: rate.purpose,
        minRate: rate.minRate,
        maxRate: rate.maxRate,
        rateType: rate.rateType,
        withholdingPeriod: rate.withholdingPeriod,
        comment: rate.comment,
        value: _.get(rate, "crop.name")
      };
    }),
    crop: {
      id: null,
      _id: ObjectId().toHexString(),
      generatedId: true,
      purpose: "",
      minRate: "",
      maxRate: "",
      rateType: null,
      withholdingPeriod: "",
      comment: "",
      value: null
    },
    editedCrops: [],
    type: {
      key: type.id,
      value: type.id,
      text: type.type
    },
    properties: mapChemicalProperties(chemical.properties)
  };
};

class ChemicalForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addDisabled: true,
      isLoading: true,
      cropsOptions: props.crops,
      formData: mapChemicalToState(props.chemical),
      isUploadingAttachment: false,
      showConfirm: false,
      confirmAction: null,
      errorsInSegments: {}
    };

    this.formRef = props.formRef || React.createRef();
  }

  componentDidMount() {
    const { actions } = this.props;

    actions.getCropsList();
  }

  componentDidUpdate(prevProps) {
    const { crops } = this.props;
    if (prevProps.crops !== crops) {
      this.setState({
        cropsOptions: crops
      });
    }
  }

  changeValue = (fieldName, fieldValue) => {
    const { formData } = this.state;
    assignValue(formData, fieldName, fieldValue);
    this.setState({
      formData
    });
  };

  updateValue = (index, fieldName, fieldValue) => {
    const { formData } = this.state;
    assignValue(formData.crops[index], fieldName, fieldValue);
    this.setState({
      formData
    });
  };

  setAddCropUpdateValidation = index => {
    let { formData } = this.state;
    formData.crops[index].notValidAnimation = !formData.crops[index]
      .notValidAnimation;
    this.setState({
      formData
    });
  };

  setAddNewCropUpdateValidation = _ => {
    let { formData } = this.state;
    formData.crop.notValidAnimation = false;
    this.setState({ formData });
  };

  handleErrors = () => {
    const formsyForm = this.formRef.current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error:
            errorFields.name ||
            errorFields.type ||
            errorFields.price ||
            errorFields.reEntry
        },
        cropsRates: {
          error:
            errorFields.allCropsSet ||
            errorFields["crop.minRate"] ||
            errorFields["crop.id"] ||
            errorFields["crop.withholdingPeriod"]
        }
      }
    });
    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = () => {
    let { formData } = this.state;

    if (!this.state.addDisabled && !this.checkIfEmpty()) {
      formData.crop.notValidAnimation = true;
    }

    if (formData.editedCrops.length) {
      formData.editedCrops.forEach(el => {
        let cropIndex = _.findIndex(formData.crops, c => c._id === el._id);
        formData.crops[cropIndex].notValidAnimation = true;
      });
      return;
    }

    if (formData.crop.notValidAnimation) {
      return;
    }

    const rateType = _.get(formData, "rateType");
    const data = {
      attachments: _.get(formData, "attachments") || [],
      batchRequired: _.get(formData, "batchRequired") || false,
      matter: _.get(formData, "matter") || null,
      name: _.get(formData, "name") || null,
      notes: _.get(formData, "notes") || "",
      price: _.get(formData, "price") || null,
      properties: _.get(formData, "properties") || null,
      rates: (_.get(formData, "crops") || [])
        .filter(crop => !crop.deleted)
        .map(crop => {
          return {
            ...(!crop.generatedId ? { id: crop._id } : {}),
            crop:
              crop.id.key !== GENERIC_VALUE
                ? {
                    id: crop.id.key
                  }
                : null,
            purpose: crop.purpose,
            maxRate: crop.maxRate,
            minRate: crop.minRate,
            withholdingPeriod: _.get(crop, "withholdingPeriod"),
            rateType: rateType,
            comment: crop.comment
          };
        }),
      sds: _.get(formData, "sds") || null,
      label: _.get(formData, "label") || null,
      reentry: _.get(formData, "reEntry"),
      reentryUnit: "DAYS",
      type: {
        id: formData.type.value,
        type: formData.type.text
      },
      withholdingPeriodUnit: "DAYS"
    };

    this.setState({
      errorsInSegments: {}
    });

    this.props.onValidSubmit(data);
  };
  checkIfEmpty = () => {
    let { formData } = this.state;
    const {
      formData: { crop }
    } = this.state;

    return (
      formData.crop.purpose === "" &&
      formData.crop.minRate === "" &&
      formData.crop.maxRate === "" &&
      formData.crop.rateType == null &&
      formData.crop.withholdingPeriod === "" &&
      formData.crop.comment === "" &&
      crop.id == null
    );
  };
  getFormsyInput = inputName => {
    const formsyForm = this.formRef.current.formsyForm;
    return formsyForm.inputs.find(input => {
      return input.props.name === inputName;
    });
  };

  checkAddDisabled = () => {
    // need timeout to await value changes in formsyForm data object
    setTimeout(() => {
      const formsyForm = this.formRef.current.formsyForm;
      const { formData } = this.state;
      if (formsyForm) {
        const minRate = this.getFormsyInput("crop.minRate");
        const maxRate = this.getFormsyInput("crop.maxRate");
        const withholdingPeriod = this.getFormsyInput("crop.withholdingPeriod");

        const isDuplicatedCropAndPurpose = cropItem => {
          return (
            _.get(cropItem, "id.key") === _.get(formData.crop, "id.key") &&
            _.get(cropItem, "purpose", "")
              .trim()
              .toUpperCase() ===
              _.get(formData.crop, "purpose", "")
                .trim()
                .toUpperCase() &&
            !_.get(cropItem, "deleted")
          );
        };
        let duplicatedCropAndPurpose =
          formData.crops.some(isDuplicatedCropAndPurpose) ||
          formData.editedCrops.some(isDuplicatedCropAndPurpose);

        this.setState({
          addDisabled: !(
            minRate &&
            minRate.isValid() &&
            (!maxRate || maxRate.isValid()) &&
            withholdingPeriod &&
            withholdingPeriod.isValid() &&
            formData.crop.id &&
            valueToString(formData.crop.withholdingPeriod) &&
            valueToString(formData.crop.minRate) &&
            (!formData.crop.maxRate ||
              Number(formData.crop.minRate) <= Number(formData.crop.maxRate)) &&
            !duplicatedCropAndPurpose
          ),
          duplicatedCropAndPurpose: duplicatedCropAndPurpose
        });
      }
    }, 100);
  };
  clearCropRow = () => {
    const { formData } = this.state;
    const crop = this.getFormsyInput("crop.id");
    crop.resetValue();

    this.setState({
      formData: {
        ...formData,
        crops: formData.crops,
        crop: {
          id: null,
          purpose: "",
          minRate: "",
          maxRate: "",
          rateType: null,
          withholdingPeriod: "",
          comment: "",
          value: null
        }
      }
    });
  };

  addCropRow = () => {
    const { formData, addDisabled } = this.state;

    if (this.checkIfEmpty()) {
      return;
    } else if (addDisabled) {
      this.formRef.current.submit();
      return;
    }
    const index = formData.crops.findIndex(
      crop =>
        crop.id.key === formData.crop.id.key &&
        crop.purpose === formData.crop.purpose
    );

    if (index === -1) {
      formData.crops.push({ ...formData.crop });
    } else {
      formData.crops.push({
        ...formData.crop,
        _id: formData.crops[index]._id,
        generatedId: false
      });
      formData.crops.splice(index, 1);
    }

    const crop = this.getFormsyInput("crop.id");
    crop.resetValue();

    this.setState({
      formData: {
        ...formData,
        crop: {
          id: null,
          _id: ObjectId().toHexString(),
          generatedId: true,
          purpose: "",
          minRate: "",
          maxRate: "",
          rateType: null,
          withholdingPeriod: "",
          comment: "",
          value: null
        }
      }
    });
  };

  validRow = index => {
    const formsyForm = this.formRef.current.formsyForm;
    const { formData } = this.state;
    const element = formData.crops[index];
    if (formsyForm) {
      const minRate = this.getFormsyInput(`${index}_crop.minRate`);
      const maxRate = this.getFormsyInput(`${index}_crop.maxRate`);
      const withholdingPeriod = this.getFormsyInput(
        `${index}_crop.withholdingPeriod`
      );

      const isDuplicatedCropAndPurpose = cropItem => {
        return (
          _.get(cropItem, "id.key") === _.get(element, "id.key") &&
          _.get(cropItem, "purpose", "")
            .trim()
            .toUpperCase() ===
            _.get(element, "purpose", "")
              .trim()
              .toUpperCase() &&
          !_.get(cropItem, "deleted") &&
          element._id !== cropItem._id
        );
      };

      let duplicatedCropAndPurpose =
        formData.crops.some(isDuplicatedCropAndPurpose) ||
        formData.editedCrops.some(isDuplicatedCropAndPurpose);

      return [
        !(
          minRate &&
          minRate.isValid() &&
          (!maxRate || maxRate.isValid()) &&
          withholdingPeriod &&
          withholdingPeriod.isValid() &&
          element.id &&
          (!element.maxRate ||
            Number(element.minRate) <= Number(element.maxRate)) &&
          !duplicatedCropAndPurpose
        ),
        duplicatedCropAndPurpose
      ];
    }

    return true;
  };

  removeCropRow = (cropId, purpose) => {
    const { formData } = this.state;

    formData.crops.forEach(crop => {
      if (crop.id === cropId && crop.purpose === purpose) {
        crop.deleted = true;
      }
    });

    this.setState(
      {
        formData: {
          ...formData,
          crops: formData.crops
        }
      },
      () => this.checkAddDisabled()
    );
  };

  showRemoveCropRowConfirm = (cropId, purpose) => {
    this.setState({
      showConfirm: true,
      confirmAction: () => this.removeCropRow(cropId, purpose)
    });
  };

  hideConfirm = () => {
    this.setState({
      showConfirm: false,
      confirmAction: null
    });
  };

  onConfirm = () => {
    const { confirmAction } = this.state;
    confirmAction();
    this.hideConfirm();
  };

  editCropRow = index => {
    let { formData } = this.state;
    if (formData.crops[index].edit) {
      let oldRowIndex = _.findIndex(
        formData.editedCrops,
        crop => crop._id === formData.crops[index]._id
      );
      formData.crops[index] = _.cloneDeep(formData.editedCrops[oldRowIndex]);
      formData.crops[index].edit = false;
      formData.editedCrops.splice(oldRowIndex, 1);
    } else {
      formData = {
        ...formData,
        crops: formData.crops,
        editedCrops: [
          ...formData.editedCrops,
          _.cloneDeep(formData.crops[index])
        ]
      };
      formData.crops[index].edit = true;
    }
    this.setState(
      {
        formData
      },
      () => this.checkAddDisabled()
    );
  };

  updateCropRow = index => {
    const { formData } = this.state;
    let isNotValid = this.validRow(index).some(res => res);

    if (isNotValid) {
      this.formRef.current.submit();
      return;
    }

    let oldRowIndex = _.findIndex(
      formData.editedCrops,
      crop => crop._id === formData.crops[index]._id
    );
    const findDuplicateIndex = _.findIndex(
      formData.crops,
      crop =>
        crop.id.key === formData.crops[index].id.key &&
        crop.purpose === formData.crops[index].purpose &&
        crop._id !== formData.crops[index]._id
    );

    if (findDuplicateIndex >= 0) {
      const findDuplicateIndexId = formData.crops[findDuplicateIndex]._id;
      formData.crops[index].deleted = true;
      formData.crops[findDuplicateIndex] = _.cloneDeep(formData.crops[index]);
      formData.crops[findDuplicateIndex]._id = findDuplicateIndexId;
      formData.crops[findDuplicateIndex].deleted = false;
      formData.crops[findDuplicateIndex].edit = false;
    }
    formData.editedCrops.splice(oldRowIndex, 1);
    formData.crops[index].edit = false;
    this.setState(
      {
        formData
      },
      () => this.checkAddDisabled()
    );
  };

  validateCrops = () => {
    const {
      formData: { crops, crop }
    } = this.state;
    const chemicalSelected = !!crop.id;
    let notDeletedCropsCount = _.filter(crops, function(o) {
      return !o.deleted;
    }).length;
    let ans;
    let allFieldsEmpty = this.checkIfEmpty();
    if (allFieldsEmpty && chemicalSelected) {
      ans = true;
    } else {
      if (!this.state.addDisabled) {
        ans = !chemicalSelected
          ? true
          : notDeletedCropsCount > 0 && !allFieldsEmpty;
      } else {
        if (notDeletedCropsCount > 0 && !chemicalSelected) {
          ans = true;
        } else {
          if (!chemicalSelected) {
            if (notDeletedCropsCount === 0 && chemicalSelected) {
              ans = true;
            } else {
              ans = notDeletedCropsCount > 0;
            }
          } else {
            if (crop.id) {
              ans = true;
            } else {
              ans = allFieldsEmpty && chemicalSelected;
            }
          }
        }
      }
    }

    return ans;
  };

  // TODO: This is a duplicated code fragment. Please, utilize to one method
  attachmentLoadCallback = r => {
    const { id, subPath, name, data, idReplace, online } = r;
    const indexToReplace = this.state.formData.attachments.findIndex(
      item => item.idReplace === idReplace
    );
    if (indexToReplace >= 0) {
      this.state.formData.attachments.splice(indexToReplace, 1, {
        id,
        subPath,
        name,
        data,
        fromOffline: !online
      });
    }
    this.changeValue("attachments", [...this.state.formData.attachments]);
  };

  uploadChemicalAttachment = ({ file, data, idReplace, callBack }) => {
    const { actions, chemical } = this.props;
    const { formData } = this.state;
    const attachments = formData.attachments;
    this.changeValue("attachments", [
      ...attachments,
      { idReplace, name: file.name }
    ]);
    actions.uploadAttachment({
      file,
      callBack,
      idReplace,
      data,
      parentId: chemical ? chemical.id : null
    });
  };

  onDeleteAttachment = id => {
    const newAttachments = this.state.formData.attachments.filter(
      a => a.id !== id && a.idReplace !== id
    );

    this.changeValue("attachments", newAttachments);
  };

  render() {
    const {
      chemical,
      chemicalsTypes,
      cropsFetching,
      modal,
      isUploadingAttachment,
      formRef,
      className,
      onSubmit
    } = this.props;
    const {
      formData,
      addDisabled,
      duplicatedCropAndPurpose,
      cropsOptions,
      showConfirm,
      errorsInSegments
    } = this.state;

    const id = chemical ? chemical.id : null;

    const columnMiddle = modal
      ? {
          width: 16
        }
      : {
          mobile: 16,
          tablet: 16,
          computer: 10,
          largeScreen: 10,
          widescreen: 10
        };

    const columnMiddleRight = modal
      ? {
          width: 16
        }
      : {
          mobile: 16,
          tablet: 16,
          computer: 6,
          largeScreen: 6,
          widescreen: 6
        };

    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        loading={false}
        ref={formRef || this.formRef}
        onChange={this.checkAddDisabled}
        className={className}
        onInvalidSubmit={this.handleErrors}
      >
        <div className={`${styles.sprayGrid} ${modal ? styles.forModal : ""}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <SegmentClosable
                title="Chemical Information"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required>
                        <label htmlFor="name">Name</label>
                        <Input
                          name="name"
                          id="name"
                          value={formData.name}
                          {...(!id ? { autoFocus: true } : {})}
                          required
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Name"
                          })}
                          errorLabel={ErrorLabel}
                          onChange={event =>
                            this.changeValue("name", event.target.value)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column {...columnMiddleRight}>
                      <Form.Field required>
                        <SwitchButton
                          name="matter"
                          onChange={this.changeValue}
                          value={formData.matter}
                          items={matterItems}
                          title="Chemical Matter"
                        />
                        <Input
                          type="hidden"
                          name="matter"
                          required
                          value={formData.matter}
                          className={styles.inputHidden}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Chemical Matter"
                          })}
                          errorLabel={ErrorLabel}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required>
                        <label>Chemical Type</label>
                        <Select
                          name="type"
                          value={formData.type.value}
                          fluid
                          options={chemicalsTypes}
                          required
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Type"
                          })}
                          errorLabel={ErrorLabel}
                          placeholder="Select the type"
                          onChange={(event, data) =>
                            this.changeValue(
                              "type",
                              data.options.find(
                                option => option.value === data.value
                              )
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column {...columnMiddleRight}>
                      <Form.Field required>
                        <SwitchButton
                          name="rateType"
                          onChange={this.changeValue}
                          value={formData.rateType}
                          items={rateTypeItems}
                          title="Rate type"
                        />
                        <Input
                          type="hidden"
                          name="rateType"
                          required
                          value={formData.rateType}
                          className={styles.inputHidden}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Rate Type"
                          })}
                          errorLabel={ErrorLabel}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Group widths="equal">
                        <Form.Field required className="field-short">
                          <label htmlFor="price">Price per Kg/L</label>
                          <Input
                            name="price"
                            id="price"
                            value={formData.price}
                            label="$"
                            labelPosition="left"
                            required
                            fluid
                            validations="isNotNegativeFloat"
                            validationErrors={validationErrors({
                              isDefaultRequiredValue: "Price per Kg/L"
                            })}
                            errorLabel={ErrorLabel}
                            onChange={event =>
                              this.changeValue("price", event.target.value)
                            }
                          />
                        </Form.Field>
                        <Form.Field required className="field-short">
                          <label htmlFor="reEntry">Re-Entry</label>
                          <Input
                            value={formData.reEntry}
                            id="reEntry"
                            name="reEntry"
                            className="dropdown field-short"
                            labelPosition="right"
                            onChange={event =>
                              this.changeValue("reEntry", event.target.value)
                            }
                            required
                            validations="isNotNegativeFloat"
                            validationErrors={validationErrors({
                              isDefaultRequiredValue: "Re-Entry"
                            })}
                            errorLabel={ErrorLabel}
                            label={<DaysLabel />}
                            fluid
                          />
                        </Form.Field>
                      </Form.Group>
                    </Grid.Column>
                    <Grid.Column {...columnMiddleRight}>
                      <Form.Field>
                        <label>Batch Required</label>
                        <Checkbox
                          toggle
                          name="batchRequired"
                          className={styles.checkBox}
                          defaultChecked={formData.batchRequired}
                          onChange={() =>
                            this.changeValue(
                              "batchRequired",
                              !formData.batchRequired
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>

              <SegmentClosable
                title="Crops Rates"
                errors={errorsInSegments.cropsRates}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <div className={styles.tableHolder}>
                        <CropTable
                          formData={formData}
                          cropsOptions={cropsOptions}
                          cropsFetching={cropsFetching}
                          allFieldsEmpty={this.checkIfEmpty()}
                          addDisabled={addDisabled}
                          duplicatedCropAndPurpose={duplicatedCropAndPurpose}
                          removeCropRow={this.showRemoveCropRowConfirm}
                          changeValue={this.changeValue}
                          addCropRow={this.addCropRow}
                          clearCropRow={this.clearCropRow}
                          editCropRow={this.editCropRow}
                          updateValue={this.updateValue}
                          validRow={this.validRow}
                          updateCropRow={this.updateCropRow}
                          setAddNewCropUpdateValidation={
                            this.setAddNewCropUpdateValidation
                          }
                          setAddCropUpdateValidation={
                            this.setAddCropUpdateValidation
                          }
                          modal={modal}
                        />

                        <Input
                          name="allCropsSet"
                          value={this.validateCrops()}
                          type="hidden"
                          className={styles.inputHidden}
                          validations="isTrue"
                          validationErrors={{
                            isTrue: "Crops should be defined"
                          }}
                          errorLabel={ErrorLabel}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
              <SegmentClosable title="Chemical Properties">
                <ChemicalProperties
                  errorLabel={ErrorLabel}
                  onChangeValue={this.changeValue}
                  properties={formData.properties}
                  columnMiddle={columnMiddle}
                  columnMiddleRight={columnMiddleRight}
                />
              </SegmentClosable>
              <SegmentClosable title="Additional Information">
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field>
                        <label htmlFor="sds">SDS</label>
                        <Input
                          value={formData.sds}
                          placeholder="http://www.sds.com/sds.pdf"
                          id="sds"
                          name="sds"
                          className="dropdown"
                          validations="isURL"
                          onChange={event =>
                            this.changeValue("sds", event.target.value)
                          }
                          validationErrors={validationErrors({
                            isURL: ""
                          })}
                          errorLabel={ErrorLabel}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field>
                        <label htmlFor="label">Label</label>
                        <Input
                          value={formData.label}
                          placeholder="http://www.lables.com/label.pdf"
                          id="label"
                          name="label"
                          validations="isURL"
                          onChange={event =>
                            this.changeValue("label", event.target.value)
                          }
                          validationErrors={validationErrors({
                            isURL: ""
                          })}
                          errorLabel={ErrorLabel}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <label>Attachments</label>
                        <Attachments
                          attachments={formData.attachments}
                          onDelete={this.onDeleteAttachment}
                          onFileSelected={this.uploadChemicalAttachment}
                          callBack={this.attachmentLoadCallback}
                          multiply
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field>
                        <label htmlFor="notes">Notes</label>
                        <TextArea
                          name="notes"
                          value={formData.notes}
                          id="notes"
                          onChange={event =>
                            this.changeValue("notes", event.target.value)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
            </div>
          </div>
          {onSubmit && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                <Button
                  disabled={isUploadingAttachment}
                  primary
                  type="button"
                  size="large"
                  onClick={onSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
        <Confirm
          open={showConfirm}
          onConfirm={this.onConfirm}
          onCancel={this.hideConfirm}
        />
      </Form>
    );
  }
}

ChemicalForm.propTypes = {
  route: PropTypes.object.isRequired,
  formRef: PropTypes.object,
  crops: PropTypes.object,
  actions: PropTypes.object,
  chemical: PropTypes.object,
  chemicalsTypes: PropTypes.array,
  className: PropTypes.string,
  cropsFetching: PropTypes.bool,
  isUploadingAttachment: PropTypes.bool,
  modal: PropTypes.bool,
  onSubmit: PropTypes.func,
  onValidSubmit: PropTypes.func
};

ChemicalForm.defaultProps = {
  route: { name: "{%name%}" },
  modal: false,
  className: ""
};

function mapStateToProps(state) {
  const {
    crops: { isFetching: cropsFetching },
    sprayDiary: {
      chemicalsTypes: { isFetching: chemicalTypesFetching }
    },
    chemical: { isUploadingAttachment }
  } = state;
  return {
    crops: cropsSelector(state),
    chemicalsTypes: chemicalsTypesSelector(state),
    dilutionRateTypes: dilutionRateTypesSelector(state),
    periodUnits: periodUnitsSelector(state),
    cropsFetching,
    chemicalTypesFetching,
    isUploadingAttachment
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { getCropsList, addNewChemical, uploadAttachment },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChemicalForm);
