import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import ShedSidebar from "./components/Sidebar/Sidebar";
import ShedModal from "./components/Modal";
import TablePageHolder from "../../../components/TablePageHolder/TablePageHolder";
import ShedsActionButtons from "./components/ShedsActionButtons";
import {
  fetchSheds,
  changeArchivedStatus,
  setActiveItemId,
  fetchListSheds,
  deleteShedError,
  getAllOperationsList,
} from "actions/Sheds";
import { matterToUnits } from "utils/constToUnits";
import { fetchChemicalsList } from "actions/Chemicals";
import { abilitiesSelector } from "selectors/user";
import { activeItemSelector } from "selectors/sheds";
import { getShedsTableColumns } from "utils/sheds";
import { Button, Icon, Modal } from "semantic-ui-react";
import { keyBy } from "lodash";
import { shedShape } from "../../../constants/Sheds/types";
import { getHashParameters, removeHashParameters } from "utils/hashToObject";
import styles from "./Sheds.module.css";
import { ActionMessage } from "./ActionMessage/ActionMessage";
import { useReactToPrint } from "react-to-print";
import PrintSheds from "./PrintSheds";

const tableColumns = getShedsTableColumns();

const Sheds = ({
  isArchivedView,
  Can,
  isFetching,
  location,
  route,
  tableData,
  chemicalList,
  productList,
  activeItem,
  fetchShedsToggle,
  error,
  printData,
  operations,
  navigate,
}) => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [filtersList, setFiltersList] = useState([]);
  const [archivedStatus, setArchivedStatus] = useState(false);
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const [isCreateUpdateModalShown, setCreateUpdateModalShown] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);

  const selectedShed = !!selectedItemsIds.length
    ? {
        content: printData
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((_, index) => !!keyBy(selectedItemsIds)[index]),
      }
    : {
        content: printData.sort((a, b) => a.name.localeCompare(b.name)),
      };
  const filtredOperation = operations.filter((item) =>
    printData
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((_, index) => !!keyBy(selectedItemsIds)[index])
      .some((i) => i.id === item.shedId)
  );

  const bucketsList = (isArchivedView
    ? selectedShed.content.filter((i) => i.archived)
    : selectedShed.content
  )
    .map((item) => item.buckets)
    .flat()
    .map((item) => ({
      ...item,
      quantity: item.currentQuantity,
    }));
  const newData = !!selectedItemsIds.length
    ? [...bucketsList, ...filtredOperation]
    : [...bucketsList, ...operations];

  let params = getHashParameters(location) || null;

  const fetchPrintoutAllInformation = () => {
    return dispatch(fetchListSheds(!isArchivedView, filtersList)).then(() =>
      dispatch(getAllOperationsList(tableData.content.map((i) => i.id)))
    );
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getData = useCallback(
    (params) => {
      setFiltersList(params);
      setSelectedItems([]);
      dispatch(fetchSheds({ ...params, archived: isArchivedView }));
      dispatch(setActiveItemId(null));
      dispatch(fetchChemicalsList(true));
      setArchivedStatus(false);
    },
    [fetchSheds, fetchShedsToggle, archivedStatus, isArchivedView]
  );

  const selectedProducts = useMemo(() => {
    const idsMap = keyBy(selectedItemsIds);
    return tableData.content.filter((_, index) => !!idsMap[index]);
  }, [selectedItemsIds]);

  const onArchiveClick = useCallback(() => {
    const idsMap = keyBy(selectedItemsIds);
    const itemsIds = tableData.content
      .filter((_, index) => !!idsMap[index])
      .map(({ id }) => id);
    setSelectedItems([]);
    dispatch(changeArchivedStatus(itemsIds, !isArchivedView));
    setArchivedStatus(true);
  }, [selectedItemsIds, tableData.content]);

  const breadcrumbSections = [
    { content: "Shed and fertiliser", active: true },
    { content: route.name, active: false },
  ];

  const onRowClick = useCallback(
    (event, row) => {
      if (!activeItem && row) {
        dispatch(setActiveItemId(row.original.id));
      }
    },
    [activeItem]
  );
  useEffect(() => {
    if (!activeItem && !!params.id && !!tableData.content.length) {
      dispatch(setActiveItemId(params.id));
    }
  }, [tableData, params.id]);

  const groups = newData.reduce((groups, item) => {
    const group = groups[item.chemicalId] || [];
    group.push(item);
    groups[item.chemicalId] = group;
    return groups;
  }, {});

  const gruppedArray = Object.keys(groups).map(function(k) {
    return groups[k];
  });

  const allProducts = gruppedArray.map((item) => {
    const productName = productList.find((i) => i.id === item[0].chemicalId)
      ?.name;
    const activeIngredients = productList.find(
      (i) => i.id === item[0].chemicalId
    )?.activeIngredients;
    const matter = productList.find((i) => i.id === item[0].chemicalId)?.matter;
    const type = productList.find((i) => i.id === item[0].chemicalId)?.type
      .type;
    const expectedInventory = item.reduce(
      (prev, curr) => prev + curr.quantity,
      0
    );
    const price =
      item.reduce((prev, curr) => prev + curr.price * curr.quantity, 0) /
      expectedInventory;
    return {
      chemicalId: item[0].chemicalId,
      productName,
      activeIngredients,
      matter,
      type,
      expectedInventory,
      price: expectedInventory >= 0 ? price : "-",
      value: expectedInventory >= 0 ? "positive" : "negative",
      unit: matterToUnits(matter),
    };
  });

  const filtredAllProducts = allProducts.reduce((groups, item) => {
    const group = groups[item.value] || [];

    group.push(item);
    groups[item.value] = group;
    return groups;
  }, {});

  const printButton = () => {
    fetchPrintoutAllInformation().then(() => {
      handlePrint();
    });
  };

  return (
    <>
      <div style={{ display: "none" }}>
        <PrintSheds
          title="Multiple sheds inventory"
          negativeData={filtredAllProducts?.negative}
          positiveData={filtredAllProducts?.positive}
          checmicals={productList}
          ref={componentRef}
          sheds={selectedShed}
        />
      </div>
      <Modal
        open={openMessages}
        onClose={() => setOpenMessages(false)}
        className={styles.archivedModal}
      >
        <Modal.Content className={styles.archivedModalContent}>
          <Icon className={styles.infoCircle} name="info circle" />
          {Array.isArray(selectedProducts) && selectedProducts.length > 1 ? (
            <div className={styles.archivedModalContentList}>
              {" "}
              You are not able to archive this sheds as you still have products
              in your Inventory
            </div>
          ) : (
            <div className={styles.archivedModalContentItem}>
              {" "}
              You are not able to archive this shed as you still have products
              in your inventory
            </div>
          )}
          <Button
            className={styles.archivedModalContentButton}
            onClick={() => setOpenMessages(false)}
          >
            OK
          </Button>
        </Modal.Content>
      </Modal>
      <ShedModal
        open={isCreateUpdateModalShown}
        sheds={tableData.content}
        onClose={() => {
          return setCreateUpdateModalShown(false);
        }}
        shed={activeItem}
      />
      <TablePageHolder
        navigate={navigate}
        actionsButtons={
          <div className={styles.buttonWrapper}>
            <Button
              disabled={isFetching}
              size="small"
              floated="right"
              className={styles.printButton}
              onClick={() => {
                printButton();
              }}
            >
              <div className={styles.iconWrapper}>
                <Icon name="print" />
                Print
              </div>
            </Button>
            {selectedItemsIds.length ? (
              <ShedsActionButtons
                archiveButtonTitle={isArchivedView ? "Restore" : "Archive"}
                archiveButtonIcon="archive"
                onArchiveButtonClick={onArchiveClick}
                selectedProducts={selectedProducts}
                setOpenMessages={setOpenMessages}
                withModal={!isArchivedView}
              />
            ) : null}
          </div>
        }
        breadcrumbSections={breadcrumbSections}
        getData={getData}
        isFetching={isFetching}
        location={location}
        mainButton={
          !isArchivedView && (
            <Can I="add" a="sheds">
              <Button
                primary
                className={styles.addButton}
                onClick={() => setCreateUpdateModalShown(true)}
              >
                Add
              </Button>
            </Can>
          )
        }
        onRowClick={onRowClick}
        onSidebarHidden={() => {
          return (
            removeHashParameters(location, ["id"], null, navigate),
            dispatch(setActiveItemId(null))
          );
        }}
        sidebarShown={!!activeItem || !!params.id}
        pageTitle={route.name}
        route={route}
        selectedItems={selectedItemsIds}
        setSelectedItems={setSelectedItems}
        sidebarComponent={
          <ShedSidebar
            shed={activeItem}
            setOpenMessages={setOpenMessages}
            sheds={tableData.content}
            isArchivedView={isArchivedView}
            chemicalList={chemicalList}
            getData={getData}
            location={location}
            onEditClick={() => {
              setCreateUpdateModalShown(true);
            }}
          />
        }
        tableColumns={tableColumns}
        tableData={tableData}
      />
      {error && (
        <ActionMessage
          onClose={() => dispatch(deleteShedError())}
          errorMessages={error.message}
        />
      )}
    </>
  );
};
Sheds.propTypes = {
  Can: PropTypes.func,
  isArchivedView: PropTypes.bool,
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(shedShape),
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  fetchSheds: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object,
};
export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  return {
    isArchivedView,
    printData: state.shed.list.content,
    operations: state.shed.pendingOperations.content,
    tableData: state.shed.data,
    filterValues: state.shed.filters,
    chemicalList: state.chemical.list,
    productList: state.chemical.list.content,
    isFetching: state.shed.data.isFetching,
    Can: abilitiesSelector(state),
    error: state.shed.error,
    fetchShedsToggle: state.shed.fetchShedsToggle,
    activeItem: activeItemSelector(state),
  };
})(Sheds);
