import React, { PureComponent } from "react";
import { Grid, Header, Button, Icon } from "semantic-ui-react";
import styles from "./Sidebar.module.css";
import { isEmptyValue } from "../../../../utils/tasksUtils";
import TaskRow from "../../../SprayDiary/TaskDetailsMobile/TaskRow";
import { calcNotAllocatedPallets } from "../Stock";
import AllocatePallets from "./AllocatePallets";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class Sidebar extends PureComponent {
  render() {
    const { stock, setActiveItem, id } = this.props;
    return stock ? (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column className={styles.wrapper} width={16}>
              <Header as="h1" className={styles.name}>
                #{stock.id}
              </Header>
              <div className={styles.btnWrapper}>
                <Button
                  as={Link}
                  color="green"
                  to={`/harvest/stock/item/${id}`}
                >
                  Edit
                </Button>
                <Icon
                  className={styles.closeIcon}
                  name="close"
                  onClick={() => setActiveItem(null)}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <TaskRow
            label="Variety"
            name="variety"
            value={
              <span>{isEmptyValue(stock.variety && stock.variety.name)}</span>
            }
          />
          <TaskRow
            label="Box unit"
            name="boxUnit"
            value={
              <span>
                {isEmptyValue(stock.boxUnit && stock.boxUnit.displayName)}
              </span>
            }
          />
          <TaskRow
            label="Units in stock"
            name="packedBoxesCount"
            value={<span>{isEmptyValue(stock.packedBoxesCount)}</span>}
          />
          <TaskRow
            label="Units on pallet"
            name="unitsPerPallet"
            value={
              <span>
                {isEmptyValue(stock.boxUnit && stock.boxUnit.unitsPerPallet)}
              </span>
            }
          />
          <TaskRow
            label="Not allocated pallets"
            name="notAllocatedPallets"
            value={<span>{isEmptyValue(calcNotAllocatedPallets(stock))}</span>}
          />
          <TaskRow
            label="Pallets in stock"
            name="palletsCount"
            value={<span>{isEmptyValue(stock.palletsCount || 0)}</span>}
          />
        </Grid>
        <AllocatePallets stock={stock} />
      </>
    ) : (
      <div>No Data</div>
    );
  }
}

Sidebar.propTypes = {
  stock: PropTypes.object.isRequired,
};

export default Sidebar;
