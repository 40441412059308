import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

import {
  addWeatherStation,
  updateWeatherStation,
} from "../../../../actions/WeatherStation/";
import { history } from "../../../../store";
import {
  getErrorFields,
  validationErrors,
} from "../../../../utils/validationRules";
import ErrorLabel from "../../../../components/ErrorLabel";
import Alert from "../../../../components/Alert";
import { abilitiesSelector } from "../../../../selectors/user";
import SegmentClosable from "../../../../components/SegmentClosable";
import { scrollIntoError } from "../../../../utils/scrollIntoError";
import styles from "./WeatherStationForm.module.css";

const initialValues = {
  stationId: "",
  stationKey: "",
  displayName: "",
};

class WeatherStationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      weatherStation: props.initialValues ? props.initialValues : initialValues,
      errorsInSegments: {},
    };
    this.formRef = React.createRef();
    this.isSubmitted = false;
  }

  changeValue = (fieldName, value) => {
    const { weatherStation } = this.state;
    this.setState({
      weatherStation: {
        ...weatherStation,
        [fieldName]: value,
      },
    });
  };

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name || errorFields.ratePerHour,
        },
      },
    });
    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = async () => {
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;
    const {
      actions: { addWeatherStation, updateWeatherStation },
      onSubmitForm,
    } = this.props;
    const { weatherStation } = this.state;

    const weatherStationData = _.omit(weatherStation, ["id", "isFetching"]);

    this.setState({
      errorsInSegments: {},
    });

    let res;

    if (weatherStation.id) {
      res = await updateWeatherStation(
        weatherStation.id,
        weatherStationData,
        true
      );
    } else {
      res = await addWeatherStation(weatherStationData, true);
    }
    this.isSubmitted = false;

    if (!res.error) {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        this.props.navigate("/weather_stations");
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  render() {
    const { modal, error, Can, showMobileHeader, formRef } = this.props;
    const { weatherStation, errorsInSegments } = this.state;

    const columnMiddle = modal
      ? {
          width: 16,
        }
      : {
          mobile: 16,
          tablet: 16,
          computer: 10,
          largeScreen: 10,
          widescreen: 10,
        };
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid} ${modal ? styles.forModal : ""}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <SegmentClosable
                title="Weather station information"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        {...(!weatherStation || !weatherStation.id
                          ? { autoFocus: true }
                          : {})}
                        label="Station ID"
                        placeholder="Station ID"
                        name="stationId"
                        size="large"
                        required
                        disabled={weatherStation.id}
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Station ID",
                        })}
                        errorLabel={ErrorLabel}
                        value={weatherStation.stationId}
                        onChange={(event) =>
                          this.changeValue(
                            "stationId",
                            event.target.value.toUpperCase()
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {!weatherStation.id && (
                    <Grid.Row>
                      <Grid.Column {...columnMiddle}>
                        <Form.Input
                          fluid
                          label="Station key"
                          placeholder="Station key"
                          name="stationKey"
                          size="large"
                          required
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Station Key",
                          })}
                          errorLabel={ErrorLabel}
                          value={weatherStation.stationKey}
                          onChange={(event) =>
                            this.changeValue("stationKey", event.target.value)
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        label="Display name"
                        placeholder="Display name"
                        name="displayName"
                        size="large"
                        required
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Display name",
                        })}
                        errorLabel={ErrorLabel}
                        value={weatherStation.displayName}
                        onChange={(event) =>
                          this.changeValue("displayName", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                {weatherStation.id ? (
                  <Can I="update" a="weather_stations">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                ) : (
                  <Can I="add" a="weather_stations">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                )}
              </div>
            </div>
          )}
        </div>
        <Alert
          open={!!error}
          size="mini"
          onClose={this.closeAlert}
          mainContent={<p>Error occurred</p>}
        />
      </Form>
    );
  }
}

WeatherStationForm.propTypes = {
  initialValues: PropTypes.object,
  actions: PropTypes.object,
  formRef: PropTypes.func,
  onSubmitForm: PropTypes.func,
  showMobileHeader: PropTypes.bool,
  error: PropTypes.bool,
  modal: PropTypes.bool,
  Can: PropTypes.func,
};

const mapStateToProps = (state) => {
  const {
    weatherStation: { error },
  } = state;
  return {
    error,
    Can: abilitiesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addWeatherStation,
      updateWeatherStation,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(WeatherStationForm);
