import PropTypes from "prop-types";
import React, { useEffect } from "react";
import LayoutOverlay from "../../../components/LayoutOverlay";
import TaskIndicator, { STATE } from "./components/TaskIndicator";
import styles from "./styles.module.css";
import { Grid } from "semantic-ui-react";
import {
  GeneralStep,
  InstructionsStep,
  ReviewAndCreateStep,
  SwitchableStep,
} from "./steps";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  moveToStage,
  setGeneralTaskToForm,
} from "../../../actions/GeneralTaskCreator/taskCreator";
import {
  generalStageValidation,
  instructionStageValidation,
  switchableStageValidation,
} from "../../../reducers/GeneralTaskCreator";
import { fetchGeneralTask } from "../../../actions/GeneralTasks/generalTasks";
import Blank from "../../Blank";
import { abilitiesSelector } from "../../../selectors/user";
import { cropText } from "../../../utils/string";
import Loader from "../../../components/Loader";

const getState = (currentStep, isValidStage, step) => {
  if (isValidStage) return STATE.DONE;
  return currentStep === step ? STATE.IN_PROGRESS : STATE.EMPTY;
};

const isOpen = (currentStep, step) => currentStep === step;

const CreateOrUpdateTask = ({ route, match, location, Can }) => {
  const {
    params: { taskTypeID: rawTaskTypeID },
  } = match;

  const [taskTypeID, taskID] = rawTaskTypeID.split("_");

  const action = taskID ? "UPDATE" : "CREATE";

  const dispatch = useDispatch();
  const taskCreator = useSelector((state) => state.taskCreator, shallowEqual);
  const {
    currentStep,
    taskTypes: { content: taskTypes = [] },
  } = taskCreator;

  const setStage = (stage) => () => dispatch(moveToStage(stage));
  const setGeneralTask = async (id) => {
    const task = await dispatch(fetchGeneralTask(id));
    dispatch(setGeneralTaskToForm(task));
  };

  useEffect(() => {
    if (action === "UPDATE") setGeneralTask(taskID);

    // return () => {
    //   dispatch(clearFormState());
    // };
  }, [action]);

  const subTaskName = taskCreator.taskStages.general.subTaskName;

  const validStages = [
    generalStageValidation(taskCreator.taskStages.general),
    instructionStageValidation(taskCreator.taskStages.instructions),
    switchableStageValidation(currentStep),
  ];

  const taskType = taskTypes.find((t) => +t.id === +taskTypeID);
  if (!taskType) return <Loader />;

  const { allowedFields, mandatoryFields } = taskType;

  const generalTaskStages = [
    { title: "General", Component: GeneralStep },
    { title: "Instructions", Component: InstructionsStep },
    {
      title: taskType.name,
      Component: SwitchableStep,
      additionalProps: { title: taskType.name, taskTypeID },
    },
    // { title: 'Tracking - QR barcodes', Component: TrackingQRBarcodesStep },
    {
      title: `Review and ${action === "CREATE" ? "create" : "update"}`,
      Component: ReviewAndCreateStep,
      additionalProps: { taskTypeID, action, taskID },
    },
  ];

  return (
    <>
      <Can not I={"access_module"} a={"general_tasks"}>
        <Blank route={route} location={location} />
      </Can>
      <Can I={"access_module"} a={"general_tasks"}>
        <LayoutOverlay
          closeLink={action === "CREATE" ? "/tasks" : undefined}
          withGoBack={action !== "CREATE"}
          route={{
            ...route,
            name: `${action === "CREATE" ? "Create" : "Update"} ${
              taskType.name
            }${subTaskName ? ` - ${cropText(subTaskName, 30)}` : ""} Task`,
          }}
          childrenOnly
        >
          <div className={styles.wrapper}>
            <div className={styles.indicatorHolder}>
              {generalTaskStages.map((task, i) => (
                <TaskIndicator
                  onClick={setStage(i + 1)}
                  title={task.title}
                  number={i + 1}
                  state={getState(currentStep, validStages[i], i + 1)}
                />
              ))}
            </div>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  {generalTaskStages.map(
                    ({ Component, additionalProps }, i) => (
                      <Component
                        onArrowClick={setStage(i + 1)}
                        isOpen={isOpen(currentStep, i + 1)}
                        allowedFields={allowedFields}
                        mandatoryFields={mandatoryFields}
                        {...(additionalProps || {})}
                      />
                    )
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </LayoutOverlay>
      </Can>
    </>
  );
};

CreateOrUpdateTask.propTypes = {
  Can: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  route: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    Can: abilitiesSelector(state),
  };
};

export default connect(mapStateToProps)(CreateOrUpdateTask);
