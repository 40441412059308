import React, { Component } from "react";
import { createSelector } from "reselect";
import { get, join, map } from "lodash";
import { matterToUnits } from "utils/constToUnits";
import Numeric from "components/Numeric/Numeric";
import moment from "moment";
const tableDataSelector = state =>
  state.reports.harvest.fieldScanning.contractorReport.data;
const tableListDataSelector = state =>
  state.reports.harvest.fieldScanning.contractorReport.list;
const areasListSelector = state => state.areas.list.content;
const cropsListSelector = state => state.crops.data;
const varietiesListSelector = state => state.varieties.data;
const farmListSelector = state => state.farms.summary;
const harvestListSelector = state =>
  state.harvestUnitsControl.filtersOptions.content;

export const formaterData = (
  data,
  areasList,
  farmsList,
  cropsList,
  varietiesList,
  harvestList
) => {
  const { content, page, ...rest } = data;
  const contentUpdated = content?.length
    ? content.map(block => {
        return {
          ...block?.contractorData.reduce(
            (prev, curr, _, items) => {
              const harvestUnit = harvestList.find(
                item => item.id === curr.harvestUnitId
              );
              const areaName = areasList.find(item => item.id === curr.areaId);
              return {
                contractors: block.contractorName,
                empty: "emptyValue",
                empty1: "emptyValue",
                empty2: "emptyValue",
                numberOfUnits: block.totalQty.toFixed(2),
                empty3: "emptyValue",
                empty4: "emptyValue",
                empty5: "emptyValue",
                totalPrice: block.totalPrice,
                empty6: "emptyValue",
                empty7: "emptyValue",
                empty8: "emptyValue",

                subRows: [
                  ...prev.subRows,
                  {
                    contractors: (
                      <span
                        style={{ paddingLeft: "5px", paddingRight: "15px" }}
                      >
                        {moment(curr.date).format("DD/MM/YYYY")}
                      </span>
                    ),
                    empty: farmsList.find(item => item.farm.id === curr.farmId)
                      ?.farm.name,
                    empty1: curr.employeeIds,
                    empty2: areaName?.parent
                      ? areaName?.parent?.name
                      : areaName?.name,
                    numberOfUnits: areaName?.parent ? areaName?.name : "-",
                    empty3: curr.row,
                    empty4: curr.qty.toFixed(2),
                    empty5: cropsList.find(item => item.id === curr.cropId)
                      ?.name,
                    totalPrice: varietiesList.find(
                      item => item.id === curr.varietyId
                    )?.name,
                    empty6: curr.barcodeId,
                    empty7: `${harvestUnit?.name} ${harvestUnit?.netWeight} Kg`,
                    empty8: (
                      <span style={{ paddingRight: "5px" }}>{curr.price} </span>
                    )
                  }
                ]
              };
            },
            {
              contractors: "",
              empty: "emptyValue",
              empty1: "emptyValue",
              empty2: "emptyValue",
              numberOfUnits: "",
              empty3: "emptyValue",
              empty4: "emptyValue",
              empty5: "emptyValue",
              totalPrice: "",
              empty6: "emptyValue",
              empty7: "emptyValue",
              empty8: "emptyValue",
              subRows: [
                {
                  contractors: (
                    <b
                      style={{
                        paddingLeft: "5px",
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      {" "}
                      Date
                    </b>
                  ),
                  empty: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Farm
                    </b>
                  ),
                  empty1: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Worker
                    </b>
                  ),
                  empty2: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Block
                    </b>
                  ),
                  numberOfUnits: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Patch
                    </b>
                  ),
                  empty3: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Row
                    </b>
                  ),
                  empty4: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Quantity
                    </b>
                  ),
                  empty5: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        paddingRight: "5px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Crop
                    </b>
                  ),
                  totalPrice: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Variety
                    </b>
                  ),
                  empty6: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        paddingRight: "5px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Barcode Id
                    </b>
                  ),
                  empty7: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        paddingRight: "5px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Bin unit
                    </b>
                  ),
                  empty8: (
                    <b
                      style={{
                        height: "48px",
                        borderTop: "1px solid #DEDEDF",
                        paddingRight: "5px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F6F7F8"
                      }}
                    >
                      Price
                    </b>
                  )
                }
              ]
            }
          )
        };
      }, {})
    : [];
  const contentUpdatedNew = contentUpdated
    ? contentUpdated.map(item => {
        return {
          ...item,
          subRows: item.subRows.map((row, index) => {
            if (index === item.subRows.length - 1) {
              row.isSubRowsLastItem = true;
            }
            return row;
          })
        };
      })
    : [];

  return {
    ...rest,
    page,
    content: contentUpdatedNew
  };
};
export const selectTableStructuredData = createSelector(
  [
    tableDataSelector,
    areasListSelector,
    farmListSelector,
    cropsListSelector,
    varietiesListSelector,
    harvestListSelector
  ],
  (tableData, areasList, farmsList, cropsList, varietiesList, harvestList) => {
    return formaterData(
      tableData,
      areasList,
      farmsList,
      cropsList,
      varietiesList,
      harvestList
    );
  }
);

export const selectExpotStructuredData = createSelector(
  [
    tableListDataSelector,
    areasListSelector,
    farmListSelector,
    cropsListSelector,
    varietiesListSelector,
    harvestListSelector
  ],
  (listData, areasList, farmsList, cropsList, varietiesList, harvestList) => {
    return formaterData(
      listData,
      areasList,
      farmsList,
      cropsList,
      varietiesList,
      harvestList
    );
  }
);
