import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import CollapsibleBlock from "components/CollapsibleBlock";
import styles from "../SideBar.module.css";

const GeneralInfo = ({ activeItem }) => {
  return (
    <CollapsibleBlock
      className={styles.generalInfoTitle}
      title="General information"
    >
      <Table className={styles.qcTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.addressRow}>Checked units</Table.Cell>
            <Table.Cell className={styles.addressValueRow}>
              {activeItem?.unitToCheckPerRound || "-"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

GeneralInfo.propTypes = {
  activeItem: PropTypes.object
};

export default GeneralInfo;
