import { createSelector } from "reselect";
import { map } from "lodash";
import { options } from "joi-browser";
const scouting = (state) => state.recommendations;

export const activeItemSelector = createSelector([scouting], (scoutingData) => {
  const { currentTask, data } = scoutingData;
  if (currentTask?.id) {
    return data.content.find(({ id }) => currentTask.id === id);
  }
  return null;
});
