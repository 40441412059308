import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import { Button, Popup } from "semantic-ui-react";
import style from "../routes/HarvestUnitsControl/HarvestUnitsControl.module.css";
import moment from "moment";
import { ROLE_COLORS } from "constants/InventoryMovements";
import { getNameInitials } from "routes/Labour/utils";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
import { StatusChip } from "routes/QualityControlNew/components/statusChip";
import { statusOptions } from "selectors/qualityControl";
import { Chip } from "routes/Reports/Harvest/components/Chip";

export const getQualityControlTableColumns = (
  employees,
  setblockName,
  setimageModalOpen,
  setUrlList,
  isFetching
) => {
  const columnsConfig = {
    date: {
      id: "date",
      title: "Date",
      withSort: false,
      accessor: ({ createdAt }) =>
        createdAt ? moment(createdAt).format("DD/MM/YYYY") : "—",
      filterId: "date",
      filter: {
        title: "Date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching} />
      },
      minWidth: 150
    },
    name: {
      title: "Name",
      customClass: true,
      id: "name",
      withSort: false,
      accessor: ({ name }) => name || "—",
      filterId: "seasons",
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector
      }
    },
    status: {
      title: "Status",
      customClass: true,
      id: "status",
      withSort: false,
      accessor: "status",
      Cell: value => {
        const { qcRounds, rounds } = value.row.original;
        return (
          <StatusChip
            label={qcRounds?.length === rounds ? "Completed" : "In progress"}
          />
        );
      },
      filterId: "status",
      filter: {
        title: "Status",
        type: filterTypes.SingleSelect,
        selector: statusOptions
      }
    },
    barcodeId: {
      title: "Bin ID",
      customClass: true,
      id: "barcodeId",
      withSort: false,
      accessor: ({ barcodeId }) => barcodeId || "—"
    },
    harvestUnits: {
      title: "Harvest unit",
      customClass: true,
      id: "harvestUnits",
      withSort: false,
      accessor: ({ harvestUnit }) =>
        harvestUnit ? `${harvestUnit.name} ${harvestUnit.netWeight} Kg` : "—"
    },
    variety: {
      title: "Crop/Variety",
      customClass: true,
      id: "variety",
      withSort: false,
      accessor: ({ variety }) => {
        return variety ? (
          <div style={{ display: "grid" }}>
            <span style={{ color: "#212121" }}>{variety.crop.name}</span>
            <span style={{ color: "#666666" }}>{variety.name}</span>
          </div>
        ) : (
          "—"
        );
      }
    },
    pickers: {
      title: "Pickers",
      customClass: true,
      id: "pickers",
      withSort: false,
      accessor: ({ employeeBinParts }) => {
        const numberOfEmployees = employeeBinParts?.length;
        const employeeIds = employeeBinParts?.map(item => item.employee?.id);
        const morePopupContent = members => {
          return (
            <div className={style.morePopupContent}>
              {members.length &&
                members &&
                members.slice(3, members.length).map(emp => {
                  const employee =
                    (employees &&
                      emp &&
                      employees.find(employeeObj => employeeObj.id === emp)) ||
                    null;
                  const employeeImg =
                    employee && employee.picture
                      ? employee.picture.presignedUrl
                      : null;
                  return (
                    employee &&
                    !employee.archived && (
                      <div key={employee.id} className={style.popupItem}>
                        <div
                          className={style.avatarHolder}
                          style={
                            employeeImg
                              ? {
                                  backgroundImage:
                                    employeeImg && `url(${employeeImg})`
                                }
                              : {
                                  background: ROLE_COLORS[employee.type]
                                    ? ROLE_COLORS[employee.type]
                                    : "gray"
                                }
                          }
                        >
                          {!employeeImg && getNameInitials(employee)}
                        </div>
                        <span>{`${employee.firstName} ${employee.lastName}`}</span>
                      </div>
                    )
                  );
                })}
            </div>
          );
        };

        return numberOfEmployees ? (
          <ul className={style.avatarsContainer}>
            {employeeIds.slice(0, 3).map((employeeId, index) => {
              const employee =
                (employees &&
                  employees.find(
                    employeeObj => employeeObj.id === employeeId
                  )) ||
                null;
              const employeeImg =
                employee && employee.picture
                  ? employee.picture.presignedUrl
                  : null;

              return employeeImg ? (
                <Popup
                  position="bottom center"
                  inverted
                  key={employee.id || index}
                  trigger={
                    <li
                      className={style.avatarHolder}
                      style={{ backgroundImage: `url(${employeeImg})` }}
                    />
                  }
                  content={`${employee.firstName} ${employee.lastName}`}
                />
              ) : (
                <Popup
                  key={(employee && employee.id) || index}
                  inverted
                  position="bottom center"
                  trigger={
                    <li
                      key={(employee && employee.id) || index}
                      className={style.avatarHolder}
                      style={{
                        background: ROLE_COLORS[employee && employee.type]
                          ? ROLE_COLORS[employee && employee.type]
                          : "gray"
                      }}
                    >
                      {getNameInitials(employee && employee)}
                    </li>
                  }
                  content={`${employee && employee.firstName} ${employee &&
                    employee.lastName}`}
                />
              );
            })}
            {numberOfEmployees > 3 && (
              <Popup
                inverted
                position="right center"
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: "offsetParent"
                  }
                }}
                content={morePopupContent(employeeIds)}
                trigger={<span>+{numberOfEmployees - 3}</span>}
              />
            )}
          </ul>
        ) : (
          "—"
        );
      }
    },
    checkedUnits: {
      title: "Checked units",
      customClass: true,
      id: "checkedUnits",
      withSort: false,
      accessor: ({ unitToCheckPerRound }) => unitToCheckPerRound || "—"
    },
    numberOfRounds: {
      title: "Number of rounds",
      customClass: true,
      id: "rounds",
      withSort: false,
      accessor: ({ rounds }) => <Chip label="Rounds" items={rounds} /> || "—"
    },
    parametrs: {
      title: "Parameters",
      customClass: true,
      id: "parametrs",
      withSort: false,
      accessor: ({ qcRounds }) => {
        const p = [
          ...new Set(
            qcRounds?.length &&
              qcRounds
                .map(({ defectReports }) => defectReports)
                .flat()
                .map(i =>
                  i.parameter
                    .replace(/_/g, " ")
                    .replace(
                      /^(.)(.*)$/,
                      (_, first, rest) =>
                        first.toUpperCase() + rest.toLowerCase()
                    )
                )
          )
        ];
        return <Chip label="Parameters" items={p} /> || "—";
      }
    },
    picture: {
      title: "Photos",
      customClass: true,
      id: "picture",
      type: "number",
      withSort: false,
      accessor: ({ name, qcRounds }) => {
        const imagesUrl =
          qcRounds &&
          qcRounds.length &&
          qcRounds
            .map(item =>
              item?.pictures?.length
                ? item.pictures.map(item => ({
                    url: item.presignedUrl
                  }))
                : []
            )
            .flat();
        return !!imagesUrl?.length ? (
          <div className={style.imageContainer}>
            {imagesUrl.slice(0, 3).map((item, index) => {
              return (
                <Button
                  className={style.imageButton}
                  onClick={event => {
                    return (
                      setUrlList(imagesUrl),
                      setblockName(name ? name : "—"),
                      setimageModalOpen(true),
                      event.stopPropagation()
                    );
                  }}
                >
                  <img className={style.image} src={item.url} />
                </Button>
              );
            })}
            {imagesUrl.length > 3 && `+ ${imagesUrl.length - 3}`}
          </div>
        ) : (
          "—"
        );
      },
      cellWidth: 3
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};
