import React, { useCallback } from "react";
import DatePicker from "react-multi-date-picker";
import { Icon, Input } from "semantic-ui-react";
import useOnClickOutside from "./useOutside";

import "./DateRangePicker.css";

const DateRangePickerComponent = ({ value, disabled, setFieldValue }) => {
  const dateTimeRef = React.useRef();

  const handleDatePickerClose = useCallback(
    () => dateTimeRef.current.closeCalendar(),
    [dateTimeRef]
  );

  useOnClickOutside(dateTimeRef, handleDatePickerClose);

  return (
    <DatePicker
      ref={dateTimeRef}
      disabled={disabled}
      className="range-calendar"
      value={value}
      range
      format={"DD/MM/YYYY"}
      calendarPosition="bottom-center"
      numberOfMonths={2}
      render={
        <Input
          placeholder={"dd/mm/yyyy-dd/mm/yyyy"}
          icon={<Icon name="calendar outline" />}
          iconPosition="right"
        />
      }
      onChange={(_, { validatedValue }) => setFieldValue(validatedValue)}
    />
  );
};

export default DateRangePickerComponent;
