import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";
import { fetchAreasList } from "../../../actions/Areas/areas";
import { Area } from "../../../models/block.model";

const AreaMultiSelectFilter = ({
  actions: { fetchList },
  list,
  isFetching,
  ...extraProps
}) => {
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const options = useMemo(
    () =>
      list
        .filter((area) =>
          extraProps.farmId ? area.farm.id === extraProps.farmId : true
        )
        .map((area) => new Area(area))
        .filter((area) => !area.hasSubAreas)
        .map((item) => ({
          key: item.id,
          text: `${item.parent ? `${item.parent.name} - ` : ""} ${item.name}`,
          value: item.id,
        }))
        .sort((item1, item2) => item1.text.localeCompare(item2.text)),
    [list]
  );
  return (
    <MultiSelectFilter
      options={options}
      numbers={true}
      loading={isFetching}
      {...extraProps}
    />
  );
};

const mapStateToProps = ({
  areas: {
    list: { content, isFetching },
  },
}) => {
  return {
    list: content,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchList: fetchAreasList,
    },
    dispatch
  ),
});

AreaMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaMultiSelectFilter);
