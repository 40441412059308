import React, { useState } from "react";
import styles from "./TasksCustomization.module.css";
import { Icon, Dropdown, Checkbox, Header } from "semantic-ui-react";

const TypeDropdown = ({
  selectedItems,
  options,
  onChange,
  handleClearSelection
}) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  return (
    <Dropdown
      text="More"
      icon={<Icon name="caret down" />}
      multiple
      className={styles.typeDropdown}
      closeOnChange={false}
      open={dropDownOpen}
      onClick={() => setDropDownOpen(!dropDownOpen)}
      onClose={() => setDropDownOpen(false)}
    >
      <Dropdown.Menu className={styles.dropdownMenu}>
        <Dropdown.Menu className={styles.dropdownScrollingMenu} scrolling>
          <Dropdown.Item className={styles.firstItem}>
            *select item to add it to the list
          </Dropdown.Item>
          {options.map(option => {
            return (
              <Dropdown.Item
                className={`${option.className}`}
                key={option.key}
                value={option.value}
                text={option?.text}
                content={
                  <div className={styles.checkboxLabelWrapper}>
                    <Checkbox
                      className={styles.typeCheckbox}
                      label={option.label}
                      checked={
                        Array.isArray(selectedItems) &&
                        selectedItems.some(item => item?.text === option?.text)
                      }
                    />
                    <div className={styles.label}>{option?.text}</div>
                  </div>
                }
                onClick={(e, item) => {
                  return e.stopPropagation(), onChange(option);
                }}
              />
            );
          })}
        </Dropdown.Menu>
        {!!selectedItems?.length && (
          <>
            <Dropdown.Divider />
            <Header
              className={styles.clearButton}
              onClick={() => handleClearSelection()}
            >
              Clear selection
            </Header>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TypeDropdown;
