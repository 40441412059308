import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../../components/Loader";
import { getCropsList } from "../../../actions/Farms/crops";
import { addNewChemical } from "../../../actions/SprayDiary/chemicals";
import { fetchChemicalById, updateChemical } from "../../../actions/Chemicals";
import { cropsSelector } from "../../../selectors/crops";
import {
  chemicalsTypesSelector,
  dilutionRateTypesSelector,
  periodUnitsSelector
} from "../../../selectors/chemicals";
import ChemicalForm from "./ChemicalForm";
import LayoutOverlay from "../../../components/LayoutOverlay";
import styles from "./AddNewChemical.module.css";
import { Button } from "semantic-ui-react";
const EditForm = React.forwardRef((props, ref) => (
  <ChemicalForm {...props} formRef={ref} />
));

class AddNewChemical extends Component {
  constructor(props) {
    super(props);
    const id = _.get(props, "match.params.id") || null;
    this.formRef = React.createRef();
    this.state = {
      isLoading: true,
      cropsOptions: this.props.crops,
      chemical: id && props.chemicals.find(chemical => chemical.id === id),
      editId: id
    };
  }

  componentDidMount() {
    const { crops, actions, chemicalsTypes } = this.props;
    const { editId, chemical } = this.state;

    if (editId && !chemical) {
      actions.fetchChemicalById(editId);
    }
    if (crops.length < 2) {
      actions.getCropsList();
    }
  }

  componentDidUpdate(prevProps) {
    const { crops, chemicals } = this.props;
    const { editId, chemical } = this.state;

    if (prevProps.crops !== crops) {
      this.setState({
        cropsOptions: crops
      });
    }
    if (editId && !chemical) {
      const chemicalToEdit = chemicals.find(
        chemical => chemical.id === Number(editId)
      );

      if (chemicalToEdit) {
        this.setState({
          chemical: chemicalToEdit
        });
      }
    }
  }

  onValidSubmit = async data => {
    const { editId } = this.state;
    const { actions } = this.props;
    let res = null;
    if (editId) {
      res = await actions.updateChemical(editId, data);
    } else {
      res = await actions.addNewChemical(data);
    }
    if (res && !res.error) {
      this.props.navigate("/chemicals");
    }
  };

  onSubmit = () => {
    this.formRef.current.submit();
  };

  render() {
    const { route } = this.props;
    const { editId, chemical } = this.state;

    return (
      <LayoutOverlay
        closeLink="/chemicals"
        route={{
          ...route,
          name: editId ? route.name.replace("Add New", "Edit") : route.name
        }}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            <Button primary size="large" type="button" onClick={this.onSubmit}>
              Save
            </Button>
          </div>
        }
      >
        {!editId || chemical ? (
          <EditForm
            ref={this.formRef}
            {...(chemical ? { chemical } : null)}
            onValidSubmit={this.onValidSubmit}
            onSubmit={this.onSubmit}
          />
        ) : (
          <Loader />
        )}
      </LayoutOverlay>
    );
  }
}

AddNewChemical.propTypes = {
  route: PropTypes.object.isRequired,
  crops: PropTypes.array,
  chemicalsTypes: PropTypes.array,
  dilutionRateTypes: PropTypes.array,
  periodUnits: PropTypes.array,
  cropsFetching: PropTypes.bool,
  chemicals: PropTypes.array,
  actions: PropTypes.object
};

AddNewChemical.defaultProps = {
  route: { name: "{%name%}" }
};

function mapStateToProps(state) {
  const {
    crops: { isFetching: cropsFetching },
    chemical: {
      data: { content },
      error
    }
  } = state;
  return {
    crops: cropsSelector(state),
    chemicalsTypes: chemicalsTypesSelector(state),
    dilutionRateTypes: dilutionRateTypesSelector(state),
    periodUnits: periodUnitsSelector(state),
    cropsFetching,
    chemicals: content,
    error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getCropsList,
        addNewChemical,
        fetchChemicalById,
        updateChemical
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewChemical);
