import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import "react-table-6/react-table.css";

import { bindActionCreators } from "redux";

import styles from "./Contractors.module.css";
import { abilitiesSelector } from "../../../selectors/user";
import {
  deleteContractor,
  fetchContractors,
  contractorsStatusChange,
  deleteContractors,
} from "../../../actions/Contractors/contractors";
import { fetchEmployeesList } from "../../../actions/Employee/employees";
import Sidebar from "./Sidebar";
import AddEditContractorModal from "./AddEditContractorModal/AddEditContractorModal";
import { Button, Popup } from "semantic-ui-react";
import TablePageHolder from "../../../components/TablePageHolder/TablePageHolder";
import { getHashParameter } from "../../../utils/hashToObject";
import _, { keyBy, isEmpty } from "lodash";
import ConfirmationModal from "../components/ConfirmationModal/ConfirmationModal";
import TableActionButtons from "../components/TableActionButtons/TableActionButtons";
import { ROLE_COLORS } from "../constants";
import BasicCell from "components/Table/components/BasicCell";
import { getNameInitials } from "../utils";
import { ActionMessage } from "../components/ActionMessage/ActionMessage";
import { isEmptyValue } from "../../../utils/tasksUtils";
import { DeletedMessage } from "../components/ActionMessage/DeletedMessage";
import Numeric from "components/Numeric/Numeric";

class Contractors extends Component {
  state = {
    tableData: [],
    tableColumns: [],
    sideBarShown: false,
    activeItem: {},
    activeItemId: null,
    addEditModalOpen: false,
    editItemId: null,
    selectedItems: [],
    tempArchivedItems: [],
    tempDeletedItems: [],
    archiveItemId: null,
    confirmationModalOpen: false,
    actionType: "",
    dataRefetch: 1,
  };

  componentDidMount() {
    this.prepareData();
    this.props.actions.fetchEmployeesList();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { data } = this.props;

    if (prevProps.data !== data) this.prepareData();
  }

  getData = (params) => {
    const { isArchivedView, actions } = this.props;
    this.setSelectedItems([]);
    actions.fetchAction({ ...params, archived: isArchivedView });
  };

  morePopupContent = (members) => {
    const { employees } = this.props;

    return (
      <div className={styles.morePopupContent}>
        {members.length &&
          members &&
          members.slice(3, members.length).map((emp) => {
            const employee =
              (employees &&
                employees.find((employeeObj) => employeeObj.id === emp)) ||
              null;
            const employeeImg =
              employee && employee.picture
                ? employee.picture.presignedUrl
                : null;

            return (
              <div key={employee && employee.id} className={styles.popupItem}>
                <div
                  className={styles.avatarHolder}
                  style={
                    employeeImg
                      ? {
                          backgroundImage: employeeImg && `url(${employeeImg})`,
                        }
                      : {
                          background: ROLE_COLORS[employee && employee.type]
                            ? ROLE_COLORS[employee && employee.type]
                            : "gray",
                        }
                  }
                >
                  {!employeeImg && getNameInitials(employee)}
                </div>
                <span>{`${employee && employee.firstName} ${employee &&
                  employee.lastName}`}</span>
              </div>
            );
          })}
      </div>
    );
  };

  prepareData = () => {
    const { data, employees, isArchivedView } = this.props;
    const columnsNames = {
      name: {
        withSort: true,
        type: "string",
        id: "name",
        title: "Name",
        cellWidth: isArchivedView ? 7 : 5,
      },
      rate: {
        withSort: true,
        type: "number",
        id: "rate",
        title: "Rate ($/hr)",
        cellWidth: isArchivedView ? 7 : 4,
        accessor: (row) =>
          row.rate && (
            <>
              <Numeric
                fractionDigits={Number(row.rate) > 1000 ? 0 : 2}
                value={Number(row.rate)}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
                defaultValue="-"
              />{" "}
              /hr
            </>
          ),
      },
      ...(!isArchivedView
        ? {
            numberOfWorkers: {
              title: "Number of workers",
              cellWidth: 3,
              accessor: (row) => `${row.numberOfEmployees}`,
            },
            workers: {
              id: "workers",
              title: "Workers",
              cellWidth: 3,
              disableSortBy: true,
              accessor: (row) =>
                row.numberOfEmployees ? (
                  <ul className={styles.avatarsContainer}>
                    {row.employeeIds &&
                      row.employeeIds.slice(0, 3).map((employeeId, index) => {
                        const employee =
                          (employees &&
                            employees.find(
                              (employeeObj) => employeeObj.id === employeeId
                            )) ||
                          {};
                        const employeeImg =
                          employee && employee.picture
                            ? employee.picture.presignedUrl
                            : null;

                        return employeeImg ? (
                          <Popup
                            key={employee.id}
                            position="bottom center"
                            inverted
                            trigger={
                              <li
                                key={employee.id ? employee.id : index}
                                className={styles.avatarHolder}
                                style={{
                                  backgroundImage: `url(${employeeImg})`,
                                }}
                              />
                            }
                            content={`${employee.firstName} ${employee.lastName}`}
                          />
                        ) : (
                          <Popup
                            key={employee.id}
                            position="bottom center"
                            inverted
                            trigger={
                              <li
                                key={isEmptyValue(employee.id)}
                                className={styles.avatarHolder}
                                style={{
                                  background: ROLE_COLORS[employee.type]
                                    ? ROLE_COLORS[employee.type]
                                    : "gray",
                                }}
                              >
                                {!!isEmpty(employee)
                                  ? "-"
                                  : getNameInitials(employee)}
                              </li>
                            }
                            content={`${employee.firstName} ${employee.lastName}`}
                          />
                        );
                      })}
                    {row.numberOfEmployees > 3 && (
                      <Popup
                        inverted
                        position="right center"
                        popperModifiers={{
                          preventOverflow: {
                            boundariesElement: "offsetParent",
                          },
                        }}
                        content={this.morePopupContent(row.employeeIds)}
                        trigger={<span>+{row.numberOfEmployees - 3}</span>}
                      />
                    )}
                  </ul>
                ) : (
                  "—"
                ),
            },
          }
        : {}),
      actions: {
        title: "",
        cellWidth: 2,
        accessor: (d) => d.id,
        className: `textRight actions ${styles.actionCells}`,
        disableSortBy: true,
      },
    };
    const tableColumns = [];

    const tableData = [];

    Object.entries(columnsNames).map(([id, targetColumn]) => {
      const columnToAdd = {
        id,
        ...targetColumn,
        accessor: targetColumn.accessor || id,
        Header: targetColumn.title,
        Cell: targetColumn.Cell || BasicCell,
        className: `cell_${targetColumn.cellWidth} ${targetColumn.className ||
          ""}`,
        ...(targetColumn.sortFields
          ? { sortFields: targetColumn.sortFields }
          : {}),
        disableSortBy: targetColumn.disableSortBy,
        cellWidth: targetColumn.cellWidth,
      };

      tableColumns.push(columnToAdd);
    });

    if (data && data.content) {
      data.content.forEach((row) => {
        const rowData = {
          rights: {
            update: "update",
            delete: "delete",
          },
          numberOfEmployees: row.employeeIds.length,
        };
        Object.keys(row).forEach((key) => {
          switch (key) {
            default:
              rowData[key] = row[key] || "";
              break;
          }
        });
        tableData.push(rowData);
      });
    }
    this.setState({
      tableData,
      tableColumns,
    });
  };

  updateTable = () => {
    this.setState({ dataRefetch: this.state.dataRefetch + 1 });
  };

  setCreateUpdateModalShown = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState((prevState) => ({
      addEditModalOpen: true,
      editItemId: !!value && value,
    }));
  };

  setCreateUpdateModalHidden = () => {
    this.setState({
      addEditModalOpen: false,
    });
  };

  setSelectedItems = (items) => {
    this.setState({ selectedItems: [...items] });
  };

  selectedContractors = () => {
    const { selectedItems } = this.state;
    const idsMap = keyBy(selectedItems);

    return this.state.tableData.filter((_, index) => !!idsMap[index]);
  };

  onArchiveItems = () => {
    const { selectedItems } = this.state;
    const { isArchivedView, actions } = this.props;
    const idsMap = keyBy(selectedItems);

    const itemsIds = this.state.tableData
      .filter((_, index) => !!idsMap[index])
      .map(({ id }) => id);
    this.setSelectedItems([]);
    this.setState({
      tempArchivedItems: itemsIds,
    });
    actions.archiveAction(!isArchivedView, itemsIds).then(this.updateTable);
  };

  onDeleteItems = () => {
    const { selectedItems } = this.state;
    const { actions, error } = this.props;
    const idsMap = keyBy(selectedItems);

    const itemsIds = this.state.tableData
      .filter((_, index) => !!idsMap[index])
      .map(({ id }) => id);
    this.setSelectedItems([]);
    actions
      .deleteContractors(itemsIds)
      .then(() => {
        if (!error) {
          this.setState({
            tempDeletedItems: itemsIds,
          });
        }
      })
      .then(this.updateTable);
  };

  onChangeItem = () => {
    const { archiveItemId } = this.state;
    const { isArchivedView, actions, error, contractors } = this.props;
    if (this.state.actionType === "Delete") {
      actions
        .deleteAction(archiveItemId)
        .then(() => {
          if (!error) {
            this.setState({
              tempDeletedItems: [archiveItemId],
            });
          }
          this.setState({
            confirmationModalOpen: false,
            activeItemId: null,
          });
        })
        .then(this.updateTable);
    } else {
      actions
        .archiveAction(!isArchivedView, [archiveItemId])
        .then(() => {
          this.setState({
            tempArchivedItems: [archiveItemId],
          });
          this.setState({
            confirmationModalOpen: false,
          });
        })
        .then(this.updateTable);
    }
  };

  actionMessageUndo = () => {
    const { tempArchivedItems } = this.state;
    const { isArchivedView, actions } = this.props;

    actions
      .archiveAction(isArchivedView, [tempArchivedItems])
      .then(this.updateTable);
    this.setState({
      tempArchivedItems: [],
    });
  };

  onArchiveItemClick = (e, value, actionType) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      confirmationModalOpen: true,
      archiveItemId: value && value,
      actionType,
    });
  };

  onConfirmationModalClose = () => {
    this.setState({
      confirmationModalOpen: false,
    });
  };

  getContractorReadable = (archiveContractorId) =>
    archiveContractorId
      ? `Contractor-${String(archiveContractorId).padStart(3, "0")}`
      : null;

  renderCell = (props) => {
    const {
      value,
      column: { Header },
    } = props;

    return (
      <div>
        <div className="cellTitle hide-md">{Header}</div>
        <div className="cellValue">{value}</div>
      </div>
    );
  };

  onRowClick = (e, row) => {
    if (row) {
      this.setState((prevState) => ({
        ...prevState,
        activeItem: row.original,
        activeItemId: row.original.id,
      }));
    }
  };

  clearActiveItemId = () => {
    this.setState((prevState) => ({
      ...prevState,
      activeItemId: null,
      activeItem: {},
    }));
  };

  render() {
    const {
      route,
      location,
      content,
      currentPage,
      totalPages,
      isFetching,
      Can,
      totalElements,
      isArchivedView,
      navigate
    } = this.props;
    const {
      activeItemId,
      confirmationModalOpen,
      tableData,
      archiveItemId,
      tableColumns,
      updateTable,
      editItemId,
      selectedItems,
      addEditModalOpen,
      tempArchivedItems,
      tempDeletedItems,
      actionType,
    } = this.state;

    const expandedContent =
      activeItemId && content.find((e) => e.id === activeItemId);
    const itemToArchive =
      archiveItemId &&
      content.find((contractor) => contractor.id === archiveItemId);
    const itemToEdit =
      editItemId && content.find((contractor) => contractor.id === editItemId);

    const defaultSort = getHashParameter(location, "sort") || "";

    const breadcrumbSections = [
      { key: "1", content: "Main menu", active: true, href: "/" },
      { key: "2", content: "Labour", active: true, href: "/labor_management" },
      { key: "3", content: route.name, active: false },
    ];

    return (
      <>
        <AddEditContractorModal
          open={addEditModalOpen}
          onClose={this.setCreateUpdateModalHidden}
          item={itemToEdit}
          itemId={editItemId && editItemId}
          update={updateTable}
        />
        <ConfirmationModal
          open={confirmationModalOpen}
          selectedItem={itemToArchive}
          actionType={actionType}
          onChangeItem={this.onChangeItem}
          onClose={this.onConfirmationModalClose}
        />
        <TablePageHolder
          navigate={navigate}
          actionsButtons={
            selectedItems.length ? (
              <TableActionButtons
                isArchive={isArchivedView}
                onDeleteButtonClick={this.onDeleteItems}
                onArchiveButtonClick={this.onArchiveItems}
                selectedItems={this.selectedContractors}
                actionsLocation="contractors"
              />
            ) : null
          }
          defaultSort={defaultSort}
          totalPages={totalPages}
          currentPage={currentPage}
          totalElements={totalElements}
          getData={this.getData}
          isFetching={isFetching}
          location={location}
          mainButton={
            !isArchivedView && (
              <Can I="add" a="contractors">
                <Button
                  primary
                  className={styles.addButton}
                  onClick={(e) => this.setCreateUpdateModalShown(e)}
                >
                  Add
                </Button>
              </Can>
            )
          }
          onRowClick={this.onRowClick}
          onSidebarHidden={() => this.clearActiveItemId()}
          sidebarShown={!!activeItemId}
          pageTitle={route.name}
          route={route}
          selectedItems={selectedItems}
          setSelectedItems={this.setSelectedItems}
          sidebarComponent={
            <Sidebar
              isArchive={isArchivedView}
              id={expandedContent && expandedContent}
              contractor={expandedContent}
              Can={Can}
              onClose={this.clearActiveItemId}
              onEditClick={this.setCreateUpdateModalShown}
              onDeleteItem={this.onArchiveItemClick}
            />
          }
          Can={({ children }) => <>{children}</>}
          accessName="contractors"
          tableColumns={tableColumns}
          tableData={tableData}
          updateAction="update"
          onUpdateModalOpen={this.setCreateUpdateModalShown}
          onArchiveItem={this.onArchiveItemClick}
          isArchivedView={isArchivedView}
          dataRefetch={this.state.dataRefetch}
        />
        {!this.props.error && (
          <DeletedMessage
            items={tempDeletedItems}
            onClose={() => this.setState({ tempDeletedItems: [] })}
            locationName="Contractor"
          />
        )}
        {!!tempArchivedItems.length && (
          <ActionMessage
            items={tempArchivedItems}
            onClose={() => this.setState({ tempArchivedItems: [] })}
            onUndo={this.actionMessageUndo}
            locationName="Contractor"
            isArchivedView={isArchivedView}
          />
        )}
      </>
    );
  }
}

Contractors.propTypes = {
  content: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  lastLoaded: PropTypes.bool,
  data: PropTypes.object,
  actions: PropTypes.object,
  error: PropTypes.object,
  route: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
  online: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");
  const {
    contractors: {
      isFetching,
      data,
      data: { content, number, totalPages, totalElements, size, last },
      error,
    },
    offline: { online },
    employee: {
      list: { content: employees },
    },
  } = state;
  return {
    isArchivedView,
    data,
    content,
    isFetching,
    currentPage: number,
    totalPages,
    totalElements,
    size,
    lastLoaded: last,
    Can: abilitiesSelector(state),
    error,
    online,
    employees,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        fetchAction: fetchContractors,
        deleteAction: deleteContractor,
        archiveAction: contractorsStatusChange,
        fetchEmployeesList,
        deleteContractors,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contractors);
