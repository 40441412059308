export const actionType = {
  QC_FORM_CLEAR_ERROR: "QC/QC_FORM_CLEAR_ERROR",

  FETCH_QC_FORMS_START: "QC/FETCH_QC_FORMS_START",
  FETCH_QC_FORMS: "QC/FETCH_QC_FORMS",
  FETCH_QC_FORMS_FAIL: "QC/FETCH_QC_FORMS_FAIL",

  QC_FORM_CREATE_START: "QC/QC_FORM_CREATE_START",
  QC_FORM_CREATE: "QC/QC_FORM_CREATE",
  QC_FORM_CREATE_FAIL: "QC/QC_FORM_CREATE_FAIL",

  QC_FORM_ARCHIVE_START: "QC/QC_FORM_ARCHIVE_START",
  QC_FORM_ARCHIVE: "QC/QC_FORM_ARCHIVE",
  QC_FORM_ARCHIVE_FAIL: "QC/QC_FORM_ARCHIVE_FAIL",

  QC_FORM_UPDATE_START: "QC/QC_FORM_UPDATE_START",
  QC_FORM_UPDATE: "QC/QC_FORM_UPDATE",
  QC_FORM_UPDATE_FAIL: "QC/QC_FORM_UPDATE_FAIL",

  QC_FORM_CLEAR: "QC/QC_FORM_CLEAR",
  QC_FORM_SET_FORM_SET: "QC/QC_FORM_SET",
  QC_FORM_CHANGE: "QC_FORM/QC_FORM_CHANGE",
  ADD_FORM_INPUT: "QC_FORM/ADD_FORM_INPUT",
  EDIT_FORM_INPUT: "QC_FORM/EDIT_FORM_INPUT",
  REMOVE_FORM_INPUT: "QC_FORM/REMOVE_FORM_INPUT",

  FETCH_QC_START: "QC/FETCH_QC_START",
  FETCH_QC: "QC/FETCH_QC",
  FETCH_QC_FAIL: "QC/FETCH_QC_FAIL",

  ADD_QC_START: "QC/ADD_QC_START",
  ADD_QC: "QC/ADD_QC",
  ADD_QC_FAIL: "QC/ADD_QC_FAIL",

  UPDATE_QC_START: "QC/UPDATE_QC_START",
  UPDATE_QC: "QC/UPDATE_QC",
  UPDATE_QC_FAIL: "QC/UPDATE_QC_FAIL",

  DELETE_QC_START: "QC/DELETE_QC_START",
  DELETE_QC: "QC/DELETE_QC",
  DELETE_QC_FAIL: "QC/DELETE_QC_FAIL",

  PARAMETERS_QC_START: "QC/PARAMETERS_QC_START",
  PARAMETERS_QC: "QC/PARAMETERS_QC",
  PARAMETERS_QC_FAIL: "QC/PARAMETERS_QC_FAIL",

  BARCODES_IDS_START: "QC/BARCODES_IDS_START",
  BARCODES_IDS: "QC/BARCODES_IDS",
  BARCODES_IDS_FAIL: "QC/BARCODES_IDS_FAIL",

  QC_CLEAR_ERROR: "QC/QC_CLEAR_ERROR",
  SET_ACTIVE_ITEM_ID: "QC/SET_ACTIVE_ITEM_ID"
};
