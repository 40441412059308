import { actionTypes } from "../../constants/HarvestUnits";

const initialState = {
  series: [],
  data: {
    content: [],
    page: {
      number: 0,
      totalPages: 0,
      totalElements: 0,
      size: 10
    }
  },
  isFetching: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionTypes.FETCH_HARVEST_UNITS_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case actionTypes.FETCH_HARVEST_UNITS:
      return {
        ...state,
        isFetching: false,
        error: null,
        data: action.payload
      };

    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    case actionTypes.FETCH_HARVEST_UNITS_ERROR:
      return {
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};
