import { omitBy, isNil } from "lodash";
import { actionType } from "../../constants/QualityControl";
import { axiosProxy } from "../../utils/axiosProxy";
import { fetchScannedBarcodeById } from "actions/GeneralBarcodes/generalBarcodes";

const url = "/qc";

export const fetchQC = ({
  page,
  size,
  search,
  sort,
  unpaged = false,
  filters
}) => async (dispatch, getState) => {
  try {
    dispatch({
      type: actionType.FETCH_QC_START
    });
    const paramsObject = omitBy(
      {
        page,
        search,
        size,
        unpaged,
        sort: "id,desc"
      },
      isNil
    );
    const params = new URLSearchParams(paramsObject);
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });
    const newData = await Promise.all(
      res.data.content.map(async i => {
        const barcode = await dispatch(fetchScannedBarcodeById(i.barcodeId));
        return i.barcodeId === barcode.id ? { ...barcode, ...i } : i;
      })
    );
    await dispatch({
      type: actionType.FETCH_QC,
      payload: {
        content: newData,
        page: res.data.page
      }
    });
    return newData;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_QC_FAIL,
      payload: error
    });
  }
};

export const addQC = data => async dispatch => {
  try {
    dispatch({
      type: actionType.ADD_QC_START
    });

    const res = await axiosProxy({
      method: "POST",
      url,
      data
    });
    const barcode = await dispatch(fetchScannedBarcodeById(res.data.barcodeId));
    const newData = { ...barcode, ...res.data };

    await dispatch({
      type: actionType.ADD_QC,
      payload: newData
    });
  } catch (error) {
    dispatch({
      type: actionType.ADD_QC_FAIL,
      payload: error
    });
  }
};

export const updateQC = (id, data) => async dispatch => {
  dispatch({
    type: actionType.UPDATE_QC_START
  });
  try {
    const res = await axiosProxy({
      method: "PUT",
      url: `${url}/${id}`,
      data
    });
    const barcode = await dispatch(fetchScannedBarcodeById(res.data.barcodeId));
    const newData = { ...barcode, ...res.data };
    await dispatch({
      type: actionType.UPDATE_QC,
      payload: newData
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.UPDATE_QC_FAIL,
      payload: error
    });
  }
};
export const deleteQc = id => async dispatch => {
  dispatch({
    type: actionType.DELETE_QC_START
  });
  try {
    const res = await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`
    });
    await dispatch({
      type: actionType.DELETE_QC,
      payload: id
    });
  } catch (error) {
    await dispatch({
      type: actionType.DELETE_QC_FAIL,
      payload: error
    });
  }
};
export const getParametrsList = id => async dispatch => {
  dispatch({
    type: actionType.PARAMETERS_QC_START
  });
  try {
    const res = await axiosProxy({
      method: "GET",
      url: `${url}/parameters`
    });
    await dispatch({
      type: actionType.PARAMETERS_QC,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.PARAMETERS_QC_FAIL,
      payload: error
    });
  }
};
export const getBarcodesIdsList = search => async dispatch => {
  dispatch({
    type: actionType.BARCODES_IDS_START
  });
  try {
    const res = await axiosProxy({
      method: "GET",
      url: "/barcodes/ids",
      params: {
        search
      }
    });
    await dispatch({
      type: actionType.BARCODES_IDS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.BARCODES_IDS_FAIL,
      payload: error
    });
  }
};

export const setActiveItemId = id => dispatch => {
  dispatch({ type: actionType.SET_ACTIVE_ITEM_ID, payload: id });
};
export const clearError = () => dispatch => {
  return dispatch({ type: actionType.QC_CLEAR_ERROR });
};
