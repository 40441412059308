import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Confirm } from "semantic-ui-react";
import { bindActionCreators } from "redux";

import { abilitiesSelector } from "../../../../../selectors/user";
import { getParentPageHref } from "../../../../../utils/routeHelpers";
import LayoutOverlay from "../../../../../components/LayoutOverlay";
import styles from "./BatchEditPackedBoxes.module.css";
import BatchEditPackedBoxesForm from "./BatchEditPackedBoxesForm";

class BatchEditPackedBoxes extends Component {
  state = { confirmOpen: false };

  constructor(props) {
    super(props);
    this.packedBoxForm = React.createRef();
  }

  componentDidMount() {
    const {
      location: { state },
      navigate,
    } = this.props;

    if (!state) {
      navigate("/404");
    }
  }

  doSubmitForm = () => {
    if (this.packedBoxForm.current) {
      this.packedBoxForm.current.submit();
    }
  };

  openConfirm = (event) => {
    event.preventDefault();
    this.setState({ confirmOpen: true });
  };

  onConfirm = () => {
    this.doSubmitForm();
  };

  closeConfirm = () => this.setState({ confirmOpen: false });

  render() {
    const {
      route,
      Can,
      location: { state },
      navigate,
    } = this.props;
    const { confirmOpen } = this.state;

    if (!state) {
      navigate("/404");
      return null;
    }

    const { ids } = state;
    return (
      <LayoutOverlay
        closeLink={getParentPageHref(route)}
        route={route}
        childrenOnly
        footer={
          <div className={styles.buttonsFooter}>
            <Can I="add" a="packed_boxes">
              <Button primary size="large" onClick={this.openConfirm}>
                Save
              </Button>
            </Can>
          </div>
        }
      >
        <Confirm
          open={confirmOpen}
          onCancel={this.closeConfirm}
          onConfirm={this.onConfirm}
          content={
            <div className={styles.confirmContent}>
              <div className={styles.confirmContentLine}>
                You are going to update{" "}
                <span className={styles.bold}>{ids.length}</span> items
              </div>
              <div className={`${styles.confirmContentLine} ${styles.bold}`}>
                This action cannot be undone
              </div>
              <div className={styles.confirmContentLine}>
                Are you sure you want to continue?
              </div>
            </div>
          }
        />
        <BatchEditPackedBoxesForm
          navigate={navigate}
          ids={ids}
          showCheckbox={true}
          route={route}
          formRef={this.packedBoxForm}
          doSubmitForm={this.openConfirm}
        />
      </LayoutOverlay>
    );
  }
}

BatchEditPackedBoxes.propTypes = {
  route: PropTypes.object.isRequired,
  packedBox: PropTypes.object,
  location: PropTypes.object,
  Can: PropTypes.func,
};

BatchEditPackedBoxes.defaultProps = {
  route: { name: "{%name%}" },
};

const mapStateToProps = (state) => {
  return {
    Can: abilitiesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BatchEditPackedBoxes);
