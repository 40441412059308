import React from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import CollapsibleBlock from "components/CollapsibleBlock";
import styles from "../SideBar.module.css";

const OverAllSummary = ({ activeItem }) => {
  const { qcRounds } = activeItem || {};
  const parametrs =
    (qcRounds && qcRounds.map(({ defectReports }) => defectReports).flat()) ||
    [];
  const sumUniqueValues = arr => {
    const resultMap = new Map();
    arr.forEach(obj => {
      const { parameter, majorAmount, minorAmount } = obj;
      if (resultMap.has(parameter)) {
        const existingObj = resultMap.get(parameter);
        existingObj.majorAmount += majorAmount;
        existingObj.minorAmount += minorAmount;
      } else {
        resultMap.set(parameter, { parameter, majorAmount, minorAmount });
      }
    });
    return Array.from(resultMap.values());
  };
  const resultArray = sumUniqueValues(parametrs);
  return (
    <CollapsibleBlock
      className={styles.generalInfoTitle}
      title="Overall summary of the QC"
    >
      <Table className={styles.qcTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.addressRow}>Checked units</Table.Cell>
            <Table.Cell className={styles.addressValueRow}>
              {activeItem?.unitToCheckPerRound} • 100%
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.comentsRow}>
              Number of rounds
            </Table.Cell>
            <Table.Cell className={styles.comentsValueRow}>
              {activeItem?.rounds || "-"}
            </Table.Cell>
          </Table.Row>
          {resultArray.map(item => {
            const total =
              item.majorAmount && item.minorAmount
                ? item.majorAmount + item.minorAmount
                : item.majorAmount
                ? item.majorAmount
                : item.minorAmount;
            const percentCalculate =
              (total * 100) / activeItem?.unitToCheckPerRound;
            const result =
              percentCalculate % 1 !== 0
                ? percentCalculate.toFixed(2)
                : percentCalculate;
            return (
              <Table.Row>
                <Table.Cell className={styles.comentsRow}>
                  {item.parameter.replace(
                    /^(.)(.*)$/,
                    (_, first, rest) => first.toUpperCase() + rest.toLowerCase()
                  )}
                </Table.Cell>
                <Table.Cell className={styles.comentsValueRow}>
                  {`${total} • ${result}%`}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};
OverAllSummary.propTypes = {
  activeItem: PropTypes.object
};
export default OverAllSummary;
