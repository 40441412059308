import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getChemicalsBreakdownForFarm } from "../../../actions/Farms/farms";

import "./index.css";
import SprayTasksCostsBreakdown from "../../../components/SprayTasksCostsBreakdown";
import GeneralTasksCostsBreakdown from "../../../components/GeneralTasksCostsBreakdown";
import TotalCostsBreakdown from "../PatchSidebar/components/TotalCostsBreakdown";
import { Container, Dimmer, Grid, Header } from "semantic-ui-react";
import ScannedTagBarcodes from "../../../components/ScannedTagBarcodes";
import DatePicker from "../../../components/DatePicker";
import CollapseBlock from "../../../components/CollapseBlock";
import { SPRAY_TASKS_COST_BREAKDOWN_TITLE } from "../../../constants/Farms";
import styles from "../../../components/Filters/Filters.module.css";
import Irrigation from "./Irrigation";
import OperatingCostsBreakdown from "../PatchSidebar/components/OperatingCostBreakDown/OperatingCostsBreakdown";
import Loader from "../../../components/Loader";

const FarmSidebar = ({
  data,
  isReportInProgress,
  farm,
  navigate,
  seasons,
  actions: { getChemicalsBreakdownForFarm }
}) => {
  const defaultSeason = seasons?.reduce(function(prev, current) {
    return prev.startAt > current.startAt ? prev : current;
  }, {});

  const [sprayTasksSum, setTotalChemicalsSum] = useState(0);
  const [generalTasksSum, setGeneralTasksSum] = useState(0);
  const [dateFrom, setDateFrom] = useState(defaultSeason?.startAt || null);
  const [dateTo, setDateTo] = useState(defaultSeason?.endAt || null);

  // Banana (id: 16)
  const thereAreBananas = farm.areas
    .map(
      area =>
        area.varieties.filter(variety => variety.variety.crop.id === 16)
          .length > 0
    )
    .some(a => a);

  const farmSize = useMemo(
    () =>
      farm.areas
        .filter(area => !area.archived)
        .map(({ size }) => size)
        .reduce((a, b) => a + b, 0),
    [farm]
  );

  return (
    <Container style={{ marginTop: 16 }}>
      <Header as="h2">{farm.name}</Header>
      <span>
        {
          farm.areas.filter(area => !area.archived && area.type === "BLOCK")
            .length
        }{" "}
        Blocks |{" "}
        {
          farm.areas.filter(area => !area.archived && area.type === "PATCH")
            .length
        }{" "}
        Patches | {farmSize.toFixed(2)} Ha
      </span>
      <Grid style={{ marginTop: 10, marginBottom: 10 }}>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Header style={{ paddingLeft: 14 }} as={"h3"}>
            Select Dates:
          </Header>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 5 }}>
          <Grid.Column computer={8}>
            <DatePicker
              size="small"
              value={dateFrom}
              onChange={(date, event) => {
                event.stopPropagation();
                setDateFrom(date);
              }}
              labelText={"From"}
              className={styles.datePicker}
            />
          </Grid.Column>
          <Grid.Column computer={8}>
            <DatePicker
              size="small"
              value={dateTo}
              onChange={(date, event) => {
                event.stopPropagation();
                setDateTo(date);
              }}
              labelText={"To"}
              className={styles.datePicker}
              minDate={dateFrom}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CollapseBlock
        fontSize={22}
        defaultClosed
        title={SPRAY_TASKS_COST_BREAKDOWN_TITLE}
        className={"border margin-bottom-20"}
      >
        <SprayTasksCostsBreakdown
          navigate={navigate}
          dateFrom={dateFrom}
          dateTo={dateTo}
          farm={{
            ...farm,
            size: farmSize
          }}
          className={"farm-sidebar"}
          getChemicalsBreakdown={(dateFrom, dateTo) =>
            getChemicalsBreakdownForFarm(farm.id, dateFrom, dateTo)
          }
          excludeFilters={["date"]}
          setSummary={setTotalChemicalsSum}
        />
      </CollapseBlock>
      <CollapseBlock
        fontSize={22}
        defaultClosed
        title="General tasks cost breakdown:"
        className={"border margin-bottom-20"}
      >
        <GeneralTasksCostsBreakdown
          navigate={navigate}
          dateFrom={dateFrom}
          dateTo={dateTo}
          farm={{
            ...farm,
            size: farmSize
          }}
          excludeFilters={["task_costs_date"]}
          farmName={farm.name}
          setGeneralTasksSummary={setGeneralTasksSum}
        />
      </CollapseBlock>
      {thereAreBananas && (
        <ScannedTagBarcodes
          navigate={navigate}
          farm={farm}
          excludeFilters={["tag_date"]}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}
      <CollapseBlock
        fontSize={22}
        defaultClosed
        title="Total tasks cost breakdown:"
        className={"border margin-bottom-20"}
      >
        <TotalCostsBreakdown
          farm={farm}
          itemSize={farmSize}
          sprayTasksSum={sprayTasksSum}
          generalTasksSum={generalTasksSum}
        />
      </CollapseBlock>

      <CollapseBlock
        fontSize={22}
        defaultClosed
        title="Operating Costs breakdown:"
        className={"border margin-bottom-20"}
      >
        {isReportInProgress ? (
          <Loader />
        ) : !!data?.length ? (
          <OperatingCostsBreakdown />
        ) : (
          <span>Nothing was found</span>
        )}{" "}
      </CollapseBlock>

      <CollapseBlock
        fontSize={22}
        defaultClosed
        title="Irrigation water use:"
        className={"border margin-bottom-20"}
      >
        <Irrigation />
      </CollapseBlock>
      <div style={{ height: 50 }} />
    </Container>
  );
};

FarmSidebar.propTypes = {
  actions: PropTypes.object,
  farm: PropTypes.object
};

const mapStateToProps = state => {
  const {
    farmOperatingCostBreakdow: { data, isReportInProgress },
    settings: {
      data: { seasons }
    }
  } = state;
  return {
    data,
    isReportInProgress,
    seasons
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getChemicalsBreakdownForFarm
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(FarmSidebar);
