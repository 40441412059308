export const actionType = {
  FETCH_AREAS_START: "AREAS/FETCH_AREAS_START",
  FETCH_AREAS: "AREAS/FETCH_AREAS",
  FETCH_AREAS_FAIL: "AREAS/FETCH_AREAS_FAIL",

  FETCH_AREA_LIST_START: "AREAS/FETCH_AREA_LIST_START",
  FETCH_AREA_LIST: "AREAS/FETCH_AREA_LIST",
  FETCH_AREA_LIST_FAIL: "AREAS/FETCH_AREA_LIST_FAIL",

  GET_AREAS_START: "AREAS/GET_AREAS_START",
  GET_AREAS: "AREAS/GET_AREAS",
  GET_AREAS_FAIL: "AREAS/GET_AREAS_FAIL",

  ADD_AREA_START: "AREAS/ADD_AREA_START",
  ADD_AREA: "AREAS/ADD_AREA",
  ADD_AREA_FAIL: "AREAS/ADD_AREA_FAIL",

  UPDATE_AREA_START: "AREAS/UPDATE_AREA_START",
  UPDATE_AREA: "AREAS/UPDATE_AREA",
  UPDATE_AREA_FAIL: "AREAS/UPDATE_AREA_FAIL",

  ARCHIVE_AREA_START: "AREAS/ARCHIVE_AREA_START",
  ARCHIVE_AREA: "AREAS/ARCHIVE_AREA",
  ARCHIVE_AREA_FAIL: "AREAS/ARCHIVE_AREA_FAIL",

  DELETE_AREA_START: "AREAS/DELETE_AREA_START",
  DELETE_AREA: "AREAS/DELETE_AREA",
  DELETE_AREA_FAIL: "AREAS/DELETE_AREA_FAIL",

  UPDATE_STRUCTURE_START: "AREAS/UPDATE_STRUCTURE_START",
  UPDATE_STRUCTURE: "AREAS/UPDATE_STRUCTURE",
  UPDATE_STRUCTURE_FAIL: "AREAS/UPDATE_STRUCTURE_FAIL",

  CLEAR_AREA_ERROR: "AREAS/CLEAR_AREA_ERROR",

  UPDATE_AREAS_WEATERSTATION: "AREAS/UPDATE_AREAS_WEATERSTATION",

  CHANGE_LAST_UPDATE_DATE: "AREAS/CHANGE_LAST_UPDATE_DATE"
};
