import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  archiveTasks,
  fetchRecommendations,
  setCurrentTaskById,
  unArchiveTasks
} from "actions/Recommendations/recommendations";
import { abilitiesSelector } from "selectors/user";
import { activeItemSelector } from "selectors/recomendation";
import { getRecomendationTableColumns } from "utils/recomendationsNew";
import { Button, Container, Icon } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import { chemicalShape } from "constants/Chemicals/types";
import { useReactToPrint } from "react-to-print";
import styles from "./RecomendationNew.module.css";
import { keyBy } from "lodash";
import RecommendationSidebar from "../components/RecommendationsList/Sidebar/RecommendationSidebar";
import RecommendationsPrintout from "../components/RecommendationsPrintout";
import { convertRecommendationChemicalToSprayDiaryFormat } from "utils/recommendations";
import { Link } from "react-router-dom";

const RecomendationNew = React.forwardRef(
  (
    {
      Can,
      isArchivedView,
      location,
      route,
      isPrinting,
      isFetching,
      tableData,
      currentScouting,
      activeItem,
      navigate,
      filterData,
      chemicalsList
    },
    ref
  ) => {
    const componentRef = useRef();
    const [selectedItemsIds, setSelectedItems] = useState([]);
    const [archivedStatus, setArchivedStatus] = useState(false);
    const [itemsToPrint, setItemsToPrint] = useState(null);

    const tableColumns = getRecomendationTableColumns(isFetching);

    const dispatch = useDispatch();
    const getData = useCallback(
      params => {
        setSelectedItems([]);
        dispatch(fetchRecommendations({ ...params, archived: isArchivedView }));
        setArchivedStatus(false);
      },
      [fetchRecommendations, filterData, isArchivedView]
    );
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      ref: ref // Ensure ref is passed here
    });
    const printButton = () => {
      handlePrint();
    };
    const onRowClick = useCallback(
      (event, row) => {
        if (!activeItem && row) {
          dispatch(setCurrentTaskById(row.original.id));
        }
      },
      [activeItem]
    );
    const onArchiveClick = useCallback(() => {
      const idsMap = keyBy(selectedItemsIds);
      const itemsIds = tableData.content
        .filter((_, index) => !!idsMap[index])
        .map(({ id }) => id);
      setSelectedItems([]);
      !isArchivedView
        ? dispatch(archiveTasks(itemsIds))
        : dispatch(unArchiveTasks(itemsIds));
      setArchivedStatus(true);
    }, [selectedItemsIds, tableData.content]);

    const printData = selectedItemsIds.length
      ? tableData.content.filter((item, index) => {
          return selectedItemsIds.includes(`${index}`);
        })
      : activeItem
      ? [activeItem]
      : [];
    const recommendationTasks = printData.map(task => ({
      ...task,
      chemicals: convertRecommendationChemicalToSprayDiaryFormat(task)
    }));
    return (
      <>
        {!!recommendationTasks.length && (
          <div style={{ display: "none" }}>
            <RecommendationsPrintout
              ref={componentRef}
              tasksToPrint={recommendationTasks}
            />
          </div>
        )}
        <TablePageHolder
          mainButton={
            <Button as={Link} color="green" to={"/recommendations/add"}>
              Add recommendation
            </Button>
          }
          actionsButtons={
            <Container className={styles.container}>
              <Button
                basic
                className={styles.actionButton}
                color="green"
                size="large"
                disabled={!selectedItemsIds.length}
                onClick={() => onArchiveClick()}
              >
                <Icon name="archive" />{" "}
                {!isArchivedView ? "Archive" : "Restore"}
              </Button>
              <Button
                basic
                className={styles.actionButton}
                color="green"
                size="large"
                disabled={!selectedItemsIds.length || isPrinting}
                onClick={() => {
                  printButton();
                }}
              >
                <span>
                  {!isPrinting ? (
                    <Icon name="print" />
                  ) : (
                    <Icon name="circle notch loading icon" />
                  )}
                </span>
                Print
              </Button>
            </Container>
          }
          navigate={navigate}
          getData={getData}
          isFetching={isFetching}
          location={location}
          withoutSearch={true}
          onRowClick={onRowClick}
          onSidebarHidden={() => dispatch(setCurrentTaskById(null))}
          sidebarShown={!!activeItem}
          pageTitle={route.name}
          route={route}
          selectedItems={selectedItemsIds}
          setSelectedItems={setSelectedItems}
          sidebarComponent={<RecommendationSidebar />}
          tableColumns={tableColumns}
          tableData={tableData || []}
        />
      </>
    );
  }
);

RecomendationNew.propTypes = {
  Can: PropTypes.func,
  isArchivedView: PropTypes.bool,
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(chemicalShape)
  }).isRequired,
  fetchChemicals: PropTypes.func,
  isFetching: PropTypes.bool,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object
};

export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  return {
    isArchivedView,
    Can: abilitiesSelector(state),
    chemicalsList: state.chemical.list.content,
    tableData: state.recommendations.data,
    isPrinting: state.recommendations.isPrinting,
    isFetching: state.recommendations.isFetching,
    currentScouting: state.recommendations.currentTask,
    activeItem: activeItemSelector(state)
  };
})(RecomendationNew);
