import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, useFormikContext, useField } from "formik";
import { Form, Input } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { unitsSelector } from "selectors/chemicals";
import { fetchUnits } from "actions/Chemicals";
import { isEmpty, debounce } from "lodash";
import styles from "../../InventoryModal.module.css";

const MinMaxRateInput = ({ width, unit }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();
  const [field, meta] = useField("minRate", "maxRate");
  const dispatch = useDispatch();
  const [prevUnit, setPrevUnit] = useState("");
  const { content, isFetching } = useSelector(unitsSelector);
  const multiplier = useMemo(() => {
    const foundElement = content.find(({ id }) => id === unit);

    if (foundElement) {
      return 1 / foundElement.multiplicationFactor;
    }

    return 1;
  }, [content, unit]);

  if (isEmpty(content) && !isFetching) {
    dispatch(fetchUnits());
  }

  useEffect(() => {
    return setPrevUnit(unit);
  });

  return (
    <Form.Field width={width} required>
      <label>Rates</label>
      <Form as={"div"}>
        <Form.Group fluid>
          <Field
            as={Form.Input}
            error={meta.touched && meta.error}
            name="minRate"
            className={styles.minRateInput}
            placeholder="Min"
            width={8}
            onChange={(_, { value }) => {
              const last = value.toString().slice(-1);
              value == "" && setFieldValue("minRate", undefined);
              if (!isNaN(value) && value != "") {
                last == "." && unit != prevUnit
                  ? setFieldValue("minRate", value)
                  : setFieldValue("minRate", value / multiplier);
              }
            }}
            value={
              !isNaN(values.minRate) &&
              values.minRate.toString().slice(-1) != "." &&
              unit != prevUnit
                ? values.minRate * multiplier
                : undefined
            }
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />

          <Field
            as={Form.Input}
            className={styles.maxRateInput}
            placeholder="Max"
            error={meta.touched && meta.error}
            name="maxRate"
            width={8}
            onChange={(_, { value }) => {
              const last = value?.toString().slice(-1);
              value == "" && setFieldValue("maxRate", undefined);
              if (!isNaN(value) && value != "") {
                last == "." && unit != prevUnit
                  ? setFieldValue("maxRate", value)
                  : setFieldValue("maxRate", value / multiplier);
              }
            }}
            value={
              !isNaN(values.maxRate) &&
              values.maxRate?.toString().slice(-1) != "." &&
              unit != prevUnit
                ? values.maxRate * multiplier
                : undefined
            }
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Group>
      </Form>
    </Form.Field>
  );
};

export default MinMaxRateInput;
