import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Dimmer, Loader } from "semantic-ui-react";

import {
  clearCreator,
  getTaskById,
  prefillCreator,
  setAreasToAdd,
  setStatus
} from "../../../actions/SprayDiary/common";
import {
  getAreasList,
  setAreaDoneState
} from "../../../actions/SprayDiary/areas";
import { history } from "../../../store";
import SprayForm from "./SprayForm/SprayForm";
import Sidebar from "./Sidebar";
import ErrorBar from "../../../components/ErrorBar";
import { getParentPageHref } from "../../../utils/routeHelpers";
import { getMachineryList } from "../../../actions/SprayDiary/machinery";
import { getChemicalsList } from "../../../actions/SprayDiary/chemicals";
import { fetchChemicalsListForST } from "../../../actions/Chemicals";
import {
  getEmployeeList,
  selectSingleEmployee
} from "../../../actions/SprayDiary/employee";
import LayoutOverlay from "../../../components/LayoutOverlay";
import { userSelector } from "../../../selectors/user";
import CreateTaskFooter from "./SprayForm/CreateTaskFooter";
import styles from "./createSprayTask.module.css";
import moment from "moment";
import querystring from "querystring";

class CreateSprayTask extends Component {
  state = {
    showError: false
  };

  componentDidMount() {
    const { match, actions, user } = this.props;
    const options = [];
    querystring.parse(history.location.search.substr(1));
    const { id } = match.params;
    actions.clearCreator();
    if (id) {
      actions.getTaskById(id, options).then(() => {
        if (options.status) {
          actions.setStatus(options.status);
        }
        if (options.done) {
          actions.setAreaDoneState(Number(options.done));
        }
      });
    } else {
      actions.selectSingleEmployee(user.profile.employee_id, "creator");
    }

    if (options.block || options.patch) {
      actions.setAreasToAdd({
        block: options.block,
        patch: options.patch
      });
    }

    this.prefetchLibData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { id }
      },
      actions,
      error
    } = this.props;
    const options = [];
    querystring.parse(history.location.search.substr(1));
    if (id && id !== prevProps.match.params.id) {
      actions.clearCreator();
      actions.getTaskById(id, options);
    }

    if (error && error !== prevProps.error) {
      this.setState({
        showError: true
      });
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    actions.clearCreator();
  }

  prefetchLibData = () => {
    const { actions } = this.props;
    actions.getMachineryList();
    // actions.getChemicalsList();
    actions.fetchChemicalsListForST();
    actions.getEmployeeList();
    actions.getAreasList();
  };

  hideError = () => {
    this.setState({
      showError: false
    });
  };

  overlapping = (a, b) => {
    const range = moment.range(a, b);
    const getMinutes = s => {
      const p = s.split(":").map(Number);
      return p[0] * 60 + p[1];
    };
    return a.date === b.date
      ? getMinutes(a.end) > getMinutes(b.start) &&
          getMinutes(b.end) > getMinutes(a.start)
      : false;
  };
  isOverlapping = arr => {
    let i, j;
    for (i = 0; i < arr.length - 1; i++) {
      for (j = i + 1; j < arr.length; j++) {
        if (this.overlapping(arr[i], arr[j])) {
          return true;
        }
      }
    }
    return false;
  };
  render() {
    const {
      route,
      activeDimmer,
      error,
      match: {
        params: { id }
      },
      status,
      actions: { prefillCreator },
      navigate
    } = this.props;
    const { showError } = this.state;
    const isPreviouslyCreatedTask = !!id;
    return (
      <LayoutOverlay
        sidebar={
          <Sidebar
            isOverlapping={this.isOverlapping}
            onTaskChange={prefillCreator}
            isPreviouslyCreatedTask={isPreviouslyCreatedTask}
          />
        }
        extra={
          <Dimmer active={activeDimmer}>
            <Loader />
          </Dimmer>
        }
        closeLink={getParentPageHref(route)}
        route={{ ...route, name: `${id ? "Edit" : "Create"} ${route.name}` }}
        footer={
          <CreateTaskFooter
            navigate={navigate}
            isOverlapping={this.isOverlapping}
          />
        }
      >
        <ErrorBar
          showError={showError}
          onHide={this.hideError}
          error={error}
          className={styles.errorBar}
        />
        <SprayForm
          isPreviouslyCreatedTask={isPreviouslyCreatedTask}
          completed={status === "COMPLETED"}
        />
      </LayoutOverlay>
    );
  }
}

CreateSprayTask.propTypes = {
  match: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  route: PropTypes.object,
  error: PropTypes.object,
  status: PropTypes.string,
  activeDimmer: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    sprayDiary: { isFetching, activeDimmer, error, status }
  } = state;
  return {
    isFetching,
    activeDimmer,
    user: userSelector(state),
    error,
    status
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getTaskById,
      clearCreator,
      setAreasToAdd,
      prefillCreator,
      setStatus,
      setAreaDoneState,
      getMachineryList,
      getChemicalsList,
      fetchChemicalsListForST,
      getEmployeeList,
      getAreasList,
      selectSingleEmployee
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSprayTask);
