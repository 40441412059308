import React from "react";
import { Modal, Button } from "semantic-ui-react";
import {
  addContractor,
  updateContractor
} from "../../../../actions/Contractors/contractors";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import styles from "./AddEditContractorModal.module.css";
import { get, isEmpty } from "lodash";
import ContractorInformation from "./components/ContractorInformation";
import ContractorRate from "./components/ContractorRate";
import ContractorWorkers from "./components/ContractorWorkers";

const REQUIRED_FIELDS = ["name", "rate"];

const AddEditContractorModal = ({ update, open, item, onClose }) => {
  const dispatch = useDispatch();

  const validateValues = async values => {
    const errors = {};
    const { name, email, type, rate } = values;

    REQUIRED_FIELDS.forEach(filedName => {
      if (!get(values, filedName)) {
        errors[filedName] = "Required field";
      }
    });

    if (isEmpty(name)) {
      errors.name = true;
    }

    /*     if (isEmpty(type)) {
      errors.type = true;
    } */

    if (!rate) {
      errors.rate = "Rate is required";
    }

    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  function onSubmit(values) {
    if (!!item) {
      dispatch(updateContractor(values.id, values))
        .then(onClose)
        .then(update);
    } else {
      dispatch(addContractor(values))
        .then(onClose)
        .then(update);
    }
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
      closeOnDimmerClick={false}
    >
      <Modal.Header className={styles.modalHeader}>
        {!!item ? "Edit" : "Add"} contractor
      </Modal.Header>
      <Formik
        validate={validateValues}
        enableReinitialize
        validateOnChange={true}
        onSubmit={onSubmit}
        initialValues={{
          name: "",
          phoneNumber: "",
          type: "",
          email: "",
          address: "",
          abn: "",
          rate: "",
          employeeIds: [],
          ...(!!item ? item : {})
        }}
      >
        {props => {
          return (
            <>
              <Modal.Content
                scrolling
                className={styles.modalContentContainer}
                id={"contractors_modal_scroll_container"}
              >
                <ContractorInformation />
                <ContractorRate isEdit={!!item} contractor={item} />
                <ContractorWorkers />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Cancel"
                  color="green"
                  basic
                  className={styles.modalButton}
                  onClick={onClose}
                />
                <Button
                  className={styles.modalButton}
                  content={!!item ? "Save" : "Add"}
                  color="green"
                  onClick={props.submitForm}
                  disabled={
                    !props.isValid || !props.dirty || props.isValidating
                  }
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddEditContractorModal;
