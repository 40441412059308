import { actionType } from "../../constants/BoxUnits";

const initialState = {
  list: {
    content: [],
    isFetching: false
  },
  data: { content: [], page: {} },
  isFetching: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.FETCH_BOX_UNITS_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true
        }
      };
    case actionType.FETCH_BOX_UNITS_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case actionType.FETCH_BOX_UNITS: {
      return {
        ...state,
        data: {
          ...action.payload
        },
        isFetching: false
      };
    }
    case actionType.ADD_BOX_UNITS: {
      const newTotalElements = state.data.totalElements + 1;
      const dataContent = [action.payload, ...state.data.content];
      const newListTotalElements = state.list.totalElements + 1;
      const listContent = [action.payload, ...state.list.content];

      return {
        ...state,
        data: {
          ...state.data,
          content: dataContent,
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size)
        },
        list: {
          ...state.list,
          content: listContent,
          totalElements: newListTotalElements
        }
      };
    }
    case actionType.UPDATE_BOX_UNITS: {
      return {
        ...state,
        data: {
          ...initialState.data,
          content: state.data.content.map(item => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          })
        },
        list: {
          ...state.list,
          content: state.list.content.map(item => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          })
        }
      };
    }
    case actionType.DELETE_BOX_UNITS: {
      const newTotalElements = state.data.totalElements - 1;

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => e.id !== action.payload),
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size)
        },
        list: {
          ...state.list,
          content: state.list.content.filter(e => e.id !== action.payload)
        }
      };
    }

    case actionType.FETCH_BOX_UNITS_LIST:
      return {
        ...state,
        list: { ...action.payload, isFetching: false }
      };
    case actionType.FETCH_BOX_UNITS_LIST_FAIL:
      return {
        ...state,
        list: { ...state.list, isFetching: false },
        error: action.payload
      };
    case actionType.FETCH_BOX_UNITS_FAIL:
    case actionType.ADD_BOX_UNITS_FAIL:
    case actionType.UPDATE_BOX_UNITS_FAIL:
    case actionType.DELETE_BOX_UNITS_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    }
    case actionType.CLEAR_BOX_UNITS_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
