import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Menu, Button } from "semantic-ui-react";
import HarvestUnitsImageModal from "routes/HarvestUnitsControl/components/Slider";
import styles from "./Pictures.module.css";
import { Formik } from "formik";
import { useDispatch, connect } from "react-redux";
import Loader from "components/Loader";

const BinPicture = ({
  activeRounds,
  header,
  isFetching,
  isFetchingBarcode
}) => {
  const [urlList, setUrlList] = useState(false);
  const [blockName, setblockName] = useState(false);
  const [imageModalOpen, setimageModalOpen] = useState(false);
  const dispatch = useDispatch();
  const reader = new FileReader();
  const style = {
    imageIconContainer: {
      padding: "0px"
    },
    iconContainer: {
      backgroundColor: "black",
      padding: " 0px",
      width: "25px",
      height: "25px",
      paddingLeft: " 5px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "0"
    },

    closeIcon: {
      color: "white",
      marginRight: "11px",
      cursor: "ponter"
    },

    dotsContainerStyles: {
      display: "flex",
      justifyContent: "center"
    },

    dotStyle: {
      margin: "12px 12px",
      fontSize: "20px",
      marginTop: "15px"
    },

    image: {
      width: "136px",
      height: "136px",
      borderRadius: "8px",
      cursor: "pointer"
    }
  };

  return isFetching || isFetchingBarcode ? (
    <Loader />
  ) : (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={activeRounds || {}}
    >
      {({ values }) => {
        const imagesUrl = values.pictures?.map(i => ({
          url: i.presignedUrl
        }));

        return (
          <div className={styles.wrapper}>
            <Form>
              <HarvestUnitsImageModal
                open={imageModalOpen}
                blockName={blockName}
                urlList={urlList || []}
                onClose={() => {
                  return setimageModalOpen(false);
                }}
              />
              <Form.Group className={styles.imageSegment}>
                {imagesUrl?.map((slide, slideIndex) => (
                  <div style={style.dotsContainerStyles}>
                    <div
                      style={style.dotStyle}
                      key={slideIndex}
                      onClick={() => {}}
                    >
                      <Menu compact>
                        <Menu.Item style={style.imageIconContainer} as="a">
                          <Button
                            className={styles.imageButton}
                            onClick={event => {
                              return (
                                setUrlList(imagesUrl),
                                setblockName(header),
                                setimageModalOpen(true)
                              );
                            }}
                          >
                            <img style={style.image} src={slide.url} />
                          </Button>
                        </Menu.Item>
                      </Menu>
                    </div>
                  </div>
                ))}
              </Form.Group>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

BinPicture.propTypes = {
  activeItem: PropTypes.object
};

export default connect((state, props) => {
  return {
    isFetching: state.qualityControl.data.isFetching,
    isFetchingBarcode: state.generalBarcodes.isFetching
  };
})(BinPicture);
