import React from "react";
import { Header, Grid } from "semantic-ui-react";
import SelectedAreasTable from "../SelectedAreasTable";
import { ROWS_TO_SPRAY } from "constants/shared";
import {
  APPLICATION_TYPES,
  recommendationTaskShape,
} from "constants/Recommendations/types";
import styles from "./RecommendationsPrintout.module.css";

const ApplyToAreasSection = React.forwardRef(({ taskData }, ref) => {
  return (
    <>
      <Grid.Row columns={1} ref={ref}>
        <Grid.Column>
          <Header className="bold" size="medium">
            Apply to areas
          </Header>
        </Grid.Column>
        <Grid.Column>
          <SelectedAreasTable
            basic="very"
            recommendationTask={taskData}
            striped
            stickyFirstColumn={false}
            className={styles.printedTable}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={3}>
          <Header size="small" className="uppercase">
            Rows to apply
          </Header>
          <Header size="small" className="bold m-0">
            {ROWS_TO_SPRAY[taskData.rowsToSpray]}
          </Header>
        </Grid.Column>
        <Grid.Column width={5}>
          <Header size="small" className="uppercase">
            Row width
          </Header>
          <Header size="small" className="bold m-0">
            {taskData.rowWidth}%
          </Header>
        </Grid.Column>
        {APPLICATION_TYPES[taskData.applicationType] ===
          APPLICATION_TYPES.SPRAY && (
          <Grid.Column width={4}>
            <Header size="small" className="uppercase">
              Water Rate(L/Ha)
            </Header>
            <Header size="small" className="bold m-0">
              {taskData.literPerHectare}
            </Header>
          </Grid.Column>
        )}
        <Grid.Column width={4}>
          <Header size="small" className="uppercase">
            Growth Stage
          </Header>
          <Header size="small" className="bold m-0">
            {taskData.growthStage}
          </Header>
        </Grid.Column>
      </Grid.Row>
    </>
  );
});
ApplyToAreasSection.propTypes = {
  taskData: recommendationTaskShape,
};

export default ApplyToAreasSection;
