import React, { useState } from "react";
import {
  Form,
  Input,
  Segment,
  Icon,
  Dropdown,
  Button
} from "semantic-ui-react";
import { Field, Formik } from "formik";
import { useDispatch } from "react-redux";
import { fetchScannedBarcodeById } from "actions/GeneralBarcodes/generalBarcodes";
import { getBarcodesIdsList, updateQC } from "actions/QualityControl/index";
import { debounce } from "lodash";
import styles from "./SideBar.module.css";

const GeneralEdit = ({ activeItem, setGeneralEdit }) => {
  const dispatch = useDispatch();
  const [barcodeData, setBarcodeData] = useState({});
  const [barcodeList, setBarcodeList] = useState([]);
  const [searchCheck, setSearchCheck] = useState(false);
  const onSubmit = data => {
    dispatch(updateQC(activeItem.id, data)).then(() => setGeneralEdit(false));
  };
  const debounceUpdate = id => {
    barcodeList.includes(id) &&
      dispatch(fetchScannedBarcodeById(id)).then(response =>
        setBarcodeData(response)
      );
  };
  const getBarcodesList = debounce(async id => {
    dispatch(getBarcodesIdsList(id))
      .then(response => setBarcodeList(response))
      .then(() =>
        id.length > 2 ? setSearchCheck(true) : setSearchCheck(false)
      );
  }, 900);
  const options = barcodeList.map(i => ({
    value: i,
    text: i,
    key: i
  }));
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={
        activeItem || {
          name: "",
          barcodeId: "",
          rounds: "",
          unitToCheckPerRound: ""
        }
      }
    >
      {({ values, dirty, isValid, setValues, setFieldValue, handleSubmit }) => {
        const validate =
          values.name &&
          values.barcodeId &&
          values.rounds &&
          values.unitToCheckPerRound;
        const dataToSend = {
          barcodeId: values?.barcodeId,
          createdAt: activeItem?.createdAt,
          id: activeItem?.id,
          name: values?.name,
          unitToCheckPerRound: values?.unitToCheckPerRound,
          qcRounds: activeItem?.qcRounds,
          rounds: values?.rounds
        };
        return (
          <Form>
            <Segment className={styles.editModalSegment}>
              <Form.Group>
                <Form.Field
                  fluid
                  width={16}
                  className={styles.formField}
                  required
                >
                  <label>QC procedure name</label>
                  <Field
                    fluid
                    width={16}
                    as={Input}
                    value={values.name}
                    className={styles.totalField}
                    required
                    name="name"
                    onChange={e => {
                      const { value } = e.target;
                      setFieldValue("name", value);
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field
                  fluid
                  width={8}
                  required
                  className={styles.formField}
                >
                  <label>Bin ID</label>
                  <Dropdown
                    className={styles.barcodesDropdown}
                    placeholder="Select Barcode"
                    fluid
                    selection
                    search
                    onSearchChange={e => {
                      const { value } = e.target;
                      if (value.length > 2) {
                        getBarcodesList(value);
                      } else {
                        setBarcodeList([]);
                        setSearchCheck(false);
                      }
                    }}
                    text={values.barcodeId}
                    noResultsMessage={
                      searchCheck
                        ? "Nothing was found"
                        : "type at least 3 symbols"
                    }
                    options={options}
                    onChange={(e, { value }) => {
                      return (
                        debounceUpdate(value),
                        setFieldValue("barcodeId", value),
                        setBarcodeList([]),
                        setSearchCheck(false)
                      );
                    }}
                  />
                </Form.Field>

                <Form.Field width={8} fluid className={styles.formField}>
                  <label className={styles.disabledLabel}>Crop</label>
                  <Field
                    as={Input}
                    icon={<Icon name="chevron down" />}
                    disabled
                    value={
                      barcodeData?.variety?.crop?.name ||
                      values.variety?.crop?.name ||
                      "-"
                    }
                    fluid
                    className={styles.totalDisabledField}
                    name="crop"
                    onChange={() => {}}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field
                  fluid
                  width={8}
                  required
                  className={styles.formField}
                >
                  <label>Number of rounds</label>
                  <Field
                    as={Input}
                    value={values?.rounds}
                    fluid
                    required
                    name="rounds"
                    onChange={e => {
                      const { value } = e.target;
                      setFieldValue("rounds", value);
                    }}
                  />
                </Form.Field>
                <Form.Field width={8} fluid className={styles.formField}>
                  <label className={styles.disabledLabel}>Variety</label>
                  <Field
                    as={Input}
                    icon={<Icon name="chevron down" />}
                    disabled
                    value={
                      barcodeData?.variety?.name || values?.variety?.name || "-"
                    }
                    fluid
                    className={styles.totalDisabledField}
                    name="variety"
                    onChange={() => {}}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field
                  fluid
                  width={8}
                  required
                  className={styles.formField}
                >
                  <label>Unit to check per round</label>
                  <Field
                    as={Input}
                    value={values?.unitToCheckPerRound}
                    fluid
                    required
                    name="unitToCheckPerRound"
                    onChange={e => {
                      const { value } = e.target;
                      setFieldValue("unitToCheckPerRound", value);
                    }}
                  />
                </Form.Field>

                <Form.Field width={8} fluid className={styles.formField}>
                  <label className={styles.disabledLabel}>
                    Harvest unit type
                  </label>
                  <Field
                    icon={<Icon name="chevron down" />}
                    as={Input}
                    disabled
                    value={
                      barcodeData?.harvestUnit
                        ? `${barcodeData?.harvestUnit?.name} ${barcodeData?.harvestUnit?.netWeight} Kg`
                        : values?.harvestUnit
                        ? `${values?.harvestUnit?.name} ${values?.harvestUnit?.netWeight} Kg`
                        : "-"
                    }
                    fluid
                    className={styles.totalDisabledField}
                    name="harvestUnit"
                    onChange={e => {}}
                  />
                </Form.Field>
              </Form.Group>
            </Segment>

            <div className={styles.btnWrapper}>
              <Button
                content="Cancel"
                color="green"
                basic
                onClick={() => {
                  setGeneralEdit(false);
                }}
              />
              <Button
                content={"Save"}
                color="green"
                onClick={() => {
                  return onSubmit(dataToSend);
                }}
                disabled={!validate || !dirty}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GeneralEdit;
