import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/SprayDiary";
import { history } from "../../store";

export const addNewChemical = data => async dispatch => {
  let url = "/chemicals";
  try {
    dispatch({
      type: actionType.ADD_NEW_CHEMICAL_START
    });
    const res = await axiosProxy({
      method: "POST",
      data,
      url
    });

    dispatch({
      type: actionType.ADD_NEW_CHEMICAL,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.ADD_NEW_CHEMICAL_FAIL,
      payload: error
    });
  }
};

export const getChemicalsList = chemicals => (dispatch, getState) => {
  const { chemical } = getState();
  dispatch({
    type: actionType.GET_CHEMICALS_LIST_SUCCESS,
    payload: chemicals || chemical.list
  });
};

export const setChemicalItemToAdd = (id, item) => dispatch => {
  dispatch({
    type: actionType.SET_CHEMICAL_ITEM_TO_ADD,
    payload: { id, item }
  });
};

export const onEditChemicalItem = id => dispatch => {
  dispatch({
    type: actionType.ON_EDIT_CHEMICAL_ITEM,
    payload: id
  });
};

export const deleteChemicalItem = id => dispatch => {
  dispatch({
    type: actionType.DELETE_CHEMICAL_ITEM,
    payload: id
  });
};

export const addChemicalItem = (id, targetChemicalId) => dispatch => {
  dispatch({
    type: actionType.ADD_CHEMICAL_ITEM,
    payload: { id, targetChemicalId }
  });
};

export const setChemicalFromList = (id, chemicalId, dataFrom) => dispatch => {
  dispatch({
    type: actionType.SET_CHEMICAL_FROM_LIST,
    payload: { id, chemicalId, dataFrom }
  });
};

export const declineEditingChemicalItem = id => dispatch => {
  dispatch({
    type: actionType.DECLINE_EDITING_CHEMICAL_ITEM,
    payload: id
  });
};

export const setAddChemicalSelection = status => dispatch => {
  dispatch({
    type: actionType.ADD_CHEMICAL_SELECTION,
    payload: status
  });
};
export const changeChemicalBatchnumbersList = data => dispatch => {
  dispatch({
    type: actionType.CHANGE_CHEMICAL_BATCHNUMBERS_LIST,
    payload: data
  });
};
