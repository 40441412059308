import PropTypes from "prop-types";
import React from "react";
import SegmentClosableControlled from "../../../../../components/SegmentClosableControlled";
import TaskStepFooter from "../TaskStepFooter";
import { Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  generalStageValidation,
  instructionStageValidation,
  switchableStageValidation,
} from "../../../../../reducers/GeneralTaskCreator";
import {
  createGeneralTask,
  updateGeneralTask,
} from "../../../../../actions/GeneralTasks/generalTasks";
import {
  toEndDateString,
  toStartDateString,
} from "../../../../../utils/dateUtils";

const ReviewAndCreateStep = ({
  isOpen,
  taskTypeID,
  action,
  taskID,
  onArrowClick,
  navigate,
}) => {
  const dispatch = useDispatch();

  const stages = useSelector((state) => state.taskCreator.taskStages);
  const generalStageIsValid = generalStageValidation(stages.general);
  const instructionStageIsValid = instructionStageValidation(
    stages.instructions
  );
  const switchableStageIsValid = switchableStageValidation(4);

  const isValid =
    generalStageIsValid && instructionStageIsValid && switchableStageIsValid;

  const flowRate =
    stages.switchable.flowRate &&
    (stages.switchable.runningTimeHours || stages.switchable.runningTimeMinutes)
      ? (
          stages.switchable.flowRate *
          (stages.switchable.runningTimeHours +
            stages.switchable.runningTimeMinutes / 60)
        ).toFixed(2)
      : 0;

  const objectToSend = {
    areas:
      stages.instructions.areas &&
      stages.instructions.areas.map((area) => ({ areaId: area.id })),
    assignedTo: stages.general.assignTo.map((employeeId) => ({ employeeId })),
    attachments: stages.general.attachments.map((a) =>
      a.responseData ? a.responseData : a
    ),
    comments: stages.switchable.comments,
    description: stages.general.description,
    endAt: toEndDateString(stages.general.dateRange.end),
    equipments: stages.instructions.equipment.map((machineryId) => ({
      machineryId,
    })),
    generalTaskSubTypeId: stages.switchable.subType,
    generalTaskTypeId: taskTypeID,
    harvestUnitId: stages.switchable.harvestUnitId,
    harvestUnitPickingTypeId: stages.switchable.pickingType,
    name: stages.general.taskName,
    numberOfEmployees: stages.instructions.numberOfEmployees,
    paymentMethod: stages.instructions.paymentMethod,
    priority: stages.general.priority,
    round: stages.switchable.round,
    size: stages.switchable.size,
    startAt: toStartDateString(stages.general.dateRange.start),
    status: stages.general.status,
    subTaskName: stages.general.subTaskName,
    timeBudgeting: stages.switchable.timeBudgeting,
    timeBudgetingPerPlant: stages.switchable.perPlant,
    timeBudgetingPerRow: stages.switchable.perRow,
    unitPrice: stages.switchable.unitPrice,
    varietyId: stages.instructions.variety && stages.instructions.variety.id,
    runningTimeMin:
      (stages.switchable.runningTimeMinutes || 0) +
      (stages.switchable.runningTimeHours || 0) * 60,
    flowRate,
    totalIrrigationWater: stages.switchable.totalIrrigationWater,
  };

  const submitTask = async () => {
    if (!isValid) return;

    const cleanObject = Object.fromEntries(
      Object.entries(objectToSend).filter(
        ([, value]) => Number.isFinite(value) || !!value
      )
    );

    if (action === "CREATE") {
      await dispatch(createGeneralTask(cleanObject));
      navigate("/tasks");
    } else {
      await dispatch(updateGeneralTask(taskID, cleanObject));
      navigate(-1);
    }
  };

  return (
    <SegmentClosableControlled
      onArrowClick={onArrowClick}
      title={"Review and " + (action === "CREATE" ? "create" : "update")}
      open={isOpen}
    >
      {isOpen ? (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column style={{ paddingLeft: 55 }}>
                <span style={{ fontSize: 16 }}>
                  Please review all the inserted info and submit task
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <TaskStepFooter
            submitButtonText={"Submit"}
            isValid={isValid}
            submitAction={submitTask}
          />
        </>
      ) : (
        <></>
      )}
    </SegmentClosableControlled>
  );
};

ReviewAndCreateStep.propTypes = {
  action: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onArrowClick: PropTypes.func,
  taskID: PropTypes.number,
  taskTypeID: PropTypes.number.isRequired,
};

export default ReviewAndCreateStep;
