import { actionType } from "../../constants/GeneralBarcodes";
import { axiosProxy } from "../../utils/axiosProxy";
import { genericAttachmentUploaderWithoutSW } from "../../utils/genericAttachmentUploader";
import { toEndDateString, toStartDateString } from "../../utils/dateUtils";

const url = "/barcodes";
const batchUrl = "/batch_barcodes";

export const fetchScannedBarcodes = ({
  page,
  size,
  search,
  sort,
  filters,
  from,
  to,
  batchIDs = [],
  unpaged = false
}) => async (dispatch, getState) => {
  const { areas, employee } = getState();
  try {
    dispatch({ type: actionType.FETCH_SCANNED_BARCODES_START });

    const params = new URLSearchParams(filters);
    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    batchIDs.forEach(batchID => params.append("batchId", batchID));
    from && params.append("from", from);
    to && params.append("to", to);
    search && params.append("search", search);

    (sort || ["id,asc"]).forEach(field => {
      params.append("sort", field);
    });

    const res = await axiosProxy({ method: "GET", params, url });
    const newData = {
      ...res.data,
      content: res.data.content.map(item => {
        return {
          ...item,
          area: areas.list.content.find(area => area.id === item.areaId),
          employeeBinParts: item.employeeBinParts.map(employeeBinPart => {
            return {
              id: employeeBinPart.id,
              part: employeeBinPart.part,
              employee: employee.list.content.find(
                emp => emp.id === employeeBinPart.employeeId
              )
            };
          }),
          supervisors: item.supervisors.map(supervisor => {
            return {
              id: supervisor.id,
              employee: employee.list.content.find(
                emp => emp.id === supervisor.employeeId
              )
            };
          })
        };
      })
    };
    await dispatch({
      type: actionType.FETCH_SCANNED_BARCODES,
      payload: newData
    });
    return newData;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_SCANNED_BARCODES_FAIL,
      payload: error
    });
  }
};

export const fetchScannedBarcodeById = id => async (dispatch, getState) => {
  const { areas, employee } = getState();
  dispatch({
    type: actionType.FETCH_SCANNED_BARCODE_START
  });

  try {
    const { data } = await axiosProxy({
      method: "GET",
      url: `${url}/${id}`
    });
    const newData = {
      ...data,
      area: areas.list.content.find(area => area.id === data.areaId),
      employeeBinParts: data.employeeBinParts.map(employeeBinPart => {
        return {
          id: employeeBinPart.id,
          part: employeeBinPart.part,
          employee: employee.list.content.find(
            emp => emp.id === employeeBinPart.employeeId
          )
        };
      }),
      supervisors: data.supervisors.map(supervisor => {
        return {
          id: supervisor.id,
          employee: employee.list.content.find(
            employee => employee.id === supervisor.employeeId
          )
        };
      })
    };
    await dispatch({
      type: actionType.FETCH_SCANNED_BARCODE,
      payload: newData
    });

    return newData;
  } catch (e) {
    await dispatch({
      type: actionType.FETCH_SCANNED_BARCODE_FAIL,
      payload: e
    });
  }
};

export const generateGeneralBarcodes = ({
  blockLabel,
  comment,
  cropLabel,
  dateLabel,
  farmLabel,
  patchLabel,
  quantity,
  varietyLabel
}) => async dispatch => {
  try {
    dispatch({
      type: actionType.GENERATE_GENERAL_BARCODES_START
    });

    const res = await axiosProxy({
      method: "POST",
      url: `${url}/generate`,
      data: {
        blockLabel,
        comment,
        cropLabel,
        dateLabel,
        farmLabel,
        patchLabel,
        varietyLabel,
        quantity
      }
    });

    await dispatch({
      type: actionType.GENERATE_GENERAL_BARCODES,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.GENERATE_GENERAL_BARCODES_FAIL,
      payload: error
    });
  }
};

export const updateGeneralBarcodeSubmission = (id, data) => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_SUBMISSION_START
    });
    const res = await axiosProxy({
      method: "PUT",
      data,
      url: `${url}/${id}`
    });

    await dispatch({
      type: actionType.UPDATE_SUBMISSION,
      payload: res.data
    });

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_SUBMISSION_ERROR,
      payload: error
    });
    return { error };
  }
};

export const deleteGeneralBarcodeSubmission = id => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: actionType.DELETE_SUBMISSION_START
    });
    await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`
    });

    await dispatch({
      type: actionType.DELETE_SUBMISSION,
      payload: id
    });

    // TODO: Avoid page reload by setting
    //  the filtering/pagination to the redux store
    window.location.reload();
  } catch (error) {
    dispatch({
      type: actionType.DELETE_SUBMISSION_FAIL,
      payload: error
    });
  }
};

export const uploadAttachment = ({
  file,
  idReplace,
  callBack,
  data,
  parentId
}) =>
  genericAttachmentUploaderWithoutSW({
    url: `${url}/attachments/presign_put_url`,
    file,
    startAction: actionType.UPLOAD_GENERAL_BARCODES_ATTACHMENTS_START,
    successAction: actionType.UPLOAD_GENERAL_BARCODES_ATTACHMENTS,
    errorAction: actionType.UPLOAD_GENERAL_BARCODES_ATTACHMENTS_ERROR,
    callBack,
    idReplace,
    data,
    parentId
  });

export const clearSeries = () => dispatch => {
  dispatch({
    type: actionType.GENERAL_BARCODES_CLEAR_SERIES
  });
};

export const generatePickedBinsPerGroupReport = ({
  filters,
  from,
  to
}) => async dispatch => {
  dispatch({
    type: actionType.GENERATE_PICKED_BINS_PER_GROUP_REPORT_START
  });

  const params = new URLSearchParams(filters);

  try {
    params.append("unpaged", "true");

    from && params.append("from", toStartDateString(from));
    to && params.append("to", toEndDateString(to));

    const res = await axiosProxy({ method: "GET", params, url });
    const pickedBinsData = [];
    const pickedBinsPerGroup = {};

    res.data.content.forEach(barcode => {
      barcode.employeeBinParts.forEach(data => {
        if (data.part !== 1) {
          return pickedBinsData.push({
            groupName: data.employee.groupName,
            groupId: data.employee.groupId,
            part: data.part,
            employeeName: `${data.employee.firstName} ${data.employee.lastName}`,
            employeeId: data.employee.id,
            location: barcode.area.name
          });
        } else {
          return pickedBinsData.push({
            groupName: data.employee.groupName,
            groupId: data.employee.groupId,
            part: data.part,
            employeeName: `${data.employee.firstName} ${data.employee.lastName}`,
            employeeId: data.employee.id,
            location: barcode.area.name
          });
        }
      });
    });

    pickedBinsData.map(group => {
      if (group.groupId === null && !pickedBinsPerGroup[group.groupId]) {
        pickedBinsPerGroup[group.groupId] = {
          groupName: "Workers without group",
          groupId: "-",
          location: [group.location],
          bins: 0,
          employees: {}
        };

        pickedBinsPerGroup[group.groupId].employees[group.employeeId] = {
          name: group.employeeName,
          id: group.employeeId,
          bins: group.part,
          location: [group.location]
        };

        pickedBinsPerGroup[group.groupId].bins += group.part;
      } else if (!pickedBinsPerGroup[group.groupId]) {
        pickedBinsPerGroup[group.groupId] = {
          groupName: group.groupName,
          groupId: group.groupId,
          location: [group.location],
          bins: 0,
          employees: {}
        };

        pickedBinsPerGroup[group.groupId].employees[group.employeeId] = {
          name: group.employeeName,
          id: group.employeeId,
          bins: group.part,
          location: [group.location]
        };

        pickedBinsPerGroup[group.groupId].bins += group.part;
      } else if (pickedBinsPerGroup[group.groupId]) {
        pickedBinsPerGroup[group.groupId].bins += group.part;
        pickedBinsPerGroup[group.groupId].location.push(group.location);

        /*----algorithm of bins per employee----*/

        if (!pickedBinsPerGroup[group.groupId].employees[group.employeeId]) {
          pickedBinsPerGroup[group.groupId].employees[group.employeeId] = {
            name: group.employeeName,
            id: group.employeeId,
            bins: group.part,
            location: [group.location]
          };
        } else {
          pickedBinsPerGroup[group.groupId].employees[group.employeeId].bins +=
            group.part;
          pickedBinsPerGroup[group.groupId].employees[
            group.employeeId
          ].location.push(group.location);
        }
      }
    });

    await dispatch({
      type: actionType.GENERATE_PICKED_BINS_PER_GROUP_REPORT,
      payload: pickedBinsPerGroup
    });
  } catch (e) {
    dispatch({
      type: actionType.GENERATE_PICKED_BINS_PER_GROUP_REPORT_ERROR,
      payload: e
    });
  }
};

export const generatePickedBinsReport = ({
  filters,
  from,
  to
}) => async dispatch => {
  dispatch({
    type: actionType.GENERATE_PICKED_BINS_REPORT_START
  });

  const params = new URLSearchParams(filters);

  try {
    params.append("unpaged", "true");

    from && params.append("from", toStartDateString(from));
    to && params.append("to", toEndDateString(to));

    const res = await axiosProxy({ method: "GET", params, url });

    const farms = {};

    const addBlockToFarm = (farmID, block) => {
      if (!farms[farmID].blocksStat[block.id]) {
        farms[farmID].blocksStat[block.id] = {
          blockName: block.name,
          bins: 0,
          areasStat: {}
        };
      }
    };

    const addAreaToBlock = (farmID, patch) => {
      if (!farms[farmID].blocksStat[patch.parent.id])
        addBlockToFarm(farmID, patch.parent);
      if (!farms[farmID].blocksStat[patch.parent.id].areasStat[patch.id]) {
        farms[farmID].blocksStat[patch.parent.id].areasStat[patch.id] = {
          areaName: patch.name,
          bins: 0
        };
      }
    };

    res.data.content.forEach(barcode => {
      const farmID = barcode.area.farm.id;

      if (!farms[farmID])
        farms[farmID] = {
          farmName: barcode.area.farm.name,
          bins: 0,
          blocksStat: {}
        };

      if (barcode.area.type === "BLOCK") addBlockToFarm(farmID, barcode.area);
      if (barcode.area.type === "PATCH") addAreaToBlock(farmID, barcode.area);

      farms[farmID].bins += 1;
      if (barcode.area.type === "BLOCK") {
        farms[farmID].blocksStat[barcode.area.id].bins += 1;
      } else {
        farms[farmID].blocksStat[barcode.area.parent.id].bins += 1;
        farms[farmID].blocksStat[barcode.area.parent.id].areasStat[
          barcode.area.id
        ].bins += 1;
      }
    });

    await dispatch({
      type: actionType.GENERATE_PICKED_BINS_REPORT,
      payload: farms
    });
  } catch (e) {
    dispatch({
      type: actionType.GENERATE_PICKED_BINS_REPORT_ERROR,
      payload: e
    });
  }
};

export const fetchGeneralBarcodesIDs = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_GENERAL_BARCODE_IDS_START });

    const res = await axiosProxy({ method: "GET", url: `${batchUrl}/ids` });
    const barcodeIDs = await axiosProxy({
      method: "GET",
      url: `/barcodes`,
      params: {
        unpaged: true
      }
    });
    dispatch({
      type: actionType.FETCH_GENERAL_BARCODE_IDS,
      payload: {
        res: res.data,
        barcodeIDs: barcodeIDs.data.content
      }
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_GENERAL_BARCODE_IDS_FAIL,
      payload: error
    });
  }
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.GENERAL_BARCODES_CLEAR_ERROR
  });
};
