import { filter } from "lodash";
import { actionTypes } from "../../constants/HarvestUnits";

const initialState = {
  series: [],
  data: {
    content: [],
    page: {
      number: 0,
      totalPages: 0,
      totalElements: 0,
      size: 10
    }
  },
  filtersOptions: {
    content: [],
    page: {
      number: 0,
      totalPages: 0,
      totalElements: 0,
      size: 10
    }
  },
  list: {
    content: []
  },
  exportData: [],
  filters: {},
  error: null,
  isFetching: false
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionTypes.EXPORT_DATA_HARVEST_UNITS_START:
    case actionTypes.FETCH_HARVEST_UNITS_START:
    case actionTypes.ADD_HARVEST_UNITS_START:
    case actionTypes.UPDATE_HARVEST_UNITS_START:
    case actionTypes.EXPORT_DATA_HARVEST_UNITS_START:
    case actionTypes.DELETE_HARVEST_UNITS_START:
    case actionTypes.FETCH_HARVEST_UNITS_OPTIONS_START:
    case actionTypes.FETCH_HARVEST_UNITS_CONTROL_START:
    case actionTypes.FETCH_HARVEST_UNITS_CONTROL_LIST_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };

    case actionTypes.FETCH_HARVEST_UNITS_CONTROL:
      return {
        ...state,
        error: null,
        data: action.payload,
        isFetching: false
      };
    case actionTypes.FETCH_HARVEST_UNITS_OPTIONS:
      return {
        ...state,
        error: null,
        filtersOptions: action.payload
      };
    case actionTypes.FETCH_HARVEST_UNITS_CONTROL_LIST:
      return {
        ...state,
        error: null,
        list: action.payload,
        isFetching: false
      };
    case actionTypes.EXPORT_DATA_HARVEST_UNITS:
      return {
        ...state,
        error: null,
        exportData: action.payload,
        isFetching: false
      };

    case actionTypes.DELETE_HARVEST_UNITS:
      return {
        ...state,
        error: null,
        isFetching: false
      };
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    case actionTypes.FETCH_HARVEST_UNITS_CONTROL_ERROR:
      return {
        error: action.payload,
        isFetching: false
      };

    case actionTypes.FETCH_HARVEST_UNITS_FILTERS:
      return {
        ...state,
        filters: action.payload
      };

    default:
      return state;
  }
};
