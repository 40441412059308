export const actionType = {
  FETCH_GENERAL_TASKS: "GENERAL_TASKS/FETCH_GENERAL_TASKS",
  FETCH_GENERAL_TASKS_START: "GENERAL_TASKS/FETCH_GENERAL_TASKS_START",
  FETCH_GENERAL_TASKS_FAIL: "GENERAL_TASKS/FETCH_GENERAL_TASKS_FAIL",

  FETCH_GENERAL_TASK_NAMES_START:
    "GENERAL_TASKS/FETCH_GENERAL_TASK_NAMES_START",
  FETCH_GENERAL_TASK_NAMES: "GENERAL_TASKS/FETCH_GENERAL_TASK_NAMES",
  FETCH_GENERAL_TASK_NAMES_FAIL: "GENERAL_TASKS/FETCH_GENERAL_TASK_NAMES_FAIL",

  FETCH_GENERAL_TASK_IDS_START: "GENERAL_TASKS/FETCH_GENERAL_TASK_IDS_START",
  FETCH_GENERAL_TASK_IDS: "GENERAL_TASKS/FETCH_GENERAL_TASK_IDS",
  FETCH_GENERAL_TASK_IDS_FAIL: "GENERAL_TASKS/FETCH_GENERAL_TASK_IDS_FAIL",

  FETCH_GENERAL_TASK_LIST_START: "GENERAL_TASKS/FETCH_GENERAL_TASK_LIST_START",
  FETCH_GENERAL_TASK_LIST: "GENERAL_TASKS/FETCH_GENERAL_TASK_LIST",
  FETCH_GENERAL_TASK_LIST_FAIL: "GENERAL_TASKS/FETCH_GENERAL_TASK_LIST_FAIL",

  FETCH_SUBTASK_LIST_START: "GENERAL_TASKS/FETCH_SUBTASK_LIST_START",
  FETCH_SUBTASK_LIST: "GENERAL_TASKS/FETCH_SUBTASK_LIST",
  FETCH_SUBTASK_LIST_FAIL: "GENERAL_TASKS/FETCH_SUBTASK_LIST_FAIL",

  FETCH_GENERAL_TASKS_BASIC_INFORMATION:
    "GENERAL_TASKS/FETCH_GENERAL_TASKS_BASIC_INFORMATION",
  FETCH_GENERAL_TASKS_BASIC_INFORMATION_START:
    "GENERAL_TASKS/FETCH_GENERAL_TASKS_BASIC_INFORMATION_START",
  FETCH_GENERAL_TASKS_BASIC_INFORMATION_FAIL:
    "GENERAL_TASKS/FETCH_GENERAL_TASKS_BASIC_INFORMATION_FAIL",

  FETCH_GENERAL_TASK_TYPE_START:
    "GENERAL_TASK_TYPE/FETCH_GENERAL_TASK_TYPE_START",
  FETCH_GENERAL_TASK_TYPE: "GENERAL_TASK_TYPE/FETCH_GENERAL_TASK_TYPE",
  FETCH_GENERAL_TASK_TYPE_FAIL:
    "GENERAL_TASK_TYPE/FETCH_GENERAL_TASK_TYPE_FAIL",

  FETCH_GENERAL_TASK_START: "GENERAL_TASK/FETCH_GENERAL_TASK_START",
  FETCH_GENERAL_TASK: "GENERAL_TASK/FETCH_GENERAL_TASK",
  FETCH_GENERAL_TASK_FAIL: "GENERAL_TASK/FETCH_GENERAL_TASK_FAIL",

  DELETE_GENERAL_TASK: "GENERAL_TASKS/DELETE_GENERAL_TASKS",
  DELETE_GENERAL_TASK_START: "GENERAL_TASKS/DELETE_GENERAL_TASK_START",
  DELETE_GENERAL_TASK_FAIL: "GENERAL_TASKS/DELETE_GENERAL_TASK_FAIL",

  CREATE_GENERAL_TASK: "GENERAL_TASKS/CREATE_GENERAL_TASK",
  CREATE_GENERAL_TASK_START: "GENERAL_TASKS/CREATE_GENERAL_TASK_START",
  CREATE_GENERAL_TASK_FAIL: "GENERAL_TASKS/CREATE_GENERAL_TASK_FAIL",

  UPDATE_GENERAL_TASK: "GENERAL_TASKS/UPDATE_GENERAL_TASK",
  UPDATE_GENERAL_TASK_START: "GENERAL_TASKS/UPDATE_GENERAL_TASK_START",
  UPDATE_GENERAL_TASK_FAIL: "GENERAL_TASKS/UPDATE_GENERAL_TASK_FAIL",

  UPDATE_GENERAL_TASK_STATUS: "GENERAL_TASKS/UPDATE_GENERAL_TASK_STATUS",

  GENERAL_TASK_WORKING_TIME_DELETE_WITH_TASK_START:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_DELETE_WITH_TASK_START",
  GENERAL_TASK_WORKING_TIME_DELETE_WITH_TASK_DELETE:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_DELETE_WITH_TASK_DELETE",
  GENERAL_TASK_WORKING_TIME_DELETE_WITH_TASK_FAIL:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_DELETE_WITH_TASK_FAIL",

  GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_START:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_START",
  GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_ARCHIVE:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_ARCHIVE",
  GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_FAIL:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_FAIL",

  FETCH_PAYROLL_REPORT_START: "GENERAL_TASKS/FETCH_PAYROLL_REPORT_START",
  FETCH_PAYROLL_REPORT: "GENERAL_TASKS/FETCH_PAYROLL_REPORT",
  FETCH_PAYROLL_REPORT_FAIL: "GENERAL_TASKS/FETCH_PAYROLL_REPORT_FAIL",

  ADD_WORKING_TIME_TO_GENERAL_TASK:
    "GENERAL_TASKS/ADD_WORKING_TIME_TO_GENERAL_TASK",
  EDIT_WORKING_TIME_OF_GENERAL_TASK:
    "GENERAL_TASKS/EDIT_WORKING_TIME_OF_GENERAL_TASK",

  CLEAR_ERROR: "GENERAL_TASKS/CLEAR_ERROR",
  SET_LOCATION: "GENERAL_TASKS/SET_LOCATION",

  GET_GENERAL_TASK_WORKING_TIME_START:
    "GENERAL_TASKS/GET_GENERAL_TASK_WORKING_TIME_START",
  GET_GENERAL_TASK_WORKING_TIME: "GENERAL_TASKS/GET_GENERAL_TASK_WORKING_TIME",
  GET_GENERAL_TASK_WORKING_TIME_FAIL:
    "GENERAL_TASKS/GET_GENERAL_TASK_WORKING_TIME_FAIL",

  GENERAL_TASK_WORKING_TIME_DELETE_START:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_DELETE_START",
  GENERAL_TASK_WORKING_TIME_DELETE:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_DELETE",
  GENERAL_TASK_WORKING_TIME_DELETE_FAIL:
    "GENERAL_TASKS/GENERAL_TASK_WORKING_TIME_DELETE_FAIL"
};

export const PAYMENT_TYPES = {
  HOURLY_RATE: "HOURLY_RATE",
  PIECE_RATE: "PIECE_RATE"
};
