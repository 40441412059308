import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import {
  activeIngredientsSelector,
  chemicalTypeOptionsSelector,
  matterOptionsSelector,
  trackInventoryOptionsSelector,
  typeOptionsSelector,
} from "selectors/chemicals";
import { get, join, map } from "lodash";
import {
  fetchActiveIngredients,
  fetchFilterValues,
} from "../actions/Chemicals";
import { uppercaseFirstLetter } from "./string";
import { matterToUnits } from "./constToUnits";
import Numeric from "components/Numeric/Numeric";
import { areaFilterOptions } from "selectors/areas";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
import { farmsOptionsFilterSelector } from "selectors/farms";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import moment from "moment";
import { creatorSelector, taskIdSelector } from "selectors/scouting";

export const getScoutingTableColumns = (isFetching) => {
  const columnsConfig = {
    id: {
      withSort: true,
      type: "string",
      id: "id",
      title: "Task ID",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ id }) => `SC-${id}`,
      withSort: true,
      filterId: "seasons",
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector,
      },

      cellWidth: 2,
    },
    date: {
      withSort: true,
      type: "date",
      id: "setOn",
      title: "Date",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ setOn }) => moment(setOn).format("DD/MM/YYYY"),
      withSort: true,
      filter: {
        title: "Date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching} />,
      },
      cellWidth: 2,
    },
    name: {
      withSort: true,
      type: "string",
      id: "name",
      title: "Task name",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ name }) => name,
      withSort: true,
      filterId: "farmId",
      filter: {
        title: "Farm",
        type: filterTypes.MultiSelect,
        selector: farmsOptionsFilterSelector,
      },
      cellWidth: 2,
    },
    farm: {
      withSort: true,
      type: "string",
      id: "farm",
      title: "Farm",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ areas }) => areas.map((item) => item.area.farm.name)[0],
      withSort: true,
      filterId: "areaId",
      filter: {
        title: "Block/Patch",
        type: filterTypes.MultiSelect,
        selector: areaFilterOptions,
      },
      cellWidth: 2,
    },
    areas: {
      withSort: true,
      type: "string",
      id: "areas",
      title: "Block/Pacth",
      cellWidth: 3, // of 16, see Semantic Table
      accessor: ({ areas }) => areas.map((item) => item.area.name).join(", "),
      withSort: true,
      filterId: "creatorIds",
      filter: {
        title: "Creator",
        type: filterTypes.MultiSelect,
        selector: creatorSelector,
      },

      cellWidth: 2,
    },
    issues: {
      withSort: true,
      type: "string",
      id: "issues",
      title: "Issues reported",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ issues }) => issues || "-",
      withSort: true,
      filterId: "ids",
      filter: {
        title: "Task ID",
        type: filterTypes.MultiSelect,
        selector: taskIdSelector,
      },

      cellWidth: 2,
    },
    creator: {
      withSort: true,
      type: "string",
      id: "creator",
      title: "Creator",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ creator }) => `${creator.firstName}${creator.lastName}`,
      withSort: true,
      cellWidth: 2,
    },
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {}),
  }));
};
