import moment from "moment";
import { actionType } from "../../constants/PieceRatePayroll";
import { axiosProxy } from "../../utils/axiosProxy";
import {
  enumerateDaysBetweenDates,
  convertDatesToCertainRange
} from "utils/dateUtils";
import uuid from "uuid/v4";

let uniqId;

const url = "/reporting_module";

export const fetchPieceRatePayrollsByEmployeeId = ({
  employeeId,
  from,
  to
}) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_PIECE_RATE_PAYROLLS_EXPANDED_DATA_START,
      payload: employeeId
    });

    const params = new URLSearchParams({
      unpaged: true,
      sort: "date,desc",
      employeeIds: [employeeId]
    });

    if (from) params.append("from", from);
    if (to)
      params.append(
        "to",
        moment(to)
          .add(1, "day")
          .format("YYYY-MM-DD")
      );

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/payrollPieceRate`
    });

    await dispatch({
      type: actionType.FETCH_PIECE_RATE_PAYROLLS_EXPANDED_DATA,
      payload: {
        employeeId,
        data: res.data.content
      }
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_PIECE_RATE_PAYROLLS_EXPANDED_DATA_FAIL,
      payload: error
    });
  }
};

export const fetchPieceRatePayrollsAllInformation = (
  dateAfter,
  dateBefore,
  filters = {}
) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_PIECE_RATE_PAYROLLS_ALL_INFORMATION_START
    });

    const params = new URLSearchParams({ unpaged: true });

    Object.entries(filters).forEach(([name, value]) => {
      const from = value[0].slice(0, 10);
      const to = value[0].slice(-10);
      return name !== "seasons"
        ? Array.isArray(value)
          ? value.forEach(val => params.append(name, val))
          : params.append(name, value)
        : (params.append("from", from), params.append("to", to));
    });

    if (dateAfter) params.append("from", dateAfter);
    if (dateBefore)
      params.append(
        "to",
        moment(dateBefore)
          .add(1, "day")
          .format("YYYY-MM-DD")
      );

    const res = await axiosProxy({
      method: "GET",
      params,
      url: `${url}/payrollPieceRate`
    });

    const formatedData = res.data.content
      .filter(item => !!item)
      .reduce(
        (
          prev,
          {
            employeeId,
            employeeName,
            contractor,
            workingSeconds,
            numberOfPieces,
            currentRate,
            rate,
            ...restPayroll
          }
        ) => {
          if (prev[employeeId]) {
            prev[employeeId] = {
              ...prev[employeeId],
              workingSeconds: prev[employeeId].workingSeconds + workingSeconds,
              numberOfPieces: prev[employeeId].numberOfPieces + numberOfPieces,
              totalPay: prev[employeeId].totalPay + numberOfPieces * rate,
              expandedData: [
                ...prev[employeeId].expandedData,
                { ...restPayroll, workingSeconds, numberOfPieces, rate }
              ]
            };

            return prev;
          }

          return {
            ...prev,
            [employeeId]: {
              employeeName,
              contractor,
              workingSeconds,
              numberOfPieces,
              currentRate,
              workingSeconds,
              totalPay: numberOfPieces * rate,
              expandedData: [
                { ...restPayroll, workingSeconds, numberOfPieces, rate }
              ]
            }
          };
        },
        {}
      );

    await dispatch({
      type: actionType.SET_PIECE_RATE_PAYROLLS_ALL_INFORMATION,
      payload: Object.values(formatedData)
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_PIECE_RATE_PAYROLLS_ALL_INFORMATION_FAIL,
      payload: error
    });
  }
};

export const fetchPieceRatePayrollsSummary = (
  page,
  size,
  filters = {},
  sort,
  dateAfter,
  dateBefore
) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_PIECE_RATE_PAYROLLS_SUMMARY_START });
    dispatch({
      type: actionType.FETCH_PIECE_RATE_PAYROLLS_ALL_INFORMATION_START
    });

    const params = new URLSearchParams({
      page,
      size
    });

    Object.entries(filters).forEach(([name, value]) => {
      const from = value[0].slice(0, 10);
      const to = value[0].slice(-10);
      return name !== "seasons"
        ? Array.isArray(value)
          ? value.forEach(val => params.append(name, val))
          : params.append(name, value)
        : (params.append("from", from), params.append("to", to));
    });

    if (dateAfter) params.append("from", dateAfter);
    if (dateBefore)
      params.append(
        "to",
        moment(dateBefore)
          .add(1, "day")
          .format("YYYY-MM-DD")
      );

    sort.forEach(field => params.append("sort", field));
    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url: `${url}/payrollPieceRateTotal`
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.SET_PIECE_RATE_PAYROLLS_SUMMARY,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: actionType.FETCH_PIECE_RATE_PAYROLLS_SUMMARY_FAIL,
      payload: error
    });
    dispatch({
      type: actionType.FETCH_PIECE_RATE_PAYROLLS_ALL_INFORMATION_FAIL
    });
  }
};

export const fetchPieceRatePayrollsExcelData = (
  dateAfter,
  dateBefore,
  filters = {}
) => async () => {
  const { dateFrom, dateTo } = convertDatesToCertainRange(
    dateAfter,
    moment(dateBefore)
      .add(1, "day")
      .format("YYYY-MM-DD"),
    2,
    "months"
  );
  const params = new URLSearchParams({ unpaged: true });

  Object.entries(filters).forEach(([name, value]) => {
    const from = value[0].slice(0, 10);
    const to = value[0].slice(-10);
    return name !== "seasons"
      ? Array.isArray(value)
        ? value.forEach(val => params.append(name, val))
        : params.append(name, value)
      : (params.append("from", from), params.append("to", to));
  });

  dateAfter && params.append("from", dateFrom);
  dateBefore && params.append("to", dateTo);

  const res = await axiosProxy({
    method: "GET",
    params,
    url: `${url}/payrollPieceRate`
  });
  const formatedData = res.data.content
    .filter(item => !!item)
    .reduce(
      (
        prev,
        {
          employeeId,
          employeeName,
          contractor,
          workingSeconds,
          numberOfPieces,
          rate,
          date
        }
      ) => {
        const prevEmployee = prev.values[employeeId];
        const payrollDate = `${moment(date).format("ddd DD/MM/YYYY")} (NoP)`;
        const workingMinutes = workingSeconds / 60;
        const coveredPieceByDate = {};
        const workedTimeColumns = [];

        if (prevEmployee && prevEmployee[payrollDate]) {
          prevEmployee[payrollDate] =
            prevEmployee[payrollDate] === 0
              ? numberOfPieces.toString()
              : prevEmployee[payrollDate].concat("|", numberOfPieces);
        } else {
          enumerateDaysBetweenDates(dateFrom, dateTo).forEach(item => {
            const dateFormatedColumnName = `${moment(item).format(
              "ddd DD/MM/YYYY"
            )} (NoP)`;
            workedTimeColumns.push(dateFormatedColumnName);
            coveredPieceByDate[dateFormatedColumnName] =
              payrollDate === dateFormatedColumnName
                ? numberOfPieces.toString()
                : 0;
          });
        }

        if (prevEmployee) {
          return {
            ...prev,
            values: {
              ...prev.values,
              [employeeId]: {
                ...prevEmployee,
                totalWorkingTime:
                  prevEmployee.totalWorkingTime + workingMinutes,
                rates: prevEmployee.rates.includes(rate)
                  ? prevEmployee.rates
                  : [...prevEmployee.rates, rate],
                totalPay: prevEmployee.totalPay + numberOfPieces * rate
              }
            },
            totalPaySum: prev.totalPaySum + numberOfPieces * rate
          };
        }

        return {
          ...prev,
          values: {
            ...prev.values,
            [employeeId]: {
              employeeName,
              contractor,
              ...coveredPieceByDate,
              totalWorkingTime: workingMinutes,
              rates: [rate],
              totalPay: numberOfPieces * rate
            }
          },
          columns: [
            "Employee Name",
            "Contractor",
            ...workedTimeColumns,
            "Total Working Time",
            "Rates ($/P)",
            "Total Pay"
          ],
          totalPaySum: prev.totalPaySum + numberOfPieces * rate
        };
      },
      { values: {}, columns: [], totalPaySum: 0 }
    );
  return {
    ...formatedData,
    values: [
      ...Object.values(formatedData.values).map(
        ({
          employeeName,
          contractor,
          totalWorkingTime,
          rates,
          totalPay,
          ...rest
        }) => {
          return {
            employeeName,
            contractor,
            ...Object.keys(rest).reduce(
              (prev, key) => ({
                ...prev,
                [key]:
                  rest[key] > 0 ? parseFloat(rest[key]).toFixed(2) : rest[key]
              }),
              rest
            ),
            totalWorkingTime: parseFloat(totalWorkingTime).toFixed(2),
            rates: rates.join("|"),
            totalPay: `$ ${totalPay.toFixed(2)}`
          };
        }
      ),
      {
        totalPay: `$ ${formatedData.totalPaySum.toFixed(2)}`
      }
    ],
    fileName: `${dateAfter ? dateFrom.replaceAll("-", ".") : "any time"}-${
      dateBefore ? dateTo.replaceAll("-", ".") : "any time"
    }`
  };
};
