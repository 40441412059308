import moment from "moment";
import React from "react";
import { Label } from "semantic-ui-react";

export const Chip = ({ task }) => {
  const localTime = moment().format("YYYY-MM-DDTHH:mm:ss");
  return (
    <Label
      as="a"
      style={{
        width: "110px",
        height: "28px",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          task.status === "OPEN" && task.expireAt > localTime
            ? "#9DACBA"
            : task.status === "IN_PROGRESS" && task.expireAt > localTime
            ? "#F0AC47"
            : task.status !== "COMPLETED" && task.expireAt < localTime
            ? "#B00020"
            : "#59B655",
        color: "#FFFFFF",
        borderRadius: 20,
        padding: "4px 8px",
        paddingLeft: "0px",
      }}
    >
      {task.status !== "COMPLETED" && task.expireAt < localTime
        ? "Expired"
        : task?.status
        ? (
            task.status
              .toLowerCase()
              .charAt(0)
              .toUpperCase() + task.status?.toLowerCase()?.slice(1)
          ).replace(/_/g, " ")
        : ""}
    </Label>
  );
};
