import React, { useEffect, useMemo, useState } from "react";
import { useFormikContext, Field, useFormik } from "formik";
import CollapsibleBlock from "components/CollapsibleBlock";
import { Container, Grid, Segment, Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { unitsSelector } from "../../../../../selectors/chemicals";
import { fetchUnits } from "actions/Chemicals";
import CropRateForm from "./CropRateForm";
import CropRateRow from "./CropRateRow";
import styles from "./CropRatesSection.module.css";

const CropRates = ({ editSectionIndex, setCheck, setCheckEditForm }) => {
  const { values, setFieldValue } = useFormikContext();
  const { content, isFetching } = useSelector(unitsSelector);
  const [addButtonCheck, setAddButtonCheck] = useState(true);
  const [deleteButtonCheck, setDeleteButtonCheck] = useState(false);
  const cropListCheck = values.rates?.length;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(content) && !isFetching) {
      dispatch(fetchUnits());
    }
  }, [content, isFetching]);

  const onNewRateAdd = cropValues => {
    setFieldValue(
      "rates",
      values.rates ? [...values.rates, cropValues] : [cropValues],
      true
    );
    setAddButtonCheck(true);
  };
  const addProduct = () => {
    setAddButtonCheck(false);
    setDeleteButtonCheck(true);
  };
  const deleteAddingForm = () => {
    setAddButtonCheck(true);
  };
  useEffect(() => {
    setCheck(!addButtonCheck || !cropListCheck);
  });
  return (
    <Segment className={styles.cropRateSegment}>
      <CollapsibleBlock
        title="Crop rates"
        className={styles.root}
        defaultActive={!editSectionIndex || editSectionIndex === 2}
      >
        {!isEmpty(values.rates) && (
          <Grid columns="equal" as={Container}>
            <Grid.Row className={styles.labelContainer}>
              <Grid.Column className={styles.rowLabelCrop}>Crop</Grid.Column>
              <Grid.Column className={styles.rowLabelPurpose}>
                Purpose
              </Grid.Column>
              <Grid.Column className={styles.rowLabelRate}>Rate</Grid.Column>
              <Grid.Column className={styles.rowLabelReentr}>
                Re-entry
              </Grid.Column>
              <Grid.Column className={styles.rowLabelWHP}>WHP</Grid.Column>
              <Grid.Column className={styles.rowLabelComments}>
                Comments
              </Grid.Column>
              {/* <Grid.Column className={styles.rowLabel} /> */}
            </Grid.Row>
            {values.rates.map((rate, index) => (
              <CropRateRow
                chemicalRate={rate}
                index={index}
                cropListCheck={cropListCheck}
                deleteButtonCheck={deleteButtonCheck}
                setCheckEditForm={setCheckEditForm}
                key={rate.id}
                content={content}
                unit={rate?.unit}
              />
            ))}
          </Grid>
        )}
        {cropListCheck && addButtonCheck ? (
          <div className={styles.addButtonContainer}>
            <Button
              type="submit"
              className="button-text color-green"
              onClick={addProduct}
            >
              + Add crop rate
            </Button>
          </div>
        ) : null}

        {(!addButtonCheck || !cropListCheck) && (
          <Segment className={styles.cropRatesForm}>
            <CropRateForm
              cropListCheck={cropListCheck}
              deleteAddingForm={deleteAddingForm}
              deleteButtonCheck={deleteButtonCheck}
              setCheck={setCheck}
              unit={values.unit}
              onSubmit={onNewRateAdd}
            />
          </Segment>
        )}
      </CollapsibleBlock>
    </Segment>
  );
};

export default CropRates;
