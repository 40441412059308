import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Form,
  Icon,
  Input,
  Dropdown,
  Modal
} from "semantic-ui-react";
import HeaderMain from "components/HeaderMain/HeaderMain";
import styles from "./AddTask.module.css";
import { history } from "store";
import { Formik, Field } from "formik";
import {
  tasksPriorityFilter,
  generalTasksPaymentMethod,
  generalTasksAssignTo,
  machineryFilterOptions
} from "selectors/generalTasks";
import ObjectID from "bson-objectid";
import { priorityIcon } from "components/Table/helpers";
import {
  selectedEmployeeViewer,
  selectedMachineryViewer,
  swtichButton
} from "../components/helper";
import {
  updateGeneralTask,
  uploadAttachment,
  createGeneralTask
} from "actions/Tasks";
import AttachmentLabel from "components/Attachments/AttachmentLabel";
import FileChooser from "components/Attachments/FileChooser";
import { isArray, map, range } from "lodash";
import AreasTree from "./AreasTree";
import AreasTable from "./AreasTable";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment/moment";
import DateRangePickerComponent from "./DateRangePicker";

const AddTask = ({
  tableData,
  route,
  employeeList,
  areas,
  areasList,
  machineryList,
  activeTask,
  addedType,
  navigate
}) => {
  const dispatch = useDispatch();
  const taskCreator = useSelector(state => state.taskCreator);
  const [activeMenuItem, setActiveMenuItem] = useState(
    "section-General-details"
  );
  const selectedTask = tableData.content.find(
    item => item.id === activeTask?.id
  );
  const [setMenuOpen, menuOpen] = useState(false);
  const [openAreaModal, setOpenAreaModal] = useState(false);
  const [areaType, setAreaType] = useState("ALL");
  const [loadingAttachments, setLoadingAttachments] = useState([]);
  const [data, setData] = useState(
    selectedTask?.areas?.map(item => item?.areaId) || []
  );

  const [selectedItem, setSelectedItem] = useState(
    selectedTask?.areas
      ? areasList
          .filter(item => selectedTask?.areas.includes(item.id))
          .map(area => ({
            id: area.id,
            type: area.type,
            hasSubAreas: area.hasSubAreas
          }))
      : []
  );

  const generalTaskType = taskCreator.taskTypes.content.find(
    item => item.id === selectedTask?.generalTaskTypeId
  );
  const taskInitialValue = selectedTask
    ? {
        name: selectedTask.name,
        priority: selectedTask.priority,
        start: selectedTask.startAt,
        end: selectedTask.endAt,
        equipments: selectedTask.equipments.map(item => item?.machineryId),
        expireAt: selectedTask.expireAt,
        assignTo: selectedTask.assignedTo?.map(item => item?.employeeId) || [],
        supervisors:
          selectedTask.supervisors?.map(item => item?.employeeId) || [],
        paymentMethod: selectedTask.paymentMethod,
        description: selectedTask.description,
        attachments: selectedTask.attachments,
        areaIds: selectedTask.areas.map(item => item?.areaId) || [],
        comments: selectedTask.comments,
        timeBudgeting: selectedTask.timeBudgeting,
        timeBudgetingPerPlant: selectedTask.timeBudgetingPerPlant,
        timeBudgetingPerRow: selectedTask.timeBudgetingPerRow,
        round: selectedTask.round,
        subType: selectedTask.subType
      }
    : {
        name: "",
        priority: null,
        start: null,
        end: null,
        equipments: [],
        expireAt: null,
        assignTo: [],
        supervisors: [],
        paymentMethod: null,
        description: "",
        attachments: [],
        areaIds: [],
        comments: "",
        timeBudgeting: null,
        timeBudgetingPerPlant: null,
        timeBudgetingPerRow: null,
        round: null,
        subType: ""
      };
  const handleMenuItemClick = menuItem => {
    setActiveMenuItem(menuItem);
    const sectionId = menuItem;
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const onAttachmentSelected = (setFieldValue, values) => files => {
    setLoadingAttachments(attachments => [...attachments, "attachments"]);
    files.forEach(file => {
      const dataToSend = {
        file,
        idReplace: ObjectID().toHexString(),
        callBack: loadedFile => {
          setFieldValue(
            "attachments",
            values["attachments"]
              ? [...values["attachments"], loadedFile]
              : [loadedFile]
          );
          setLoadingAttachments(attachments =>
            attachments.filter(i => i !== "attachments")
          );
        }
      };
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(uploadAttachment(dataToSend));
      };
    });
  };

  const subTypes = taskCreator.taskTypes.content.find(
    type => +type.id === (selectedTask ? +generalTaskType.id : +addedType.id)
  )?.taskSubTypes;

  const dataFormater = areas.content
    .map(item => {
      const children = areas.content
        .filter(
          a =>
            a.type === "PATCH" &&
            areas.content.some(area => area.parent?.id === item.id)
        )
        .filter(ch => ch.parent.id === item.id);
      return {
        ...item,
        children
      };
    })
    .filter(a => a.type !== "PATCH");

  const prioritiesList = useSelector(tasksPriorityFilter).options.map(item => ({
    key: item.key,
    text: item.label,
    value: item.value
  }));

  const paymentMethod = useSelector(generalTasksPaymentMethod).options.map(
    item => ({
      key: item.key,
      text: item.label,
      value: item.value
    })
  );

  const assignees = useSelector(generalTasksAssignTo).options.map(item => ({
    key: item.key,
    text: item.label,
    value: item.value
  }));

  const machineryOptions = useSelector(machineryFilterOptions).options.map(
    item => ({
      key: item.key,
      text: item.label,
      value: item.value
    })
  );

  return (
    <Container id="el" fluid className={styles.root}>
      <HeaderMain route={route} menuOpen={menuOpen} online={true} />
      <Formik initialValues={taskInitialValue}>
        {({ values, setFieldValue, setValues, isValid }) => {
          const dataToSend = {
            name: values.name,
            priority: values.priority,
            startAt: values.start,
            endAt: values.end,
            expireAt: values.expireAt,
            assignedTo: values.assignTo.map(item =>
              typeof item === "object"
                ? item
                : {
                    employeeId: item
                  }
            ),
            attachments: values.attachments,
            supervisors: values.supervisors.map(item =>
              typeof item === "object"
                ? item
                : {
                    employeeId: item
                  }
            ),
            paymentMethod: values.paymentMethod,
            description: values.description,
            areas: values.areaIds.map(item => ({
              areaId: item,
              completed: false
            })),
            equipments: values.equipments.map(item =>
              typeof item === "object"
                ? item
                : {
                    machineryId: item
                  }
            ),
            round: values.round,
            subType: values.subType,
            timeBudgeting: values.timeBudgeting,
            timeBudgetingPerPlant: values.timeBudgetingPerPlant,
            timeBudgetingPerRow: values.timeBudgetingPerRow,
            comments: values.comments,
            generalTaskTypeId: selectedTask ? generalTaskType.id : addedType.id,
            generalTaskSubTypeId: values?.subType?.id,
            status: selectedTask?.status || "OPEN"
          };

          const validation =
            !!dataToSend.startAt &&
            !!dataToSend.endAt &&
            !!dataToSend.assignedTo?.length &&
            !!dataToSend.paymentMethod &&
            !!dataToSend.areas?.length;

          const handelDeleteAreas = id => {
            return setFieldValue(
              "areaIds",
              values.areaIds.filter(a =>
                isArray(id) ? !id.includes(a) : a !== id
              )
            );
          };

          const submitTask = async () => {
            if (!isValid) return;

            if (!activeTask.id) {
              await dispatch(createGeneralTask(dataToSend));
              navigate("/tasks");
            } else {
              await dispatch(updateGeneralTask(activeTask.id, dataToSend));
              navigate(-1);
            }
          };

          return (
            <Form>
              <AreasTree
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                open={openAreaModal}
                closeOnDimmerClick={false}
                closeOnEscape={false}
                size="large"
                onClick={e => e.stopPropagation()}
                className={styles.areaModal}
                /*    areasSelector={areasSelector} */
                setFieldValue={setFieldValue}
                values={values}
                data={data}
                setOpenAreaModal={setOpenAreaModal}
                areas={dataFormater}
                setData={setData}
              />

              <div className={styles.contentWrapper}>
                <div className={styles.headerContainer}>
                  <div className={styles.header}>
                    {selectedTask ? (
                      <>Edit {selectedTask.name} task</>
                    ) : (
                      `Add ${addedType.name} task`
                    )}
                  </div>
                </div>

                <div className={styles.formContainer}>
                  <div className={styles.sectionTableWrapper}>
                    <div className={styles.navigateMenu}>
                      <div className={styles.generalInfoSection}>
                        <div
                          className={
                            activeMenuItem === "section-General-information" ||
                            activeMenuItem === "section-General-details" ||
                            activeMenuItem === "section-Timing" ||
                            activeMenuItem === "section-Assign-to" ||
                            activeMenuItem === "section-Additional-Information"
                              ? styles.activeMenuHeader
                              : styles.menuHeader
                          }
                          onClick={() =>
                            handleMenuItemClick("section-General-information")
                          }
                        >
                          General information
                        </div>
                        <div
                          className={
                            activeMenuItem === "section-General-details"
                              ? styles.activeSubHeader
                              : styles.menuSubHeader
                          }
                          onClick={() =>
                            handleMenuItemClick("section-General-details")
                          }
                        >
                          General details
                        </div>
                        <div
                          className={
                            activeMenuItem === "section-Timing"
                              ? styles.activeSubHeader
                              : styles.menuSubHeader
                          }
                          onClick={() => handleMenuItemClick("section-Timing")}
                        >
                          Timing
                        </div>
                        <div
                          className={
                            activeMenuItem === "section-Assign-to"
                              ? styles.activeSubHeader
                              : styles.menuSubHeader
                          }
                          onClick={() =>
                            handleMenuItemClick("section-Assign-to")
                          }
                        >
                          Assign to
                        </div>
                        <div
                          onClick={() =>
                            handleMenuItemClick(
                              "section-Additional-Information"
                            )
                          }
                          className={
                            activeMenuItem === "section-Additional-Information"
                              ? styles.activeSubHeader
                              : styles.menuSubHeader
                          }
                        >
                          Additional information
                        </div>
                      </div>
                      <div className={styles.locationSection}>
                        <div
                          onClick={() =>
                            handleMenuItemClick("section-Locations")
                          }
                          className={
                            activeMenuItem === "section-Locations"
                              ? styles.activeMenuHeader
                              : styles.menuHeader
                          }
                        >
                          Location
                        </div>
                        <div
                          className={
                            activeMenuItem === "section-Locations"
                              ? styles.activeSubHeader
                              : styles.menuSubHeader
                          }
                          onClick={() =>
                            handleMenuItemClick("section-Locations")
                          }
                        >
                          Select areas
                        </div>
                      </div>
                      <div className={styles.detailsSection}>
                        <div
                          className={
                            activeMenuItem === "section-Details" ||
                            activeMenuItem === "section-Task-details"
                              ? styles.activeMenuHeader
                              : styles.menuHeader
                          }
                          onClick={() => handleMenuItemClick("section-Details")}
                        >
                          Details
                        </div>
                        <div
                          className={
                            activeMenuItem === "section-Details"
                              ? styles.activeSubHeader
                              : styles.menuSubHeader
                          }
                          onClick={() => handleMenuItemClick("section-Details")}
                        >
                          Machinery set-up
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${styles.navigateTableContainer} scrollable-container`}
                    >
                      <div
                        className={styles.tableHeader}
                        id="section-General-information"
                      >
                        General information
                      </div>
                      <div
                        id="section-General-details"
                        className={styles.tableSegment}
                      >
                        <div className={styles.tableSubHeader}>
                          General details
                        </div>
                        <Form.Group>
                          <Form.Field width={8}>
                            <label className={styles.formLabel}>
                              Task Name
                            </label>
                            <Input
                              onFocus={() =>
                                handleMenuItemClick("section-General-details")
                              }
                              value={values.name}
                              maxLength={30}
                              onChange={(_, data) =>
                                setFieldValue("name", data.value)
                              }
                              fluid
                              type={"text"}
                            />

                            <div className={styles.warnMessage}>
                              {values.name.length >= 30 && "30 characters max"}
                            </div>
                          </Form.Field>
                          <Form.Field width={8}>
                            <label className={styles.formLabel}>Priority</label>
                            <Dropdown
                              onOpen={() =>
                                handleMenuItemClick("section-General-details")
                              }
                              text={
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px"
                                  }}
                                >
                                  {values.priority &&
                                    priorityIcon(values.priority)}
                                  {values.priority &&
                                    (
                                      values.priority
                                        .toLowerCase()
                                        .charAt(0)
                                        .toUpperCase() +
                                      values.priority.toLowerCase().slice(1)
                                    ).replace(/_/g, " ")}
                                </span>
                              }
                              icon={<Icon name="chevron down" />}
                              className={styles.dropdownCustomStyle}
                              options={prioritiesList}
                              onChange={(_, data) =>
                                setFieldValue("priority", data.value)
                              }
                            />
                          </Form.Field>
                        </Form.Group>
                      </div>
                      <div className={styles.tableSegment} id="section-Timing">
                        <div className={styles.tableSubHeader}>Timing</div>
                        <Form.Group>
                          <Form.Field width={8} required>
                            <label className={styles.formLabel}>
                              Planned date range
                            </label>
                            <DateRangePickerComponent
                              handleMenuItemClick={handleMenuItemClick}
                              setFieldValue={setFieldValue}
                              dateStart={values.start}
                              dateEnd={values.end}
                            />
                          </Form.Field>
                          <Form.Field width={8}>
                            <label className={styles.formLabel}>
                              Expiry date and time
                            </label>
                            <div className={styles.calendarWrapper}>
                              <Datetime
                                value={
                                  values.expireAt
                                    ? moment(values.expireAt)
                                    : null
                                }
                                dateFormat="DD/MM/YYYY"
                                timeFormat="HH:mm"
                                inputProps={{
                                  placeholder: "dd/mm/yyyy hh:mm",
                                  readOnly: false
                                }}
                                onChange={selectedDate => {
                                  const formattedDate = selectedDate
                                    ? moment(selectedDate).format(
                                        "YYYY-MM-DDTHH:mm:ss"
                                      )
                                    : null;
                                  setFieldValue("expireAt", formattedDate);
                                }}
                                className={styles.expirCalendar}
                              />
                              <div className={styles.calendarIcon}>
                                <Icon name="calendar outline" />
                              </div>
                            </div>
                          </Form.Field>
                        </Form.Group>
                      </div>
                      <div
                        className={styles.tableSegment}
                        id="section-Assign-to"
                      >
                        <div className={styles.tableSubHeader}>Assign to</div>
                        <Form.Group widths="equal">
                          <Form.Field>
                            <label className={styles.formLabel}>
                              Supervisor
                            </label>
                            <Dropdown
                              onOpen={() =>
                                handleMenuItemClick("section-Assign-to")
                              }
                              placeholder="Add a supervisor"
                              value={values.supervisors}
                              multiple
                              icon={<Icon name="chevron down" />}
                              className={styles.dropdownCustomStyle}
                              options={assignees}
                              text={
                                !!values.supervisors.length &&
                                `${values.supervisors.length} supervisor${
                                  values.supervisors.length > 1 ? "s" : ""
                                }`
                              }
                              onChange={(_, data) =>
                                setFieldValue("supervisors", data.value)
                              }
                            />
                          </Form.Field>
                          <Form.Field required>
                            <label className={styles.formLabel} required>
                              Assignees
                            </label>
                            <Dropdown
                              onOpen={() =>
                                handleMenuItemClick("section-Assign-to")
                              }
                              placeholder="Add an assignee"
                              direction="left"
                              value={values.assignTo}
                              multiple
                              icon={<Icon name="chevron down" />}
                              className={styles.dropdownCustomStyle}
                              options={assignees}
                              text={
                                !!values.assignTo.length &&
                                `${values.assignTo.length} assignee${
                                  values.assignTo.length > 1 ? "s" : ""
                                }`
                              }
                              onChange={(_, data) =>
                                setFieldValue("assignTo", data.value)
                              }
                            />
                          </Form.Field>
                        </Form.Group>
                        <div className={styles.employeesLabelContainer}>
                          {!!values.supervisors?.length &&
                            selectedEmployeeViewer(
                              "supervisors",
                              "Supervisors",
                              employeeList,
                              setValues,
                              values
                            )}
                          {!!values.assignTo?.length &&
                            selectedEmployeeViewer(
                              "assignTo",
                              "Assignees",
                              employeeList,
                              setValues,
                              values
                            )}
                        </div>
                        <Form.Group widths="equal">
                          <Form.Field required>
                            <label className={styles.formLabel} required>
                              Payment method
                            </label>
                            <Dropdown
                              onOpen={() =>
                                handleMenuItemClick("section-Assign-to")
                              }
                              placeholder="Select method"
                              value={values.paymentMethod}
                              icon={<Icon name="chevron down" />}
                              className={styles.dropdownCustomStyle}
                              options={paymentMethod}
                              onChange={(_, data) =>
                                setFieldValue("paymentMethod", data.value)
                              }
                            />
                          </Form.Field>
                          <Form.Field></Form.Field>
                          <Form.Field></Form.Field>
                        </Form.Group>
                      </div>
                      <div
                        className={styles.tableASegment}
                        id="section-Additional-Information"
                      >
                        <div className={styles.tableSubHeader}>
                          Additional information
                        </div>
                        <Form.Group>
                          <Field
                            onClick={() =>
                              handleMenuItemClick(
                                "section-Additional-Information"
                              )
                            }
                            placeholder="You can leave some notes here"
                            as={Form.TextArea}
                            width={16}
                            name="description"
                            label="Notes"
                            onChange={(_, { value }) =>
                              setFieldValue("description", value)
                            }
                          />
                        </Form.Group>
                        <Form.Group className={styles.attachFileWrapper}>
                          <Container
                            fluid
                            className={styles.generalAttachmentsContainer}
                          >
                            {map(values.attachments, (attachment, index) => (
                              <AttachmentLabel
                                attachment={attachment}
                                withDownloadfromEdit={
                                  attachment.online ? false : true
                                }
                                onDeleteClick={() => {
                                  setFieldValue(
                                    "attachments",
                                    values.attachments.filter(
                                      (_, i) => i !== index
                                    )
                                  );
                                }}
                              />
                            ))}
                            <FileChooser
                              className={styles.generalAttachmentsButton}
                              onChange={onAttachmentSelected(
                                setFieldValue,
                                values
                              )}
                              label="Attach files"
                              disabled={loadingAttachments.includes(
                                "attachments"
                              )}
                            />
                          </Container>
                        </Form.Group>
                      </div>
                      <div
                        className={styles.tableHeader}
                        id="section-Locations"
                      >
                        Location
                      </div>
                      <div className={styles.tableSegment}>
                        <Form.Group className={styles.areasFileWrapper}>
                          <div className={styles.tableSubHeader}>
                            Select Areas
                          </div>
                          <div className={styles.locationBtnWrapper}>
                            <Button
                              className={styles.addBtn}
                              onClick={() => (
                                setOpenAreaModal(true),
                                handleMenuItemClick("section-Locations")
                              )}
                            >
                              <Icon className={styles.plusIcon} name="plus" />
                              Select areas
                            </Button>
                            <div className={styles.switchButtonWrapper}>
                              {swtichButton(areaType, setAreaType, null)}
                            </div>
                          </div>
                          {!!values.areaIds.length && (
                            <div className={styles.tableContainer}>
                              <AreasTable
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                                handelDeleteAreas={handelDeleteAreas}
                                /* areasSelector={areasSelector} */
                                setFieldValue={setFieldValue}
                                areaType={areaType}
                                areas={areasList}
                                areasIds={dataToSend.areas}
                              />
                            </div>
                          )}
                        </Form.Group>
                      </div>
                      <div className={styles.tableHeader} id="section-Details">
                        Details
                      </div>
                      <div className={styles.tableSegment}>
                        <div className={styles.tableSubHeader}>
                          Machinery set-up
                        </div>
                        <Form.Group>
                          <Form.Field width={6}>
                            <label className={styles.formLabel}>
                              Equipment
                            </label>
                            <Dropdown
                              onOpen={() =>
                                handleMenuItemClick("section-Details")
                              }
                              placeholder="Select a machinery"
                              direction="right"
                              value={values.equipments}
                              multiple
                              icon={<Icon name="chevron down" />}
                              className={styles.dropdownCustomStyle}
                              options={machineryOptions}
                              text={
                                !!values.equipments.length &&
                                `${values.equipments.length} machineries`
                              }
                              onChange={(_, data) =>
                                setFieldValue("equipments", data.value)
                              }
                            />
                          </Form.Field>
                          <Form.Field
                            width={10}
                            className={styles.machinerList}
                          >
                            {!!values.equipments?.length &&
                              selectedMachineryViewer(
                                "equipments",
                                "",
                                machineryList,
                                setValues,
                                values
                              )}
                          </Form.Field>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.buttonContainer}>
                  <div className={styles.buttonWrapper}>
                    <div className={styles.cancelButton}>
                      <Button
                        content="Cancel"
                        color="green"
                        basic
                        onClick={() => navigate(-1)}
                      />
                    </div>
                    <div className={styles.saveButton}>
                      <Button
                        disabled={!validation}
                        content={"Save"}
                        color="green"
                        onClick={() => submitTask()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
AddTask.propTypes = {};
export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");
  return {
    isArchivedView,
    tableData: state.newTasks.data || [],
    attachments: state.newTasks.attachments || [],
    addedType: state.newTasks.addedType || {},
    isUploadingAttachment: state.newTasks.isUploadingAttachment,
    isFetching: state.newTasks.isFetching,
    isWorkinTimeFetching: state.newTasks.isWorkinTimeFetching,
    areasList: state.areas.list.content,
    areas: state.areas.list,
    employeeList: state.employee.list.content,
    activeTask: state.newTasks.activeItem,
    machineryList: state.machinery.data.content
  };
})(AddTask);
