import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import { areaFilterOptions } from "selectors/areas";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import moment from "moment";

export const getRecomendationTableColumns = (isFetching) => {
  const columnsConfig = {
    id: {
      withSort: true,
      type: "string",
      id: "id",
      title: "Task ID",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ id }) => `RT-${id}`,
      withSort: true,
      filterId: "seasons",
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector,
      },

      cellWidth: 2,
    },
    name: {
      withSort: true,
      type: "string",
      id: "name",
      title: "Task name",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ name }) => name || "-",
      withSort: true,
      cellWidth: 2,
    },
    date: {
      withSort: true,
      type: "date",
      id: "setOn",
      title: "Date",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ plannedDate }) => moment(plannedDate).format("DD/MM/YYYY"),
      withSort: true,
      filter: {
        title: "Date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching} />,
      },
      cellWidth: 2,
    },

    applicationType: {
      withSort: true,
      type: "string",
      id: "applicationType",
      title: "Type",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ applicationType }) =>
        (
          applicationType
            .toLowerCase()
            .charAt(0)
            .toUpperCase() + applicationType.toLowerCase().slice(1)
        ).replace(/_/g, " ") || "-",
      withSort: true,
      filterId: "areaIds",
      filter: {
        title: "Block/Patch",
        type: filterTypes.MultiSelect,
        selector: areaFilterOptions,
      },
      cellWidth: 2,
    },
    urgency: {
      withSort: true,
      type: "string",
      id: "urgencyLevel",
      title: "Urgency",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ urgencyLevel }) =>
        (
          urgencyLevel
            .toLowerCase()
            .charAt(0)
            .toUpperCase() + urgencyLevel.toLowerCase().slice(1)
        ).replace(/_/g, " ") || "-",
      withSort: true,
      cellWidth: 2,
    },
    status: {
      withSort: true,
      type: "string",
      id: "status",
      title: "Status",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ status }) =>
        (
          status
            .toLowerCase()
            .charAt(0)
            .toUpperCase() + status.toLowerCase().slice(1)
        ).replace(/_/g, " ") || "-",
      withSort: true,
      cellWidth: 2,
    },
    areas: {
      withSort: false,
      type: "string",
      id: "areas",
      title: "Block/Pacth",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ areas }) => areas.map((item) => item.area.name).join(", "),
      withSort: true,
      cellWidth: 2,
    },
    product: {
      withSort: false,
      type: "product",
      id: "product",
      title: "Product",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ chemicals }) => chemicals[0]?.chemical.name,
      withSort: true,
      cellWidth: 2,
    },
    creator: {
      withSort: true,
      type: "string",
      id: "creator",
      title: "Creator",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ creator }) => `${creator.firstName} ${creator.lastName}`,
      withSort: true,
      cellWidth: 2,
    },
    assignees: {
      withSort: false,
      type: "assignees",
      id: "assignees",
      title: "Assignees",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ assignees }) =>
        assignees
          .map((item) => `${item.employee.firstName}${item.employee.lastName}}`)
          .join(", "),
      withSort: true,
      cellWidth: 2,
    },
    totalVat: {
      withSort: false,
      type: "totalVat",
      id: "totalVat",
      title: "Total Vat",
      cellWidth: 2, // of 16, see Semantic Table
      accessor: ({ assignees }) => "-",
      withSort: true,
      cellWidth: 2,
    },
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {}),
  }));
};
