import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./QrScanner.module.css";
import { Button, Grid } from "semantic-ui-react";
import { beep } from "../../utils/sounds";
import QrReader from "react-qr-scanner";

const QrScanner = ({ validateBarcode, addAction, onDone, items }) => {
  const [lastValue, setLastValue] = useState(null);
  const [lastValueTs, setLastValueTs] = useState(null);
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <div>Total scanned: {items.length}</div>
        </Grid.Column>
        <Grid.Column width={16}>
          <div>
            <QrReader
              delay={50}
              onError={() => {}}
              onScan={value => {
                if (value && validateBarcode(value)) {
                  if (lastValue !== value || lastValueTs + 5000 < Date.now()) {
                    setLastValue(value);
                    setLastValueTs(Date.now());
                    beep();
                    addAction(value);
                  }
                }
              }}
              className={styles.qrReader}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column align="center">
          <Button primary onClick={onDone} type="button">
            Done
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

QrScanner.propTypes = {
  validateBarcode: PropTypes.func,
  addAction: PropTypes.func,
  onDone: PropTypes.func,
  switchScanMode: PropTypes.func,
  items: PropTypes.array.isRequired
};

QrScanner.defaultProps = {
  validateBarcode: () => true,
  addAction: () => {},
  onDone: () => {}
};

export default QrScanner;
