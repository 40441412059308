import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/Farms";
import { actionType as type } from "../../constants/Areas";

export const updateFarm = ({
  farmStructure,
  location,
  name,
  weatherStation,
  id
}) => async dispatch => {
  let url = `/farms/${id}`;

  try {
    dispatch({
      type: actionType.UPDATE_FARM_START
    });
    const res = await axiosProxy({
      method: "PUT",
      data: {
        farmStructure,
        id,
        location,
        name,
        weatherStation
      },
      url
    });
    dispatch({
      type: actionType.UPDATE_FARM,
      payload: res.data
    });
    dispatch({
      type: type.UPDATE_AREAS_WEATERSTATION,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.UPDATE_FARM_FAIL,
      payload: error
    });
  }
};

export const addNewFarm = ({
  farmStructure,
  location,
  name,
  weatherStation
}) => async dispatch => {
  let url = "/farms";
  try {
    dispatch({
      type: actionType.ADD_NEW_FARM_START
    });
    const res = await axiosProxy({
      method: "POST",
      data: {
        farmStructure,
        location,
        name,
        weatherStation
      },
      url
    });

    dispatch({
      type: actionType.ADD_NEW_FARM,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.ADD_NEW_FARM_FAIL,
      payload: error
    });
  }
};

export const setFarmField = ({ fieldName, fieldValue }) => {
  return {
    type: actionType.SET_NEW_FARM_FIELD,
    payload: { fieldName, fieldValue }
  };
};

export const setPolygon = polygon => {
  return {
    type: actionType.SET_NEW_POLYGON,
    payload: polygon
  };
};

export const clearPolygons = clearPolygons => {
  return {
    type: actionType.CLEAR_NEW_POLYGON,
    payload: clearPolygons
  };
};

export const initBlock = block => {
  return {
    type: actionType.INIT_NEW_BLOCK,
    payload: block
  };
};

export const setCurrentBlock = blockId => {
  return {
    type: actionType.SET_CURRENT_BLOCK,
    payload: blockId
  };
};

export const setCurrentPatch = blockId => {
  return {
    type: actionType.SET_CURRENT_PATCH,
    payload: blockId
  };
};
export const removeBlock = blockId => {
  return {
    type: actionType.REMOVE_BLOCK_BY_ID,
    payload: blockId
  };
};

export const archiveBlockById = (blockId, archive) => {
  return {
    type: actionType.ARCHIVE_BLOCK_BY_ID,
    payload: { blockId, archive }
  };
};

export const removePatchesFromBlock = blockId => {
  return {
    type: actionType.REMOVE_PATCHES_FROM_BLOCK,
    payload: blockId
  };
};

export const setBlockField = ({ blockId, fieldName, fieldValue }) => {
  return {
    type: actionType.SET_NEW_BLOCK_FIELD,
    payload: { blockId, fieldName, fieldValue }
  };
};

export const setBlockFieldsMultiple = ({ blockId, fields }) => {
  return {
    type: actionType.SET_NEW_BLOCK_FIELDS,
    payload: { blockId, fields }
  };
};

export const setPatchField = ({ blockId, patchId, fieldName, fieldValue }) => {
  return {
    type: actionType.SET_NEW_PATCH_FIELD,
    payload: { blockId, patchId, fieldName, fieldValue }
  };
};

export const addPatchesToBlock = ({ blockId, patches }) => {
  return {
    type: actionType.ADD_PATCHES_TO_BLOCK,
    payload: { blockId, patches }
  };
};

export const removeAllBlocks = () => {
  return {
    type: actionType.REMOVE_ALL_BLOCKS
  };
};
export const clearError = () => {
  return {
    type: actionType.NEW_FARM_CLEAR_ERROR
  };
};

export const toggleShowArchived = show => {
  return {
    type: actionType.TOGGLE_SHOW_ARCHIVED,
    payload: show
  };
};
