import { find } from "lodash";
import { axiosProxy } from "../../utils/axiosProxy";
import { actionType as type } from "../../constants/ApplicationsRecordByBlock";
import uuid from "uuid/v4";

let uniqId;

const actionsTypes = {
  page: {
    start: type.FETCH_SPRAY_TASKS_START,
    set: type.FETCH_SPRAY_TASKS,
    fail: type.FETCH_SPRAY_TASKS_FAIL
  },
  list: {
    start: type.FETCH_SPRAY_TASKS_LIST_START,
    set: type.FETCH_SPRAY_TASKS_LIST,
    fail: type.FETCH_SPRAY_TASKS_LIST_FAIL
  }
};

const parseResponseValue = ({ content, ...restData }, allAreasFromStorage) => ({
  content: content.map(({ areas, ...rest }) => ({
    areas: areas.map(({ areaId, ...restOfArea }) => ({
      area: find(allAreasFromStorage, a => a.id === areaId),
      ...restOfArea
    })),
    ...rest
  })),
  ...restData
});

export const fetchSprayTasks = ({
  page,
  size,
  filters = {},
  sort,
  dateAfter,
  dateBefore,
  unpaged = false
}) => async (dispatch, getState) => {
  const actionTypes = actionsTypes[unpaged ? "list" : "page"];
  const { areas, employee, chemical } = getState();
  try {
    dispatch({ type: actionTypes.start });

    const url = "/spray_tasks_v3?status=COMPLETED";
    const params = new URLSearchParams({ unpaged });

    params.append("page", page);
    params.append("size", size);

    Object.entries(filters).forEach(([name, value]) => {
      const from = value[0].slice(0, 10);
      const to = value[0].slice(-10);
      return name !== "seasons"
        ? Array.isArray(value)
          ? value.forEach(val => params.append(name, val))
          : params.append(name, value)
        : (params.append("dateAfter", from), params.append("dateBefore", to));
    });

    if (dateAfter) params.append("dateAfter", dateAfter);
    if (dateBefore) params.append("dateBefore", dateBefore);

    sort.forEach(field => params.append("sort", field));
    uniqId = uuid();
    const res = await axiosProxy({ method: "GET", params, uniqId, url });
    const newData = {
      ...res.data,
      content: res.data.content.map((item, index) => {
        return {
          ...item,
          assignees: item.assignees.map(a => {
            return {
              ...a,
              employee: employee.list.content.find(e => e.id === a.employeeId)
            };
          }),
          supervisors: item.supervisors.map(s => {
            return {
              ...s,
              employee: employee.list.content.find(e => e.id === s.employeeId)
            };
          }),
          chemicals: item.chemicals.map(chem => {
            return {
              ...chem,
              chemical: chemical.list.content?.find(
                c => c.id === chem.chemicalId
              ),
              chemicalRate: chemical.list.content
                ?.find(c => c.id === chem.chemicalId)
                ?.rates?.find(r => r.id === chem.chemicalRateId)
            };
          }),
          creator: employee.list.content.find(e => e.id === item.creatorId)
        };
      })
    };
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionTypes.set,
        payload: parseResponseValue(newData, areas.list.content)
      });
    }
    return newData;
  } catch (error) {
    dispatch({
      type: actionTypes.fail,
      payload: error
    });
  }
};
