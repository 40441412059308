import { actionType } from "../../constants/PackedBoxes";

const initialState = {
  data: {
    content: [],
    page: {}
  },
  countPerEmployee: {
    content: []
  },
  isFetching: false,
  isSaving: true,
  error: null
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.ADD_PACKED_BOXES_START:
      return {
        ...state,
        isSaving: true
      };
    case actionType.ADD_PACKED_BOXES:
      return {
        ...state,
        isSaving: false
      };
    case actionType.ADD_PACKED_BOXES_FAIL:
      return {
        ...state,
        isSaving: false,
        error: action.payload
      };
    case actionType.FETCH_PACKED_BOXES_START:
    case actionType.FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case actionType.FETCH_PACKED_BOXES: {
      return {
        ...state,
        data: {
          ...action.payload
        },
        isFetching: false
      };
    }
    case actionType.FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE: {
      return {
        ...state,
        countPerEmployee: {
          ...action.payload
        },
        isFetching: false
      };
    }
    case actionType.UPDATE_PACKED_BOXES: {
      return {
        ...state,
        data: {
          ...initialState.data,
          content: state.data.content.map(item => {
            if (item.id === action.payload.id) {
              return action.payload;
            }
            return item;
          })
        }
      };
    }
    case actionType.DELETE_PACKED_BOXES: {
      const newTotalElements = state.data.totalElements - 1;

      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(e => e.id !== action.payload),
          totalElements: newTotalElements,
          totalPages: Math.ceil(newTotalElements / state.data.size)
        }
      };
    }
    case actionType.FETCH_PACKED_BOXES_FAIL:
    case actionType.FETCH_PACKED_BOXES_COUNT_PER_EMPLOYEE_FAIL:
    case actionType.UPDATE_PACKED_BOXES_FAIL:
    case actionType.BATCH_UPDATE_PACKED_BOXES_FAIL:
    case actionType.DELETE_PACKED_BOXES_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    }
    case actionType.CLEAR_PACKED_BOXES_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
