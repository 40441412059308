import { actionType } from "../../constants/Areas";

const initialState = {
  data: {
    content: [],
    isFetching: false
  },
  list: {
    content: [],
    isFetching: false
  },
  lastUpdateDate: null,
  error: null
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.FETCH_AREAS_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };

    case actionType.FETCH_AREAS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false
        }
      };
    }
    case actionType.UPDATE_AREAS_WEATERSTATION: {
      return {
        ...state,
        list: {
          ...state.list,
          content: state.list.content.map(item => {
            return item.farm.id === action.payload.id
              ? {
                  ...item,
                  farm: action.payload
                }
              : item;
          })
        }
      };
    }
    case actionType.FETCH_AREAS_FAIL:
      return {
        ...state,
        error: action.payload,
        data: {
          ...state.data,
          isFetching: false
        }
      };
    case actionType.FETCH_AREA_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true
        }
      };
    case actionType.FETCH_AREA_LIST:
      return {
        ...state,
        list: {
          ...action.payload.data,
          isFetching: false
        },
        lastUpdateDate: action.payload.lastUpdateDate
      };
    case actionType.FETCH_AREA_LIST_FAIL:
      return {
        ...state,
        list: { ...state.list, isFetching: false },
        error: action.payload
      };
    case actionType.CHANGE_LAST_UPDATE_DATE:
      return {
        ...state,
        lastUpdateDate: action.payload
      };
    case actionType.CLEAR_AREA_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};
