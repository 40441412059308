import React, { useEffect } from "react";
import { Button, Form, Input } from "semantic-ui-react";
import { Formik, Field } from "formik";
import styles from "./Parametrs.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getParametrsList, updateQC } from "actions/QualityControl";
import { parametrsFiltersOptions } from "selectors/qualityControl";

const ParameterAdd = ({
  activeItem,
  setOpenParametrAdd,
  onChangeField,
  activeRounds
}) => {
  const dispatch = useDispatch();
  const parametrsOptions = useSelector(parametrsFiltersOptions);

  useEffect(() => {
    activeItem?.qcRounds.length <= 1 &&
      !parametrsOptions.options.length &&
      dispatch(getParametrsList());
  }, []);
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={{
        parameter: null,
        minorAmount: null,
        majorAmount: null
      }}
    >
      {({ values, dirty, isValid, errors, setFieldValue, setFieldError }) => {
        const newOptions = activeRounds
          ? parametrsOptions.options.filter(item =>
              activeRounds?.defectReports.every(i => item.key !== i.parameter)
            )
          : parametrsOptions.options;
        return (
          <>
            <div>
              <Form>
                <div className={styles.rowWrapper}>
                  <div className={styles.formFieldWrapper}>
                    <Form.Field required width={8} className={styles.formField}>
                      <label className={styles.label}>Parameter type</label>
                      <Form.Dropdown
                        required
                        selection
                        search
                        name="parameters"
                        options={newOptions || []}
                        onChange={(e, { value }) => {
                          setFieldValue("parameter", value);
                        }}
                      />
                    </Form.Field>
                    <Form.Field required width={4} className={styles.formField}>
                      <label className={styles.label}>Minor</label>
                      <Field
                        as={Input}
                        fluid
                        className={styles.totalField}
                        required
                        name="minor"
                        onChange={e => {
                          const { value } = e.target;
                          setFieldValue("minorAmount", value);
                        }}
                      />
                    </Form.Field>

                    <Form.Field required width={4} className={styles.formField}>
                      <label className={styles.label}>Major</label>
                      <Field
                        required
                        as={Input}
                        className={styles.totalField}
                        fluid
                        name="major"
                        onChange={e => {
                          const { value } = e.target;
                          setFieldValue("majorAmount", value);
                        }}
                      />
                    </Form.Field>
                  </div>
                  <div className={styles.btnWrapper}>
                    <Button
                      content="Cancel"
                      color="green"
                      basic
                      onClick={() => {
                        setOpenParametrAdd(false);
                      }}
                    />
                    <Button
                      content={"Save"}
                      color="green"
                      onClick={() => {
                        return (
                          setOpenParametrAdd(false),
                          onChangeField(
                            "qcRounds",
                            activeItem.qcRounds.map(item => {
                              return item.roundNumber ===
                                activeRounds?.roundNumber
                                ? {
                                    ...item,
                                    defectReports: [
                                      ...item?.defectReports,
                                      values
                                    ]
                                  }
                                : item;
                            })
                          )
                        );
                      }}
                      disabled={
                        !values.parameter ||
                        !values.minorAmount ||
                        !values.majorAmount
                      }
                    />
                  </div>
                </div>
              </Form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default ParameterAdd;
