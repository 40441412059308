import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Segment,
  Menu,
} from "semantic-ui-react";
import moment from "moment";
import { cloneDeep, get, map } from "lodash";
import { history } from "store";
import { scoutingShape } from "constants/Scouting/types";
import { formatDate } from "utils/dateUtils";
import ScoutingSidebarAreaElement from "./ScoutingSidebar/ScoutingSidebarAreaElement";
import styles from "./ScoutingSidebar.module.css";
import ScoutingSidebarMap from "./ScoutingSidebar/ScoutingSidebarMap";
import ScoutingSidebarIssueModal from "./ScoutingSidebar/ScoutingSidebarIssueModal";

const TaskFieldRow = ({ name, value, units = "", children = "", style }) => (
  <Grid.Row className={styles.taskField} style={style}>
    <Grid.Column width={6}>{name}</Grid.Column>
    <Grid.Column width={10}>
      <b>
        {!children && value ? value + units : "-"}
        {children}
      </b>
    </Grid.Column>
  </Grid.Row>
);

const TABS = {
  LOCATION: "Location",
  MAP: "Map",
};

const ScoutingSidebar = ({
  scouting: currentScouting,
  isPrinting,
  Can,
  onPrintClick,
  onClose,
  navigate,
}) => {
  const [currentTab, setCurrentTab] = useState(TABS.LOCATION);
  const [selectedIssueData, setIssueData] = useState();
  const recommendationsTasks = map(
    currentScouting?.recommendations,
    (i) => `RT-${i.id}`
  ).join(", ");
  const switchTab = (tab) => () => setCurrentTab(tab);

  const onIssueAttachmentClick = (area, issue, attachmentData) => {
    setIssueData({ area, issue, attachmentData });
  };
  const onAttachmentModalClose = () => {
    setIssueData(null);
  };

  if (!currentScouting) {
    return null;
  }

  const scoutingAreasToMap = cloneDeep(currentScouting.areas).sort((a1, a2) => {
    const a1Name = a1.area.parent
      ? `${a1.area.parent.name} - ${a1.area.name}`
      : a1.area.name;
    const a2Name = a2.area.parent
      ? `${a2.area.parent.name} - ${a2.area.name}`
      : a2.area.name;

    return a1Name
      .toLowerCase()
      .replace(/\s/g, "")
      .localeCompare(a2Name.toLowerCase().replace(/\s/g, ""), undefined, {
        numeric: true,
        sensitivity: "base",
      });
  });
  return (
    <Container className={styles.root}>
      <ScoutingSidebarIssueModal
        selectedIssueData={selectedIssueData}
        onClose={onAttachmentModalClose}
      />
      <Grid style={{ maxWidth: "100%" }} padded>
        <Grid.Row>
          <Grid.Column className={styles.header}>
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={9} style={{ paddingLeft: 0 }}>
                  <Header as={"h1"}>SC-{currentScouting.id}</Header>
                </Grid.Column>
                <Grid.Column width={7} className={styles.buttonContainer}>
                  <Button
                    className="button-text color-green"
                    size="large"
                    onClick={onPrintClick}
                    disabled={isPrinting}
                    floated="left"
                  >
                    <span>
                      {!isPrinting ? (
                        <Icon name="print" />
                      ) : (
                        <Icon name="circle notch loading icon" />
                      )}
                      Print
                    </span>
                  </Button>
                  <Can I={"update"} a={"scouting"}>
                    <Button
                      className="color-green transparent"
                      size="large"
                      onClick={() =>
                        navigate(`/scouting/edit/${currentScouting.id}`)
                      }
                      floated="left"
                    >
                      Edit
                    </Button>
                  </Can>
                  <Icon
                    name="close"
                    size="large"
                    color="grey"
                    onClick={onClose}
                    link
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Segment>
            <Grid padded>
              <Grid.Row>
                <Grid.Column>
                  <Header as={"h2"}>General information</Header>
                </Grid.Column>
              </Grid.Row>
              {recommendationsTasks && (
                <TaskFieldRow
                  name={"Linked to recommendation task"}
                  value={recommendationsTasks}
                />
              )}
              <TaskFieldRow name={"Task Name"} value={currentScouting.name} />
              <TaskFieldRow
                name={"Created on"}
                value={formatDate(currentScouting.setOn)}
              />
              <TaskFieldRow
                name={"Done on"}
                value={formatDate(currentScouting.doneOn)}
              />
              <TaskFieldRow
                name={"Farm"}
                value={get(currentScouting, "areas[0].area.farm.name")}
              />
              <TaskFieldRow
                name={"General comments"}
                value={currentScouting.comment}
              />
            </Grid>
          </Segment>
        </Grid.Row>

        <Grid.Row>
          <Container fluid>
            <Segment className={styles.tabs}>
              <Menu pointing secondary className={styles.tabsMenu}>
                <Menu.Item
                  name="Location"
                  active={currentTab === TABS.LOCATION}
                  onClick={switchTab(TABS.LOCATION)}
                />
                <Menu.Item
                  name="Map"
                  active={currentTab === TABS.MAP}
                  onClick={switchTab(TABS.MAP)}
                />
              </Menu>
            </Segment>
          </Container>
        </Grid.Row>
        {currentTab === TABS.LOCATION && (
          <>
            {scoutingAreasToMap.map((area) => {
              return (
                <ScoutingSidebarAreaElement
                  scoutingArea={area}
                  onIssueAttachmentClick={onIssueAttachmentClick}
                />
              );
            })}
          </>
        )}
        {currentTab === TABS.MAP && (
          <Grid.Row>
            <Grid.Column>
              <Segment className={styles.mapContainer}>
                <ScoutingSidebarMap
                  scoutingData={currentScouting}
                  navigate={navigate}
                  onIssueAttachmentClick={onIssueAttachmentClick}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
};

ScoutingSidebar.propTypes = {
  Can: PropTypes.any,
  scouting: scoutingShape.isRequired,
  onClose: PropTypes.func,
};

ScoutingSidebar.defaultProps = {
  scouting: {},
};

TaskFieldRow.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any,
  name: PropTypes.string.isRequired,
  units: PropTypes.string,
  value: PropTypes.any.isRequired,
};

TaskFieldRow.defaultProps = {
  units: "",
};

export default memo(ScoutingSidebar);
