import { actionType } from "../../constants/Farms";
import objectPath from "object-path";

import { actionType as actionTypeAreas } from "../../constants/Areas";
import { AreaVariety } from "../../models/area_variety.model";

export const initialState = {
  isFetching: false,
  error: null,
  summary: null,
  showArchived: false,
  search: "",
  attributes: {
    filters: {},
    showOnMap: false,
    search: "",
    activeBlock: null //{ parentId: '22', farmId: '1', id: '77' }
  },
  data: {
    content: []
  },

  areas: {
    isFetching: false,
    savingStructure: false,
    error: null,
    content: []
  },

  newFarm: {
    isFetching: false,
    error: null,
    id: null,
    name: null,
    constructed: "BLOCKS_AND_PATCHES",
    location: {
      name: ""
    },
    weatherStation: null,
    currentBlock: null,
    currentPatch: null,
    blocks: [],
    polygonsToAdd: null,
    showArchived: false
  },

  constructed: [
    { id: "BLOCKS_AND_PATCHES", label: "Blocks & Patches" },
    { id: "BLOCKS_ONLY", label: "Blocks Only" },
    { id: "PATCHES_ONLY", label: "Patches Only" }
  ],

  sprayTasksForBreakdown: {
    isFetching: false,
    data: {
      content: []
    }
  },

  sprayTasksForArea: []
};

const getTargetBlock = (stateBlocks, paylodBlock) => {
  let targetBlock = stateBlocks.find(block => {
    if (paylodBlock.parent && paylodBlock.parent.id) {
      return (
        block.id === paylodBlock.parent.id ||
        block._id === paylodBlock.parent.id
      );
    }

    return block.id === paylodBlock.id || block._id === paylodBlock.id;
  });

  if (paylodBlock.parent && paylodBlock.parent.id) {
    targetBlock = targetBlock.patches.find(
      patch => patch.id === paylodBlock.id || patch._id === paylodBlock.id
    );
  }
  return targetBlock;
};

const handleUpdateArea = (state, action) => {
  const blocksNew = JSON.parse(JSON.stringify(state.newFarm.blocks));
  const targetBlock = getTargetBlock(blocksNew, action.payload);

  targetBlock.attributes.varieties = (action.payload.varieties || []).map(
    variety => {
      return new AreaVariety(variety);
    }
  );

  return {
    ...state,
    newFarm: {
      ...state.newFarm,
      isFetching: false,
      blocks: blocksNew
    }
  };
};

const manageBlocks = (state, action) => {
  switch (action.type) {
    case actionTypeAreas.UPDATE_STRUCTURE_START:
      return {
        ...state,
        areas: {
          ...state.areas,
          savingStructure: action.payload,
          error: null
        }
      };
    case actionTypeAreas.UPDATE_STRUCTURE:
      return {
        ...state,
        areas: {
          ...state.areas,
          savingStructure: null
        }
      };
    case actionTypeAreas.UPDATE_STRUCTURE_FAIL:
      return {
        ...state,
        areas: {
          ...state.areas,
          savingStructure: null,
          error: action.payload
        }
      };
    case actionTypeAreas.GET_AREAS_START:
      return {
        ...state,
        areas: {
          ...state.areas,
          isFetching: true,
          error: null
        }
      };
    case actionTypeAreas.GET_AREAS:
      return {
        ...state,
        areas: {
          ...state.areas,
          ...action.payload,
          isFetching: false
        }
      };
    case actionTypeAreas.GET_AREAS_FAIL:
      return {
        ...state,
        areas: {
          ...state.areas,
          isFetching: false,
          error: action.payload
        }
      };
    case actionTypeAreas.ADD_AREA_START:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          isFetching: true,
          error: null
        }
      };
    case actionTypeAreas.ADD_AREA:
      const blocksNew = JSON.parse(JSON.stringify(state.newFarm.blocks));
      const targetBlock = blocksNew.find(block => {
        if (action.payload.parentId) {
          return block.id === action.payload.parentId;
        }

        return block.id === action.payload.blockId;
      });

      if (action.payload.parentId) {
        const targetPatch = targetBlock.patches.find(
          patch => patch.id === action.payload.blockId
        );

        targetPatch._id = action.payload.block.id;
      } else {
        targetBlock._id = action.payload.block.id;
      }
      if (targetBlock.attributes === undefined) {
        targetBlock.attributes = {};
      }
      targetBlock.attributes.varieties = (
        action.payload.block.varieties || []
      ).map(variety => {
        return new AreaVariety(variety);
      });

      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          isFetching: false,
          blocks: blocksNew
        }
      };
    case actionTypeAreas.ADD_AREA_FAIL:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          isFetching: false,
          error: action.payload
        }
      };
    case actionTypeAreas.UPDATE_AREA_START:
    case actionTypeAreas.ARCHIVE_AREA_START:
    case actionTypeAreas.DELETE_AREA_START:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          isFetching: true,
          error: null
        }
      };
    case actionTypeAreas.UPDATE_AREA:
      return handleUpdateArea(state, action);
    case actionTypeAreas.ARCHIVE_AREA:
      return {
        ...state,
        summary: state.summary.map(farm => {
          return {
            ...farm,
            areas: farm.areas.map(area => {
              if (area.id === action.payload.areaId) {
                return {
                  ...area,
                  archived: action.payload.archive
                };
              }
              return area;
            })
          };
        }),
        newFarm: {
          ...state.newFarm,
          isFetching: false
        }
      };
    case actionTypeAreas.DELETE_AREA:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          isFetching: false
        }
      };
    case actionTypeAreas.UPDATE_AREA_FAIL:
    case actionTypeAreas.ARCHIVE_AREA_FAIL:
    case actionTypeAreas.DELETE_AREA_FAIL:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          isFetching: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
};

const manageFarms = (state, action) => {
  switch (action.type) {
    case actionType.SET_ACTIVE_BLOCK:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          activeBlock: action.payload
        }
      };
    case actionType.DELETE_FARM_BLOCK:
      const contentToDelete = JSON.parse(JSON.stringify(state.data.content));

      const targetFarmToDel = contentToDelete.find(
        farm => farm.id === action.payload.farmId
      );

      if (action.payload.parentId) {
        const targetBlock = targetFarmToDel.blocks.find(
          block => block.id === action.payload.parentId
        );
        const targetPatchId = targetBlock.patches.findIndex(
          block => block.id === action.payload.id
        );
        targetBlock.patches.splice(targetPatchId, 1);
      } else {
        const targetBlockIndex = targetFarmToDel.blocks.findIndex(
          block => block.id === action.payload.id
        );
        targetFarmToDel.blocks.splice(targetBlockIndex, 1);
      }

      //        .patches.find(patch => patch.id === attributes.activeBlock.id);
      return {
        ...state,
        data: { ...state.data, content: contentToDelete }
      };
    case actionType.DELETE_SUMMARY_FARM_AREA:
      const summaryFarms = JSON.parse(JSON.stringify(state.summary));

      const targetSummaryFarm = summaryFarms.find(
        farm => farm.farm.id === action.payload.farmId
      );

      const targetAreaIndex = targetSummaryFarm.areas.findIndex(
        area => area.id === action.payload.id
      );
      targetSummaryFarm.areas.splice(targetAreaIndex, 1);

      return {
        ...state,
        summary: summaryFarms
      };
    case actionType.TOGGLE_FARM_LIST_SHOW_ARCHIVED:
      return {
        ...state,
        showArchived: action.payload
      };
    case actionType.SET_FARMS_SEARCH:
      return {
        ...state,
        search: action.payload
      };
    case actionType.TOGGLE_OPEN_FARM_TABLE:
      const targetFarm = state.summary.findIndex(
        farm => farm.farm.id === action.payload.farmId
      );
      state.summary[targetFarm].open = action.payload.open;
      state.summary.splice(targetFarm, 1, {
        ...state.summary[targetFarm]
      });
      return {
        ...state,
        summary: [...state.summary]
      };
    case actionType.SET_FARM_FIELD:
      const targetFarmIndex = state.data.content.findIndex(
        farm => farm.id === action.payload.farmId
      );
      objectPath.set(
        state.data.content[targetFarmIndex],
        action.payload.fieldName,
        action.payload.fieldValue
      );

      return {
        ...state,
        data: JSON.parse(JSON.stringify(state.data))
      };
    case actionType.SET_SUMMARY_FIELD:
      const targetSummaryFarmIndex = state.summary.findIndex(
        farm => farm.farm.id === action.payload.farmId
      );
      objectPath.set(
        state.summary[targetSummaryFarmIndex],
        action.payload.fieldName,
        action.payload.fieldValue
      );

      return {
        ...state,
        summary: [...state.summary]
      };
    case actionType.GET_FARMS_LIST_START:
    case actionType.GET_FARMS_SUMMARY_START:
    case actionType.GET_FARM_SUMMARY_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case actionType.GET_FARMS_LIST:
      const content = action.payload.content.map(farm => {
        if (farm.show === undefined) {
          farm.show = true;
        }
        return farm;
      });
      return {
        ...state,
        isFetching: false,
        data: { ...action.payload, content }
      };
    case actionType.GET_FARMS_SUMMARY:
      const newData = action.payload.reduce((prev, farm) => {
        const item =
          !!state.summary &&
          state.summary.find(item => item.farm.id === farm.farm.id);

        return [
          ...prev,
          {
            ...farm,
            ...(item && item.open && { open: item.open })
          }
        ];
      }, []);
      return {
        ...state,
        isFetching: false,
        summary: newData
      };

    case actionType.GET_FARM_SUMMARY:
      const farmSummary = action.payload;
      return {
        ...state,
        isFetching: false,
        summary: [
          ...(state.summary || []).filter(
            farm => farmSummary.farm.id !== farm.farm.id
          ),
          farmSummary
        ]
      };

    case actionType.UPDATE_FARM:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          ...action.payload
        }
      };

    case actionType.GET_FARMS_LIST_FAIL:
    case actionType.GET_FARMS_SUMMARY_FAIL:
    case actionType.GET_FARM_SUMMARY_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case actionType.ARCHIVE_FARM_START:
    case actionType.DELETE_FARM_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case actionType.ARCHIVE_FARM:
      return {
        ...state,
        isFetching: false,
        summary: [
          ...state.summary.map(farm => {
            if (farm.farm.id === action.payload.farmId) {
              return {
                ...farm,
                farm: {
                  ...farm.farm,
                  archived: action.payload.archive
                }
              };
            }

            return farm;
          })
        ]
      };
    case actionType.DELETE_FARM:
      return {
        ...state,
        isFetching: false,
        summary: [
          ...state.summary.filter(farm => farm.farm.id !== action.payload)
        ]
      };
    case actionType.ARCHIVE_FARM_FAIL:
    case actionType.DELETE_FARM_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case actionType.GET_FARM_START:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case actionType.GET_FARM:
      if (action.payload.show === undefined) {
        action.payload.show = true;
      }
      const farmContent = JSON.parse(JSON.stringify(state.data.content));
      const farmContentIndex = farmContent.findIndex(
        farm => farm.id === action.payload.id
      );

      if (farmContentIndex >= 0) {
        farmContent.splice(farmContentIndex, 1, action.payload);
      } else {
        farmContent.push(action.payload);
      }
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          content: farmContent
        }
      };
    case actionType.GET_FARM_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case actionType.GET_FARM_AREAS_START:
      const targetFarmBlocksIndex = state.data.content.findIndex(
        farm => farm.id === action.payload.farmId
      );

      const targetFarmBlocks = JSON.parse(
        JSON.stringify(state.data.content[targetFarmBlocksIndex])
      );
      targetFarmBlocks.isFetching = true;
      state.data.content.splice(targetFarmBlocksIndex, 1, targetFarmBlocks);
      return {
        ...state,
        data: { ...state.data },
        error: null
      };
    case actionType.GET_FARM_AREAS:
      const targetFarmBlocksIndexGot = state.data.content.findIndex(
        farm => farm.id === action.payload.farmId
      );
      const targetFarmBlocksGot = JSON.parse(
        JSON.stringify(state.data.content[targetFarmBlocksIndexGot])
      );
      targetFarmBlocksGot.isFetching = false;
      targetFarmBlocksGot.blocks = action.payload.data.content;
      state.data.content.splice(
        targetFarmBlocksIndexGot,
        1,
        targetFarmBlocksGot
      );

      return {
        ...state,
        data: { ...state.data }
      };
    case actionType.GET_FARM_AREAS_FAIL:
      const targetFarmBlocksIndexError = state.data.content.findIndex(
        farm => farm.id === action.payload.farmId
      );
      const targetFarmBlocksError = JSON.parse(
        JSON.stringify(state.data.content[targetFarmBlocksIndexError])
      );
      targetFarmBlocksError.isFetching = false;
      targetFarmBlocksError.error = action.payload.error;
      state.data.content.splice(
        targetFarmBlocksIndexError,
        1,
        targetFarmBlocksError
      );
      return {
        ...state,
        data: { ...state.data }
      };
    case actionType.FARM_CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

const addNewFarm = (state, action) => {
  switch (action.type) {
    case actionType.NEW_FARM_CLEAR_ERROR:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          error: null
        }
      };

    case actionType.ADD_NEW_FARM_START:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          isFetching: true,
          error: null
        }
      };
    case actionType.ADD_NEW_FARM:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          id: action.payload.id,
          isFetching: false
        }
      };
    case actionType.ADD_NEW_FARM_FAIL:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          isFetching: false,
          error: action.payload
        }
      };

    case actionType.SET_CURRENT_BLOCK:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          currentBlock: action.payload
        }
      };

    case actionType.SET_CURRENT_PATCH:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          currentPatch: action.payload
        }
      };

    case actionType.SET_NEW_FARM_FIELD:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          [action.payload.fieldName]: action.payload.fieldValue
        }
      };

    case actionType.SET_NEW_FARM:
      return {
        ...state,
        newFarm: action.payload.newFarm
      };

    case actionType.SET_NEW_POLYGON:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          polygonsToAdd: state.newFarm.polygonsToAdd
            ? [...state.newFarm.polygonsToAdd, action.payload]
            : [action.payload]
        }
      };
    case actionType.CLEAR_NEW_POLYGON:
      const polygonsToAdd =
        action.payload && state.newFarm.polygonsToAdd
          ? state.newFarm.polygonsToAdd.filter(
              polygon => action.payload.indexOf(polygon) <= 0
            )
          : null;
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          polygonsToAdd
        }
      };
    case actionType.INIT_NEW_BLOCK:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          currentBlock: action.payload.id,
          blocks: [...state.newFarm.blocks, action.payload],
          polygonsToAdd: null
        }
      };
    case actionType.SET_NEW_BLOCK_FIELD:
      const newBlocks = JSON.parse(JSON.stringify(state.newFarm.blocks));
      const targetBlockIndex = newBlocks.findIndex(
        block => block.id === action.payload.blockId
      );
      objectPath.set(
        newBlocks[targetBlockIndex],
        action.payload.fieldName,
        action.payload.fieldValue
      );

      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          blocks: newBlocks
        }
      };

    case actionType.SET_NEW_PATCH_FIELD:
      const newPatchBlock = JSON.parse(JSON.stringify(state.newFarm.blocks));
      const targetBlockPatchIndex = newPatchBlock.findIndex(
        block => block.id === action.payload.blockId
      );
      const targetBlockPatch = newPatchBlock[targetBlockPatchIndex];
      const targetPatchIndex = targetBlockPatch.patches.findIndex(
        patch => patch.id === action.payload.patchId
      );
      objectPath.set(
        targetBlockPatch.patches[targetPatchIndex],
        action.payload.fieldName,
        action.payload.fieldValue
      );

      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          blocks: newPatchBlock
        }
      };

    case actionType.SET_NEW_BLOCK_FIELDS:
      const newBlocks1 = JSON.parse(JSON.stringify(state.newFarm.blocks));
      const block = newBlocks1.find(
        block => block.id === action.payload.blockId
      );
      action.payload.fields.forEach(field => {
        objectPath.set(block, field.fieldName, field.fieldValue);
      });
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          blocks: newBlocks1
        }
      };

    case actionType.REMOVE_ALL_BLOCKS:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          currentBlock: null,
          currentPatch: null,
          polygonsToAdd: null,
          blocks: []
        }
      };
    case actionType.REMOVE_BLOCK_BY_ID:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          currentBlock:
            state.newFarm.currentBlock === action.payload
              ? null
              : state.newFarm.currentBlock,
          blocks: state.newFarm.blocks.filter(
            block => block.id !== action.payload
          )
        }
      };
    case actionType.ARCHIVE_BLOCK_BY_ID:
      const blocks = state.newFarm.blocks.map(block => {
        if (block.id === action.payload) {
          return {
            ...block,
            archived: true
          };
        }
        return block;
      });
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          currentBlock:
            state.newFarm.currentBlock === action.payload
              ? null
              : state.newFarm.currentBlock,
          blocks: blocks
        }
      };
    case actionType.REMOVE_PATCHES_FROM_BLOCK:
      const targetBlockId = state.newFarm.blocks.findIndex(
        block => block.id === action.payload
      );
      state.newFarm.blocks[targetBlockId].patches = [];
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          polygonsToAdd: null,
          blocks: [...state.newFarm.blocks]
        }
      };
    case actionType.ADD_PATCHES_TO_BLOCK:
      const targetPatchBlockId = state.newFarm.blocks.findIndex(
        block => block.id === action.payload.blockId
      );
      state.newFarm.blocks[targetPatchBlockId].patches = [
        ...state.newFarm.blocks[targetPatchBlockId].patches,
        ...action.payload.patches
      ];
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          polygonsToAdd: null,
          blocks: [...state.newFarm.blocks]
        }
      };
    case actionType.TOGGLE_SHOW_ARCHIVED:
      return {
        ...state,
        newFarm: {
          ...state.newFarm,
          showArchived: action.payload
        }
      };

    default:
      return state;
  }
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.SET_CURRENT_BLOCK:
    case actionType.SET_CURRENT_PATCH:
    case actionType.SET_NEW_FARM_FIELD:
    case actionType.SET_NEW_POLYGON:
    case actionType.SET_NEW_FARM:
    case actionType.CLEAR_NEW_POLYGON:
    case actionType.INIT_NEW_BLOCK:
    case actionType.SET_NEW_BLOCK_FIELD:
    case actionType.SET_NEW_BLOCK_FIELDS:
    case actionType.SET_NEW_PATCH_FIELD:
    case actionType.REMOVE_ALL_BLOCKS:
    case actionType.REMOVE_BLOCK_BY_ID:
    case actionType.ARCHIVE_BLOCK_BY_ID:
    case actionType.REMOVE_PATCHES_FROM_BLOCK:
    case actionType.ADD_PATCHES_TO_BLOCK:
    case actionType.ADD_NEW_FARM_START:
    case actionType.ADD_NEW_FARM:
    case actionType.ADD_NEW_FARM_FAIL:
    case actionType.NEW_FARM_CLEAR_ERROR:
    case actionType.TOGGLE_SHOW_ARCHIVED:
      return addNewFarm(state, action);

    case actionType.TOGGLE_FARM_LIST_SHOW_ARCHIVED:
    case actionType.SET_FARMS_SEARCH:
    case actionType.TOGGLE_OPEN_FARM_TABLE:
    case actionType.SET_FARM_FIELD:
    case actionType.SET_SUMMARY_FIELD:
    case actionType.SET_ACTIVE_BLOCK:
    case actionType.DELETE_FARM_BLOCK:
    case actionType.DELETE_SUMMARY_FARM_AREA:
    case actionType.GET_FARMS_LIST_START:
    case actionType.GET_FARMS_LIST:
    case actionType.GET_FARMS_LIST_FAIL:
    case actionType.GET_FARMS_SUMMARY_START:
    case actionType.GET_FARMS_SUMMARY:
    case actionType.GET_FARMS_SUMMARY_FAIL:
    case actionType.GET_FARM_SUMMARY_START:
    case actionType.GET_FARM_SUMMARY:
    case actionType.GET_FARM_SUMMARY_FAIL:
    case actionType.GET_FARM_AREAS_START:
    case actionType.GET_FARM_AREAS:
    case actionType.GET_FARM_AREAS_FAIL:
    case actionType.GET_FARM_START:
    case actionType.GET_FARM:
    case actionType.GET_FARM_FAIL:
    case actionType.ARCHIVE_FARM_START:
    case actionType.ARCHIVE_FARM:
    case actionType.ARCHIVE_FARM_FAIL:
    case actionType.DELETE_FARM_START:
    case actionType.DELETE_FARM:
    case actionType.DELETE_FARM_FAIL:
    case actionType.FARM_CLEAR_ERROR:
      return manageFarms(state, action);

    case actionTypeAreas.ADD_AREA_START:
    case actionTypeAreas.ADD_AREA:
    case actionTypeAreas.ADD_AREA_FAIL:
    case actionTypeAreas.UPDATE_AREA:
    case actionTypeAreas.UPDATE_AREA_FAIL:
    case actionTypeAreas.DELETE_AREA_START:
    case actionTypeAreas.DELETE_AREA:
    case actionTypeAreas.DELETE_AREA_FAIL:
    case actionTypeAreas.ARCHIVE_AREA:
    case actionTypeAreas.ARCHIVE_AREA_FAIL:
    case actionTypeAreas.GET_AREAS_START:
    case actionTypeAreas.GET_AREAS:
    case actionTypeAreas.GET_AREAS_FAIL:
    case actionTypeAreas.UPDATE_STRUCTURE_START:
    case actionTypeAreas.UPDATE_STRUCTURE:
    case actionTypeAreas.UPDATE_STRUCTURE_FAIL:
      return manageBlocks(state, action);

    case actionType.FETCH_CHEMICALS_BREAKDOWN_START: {
      return {
        ...state,
        sprayTasksForBreakdown: {
          isFetching: true,
          data: {
            content: []
          }
        }
      };
    }

    case actionType.FETCH_CHEMICALS_BREAKDOWN: {
      return {
        ...state,
        sprayTasksForBreakdown: {
          isFetching: false,
          data: {
            content: action.payload
          }
        }
      };
    }

    case actionType.FETCH_CHEMICALS_BREAKDOWN_FAIL: {
      return {
        ...state,
        error: action.payload,
        sprayTasksForBreakdown: {
          isFetching: false,
          data: {
            content: []
          }
        }
      };
    }

    case actionType.FETCH_SPRAY_TASKS_FOR_AREA_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case actionType.FETCH_SPRAY_TASKS_FOR_AREA_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        sprayTasksForArea: action.payload
      };
    }

    case actionType.FETCH_SPRAY_TASKS_FOR_AREA_FAIL: {
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    }

    default:
      return state;
  }
};
