import React from "react";
import PropTypes from "prop-types";
import { Icon, Message } from "semantic-ui-react";

import Loader from "../Loader";
import RouteGoogleMaps from "../RouteGoogleMaps";

export const RouteViewer = ({ route, isFetching, navigate }) => {
  return (
    <>
      {isFetching ? (
        <Loader />
      ) : route && route.length > 0 ? (
        <RouteGoogleMaps navigate={navigate} paths={route} />
      ) : (
        <Message color="yellow">
          <Icon name="warning" /> Route not available
        </Message>
      )}
    </>
  );
};

RouteViewer.propTypes = {
  route: PropTypes.array,
  isFetching: PropTypes.bool,
};

RouteViewer.defaultProps = {};
