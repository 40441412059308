import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PropTypes from "prop-types";

import { Mixpanel } from "../../tools/mixpanel/Mixpanel";
import { MixpanelEvents } from "../../tools/mixpanel/MixpanelEvents";
const RoutesPageHolder = (props) => {
  const { routesPages, route } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    Mixpanel.track(MixpanelEvents.SCREEN_APPEAR, route);
  }, [route]);

  const Component = routesPages[route.component];

  return (
    <Component
      {...props}
      match={{ params }}
      location={location}
      navigate={navigate}
    />
  );
};

RoutesPageHolder.propTypes = {
  routesPages: PropTypes.object,
  route: PropTypes.object,
};

export default RoutesPageHolder;
