import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Button, Container, Icon, Sidebar } from "semantic-ui-react";
import { get } from "lodash";
import styles from "./SlashingTask.module.css";
import HeaderMain from "components/HeaderMain/HeaderMain";
import { useReactToPrint } from "react-to-print";
import { Chip } from "./components/StatusChip";
import {
  progressLine,
  slashingTabsSection,
  swtichButton
} from "../components/helper";
import AreaTable from "./Tables/AreaTable";
import TaskInformation from "./Tables/TaskInformation";
import SideBar from "./Sidebar/SideBar";
import {
  fetchGeneralTaskById,
  fetchGeneralTasksWorkingTimes
} from "actions/Tasks";
import Loader from "components/Loader";
import CompletionModal from "./CompletionModal/CompletionModal";
import TaskPrintout from "../TaskPrint/TaskPrint";
import EditModal from "./EditModal/EditModal";

const SlashingTask = ({
  task,
  taskWorkingTimes,
  isFetching,
  machineryList,
  areasList,
  route,
  employeeList,
  isWorkinTimeFetching,
  archivedList,
  navigate
}) => {
  const componentRef = useRef();
  const { taskId } = useParams();
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const [activeArea, setActiveArea] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setactiveTab] = useState("Open");
  const [areaType, setAreaType] = useState("ALL");
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [activeRow, setActiveRow] = useState({});

  useEffect(() => {
    if (taskId) {
      dispatch(fetchGeneralTaskById(taskId));
    }
  }, [taskId]);

  useEffect(() => {
    if (task) {
      dispatch(
        fetchGeneralTasksWorkingTimes({
          id: task.id,
          filters: { taskId: task.id }
        })
      );
    }
  }, [task]);

  if (!task || isFetching) {
    return <Loader />;
  }

  const { completedTabAreas = [], toDoTabAreas = [] } = Object.groupBy(
    task.areas.map(item => ({
      ...item,
      area:
        item.area.type === "BLOCK"
          ? { ...item.area, children: [] }
          : { ...item.area.parent, children: [item.area] }
    })),
    ({ completed }) => (completed ? "completedTabAreas" : "toDoTabAreas")
  );

  const handleRowClick = item => {
    setActiveRow(item);
    setSidebarVisible(true);
  };
  const onSidebarHidden = () => {
    return setSidebarVisible(false);
  };

  const handleItemClick = (e, { name }) => {
    setactiveTab(name);
    setSelectedItems([]);
  };
  const handleSelectChange = id => {
    Array.isArray(id)
      ? id.filter(i => selectedItemsIds.includes(i)).length
        ? setSelectedItems(selectedItemsIds.filter(item => !id.includes(item)))
        : setSelectedItems([...selectedItemsIds, ...id])
      : selectedItemsIds.includes(id)
      ? setSelectedItems(selectedItemsIds.filter(item => item !== id))
      : setSelectedItems([...selectedItemsIds, id]);
  };
  const handleSelectAllItem = newData => {
    const areaIds = newData.map(({ area }) =>
      areaType !== "ALL"
        ? area.id
        : area?.children?.length
        ? area.children[0].id
        : area.id
    );
    !selectedItemsIds.length ? setSelectedItems(areaIds) : setSelectedItems([]);
  };

  const filtredArea = areasList.filter(item =>
    activeArea
      ? Array.isArray(activeArea)
        ? activeArea?.includes(item.id)
        : item.id === activeArea
      : selectedItemsIds.includes(item.id)
  );

  const activeWorkingTime = taskWorkingTimes.filter(item =>
    item.areas.some(area =>
      Array.isArray(activeArea)
        ? activeArea.includes(area.areaId)
        : area.areaId === activeArea
    )
  );

  const commonProps = {
    isFetching,
    task,
    handleRowClick,
    areaType,
    activeTab,
    selectedItemsIds,
    handleSelectAllItem,
    handleSelectChange,
    employeeList,
    archivedList,
    assignedTo: task?.assignedTo,
    setSelectedItems,
    setOpenEditModal,
    setActiveArea,
    activeArea,
    activeWorkingTime
  };

  let areas = [];
  let columns = 0;

  if (activeTab === "Open") {
    areas = toDoTabAreas;
    columns = areaType === "ALL" ? 5 : 6;
  } else if (activeTab === "Completed") {
    areas = completedTabAreas;
    columns = areaType === "ALL" ? 8 : 9;
  }

  return (
    <>
      <div style={{ display: "none" }}>
        <TaskPrintout ref={componentRef} task={task} areasList={areasList} />
      </div>
      <CompletionModal
        setSelectedItems={setSelectedItems}
        task={task}
        existingWorkingTimes={taskWorkingTimes}
        filtredArea={filtredArea}
        open={openModal}
        machineryList={machineryList}
        employeeList={employeeList}
        selectedAreaIds={selectedItemsIds}
        onClose={() => (setOpenModal(false), setActiveArea(null))}
      />
      <EditModal
        activeWorkingTime={activeWorkingTime}
        activeArea={activeArea}
        filtredArea={filtredArea}
        tableType={areaType}
        task={task}
        machineryList={machineryList}
        employeeList={employeeList}
        archivedList={archivedList}
        open={openEditModal}
        onClose={() => (setOpenEditModal(false), setActiveArea(null))}
      />
      <Sidebar.Pushable className={styles.root}>
        {React.isValidElement(<SideBar />) && (
          <Sidebar
            activeArea={activeArea}
            activeWorkingTime={activeWorkingTime}
            setSelectedItems={setSelectedItems}
            setOpenModal={setOpenModal}
            setActiveArea={setActiveArea}
            animation="overlay"
            direction="right"
            visible={sidebarVisible}
            onHide={e => {
              if (
                get(e, "target.closest") &&
                (e.target.closest(".modals") || e.target.closest(".dimmer"))
              ) {
                return;
              }
              setSidebarVisible(false);
            }}
            onHidden={onSidebarHidden}
            className={styles.sidebarWrapper}
          >
            <div className={styles.sidebarHolder}>
              {React.cloneElement(
                <SideBar
                  activeArea={activeArea}
                  activeWorkingTime={activeWorkingTime}
                  setSelectedItems={setSelectedItems}
                  setOpenModal={setOpenEditModal}
                  setActiveArea={setActiveArea}
                  task={task}
                  areaType={areaType}
                  activeTab={activeTab}
                  activeRow={activeRow}
                  employeeList={employeeList}
                  machineryList={machineryList}
                  onClose={onSidebarHidden}
                />,
                {
                  onClose: () => setSidebarVisible(false)
                }
              )}
            </div>
          </Sidebar>
        )}
        <Container id="el" fluid className={styles.root}>
          <HeaderMain
            route={route}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            online={true}
          />
          <div className={styles.contentWrapper}>
            <div className={styles.headerContainer}>
              <div className={styles.header}>
                <div
                  onClick={() => navigate("/tasks")}
                  className={styles.backButtonContainer}
                >
                  <Icon className={styles.backButton} name="chevron left" />
                </div>
                <div className={styles.taskId}> GT-{task?.id}</div>
                <div className={styles.taskName}>{task?.name}</div>
                <div className={styles.statusWrapper}>
                  <Chip task={task} />
                </div>
              </div>
              <div>
                <Button
                  primary
                  className={styles.activeBtn}
                  onClick={handlePrint}
                >
                  <Icon name="print" />
                  Print
                </Button>
                <Button
                  primary
                  className={styles.activeBtn}
                  onClick={() => navigate("/tasks/add")}
                >
                  <Icon name="edit" />
                  Edit
                </Button>
              </div>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.tabSection}>
                <div>
                  {slashingTabsSection(
                    activeTab,
                    task.paymentMethod,
                    task.priority,
                    handleItemClick
                  )}
                </div>
                {activeTab !== "Task_information" && (
                  <div className={styles.infoSection}>
                    <div className={styles.progressLineWrapper}>
                      <div>{progressLine(task)}</div>
                      {activeTab === "Open" && (
                        <div>
                          <Button
                            type="button"
                            className={
                              selectedItemsIds.length
                                ? styles.activeCompletedBtn
                                : styles.completedBtn
                            }
                            content="Complete areas"
                            disabled={!selectedItemsIds.length}
                            onClick={() => setOpenModal(true)}
                          />
                        </div>
                      )}
                    </div>
                    <div className={styles.switchButton}>
                      {swtichButton(areaType, setAreaType, setSelectedItems)}
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.tableSection}>
                <>
                  {activeTab === "Open" || activeTab === "Completed" ? (
                    <AreaTable
                      areas={areas}
                      taskWorkingTimes={taskWorkingTimes}
                      columns={columns}
                      {...commonProps}
                    />
                  ) : (
                    <TaskInformation
                      task={task}
                      taskWorkingTimes={taskWorkingTimes}
                      machineryList={machineryList}
                      employeeList={employeeList}
                    />
                  )}
                </>
              </div>
            </div>
          </div>
        </Container>
      </Sidebar.Pushable>
    </>
  );
};
SlashingTask.propTypes = {};
export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");
  return {
    isArchivedView,
    task: state.newTasks.taskById,
    taskWorkingTimes: state.newTasks.taskByIdWorkingTimes,
    isFetching: state.newTasks.isFetching,
    isWorkinTimeFetching: state.newTasks.isWorkinTimeFetching,
    areasList: state.areas.list.content,
    employeeList: state.employee.list.content,
    archivedList: state.employee.archivedList.content,
    machineryList: state.machinery.data.content
  };
})(SlashingTask);
