import React, { useEffect, useState } from "react";
import { Icon, Form, Segment, Label, Input } from "semantic-ui-react";
import { useSelector, connect } from "react-redux";
import { Field } from "formik";
import styles from "../ClockOut.module.css";
import moment from "moment";
import Datetime from "react-datetime";
import {
  calculateTimeDifference,
  employeeViewer
} from "routes/Tasks/components/helper";
import AreasList from "routes/WorkingTime/components/AreasList";
import { generalTasksAreas } from "selectors/generalTasks";

const EmployeeCard = ({
  activeItem,
  setSubRowsIndex,
  index,
  subRowsIndex,
  setFieldValue,
  employees,
  areasList,
  setDiffHours
}) => {
  const [open, setOpen] = useState(false);
  const activeEmployee = employees.filter(
    item => item.id === activeItem.employeeId
  );

  const tasksAreas = useSelector(generalTasksAreas);
  const areasOptions = activeItem.generalTaskId
    ? tasksAreas[activeItem.generalTaskId]
    : [];

  const detailsHoursDifference =
    activeItem?.startAt &&
    activeItem?.endAt &&
    calculateTimeDifference(
      moment(activeItem?.startAt).format("HH:mm"),
      moment(activeItem?.endAt).format("HH:mm"),
      activeItem?.lunchInterval
    );

  const start = moment(activeItem.startAt);
  let end = moment(activeItem.endAt);

  if (end.isBefore(start)) {
    end.add(1, "day");
  }

  const diffHours = end.diff(start, "hours", true);

  useEffect(() => {
    (index === subRowsIndex || subRowsIndex == "totalRow") && setOpen(true);
  });
  useEffect(() => {
    setDiffHours(diffHours);
  }, [setFieldValue, activeItem]);
  return (
    <Segment className={styles.card}>
      <div className={styles.employeeRow}>
        <div className={styles.checkIconWrapper}>
          <Icon
            className={styles.rowChevronIcon}
            onClick={() => (setSubRowsIndex(false), setOpen(!open))}
            name={!!open ? `chevron up` : `chevron down`}
          />
        </div>
        <div className={styles.avatarWrapper}>
          {employeeViewer(activeEmployee, employees)}
          {`${activeEmployee[0]?.firstName}  ${activeEmployee[0]?.lastName}`}
        </div>
      </div>
      {(open || index === subRowsIndex || subRowsIndex == "totalRow") && (
        <Form className={styles.form}>
          <Form.Group required widths={16}>
            <Form.Field width={16} required>
              <label className={styles.formLabel}>Areas</label>
              <AreasList
                text={
                  !!activeItem.areas.length &&
                  `${activeItem.areas.length} areas`
                }
                onChange={(_, data) => {
                  return setFieldValue(`children[${index}]areas`, [
                    ...activeItem.areas,
                    { areaId: data }
                  ]);
                }}
                options={areasOptions.filter(
                  i => !activeItem.areas.map(i => i.areaId).includes(i.value)
                )}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            {!!activeItem.areas.length && (
              <Form.Field>
                <div className={styles.areasRow}>
                  <span className={styles.areasRowHead}>Areas:</span>
                  {activeItem.areas?.map(item => {
                    return areasList.map(({ id, name, parent }) => {
                      return (
                        id === (item?.areaId || item) && (
                          <Label className={styles.addedItem}>
                            <span className={styles.addedItemValue}>
                              {" "}
                              {parent ? `${parent.name}/ ${name}` : name}
                            </span>
                            <Icon
                              className={styles.closeIcon}
                              name="close"
                              onClick={() => {
                                const filteredValues = activeItem.areas?.filter(
                                  id => item !== id
                                );
                                setFieldValue(
                                  `children[${index}]areas`,
                                  filteredValues
                                );
                              }}
                            />
                          </Label>
                        )
                      );
                    });
                  })}
                </div>
              </Form.Field>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Field required width={8}>
              <label className={styles.formLabel}>Clock in</label>
              <div className={styles.calendarWrapper}>
                <Datetime
                  value={activeItem.startAt ? moment(activeItem.startAt) : null}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  inputProps={{
                    placeholder: "dd/mm/yyyy hh:mm",
                    readOnly: false
                  }}
                  onChange={selectedDate => {
                    const formattedDate = selectedDate
                      ? moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss")
                      : null;
                    setFieldValue(`children[${index}]startAt`, formattedDate);
                  }}
                  className={styles.expirCalendar}
                />
                <div className={styles.calendarIcon}>
                  <Icon name="calendar outline" />
                </div>
              </div>
            </Form.Field>
            <Form.Field width={8} className={styles.formField}>
              <label>Working time</label>
              <Field
                as={Input}
                disabled={true}
                icon={<Icon name="clock outline" />}
                className={styles.disabledField}
                name="row"
                value={
                  detailsHoursDifference
                    ? `${detailsHoursDifference?.hoursDifference}h ${detailsHoursDifference?.minutesDifference}mm`
                    : "0h 00mm"
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field required width={8}>
              <label className={styles.formLabel}>Clock out</label>
              <div className={styles.calendarWrapper}>
                <Datetime
                  value={activeItem.endAt ? moment(activeItem.endAt) : null}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  inputProps={{
                    placeholder: "dd/mm/yyyy hh:mm",
                    readOnly: false
                  }}
                  onChange={selectedDate => {
                    const formattedDate = selectedDate
                      ? moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss")
                      : null;
                    setFieldValue(`children[${index}]endAt`, formattedDate);
                  }}
                  className={styles.expirCalendar}
                />
                <div className={styles.calendarIcon}>
                  <Icon name="calendar outline" />
                </div>
              </div>
            </Form.Field>
            <Form.Field width={8} className={styles.formField}>
              <label>Piece rate</label>
              <Field
                as={Input}
                name="pieceRate"
                value={activeItem.pieceRate}
                onChange={(_, { value }) =>
                  setFieldValue(`children[${index}]pieceRate`, value)
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={16}>
            <Form.Field required width={8}>
              <label className={styles.formLabel}>Lunch interval</label>
              <Field
                as={Input}
                icon={<Icon name="clock outline" />}
                className={styles.disabledField}
                name="lunchInterval"
                value={activeItem?.lunchInterval}
                onChange={(_, { value }) => {
                  setFieldValue(`children[${index}]lunchInterval`, value);
                }}
              />
            </Form.Field>
            <Form.Field width={4} type="number" className={styles.formField}>
              <label>No. of pieces</label>
              <Field
                as={Input}
                name="piecesPerEmployee"
                value={activeItem.piecesPerEmployee}
                onChange={(_, { value }) =>
                  setFieldValue(`children[${index}]piecesPerEmployee`, value)
                }
              />
            </Form.Field>
            <Form.Field width={4} className={styles.formField}>
              <label>Total ($)</label>
              <Field
                as={Input}
                disabled={true}
                className={styles.disabledField}
                name="number"
                value={
                  activeItem?.pieceRate && activeItem?.piecesPerEmployee
                    ? !isNaN(
                        +activeItem?.pieceRate * +activeItem?.piecesPerEmployee
                      )
                      ? +activeItem?.pieceRate * +activeItem?.piecesPerEmployee
                      : "0.00"
                    : "0.00"
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={16}>
            <Field
              value={activeItem.comment}
              placeholder=""
              as={Form.TextArea}
              width={16}
              name="notes"
              label="Comments"
              onChange={(_, { value }) =>
                setFieldValue(`children[${index}]comment`, value)
              }
            />
          </Form.Group>
          {diffHours > 12 && (
            <Form.Group>
              <div className={styles.errorMessage}>
                Clock out time cannot be more than 12 hours after Clock in time.
              </div>
            </Form.Group>
          )}
          {diffHours <= 0 && (
            <Form.Group>
              <div className={styles.errorMessage}>
                Clock out cannot be less Clock in.
              </div>
            </Form.Group>
          )}
        </Form>
      )}
    </Segment>
  );
};

export default connect((state, props) => {
  return {
    employees: [
      ...state.employee.list.content,
      ...state.employee.archivedList.content
    ],
    areasList: state.areas.list.content
  };
})(EmployeeCard);
