import moment from "moment";
import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/Areas";
import { getAreasList as setSprayDiaryAreas } from "../SprayDiary/areas";
import { getFarmsSummary } from "actions/Farms/farms";

const url = "/areas";
const changeLogUrl = "/changeLog";

export const fetchAreas = ({
  page,
  size,
  search,
  sort,
  unpaged = false
}) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_AREAS_START
    });

    const params = new URLSearchParams();
    if (unpaged) {
      params.append("unpaged", true);
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    if (search) {
      params.append("search", search);
    }

    (sort || ["id,asc"]).forEach(field => {
      params.append("sort", field);
    });

    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });

    await dispatch({
      type: actionType.FETCH_AREAS,
      payload: res.data
    });

    return res;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_AREAS_FAIL,
      payload: error
    });
  }
};
let db;

let openRequest = indexedDB.open("changelog_db", 1);

openRequest.onupgradeneeded = function(e) {
  db = e.target.result;
  if (!db.objectStoreNames.contains("store")) {
    const storeOS = db.createObjectStore("store", { keyPath: "name" });
  }
};

openRequest.onsuccess = function(e) {
  db = e.target.result;
  addData();
};

const addData = item => {
  return new Promise(resolve => {
    let openRequest = indexedDB.open("changelog_db", 1);

    openRequest.onsuccess = () => {
      db = openRequest.result;

      if (db.objectStoreNames.contains("store")) {
        const tx = db.transaction(["store"], "readwrite");
        const store = tx.objectStore("store");
        item && store.put(item);
        resolve(item);
      }
    };

    openRequest.onerror = () => {
      const error = openRequest.error?.message;
      if (error) {
        resolve(error);
      } else {
        resolve("Unknown error");
      }
    };
  });
};

export const fetchAreasList = (forceFetch = false) => async (
  dispatch,
  getState
) => {
  const {
    user: { user },
    areas
  } = getState();
  const clientId = localStorage.getItem("areaClientId");

  if (
    !areas.list.content.length ||
    !clientId ||
    clientId !== user.profile.client_id ||
    forceFetch
  ) {
    try {
      dispatch({
        type: actionType.FETCH_AREA_LIST_START
      });

      const lastUpdateDate = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

      const res = await axiosProxy({
        method: "GET",
        url,
        params: {
          unpaged: true
        }
      });

      await dispatch({
        type: actionType.FETCH_AREA_LIST,
        payload: {
          data: res.data,
          lastUpdateDate
        }
      });

      dispatch(setSprayDiaryAreas());
      const item = {
        name: "areas",
        ...areas,
        list: {
          ...res?.data,
          isFetching: false
        },
        lastUpdateDate
      };
      await addData(item);

      localStorage.setItem("areaClientId", user.profile.client_id);

      return res;
    } catch (error) {
      dispatch({
        type: actionType.FETCH_AREA_LIST_FAIL,
        payload: error
      });
    }
  }
};

export const fetchAreasLog = () => async (dispatch, getState) => {
  const {
    areas,
    areas: { list, lastUpdateDate }
  } = getState();

  try {
    const startSyncDate = moment.utc().format("YYYY-MM-DDTHH:mm:ss");

    const params = new URLSearchParams({
      unpaged: true,
      entityType: "AREA",
      ...(lastUpdateDate && { updatedAfter: lastUpdateDate })
    });

    const {
      data: { content }
    } = await axiosProxy({
      method: "GET",
      url: changeLogUrl,
      params
    });

    if (content.length > 0) {
      const { updatableAreasIds, deletableAreasIds } = content.reduce(
        (prev, item) => ({
          updatableAreasIds:
            item.updateType !== "DELETED"
              ? [...prev.updatableAreasIds, item.entityId]
              : prev.updatableAreasIds,
          deletableAreasIds:
            item.updateType === "DELETED"
              ? [...prev.deletableAreasIds, item.entityId]
              : prev.deletableAreasIds
        }),
        {
          updatableAreasIds: [],
          deletableAreasIds: []
        }
      );
      const updatedAreas = list.content.filter(
        area => !deletableAreasIds.includes(area.id)
      );

      if (updatableAreasIds.length) {
        dispatch({
          type: actionType.FETCH_AREA_LIST_START
        });

        const params = new URLSearchParams({
          unpaged: true,
          ids: updatableAreasIds
        });

        const {
          data: { content: fetchedAreas }
        } = await axiosProxy({
          method: "GET",
          url,
          params
        });

        fetchedAreas.forEach(area => {
          const itemIndex = updatedAreas.findIndex(item => item.id === area.id);

          if (itemIndex > -1) {
            updatedAreas[itemIndex] = area;
          } else {
            updatedAreas.push(area);
          }
        });
      }

      await dispatch({
        type: actionType.FETCH_AREA_LIST,
        payload: {
          data: {
            ...list,
            content: updatedAreas,
            numberOfElements: updatedAreas.length,
            totalElements: updatedAreas.length
          }
        }
      });

      dispatch(setSprayDiaryAreas());
      if (content.length > 0) {
        dispatch(fetchAreasList());
        dispatch(getFarmsSummary());
      }
      const i = {
        name: "areas",
        ...areas,
        list: {
          ...list,
          content: updatedAreas,
          numberOfElements: updatedAreas.length,
          totalElements: updatedAreas.length,
          isFetching: false
        },
        lastUpdateDate: startSyncDate
      };
      await addData(i);
    } else {
      const i = {
        name: "areas",
        ...areas,
        list: { ...list },
        lastUpdateDate: startSyncDate
      };
      await addData(i);
    }

    dispatch({
      type: actionType.CHANGE_LAST_UPDATE_DATE,
      payload: startSyncDate
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_AREA_LIST_FAIL,
      payload: error
    });
  }
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_AREA_ERROR
  });
};
