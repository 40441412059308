import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Input, Message, Segment } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { abilitiesSelector } from "../../../../../selectors/user";
import styles from "./AddStockItemForm.module.css";
import _ from "lodash";
import { getParentPageHref } from "../../../../../utils/routeHelpers";
import { putMessage } from "../../../../../actions/InfoMessages";
import {
  getErrorFields,
  validationErrors,
} from "../../../../../utils/validationRules";
import ErrorLabel from "../../../../../components/ErrorLabel";
import { scrollIntoError } from "../../../../../utils/scrollIntoError";
import {
  addStock,
  getStockByVarietyIdAndBoxUnitId,
  updateStock,
} from "../../../../../actions/Stock/stock";
import SelectBoxUnit from "../../../../../components/SelectBoxUnit";
import Loader from "../../../../../components/Loader";
import SelectUsedVariety from "../../../../../components/SelectUsedVariety/SelectUsedVariety";

const initialValues = {
  id: null,
  variety: { id: null },
  boxUnit: { id: null },
  packedBoxesCount: null,
  isFetching: false,
  editMode: false,
  showCount: false,
};

class AddStockItemForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...(props.initialValues || initialValues),
      editMode: !!(props.initialValues || initialValues).id,
      showCount: !!(props.initialValues || initialValues).id,
      errorsInSegments: {},
    };

    this.formRef = React.createRef();
  }

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name,
        },
      },
    });
    setTimeout(scrollIntoError, 300);
  };

  changeValue = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  changeValueAndUpdateCount = async (fieldName, value) => {
    const { actions } = this.props;
    this.setState(
      {
        [fieldName]: value,
      },
      () => {
        const { variety, boxUnit } = this.state;
        if (variety && variety.id && boxUnit && boxUnit.id) {
          this.setState({
            isFetching: true,
          });
          actions
            .getStockByVarietyIdAndBoxUnitId(variety.id, boxUnit.id)
            .then((stock) => {
              if (stock === null) {
                this.setState({
                  id: null,
                  packedBoxesCount: null,
                  isFetching: false,
                  showCount: true,
                });
              } else {
                this.setState({
                  isFetching: false,
                  showCount: true,
                  ...stock,
                });
              }
            })
            .catch((_) => {
              this.setState({
                isFetching: false,
                id: null,
                showCount: false,
              });
            });
        }
      }
    );
  };

  onValidSubmit = async () => {
    const { actions, onSubmitForm, route } = this.props;
    const { id, variety, boxUnit, packedBoxesCount } = this.state;
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;

    const request = {
      variety,
      boxUnit,
      packedBoxesCount,
    };

    this.setState({
      errorsInSegments: {},
    });

    let res;

    if (!id) {
      res = await actions.addStock(request);
    } else {
      res = await actions.updateStock(id, request);
    }

    this.isSubmitted = false;

    if (!res.error) {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        this.props.navigate(getParentPageHref(route));
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  changeValue = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { Can, showMobileHeader, formRef } = this.props;
    const {
      id,
      variety,
      boxUnit,
      packedBoxesCount,
      isFetching,
      editMode,
      showCount,
    } = this.state;
    const columnMiddle = {
      mobile: 16,
      tablet: 16,
      computer: 10,
      largeScreen: 10,
      widescreen: 10,
    };

    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        autoComplete="off"
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <label>Select variety</label>
                        <SelectUsedVariety
                          name="variety"
                          validation={"isRequired"}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "variety",
                          })}
                          errorLabel={ErrorLabel}
                          required
                          disabled={editMode}
                          value={variety && variety.id}
                          onChange={(_, data) =>
                            this.changeValueAndUpdateCount("variety", {
                              id: data.value,
                            })
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <label>Select box unit</label>
                        <SelectBoxUnit
                          name="boxUnit"
                          validation={"isRequired"}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "boxUnit",
                          })}
                          errorLabel={ErrorLabel}
                          required
                          disabled={editMode}
                          value={boxUnit && boxUnit.id}
                          onChange={(_, data) =>
                            this.changeValueAndUpdateCount("boxUnit", {
                              id: data.value,
                            })
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      {isFetching ? (
                        <Loader />
                      ) : (
                        showCount && (
                          <Form.Field required>
                            {!editMode && showCount && id && (
                              <Message color={"blue"}>
                                Stock item already exists, you can update the
                                box units is stock
                              </Message>
                            )}
                            <label htmlFor="unitsOnStock">Units in stock</label>
                            <Input
                              fluid
                              name="packedBoxesCount"
                              size="large"
                              type="number"
                              validations="isNotNegativeInteger"
                              validationErrors={validationErrors({
                                isDefaultRequiredValue: "Units in stock",
                              })}
                              errorLabel={ErrorLabel}
                              value={packedBoxesCount}
                              required
                              onChange={(event) =>
                                this.changeValue(
                                  "packedBoxesCount",
                                  event.target.value
                                )
                              }
                            />
                          </Form.Field>
                        )
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                <Can I="update" a="stock">
                  <Button
                    primary
                    size="large"
                    type="button"
                    onClick={this.onSubmit}
                  >
                    Save
                  </Button>
                </Can>
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

AddStockItemForm.propTypes = {
  formRef: PropTypes.func,
  onSubmitForm: PropTypes.func,
  Can: PropTypes.func,
  initialValues: PropTypes.object,
  route: PropTypes.object,
  actions: PropTypes.object,
  showMobileHeader: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    Can: abilitiesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addStock,
      updateStock,
      getStockByVarietyIdAndBoxUnitId,
      putMessage,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStockItemForm);
