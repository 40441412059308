import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Input, Segment, TextArea } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { abilitiesSelector } from "../../../../../selectors/user";
import styles from "./AddPalletForm.module.css";
import _ from "lodash";
import { getParentPageHref } from "../../../../../utils/routeHelpers";
import { putMessage } from "../../../../../actions/InfoMessages";
import {
  getErrorFields,
  validationErrors,
} from "../../../../../utils/validationRules";
import ErrorLabel from "../../../../../components/ErrorLabel";
import { scrollIntoError } from "../../../../../utils/scrollIntoError";
import moment from "moment";
import {
  addPallet,
  updatePallet,
} from "../../../../../actions/Pallets/pallets";
import DatePicker from "../../../../../components/DatePicker";
import SelectArea from "../../../../../components/SelectArea";
import SelectVariety from "../../../../../components/SelectVariety";
import SelectBoxUnit from "../../../../../components/SelectBoxUnit";
import SelectPackingShed from "../../../../../components/SelectPackingShed";
import SelectCrop from "../../../../../components/SelectCrop";
import SelectFarm from "../../../../../components/SelectFarm";

const initialValues = {
  id: null,
  createDate: moment().format("YYYY-MM-DD"),
  crop: { id: null },
  variety: { id: null },
  boxUnit: { id: null },
  farmIDs: [],
  areas: [],
  packingShed: { id: null },
  unitsOnPallet: null,
  notes: "",
  dispatch: { id: null },
  shipped: false,
};

class AddPalletForm extends Component {
  constructor(props) {
    super(props);
    let modifiedInitialValues = props.initialValues
      ? {
          ...props.initialValues,
        }
      : null;

    this.state = {
      ...(modifiedInitialValues || initialValues),
      errorsInSegments: {},
    };

    this.formRef = React.createRef();
  }

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name,
        },
      },
    });
    setTimeout(scrollIntoError, 300);
  };

  changeValue = (fieldName, value) => this.setState({ [fieldName]: value });

  onValidSubmit = async () => {
    const { actions, onSubmitForm, route, initialValues } = this.props;
    const {
      createDate,
      variety,
      boxUnit,
      areas,
      packingShed,
      unitsOnPallet,
      notes,
      dispatch,
      shipped,
    } = this.state;
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;

    const request = {
      createDate,
      variety: { id: variety.id },
      boxUnit: { id: boxUnit.id },
      areas: areas.map((id) => ({ area: { id } })),
      unitsOnPallet,
      packingShed: { id: packingShed.id },
      notes,
      dispatch: dispatch && dispatch.id ? { id: dispatch.id } : null,
      shipped,
    };

    this.setState({
      errorsInSegments: {},
    });

    let res;

    if (!initialValues || !initialValues.id) {
      res = await actions.addPallet(request);
    } else {
      res = await actions.updatePallet(initialValues.id, request);
    }

    this.isSubmitted = false;

    if (!res.error) {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        this.props.navigate(getParentPageHref(route));
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  render() {
    const { Can, showMobileHeader, formRef } = this.props;
    const {
      id,
      createDate,
      crop,
      variety,
      boxUnit,
      farmIDs = [],
      areas = [],
      packingShed,
      unitsOnPallet,
      notes,
      dispatch,
    } = this.state;

    const columnMiddle = {
      mobile: 16,
      tablet: 16,
      computer: 10,
      largeScreen: 10,
      widescreen: 10,
    };

    const isValid =
      createDate &&
      crop &&
      variety &&
      boxUnit &&
      farmIDs.length &&
      areas.length &&
      packingShed &&
      unitsOnPallet >= 0;

    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        autoComplete="off"
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <Segment>
                <Grid>
                  {id && (
                    <Grid.Row>
                      <Grid.Column {...columnMiddle}>
                        <Form.Field className="sprayField">
                          <label>Id</label>
                          <Input
                            name="Id"
                            disabled={true}
                            size="large"
                            value={id}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <DatePicker
                          required
                          validationErrors={validationErrors()}
                          errorLabel={ErrorLabel}
                          value={createDate}
                          onChange={(createDate) =>
                            this.changeValue("createDate", createDate)
                          }
                          labelText="Date"
                          focused={true}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <label>Select crop</label>
                        <SelectCrop
                          name="crop"
                          validation={"isRequired"}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "crop",
                          })}
                          errorLabel={ErrorLabel}
                          required
                          value={crop && crop.id}
                          onChange={(_, data) =>
                            this.changeValue("crop", { id: data.value })
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <label>Select variety</label>
                        <SelectVariety
                          disabled={!(crop && crop.id)}
                          name="variety"
                          selectedCropID={crop && crop.id}
                          validation={"isRequired"}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "variety",
                          })}
                          errorLabel={ErrorLabel}
                          date={createDate}
                          required
                          value={variety && variety.id}
                          onChange={(_, data) =>
                            this.changeValue("variety", { id: data.value })
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <label>Select box unit</label>
                        <SelectBoxUnit
                          name="boxUnit"
                          validation={"isRequired"}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "boxUnit",
                          })}
                          errorLabel={ErrorLabel}
                          date={createDate}
                          required
                          value={boxUnit && boxUnit.id}
                          onChange={(_, data) =>
                            this.changeValue("boxUnit", { id: data.value })
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <label>Select farm</label>
                        <SelectFarm
                          name="farmIDs"
                          multiple
                          validation={"isRequired"}
                          required
                          placeholder={"Select farm"}
                          value={farmIDs}
                          onChange={(_, data) =>
                            this.changeValue("farmIDs", data.value)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field
                        disabled={!farmIDs}
                        required
                        className="sprayField"
                      >
                        <label>Select areas</label>
                        <SelectArea
                          name="area"
                          validation={"isRequired"}
                          required
                          farms={farmIDs}
                          multiple
                          errorLabel={ErrorLabel}
                          date={createDate}
                          value={areas}
                          onChange={(_, data) =>
                            this.changeValue("areas", data.value)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required className="sprayField">
                        <label>Select packing shed</label>
                        <SelectPackingShed
                          name="packingShed"
                          validation={"isRequired"}
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Packing shed",
                          })}
                          errorLabel={ErrorLabel}
                          required
                          value={packingShed && packingShed.id}
                          onChange={(_, data) =>
                            this.changeValue("packingShed", { id: data.value })
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field required>
                        <label htmlFor="unitsOnPallet">Units on pallet</label>
                        <Input
                          fluid
                          name="unitsOnPallet"
                          size="large"
                          type="number"
                          validations="isNotNegativeInteger"
                          validationErrors={validationErrors({
                            isDefaultRequiredValue: "Units on pallet",
                          })}
                          errorLabel={ErrorLabel}
                          value={unitsOnPallet}
                          required
                          onChange={(event) =>
                            this.changeValue(
                              "unitsOnPallet",
                              event.target.value
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  {dispatch && dispatch.id && (
                    <Grid.Row>
                      <Grid.Column {...columnMiddle}>
                        <Form.Field className="sprayField">
                          <label>Dispatch id</label>
                          <Input
                            name="dispatchId"
                            disabled={true}
                            size="large"
                            value={dispatch && dispatch.id}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  {/*<Grid.Row>*/}
                  {/*  <Grid.Column {...columnMiddle}>*/}
                  {/*    <Form.Field className="sprayField">*/}
                  {/*      <Checkbox*/}
                  {/*        name="shipped"*/}
                  {/*        size="large"*/}
                  {/*        checked={shipped}*/}
                  {/*        label={'Shipped'}*/}
                  {/*        onChange={(_, event) => this.changeValue('shipped', event.checked)}*/}
                  {/*      />*/}
                  {/*    </Form.Field>*/}
                  {/*  </Grid.Column>*/}
                  {/*</Grid.Row>*/}
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field className="sprayField">
                        <label>Notes</label>
                        <TextArea
                          name="notes"
                          size="large"
                          value={notes}
                          onChange={(event) =>
                            this.changeValue("notes", event.target.value)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                <Can I="update" a="pallets">
                  <Button
                    primary
                    disabled={!isValid}
                    size="large"
                    type="button"
                    onClick={this.onSubmit}
                  >
                    Save
                  </Button>
                </Can>
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

AddPalletForm.propTypes = {
  formRef: PropTypes.func,
  onSubmitForm: PropTypes.func,
  Can: PropTypes.func,
  showMobileHeader: PropTypes.bool,
  initialValues: PropTypes.object,
  route: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    Can: abilitiesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addPallet,
      updatePallet,
      putMessage,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPalletForm);
