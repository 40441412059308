import React from "react";
import { values, map } from "lodash";
import { actionType } from "constants/Scouting";
import { axiosProxy } from "utils/axiosProxy";
import { genericAttachmentUploaderWithoutSW } from "utils/genericAttachmentUploader";
import { toEndDateString, toStartDateString } from "utils/dateUtils";
import uuid from "uuid/v4";

const url = "/scouting";
let uniqId;

export const fetchScoutingTasks = ({
  archived = false,
  draft,
  page,
  size,
  search,
  filters,
  sort,
  unpaged = false,
}) => async (dispatch) => {
  dispatch({ type: actionType.FETCH_SCOUTING_START });
  try {
    const formatedFilters = filters
      .filter(
        ([key, value]) => key !== "seasons" && key !== "from" && key !== "to"
      )
      .map(([key, value]) => [
        key,
        key === "setOnFrom"
          ? toStartDateString(value)
          : key === "setOnTo"
          ? toEndDateString(value)
          : value,
      ]);

    const params = new URLSearchParams(formatedFilters);

    const convertedFilter = Object.fromEntries(filters);
    if (convertedFilter.seasons) {
      const newFrom =
        convertedFilter.seasons && convertedFilter.seasons.slice(0, 10);
      const newTo =
        convertedFilter.seasons && convertedFilter.seasons.slice(-10);
      params.append("setOnFrom", `${newFrom}T00:00:00`);
      params.append("setOnTo", `${newTo}T23:59:59`);
    }
    if (convertedFilter.from || convertedFilter.to) {
      const newFrom = convertedFilter?.from;
      const newTo = convertedFilter?.to;
      newFrom && params.append("setOnFrom", newFrom);
      newTo && params.append("setOnTo", newTo);
    }

    const farmParam = params.getAll("farm");

    if (farmParam.length) params.set("farmId", farmParam);
    if (draft) params.append("status", "DRAFT");

    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    if (search) params.append("search", search);

    (sort || ["id,asc"]).forEach((field) => {
      params.append("sort", field);
    });

    params.append("archived", archived);

    uniqId = uuid();
    const res = await axiosProxy({
      method: "GET",
      params,
      uniqId,
      url,
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.SET_SCOUTING_DATA,
        payload: res.data,
      });
    }

    return res;
  } catch (e) {
    dispatch({ type: actionType.FETCH_SCOUTING_FAIL, payload: e });
  }
};

export const fetchScoutingFilterData = () => async (dispatch) => {
  dispatch({ type: actionType.FETCH_SCOUTING_FILTER_START });

  try {
    const params = new URLSearchParams({ unpaged: true });
    const res = await axiosProxy({
      method: "GET",
      params,
      url,
    });
    const { content } = res.data;
    const filterDataMaps = content.reduce(
      (acc, current) => {
        const { id, creator, areas } = current;
        const farms = map(areas, "area.farm");

        if (!acc.id[id]) {
          acc.id[id] = { key: id, text: `SC-${id}`, value: id };
        }
        if (creator && !acc.creatorIds[creator.id]) {
          acc.creatorIds[creator.id] = {
            key: creator.id,
            text: `${creator.firstName} ${creator.lastName}`,
            value: creator.id,
          };
        }
        if (farms) {
          farms.forEach(({ id: farmId, name: text }) => {
            if (!acc.farm[farmId]) {
              acc.farm[farmId] = { key: farmId, text, value: farmId };
            }
          });
        }

        return acc;
      },
      { id: {}, creatorIds: {}, farm: {} }
    );

    const filterData = {
      id: values(filterDataMaps.id),
      creatorIds: values(filterDataMaps.creatorIds),
      farm: values(filterDataMaps.farm),
    };

    dispatch({ type: actionType.FETCH_SCOUTING_FILTER, payload: filterData });
  } catch (e) {
    dispatch({ type: actionType.FETCH_SCOUTING_FILTER_FAIL, payload: e });
  }
};

export const updateScouting = (id, newValue) => async (dispatch) => {
  dispatch({ type: actionType.UPDATE_SCOUTING_START });

  try {
    const res = await axiosProxy({
      method: "PUT",
      data: newValue,
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.UPDATE_SCOUTING,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: actionType.UPDATE_SCOUTING_FAIL,
      payload: e.toString(),
    });
  }
};

export const archiveScoutingTasks = (tasksIds) => async (
  dispatch,
  getState
) => {
  dispatch({ type: actionType.ARCHIVE_SCOUTING_START });

  try {
    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?ids=${tasksIds.join(",")}&archive=true`,
    });

    dispatch({ type: actionType.ARCHIVE_SCOUTING, payload: tasksIds });
  } catch (e) {
    dispatch({ type: actionType.ARCHIVE_SCOUTING_FAIL });
  }
};

export const unArchiveScoutingTasks = (tasksIds) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: actionType.UNARCHIVE_SCOUTING_START });

    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?ids=${tasksIds.join(",")}&archive=false`,
    });

    const { scouting } = getState();
    const { data, preArchivedTasks } = scouting;

    dispatch({
      type: actionType.SET_SCOUTING_DATA,
      payload: { ...data, content: preArchivedTasks },
    });
  } catch (e) {
    dispatch({ type: actionType.UNARCHIVE_SCOUTING_FAIL });
  }
};

export const fetchScoutingByID = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionType.FETCH_SCOUTING_BY_ID_START });

    const res = await axiosProxy({
      method: "GET",
      url: `${url}/${id}`,
    });

    dispatch({
      type: actionType.SET_CURRENT_SCOUTING,
      payload: res.data,
    });
  } catch (e) {
    dispatch({ type: actionType.FETCH_SCOUTING_BY_ID_FAIL, payload: e });
  }
};

export const uploadAttachment = ({
  file,
  idReplace,
  callBack,
  data,
  parentId,
}) =>
  genericAttachmentUploaderWithoutSW({
    url: `${url}/attachments/presign_put_url`,
    file,
    startAction: actionType.UPLOAD_SCOUTING_ATTACHMENTS_START,
    successAction: actionType.UPLOAD_SCOUTING_ATTACHMENTS,
    errorAction: actionType.UPLOAD_SCOUTING_ATTACHMENTS_ERROR,
    callBack,
    idReplace,
    data,
    parentId,
  });

export const editScoutingAttachment = (
  scoutingID,
  attachmentID,
  name,
  value
) => (dispatch) =>
  dispatch({
    type: actionType.EDIT_SCOUTING_ATTACHMENT,
    payload: { scoutingID, attachmentID, name, value },
  });

export const clearError = () => (dispatch) =>
  dispatch({ type: actionType.CLEAR_ERROR });

export const setCurrentScouting = (scouting) => (dispatch) => {
  dispatch({ type: actionType.SET_CURRENT_SCOUTING, payload: scouting });
};

export const printingReportStart = () => (dispatch) => {
  dispatch({ type: actionType.PRINT_SCOUTING_START });
};

export const printingReportEnd = () => (dispatch) => {
  dispatch({ type: actionType.PRINT_SCOUTING_END });
};
