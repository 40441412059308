import { createSelector } from "reselect";
const qualityControl = state => state.qualityControl;

export const statusOptions = createSelector([], () => {
  return {
    options: [
      { key: "inProgress", label: "In Progress", value: "IN_PROGRESS" },
      { key: "completed", label: "Completed", value: "COMPLETED" }
    ]
  };
});

export const activeItemSelector = createSelector(
  [qualityControl],
  qualityControlData => {
    const { activeItem, data } = qualityControlData;
    if (activeItem.id) {
      return data.content.find(({ id }) => activeItem.id === id);
    }
    return null;
  }
);

export const parametrsFiltersOptions = createSelector(
  [qualityControl],
  ({ parameters }) => ({
    options: parameters?.map(item => ({
      key: item,
      text: item
        .replace(/_/g, " ")
        .replace(
          /^(.)(.*)$/,
          (_, first, rest) => first.toUpperCase() + rest.toLowerCase()
        ),
      value: item
    }))
  })
);
