import React, { useState } from "react";
import { Button, Table, TableCell } from "semantic-ui-react";
import { round } from "lodash";
import Numeric from "../../../../components/Numeric/Numeric";

const TotalCostChemicalRow = ({ data, name }) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <Table.Row style={{ fontWeight: "bold" }}>
        <TableCell width={1}>
          <Button
            active={isExpanded}
            onClick={() => setExpanded(!isExpanded)}
            compact
            icon="dropdown"
          />
        </TableCell>
        <Table.Cell width={3}>{name}</Table.Cell>
        <Table.Cell width={1}></Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={data.costPerHarvestUnit}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={data.costPerHa}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell width={2}>
          <Numeric
            value={data.totalCosts}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
      </Table.Row>
      {isExpanded && (
        <TableCell colSpan={16}>
          <Table colSpan={16} unstackable>
            <Table.Body>
              {data.tasks.map(task => (
                <>
                  <Table.Row>
                    <Table.Cell width={1}></Table.Cell>
                    <TableCell width={3}>{task.chemicalType}</TableCell>
                    <Table.Cell width={1}></Table.Cell>
                    <Table.Cell width={2}>
                      <Numeric
                        value={
                          data.binCount
                            ? task.chemicalCostPerHarvestUnit
                            : "No information"
                        }
                        fractionDigits={2}
                        commaSeparatorOnThousands
                        units="$ "
                        unitsPosition="left"
                      />
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <Numeric
                        value={
                          data.areaSize
                            ? task.chemicalCostPerHa
                            : "No information"
                        }
                        fractionDigits={2}
                        commaSeparatorOnThousands
                        units="$ "
                        unitsPosition="left"
                      />
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <Numeric
                        value={task.chemicalTotalCosts}
                        fractionDigits={2}
                        commaSeparatorOnThousands
                        units="$ "
                        unitsPosition="left"
                      />
                    </Table.Cell>
                  </Table.Row>
                </>
              ))}
            </Table.Body>
          </Table>
        </TableCell>
      )}
    </>
  );
};

export default TotalCostChemicalRow;
