import { createSelector } from "reselect";

const areas = state => state.areas;
export const areaList = createSelector([areas], ({ list, isFetching }) => ({
  options: list.content.filter(({ archived }) => !archived).map(item => item),
  isFetching
}));
export const areaFilterOptions = createSelector(
  [areas],
  ({ list, isFetching }) => ({
    options: list.content
      // .filter(({ archived }) => !archived)
      .map(({ id, name, parent }) =>
        parent
          ? {
              key: id,
              label: `${parent.name}/${name}`,
              value: id
            }
          : {
              key: id,
              label: name,
              value: id
            }
      ),
    isFetching
  })
);
export const blockSelector = ({ farmIds }) =>
  createSelector([areas], ({ list, isFetching }) => ({
    options: list.content
      .filter(({ parent, archived }) => parent === null && !archived)
      .map(({ id, name, farm }) =>
        farmIds && farmIds.length > 0
          ? farmIds.includes(farm.id) && {
              key: id,
              label: name,
              value: id
            }
          : {
              key: id,
              label: name,
              value: id
            }
      ),
    isFetching
  }));
export const archivedBlockSelector = ({ farmIds }) =>
  createSelector([areas], ({ list, isFetching }) => ({
    options: list.content
      .filter(({ parent, archived }) => parent === null && archived)
      .map(({ id, name, farm }) =>
        farmIds && farmIds.length > 0
          ? farmIds.includes(farm.id) && {
              key: id,
              label: name,
              value: id
            }
          : {
              key: id,
              label: name,
              value: id
            }
      ),
    isFetching
  }));

export const blocListSelector = createSelector(
  [areas],
  ({ list, isFetching }) => ({
    options: list.content
      .filter(({ parent, archived }) => parent === null && !archived)
      .map(({ id, name }) => ({
        key: id,
        label: name,
        value: id
      })),
    isFetching
  })
);
export const blockListOption = createSelector(
  [areas],
  ({ list, isFetching }) => ({
    options: list.content
      .filter(({ parent, archived }) => parent === null && !archived)
      .map(({ id, name }) => ({
        key: id,
        text: name,
        value: id
      })),
    isFetching
  })
);
export const blockListSelectorOption = createSelector(
  [areas],
  ({ list, isFetching }) => ({
    options: list.content
      .filter(({ parent, archived }) => parent === null)
      .map(({ id, name }) => ({
        key: id,
        text: name,
        value: id
      })),
    isFetching
  })
);

export const patchListSelectorOption = ({ blockIds }) =>
  createSelector([areas], ({ list, isFetching }) => ({
    options: list.content
      .filter(
        ({ parent, archived }) =>
          parent !== null && blockIds.includes(parent.id)
      )
      .map(({ id, name }) => ({
        key: id,
        label: name,
        value: id
      })),

    isFetching
  }));

export const patchSelector = ({ farmIds, blockIds }) =>
  createSelector([areas], ({ list, isFetching }) => ({
    options: list.content
      .filter(({ parent, archived }) =>
        blockIds
          ? parent !== null && !archived && blockIds.includes(parent.id)
          : parent !== null && !archived
      )
      .map(({ id, name, farm }) =>
        farmIds && farmIds.length > 0
          ? farmIds.includes(farm.id) && {
              key: id,
              label: name,
              value: id
            }
          : {
              key: id,
              label: name,
              value: id
            }
      ),

    isFetching
  }));

export const patchListOption = createSelector(
  [areas],
  ({ list, isFetching }) => ({
    options: list.content
      .filter(({ parent, archived }) => parent !== null && !archived)
      .map(({ id, name }) => ({
        key: id,
        text: name,
        value: id
      })),
    isFetching
  })
);
export const patchListSelector = createSelector(
  [areas],
  ({ list, isFetching }) => ({
    options: list.content
      .filter(({ parent, archived }) => parent !== null && !archived)
      .map(({ id, name }) => ({
        key: id,
        label: name,
        value: id
      })),
    isFetching
  })
);

export const cropsOptionSelector = createSelector([areas], ({ list }) => ({
  options: [
    ...new Map(
      list.content
        .map(
          i =>
            i.varieties &&
            i.varieties.length > 0 &&
            i.varieties
              .map(item => {
                return (
                  item.variety.crop && {
                    key: item.variety.crop.id,
                    label: item.variety.crop.name,
                    value: item.variety.crop.id
                  }
                );
              })
              .reduce((a, v) => ({ ...v }), {})
        )
        .filter((item, i, ar) => item !== false)
        .map(item => [item["key"], item])
    ).values()
  ]
}));

export const varietiesOptionSelector = ({ cropIds }) =>
  createSelector([areas], ({ list }) => ({
    options: [
      ...new Map(
        list.content
          .map(
            i =>
              i.varieties &&
              i.varieties.length > 0 &&
              i.varieties
                .map(item => {
                  return cropIds
                    ? cropIds.includes(item.variety.crop.id) &&
                        item.variety && {
                          key: item.variety.id,
                          label: `${item.variety.crop.name}/${item.variety.name}`,
                          value: item.variety.id
                        }
                    : item.variety && {
                        key: item.variety.id,
                        label: `${item.variety.crop.name}/${item.variety.name}`,
                        value: item.variety.id
                      };
                })
                .reduce((a, v) => ({ ...v }), {})
          )
          .filter((item, i, ar) => item !== false)
          .map(item => [item["key"], item])
      ).values()
    ]
  }));
