const commonConfig = {
  authority: `${process.env.REACT_APP_AUTH_URL}/realms/tieupfarming`,
  client_id: "app-web-react",
  redirect_uri: `${process.env.REACT_APP_URL}/auth-callback`,
  silent_redirect_uri: `${process.env.REACT_APP_URL}/silent-renew.html`,
  scope: "openid profile offline_access"
};

const oauth2 = {
  ...commonConfig,
  post_logout_redirect_uri: `${process.env.REACT_APP_URL}/#login`,
  response_type: "id_token token",
  automaticSilentRenew: true,
  silentRequestTimeout: 10000,
  accessTokenExpiringNotificationTime: 5 * 60,
  validateSubOnSilentRenew: true,
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true
};

const oauth2Offline = {
  ...commonConfig,
  response_type: "code",
  silentRequestTimeout: 3000
};

export default { oauth2, oauth2Offline };
