import React from "react";
import Logo from "../../../components/Logo";
import { Table, TableBody } from "semantic-ui-react";
import styles from "./TaskPrint.module.css";
import moment from "moment";

const statuses = {
  OPEN: "Open",
  IN_PROGRESS: "In progress",
  COMPLETED: "Completed"
};

class TaskPrintout extends React.Component {
  render() {
    const { task, areasList, employeeList } = this.props;

    function mergeById(arr) {
      const map = new Map();
      arr.forEach(item => {
        if (map.has(item.id)) {
          map.get(item.id).children.push(...item.children);
        } else {
          map.set(item.id, { ...item });
        }
      });

      return Array.from(map.values());
    }
    const filtredTodoAreas = areasList
      ?.filter(item => task?.areas?.some(i => i.areaId === item.id))
      .map((item, index) => {
        const newItem = {
          ...item,
          status: task?.areas.find(a => a.areaId === item.id)?.completed
        };
        return item.type === "BLOCK"
          ? { ...newItem, children: [] }
          : {
              ...newItem.parent,
              children: [newItem]
            };
      });

    const completedAreas = task?.areas?.filter(item => item.completed);
    const progressPrecent = `${Math.ceil(
      (completedAreas?.length * 100) / task?.areas.length
    )}%`;
    const sortedArray = mergeById(filtredTodoAreas);
    return (
      <div className={styles.reportWrapper}>
        <style type="text/css" media="print">
          {"\
  @page { size: landscape; }\
"}
        </style>
        <div className={styles.printTableRow}>
          <div className={styles.printTableHeader}>
            GT-{task?.id}, {task?.name}
          </div>
          <div className={styles.printTableLogo}>
            <Logo width={90} height={40} />
          </div>
        </div>
        <div className={styles.contentSection}>
          <div className={styles.totalSection}>
            <div className={styles.totalHeaderSection}>
              <div className={styles.totalHeader}>Task status</div>
              <div className={styles.totalHeader}>Date range</div>
              <div className={styles.totalHeader}>Expiry date & time</div>
              <div className={styles.totalHeader}>Supervisor</div>
            </div>
            <div className={styles.totalValueSection}>
              <div className={styles.totalValue}>
                {task?.status === "IN_PROGRESS" ? `${progressPrecent} ` : ""}
                {statuses[task?.status]}
              </div>
              <div className={styles.totalValue}>
                {moment(task?.startAt).format("DD/MM/YYYY")}-
                {moment(task?.endAt).format("DD/MM/YYYY")}
              </div>
              <div className={styles.totalValue}>
                {" "}
                {moment(task?.expireAt).format("DD/MM/YYYY")}
              </div>
              <div className={styles.totalValue}>
                {employeeList
                  ?.filter(item =>
                    task?.supervisors.some(i => i.employeeId === item.id)
                  )
                  .map(e => `${e.firstName} ${e.lastName}`)
                  .join(", ")}
              </div>
            </div>
            <div className={styles.totalAssigneeHeaderSection}>
              <div className={styles.totalAssigneeHeader}>Assignees</div>
            </div>
            <div className={styles.totalAssigneeValueSection}>
              <div className={styles.totalAssigneeValue}>
                {" "}
                {employeeList
                  ?.filter(item =>
                    task?.assignedTo.some(i => i.employeeId === item.id)
                  )
                  .map(e => `${e.firstName} ${e.lastName}`)
                  .join(", ")}
              </div>
            </div>
          </div>
          {task && (
            <>
              {sortedArray.map(block => {
                const blockWt = task?.workingTimes?.find(item =>
                  item.areas.some(i => i.areaId === block.id)
                );
                return (
                  <>
                    <div className={styles.blockWrapper}>
                      Block {block.name}
                    </div>
                    {block.children.length ? (
                      block.children.map(item => {
                        const patchWt = task?.workingTimes?.find(p =>
                          p.areas.some(i => i.areaId === item.id)
                        );
                        return (
                          <div className={styles.subTable}>
                            <div className={styles.subTableHeaderSection}>
                              <div className={styles.subTableHeader}>Patch</div>
                              <div className={styles.subTableHeader}>Crop</div>
                              <div className={styles.subTableHeader}>
                                Variety
                              </div>
                              <div className={styles.subTableHeader}>Size</div>
                              <div className={styles.subTableHeader}>
                                Working time
                              </div>
                              <div className={styles.subTableHeader}>
                                Status
                              </div>
                            </div>
                            <div className={styles.subTableValueSection}>
                              <div className={styles.subTableValue}>
                                {item.name}
                              </div>
                              <div className={styles.subTableValue}>
                                {" "}
                                {item.varieties
                                  .map(i => i.variety.crop.name)
                                  .join(", ")}
                              </div>
                              <div className={styles.subTableValue}>
                                {item.varieties
                                  .map(i => i.variety.name)
                                  .join(", ")}
                              </div>
                              <div className={styles.subTableValue}>
                                {" "}
                                {item.size} Ha
                              </div>
                              <div className={styles.subTableValue}>
                                {moment(patchWt?.startAt).format("DD/MM/YYYY")}-
                                {moment(patchWt?.endAt).format("DD/MM/YYYY")}
                                <p>
                                  {moment(patchWt?.startAt).format("HH:MM")}-
                                  {moment(patchWt?.endAt).format("HH:MM")}
                                </p>
                              </div>
                              <div
                                className={
                                  item.status
                                    ? styles.subTableActiveValue
                                    : styles.subTableValue
                                }
                              >
                                {item.status ? "Completed" : "To do"}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className={styles.subTable}>
                        <div className={styles.subTableHeaderSection}>
                          <div className={styles.subTableHeader}>Patch</div>
                          <div className={styles.subTableHeader}>Crop</div>
                          <div className={styles.subTableHeader}>Variety</div>
                          <div className={styles.subTableHeader}>Size</div>
                          <div className={styles.subTableHeader}>
                            Working time
                          </div>
                          <div className={styles.subTableHeader}>Status</div>
                        </div>
                        <div className={styles.subTableValueSection}>
                          <div className={styles.subTableValue}>-</div>
                          <div className={styles.subTableValue}>
                            {block.varieties
                              .map(item => item.variety.crop.name)
                              .join(", ")}
                          </div>
                          <div className={styles.subTableValue}>
                            {block.varieties
                              .map(item => item.variety.name)
                              .join(", ")}
                          </div>
                          <div className={styles.subTableValue}>
                            {block.size} Ha
                          </div>
                          <div className={styles.subTableValue}>
                            {moment(blockWt?.startAt).format("DD/MM/YYYY")}-
                            {moment(blockWt?.endAt).format("DD/MM/YYYY")}
                            <p>
                              {moment(blockWt?.startAt).format("HH:MM")}-
                              {moment(blockWt?.endAt).format("HH:MM")}
                            </p>
                          </div>
                          <div
                            className={
                              block.status
                                ? styles.subTableActiveValue
                                : styles.subTableValue
                            }
                          >
                            {block.status ? "Completed" : "To do"}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default TaskPrintout;
