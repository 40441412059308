import React, { useState, useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import styles from "./Filters.module.css";
import { Dropdown, Icon, Button, Input, Checkbox } from "semantic-ui-react";

const Filters = ({
  filterChange,
  withMoreButton,
  options,
  selectedFilters,
  onFilterChange,
  clearFilters,
  loading,
  dateType,
  defaultDate
}) => {
  const [selectedFilter, setSelectedFilter] = useState([]);
  let showOptions = options.slice(0, 3);
  const dispatch = useDispatch();
  const dropDownOptions = options.slice(3, options.length).map(item => ({
    key: item.filterId,
    text: item.title,
    value: item.filterId
  }));

  return withMoreButton ? (
    <>
      {showOptions.map(filter => {
        const FilterComponent = filter.type;
        const disableChecker = !!selectedFilters.seasons
          ? "seasons"
          : !!selectedFilters.from || !!selectedFilters.to
          ? "date"
          : null;
        return (
          <FilterComponent
            filterChange={filterChange}
            key={filter.filterId}
            disableChecker={disableChecker}
            loading={loading}
            dateType={dateType}
            defaultDate={defaultDate}
            text={filter.title}
            className={classNames(styles.filterButton, {
              [styles.selectedFilter]:
                selectedFilters[filter.filterId] != null &&
                selectedFilters[filter.filterId] != undefined &&
                !!selectedFilters[filter.filterId]?.length
            })}
            onChange={(filterKey, value) => {
              return onFilterChange(filterKey, value);
            }}
            value={selectedFilters[filter.filterId]}
            {...filter}
          />
        );
      })}

      {options
        .filter(item => selectedFilter.some(id => id == item.filterId))
        .map(filter => {
          const FilterComponent = filter.type;
          const disableChecker = !!selectedFilters.seasons
            ? "seasons"
            : !!selectedFilters.from || !!selectedFilters.to
            ? "date"
            : null;
          return (
            <FilterComponent
              filterChange={filterChange}
              selectedFilter={selectedFilter}
              disableChecker={disableChecker}
              loading={loading}
              dateType={dateType}
              defaultDate={defaultDate}
              filter={filter}
              showOptions={showOptions}
              setSelectedFilter={setSelectedFilter}
              addedFilter={true}
              key={filter.filterId}
              text={filter.title}
              className={classNames(styles.filterButton, {
                [styles.selectedFilter]:
                  selectedFilters[filter.filterId] != null &&
                  selectedFilters[filter.filterId] != undefined &&
                  !!selectedFilters[filter.filterId]?.length
              })}
              onChange={(filterKey, value) => {
                return onFilterChange(filterKey, value);
              }}
              value={selectedFilters[filter.filterId]}
              {...filter}
            />
          );
        })}

      {
        <Dropdown
          // disabled={loading}
          text="More"
          multiple
          className={styles.filterDropDown}
          icon={
            <div className={styles.moreButtonsIcon}>
              <Icon size="small" name="plus" />
            </div>
          }
          closeOnChange={false}
          selectOnBlur={false}
        >
          <Dropdown.Menu className={styles.scrollingMenuWrapper}>
            <Dropdown.Menu className={styles.scrollingMenu} scrolling>
              {dropDownOptions.map(option => (
                <Dropdown.Item
                  key={option.value}
                  value={option.value}
                  text={option.text}
                  content={
                    <Checkbox
                      label={option.text}
                      checked={selectedFilter.includes(option.value)}
                    />
                  }
                  onClick={(e, { value }) => {
                    e.stopPropagation();
                    return selectedFilter.includes(value)
                      ? setSelectedFilter(
                          selectedFilter.filter(item => item != value)
                        )
                      : setSelectedFilter([...selectedFilter, value]);
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown>
      }

      {!!Object.keys(selectedFilters).length && (
        <span className={styles.resetButton} onClick={clearFilters}>
          Reset
        </span>
      )}
    </>
  ) : (
    <>
      {options.map(filter => {
        const FilterComponent = filter.type;
        const disableChecker = !!selectedFilters.seasons
          ? "seasons"
          : !!selectedFilters.from || !!selectedFilters.to
          ? "date"
          : null;
        return (
          <FilterComponent
            filterChange={filterChange}
            loading={loading}
            dateType={dateType}
            disableChecker={disableChecker}
            defaultDate={defaultDate}
            key={filter.filterId}
            text={filter.title}
            className={classNames(styles.filterButton, {
              [styles.selectedFilter]:
                selectedFilters[filter.filterId] != null &&
                selectedFilters[filter.filterId] != undefined &&
                !!selectedFilters[filter.filterId]?.length
            })}
            onChange={(filterKey, value) => {
              return onFilterChange(filterKey, value);
            }}
            value={selectedFilters[filter.filterId]}
            {...filter}
          />
        );
      })}
      {!!Object.keys(selectedFilters).length && (
        <span className={styles.resetButton} onClick={clearFilters}>
          Reset
        </span>
      )}
    </>
  );
};

Filters.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired
};

Filters.defaultProps = {
  disabled: false
};

export default Filters;
