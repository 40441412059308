import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

import {
  getErrorFields,
  validationErrors,
} from "../../../../../utils/validationRules";
import ErrorLabel from "../../../../../components/ErrorLabel";
import { abilitiesSelector } from "../../../../../selectors/user";
import SegmentClosable from "../../../../../components/SegmentClosable";
import { scrollIntoError } from "../../../../../utils/scrollIntoError";
import styles from "./VendorForm.module.css";
import {
  addVendor,
  updateVendor,
} from "../../../../../actions/Vendors/vendors";
import { getParentPageHref } from "../../../../../utils/routeHelpers";

const initialValues = {
  id: null,
  name: "",
  email: "",
};

class VendorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Vendor: props.initialValues ? props.initialValues : initialValues,
      errorsInSegments: {},
    };
    this.formRef = React.createRef();
    this.isSubmitted = false;
  }

  changeValue = (fieldName, value) => {
    const { Vendor } = this.state;
    this.setState({
      Vendor: {
        ...Vendor,
        [fieldName]: value,
      },
    });
  };

  handleErrors = () => {
    const { formRef } = this.props;
    const formsyForm = (formRef || this.formRef).current.formsyForm;
    const errorFields = getErrorFields(formsyForm);
    this.setState({
      errorsInSegments: {
        information: {
          error: errorFields.name,
        },
      },
    });
    setTimeout(scrollIntoError, 300);
  };

  onValidSubmit = async () => {
    if (this.isSubmitted) {
      return null;
    }
    this.isSubmitted = true;
    const {
      actions: { addVendor, updateVendor },
      onSubmitForm,
      route,
    } = this.props;
    const { Vendor } = this.state;

    const VendorData = _.omit(Vendor, ["id", "isFetching"]);

    this.setState({
      errorsInSegments: {},
    });

    let res;

    if (Vendor.id) {
      res = await updateVendor(Vendor.id, VendorData, true);
    } else {
      res = await addVendor(VendorData, true);
    }
    this.isSubmitted = false;

    if (!res.error) {
      if (_.isFunction(onSubmitForm)) {
        onSubmitForm(res);
      } else {
        this.props.navigate(getParentPageHref(route));
      }
    }
  };

  onSubmit = () => {
    const { formRef } = this.props;
    const targetRef = formRef || this.formRef;
    targetRef.current.submit();
  };

  render() {
    const { modal, Can, showMobileHeader, formRef } = this.props;
    const {
      Vendor: { id, name, email },
      errorsInSegments,
    } = this.state;

    const columnMiddle = modal
      ? {
          width: 16,
        }
      : {
          mobile: 16,
          tablet: 16,
          computer: 10,
          largeScreen: 10,
          widescreen: 10,
        };
    return (
      <Form
        onValidSubmit={this.onValidSubmit}
        onInvalidSubmit={this.handleErrors}
        loading={false}
        ref={formRef || this.formRef}
      >
        <div className={`${styles.sprayGrid} ${modal ? styles.forModal : ""}`}>
          <div className={styles.sprayFormContainer} id="sprayGrid">
            <div>
              <SegmentClosable
                title="Client/Buyer information"
                errors={errorsInSegments.information}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Input
                        fluid
                        {...(!id ? { autoFocus: true } : {})}
                        label="Name"
                        placeholder="Name"
                        name="name"
                        size="large"
                        required
                        validationErrors={validationErrors({
                          isDefaultRequiredValue: "Name",
                        })}
                        errorLabel={ErrorLabel}
                        value={name}
                        onChange={(event) =>
                          this.changeValue("name", event.target.value)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column {...columnMiddle}>
                      <Form.Field>
                        <label htmlFor="email">Email</label>
                        <Form.Input
                          placeholder="joe@tieupfarming.com"
                          value={email}
                          validations={{ isEmail: true }}
                          validationErrors={{
                            isEmail: "You have to type valid email",
                          }}
                          errorLabel={ErrorLabel}
                          id="email"
                          name="email"
                          onChange={(event) =>
                            this.changeValue("email", event.target.value)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </SegmentClosable>
            </div>
          </div>
          {!showMobileHeader && (
            <div className={`${styles.sprayFormFooter} show-sm`}>
              <div className="text-right">
                {id ? (
                  <Can I="update" a="vendors">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                ) : (
                  <Can I="add" a="vendors">
                    <Button
                      primary
                      size="large"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Save
                    </Button>
                  </Can>
                )}
              </div>
            </div>
          )}
        </div>
      </Form>
    );
  }
}

VendorForm.propTypes = {
  formRef: PropTypes.func,
  onSubmitForm: PropTypes.func,
  Can: PropTypes.func,
  initialValues: PropTypes.object,
  modal: PropTypes.bool,
  showMobileHeader: PropTypes.bool,
  route: PropTypes.object,
  actions: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    Can: abilitiesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addVendor,
      updateVendor,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorForm);
