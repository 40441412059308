import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import { Button, Grid, Icon, Menu } from "semantic-ui-react";
import localStorage from "localStorage";
import CircularProgressbar from "../../../components/CircularProgressbar";
import { onlineSelector } from "../../../selectors/common";
import Location from "./Tabs/Location";
import GeneralInfo from "./Tabs/GeneralInfo";
import Operations from "./Tabs/Operations";
import StatusDropdown from "../../../components/StatusDropdown";
import TaskHeader from "./TaskHeader";
import Loader from "../../../components/Loader";
import { abilitiesSelector } from "../../../selectors/user";

import {
  employeesSelector,
  interventionTypesSelector,
  originalFetchedTaskSelector,
  taskNamesSelector,
  taskSelector,
} from "../../../selectors/sprayDiary";

import { shedsListSelector } from "selectors/sheds";

import { abilitiesPairsSelector } from "selectors/user";
import moment from "moment";
import {
  selectEmployees,
  selectSingleEmployee,
} from "../../../actions/SprayDiary/employee";
import {
  getBatchNumbersLastYear,
  updateTaskItem,
} from "../../../actions/Spray/tasks";
import { fetchListSheds } from "actions/Sheds";
import {
  createTask,
  getTaskById,
  restoreBackup,
  setActualRowsToSpray,
  setActualRowsToSprayCheckState,
  setComments,
  setDeltaT,
  setGrowthStage,
  setInterventionType,
  setPPEWorn,
  setRowsToSpray,
  setSprayDiaryField,
  setStatus,
  setWindDirection,
  setWindSpeed,
} from "../../../actions/SprayDiary/common";
import {
  changeAreas,
  setLPerHa,
  setWidthPerRow,
} from "../../../actions/SprayDiary/areas";
import {
  clearMachinery,
  selectMachinery,
  setGear,
  setMachineryConc,
  setMachinerySpeed,
  setNozzle,
  setNozzlesPerSide,
  setPressure,
  setPto,
  setRpm,
} from "../../../actions/SprayDiary/machinery";
import {
  setChemicalFromList,
  changeChemicalBatchnumbersList,
  setChemicalItemToAdd,
} from "../../../actions/SprayDiary/chemicals";
import { prepareTaskDataToSend } from "../Utils/prepareTaskDataToSend";
import { prepareTaskData } from "../Utils/prepareTaskData";
import TaskRow from "./TaskRow";
import generatePrintPage from "../TaskDetails/generatePrintPage";
import { collectBlockData } from "../Utils/collectBlockData";
import WorkingTimes, { prepareTimeToShow } from "./WorkingTimes/WorkingTimes";
import Wizard from "./Wizard";
import { calcTotalWorkingTimes } from "../CreateSprayTask/WorkingTimes/WorkingTimes";
import styles from "./TaskDetailsMobile.module.css";
import TaskRoute from "./Tabs/TaskRoute";
import { TASK_NAME_MAX_LENGTH } from "../../../constants/SprayDiary";

const collectTaskDoneData = (task) => {
  let data = {
    blocksNames: [],
    doneCount: 0,
    donePercent: 0,
  };

  if (task.selectedAreas && task.selectedAreas.length) {
    data = task.selectedAreas.reduce((data, block) => {
      if (block) {
        data.blocksNames.push({
          name: block.name,
          id: block.id,
          done: block.done,
          parent: block.parent ? block.parent : {},
        });
      }
      data.doneCount = block.done ? data.doneCount + 1 : data.doneCount;
      return data;
    }, data);
    data.donePercent = (
      (data.doneCount / task.selectedAreas.length) *
      100
    ).toFixed(0);
  }

  return data;
};

class TaskDetailsMobile extends Component {
  state = {
    showMore: !(
      localStorage.getItem("sprayTaskShowMore") &&
      localStorage.getItem("sprayTaskShowMore") === "No"
    ),
    fieldToEdit: null,
    blocksData: {},
    showWizard: false,
    wizardStep: null,
    totalSteps: null,
    stepsValidations: [],
    activeTab: "Location",
    showExternalField: false,
    rowWithHeavy: null,
  };

  componentDidMount() {
    this.checkStepsValidations();
    this.props.actions.fetchListSheds(true);

    let collectedData = collectTaskDoneData(this.props.task);
    this.setState({ blocksData: collectedData });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { task, sprayTask, actions } = this.props;
    if (
      task &&
      (task.id !== prevProps.task.id ||
        (!task.id && task._id && task._id !== prevProps.task._id))
    ) {
      actions.getTaskById(task.id || task._id);
      this.setState({
        fieldToEdit: null,
        showWizard: false,
        wizardStep: null,
        totalSteps: null,
        stepsValidations: [],
        showExternalField: false,
        rowWithHeavy: null,
        blocksData: collectTaskDoneData(task),
      });
    } else if (task !== prevProps.task) {
      this.setState({
        blocksData: collectTaskDoneData(task),
      });
    }
    if (sprayTask !== prevProps.sprayTask) {
      this.checkStepsValidations();
    }
  }

  setExternalField = (showExternalField) => {
    this.setState({
      showExternalField,
    });
  };

  checkStepsValidations() {
    const { sprayTask } = this.props;

    const workingTimeList = sprayTask.workingTimes.map((i) => ({
      start: i.workingFrom,
      end: i.workingTo,
      date: i.date,
    }));

    const stepsValidations = [];
    stepsValidations[0] =
      !(calcTotalWorkingTimes(sprayTask.workingTimes).total <= "0:00") &&
      sprayTask.workingTimes.every(
        (item) => item.workingTo > item.workingFrom
      ) &&
      !this.isOverlapping(workingTimeList);

    stepsValidations[1] = !sprayTask.selectedChemicals.find(
      (chemical) =>
        chemical.batchRequired &&
        !(
          chemical.batchNumbers &&
          chemical.batchNumbers.length &&
          chemical.batchNumbers[0] !== ""
        )
    );
    stepsValidations[2] = true;
    stepsValidations[3] =
      sprayTask.windDirection &&
      sprayTask.temperature &&
      sprayTask.windStrength;
    stepsValidations[4] = sprayTask.ppeWorn !== null;
    stepsValidations[5] = true;
    this.setState({
      stepsValidations,
    });
    return stepsValidations;
  }

  setActiveTask = () => {
    const { setActiveTask } = this.props;
    setActiveTask(null);
  };

  toggleMore = () => {
    const { showMore } = this.state;
    localStorage.setItem("sprayTaskShowMore", showMore ? "No" : "Yes");
    this.setState({
      showMore: !showMore,
    });
  };

  updateTask = async () => {
    const { sprayDiary, sprayTask, actions } = this.props;
    const stepsValidations = this.checkStepsValidations();

    let taskData = prepareTaskData({
      forTask: true,
      sprayDiary: this.props.sprayDiary,
    });

    const attachmentsToWatch = !sprayTask.id
      ? taskData.attachments
          .filter((file) => file.idReplace)
          .map((file) => file.idReplace)
      : null;
    const dataToSend = prepareTaskDataToSend({
      sprayDiary: this.props.sprayDiary,
      fetchedTask: this.props.fetchedTask,
    });

    if (
      this.props.sprayDiary.status === "COMPLETED" &&
      !(stepsValidations[0] && stepsValidations[1] && stepsValidations[3])
    ) {
      await actions.restoreBackup();
      taskData = prepareTaskData({
        forTask: true,
        sprayDiary: this.props.sprayDiary,
      });

      //this.updateTask();
    } else if (Object.keys(dataToSend).length > 0) {
      actions.createTask({
        id: sprayTask.id,
        _id: sprayDiary._id,
        data: dataToSend,
        attachmentsToWatch,
      });
    }

    actions.updateTaskItem({
      id: sprayTask.id,
      _id: sprayDiary._id,
      data: taskData,
    });
  };

  checkAndUpdate = async () => {
    const { fieldToEdit } = this.state;
    const { actions } = this.props;
    if (
      fieldToEdit &&
      fieldToEdit.valueForUpdate !== undefined &&
      fieldToEdit.valueForUpdate !== fieldToEdit.value
    ) {
      switch (fieldToEdit.name) {
        case "supervisors":
        case "assignees":
          await actions.selectEmployees(
            fieldToEdit.valueForUpdate,
            fieldToEdit.name
          );
          break;
        case "creator":
          await actions.selectSingleEmployee(
            fieldToEdit.valueForUpdate,
            fieldToEdit.name
          );
          break;
        case "selectedAreas":
          await actions.changeAreas(fieldToEdit.valueForUpdate);
          break;
        case "interventionType":
          await actions.setInterventionType(
            fieldToEdit.valueForUpdate !== ""
              ? fieldToEdit.valueForUpdate
              : null
          );
          break;
        case "widthPerRow":
          await actions.setWidthPerRow(fieldToEdit.valueForUpdate);
          break;
        case "literPerHectare":
          await actions.setLPerHa(fieldToEdit.valueForUpdate);
          break;
        case "rowsToSpray":
          await actions.setRowsToSpray(fieldToEdit.valueForUpdate);
          break;
        case "growthStage":
          await actions.setGrowthStage(fieldToEdit.valueForUpdate);
          break;
        case "selectedMachinery":
          if (fieldToEdit.valueForUpdate) {
            await actions.selectMachinery(fieldToEdit.valueForUpdate);
          } else {
            await actions.clearMachinery();
          }
          break;
        case "nozzle":
          await actions.setNozzle(fieldToEdit.valueForUpdate);
          break;
        case "nozzlesPerSide":
          await actions.setNozzlesPerSide(fieldToEdit.valueForUpdate);
          break;
        case "gear":
          await actions.setGear(fieldToEdit.valueForUpdate);
          break;
        case "rpm":
          await actions.setRpm(fieldToEdit.valueForUpdate);
          break;
        case "pressure":
          await actions.setPressure(fieldToEdit.valueForUpdate);
          break;
        case "machinerySpeed":
          await actions.setMachinerySpeed(fieldToEdit.valueForUpdate);
          break;
        case "pto":
          await actions.setPto(fieldToEdit.valueForUpdate);
          break;
        case "conc":
          await actions.setMachineryConc(fieldToEdit.valueForUpdate);
          break;
        case "selectedChemical":
          await actions.setChemicalFromList(
            fieldToEdit.suffix,
            fieldToEdit.valueForUpdate
          );
          break;
        case "chemicalRate.rate":
          await actions.setChemicalItemToAdd(fieldToEdit.suffix, {
            rate: {
              ...fieldToEdit.value,
              rate: fieldToEdit.valueForUpdate,
            },
          });
          break;
        case "chemicalBatchNumber":
          await actions.setChemicalItemToAdd(fieldToEdit.suffix, {
            batchNumbers: fieldToEdit.valueForUpdate,
          });
          break;
        case "addForm":
          await actions.setChemicalItemToAdd(fieldToEdit.suffix, {
            batchNumbers: fieldToEdit.valueForUpdate,
          });
          break;
        case "comment":
          await actions.setComments(fieldToEdit.valueForUpdate);
          break;
        case "ppeWorn":
          await actions.setPPEWorn(fieldToEdit.valueForUpdate);
          break;
        case "actualRowsToSpray":
          await actions.setActualRowsToSpray(fieldToEdit.valueForUpdate);
          break;
        case "windDirection":
          await actions.setWindDirection(fieldToEdit.valueForUpdate);
          break;
        case "windStrength":
          await actions.setWindSpeed(fieldToEdit.valueForUpdate);
          break;
        case "deltaT":
          await actions.setDeltaT(fieldToEdit.valueForUpdate);
          break;
        default:
          await actions.setSprayDiaryField({
            fieldName: fieldToEdit.name,
            fieldValue: fieldToEdit.valueForUpdate,
          });
          break;
      }
      this.updateTask();
    }
  };

  cancelActiveTask = async () => {
    const { actions } = this.props;
    await actions.restoreBackup();
    this.updateTask();
    this.setState({
      fieldToEdit: null,
    });
  };

  onCancel = () => {
    const { showWizard } = this.state;

    showWizard ? this.cancelWizard() : this.cancelActiveTask();
  };

  toggleEditField = ({ name, value, suffix, heavy, rules, component }) => {
    const { fieldToEdit, showWizard } = this.state;
    if ((fieldToEdit && fieldToEdit.error) || showWizard) {
      return false;
    }
    if (
      fieldToEdit &&
      fieldToEdit.name === name &&
      (!suffix || fieldToEdit.suffix === suffix)
    ) {
      this.cancelActiveTask();
    } else if (name) {
      this.checkAndUpdate();
      this.setState({
        fieldToEdit: {
          name,
          value,
          suffix,
          heavy,
          rules,
          component,
        },
      });
    } else {
      this.setState({
        fieldToEdit: null,
      });
    }
  };

  saveActiveTask = () => {
    const { fieldToEdit } = this.state;
    if (fieldToEdit && fieldToEdit.component && fieldToEdit.heavy) {
      this.updateTask();
    } else if (fieldToEdit) {
      this.checkAndUpdate();
    }

    this.setState({
      fieldToEdit: null,
    });
  };

  onFieldChange = (valueForUpdate) => {
    const { fieldToEdit } = this.state;
    const isBatchNumberField = fieldToEdit?.name?.includes(
      "chemicalBatchNumber"
    );
    this.setState({
      fieldToEdit: {
        ...fieldToEdit,
        name: isBatchNumberField ? "chemicalBatchNumber" : fieldToEdit?.name,
        valueForUpdate,
        error: this.checkValidationError(valueForUpdate),
      },
    });
    if (isBatchNumberField) {
      this.props.actions.changeChemicalBatchnumbersList({
        id: fieldToEdit.suffix,
        batchNumbers: valueForUpdate,
      });
    }
  };

  changeStatus = async (_, data) => {
    const { actions } = this.props;
    this.saveActiveTask();
    await actions.setStatus(data.value);
    if (data.value === "COMPLETED") {
      actions.getBatchNumbersLastYear(this.props.sprayTask.selectedChemicals);
      this.startWizard();
    } else {
      this.updateTask();
    }
  };

  setStep = (next) => {
    const { sprayTask } = this.props;
    const { wizardStep } = this.state;

    this.setState({
      wizardStep: Math.max(
        0,
        next
          ? wizardStep +
              (wizardStep === 1 &&
              (!sprayTask.selectedMachinery || !sprayTask.selectedMachinery.id)
                ? 2
                : 1)
          : wizardStep -
              (wizardStep === 3 &&
              (!sprayTask.selectedMachinery || !sprayTask.selectedMachinery.id)
                ? 2
                : 1)
      ),
    });
  };

  startWizard = async () => {
    const { actions, sprayTask } = this.props;
    await actions.setActualRowsToSprayCheckState(true);
    this.setState({
      showWizard: true,
      wizardStep: 0,
      totalSteps:
        !sprayTask.selectedMachinery || !sprayTask.selectedMachinery.id ? 4 : 5,
    });
  };

  finishWizard = () => {
    const { wizardStep } = this.state;
    this.updateTask();
    this.setState({
      wizardStep: wizardStep + 1,
    });
  };

  closeWizard = () => {
    this.setState({
      showWizard: false,
      wizardStep: null,
      totalSteps: null,
      fieldToEdit: null,
    });
  };

  cancelWizard = async () => {
    const { actions } = this.props;
    await actions.restoreBackup();
    this.updateTask();
    this.closeWizard();
  };

  getTimesList = () => {
    const { sprayTask } = this.props;

    return sprayTask && sprayTask.workingTimes
      ? sprayTask.workingTimes.map((workingTime) =>
          prepareTimeToShow(workingTime)
        )
      : "—";
  };

  getTargetProp = (rule, valueForUpdate) => {
    const { fieldToEdit } = this.state;
    return fieldToEdit.rules[rule].keyToCheck && valueForUpdate
      ? get(valueForUpdate, fieldToEdit.rules[rule].keyToCheck)
      : fieldToEdit.rules[rule].keyToCheck
      ? get(fieldToEdit.value, fieldToEdit.rules[rule].keyToCheck)
      : valueForUpdate;
  };

  checkValidationError = (valueForUpdate) => {
    const { sprayTask } = this.props;
    const { fieldToEdit } = this.state;
    let disabled = false;
    let error = null;

    if (fieldToEdit && fieldToEdit.rules) {
      Object.keys(fieldToEdit.rules).forEach((rule) => {
        const targetProp = this.getTargetProp(rule, valueForUpdate);
        switch (rule) {
          case "required":
          case "requiredOnComplete":
            disabled =
              targetProp !== undefined &&
              (Array.isArray(targetProp)
                ? targetProp.length === 0
                : !targetProp);
            if (rule === "requiredOnComplete")
              disabled = disabled && sprayTask.status === "COMPLETED";
            if (disabled) {
              error = { message: "Required Field" };
            }
            break;
          case "notIncludeInArray":
            fieldToEdit.rules[rule].forEach((itemToSearch) => {
              const includeIndexes = Array.isArray(valueForUpdate)
                ? valueForUpdate.reduce((indexes, item, index) => {
                    if (item.indexOf(itemToSearch) >= 0) {
                      indexes.push(index);
                    }
                    return indexes;
                  }, [])
                : valueForUpdate.indexOf(itemToSearch) >= 0
                ? [0]
                : [];
              disabled = includeIndexes.length > 0;
              if (disabled) {
                error = {
                  message: "Not accepted value",
                  indexes: includeIndexes,
                };
              }
            });
            break;

          default:
            break;
        }
      });
    }

    return error;
  };

  handleItemClick = (e, { name }) => {
    const { actions } = this.props;
    this.setState({ activeTab: name });
    name === "Operations" &&
      actions.getBatchNumbersLastYear(this.props.sprayTask.selectedChemicals);
  };
  overlapping = (a, b) => {
    const range = moment.range(a, b);
    const getMinutes = (s) => {
      const p = s.split(":").map(Number);
      return p[0] * 60 + p[1];
    };
    return a.date === b.date
      ? getMinutes(a.end) > getMinutes(b.start) &&
          getMinutes(b.end) > getMinutes(a.start)
      : false;
  };
  isOverlapping = (arr) => {
    let i, j;
    for (i = 0; i < arr.length - 1; i++) {
      for (j = i + 1; j < arr.length; j++) {
        if (this.overlapping(arr[i], arr[j])) {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    const {
      interventionTypesOptions,
      sprayTask,
      employeesOptions,
      shedsOptions,
      applicationTypeOptions,
      taskNamesOptions,
      Can,
      online,
      areasList,
      showParentBlocks,
      onCloseSidebar,
      task,
      handlePrintTask,
      userAbilities,
      navigate,
      list,
    } = this.props;
    const {
      showMore,
      fieldToEdit,
      blocksData,
      showWizard,
      wizardStep,
      stepsValidations,
      activeTab,
      showExternalField,
      totalSteps,
    } = this.state;
    const workingTimeList = sprayTask.workingTimes.map((i) => ({
      start: i.workingFrom,
      end: i.workingTo,
      date: i.date,
    }));
    const overlappDate = this.isOverlapping(workingTimeList);
    const shouldDisableFieldsEdit = userAbilities.cannot(
      "update",
      "spray_tasks"
    );
    const shouldDisableChangeStatus = userAbilities.cannot(
      "update_status",
      "spray_tasks"
    );
    const tabProps = {
      onChange: this.onFieldChange,
      sprayTask,
      areasList,
      blocksData,
      showParentBlocks,
      fieldToEdit,
      toggleEditField: this.toggleEditField,
      checkAndUpdate: this.checkAndUpdate,
      updateTask: this.updateTask,
      onCancel: this.cancelActiveTask,
      online,
      setExternalField: this.setExternalField,
      startWizard: this.startWizard,
      ppeWorn: sprayTask.ppeWorn,
      shouldDisableFieldsEdit,
    };

    const showRouteTab =
      sprayTask &&
      sprayTask.status !== "OPEN" &&
      sprayTask.selectedMachinery &&
      sprayTask.selectedMachinery.trackerId;
    return sprayTask ? (
      <div className={styles.taskHolder}>
        {!showExternalField && (
          <TaskHeader
            navigate={navigate}
            fieldToEdit={!showWizard ? fieldToEdit : null}
            sprayTaskId={sprayTask.id || "New"}
            onSave={this.saveActiveTask}
            onCancel={this.onCancel}
            onBack={this.setActiveTask}
            onPrint={(e) => handlePrintTask(e, task)}
            disabledSave={!!fieldToEdit && !!fieldToEdit.error}
            disabledBack={
              sprayTask.attachments &&
              sprayTask.attachments.find((file) => file.idReplace) &&
              online
            }
            onCloseSidebar={onCloseSidebar}
            taskId={task.id || task._id}
            taskName={task.taskName}
            taskNamesOptions={taskNamesOptions}
            showWizard={showWizard}
            showWizardCancel={wizardStep < stepsValidations.length - 1}
          />
        )}

        {showWizard ? (
          <Wizard
            {...tabProps}
            sprayTask={sprayTask}
            shedId={sprayTask.shedId}
            areasList={areasList}
            showParentBlocks={showParentBlocks}
            wizardStep={wizardStep}
            toggleEditField={this.toggleEditField}
            fieldToEdit={fieldToEdit}
            onChange={this.onFieldChange}
            stepsValidations={stepsValidations}
            setStep={this.setStep}
            finishWizard={this.finishWizard}
            closeWizard={this.closeWizard}
            getTimesList={this.getTimesList}
            totalSteps={totalSteps}
            overlappingDate={overlappDate}
          />
        ) : (
          <div className={styles.taskBody}>
            <Grid verticalAlign="middle" className="no-margin">
              <TaskRow
                label="Task Name"
                name="taskName"
                inputType="textInput"
                value={task.taskName}
                toggleEditField={this.toggleEditField}
                fieldToEdit={fieldToEdit}
                onChange={this.onFieldChange}
                maxLength={TASK_NAME_MAX_LENGTH}
                heavy
                search
                disabled={shouldDisableFieldsEdit}
              />
              <TaskRow
                label="Status"
                name="status"
                value={
                  <StatusDropdown
                    selection
                    fluid
                    closeOnChange
                    onChange={this.changeStatus}
                    value={sprayTask.status}
                    className={styles.statusDropDown}
                    disabled={shouldDisableChangeStatus}
                  />
                }
                fieldToEdit={fieldToEdit}
              />
            </Grid>
            <div className={!showMore ? styles.openDiv : styles.closeDiv}>
              <div className={styles.showMoreHolder}>
                <Grid verticalAlign="middle" className="no-margin">
                  <TaskRow
                    label="Progress"
                    value={
                      <CircularProgressbar
                        value={blocksData.donePercent}
                        text={`${blocksData.donePercent}%`}
                        className={styles.progressBar}
                      />
                    }
                    fieldToEdit={fieldToEdit}
                  />
                  <TaskRow
                    label="Planned date"
                    name="plannedDate"
                    inputType="datePicker"
                    value={sprayTask.plannedDate}
                    toggleEditField={this.toggleEditField}
                    fieldToEdit={fieldToEdit}
                    onChange={this.onFieldChange}
                    rules={{ required: true }}
                    disabled={shouldDisableFieldsEdit}
                  />
                  {task.status === "COMPLETED" && (
                    <TaskRow
                      label="Completed date"
                      name="completedAt"
                      inputType="datePicker"
                      value={get(sprayTask.workingTimes.slice(-1)[0], "date")}
                      onChange={this.onFieldChange}
                      disabled={shouldDisableFieldsEdit}
                    />
                  )}
                  <TaskRow
                    label="Expiry date"
                    name="expirationDate"
                    inputType="datePicker"
                    value={sprayTask.expirationDate}
                    toggleEditField={this.toggleEditField}
                    fieldToEdit={fieldToEdit}
                    onChange={this.onFieldChange}
                    disabled={shouldDisableFieldsEdit}
                  />

                  <TaskRow
                    label="Application type"
                    name="applicationType"
                    inputType="dropdown"
                    options={applicationTypeOptions}
                    value={sprayTask.applicationType}
                    toggleEditField={this.toggleEditField}
                    fieldToEdit={fieldToEdit}
                    onChange={this.onFieldChange}
                    clearable
                  />

                  <TaskRow
                    label="Supervisors"
                    name="supervisors"
                    inputType="dropdown"
                    multiple
                    value={sprayTask.supervisors.map(
                      employee => employee.employee.id
                    )}
                    options={employeesOptions}
                    toggleEditField={this.toggleEditField}
                    fieldToEdit={fieldToEdit}
                    onChange={this.onFieldChange}
                    heavy
                    search
                    rules={{ required: true }}
                    disabled={shouldDisableFieldsEdit}
                  />
                  <TaskRow
                    label="Operators"
                    name="assignees"
                    inputType="dropdown"
                    multiple
                    value={sprayTask.assignees.map(
                      employee => employee.employee.id
                    )}
                    options={employeesOptions}
                    toggleEditField={this.toggleEditField}
                    fieldToEdit={fieldToEdit}
                    onChange={this.onFieldChange}
                    heavy
                    search
                    disabled={shouldDisableFieldsEdit}
                  />
                  <TaskRow
                    label="Intervention type"
                    name="interventionType"
                    inputType="dropdown"
                    options={interventionTypesOptions}
                    value={sprayTask.interventionType}
                    toggleEditField={this.toggleEditField}
                    fieldToEdit={fieldToEdit}
                    onChange={this.onFieldChange}
                    clearable
                    disabled={shouldDisableFieldsEdit}
                  />
                  <TaskRow
                    label="Creator"
                    name="creator"
                    inputType="dropdown"
                    options={employeesOptions}
                    value={sprayTask.creator.id}
                    toggleEditField={this.toggleEditField}
                    fieldToEdit={fieldToEdit}
                    onChange={this.onFieldChange}
                    rules={{ required: true }}
                    disabled
                  />
                  {!!list.content.length && (
                    <Can I={"access_module"} a="inventory">
                      <TaskRow
                        label="Shed"
                        name="shedId"
                        inputType="dropdown"
                        options={shedsOptions}
                        value={sprayTask.shedId}
                        toggleEditField={this.toggleEditField}
                        fieldToEdit={fieldToEdit}
                        onChange={this.onFieldChange}
                        clearable
                        disabled={shouldDisableFieldsEdit}
                      />
                    </Can>
                  )}

                  {sprayTask.status === "COMPLETED" && (
                    <TaskRow
                      label="Working times"
                      name="workingTimes"
                      inputType="component"
                      value={this.getTimesList()}
                      toggleEditField={this.toggleEditField}
                      fieldToEdit={fieldToEdit}
                      heavy
                      wide
                      component={
                        <WorkingTimes
                          sprayTask={sprayTask}
                          toggleEditField={this.toggleEditField}
                          fieldToEdit={fieldToEdit}
                          onChange={this.onFieldChange}
                          heavy
                          editAllMode
                        />
                      }
                      rules={{ notIncludeInArray: ["(--:--)", "(00:00)"] }}
                      disabled={shouldDisableFieldsEdit}
                    />
                  )}
                </Grid>
              </div>
            </div>
            <Button className={styles.moreToggle} onClick={this.toggleMore}>
              {showMore ? (
                <span>
                  More <Icon name="angle down" />
                </span>
              ) : (
                <span>
                  Less <Icon name="angle up" />
                </span>
              )}
            </Button>
            <div className={styles.tabsHolder}>
              <Menu pointing secondary className={styles.tabsMenu}>
                <Menu.Item
                  name="Location"
                  active={activeTab === "Location"}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name="Operations"
                  active={activeTab === "Operations"}
                  onClick={this.handleItemClick}
                />
                <Menu.Item
                  name="General Info"
                  active={activeTab === "General Info"}
                  onClick={this.handleItemClick}
                />
                {showRouteTab && (
                  <Menu.Item
                    name="Route"
                    active={activeTab === "Route"}
                    onClick={this.handleItemClick}
                  />
                )}
              </Menu>
              <div
                className={`${styles.tabItem} ${
                  activeTab === "Location" ? styles.visibleTab : ""
                }`}
              >
                <Location {...tabProps} />
              </div>
              <div
                className={`${styles.tabItem} ${
                  activeTab === "Operations" ? styles.visibleTab : ""
                }`}
              >
                <Operations
                  navigate={navigate}
                  sprayTask={sprayTask}
                  {...tabProps}
                />
              </div>
              <div
                className={`${styles.tabItem} ${
                  activeTab === "General Info" ? styles.visibleTab : ""
                }`}
              >
                <GeneralInfo {...tabProps} />
              </div>
              {showRouteTab && (
                <div
                  className={`${styles.tabItem} ${
                    activeTab === "Route" ? styles.visibleTab : ""
                  }`}
                >
                  <TaskRoute
                    navigate={navigate}
                    id={sprayTask.id}
                    {...tabProps}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    ) : (
      <Loader />
    );
  }
}

TaskDetailsMobile.propTypes = {
  Can: PropTypes.func,
  task: PropTypes.object,
  interventionTypes: PropTypes.object,
  list: PropTypes.object,
  actions: PropTypes.object,
  sprayDiary: PropTypes.object,
  setActiveTask: PropTypes.func,
  employeesOptions: PropTypes.array,
  interventionTypesOptions: PropTypes.array,
  taskNamesOptions: PropTypes.array,
  sprayTask: PropTypes.object,
  showParentBlocks: PropTypes.bool,
  areasList: PropTypes.array,
  online: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  fetchedTask: PropTypes.object,
};

TaskDetailsMobile.defaultProps = {
  interventionTypesOptions: [],
  setActiveTask: () => {},
  task: {},
  sprayTask: {},
};

function mapStateToProps(state) {
  const {
    sprayDiary,
    shed: { list },
    spray: {
      tasks: { interventionTypes },
    },
    applicationSettings: { showParentBlocks },
    sprayDiary: { workingTimes, areasList },
  } = state;
  return {
    Can: abilitiesSelector(state),
    interventionTypesOptions: interventionTypesSelector(state),
    employeesOptions: employeesSelector(state),
    shedsOptions: shedsListSelector(state),
    applicationTypeOptions: [
      { key: 1, text: "Spray", value: "SPRAY" },
      { key: 2, text: "Spread", value: "SPREAD" },
      { key: 3, text: "Fertigation", value: "FERTIGATION" },
    ],
    taskNamesOptions: taskNamesSelector(state),
    sprayTask: taskSelector(state),
    userAbilities: abilitiesPairsSelector(state),
    areasList,
    showParentBlocks,
    sprayDiary,
    fetchedTask: originalFetchedTaskSelector(state),
    interventionTypes,
    list,
    online: onlineSelector(state),
    workingTimes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        getBatchNumbersLastYear,
        getTaskById,
        changeChemicalBatchnumbersList,
        updateTaskItem,
        setSprayDiaryField,
        createTask,
        selectEmployees,
        selectSingleEmployee,
        changeAreas,
        setInterventionType,
        setWidthPerRow,
        setLPerHa,
        setDeltaT,
        setPPEWorn,
        setGrowthStage,
        setRowsToSpray,
        selectMachinery,
        clearMachinery,
        setMachineryConc,
        setMachinerySpeed,
        setNozzle,
        setNozzlesPerSide,
        setGear,
        setRpm,
        setPressure,
        setPto,
        setChemicalFromList,
        setChemicalItemToAdd,
        setComments,
        setStatus,
        setActualRowsToSpray,
        setWindSpeed,
        restoreBackup,
        setWindDirection,
        setActualRowsToSprayCheckState,
        fetchListSheds,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailsMobile);
