import { actionTypes } from "../../constants/TasksCreator";

const defaultOptions = {
  content: [],
  isFetching: false,
};

const initialState = {
  currentStep: 1,
  taskID: null,
  taskStages: {
    general: {
      taskName: "",
      subTaskName: "",
      description: "",
      dateRange: {
        start: null,
        end: null,
      },
      priority: null,
      assignTo: [],
      status: "OPEN",
      attachments: [],
    },
    instructions: {
      expandedFarmsIds: [],
      expandedAreasIds: [],
      treeSelectSearchFilter: "",
      treeSelect: [],
      farms: [],
      areas: [],
      crop: null,
      variety: null,
      numberOfEmployees: null,
      equipment: [],
      paymentMethod: null,
    },
    switchable: {
      round: null,
      subType: null,
      comments: null,
      timeBudgeting: null,
      perRow: null,
      perPlant: null,
      pickingType: null,
      harvestUnitId: null,
      unitPrice: null,
      harvestQualityCheck: null,
      checksType: [],
      size: null,
      runningTimeHours: null,
      runningTimeMinutes: null,
      flowRate: null,
      totalIrrigationWater: null,
    },
  },
  taskTypeID: null,
  taskTypes: { ...defaultOptions },
  taskPriorities: { ...defaultOptions },
  taskStatuses: { ...defaultOptions },
  taskPickingTypes: { ...defaultOptions },
  taskPaymentMethods: { ...defaultOptions },
  subTaskNames: { ...defaultOptions },
  isUploadingAttachment: false,
  error: null,
};

export const generalStageValidation = (data) => {
  const dateIsSet =
    data.dateRange.start !== null && data.dateRange.end !== null;
  const taskNameIsSet = !!data.taskName;
  const assignedToIsSet = !!data.assignTo.length;

  return dateIsSet && taskNameIsSet && assignedToIsSet;
};

export const instructionStageValidation = (data) => {
  const areasIsSet = !!data.areas.length;
  const paymentMethodIsSet = !!data.paymentMethod;

  return areasIsSet && paymentMethodIsSet;
};

export const switchableStageValidation = (currentStep) => currentStep > 3;

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    //TASK_TYPES
    case actionTypes.FETCH_TASK_TYPES_START:
      return {
        ...state,
        taskTypes: {
          ...state.taskTypes,
          isFetching: true,
        },
      };
    case actionTypes.FETCH_TASK_TYPES:
      return {
        ...state,
        taskTypes: {
          content: action.payload,
          isFetching: false,
        },
      };
    case actionTypes.FETCH_TASK_TYPES_ERROR:
      return {
        ...state,
        taskTypes: {
          ...state.taskTypes,
          isFetching: false,
        },
        error: action.payload,
      };

    //TASK_PRIORITIES
    case actionTypes.FETCH_TASK_PRIORITIES_START:
      return {
        ...state,
        taskPriorities: {
          ...state.taskPriorities,
          isFetching: true,
        },
      };
    case actionTypes.FETCH_TASK_PRIORITIES:
      return {
        ...state,
        taskPriorities: {
          ...state.taskPriorities,
          content: [...action.payload],
          isFetching: false,
        },
      };
    case actionTypes.FETCH_TASK_PRIORITIES_ERROR:
      return {
        ...state,
        taskPriorities: {
          ...state.taskPriorities,
          isFetching: false,
        },
        error: action.payload,
      };

    //TASK_PAYMENT_METHODS
    case actionTypes.FETCH_TASK_PAYMENT_METHODS_START:
      return {
        ...state,
        taskPaymentMethods: {
          ...state.taskPaymentMethods,
          isFetching: true,
        },
      };
    case actionTypes.FETCH_TASK_PAYMENT_METHODS:
      return {
        ...state,
        taskPaymentMethods: {
          ...state.taskPaymentMethods,
          content: [...action.payload],
          isFetching: false,
        },
      };
    case actionTypes.FETCH_TASK_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        taskPaymentMethods: {
          ...state.taskPaymentMethods,
          isFetching: false,
        },
        error: action.payload,
      };

    //TASK_STATUSES
    case actionTypes.FETCH_TASK_STATUSES_START:
      return {
        ...state,
        taskStatuses: {
          ...state.taskStatuses,
          isFetching: true,
        },
      };
    case actionTypes.FETCH_TASK_STATUSES:
      return {
        ...state,
        taskStatuses: {
          ...state.taskStatuses,
          content: [...action.payload],
          isFetching: false,
        },
      };
    case actionTypes.FETCH_TASK_STATUSES_ERROR:
      return {
        ...state,
        taskStatuses: {
          ...state.taskStatuses,
          isFetching: false,
        },
        error: action.payload,
      };

    //TASK_PICKING_TYPES
    case actionTypes.FETCH_TASK_PICKING_TYPES_START:
      return {
        ...state,
        taskPickingTypes: {
          ...state.taskPickingTypes,
          isFetching: true,
        },
      };
    case actionTypes.FETCH_TASK_PICKING_TYPES:
      return {
        ...state,
        taskPickingTypes: {
          ...state.taskPickingTypes,
          content: [...action.payload],
          isFetching: false,
        },
      };
    case actionTypes.FETCH_TASK_PICKING_TYPES_ERROR:
      return {
        ...state,
        taskPickingTypes: {
          ...state.taskPickingTypes,
          isFetching: false,
        },
        error: action.payload,
      };

    //TASK_SUB_NAMES
    case actionTypes.FETCH_SUB_TASK_NAMES_START:
      return {
        ...state,
        subTaskNames: {
          ...state.subTaskNames,
          isFetching: true,
        },
      };
    case actionTypes.FETCH_SUB_TASK_NAMES:
      return {
        ...state,
        subTaskNames: {
          ...state.subTaskNames,
          content: [...action.payload],
          isFetching: false,
        },
      };
    case actionTypes.FETCH_SUB_TASK_NAMES_ERROR:
      return {
        ...state,
        subTaskNames: {
          ...state.subTaskNames,
          isFetching: false,
        },
        error: action.payload,
      };

    // FORM
    case actionTypes.CLEAR_FORM_STATE:
      return initialState;
    case actionTypes.SET_FORM_STATE:
      return {
        ...state,
        taskStages: {
          ...action.payload,
        },
      };
    case actionTypes.MOVE_TO_THE_NEXT_STEP:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    case actionTypes.MOVE_TO_STEP:
      return {
        ...state,
        currentStep: action.payload.stage,
      };
    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        taskStages: {
          ...state.taskStages,
          [action.payload.stage]: {
            ...state.taskStages[action.payload.stage],
            [action.payload.fieldName]: action.payload.fieldValue,
          },
        },
      };

    //  ATTACHMENTS

    case actionTypes.UPLOAD_GENERAL_TASKS_ATTACHMENTS_START:
      return {
        ...state,
        taskStages: {
          ...state.taskStages,
          general: {
            ...state.taskStages.general,
            attachments: [
              ...state.taskStages.general.attachments,
              action.payload,
            ],
          },
        },
        isUploadingAttachment: true,
      };

    case actionTypes.UPLOAD_GENERAL_TASKS_ATTACHMENTS:
      return {
        ...state,
        taskStages: {
          ...state.taskStages,
          general: {
            ...state.taskStages.general,
            attachments: state.taskStages.general.attachments.map(
              ({ idReplace, ...item }) =>
                idReplace === action.payload.idReplace
                  ? {
                      ...action.payload.localData,
                      responseData: action.payload.responseData,
                    }
                  : { ...item, idReplace }
            ),
          },
        },
        isUploadingAttachment: false,
      };

    case actionTypes.UPLOAD_GENERAL_TASKS_ATTACHMENTS_ERROR:
      return {
        ...state,
        isUploadingAttachment: false,
      };

    case actionTypes.DELETE_ATTACHMENT:
      return {
        ...state,
        taskStages: {
          ...state.taskStages,
          general: {
            ...state.taskStages.general,
            attachments: state.taskStages.general.attachments.filter(
              (attachment) => attachment.id !== action.payload
            ),
          },
        },
      };

    case actionTypes.SET_TASK_TYPE:
      return {
        ...state,
        taskTypeID: action.payload,
      };

    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
