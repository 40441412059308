import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MultiSelectFilter from "./MultiSelectFilter/MultiSelectFilter";

const EmployeeMultiSelectFilter = ({ list, isFetching, ...extraProps }) => {
  const options = useMemo(
    () =>
      list
        .map(item => ({
          key: item.id,
          text: `${item.firstName} ${item.lastName}`,
          value: item.id
        }))
        .sort((o1, o2) => o1.text.localeCompare(o2.text)),
    [list]
  );

  return <MultiSelectFilter options={options} numbers={true} {...extraProps} />;
};

EmployeeMultiSelectFilter.propTypes = {
  isFetching: PropTypes.bool,
  list: PropTypes.array,
  actions: PropTypes.object
};

const mapStateToProps = ({
  employee: {
    data: { content },
    isFetching
  }
}) => {
  return {
    list: content,
    isFetching
  };
};

export default connect(mapStateToProps)(EmployeeMultiSelectFilter);
