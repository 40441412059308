import React, { useMemo } from "react";
import PropTypes from "prop-types";
import LocalDataTable from "../../components/LocalDataTable";
import flatten from "flatley";
import PackingShedTable from "components/LocalDataTable/PackingShed";

const FlattenRecordsView = ({ name, data, options = {}, ...extraProps }) => {
  const flattenData = useMemo(
    () => (data || []).map((item) => flatten(item, options)),
    [data, options]
  );
  const columns = useMemo(
    () =>
      flattenData
        .reduce(function(arr, o) {
          return Object.keys(o).reduce(function(a, k) {
            if (a.indexOf(k) === -1) a.push(k);
            return a;
          }, arr);
        }, [])
        .map((name) => ({
          id: name,
          Header:
            name &&
            name
              .split(/(?=[A-Z])/)
              .join("_")
              .toLowerCase(),
          accessor: (r) =>
            r[name] instanceof Object && r[name].constructor === Object
              ? JSON.stringify(r[name])
              : r[name]
              ? String(r[name])
              : r[name],
        })),
    [flattenData]
  );

  return (
    <PackingShedTable
      name={name}
      data={flattenData}
      tableColumns={columns}
      withDateRange={false}
      exportableToExcel={true}
      {...extraProps}
    />
  );
};

FlattenRecordsView.propTypes = {
  name: PropTypes.object,
  data: PropTypes.array.isRequired,
  options: PropTypes.object,
};

FlattenRecordsView.defaultProps = {};

export default FlattenRecordsView;
