import React from "react";
import { Table } from "semantic-ui-react";
import { useTable } from "react-table";
import "./PackingShed.css";
import moment from "moment";

const PackingShedTable = ({ name, data, tableColumns, ...extraProps }) => {
  const columns = React.useMemo(() => tableColumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div className="table-container">
      <Table celled {...getTableProps()}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {!!rows.length &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <Table.Row {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <Table.Cell {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </Table.Cell>
                  ))}
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default PackingShedTable;
